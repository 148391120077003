import React from "react";
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";

export const DDndContext = React.memo(props => {
  const { onDragEnd } = props;
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 50 pixels before activating
    /*
    activationConstraint: {
      delay: 100,
      tolerance: 50,
      distance: 5,
    },
    */
  });
  const sensors = useSensors(mouseSensor);
  return (
    <DndContext onDragEnd={onDragEnd} sensors={sensors}>
      {props.children}
    </DndContext>
  );
});
