import React from "react";
import request from "request";
import * as querystring from "query-string";
import throttle from "lodash.throttle";
import TagManager from "../../components/TagManager";

import {
  baseApiUrl,
  COOKIE_EMAIL_SHARED,
  getCookieValue,
  imageUrl,
  getData,
  capitalize,
} from "../../util";
import "./screenshots.css";
import Loading from "../../components/Loading";
import CarouselNew from "../../components/CarouselNew";
import CarouselNewMobile from "../../components/CarouselNewMobile";
import AccessDenied from "../../components/AccessDenied";
import { useParams } from "react-router";
import Modal from "@material-ui/core/Modal";
import * as cx from "classnames";
import SadComputer from "../../icons/old/sadpage.svg";
import Eye from "../../icons/eye_green.svg";
import Alert from "../../icons/exclamation_purple.svg";
import Chart from "../../icons/chart_blue.svg";
import X from "../../icons/red_x.svg";
import Screenshot from "../../img/free_trial_screenshots.png";
import Bg from "../../img/free_trial_gradient.png";
import LockIcon from "../../icons/lock_white.svg";

class ViewSharedScreenshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: { screenshots: [] },
      company: this.props.company,
      zoomedOut: false,
      loadedSavedTests: true,
      testId: this.props.match.params.test,
      saveEmailRequestLoading: false,
      emailShared: false,
      showLinkPopup: false,
      loading: true,
      useMobile: this.useMobileComponent(),
    };
  }
  resizeListener = throttle(() => {
    this.setState({ useMobile: this.useMobileComponent() });
  });
  componentDidMount() {
    window.addEventListener("resize", this.resizeListener);
    request({
      url: `${baseApiUrl}/v1/t?url=${window.location.href}`,
      headers: {
        Authorization: "",
      },
    });
    getData({
      url: `${baseApiUrl}/v1/screenshot/share?id=${this.state.testId}`,
    })
      .then(({ err, res, data }) => {
        if (err) {
          this.setState({
            loading: false,
          });
        }
        data.screenshots.screenshots.sort((a, b) => (a.champ ? -1 : 1));

        let varNum = 0;
        data.screenshots.screenshots.forEach(s => {
          if (!s.control) {
            varNum += 1;
            s.varNum = varNum;
          }
        });

        this.setState({
          loading: false,
          screenshots: data.screenshots,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    document.body.style = "overflow:hidden!important";
    const emailSharedCookie = getCookieValue(COOKIE_EMAIL_SHARED);

    this.setState({
      emailShared:
        emailSharedCookie && emailSharedCookie === "1" ? true : false,
    });
    const tagManagerArgs = {
      gtmId: "GTM-PZ53KLT",
    };

    TagManager.initialize(tagManagerArgs);
  }
  componentWillUnmount() {
    document.body.style = "";
    window.removeEventListener("resize", this.resizeListener);
  }
  closeLinkPopup = () => {
    this.setState({ showLinkPopup: false });
  };
  showLinkPopup = () => {
    this.setState({ showLinkPopup: true });
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  openGetinTouchForm = () => {
    window.open(
      "https://dowhatworks.io/learn-more?utm_source=dowhatworks_share",
      "_blank"
    );
    this.closeLinkPopup();
  };
  useMobileComponent = () => {
    let width = window.visualViewport
      ? window.visualViewport.width
      : window.innerWidth;
    return width < 800;
  };
  requestAccess = e => {
    e.preventDefault();
    if (this.state.saveEmailRequestLoading) return;
    if (
      this.state.email.trim() === "" ||
      !this.state.email.match(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    )
      return;
    this.setState({
      saveEmailRequestLoading: true,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        url: `${baseApiUrl}/v1/user/get_access`,
        form: {
          email: this.state.email.trim(),
          shared_link: this.state.testId,
        },
      },
      (err, res) => {
        this.setState({
          saveEmailRequestLoading: false,
        });
        if (err) {
          alert("Something went wrong, please try again");
        } else {
          console.log({ err, res });
          document.cookie = `${COOKIE_EMAIL_SHARED}=1; max-age=${
            60 * 60 * 24 * 30
          }`;
          this.setState({
            emailShared: true,
          });
          this.closeLinkPopup();
        }
      }
    );
  };
  render() {
    let width = 800;
    let height = 700;
    const { useMobile } = this.state;
    if (this.state.error) {
      return (
        <>
          <Modal open={true}>
            <div
              className="box tc"
              style={{
                width: useMobile ? `100vw` : `${width}px`,
                height: useMobile ? "auto" : `${height}px`,
                top: useMobile ? "0px" : (window.innerHeight - height) / 2,
                position: "absolute",
                left: useMobile ? "0px" : (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div className="pa4">
                <>
                  <img
                    src={SadComputer}
                    style={{ width: "60px" }}
                    alt="error"
                  />
                  <p className="f3 tc pt4">
                    Looks like the link to this test has expired.
                  </p>
                  <p className="f3 tc">
                    Connect with our team to get access to thousands of
                    experiments.
                  </p>
                  <button
                    className="primary br-pill bn ma4"
                    style={{
                      color: "#fff",
                      padding: "6px 18px",
                      backgroundColor: "rgba(246,91,86,1)",
                    }}
                    onClick={this.openGetinTouchForm}
                  >
                    Request Access
                  </button>
                </>
              </div>
            </div>
          </Modal>
        </>
      );
    }
    if (this.state.loading) {
      return <Loading />;
    }

    const { screenshots, error } = this.state;

    width = 625;
    const moreModalWidth = 950;
    const showEmailPopup =
      !this.state.screenshots.open && !this.state.emailShared;
    return (
      <>
        {screenshots && screenshots.screenshots.length > 0 ? (
          <>
            {screenshots.screenshots[0].notes &&
              screenshots.screenshots[0].notes.length > 0 && (
                <>
                  {this.state.useMobile ? (
                    <CarouselNewMobile
                      images={screenshots.screenshots}
                      url={screenshots.url}
                      screenshots={screenshots}
                      id={this.props.id}
                      didScroll={window.scrollY > 60}
                      urlParams={this.props.urlParams}
                      newPage={this.props.newPage}
                      showLinkPopup={this.showLinkPopup}
                      sharedScreenshot={true}
                      history={this.props.history}
                    />
                  ) : (
                    <>
                      <div className="ss-modal-wrapper" />
                      <div
                        className={cx("mt3", "center", "tl")}
                        onClick={this.closeModal}
                        id="ss-modal-scroller"
                      >
                        <div
                          className="modal-scroll-inner"
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <CarouselNew
                            images={screenshots.screenshots}
                            url={screenshots.url}
                            screenshots={screenshots}
                            id={this.props.id}
                            didScroll={window.scrollY > 60}
                            urlParams={this.props.urlParams}
                            newPage={this.props.newPage}
                            showLinkPopup={this.showLinkPopup}
                            sharedScreenshot={true}
                            history={this.props.history}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
          </>
        ) : (
          <Loading />
        )}
        <Modal
          open={showEmailPopup && !this.state.loading}
          className="dww-modal-container light-modal-bg"
          style={{ position: useMobile ? "absolute" : "fixed" }}
        >
          <div
            className="box"
            style={{
              width: useMobile ? "100vw" : `${width}px`,
              minHeight: useMobile ? "95vh" : "auto",
              top: useMobile ? "0px" : "50px",
              position: "absolute",
              left: useMobile ? 0 : (window.innerWidth - width) / 2,
              overflow: useMobile ? "visible" : "hidden",
              overflowX: "auto",
              outline: 0,
            }}
          >
            <div style={{ top: "6px", right: "12px", position: "absolute" }}>
              <a href="https://dowhatworks.io">
                <img src={X} alt="Close" className="pointer" />
              </a>
            </div>
            <div
              className="flex"
              style={{
                backgroundImage: "linear-gradient(white 51%, #2A2D30 49%)",
                backgroundClip: "padding-box",
              }}
            >
              <div
                className={cx({
                  tl: true,
                  "flex flex-column": true,
                })}
                style={useMobile ? { minHeight: "100vh" } : {}}
              >
                <div
                  className="pa3 tc flex"
                  style={{
                    fontSize: "1.8rem",
                    flexGrow: 1,
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      lineHeight: "2.5rem",
                    }}
                    className="pt2 w-100"
                  >
                    Sign up for our newsletter to see this test
                  </div>
                </div>

                <div
                  className={cx({
                    "center pb3": true,
                    "w-80": !useMobile,
                    "w-90": useMobile,
                  })}
                >
                  <form
                    onSubmit={this.requestAccess}
                    className={cx({
                      pa3: true,
                    })}
                  >
                    <div
                      className="flex"
                      style={{ alignItems: "center", flexDirection: "column" }}
                    >
                      <div
                        className={cx({
                          flex: !useMobile,
                        })}
                      >
                        {!useMobile && (
                          <div className="mr3" style={{ padding: "1px 0" }}>
                            <div
                              className={`screenshotItem-notesHeader ${
                                screenshots.screenshots[1].champ
                                  ? "screenshotItem-notesHeader-green"
                                  : ""
                              }`}
                              style={{
                                overflow: "hidden",
                                maxHeight: "150px",
                                width: "150px",
                                padding: "0!important",
                                borderRadius: "6px",
                                boxShadow: "0 0 4px rgba(0,0,0, 0.5)",
                                padding: "0px",
                              }}
                            >
                              <div className="b f8 tc pv1">
                                {screenshots.screenshots[1].champ
                                  ? "Winner"
                                  : "Variant"}
                              </div>
                              <div
                                style={{
                                  overflow: "hidden",
                                  width: "150px",
                                  height: "150px",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={imageUrl(
                                    screenshots.screenshots[1].src,
                                    200,
                                    0,
                                    2
                                  )}
                                  style={{ width: "100%", filter: "blur(3px)" }}
                                />
                                <div
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.65)",
                                    width: "150px",
                                    height: "130px",
                                    position: "absolute",
                                    top: "0px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={LockIcon}
                                    height={100}
                                    style={{
                                      zIndex: 2,
                                      opacity: 0.6,
                                      marginTop: "12px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className={`screenshotItem-notesHeader ${
                                screenshots.screenshots[0].champ
                                  ? "screenshotItem-notesHeader-green"
                                  : ""
                              }`}
                              style={{
                                overflow: "hidden",
                                width: "150px",
                                height: "150px",
                                padding: 0,
                                borderRadius: "6px",
                                boxShadow: "0 0 4px rgba(0,0,0, 0.5)",
                                padding: "0px",
                                marginTop: "24px",
                              }}
                            >
                              <div className="b f8 tc pv1">
                                {screenshots.screenshots[0].champ
                                  ? "Winner"
                                  : "Variant"}
                              </div>
                              <div
                                style={{
                                  overflow: "hidden",
                                  maxHeight: "150px",
                                  width: "150px",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={imageUrl(
                                    screenshots.screenshots[0].src,
                                    200,
                                    0,
                                    2
                                  )}
                                  style={{ width: "100%", filter: "blur(3px)" }}
                                />
                                <div
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.65)",
                                    width: "150px",
                                    height: "130px",
                                    position: "absolute",
                                    top: "0px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={LockIcon}
                                    height={100}
                                    style={{
                                      zIndex: 2,
                                      opacity: 0.6,
                                      marginTop: "12px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className="pt2 br3"
                          style={{ background: "#F0F0F0" }}
                        >
                          <div className="mv3 tl">
                            <div
                              className={cx("sb ph3 pb2 bb b--black-05 f7 ttu")}
                            >
                              Who's Testing
                            </div>
                            <div className="ph3 pb3 pt2">
                              <div className="f5 tl" id="ss-header-text">
                                {screenshots.company_name}
                              </div>
                            </div>
                          </div>
                          <div className="mv3 tl">
                            <div
                              className={cx("sb ph3 pb2 bb b--black-05 f7 ttu")}
                            >
                              What's Tested
                            </div>
                            <div className="ph3 pb3 pt2">
                              <div className="f5 tl" id="ss-header-text">
                                {capitalize(screenshots.headline_tag)}.
                              </div>
                            </div>
                          </div>
                          <div className="mv3 tl">
                            <div
                              className={cx("sb ph3 pb2 bb b--black-05 f7 ttu")}
                            >
                              Page type
                            </div>
                            <div className="ph3 pb3 pt2">
                              {screenshots.page_type &&
                                screenshots.page_type.map((page_type, i) => (
                                  <>
                                    {i > 0 && ", "}
                                    <span key={`pt-${i}`}>{page_type}</span>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={cx({ "flex pt4": !useMobile })}>
                      <div
                        className={cx({
                          "w-60": !useMobile,
                        })}
                      >
                        <input
                          type="email"
                          placeholder="Your Work Email"
                          className="bg-white gray br2 w-100 pl2 pv2 ba b--black-10"
                          name="email"
                          onKeyUp={this.handleKeyUp}
                        />
                      </div>
                      <div
                        className={cx({
                          "w-40 pl3 tr": !useMobile,
                          "w-100 mt3": useMobile,
                        })}
                      >
                        <button
                          className={cx({
                            "modal-action ui-button w-100": true,
                          })}
                          type="submit"
                          style={{
                            padding: useMobile ? undefined : "8px 18px",
                          }}
                        >
                          Get Access
                        </button>
                      </div>
                    </div>
                  </form>
                  <div
                    className={cx({
                      "ph3 pt3 pb3 f6": true,
                      "mt1 bt b--black-05": useMobile,
                    })}
                    style={{ color: "#F0F0F0" }}
                  >
                    <a href="https://dowhatworks.io" target="_blank">
                      DoWhatWorks
                    </a>{" "}
                    lets you see winners and losers of split tests from the
                    world's top brands. We have <b>over 20,000</b> tests in our
                    vault and growing.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.showLinkPopup}
          className="dww-modal-container light-modal-bg"
        >
          <div
            className="box"
            style={{
              width: useMobile ? "100vw" : `${moreModalWidth}px`,
              height: useMobile ? "100vh" : "auto",
              top: useMobile ? "0px" : "40px",
              position: "absolute",
              left: useMobile ? 0 : (window.innerWidth - moreModalWidth) / 2,
              minHeight: useMobile ? "100vh" : "auto",
              overflow: useMobile ? "visible" : "hidden",
              overflowX: useMobile ? "auto" : "hidden",
              outline: 0,
            }}
          >
            <div className={cx({ flex: !useMobile })}>
              <div style={{ top: "6px", right: "12px", position: "absolute" }}>
                <img
                  src={X}
                  alt="Close"
                  className="pointer"
                  onClick={this.closeLinkPopup}
                />
              </div>
              <div
                className={cx({
                  "w-50 flex": !useMobile,
                  dn: useMobile,
                })}
              >
                <div
                  style={{
                    backgroundImage: `url("${Bg}")`,
                    backgroundSize: "contain",
                    overflow: "hidden",
                    width: "74%",
                  }}
                >
                  <img
                    src={Screenshot}
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      left: "0px",
                      width: "49%",
                    }}
                  />
                </div>
              </div>
              <div
                className={cx({
                  tl: true,
                  "w-50": !useMobile,
                })}
              >
                <div
                  className={cx({
                    "b mt3 pa3 tc f5": true,
                    "bb b--black-025 mb3": useMobile,
                  })}
                  style={{ color: "rgb(155, 81, 224)" }}
                >
                  Unfortunately that's only available to DoWhatWorks customers.
                </div>
                <h3
                  className={cx({
                    "f1 tc b": true,
                    "ph3 pt2": useMobile,
                    pa3: !useMobile,
                  })}
                  style={{
                    lineHeight: "3.25rem",
                    marginBottom: 0,
                  }}
                >
                  Want to <br />
                  see more?
                </h3>
                <div className="pt3 pa3">
                  With DoWhatWorks you'd be able to see everything about this
                  test and so many more.
                </div>
                <div className="pa3">
                  <div className="flex">
                    <div style={{ width: "42px" }}>
                      <img
                        src={Eye}
                        style={{ minWidth: "24px", marginTop: "-1px" }}
                      />
                    </div>
                    <div className="pl1">
                      See{" "}
                      <span style={{ color: "#4ac0a8" }} className="b">
                        split tests
                      </span>{" "}
                      from top brands (and your competitors).
                    </div>
                  </div>
                  <div className="mt3 flex">
                    <div style={{ width: "58px" }}>
                      <img
                        src={Alert}
                        style={{ width: "22px", marginTop: "-3px" }}
                      />
                    </div>
                    <div className="pl1">
                      Be in the know with{" "}
                      <span style={{ color: "#9b51e0" }} className="b">
                        tailored alerts
                      </span>{" "}
                      about who's testing and what's winning.
                    </div>
                  </div>
                  <div className="mt3 flex">
                    <div style={{ width: "58px" }}>
                      <img src={Chart} style={{ width: "24px" }} />
                    </div>
                    <div className="pl1">
                      Get{" "}
                      <span style={{ color: "#3f80fd" }} className="b">
                        custom, evidence-based assessments
                      </span>{" "}
                      of your UX, based on 19,000+ tests.
                    </div>
                  </div>
                  <div className="mt4 tc b">Check out a demo to see more.</div>
                </div>
                <div className="ph4 pb3 tc mt3">
                  <a
                    className="ui-button modal-action"
                    onClick={this.openGetinTouchForm}
                  >
                    Get in Touch
                  </a>
                  <div className="pt3">
                    <a href="https://dowhatworks.io" target="_blank">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ViewSharedScreenshot;
