import React from "react";
import { parseNoteTag } from "../util";
import Lock from "../icons/lock_grey.svg";
import LockWhite from "../icons/lock_white.svg";

const getDescLength = len => {
  // const amount = Math.round(Math.random() * 100);
  return `${100 + len}px`;
};

const NoteDisplay = ({
  text,
  pos,
  isAdminPage,
  isTiny,
  count,
  company,
  freeTrial = false,
  triggerUnlockModal,
}) => {
  if (!text) {
    return <></>;
  }
  const parsedTag = parseNoteTag(text, isAdminPage);

  if (!parsedTag) {
    return <div>{text}</div>;
  } else {
    const { tag, desc } = parsedTag;
    if (isTiny) {
      if (freeTrial)
        return (
          <div
            className="note-tag-desc-free"
            title="This data is only available to subscribers."
          >
            <img src={LockWhite} width={16} className="mr2 pointer" />
            {count && <span className="mr2">{count}.</span>}
            <span
              style={{
                width: getDescLength(Number.parseInt(desc)),
                height: "12px",
                background: "rgba(255, 255, 255, 0.15)",
                borderRadius: "4px",
                marginTop: "3px",
                display: "block",
              }}
            >
              &nbsp;
            </span>
          </div>
        );
      else {
        return (
          <div>
            {count && <span className="mr2">{count}.</span>}
            {desc}
          </div>
        );
      }
    }
    return (
      <div className="note-tag-container flex">
        <div className="note-tag-tag f7">
          {company ? (
            <a
              href={`/home/${company}/report?tag=${tag}`}
              className="ui-link-underline"
            >
              {tag}
            </a>
          ) : (
            <>{tag}</>
          )}
        </div>
        <div className="note-tag-desc">
          {freeTrial ? (
            <>
              <img
                src={Lock}
                width={16}
                className="mr1 pointer"
                style={{ paddingBottom: "1px" }}
                title="Unlock this test"
                onClick={triggerUnlockModal}
              />
              <span
                style={{
                  width: getDescLength(Number.parseInt(desc)),
                  height: "12px",
                  background: "rgba(0, 0, 0, 0.15)",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;
              </span>
            </>
          ) : (
            desc
          )}
        </div>
      </div>
    );
  }
};

export default NoteDisplay;
