import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl, handleError, addCommas, formatDate } from "../../util";
import { Link } from "react-router-dom";
import * as cx from "classnames";

const times = [
  { name: "Since Last Alert", value: "last_alert" },
  { name: "7 days", value: 7 },
  { name: "30 days", value: 30 },
];

const timesData = {
  last_alert: "since last alert",
  7: "in the last 7 days",
  30: "in the last 30 days",
};

class PublishingDashboard extends React.Component {
  state = {
    loading: true,
    time: "last_alert",
    view: "stats",
    challenges: [],
    page_types: [],
    tags: [],
    sectors: [],
    show_all_tags: false,
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/publishing_dashboard/?time=${this.state.time}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);
        this.setState({
          loading: false,
          ...json,
        });
      }
    );
  };
  doChange = e => {
    this.setState({ time: e.currentTarget.value }, () => {
      this.getData();
    });
  };
  showAll = e => {
    e.preventDefault();
    this.setState({ show_all_tags: true });
  };
  showLess = e => {
    e.preventDefault();
    this.setState({ show_all_tags: false });
  };
  render() {
    const {
      tags,
      challenges,
      page_types,
      sectors,
      show_all_tags,
      test_count,
      time,
    } = this.state;
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <h1 className="f1 pa3">
          {test_count} tests published since
          <select
            name="time"
            onChange={this.doChange}
            value={time}
            className="ml2"
          >
            <option value="Last Alert">last alert</option>
            <option value="7">7 days ago</option>
            <option value="30">30 days ago</option>
          </select>
        </h1>
        <div className="box">
          <div className="flex pa3">
            <div className="w-40 pr3">
              <h2 className="f3 b">Tags</h2>
              <div className={cx({ "scroller-down": show_all_tags })}>
                <div
                  className={cx({
                    "mb3 flex flex-wrap": true,
                    "scroller-down-scroll": show_all_tags,
                  })}
                >
                  {tags.map((tag, i) => {
                    if (!show_all_tags && i > 41) {
                      return <></>;
                    }
                    return (
                      <div className="pt1 flex w-50 pr2">
                        <div className="flex-grow">
                          <span className="ttc">
                            <Link
                              to={`/home/admin/report?tag=${tag.tag}&sort=ascending`}
                            >
                              {tag.tag}
                            </Link>
                          </span>
                        </div>
                        <div className="b">{tag.count}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="pt2 tc">
                {!show_all_tags ? (
                  <a href=" #" className="b" onClick={this.showAll}>
                    Show all
                  </a>
                ) : (
                  <a href=" #" className="b" onClick={this.showLess}>
                    Show less
                  </a>
                )}
              </div>
            </div>
            <div className="w-60 flex pl3">
              <div className="w-50 pr3">
                <h2 className="f3 b">Page Types</h2>
                <div className="mb3">
                  {page_types.map((tag, i) => {
                    return (
                      <div className="pt1 flex">
                        <div className="flex-grow">
                          <span className="ttc">
                            <Link
                              to={`/home/admin/report?page_type=${tag.page_type}&sort=ascending`}
                            >
                              {tag.page_type}
                            </Link>
                          </span>
                        </div>
                        <div className="b">{tag.count}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="pl3 w-50">
                <h2 className="f3 b">Challenges</h2>
                <div className="mb3">
                  {challenges.map((tag, i) => {
                    return (
                      <div className="pt1 flex">
                        <div className="flex-grow">
                          <span className="ttc">
                            <Link
                              to={`/home/admin/report?challenge=${tag.challenge}&sort=ascending`}
                            >
                              {tag.challenge}
                            </Link>
                          </span>
                        </div>
                        <div className="b">{tag.count}</div>
                      </div>
                    );
                  })}
                </div>
                <h2 className="f3 b">Sectors</h2>
                <div className="mb3">
                  {sectors.map((tag, i) => {
                    return (
                      <div className="pt1 flex">
                        <div className="flex-grow">
                          <span className="ttc">
                            <Link
                              to={`/home/admin/report?sector=${tag.sector}&sort=ascending`}
                            >
                              {tag.sector.replace(/_/gi, " ")}
                            </Link>
                          </span>
                        </div>
                        <div className="b">{tag.count}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublishingDashboard;
