import React, { Component } from "react";
import request from "request";
import { baseApiUrl } from "../../util";

class SSO extends Component {
  state = {
    redir: null,
    email: null,
  };
  componentDidMount() {}

  handleSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    request.get(
      {
        url: `${baseApiUrl}/v1/sso/auth?email=${email}`,
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        window.location.replace(json.url);
        return;
      }
    );
  };

  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const { error } = this.state;
    const labelClass = "db fw4 lh-copy black-60 mt4";
    const inputClass = "pa2 input-reset w-80 ba b--black-20 bg-white br2";
    return (
      <main className="pv4">
        <div className="box mw6 center pa3 br2 flex flex-wrap bg-white tl">
          <form onSubmit={this.handleSubmit} className="w-100">
            <h1>Single Sign-On</h1>
            {error && <p className="dark-red">{error.message}</p>}
            <div className="mt3">
              <label className={labelClass}>Email</label>
              <input
                type="email"
                onChange={this.handleKeyUp}
                name="email"
                className={inputClass}
              />
            </div>
            <div className="mt4">
              <input
                type="submit"
                value="Continue"
                className="ui-button primary"
              />
            </div>
          </form>
        </div>
      </main>
    );
  }
}

export default SSO;
