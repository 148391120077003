import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import EditCustomer from "./components/EditCustomer";
import Dashboard from "./Dashboard";
import { baseApiUrl, formatStripePrice } from "../../util";
import UploadCSV from "../../components/form/UploadCSV";

class Companies extends React.Component {
  state = {
    loading: true,
    companies: [],
    customers: [],
    allCompanies: [],
    allCustomers: [],
    addNew: false,
    type: "all",
    editing: null,
    paid: false,
    sub: null,
    tld: null,
    file: null,
    reportDelivery: {
      day: "monday",
    },
  };
  componentDidMount() {
    this.getData();
  }
  showAddNew = e => {
    e.preventDefault();
    this.setState({ addNew: true });
  };
  changeType = e => {
    this.setState({ type: e.currentTarget.value });
  };
  onChange = e => {
    const { name, value, type, checked } = e.currentTarget;
    if (type === "checkbox") {
      this.setState({ [name]: !!checked });
    } else {
      this.setState({ [name]: value });
    }
  };
  getData = () => {
    this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/sem/customers`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          sem_customers: json.sem_customers,
          all_sem_customers: json.sem_customers,
          plans: json.prices.filter(p => p.price_data),
        });
      }
    );
  };

  filterCustomers = () => {
    const { type, sem_customers } = this.state;
    if (type === "paid") {
      return sem_customers.filter(c => c.paid);
    }
    return sem_customers;
  };

  doEdit = id => e => {
    e.preventDefault();
    const customer = this.state.sem_customers.find(c => c.id === id);
    this.setState({
      editing: id,

      sub: customer.sub,
      paid: customer.paid,
      coupon: customer.coupon,
      tld: customer.tld,
      terms: customer.terms,
      reportDelivery: customer.report_delivery
        ? customer.report_delivery
        : {
            day: "monday",
          },
      showAddNew: false,
    });
  };

  add = e => {
    this.setState({ saving: true });
    e.preventDefault();
    const {
      tld,
      editing,
      company,
      sub,
      paid,
      coupon,
      terms,
      file,
      reportDelivery,
      limit,
    } = this.state;
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem/customers/new`,
        form: {
          tld: tld,
          company,
          sub,
          file,
          terms: terms
            ? terms
                .split(",")
                .map(t => t.trim())
                .join("||")
            : undefined,
          report_delivery: JSON.stringify(reportDelivery),
          paid,
          coupon,
          limit,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.setState(
            {
              saving: false,
              tld: null,
              company: null,
              sub: null,
              terms: null,
            },
            () => {
              this.getData();
            }
          );
        }
      }
    );
  };
  edit = e => {
    e.preventDefault();
    this.setState({ saving: true });
    const {
      tld,
      editing,
      sub,
      paid,
      terms,
      id,
      file,
      reportDelivery,
      limit,
      coupon,
    } = this.state;
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem/customers/new`,
        form: {
          tld: tld.join("||"),
          id: editing,
          paid,
          coupon,
          sub,
          terms: terms ? terms.join("||") : undefined,
          file,
          limit,
          report_delivery: JSON.stringify(reportDelivery),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.setState(
            {
              saving: false,
              displayTerm: "",
              term: "",
              editing: false,
            },
            () => {
              this.getData();
            }
          );
        }
      }
    );
  };
  setFile = file => {
    this.setState({ file: file[0] });
  };
  changeReportDelivery = e => {
    const { value } = e.currentTarget;
    this.setState({
      reportDelivery: {
        day: value,
        date: this.state.reportDelivery
          ? this.state.reportDelivery.date
          : undefined,
      },
    });
  };
  changeReportDeliveryDate = e => {
    const { value } = e.currentTarget;
    this.setState({
      reportDelivery: {
        day: this.state.reportDelivery.day,
        date: value,
      },
    });
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const sem_customers = this.filterCustomers();
    const { plans, editing, paid, sub, tld, company, saving, limit, coupon } =
      this.state;
    return (
      <div>
        <h1>SEM Customers</h1>
        <div className="mv3">
          <b>Show: </b>
          <select
            name="type"
            onChange={this.changeType}
            value={this.state.type}
          >
            <option value="all">All Customers</option>
            <option value="paid">Paid Customers</option>
          </select>
        </div>
        {!this.state.addNew ? (
          <div className="mv4">
            <a href=" #" onClick={this.showAddNew} className="f4">
              Add New
            </a>
          </div>
        ) : (
          <form onSubmit={this.add}>
            <div>
              <label className="db b">Company Name</label>
              <input type="text" onChange={this.onChange} name="company" />
            </div>
            <div>
              <label className="db b">Company URL</label>
              <input type="text" onChange={this.onChange} name="tld" />
            </div>
            <div>
              <label className="db b">Keywords</label>
              <input type="text" onChange={this.onChange} name="terms" />
            </div>
            <div className="flex">
              <label className="db b mr3">
                <input
                  type="checkbox"
                  checked={paid}
                  name="paid"
                  onChange={this.onChange}
                />{" "}
                Paid
              </label>
              <label className="db b mr3">
                <input
                  type="checkbox"
                  checked={coupon}
                  name="coupon"
                  onChange={this.onChange}
                />{" "}
                Free Trial
              </label>
            </div>
            <div className="pb3">
              <select name="sub" value={sub} onChange={this.onChange}>
                <option>--- Pick a Subscription ---</option>
                {plans.map(plan => (
                  <option value={plan.price_data.id}>
                    {formatStripePrice(plan.price_data.unit_amount)} -{" "}
                    {plan.name} - {plan.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="pb3">
              {this.state.file ? (
                <>
                  {this.state.file}
                  <a href=" #" onClick={e => this.setState({ file: null })}>
                    change
                  </a>
                </>
              ) : (
                <>
                  <b>Upload CSV </b>
                  <UploadCSV callback={this.setFile} user={this.props.user} />
                </>
              )}
            </div>
            <div className="pb3">
              <label className="db b">Keyword Limit</label>
              <input type="text" onChange={this.onChange} name="limit" />
            </div>
            {saving ? (
              <>Saving&hellip;</>
            ) : (
              <input
                type="submit"
                className="ui-button primary mb3"
                value="Create Customer"
              />
            )}
          </form>
        )}
        {sem_customers.map(c => {
          return (
            <form className="mb3" onSubmit={this.edit} key={c.id}>
              <div>
                <b>
                  {c.company} (created {c.ctime})
                  {editing !== c.id && (
                    <a href=" #" onClick={this.doEdit(c.id)} className="ml3">
                      edit
                    </a>
                  )}
                </b>
              </div>
              <div>
                {editing === c.id ? (
                  <div className="flex">
                    <label className="db b mr3">
                      <input
                        type="checkbox"
                        checked={paid}
                        name="paid"
                        onChange={this.onChange}
                      />{" "}
                      Paid
                    </label>
                    <label className="db b mr3">
                      <input
                        type="checkbox"
                        checked={coupon}
                        name="coupon"
                        onChange={this.onChange}
                      />{" "}
                      Free Trial
                    </label>
                  </div>
                ) : (
                  <>
                    <div className="b">Paid: {c.paid ? "YES" : "NO"}</div>
                    <div className="b">
                      Free Trial: {c.coupon ? "YES" : "NO"}
                    </div>
                  </>
                )}
                {!c.paid && (
                  <>
                    <a href={`https://sem.dowhatworks.io/signup?id=${c.id}`}>
                      Signup Link
                    </a>{" "}
                    &middot;{" "}
                  </>
                )}
                <a href={`https://sem.dowhatworks.io/sem?id=${c.id}`}>
                  See Dashboard
                </a>
              </div>
              {editing === c.id && (
                <>
                  <div className="pb3">
                    <label className="db b">Keyword Limit</label>
                    <input type="text" onChange={this.onChange} name="limit" />
                  </div>
                  <div className="pb3">
                    {this.state.file ? (
                      <>
                        {this.state.file}
                        <a
                          href=" #"
                          onClick={e => this.setState({ file: null })}
                        >
                          change
                        </a>
                      </>
                    ) : (
                      <>
                        <b>Upload CSV </b>
                        <UploadCSV
                          callback={this.setFile}
                          user={this.props.user}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              <div>
                {editing === c.id ? (
                  <select name="sub" value={sub} onChange={this.onChange}>
                    <option>--- Pick a Subscription ---</option>
                    {plans.map(plan => (
                      <option value={plan.price_data.id}>
                        {formatStripePrice(plan.price_data.unit_amount)} -{" "}
                        {plan.name} - {plan.description}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div>{c.sub}</div>
                )}
              </div>
              {editing === c.id && (
                <div>
                  <div className="b">Send Email</div>
                  <label for="monday" className="db">
                    <input
                      type="radio"
                      name="report-delivery"
                      value="monday"
                      id="monday"
                      className="mr2"
                      checked={
                        this.state.reportDelivery
                          ? this.state.reportDelivery.day === "monday"
                          : true
                      }
                      onChange={this.changeReportDelivery}
                    />
                    First monday of the month
                  </label>
                  <label for="day" className="db">
                    <input
                      type="radio"
                      name="report-delivery"
                      value="day"
                      id="day"
                      className="mr2"
                      checked={
                        this.state.reportDelivery
                          ? this.state.reportDelivery.day === "day"
                          : false
                      }
                      onChange={this.changeReportDelivery}
                    />
                    A specific day each month{" "}
                    <input
                      type="number"
                      name="day-value"
                      defaultValue={
                        this.state.reportDelivery
                          ? this.state.reportDelivery.date
                          : undefined
                      }
                      onChange={this.changeReportDeliveryDate}
                    />
                  </label>
                </div>
              )}
              {c.status === "onboarding" && c.onboarding_status < 3 ? (
                <div>
                  <label className="b">
                    Status: Onboarding Step: {c.onboarding_status + 1}
                  </label>
                </div>
              ) : (
                <div>
                  <label className="b">Status: {c.status}</label>
                </div>
              )}
              <div>
                <b>Domains: </b> {c.tld.join(", ")}
              </div>
              {editing === c.id && (
                <input
                  type="submit"
                  className="ui-button primary"
                  value="Save"
                />
              )}
            </form>
          );
        })}
      </div>
    );
  }
}

export default Companies;
