import React from "react";
import { Link } from "react-router-dom";
import * as querystring from "query-string";
import * as cx from "classnames";
import * as cloneDeep from "lodash.clonedeep";
import request from "request";
import "./dashboard.css";
import {
  loginLookup,
  getData,
  baseApiUrl,
  addCommas,
  isDemoComp,
} from "../../util";
import FakeDashboard from "../../components/FakeDashboard";
import DashboardHeader from "../../components/DashboardHeader";
import InfoPopup from "../../components/InfoPopup";
import AccessDenied from "../../components/AccessDenied";
import MovieFone from "../../components/MovieFone";
import TagFilter from "../../components/TagFilter";
import KeywordGroups from "../../components/KeywordGroups";
import CompaniesIcon from "../../icons/companies_gray.svg";
import GroupsIcon from "../../icons/keywords_gray.svg";
import KeywordIcon from "../../icons/keyword.svg";
import BlueArrowIcon from "../../icons/blue_arrow.svg";
import DownloadIcon from "../../icons/Download.svg";
import X from "../../icons/tinyX.svg";
import Help from "../../icons/old/help_outlined.svg";
import XIcon from "../../icons/x.svg";
import HeadlineImg from "../../img/headline.png";
import Modal from "@material-ui/core/Modal";
import UploadCSV from "../../components/form/UploadCSV";

class SemDashboard extends React.Component {
  qs = null;
  getCachedQS = arg => {
    if (this.qs === null) {
      this.qs = querystring.parse(window.location.search);
    }
    if (arg) {
      if (this.qs[arg]) {
        return this.qs[arg].split("|");
      }
      return undefined;
    }
    return this.qs;
  };
  did_hash = false;
  state = {
    loading: true,
    csv_loading: false,
    counts: undefined,
    percents: [],
    hint: false,
    openModal: false,
    openEditModal: false,
    editSuccess: false,
    show_all_ab: false,
    show_all_sem: false,
    saved_tests: [],
    first: false,
    sem_tlds: [],
    sem_filter_tlds: this.getCachedQS("companies") || [],
    chunks: [],
    keywords: [],
    kw_groups: [],
    kws_filter: this.getCachedQS("keywords") || [],
    edit_priority: this.getCachedQS().editPriority || false,
    comp_lookup: {},
    total_sem_count: 0,
    total_sem_test_count: 0,
    total_unique_sem_count: 0,
    start: 0,
  };
  inputRef = React.createRef();
  foundFirst = false;
  triggerFoundTwice = false;
  canvasOptions = {};
  addStorage = () => {
    const { sem_filter_tlds, kws_filter } = this.state;

    const encodeAndJoin = arr =>
      arr
        .filter(a => a)
        .map(a => encodeURIComponent(a))
        .join("|");
    const urlArr = [];

    if (sem_filter_tlds && sem_filter_tlds.length > 0) {
      urlArr.push(`companies=${encodeAndJoin(sem_filter_tlds)}`);
    }

    if (kws_filter && kws_filter.length > 0) {
      urlArr.push(`keywords=${encodeAndJoin(kws_filter)}`);
    }
    const urlParams = urlArr.length > 0 ? `?${urlArr.join("&")}` : "";
    if (urlParams !== this.state.urlParams) {
      this.setState({ urlParams }, () => {
        this.props.history.push(
          `/home/${this.props.match.params.company}/sem${urlParams}`
        );
      });
    } else {
      this.props.history.push(
        `/home/${this.props.match.params.company}/sem${urlParams}`
      );
    }
  };
  filterSemByTld = data => {
    const { sem_filter_tlds, kws_filter } = this.state;
    let sem_data = data;
    if (sem_data) {
      if (sem_filter_tlds.length > 0) {
        sem_data = sem_data.filter(d => sem_filter_tlds.includes(d.tld));
      }
      if (kws_filter.length > 0) {
        sem_data = sem_data.filter(d => kws_filter.includes(d.keyword));
      }
    }
    return sem_data;
  };
  filterSemByTldChunks = () => {
    const { sem_filter_tlds, kws_filter, chunks, keywords } = this.state;
    const cChunks = cloneDeep(chunks);
    if (sem_filter_tlds.length === 0 && kws_filter.length === 0) {
      return { filtered_chunks: cChunks, count: this.state.total_count };
    }
    let count = 0;
    const filtered_terms =
      kws_filter && kws_filter.length === 0
        ? keywords
        : keywords.filter(t => kws_filter.includes(t));
    let filtered_chunks = filtered_terms
      ? cChunks.filter(c => filtered_terms.includes(c.term))
      : cChunks.filter(c => true);

    if (sem_filter_tlds.length === 0) {
      filtered_chunks.forEach(chunk => {
        Object.keys(chunk.data).forEach(c => {
          count += chunk.data[c].data.count;
        });
      });
    } else {
      filtered_chunks = filtered_chunks.filter(chunk => {
        const companies = Object.keys(chunk.data);
        const filtered_companies = companies.filter(c =>
          sem_filter_tlds.includes(chunk.data[c].data.company)
        );
        if (filtered_companies.length === 0) {
          return false;
        }
        const comps = {};
        filtered_companies.forEach(c => {
          count += chunk.data[c].data.count;
          comps[c] = chunk.data[c];
        });
        chunk.data = comps;
        return true;
      });
    }

    return { filtered_chunks: filtered_chunks, count };
  };
  addSemFilter = values => {
    const sem_filter_tlds = values.map(v => v.value);
    const { id } = querystring.parse(window.location.search);
    const report = id ? id : loginLookup[this.props.match.params.company];
    if (this.props.user.token) {
      request({
        url: `${baseApiUrl}/v1/t?url=${
          window.location.href
        }%3Fsem_companies=${sem_filter_tlds.join("%26")}`,
        headers: {
          Authorization: this.props.user.token,
        },
      });
    }
    this.setState(
      {
        sem_filter_tlds,
      },
      () => {
        this.addStorage(`${report}-dashboard-sem-company`, values);
      }
    );
  };
  makeAdGroupFilter = values => {
    const kws = [];

    values.forEach(v => {
      if (v.group) {
        v.value.forEach(k => {
          if (!kws.includes(k)) {
            kws.push(k);
          }
        });
      } else {
        kws.push(v.value);
      }
    });

    return kws;
  };
  addAdGroupFilter = values => {
    const report = this.state.reportId;
    const kws = this.makeAdGroupFilter(values);

    this.setState(
      {
        kws_filter: kws,
        init_kws_filter: values,
      },
      () => {
        this.addStorage(`${report}-dashboard-sem-kws`, values);
      }
    );
  };
  doHash = () => {
    if (this.state.loading) {
      return;
    }
    window.setTimeout(() => {
      if (window.location.hash) {
        const elm = document.getElementById(window.location.hash.split("#")[1]);
        if (elm) {
          window.scrollTo(0, elm.offsetTop - 90);
        }
      }
      this.did_hash = true;
    }, 200);
  };
  getData = shouldSkipCache => {
    const { id, newCall, byMonth } = querystring.parse(window.location.search);
    let report = null;

    if (id) {
      report = id;
    }
    const apiUrl = `${baseApiUrl}/v1/report?byModule=false${
      report ? `&report=${report}` : ""
    }&company=${
      this.props.match.params.company
    }&returnData=sem_new&newSem=true&newDash=true&doSavedTests=true${
      newCall ? "&newCall=true" : ""
    }`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      false,
      true,
      shouldSkipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 400) {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          this.props.history.push(
            `/subscribe/${this.props.match.params.company}`
          );
          return;
        }
        this.setState({
          loading: true,
          authed: false,
          accessDenied: res.statusCode === 400,
        });
        return;
      }

      const kws = Object.keys(data.chunks || {});

      const tlds = this.getCachedQS("companies");
      const keywords = this.getCachedQS("keywords");

      let init_sem_filter_tlds;
      let init_kws_filter;

      const semtlds = data.companies.map(s => ({
        label: s.name,
        value: s.tld,
      }));
      if (tlds) {
        init_sem_filter_tlds = semtlds.filter(x => tlds.includes(x.value));
      }

      if (keywords) {
        init_kws_filter = keywords.map(k => ({ label: k, value: k }));
      }

      let sortedTerms = Object.keys(data.chunks || {});

      sortedTerms = sortedTerms.filter(
        s => Object.keys(data.chunks[s]).length > 0
      );

      sortedTerms.sort((a, b) =>
        Object.keys(data.chunks[a]).length >= Object.keys(data.chunks[b]).length
          ? -1
          : 1
      );

      const sortedChunks = [];

      let count = 0;

      sortedTerms.forEach(t => {
        const sortedComps = Object.keys(data.chunks[t]);
        sortedComps.sort((a, b) => {
          if (
            data.chunks[t][a].data.low_volume &&
            !data.chunks[t][b].data.low_volume
          ) {
            return 1;
          }
          if (
            !data.chunks[t][a].data.low_volume &&
            data.chunks[t][b].data.low_volume
          ) {
            return -1;
          }
          return data.chunks[t][a].data.count <= data.chunks[t][b].data.count
            ? 1
            : -1;
        });
        data.chunks[t] = sortedComps.map(c => {
          return data.chunks[t][c];
        });
        sortedChunks.push({ term: t, data: data.chunks[t] });
        Object.keys(data.chunks[t]).forEach(c => {
          count += data.chunks[t][c].data.count;
        });
      });

      this.setState({
        loading: false,
        init_sem_filter_tlds: init_sem_filter_tlds || [],
        init_kws_filter: init_kws_filter || [],
        saved_tests: data.saved_tests,
        reportId: id ? id : data.report.id,
        report: report,
        counts: data.counts,
        data: data.data,
        customer: data.customer,
        comp_lookup: data.comp_lookup,
        editSuccess: false,
        openEditModal: false,
        chunks: sortedChunks,
        total_sem_count: data.total_sem_count,
        total_unique_sem_count:
          data.total_unique_sem_count || data.total_sem_count,
        sem_tlds: semtlds,
        keywords: kws,
        byMonth: byMonth === "true",
        total_count: count,
      });
    });
  };
  showAllSem = e => {
    e.preventDefault();
    this.setState({
      show_all_sem: true,
    });
  };
  showModal = e => {
    e.preventDefault();
    this.setState({ modal: true });
  };
  closeModal = e => {
    e.preventDefault();
    this.setState({ modal: false });
  };
  closeEditModal = e => {
    this.setState({ openEditModal: false });
  };
  componentDidMount() {
    this.getData();
  }
  doCSVDownload = type => e => {
    e.preventDefault();
    const company = this.props.match.params.company;
    this.setState({ csv_loading: true });
    request({
      url: `${baseApiUrl}/v1/t?url=${window.location.href}%2Fcsv_download`,
      headers: {
        Authorization: this.props.user.token,
      },
    });
    request(
      {
        url: `${baseApiUrl}/v1/sem_csv?company=${company}&report=${
          company === "admin" ? "admin" : this.state.reportId
        }`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);
        window.open(json.csv);

        this.setState({
          csv_loading: false,
        });
      }
    );
  };
  doBFDownload = type => e => {
    e.preventDefault();
    const company = this.props.match.params.company;
    this.setState({ bf_csv_loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/get_bf_csv?customer=${company}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);
        window.open(json.csv);

        this.setState({
          bf_csv_loading: false,
        });
      }
    );
  };
  removeTag = f => e => {
    e.preventDefault();
    const { sem_filter_tlds, kws_filter, init_kws_filter } = this.state;
    if (f.type === "company") {
      const new_sem_filter_tlds = sem_filter_tlds.filter(c => c !== f.value);

      this.setState(
        {
          sem_filter_tlds: new_sem_filter_tlds,
          init_sem_filter_tlds: new_sem_filter_tlds,
        },
        () => {
          this.addStorage();
        }
      );
    }
    if (f.type === "keyword") {
      const new_kws_filter = kws_filter.filter(c => c !== f.value);

      this.setState(
        {
          kws_filter: new_kws_filter,
          init_kws_filter: new_kws_filter.map(kw => ({ label: kw, value: kw })),
        },
        () => {
          this.addStorage();
        }
      );
    }
    if (f.type === "group") {
      const new_kws_filter = kws_filter.filter(c => !f.value.includes(c));
      const new_init_kws_filter = init_kws_filter.filter(c => c.id !== f.id);

      this.setState(
        {
          kws_filter: new_kws_filter,
          init_kws_filter: new_init_kws_filter,
        },
        () => {
          this.addStorage();
        }
      );
    }
  };

  onUpload = files => {
    console.info(files);
    request(
      {
        url: `${baseApiUrl}/v1/sem/parse_csv?company=${this.props.match.params.company}&file=${files[0]}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        this.setState({
          loading: false,
        });
        if (err || res.statusCode >= 400) {
          alert("Something went wrong!");
          return;
        }

        alert(
          "CSV was successfully uploaded! Refresh this page in a few minutes."
        );
      }
    );
  };

  render() {
    const {
      loading,
      sem_activity,
      sem_phrases,
      show_all_sem,
      reportId,
      total_unique_sem_count,
    } = this.state;

    const { filtered_chunks, count } = this.filterSemByTldChunks();

    const displayFilters = [];
    this.state.sem_filter_tlds.forEach(tld => {
      const company = this.state.sem_tlds.find(c => c.value === tld);
      if (company) {
        company.type = "company";
        displayFilters.push(company);
      }
    });
    if (this.state.init_kws_filter) {
      this.state.init_kws_filter.forEach(kw => {
        if (kw.group) {
          displayFilters.push({
            label: kw.label,
            value: kw.value,
            type: "group",
            id: kw.id,
          });
        } else {
          displayFilters.push({
            label: kw.label,
            value: kw.value,
            type: "keyword",
          });
        }
      });
    }

    if (loading) {
      return (
        <>
          <FakeDashboard company={this.props.match.params.company} isSEM />
          {this.state.accessDenied && <AccessDenied />}
        </>
      );
    }
    return (
      <div className="mb4">
        <DashboardHeader
          customer={this.props.match.params.company}
          name={this.state.customer ? this.state.customer.name : null}
          customerData={this.state.customer}
          user={this.props.user}
          selected="sem"
        />
        <div
          className="header-shadow"
          style={{ position: "sticky", top: "65px", background: "#fff" }}
        >
          <div className="pl3 b--dww filter-container omnifilter-container document-spacing center">
            <div className="flex">
              <div style={{ paddingTop: "10px", flexGrow: 1 }}>
                Viewing{" "}
                <span className="pill-purple b">{addCommas(count)}</span>{" "}
                headlines
              </div>
              <div className="pr1">
                <TagFilter
                  options={this.state.sem_tlds.filter(x => x && x.label)}
                  defaultOptions={this.state.init_sem_filter_tlds}
                  icon={CompaniesIcon}
                  title="Companies"
                  callback={this.addSemFilter}
                  onRight
                  fixAt={{ width: 300, right: -40, top: 32 }}
                  isSticky
                  small
                  title_slug="companies"
                />
              </div>
              <div>
                {this.state.keywords && (
                  <KeywordGroups
                    keywords={this.state.keywords}
                    callback={this.addAdGroupFilter}
                    token={this.props.user.token}
                    options={this.state.kw_groups}
                    defaultOptions={this.state.init_kws_filter}
                    reportId={this.state.reportId}
                    isAdmin={this.props.user.company === "admin"}
                    editPriority={this.state.edit_priority}
                  />
                )}
              </div>
            </div>
          </div>
          {displayFilters && displayFilters.length > 0 && (
            <div
              className="ph3 b--dww filter-container omnifilter-container center"
              style={{ borderTop: "1px solid #ccced8" }}
            >
              <div className="center flex pl3 pr2 document-spacing">
                {displayFilters.slice(0, 5).map(f => {
                  let icon;

                  if (f.type === "company") {
                    icon = CompaniesIcon;
                  } else if (f.type === "keyword") {
                    icon = KeywordIcon;
                  } else if (f.type === "group") {
                    icon = GroupsIcon;
                  }
                  return (
                    <div
                      className="f-tag"
                      key={`${f.type}-${f.id ? f.id : f.value}`}
                    >
                      <span className="f-tag-x" onClick={this.removeTag(f)}>
                        <img src={X} alt="remove" />
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        title={f.label}
                      >
                        {f.label}
                      </span>
                      {icon && (
                        <img src={icon} className="f-tag-icon" alt={f.type} />
                      )}
                    </div>
                  );
                })}
                {displayFilters.length > 5 && (
                  <div className="f-tag f-tag-more">
                    +{displayFilters.length - 5} more
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt4 document-spacing center">
          <div
            className="mt4 box pb2"
            style={{ minHeight: "600px" }}
            id="winning-headlines"
          >
            <div className="bb b--dww pv2 ph3">
              <div className="pv2 dashbox-pad flex">
                <div>
                  <span className="f3 dash-head mb3">
                    Winners & Losers Report (SEM)
                    <>
                      {this.state.csv_loading ? (
                        <span className="gray ml2 f7">Downloading&hellip;</span>
                      ) : (
                        <a
                          href=" #"
                          className="ml2 f7 ttu"
                          onClick={this.doCSVDownload("sem")}
                        >
                          <img
                            src={DownloadIcon}
                            alt="Download"
                            title="Download a CSV of the full report."
                            style={{ height: "20px", marginBottom: "7px" }}
                          />
                        </a>
                      )}
                      {this.props.user.company === "admin" && (
                        <UploadCSV
                          callback={this.onUpload}
                          user={this.props.user}
                        />
                      )}
                    </>
                  </span>
                  <div className="f5 dww-gray">
                    Top performing search marketing headlines tested this month.{" "}
                    Most powerful concepts in{" "}
                    <span style={{ color: "#19a974" }}>green</span>.
                  </div>
                </div>
                <div className="pt3 tr" style={{ flexGrow: "1" }}>
                  <a href=" #" onClick={this.showModal}>
                    <img src={Help} />
                  </a>
                </div>
              </div>
            </div>
            {filtered_chunks && (
              <div className="ma3">
                {filtered_chunks.map((obj, z) => {
                  let displayTerm = obj.term;
                  if (obj.term.includes("||")) {
                    const termArr = obj.term.split("||");
                    displayTerm = `${termArr[0]} (${termArr[1]})`;
                  }
                  return (
                    <div className="ph2 mb4" key={`obj-${z}`}>
                      <h3
                        className="f3 dash-head"
                        style={{ textTransform: "capitalize" }}
                      >
                        <span className="q lq">&ldquo;</span>
                        {displayTerm}
                        <span className="q rq">&rdquo;</span>
                      </h3>
                      <div
                        className="flex scroller"
                        style={{ overflow: "auto" }}
                      >
                        {Object.keys(obj.data).map(comp => {
                          if (!obj.data[comp].data) {
                            return <></>;
                          }
                          const compData = obj.data[comp].data;
                          let compLink = `/home/${
                            this.props.match.params.company
                          }/sem_2/${compData.company}/${encodeURIComponent(
                            obj.term
                          )}`;

                          if (compData.company.indexOf("#") !== -1) {
                            const tldArr = compData.company.split("#");
                            compLink = `/home/${
                              this.props.match.params.company
                            }/sem_2/${tldArr[0]}/${encodeURIComponent(
                              obj.term
                            )}?location=${tldArr[1]}`;
                          }

                          return (
                            <Link
                              className="mr3 mb3 box ui-link db"
                              style={{ minWidth: "350px", width: "350px" }}
                              to={compLink}
                              key={`obj-${z}-${comp}`}
                            >
                              <div className="pt3 pr3 pl3 f4">
                                {compData.name}
                              </div>
                              {obj.data[comp].chunks.map((hl, i) => (
                                <div
                                  className={cx({
                                    "pa3 flex": true,
                                    "bg-stripe": i % 2 !== 0,
                                  })}
                                  key={`obj-${z}-${comp}-${i}`}
                                >
                                  <div>
                                    {hl.low_volume ? (
                                      <div className="place-circle bg-black-30 white items-center">
                                        <div className="place-circle-number">
                                          &nbsp;
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {hl.status === "best" && (
                                          <div className="place-circle bg-green white items-center">
                                            <div className="place-circle-number">
                                              {hl.place || i + 1}
                                            </div>
                                          </div>
                                        )}
                                        {hl.status === "ok" && (
                                          <div className="place-circle bg-light-green white items-center">
                                            <div className="place-circle-number">
                                              {hl.place}
                                            </div>
                                          </div>
                                        )}
                                        {hl.status === "borderline" && (
                                          <div className="place-circle bg-yellow white items-center">
                                            <div className="place-circle-number">
                                              {hl.place}
                                            </div>
                                          </div>
                                        )}
                                        {(hl.status === "worst" ||
                                          hl.status === "bad") && (
                                          <div className="place-circle bg-dark-red white items-center">
                                            <div className="place-circle-number">
                                              {hl.place}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="pl3">{hl.text}</div>
                                </div>
                              ))}
                              {obj.data[comp].data.low_volume && (
                                <div className="pv3 ph3 tc">
                                  <span className="ui-link db center dww-gray f7">
                                    Ad volume is too low to rank.
                                  </span>
                                </div>
                              )}
                              {!obj.data[comp].data.low_volume &&
                                obj.data[comp].data.count &&
                                obj.data[comp].data.count > 6 && (
                                  <div className="f7 pa2 tc">
                                    <span className="ui-link db center dww-gray">
                                      +
                                      {obj.data[comp].data.count -
                                        obj.data[comp].chunks.length}{" "}
                                      more
                                    </span>
                                  </div>
                                )}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <MovieFone
          user={this.props.user}
          company={this.props.match.params.company}
        />
        {this.state.modal && (
          <Modal open={true}>
            <div
              className="box"
              style={{
                width: `40vw`,
                top: "10vh",
                position: "absolute",
                left: "30vw",
                overflow: "auto",
              }}
            >
              <img
                src={XIcon}
                className="pointer modal-close"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                }}
                onClick={this.closeModal}
              />
              <div className="pa3 bb b--black-05">
                <div className="f3 dash-head">How does this work?</div>
              </div>
              <div className="pa3">
                <p>Search ads are made up of 2 to 3 headlines.</p>
                <div className="tc pb3">
                  <img
                    src={HeadlineImg}
                    style={{ width: "80%" }}
                    className="br4"
                  />
                </div>
                <p>
                  Headlines are ranked by likelihood to resonate by keyword.
                </p>
                <p>
                  Use the top headlines for inspiration when writing ad copy and
                  product headlines.{" "}
                </p>

                <div className="tc mt4">
                  <a
                    href=" #"
                    className="ui-button primary"
                    onClick={this.closeModal}
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default SemDashboard;
