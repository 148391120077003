import React from "react";
import request from "request";
import Modal from "@material-ui/core/Modal";
import SadComputer from "../icons/old/sadpage.svg";
import { baseApiUrl } from "../util";

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {}

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const width = 600;
    const height = this.props.awsError ? 360 : 300;

    if (!this.state.error && !this.props.awsError) {
      return this.props.children;
    }
    if (!this.props.awsError && !document.hidden) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          url: `${baseApiUrl}/v1/error`,
          form: {
            error: this.state.error.stack,
            url: window.location.href,
            user: this.props.user ? this.props.user.id : null,
            browser: window.navigator.userAgent,
          },
        },
        (err, resp) => {}
      );
    }

    return (
      <>
        <Modal open={true}>
          <div
            className="box tc"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              overflow: "hidden",
              top: (window.innerHeight - height) / 2,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
              outline: 0,
            }}
          >
            <div className="pa4">
              {this.props.awsError ? (
                <>
                  <img
                    src={SadComputer}
                    style={{ width: "60px" }}
                    alt="error"
                  />
                  <p className="f3 tc pt2">
                    Looks like we're having trouble connecting to DoWhatWorks'
                    servers!
                  </p>
                  <p className="f3 tc pt4">
                    Please try again in a few minutes, but{" "}
                    <a href="mailto:help@dowhatworks.io">let us know</a> if we
                    can do anything to help in the meantime.
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={SadComputer}
                    style={{ width: "60px" }}
                    alt="error"
                  />
                  <p className="f3 tc pt4">
                    Looks like something went wrong! We were just sent an email
                    and are looking into the problem.
                  </p>
                  <p className="f3 tc">
                    Try refreshing the page, if that doesn't work please{" "}
                    <a href="mailto:help@dowhatworks.io">let us know</a>.
                  </p>
                </>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Error;
