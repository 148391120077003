import React, { useEffect, useState } from "react";
import request from "request";
import Loading from "../../components/Loading";
import EditCompany from "./components/EditCompany";
import TagSearch from "../../components/TagSearch";
import { baseApiUrl, getData, formatDateAndTime, capitalize } from "../../util";
import { Link } from "react-router-dom";
import ToggleOn from "../../icons/ToggleOn.svg";
import ToggleOff from "../../icons/ToggleOff.svg";
import * as cx from "classnames";

const ShareTestRow = props => {
  const { shareTest, index, user } = props;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(shareTest.open);
  const [status, setStatus] = useState(shareTest.status);
  const updateShareTest = (id, isOpen = null, status = null) => {
    if (loading) return;
    setLoading(true);
    let data = {
      shareId: id,
    };
    if (isOpen) data.isOpen = isOpen;
    if (status) data.status = status;
    fetch(`${baseApiUrl}/v1/user/share_test`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: user.token,
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        setLoading(false);
        if (response.ok) {
          if (isOpen !== null) setIsOpen(isOpen);
          if (status !== null) setStatus(status);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <div
      className={cx("w-100 flex justify-between tl pa2", {
        "bg-light-gray": index % 2 == 0,
      })}
    >
      <div className="w-10 tl">
        <Link
          target={"_blank"}
          to={`/view/${shareTest.company}_${shareTest.id}`}
        >
          View Test
        </Link>
      </div>
      <div className="w-30">
        {capitalize((shareTest.user && shareTest.user.name) || "")}
      </div>
      <div className="w-15">{formatDateAndTime(new Date(shareTest.ctime))}</div>
      <div className="w-10 tl">{shareTest.company}</div>
      <div className="w-10 tr">{shareTest.page_views}</div>
      <div className="w-20 tr">
        <img
          src={
            status && status.toLowerCase() === "active" ? ToggleOn : ToggleOff
          }
          className="toggle-icon"
          alt="Toggle off"
          onClick={() =>
            updateShareTest(
              shareTest.id,
              null,
              status === "active" ? "inactive" : "active"
            )
          }
        />
      </div>

      <div className="w-15 tr">
        <img
          src={isOpen ? ToggleOn : ToggleOff}
          className="toggle-icon"
          alt="Toggle off"
          onClick={() => updateShareTest(shareTest.id, !isOpen)}
        />
      </div>
    </div>
  );
};
const ShareTests = props => {
  const { user } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(`${baseApiUrl}/v1/admin/share_tests`, {
      method: "GET",
      headers: {
        Authorization: user.token,
      },
    })
      .then(response => response.json())
      .then(json => {
        setData(json.result);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const ShareTestHeader = (
    <div className="w-100 flex justify-between tl pa2" key={"header-row"}>
      <div className="w-10 tl">Link</div>
      <div className="w-30">Shared By</div>
      <div className="w-15">Date</div>
      <div className="w-10 tl">Tag</div>
      <div className="w-10 tr">Page Views</div>
      <div className="w-20 tr">Deactivate/Activate</div>
      <div className="w-10 tr">Close/Open</div>
    </div>
  );
  return loading && !data ? (
    <Loading />
  ) : (
    <>
      <p className="b f3">Share Test</p>
      <div className="flex flex-column">
        {ShareTestHeader}
        {data &&
          data.map((shareTest, index) => (
            <ShareTestRow
              shareTest={shareTest}
              index={index}
              user={user}
              key={index}
            />
          ))}
      </div>
    </>
  );
};

export default ShareTests;
