import { Component } from "react";
import { withRouter } from "react-router";
import { checkRefresh, useHotjar, useHotjarUrl } from "../util";
import ReactGA from "react-ga4";
import * as qs from "query-string";
ReactGA.initialize("UA-148979071-1");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

let HOTJARLOADED = false;

const loadHotjar = () => {
  if (!HOTJARLOADED) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 4942355, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

    HOTJARLOADED = true;
  }
};

if (useHotjarUrl(window.location.pathname)) {
  loadHotjar();
}

class PageWrapper extends Component {
  componentDidUpdate(prevProps) {
    const prevQS = qs.parse(prevProps.location.search);
    const QS = qs.parse(this.props.location.search);
    let hasChange = false;
    if (
      !window.location.pathname.startsWith("/login") &&
      (this.props.loggedOut ||
        (this.props.company && useHotjar(this.props.company))) &&
      !HOTJARLOADED
    ) {
      loadHotjar();
    }

    if (Object.keys(QS).length !== Object.keys(prevQS).length) {
      hasChange = true;
    } else {
      Object.keys(QS).forEach(q => {
        if (q !== "cache" && prevQS[q] && prevQS[q] !== QS[q]) {
          hasChange = true;
        }
      });
    }
    if (
      (hasChange ||
        this.props.location.pathname !== prevProps.location.pathname) &&
      this.props.location.pathname.indexOf("/screenshots/") === -1 &&
      prevProps.location.pathname.indexOf("/screenshots/") === -1
    ) {
      window.scrollTo(0, 0);
    }
  }
  lastPage = null;
  render() {
    if (checkRefresh && checkRefresh()) {
      window.location.reload();
      return <></>;
    }
    if (
      !window.location.pathname.startsWith("/login") &&
      (this.props.loggedOut ||
        (this.props.company && useHotjar(this.props.company))) &&
      !HOTJARLOADED
    ) {
      loadHotjar();
    }
    const trackParams = {
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    };
    if (!this.lastPage && this.props.company) {
      trackParams.dimension1 = this.props.company;
    }
    this.props.history.listen((location, action) => {
      const newPage = location.pathname + location.search;
      if (this.lastPage !== newPage) {
        this.props.callback(
          location.pathname === "/" || location.pathname === "",
          location.pathname.indexOf("/home/") !== -1 ||
            location.pathname.startsWith("/admin/screenshots/")
        );
        ReactGA.send(trackParams);
        this.lastPage = newPage;
      }
    });
    return this.props.children;
  }
}
export default withRouter(PageWrapper);
