import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { companyNames, hasDiscountDash } from "../util";

const getSelected = () => {
  if (window) {
    const arr = window.location.pathname.split("/");

    let selected = "dashboard";

    if (arr.includes("report")) {
      selected = "report";
    }
    if (arr.includes("sem") || arr.includes("sem_2")) {
      selected = "sem";
    }
    if (arr.includes("discounts")) {
      selected = "discounts";
    }
    return selected;
  }
};

const DashboardHeader = ({
  customer,
  selected,
  reportId,
  name,
  customerData,
  user,
}) => {
  if (!selected) {
    selected = getSelected();
  }
  return (
    <div
      className={cx({
        "company-header": true,
        "header-shadow": selected !== "report" && selected !== "sem",
      })}
    >
      <div className="document-spacing center flex ph1 ph3-ns flex-wrap">
        <div className="w-100-s w-60 flex items-center center-s pv3-s">
          <Link to={`/home/${customer}`} className="f5 b ui-link">
            {name ? (
              <>{name || companyNames[customer]} Dashboard</>
            ) : (
              <>&nbsp;</>
            )}
          </Link>
          {user &&
            user.company === "admin" &&
            customer !== "admin" &&
            customerData &&
            !customerData.demo && (
              <span className="ml2">
                {customerData &&
                customerData.paid &&
                customerData.real &&
                customer !== "admin" ? (
                  <span
                    className="ml1 dib pa1 bg-green br3"
                    title="Paid and Live"
                  ></span>
                ) : (
                  <span className="dib pa1 bg-red br3" title="Unpaid"></span>
                )}
                {customerData && !customerData.real && (
                  <span
                    className="ml1 dib pa1 bg-red br3"
                    title="Not marked real"
                  ></span>
                )}
                {customerData && customerData.freeTrial && (
                  <span
                    className="ml1 dib pa1 bg-yellow br3"
                    title="Free Trial"
                  ></span>
                )}
              </span>
            )}
        </div>
        <div className="w-100-s w-40 tr flex flex-column items-end">
          <div className="tr flex items-end center-s w-100-s">
            {(!name || (customerData && customerData.semOnly)) && (
              <div className="dib ph4 bb bw2 b empty-topnav f7 ttu w-50-s">
                &nbsp;
              </div>
            )}
            {name && customerData && !customerData.semOnly && (
              <>
                <Link
                  to={`/home/${customer}`}
                  className={cx({
                    "t-selected": selected === "dashboard",
                    "dib ph4 bb bw2 b topnav-item f7 ttu w-50-s nowrap": true,
                  })}
                >
                  Overview
                </Link>
                <Link
                  to={`/home/${customer}/report`}
                  className={cx({
                    "t-selected": selected === "report",
                    "dib ph4 bb bw2 b topnav-item f7 ttu w-50-s nowrap": true,
                  })}
                >
                  A/B tests
                </Link>
                {customerData && !customerData.abOnly && (
                  <>
                    {customerData && customerData.newSem ? (
                      <a
                        href="https://sem.dowhatworks.io/sem"
                        className={cx({
                          "t-selected": selected === "sem",
                          "dib ph4 bb bw2 b topnav-item f7 ttu w-50-s nowrap": true,
                        })}
                      >
                        SEM
                      </a>
                    ) : (
                      <Link
                        to={`/home/${customer}/sem`}
                        className={cx({
                          "t-selected": selected === "sem",
                          "dib ph4 bb bw2 b topnav-item f7 ttu w-50-s nowrap": true,
                        })}
                      >
                        SEM
                      </Link>
                    )}
                  </>
                )}
                {hasDiscountDash(customer) && (
                  <Link
                    to={`/home/${customer}/discounts`}
                    className={cx({
                      "t-selected": selected === "discounts",
                      "dib ph4 bb bw2 b topnav-item f7 ttu w-50-s": true,
                    })}
                  >
                    Discounts
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
