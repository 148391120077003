import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl, handleError } from "../../util";

class UserDashboard extends React.Component {
  state = {
    items: [],
    loading: true,
    window: "seven",
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/user_dashboard`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({ ...json, loading: false });
      }
    );
  };
  handleChange = e => {
    const { value } = e.currentTarget;
    this.setState({ window: value });
  };
  render() {
    const { thisMonth, lastMonth } = this.state;

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <h1>Activity Dashboard</h1>
        <div className="mt3 flex box pa3">
          <div className="w-50 pr3">
            <h3>This Month</h3>
            <table>
              {thisMonth.map(customer => {
                return (
                  <tr>
                    <td className="pa2">
                      <b>{customer.name}</b>
                    </td>
                    <td className="pa2">
                      {customer.percentage}% ({customer.visitors}/
                      {customer.total_users} users)
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <div className="w-50">
            <h3>Last Month</h3>
            <table>
              {lastMonth.map(customer => {
                return (
                  <tr>
                    <td className="pa2">
                      <b>{customer.name}</b>
                    </td>
                    <td className="pa2">
                      {customer.percentage}% ({customer.visitors}/
                      {customer.total_users} users)
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDashboard;
