import React, { useState } from "react";
import { getCopyData, copyData } from "../util";
import CopyIcon from "../icons/copy.svg";
import PasteIcon from "../icons/paste.svg";

const CopyPaste = props => {
  let showPaste = false;

  const [pasting, setPasting] = useState(false);
  const [copying, setCopying] = useState(false);

  const cData = getCopyData(props.title);
  if (cData) {
    showPaste = true;
  }
  const doCopy = e => {
    e.preventDefault();
    copyData(props.title, props.options);
    props.copyCallback(options, "copy");
    setCopying(true);
    setTimeout(() => {
      setCopying(false);
    }, 1000);
  };
  const doPaste = e => {
    e.preventDefault();
    const data = getCopyData(props.title);
    if (data) {
      props.copyCallback(data, "paste");
      setPasting(true);
      setTimeout(() => {
        setPasting(false);
      }, 1000);
    }
  };

  const { options } = props;
  return (
    <div className="relative w-10 pt1 pl2">
      {options && options.length > 0 && (
        <a href=" #" onClick={doCopy} className="pt3 mr2" title="Copy this">
          <img src={CopyIcon} width={20} />
        </a>
      )}
      {showPaste && (
        <>
          <a href=" #" onClick={doPaste} className="pt3" title="Paste here">
            <img src={PasteIcon} width={20} />
          </a>
        </>
      )}
      {pasting && (
        <div
          className="absolute bg-white f7 pa1 pv2 br3 green"
          style={{ marginTop: "-24px" }}
        >
          Pasted!
        </div>
      )}
      {copying && (
        <div
          className="absolute bg-white f7 pa1 pv2 br3 green"
          style={{ marginTop: "-24px" }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};

export default CopyPaste;
