import React from "react";
import * as cx from "classnames";
import CreatableSelect from "react-select/creatable";
import {
  baseApiUrl,
  capitalize,
  formatDateAndTime,
  formatReportDate,
} from "../util";
import request from "request";
import TagSearch from "./TagSearch";
import EditableNote, { convertNoteToObj, isNoteEditable } from "./EditableNote";
import { Checkbox } from "@material-ui/core";
import EditIcon from "../icons/edit_blue.svg";
import UndoIcon from "../icons/undo.svg";
import VerifiedBlueIcon from "../icons/verified_blue.svg";
import VerifiedGrayIcon from "../icons/verified_gray.svg";
import OpenLinkIcon from "../icons/open_link.svg";
import XIcon from "../icons/tinyX.svg";
import RobotIcon from "../icons/robot.svg";

const ADMIN_CUSTOMER = { label: "Admin", value: "admin" };

class CreateAnalysis extends React.Component {
  state = {
    analysis_name: this.props.currentAnalysis
      ? this.props.currentAnalysis.name
      : this.props.copyFrom
      ? `copy ${this.props.copyFrom.name}`
      : "",
    analysis_props: this.props.currentAnalysis
      ? this.props.currentAnalysis.analysis_props
      : this.props.copyFrom
      ? this.props.copyFrom.analysis_props
      : [],
    customers: this.props.allCustomers,
    saving: false,
    analysis: this.props.allAnalysis,
    selected_analysis: this.props.copyFrom ? [this.props.copyFrom.id] : null,
    type: this.props.type || "analysis",
    parentType: this.props.parentType || "report",
    isFormValid: true,
    title: this.props.type || "analysis",
    notes: (this.props.currentAnalysis && this.props.currentAnalysis.notes
      ? Array.isArray(this.props.currentAnalysis.notes)
        ? this.props.currentAnalysis.notes
        : [this.props.currentAnalysis.notes]
      : []
    ).map(note => convertNoteToObj(note)),
    selected_customer: null,
    currentNotes: "",
    verified:
      (this.props.currentAnalysis && this.props.currentAnalysis.verified) ||
      false,
    trr:
      this.props.currentAnalysis && this.props.currentAnalysis.trr
        ? this.props.currentAnalysis.trr
        : "",
    editCollectionName: this.props.editing ? false : true,
    editLink: this.props.editing ? false : true,
    editVerified: this.props.editing ? false : true,
    editAnalysisProperties: this.props.editing ? false : true,
    addingNote: false,
    editAll:
      !this.props.parentType ||
      (this.props.parentType && this.props.parentType === "report")
        ? true
        : false,
    selected_property_name:
      this.props.currentAnalysis &&
      this.props.currentAnalysis.selected_property_name
        ? this.props.currentAnalysis.selected_property_name
        : false,
    selected_property:
      this.props.currentAnalysis && this.props.currentAnalysis.selected_property
        ? this.props.currentAnalysis.selected_property
        : false,
    selected_bet_score:
      this.props.currentAnalysis &&
      this.props.currentAnalysis.selected_bet_score
        ? this.props.currentAnalysis.selected_bet_score
        : false,
    analysis_result:
      this.props.currentAnalysis && this.props.currentAnalysis.analysis_result
        ? this.props.currentAnalysis.analysis_result.data
        : false,
    savingSelectedBetScore: false,
    tags:
      this.props.currentAnalysis && this.props.currentAnalysis.tags
        ? this.props.currentAnalysis.tags
        : [],
    allTags: this.props.allTags
      ? this.props.allTags.map(tag => ({
          label: capitalize(tag),
          value: tag,
        }))
      : [],
    newTags: [],
    showAddTag: this.props.editing ? false : true,
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  isInputValid = () => {
    const { analysis_props, analysis_name, selected_analysis } = this.state;
    return (
      analysis_name.trim() !== "" &&
      (this.props.type === "analysis"
        ? analysis_props.length !== 0 &&
          analysis_props.every(record => record.name.trim() !== "")
        : true)
    );
  };
  setAddingNote = () => {
    this.setState({ addingNote: true });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (!this.isInputValid()) {
      this.setState({
        isFormValid: false,
      });
      return false;
    }
    const {
      analysis_props,
      analysis_name,
      selected_analysis,
      notes,
      selected_customer,
      currentNotes,
      verified,
      trr,
      selected_property,
      tags,
      newTags,
    } = this.state;
    this.setState({ saving: true });
    const finalTags = [];
    finalTags.push(...tags);
    newTags.forEach(newTag => {
      finalTags.push({
        tag: newTag.value,
        autoGenerated: false,
        isNew: newTag.__isNew__ || false,
      });
    });
    if (this.props.editing) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/admin/analysis/edit`,
          form: {
            data: JSON.stringify({
              id: this.props.currentAnalysis.id,
              analysis_name,
              analysis_props: analysis_props
                ? analysis_props.map(({ name, key }) => {
                    return { name, key };
                  })
                : analysis_props,
              selected_analysis,
              notes: JSON.stringify(notes),
              currentNotes,
              verified,
              trr,
              selected_property: selected_property || "",
              tags: finalTags,
            }),
          },
        },
        (err, data) => {
          if (err) {
            this.setState({
              error: true,
              saving: false,
            });
            return;
          }
          if (!err) {
            const json = JSON.parse(data.body);
            if (json.error) {
              this.setState({
                error: true,
                saving: false,
              });
              return;
            }
            this.props.doClose(true, "save", {
              id: this.props.currentAnalysis.id,
              analysis_name,
              analysis_props: analysis_props
                ? analysis_props.map(({ name, key }) => {
                    return { name, key };
                  })
                : analysis_props,
              selected_analysis,
              notes: [...notes, currentNotes],
            });
          }
        }
      );
    } else {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/admin/analysis`,
          form: {
            data: JSON.stringify({
              analysis_name,
              analysis_props: analysis_props
                ? analysis_props.map(({ name, key }) => {
                    return { name, key };
                  })
                : analysis_props,
              selected_analysis,
              notes: [...notes, currentNotes],
              selected_customer:
                this.props.type === "collection" ? selected_customer : null,
              verified,
              trr,
              tags: finalTags,
            }),
          },
        },
        (err, data) => {
          if (err) {
            this.setState({
              error: true,
              saving: false,
            });
            return;
          } else {
            const json = JSON.parse(data.body);
            if (json.error) {
              this.setState({
                error: true,
                saving: false,
              });
              return;
            }
            this.props.doClose(false, "save", {
              analysis_name,
              analysis_props,
              selected_analysis,
              notes: [...notes, currentNotes],
            });
          }
        }
      );
    }
  };

  add = e => {
    e.preventDefault();
    const { analysis_props } = this.state;
    analysis_props.push({ name: "", key: "", new: true });
    this.setState({ analysis_props });
  };
  remove = i => e => {
    const { analysis_props } = this.state;

    const new_props = analysis_props.filter((a, z) => z !== i);

    this.setState({ analysis_props: new_props });
  };
  changeName = i => e => {
    e.preventDefault();
    const { analysis_props } = this.state;
    const key = e.currentTarget.value
      .toLowerCase()
      .replace(/[^A-z|0-9]/gi, "_");
    const updatedAnalysisProps = analysis_props.map((prop, index) => {
      if (i === index) {
        return {
          ...prop,
          name: e.currentTarget.value,
          key: !prop.new ? analysis_props[i].key : key,
        };
      } else {
        return prop;
      }
    });
    updatedAnalysisProps.forEach(element => {
      console.log({ element });
    });
    this.setState({ analysis_props: updatedAnalysisProps });
  };
  onChangeAnalysis = vals => {
    this.setState({ selected_analysis: vals });
  };
  onVerifiedChange = e => {
    this.setState({ verified: e.target.checked });
  };
  onSelectCustomer = vals => {
    this.setState({ selected_customer: vals });
  };
  addNotesV2 = (note, index) => {
    const notes = this.state.notes;
    if (notes.length > index) {
      notes[index] = {
        ...note,
        edited: true,
      };
    }
    this.setState({
      notes: notes,
    });
  };
  setNameEditable = editable => e => {
    this.setState({
      editCollectionName: editable,
    });
  };
  undoEditedName = e => {
    this.setState({
      analysis_name: this.props.currentAnalysis
        ? this.props.currentAnalysis.name
        : this.props.copyFrom
        ? `copy ${this.props.copyFrom.name}`
        : "",
      editCollectionName: false,
    });
  };
  setLinkEditable = editable => e => {
    this.setState({
      editLink: editable,
    });
  };
  undoEditLink = e => {
    this.setState({
      trr:
        this.props.currentAnalysis && this.props.currentAnalysis.trr
          ? `${this.props.currentAnalysis.trr}`
          : "",
      editLink: false,
    });
  };
  undoAnalysisProperties = e => {
    const analysisProps = this.props.currentAnalysis
      ? this.props.currentAnalysis.analysis_props
      : this.props.copyFrom
      ? this.props.copyFrom.analysis_props
      : [];
    this.setState({
      analysis_props: analysisProps,
      editAnalysisProperties: false,
    });
  };
  setEditable =
    (editable, name = "") =>
    e => {
      if (!name) {
        return;
      }
      this.setState({
        [name]: editable,
      });
    };
  onChangeSelectedBetScore = (id, betScore, property) => e => {
    const isSelected = e.target.checked;

    this.setState({
      selected_property: isSelected ? property : null,
    });
  };
  onChangeAddTag = (values, selectAction) => {
    this.setState({
      newTags: values,
    });
  };
  onDeleteExistingTag = tagValue => e => {
    const updatedTags = this.state.tags.map(tag => {
      if (tag.tag === tagValue) {
        return {
          ...tag,
          isDeleted: true,
        };
      } else {
        return tag;
      }
    });
    this.setState({
      tags: updatedTags,
    });
    console.log(`deleting ${tagValue}`);
  };
  toggleShowAddTag = e => {
    e.preventDefault();
    this.setState({
      showAddTag: !this.state.showAddTag,
    });
  };
  render() {
    const {
      analysis_props,
      analysis_name,
      saving,
      isFormValid,
      title,
      notes,
      currentNotes,
      verified,
      trr,
      editCollectionName,
      editLink,
      editVerified,
      editAnalysisProperties,
      editAll,
      addingNote,
      selected_bet_score,
      selected_property,
      selected_property_name,
      analysis_result,
      showAddTag,
    } = this.state;

    const [key, skey] = selected_property ? selected_property.split("||") : [];
    const analysisSelectedProperty =
      this.props.currentAnalysis && this.props.currentAnalysis.selected_property
        ? this.props.currentAnalysis.selected_property
        : false;
    const [dbKey, dbSkey] = analysisSelectedProperty
      ? analysisSelectedProperty.split("||")
      : [];
    const selectedAnalysisResult =
      analysis_result && dbKey ? analysis_result[dbKey] : false;

    return (
      <>
        {!this.props.inline && (
          <div className="pa3 bb b--black-05">
            <h1>{this.props.editing ? `Edit ${title}` : `New ${title}`}</h1>
          </div>
        )}
        <div className="pa3">
          <form onSubmit={this.handleSubmit}>
            {!isFormValid && (
              <div className="mt3 light-red f6">
                Please add {title} name and properties
              </div>
            )}
            <div className="pt3 flex">
              {editCollectionName || editAll ? (
                <>
                  <input
                    type="text"
                    placeholder={`${title} name`}
                    className={cx("pa2 w-100", {
                      "ba b--light-red":
                        analysis_name && analysis_name.trim() === "",
                    })}
                    name="analysis_name"
                    value={analysis_name}
                    onChange={this.handleKeyUp}
                  />
                  <img
                    src={UndoIcon}
                    className="pointer ph3"
                    onClick={this.undoEditedName}
                  />
                </>
              ) : (
                <>
                  {this.props.currentAnalysis &&
                  this.props.currentAnalysis.id ? (
                    <a
                      className="f4"
                      href={`/home/admin/report?${
                        this.props.type &&
                        this.props.type.toLowerCase() === "analysis"
                          ? `analysis`
                          : `collection`
                      }=${this.props.currentAnalysis.id}`}
                      target="_blank"
                    >
                      {analysis_name}
                    </a>
                  ) : (
                    <>{analysis_name}</>
                  )}
                  <img
                    src={EditIcon}
                    className="pointer ph3"
                    onClick={this.setEditable(true, "editCollectionName")}
                  />
                </>
              )}
            </div>

            {this.props.type.toLowerCase() === "analysis" && (
              <>
                <div className="mt3">
                  <div className="flex">
                    <h4>Analysis properties</h4>
                    {editAnalysisProperties ? (
                      <>
                        <img
                          src={UndoIcon}
                          className="pointer ph3"
                          onClick={this.undoAnalysisProperties}
                        />
                      </>
                    ) : (
                      <img
                        src={EditIcon}
                        className="pointer ph3"
                        onClick={this.setEditable(
                          true,
                          "editAnalysisProperties"
                        )}
                      />
                    )}
                  </div>
                  {this.props.editing &&
                    this.props.currentAnalysis.selected_time && (
                      <div>
                        {" "}
                        Last analyzed on :{" "}
                        {formatReportDate(
                          this.props.currentAnalysis.selected_time
                        )}
                      </div>
                    )}
                </div>
                {analysis_props.map((an, i) => {
                  return (
                    <div
                      className={cx("mt2", {
                        flex: editAnalysisProperties,
                      })}
                      key={`${editAnalysisProperties}-${i}-${editAnalysisProperties}`}
                    >
                      {editAnalysisProperties ? (
                        <div className="pt2 w-100 flex items-center">
                          {this.props.editing && selectedAnalysisResult && (
                            <>
                              <input
                                className="ph3"
                                type="radio"
                                // disabled={savingSelectedBetScore}
                                checked={skey && skey === an.key ? true : false}
                                onChange={this.onChangeSelectedBetScore(
                                  this.props.currentAnalysis.id,
                                  an.key,
                                  `${dbKey}||${an.key}`
                                )}
                              ></input>

                              <span className={cx("ph3 w-20")}>
                                {selectedAnalysisResult[an.key]
                                  ? Math.round(
                                      selectedAnalysisResult[an.key].betScore
                                    )
                                  : ""}
                                {" - "}
                                {selectedAnalysisResult[an.key]
                                  ? selectedAnalysisResult[an.key].betScoreName
                                  : ""}
                              </span>
                            </>
                          )}
                          <input
                            name={`analysis_name_${i}`}
                            className={cx("w-50 pa1", {
                              "ba b--light-red": an.name.trim() === "",
                            })}
                            value={an.name}
                            onChange={this.changeName(i)}
                            placeholder="Property Name"
                          />
                          <input
                            name={`analysis_key_${i}`}
                            value={an.key}
                            className="w-30 ml3 pa1"
                            placeholder={"Key"}
                            disabled
                          />
                          <a
                            className="pointer ml2 mt2"
                            onClick={this.remove(i)}
                          >
                            remove
                          </a>
                        </div>
                      ) : (
                        <div className="flex flex-column">
                          <span
                            className={cx("w-50 pa1", {
                              b: skey && skey === an.key ? true : false,
                            })}
                          >
                            {an.name}
                          </span>
                          {this.props.editing && selectedAnalysisResult && (
                            <>
                              <span
                                className={cx("pa1 w-50 f6", {
                                  b: skey && skey === an.key ? true : false,
                                })}
                              >
                                {selectedAnalysisResult[an.key]
                                  ? Math.round(
                                      selectedAnalysisResult[an.key].betScore
                                    )
                                  : ""}
                                {" - "}
                                {selectedAnalysisResult[an.key]
                                  ? selectedAnalysisResult[an.key].betScoreName
                                  : ""}
                              </span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
                {editAnalysisProperties && (
                  <div className="pt2">
                    <a
                      className="ui-button secondary tiny pointer"
                      onClick={this.add}
                    >
                      Add analysis property
                    </a>
                  </div>
                )}
              </>
            )}
            {this.props.editing ? null : (
              <>
                <div className="mt3">
                  <h4>Copy tests from analysis/collections</h4>
                </div>
                <div className="mt3">
                  {this.state.analysis && this.state.analysis.length > 0 && (
                    <TagSearch
                      options={this.state.analysis.map(a => ({
                        label:
                          a.analysis_props && a.analysis_props.length > 0
                            ? `Analysis: ${a.name}`
                            : `Collection: ${a.name}`,
                        value: a.id,
                      }))}
                      onBottom
                      callback={this.onChangeAnalysis}
                      defaultTags={
                        this.props.copyFrom
                          ? [
                              {
                                label:
                                  this.props.copyFrom.analysis_props &&
                                  this.props.copyFrom.analysis_props.length > 0
                                    ? `Analysis: ${this.props.copyFrom.name}`
                                    : `Collection: ${this.props.copyFrom.name}`,
                                value: this.props.copyFrom.id,
                              },
                            ]
                          : null
                      }
                    />
                  )}
                </div>
                {this.props.type.toLowerCase() === "collection" && (
                  <>
                    <div className="mt3">
                      <h4>Publish collection to Customers</h4>
                    </div>
                    <div className="mt3">
                      {this.state.customers &&
                        this.state.customers.length > 0 && (
                          <TagSearch
                            options={[
                              ADMIN_CUSTOMER,
                              ...this.state.customers.map(a => ({
                                label: a.name,
                                value: a.key,
                              })),
                            ]}
                            onBottom
                            callback={this.onSelectCustomer}
                            defaultTags={[ADMIN_CUSTOMER]}
                            singular
                          />
                        )}
                    </div>
                  </>
                )}
              </>
            )}
            {this.props.type.toLowerCase() === "analysis" && (
              <div className="pt3 flex items-center">
                {editLink ? (
                  <>
                    <span className=" f4 pr3 pb0 mb0">TRR</span>
                    <input
                      type="url"
                      placeholder={`TRR Link`}
                      className={cx("pa2 w-100")}
                      name="trr"
                      value={trr}
                      onChange={this.handleKeyUp}
                    />
                    <img
                      src={UndoIcon}
                      className="pointer ph3"
                      onClick={this.undoEditLink}
                    />
                  </>
                ) : (
                  <>
                    {!trr || trr.trim() === "" ? (
                      <span
                        src={EditIcon}
                        className="ui-button secondary pointer"
                        onClick={this.setEditable(true, "editLink")}
                      >
                        Add TRR
                      </span>
                    ) : (
                      <>
                        <a className="f5" href={`${trr}`} target="_blank">
                          TRR{" "}
                          <img
                            src={OpenLinkIcon}
                            className="icon"
                            width="14"
                            style={{ marginTop: "-8px" }}
                          />
                        </a>
                        <img
                          src={EditIcon}
                          className="pointer ph3"
                          onClick={this.setEditable(true, "editLink")}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {this.props.type.toLowerCase() === "analysis" && (
              <>
                <div className="mt3 flex w-100">
                  <label for="verified" className="flex">
                    <input
                      type="checkbox"
                      name="verified"
                      id="verified"
                      checked={verified}
                      onChange={this.onVerifiedChange}
                      className="mr2"
                      style={{ height: "20px", width: "20px" }}
                    />
                    {verified ? `Verified` : `Unverified`} analysis
                    <img
                      src={verified ? VerifiedBlueIcon : VerifiedGrayIcon}
                      alt="Verified Analysis"
                      className="icon tc pl2"
                      height="24"
                    />
                  </label>
                </div>
              </>
            )}
            <div className="mt3">
              <h4>
                {this.props.type.toLowerCase() === "analysis"
                  ? "Analysis"
                  : "Collection"}{" "}
                Tags
              </h4>
            </div>
            <div className="mt3 flex w-100 items-center">
              {this.state.tags
                .filter(tag => !tag.isDeleted)
                .map(tag => {
                  return (
                    <div className="">
                      <span className="tag-highlight tag-highlight-inner">
                        {tag.autoGenerated && (
                          <img
                            src={RobotIcon}
                            className="pr2 pt1 pb2"
                            width="28px"
                          />
                        )}
                        {tag.tag}
                        {!tag.autoGenerated && (
                          <img
                            src={XIcon}
                            className="pointer pl2"
                            onClick={this.onDeleteExistingTag(tag.tag)}
                          />
                        )}
                      </span>
                    </div>
                  );
                })}
              {showAddTag ? (
                <div className="w-50">
                  <CreatableSelect
                    isMulti
                    options={this.state.allTags}
                    placeholder="Add tag"
                    onChange={this.onChangeAddTag}
                  />
                </div>
              ) : (
                <div className="w-10 ">
                  <a
                    href="#"
                    onClick={this.toggleShowAddTag}
                    style={{ paddingTop: "6px" }}
                    className="ml3"
                  >
                    Add Tag
                  </a>
                </div>
              )}
            </div>
            <div className="pt3">
              {notes.map((note, index) => {
                if (note && note.note && note.note.trim() !== "") {
                  return (
                    <EditableNote
                      index={index}
                      isEditable={isNoteEditable(note, this.props.user)}
                      noteData={note}
                      inputCols={100}
                      onNoteSave={this.addNotesV2}
                      key={`note-${index}`}
                    />
                  );
                }
                return <></>;
              })}
            </div>
            <div className="pt3">
              {addingNote ? (
                <textarea
                  placeholder={`Add a note`}
                  className={cx("pa2 w-100")}
                  name="currentNotes"
                  value={currentNotes}
                  onChange={this.handleKeyUp}
                />
              ) : (
                <span
                  className="ui-button secondary pointer"
                  onClick={e => {
                    this.setAddingNote();
                  }}
                >
                  Add a note
                </span>
              )}
            </div>
            <div className="pt4 flex">
              {saving ? (
                <button className="ui-button disabled" disabled>
                  Saving...
                </button>
              ) : (
                <>
                  <input
                    type="submit"
                    value={this.props.editing ? "Save" : "Create"}
                    className="ui-button primary"
                  />

                  <a
                    href=" #"
                    onClick={e => {
                      e.preventDefault();
                      this.props.doClose(this.props.editing, "cancel");
                    }}
                    style={{ paddingTop: "6px" }}
                    className="ml3"
                  >
                    Cancel
                  </a>
                </>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default CreateAnalysis;
