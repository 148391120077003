import { Link } from "react-router-dom";
import cx from "classnames";
const PreviewTestRow = ({ test, index = 1, showImage = false, ...props }) => {
  return (
    <div>
      <div
        className={cx("w-100 pa2", {
          "test-box": index % 2 === 0,
          "test-box-odd": index % 2 !== 0,
        })}
      >
        <span className="mb0 w-10 dib v-top b ph1">{test.company_name}</span>
        <span className="mb0 w-50 dib v-top">{test.headline_tag}</span>
        <Link
          target="_blank"
          to={`/home/admin/report/screenshots/${test.id}`}
          className="mb0 w-5 dib  v-top"
        >
          Link
        </Link>
        <span className="mb0 w-10 dib v-top">Score : {test.score}</span>
        <span className="mb0 w-25 dib v-top">
          Matched :{" "}
          {Array.isArray(test.recd) ? test.recd.join(", ") : test.recd}
        </span>
      </div>
      <div className="flex justify-around items-center pa3">
        {test.img1 && showImage && (
          <img
            style={{ width: "100%", maxWidth: "600px", borderRadius: "6px" }}
            src={test.img1}
            width="600"
          />
        )}

        {test.img2 && showImage && (
          <img
            style={{ width: "100%", maxWidth: "600px", borderRadius: "6px" }}
            src={test.img2}
            width="600"
          />
        )}
      </div>
    </div>
  );
};
const AbTestEmailPreview = ({
  previewData,
  onClosePreview,
  showCloseButton = true,
  allData,
  ...props
}) => {
  return (
    <>
      {previewData === "No Data" && <p className="mb0 f5 fl">No Data</p>}
      {showCloseButton && (
        <button
          onClick={onClosePreview}
          className="fr mr5 bg-light-red white f7 ba"
        >
          x
        </button>
      )}
      {previewData.featured && (
        <>
          <p className="b f4 mb0">Featured</p>
          <PreviewTestRow test={previewData.featured} showImage={true} />
        </>
      )}
      {previewData.called && (
        <>
          <p className="b f4 mb0">Called</p>
          {previewData.called.map((test, index) => {
            return (
              <PreviewTestRow
                test={test}
                index={index}
                key={`called-${index}`}
              />
            );
          })}
        </>
      )}
      {previewData.published && (
        <>
          <p className="b f4 mb0">Published</p>
          {previewData.published.map((test, index) => {
            return (
              <PreviewTestRow
                test={test}
                index={index}
                key={`published-${index}`}
              />
            );
          })}
        </>
      )}
      {previewData.recent && (
        <>
          <p className="b f4 mb0">Recent</p>
          {previewData.recent.map((test, index) => {
            return (
              <PreviewTestRow
                test={test}
                index={index}
                key={`recent-${index}`}
              />
            );
          })}
        </>
      )}
      <h4>Tests considered</h4>
      <div className="bb b--black-04 mv2"></div>
      {allData.called && (
        <>
          <p className="b f4 mb0">Called</p>
          {allData.called.map((test, index) => {
            return (
              <PreviewTestRow
                test={test}
                index={index}
                key={`alltests-called-${index}`}
              />
            );
          })}
        </>
      )}
      {allData.published && (
        <>
          <p className="b f4 mb0">Published</p>
          {allData.published.map((test, index) => {
            return (
              <PreviewTestRow
                test={test}
                index={index}
                key={`alltest-published-${index}`}
              />
            );
          })}
        </>
      )}
    </>
  );
};
export default AbTestEmailPreview;
