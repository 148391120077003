import React, { Component } from "react";
import Select from "react-select";
import { components } from "react-select";
import { copyData, getCopyData } from "../util";
import CopyIcon from "../icons/copy.svg";
import PasteIcon from "../icons/paste.svg";
import CopyPaste from "./CopyPaste.jsx";
const { Option } = components;
class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.props.defaultTags || [],
    };
  }
  handleChange = (newValue, actionMeta, z, x) => {
    if (this.props.singular) {
      this.props.callback([newValue.value]);
    } else {
      this.props.callback(newValue ? newValue.map(tag => tag.value) : []);
    }
    if (this.props.clearOnAdd) {
      this.setState({ options: [] });
    } else {
      this.setState({ options: newValue });
    }
  };
  copyCallback = (data, type) => {
    this.props.copyCallback(data);
    if (type === "paste") {
      this.props.callback(data ? data.data.map(tag => tag.value) : []);
      this.setState({
        options: data.data,
      });
    }
  };
  render() {
    let showPaste = false;

    if (this.props.copyCallback) {
      const cData = getCopyData(this.props.title);
      if (cData) {
        showPaste = true;
      }
    }
    const { copying, pasting } = this.state;
    return (
      <div className={`${this.props.copyCallback ? "flex" : ""}`}>
        <Select
          isMulti={this.props.singular ? false : true}
          onChange={this.handleChange}
          options={this.props.options}
          value={this.state.options}
          placeholder={this.props.placeholder || "Search..."}
          className={`basic-multi-select w-100 ttc ${this.props.className}`}
          components={{ Option: this.props.optionComponent || Option }}
          onDeleteAnalysis={this.props.onDeleteAnalysis}
          styles={{
            menu: (provided, state) => ({
              ...provided,
              zIndex: 200,
              position: this.props.menuAtBottom ? undefined : "absolute",
              background: state.isFocused ? "red" : "white",
              height: "200px",
              bottom: this.props.onBottom ? "30px" : undefined,
              top: this.props.onBottom ? undefined : "30px",
            }),
            menuList: (provided, state) => ({
              ...provided,
              height: "200px",
              background: state.isFocused ? "red" : "white",
            }),
          }}
          menuIsOpen={this.props.menuIsOpen}
        />
        {this.props.copyCallback && (
          <CopyPaste
            copyCallback={this.copyCallback}
            title={this.props.title}
            options={this.state.options}
          />
        )}
      </div>
    );
  }
}

export default MultiSelect;
