import React from "react";
import * as cx from "classnames";
import Header from "../components/Header";
import Background from "../icons/Gradient.jpg";
import SS from "../icons/SS2.jpg";
import lever1 from "../icons/lever1.jpg";
import lever2 from "../icons/lever2.jpg";
import lever3 from "../icons/lever3.jpg";
import "./index.css";

import BankCardIcon from "../icons/sectors/bank-cards-7592_00e5e308-9ea6-4084-98f7-59d1a2a893fe.svg";
import BookmarkIcon from "../icons/sectors/bookmark-7930_cb38a278-44ab-490c-9f86-2dbd137e3a6b.svg";
import CerealIcon from "../icons/sectors/cereal-box-6935_ea591fca-71e8-43a7-86dc-492ec7312934.svg";
import TakeoutIcon from "../icons/sectors/chinese-takeout-7009_e9d52dbc-47c9-4847-81de-e3b7bed1c7d3.svg";
import ConfigIcon from "../icons/sectors/configuration-7151_ed481445-fd66-40ec-83d1-a13ae3d13bfe.svg";
import FoodIcon from "../icons/sectors/cutlery-6880_d53b405d-01b3-4e3c-8d4b-27e2105d2bc9.svg";
import DNAIcon from "../icons/sectors/dna-8800_c6055bde-69f5-4006-998b-3c7899803d20.svg";
import FridgeIcon from "../icons/sectors/fridge-6894_d7ccea0e-4d85-460e-adcd-86545e80fe38.svg";
import Food2Icon from "../icons/sectors/gastronomy-6905_815e46c7-e453-4394-ab04-f61ee1989c7e.svg";
import HeartIcon from "../icons/sectors/heart-rate-8737_50b28c92-a531-49a8-953a-668a388083fb.svg";
import HospitalIcon from "../icons/sectors/hospital-8742_69f7bf89-8ed1-4f10-b3bc-f70fee7bf646.svg";
import LadyIcon from "../icons/sectors/lady-7725_eedfa4bc-d99a-42f2-b39b-3372343d6c1d.svg";
import SmokingIcon from "../icons/sectors/lungs-8814_9a585843-5384-447c-9b9f-c782b7a73ca0.svg";
import DrinkIcon from "../icons/sectors/margarita-6963_4f7d3cad-a367-4c05-9545-3eb8c2cebcc1.svg";
import RecordIcon from "../icons/sectors/medical-record-8769_757e1a3d-bea1-4933-ba45-090667395408.svg";
import DropperIcon from "../icons/sectors/medicine-dropper-8779_ee4e2a08-427d-4418-aba0-8e5669b1ee7b.svg";
import MentalIcon from "../icons/sectors/mental-health-8796_5456bc40-0905-4d18-9bd1-6a34820e026e.svg";
import MovieIcon from "../icons/sectors/movie-clapperboard-8158_c048ff19-1378-4f31-8840-e8c5db8d684e.svg";
import NewspaperIcon from "../icons/sectors/newspaper-7971_68864559-9892-4ef6-a12e-99e902df1753.svg";
import BedIcon from "../icons/sectors/Bed.svg";
import PetIcon from "../icons/sectors/Pets.svg";
import PillsIcon from "../icons/sectors/Orion_tablets.svg";
import PeopleIcon from "../icons/sectors/people-7742_2a8e2634-6f9d-4889-bb1d-ce62e4d96b31.svg";
import PillIcon from "../icons/sectors/pill-8772_b419520d-33b4-470b-a662-50e1f18a2410.svg";
import PlaneIcon from "../icons/sectors/plane-departure-7887_4df9cc2c-f5b5-4378-9f66-10f37c6bc7e3.svg";
import SalesIcon from "../icons/sectors/sales-up-7651_60e14c28-27c1-4b13-90d0-203095620b81.svg";
import SteakIcon from "../icons/sectors/steak-6982_64fc4eb8-218f-475b-a94d-00996d0cb10a.svg";
import MusicIcon from "../icons/sectors/turntable-8173_0d83775f-e22c-42f3-96f5-e6321fda4abd.svg";
import TaxiIcon from "../icons/sectors/taxi-7832_7dcdd887-a835-469e-be1b-86746091150a.svg";
import VaultIcon from "../icons/sectors/vault-7615_98adb673-7108-404d-894e-90aa2a2c824c.svg";
import ScalesIcon from "../icons/sectors/weighing-scale-8791_6c7a25fb-4ede-4171-b399-6af7ccf96158.svg";
import GlassesIcon from "../icons/sectors/Orion_glasses.svg";
import PlayIcon from "../icons/sectors/Orion_stream-video.svg";
import HearingIcon from "../icons/sectors/Orion_wireless-headphones.svg";
import UmbrellaIcon from "../icons/sectors/Orion_umbrella.svg";
import plus from "../icons/plus.svg";
import minus from "../icons/minus.svg";
import { pickRandomElm } from "../util";

const color = [
  "#FFCB58",
  "#50C8B0",
  "#2F80ED",
  "#F65B56",
  "#9B51E0",
  "#332590",
];

const sectorsRows1 = [
  { label: "Meal Kit", icon: FoodIcon },
  { label: "Prepared Meal", icon: Food2Icon },
  { label: "Food DTC", icon: CerealIcon },
  { label: "Food Delivery", icon: TakeoutIcon },
  { label: "Men's Health", icon: RecordIcon },
  { label: "Weight Loss", icon: ScalesIcon },
  { label: "Fitness", icon: HeartIcon },
  { label: "Women's Health", icon: HospitalIcon },
  { label: "Grocery", icon: FridgeIcon },
  { label: "Meat", icon: SteakIcon },
  { label: "Alcohol", icon: DrinkIcon },
  { label: "Fashion", icon: LadyIcon },
];
const sectorsRows2 = [
  { label: "Beauty", icon: DropperIcon },
  { label: "Vision", icon: GlassesIcon },
  { label: "Pharmacy", icon: PillsIcon },
  { label: "Skin Care", icon: DropperIcon },
  { label: "News Subs", icon: NewspaperIcon },
  { label: "Video Subs", icon: MovieIcon },
  { label: "Music Subs", icon: MusicIcon },
  { label: "Crowdfunding", icon: PeopleIcon },
  { label: "Digital Subs", icon: PlayIcon },
  { label: "Learning", icon: BookmarkIcon },
  { label: "Dental", icon: HospitalIcon },
  { label: "Supplements", icon: PillIcon },
  { label: "Genomics", icon: DNAIcon },
  { label: "Marketplaces", icon: PeopleIcon },
  { label: "Matresses", icon: BedIcon },
];
const sectorsRows3 = [
  { label: "Gig Economy", icon: TaxiIcon },
  { label: "Travel", icon: PlaneIcon },
  { label: "Pets", icon: PetIcon },
  { label: "Credit Cards", icon: BankCardIcon },
  { label: "Banking", icon: VaultIcon },
  { label: "Investing", icon: SalesIcon },
  { label: "Quit Smoking", icon: SmokingIcon },
  { label: "Mental Health", icon: MentalIcon },
  { label: "Insurance", icon: UmbrellaIcon },
  { label: "B2B SaaS", icon: ConfigIcon },
  { label: "Hearing", icon: HearingIcon },
  { label: "Shaving", icon: PeopleIcon },
];

class Index extends React.Component {
  state = {
    hint: false,
    pop: Math.floor(Math.random() * 5),
    sectorsRows1: sectorsRows1.map(s => ({
      name: s.label,
      color: pickRandomElm(color),
      img: s.icon,
    })),
    sectorsRows2: sectorsRows2.map(s => ({
      name: s.label,
      color: pickRandomElm(color),
      img: s.icon,
    })),
    sectorsRows3: sectorsRows3.map(s => ({
      name: s.label,
      color: pickRandomElm(color),
      img: s.icon,
    })),
    leverPosition: 0,
    selectedFAQ: 0,
  };
  interval: null;
  interval2: null;
  timeout: null;
  leverAnimate: false;
  testsAnimate: false;
  handleScroll = () => {
    if (window.scrollY > 360 && this.state.didScroll !== true) {
      this.setState({
        didScroll: true,
      });
      const button = document.getElementById("header-button");
      if (button) {
        button.classList.add("button-on");
      }
    } else if (this.state.didScroll === true && window.scrollY <= 360) {
      this.setState({
        didScroll: false,
      });
      const button = document.getElementById("header-button");
      if (button) {
        button.classList.remove("button-on");
      }
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const tests = document.getElementById("tests");
      const levers = document.getElementById("levers");
      if (tests && levers) {
        const tRect = tests.getBoundingClientRect();
        const lRect = levers.getBoundingClientRect();
        if (window.scrollY > tRect.bottom + 200 && !this.testsAnimate) {
          this.testsAnimate = true;
        } else if (tRect.bottom <= 0) {
          this.testsAnimate = false;
        }
        if (window.scrollY >= lRect.bottom + 200 && !this.leverAnimate) {
          this.leverAnimate = true;
        } else if (tRect.bottom <= -100) {
          this.leverAnimate = false;
        }
      }
    }, 300);
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.interval = setInterval(() => {
      if (this.testsAnimate) {
        const newPop = this.state.pop;

        if (newPop === 5) {
          this.setState({ pop: 0 });
        } else {
          this.setState({ pop: newPop + 1 });
        }
      }
    }, 2500);
    this.interval2 = setInterval(() => {
      if (this.leverAnimate) {
        const newPosition = this.state.leverPosition;

        if (newPosition >= 2) {
          this.setState({ leverPosition: 0 });
        } else {
          this.setState({ leverPosition: newPosition + 1 });
        }
      }
    }, 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
    window.removeEventListener("scroll", this.handleScroll);
  }
  toggleFaq = faq => e => {
    if (faq === this.state.selectedFAQ) {
      this.setState({ selectedFAQ: null });
    } else {
      this.setState({ selectedFAQ: faq });
    }
  };
  render() {
    const {
      pop,
      sectorsRows1,
      sectorsRows2,
      sectorsRows3,
      leverPosition,
      selectedFAQ,
    } = this.state;

    if (this.props.user) {
      window.location.replace(`/home/${this.props.user.company}/report`);
    }

    return (
      <div className="home">
        <Header
          user={this.props.user}
          handleLogout={this.props.handleLogout}
          isHome
        />
        <div
          className="pv4 center"
          style={{ position: "relative", overflowX: "hidden", width: "100%" }}
        >
          <div
            style={{
              backgroundImage: `url(${Background})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              position: "relative",
            }}
          >
            <div className="hero-container">
              <div className="headline-container home-stripe">
                <h1 className="f2 b f1-ns home-headline mb3 h1">
                  <div className="mb3">You can't test everything.</div>
                  <div className="mb2">You might as well</div>
                  <span className="header-bold">Do What Works.</span>
                </h1>
                <div className="mb3 home-text">
                  <p>
                    See the winners and losers from split tests run by the
                    world's top{" "}
                    <span className="b purple bg-purple">1,694</span> companies.
                  </p>
                  <div className="mt4">
                    <a
                      href="https://dowhatworks.typeform.com/to/Gq5K8S2E"
                      className="ui-button primary ui-button-home"
                      id="index-button"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-image">
              <img src={SS} style={{ width: "1000px" }} alt="Product shot" />
            </div>
          </div>
          <div className="used-container">
            <div className="home-headline tl">
              <span className="break">The world’s first </span>
              <span className="header-bold">Conversion Rate Accelerator</span>.
              <p className="home-test-text mt4">
                Used by marketing, growth and product teams at the world’s top
                companies to find wins faster.
              </p>
            </div>
            <div className="used-section">
              <div className="circle circle-1">
                <div className="used-num">20%</div>
                of Meal Direct-to-Consumer Brands
              </div>
              <div className="circle circle-2">
                <div className="used-num">2</div>
                Fortune 500 B2B Saas Companies
              </div>
              <div className="circle circle-3">
                <div className="used-num">3</div>
                Top Streaming Brands
              </div>
            </div>
          </div>
          <div className="flex home-stripe tests-stripe">
            <div className="pl3 tc w-100-s w-50">
              <div className="home-headline tests-container home-stripe">
                <span className="header-bold break">3,000+ </span>
                <span className="break">split tests </span>
                <span className="break">discovered </span>
                so far
              </div>
            </div>
            <div className="w-100-s w-50">
              <div className="pie" id="tests">
                <div className="pie-inner" />
                <div
                  className={cx({ "pop-hover": true, dn: pop !== 0 })}
                  id="pop-1"
                >
                  <div className="pop-box">
                    <div>Landing Page Optimization</div>
                    <div className="pop-button">1,833 Tests</div>
                  </div>
                  <div className="pop-triangle"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div
                  className={cx({ "pop-hover": true, dn: pop !== 1 })}
                  id="pop-2"
                >
                  <div className="pop-box">
                    <div>Search Messaging</div>
                    <div className="pop-button">605 Tests</div>
                  </div>
                  <div className="pop-triangle"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div
                  className={cx({ "pop-hover": true, dn: pop !== 2 })}
                  id="pop-3"
                >
                  <div className="pop-box">
                    <div>Pricing Plans</div>
                    <div className="pop-button">438 Tests</div>
                  </div>
                  <div className="pop-triangle"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div
                  className={cx({ "pop-hover": true, dn: pop !== 3 })}
                  id="pop-4"
                >
                  <div className="pop-box">
                    <div>Offers & Promotions</div>
                    <div className="pop-button">204 Tests</div>
                  </div>
                  <div className="pop-triangle"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div
                  className={cx({ "pop-hover": true, dn: pop !== 4 })}
                  id="pop-5"
                >
                  <div className="pop-box">
                    <div>Driving Donations</div>
                    <div className="pop-button">91 Tests</div>
                  </div>
                  <div className="pop-triangle"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div
                  className={cx({ "pop-hover": true, dn: pop !== 5 })}
                  id="pop-6"
                >
                  <div className="pop-box">
                    <div>Supplier Acquisition (marketplaces)</div>
                    <div className="pop-button">62 Tests</div>
                  </div>
                  <div className="pop-triangle"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="levers-section">
            <div className="home-headline">
              <div className="tl">
                <span className="break">Every lever </span>
                under the sun.
                <p className="home-test-text mt3">
                  Get ideas. Prioritize your backlog. Riff on winners to make
                  better bets.
                </p>
              </div>
            </div>
            <div className="lever-container" id="levers">
              <div
                className={cx({
                  "lever-item": true,
                  "lever-item-selected": leverPosition === 0,
                })}
              >
                <img
                  src={lever1}
                  width="425"
                  alt="What buttons work on different kinds of pages in your industry?"
                />
                <div
                  className="pop-hover"
                  style={{ top: "80px", right: "86px" }}
                >
                  <div className="pop-box pop-box-purple">
                    <div>
                      What buttons work on different kinds of pages in your
                      industry?
                    </div>
                  </div>
                  <div className="pop-triangle pop-triangle-purple"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div className="lever-text">
                  What buttons work on different kinds of pages in your
                  industry?
                </div>
              </div>
              <div
                className={cx({
                  "lever-item": true,
                  "lever-item-selected": leverPosition === 1,
                })}
              >
                <img
                  src={lever2}
                  width="425"
                  alt="What messaging resonates with your target customer?"
                />
                <div
                  className="pop-hover"
                  style={{ top: "28px", right: "59px" }}
                >
                  <div className="pop-box pop-box-purple">
                    <div>
                      What messaging resonates with your target customer?
                    </div>
                  </div>
                  <div className="pop-triangle pop-triangle-purple"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div className="lever-text">
                  What messaging resonates with your target customer?
                </div>
              </div>
              <div
                className={cx({
                  "lever-item": true,
                  "lever-item-selected": leverPosition === 2,
                })}
              >
                <img
                  src={lever3}
                  width="425"
                  alt="What pricing & packaging performs the best?"
                />
                <div
                  className="pop-hover"
                  style={{ top: "88px", right: "43px" }}
                >
                  <div className="pop-box pop-box-purple">
                    <div>What pricing & packaging performs the best?</div>
                  </div>
                  <div className="pop-triangle pop-triangle-purple"></div>
                  <div className="pop-triangle-outer"></div>
                </div>
                <div className="lever-text">
                  What pricing & packaging performs the best?
                </div>
              </div>
            </div>
          </div>
          <div className="lever-circles">
            <div
              className={cx({
                "tiny-circle": true,
                "tiny-circle-active": leverPosition === 0,
              })}
            ></div>
            <div
              className={cx({
                "tiny-circle": true,
                "tiny-circle-active": leverPosition === 1,
              })}
            ></div>
            <div
              className={cx({
                "tiny-circle": true,
                "tiny-circle-active": leverPosition === 2,
              })}
            ></div>
          </div>
          <div className="ph3 mb6 sectors-container">
            <div className="center home-headline home-stripe">
              Monitoring <span className="header-bold">40+</span> sectors
            </div>
            <div className="sector-row">
              {sectorsRows1.map(sector => {
                return (
                  <div className="sector-box" key={sector.name}>
                    <div
                      className="sector-image"
                      style={{ backgroundColor: sector.color }}
                    >
                      <img src={sector.img} alt={sector.name} />
                    </div>
                    <div className="sector-text">{sector.name}</div>
                  </div>
                );
              })}
            </div>
            <div className="sector-row sector-row-odd">
              {sectorsRows2.map(sector => {
                return (
                  <div className="sector-box" key={sector.name}>
                    <div
                      className="sector-image"
                      style={{ backgroundColor: sector.color }}
                    >
                      <img src={sector.img} alt={sector.name} />
                    </div>
                    <div className="sector-text">{sector.name}</div>
                  </div>
                );
              })}
            </div>
            <div className="sector-row">
              {sectorsRows3.map(sector => {
                return (
                  <div className="sector-box" key={sector.name}>
                    <div
                      className="sector-image"
                      style={{ backgroundColor: sector.color }}
                    >
                      <img src={sector.img} alt={sector.name} />
                    </div>
                    <div className="sector-text">{sector.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="home-stripe faqs">
            <div className="home-headline">Frequently Asked Questions</div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(0)}>
                <span className="faq-headline-text">
                  Why do people use DoWhatWorks?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 0 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 0 })}
              >
                <p>
                  If you had infinite money, time and traffic, you'd try
                  thousands of different things on your growth funnel. But you
                  don’t.
                </p>
                <p>
                  So our customers start by seeing what won and lost for
                  everyone else so they can bet on what’s most likely to work.
                </p>
                <p>
                  DoWhatWorks helps them generate ideas, prioritize, get buy in
                  and ultimately try things that are more likely to work.
                </p>
              </div>
            </div>
            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(2)}>
                <span className="faq-headline-text">Who is it for?</span>
                <div className="faq-toggle">
                  {selectedFAQ === 2 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 2 })}
              >
                Growth teams. Marketing teams. Product teams. Anyone who wants
                to improve the conversion rate in their marketing or website.
              </div>
            </div>
            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(1)}>
                <span className="faq-headline-text">
                  Who is behind DoWhatWorks?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 1 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 1 })}
              >
                <p>
                  <a href="https://www.linkedin.com/in/will-howard-63538841/">
                    Will Howard
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.linkedin.com/in/glusman/"
                    target="_blank"
                  >
                    Andres Glusman
                  </a>{" "}
                  spent over a decade running tons of experiments at Meetup.
                  They saw how split testing could drive tremendous growth, but
                  were frustrated by how long it took to get wins. They wanted a
                  faster way to learn what works.
                </p>

                <p>
                  So they created an engine to help them learn faster by seeing
                  what won and lost for companies they admired. Soon their
                  friends wanted dashboards and then word got out. They've been
                  growing and iterating ever since.
                </p>
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(12)}>
                <span className="faq-headline-text">
                  What channels do you cover?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 12 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 12 })}
              >
                Tests on landing pages, marketing websites, pricing pages,
                signup pages, etc. We also assess messaging experiments run on
                search advertising so you can see what messages most resonate
                with your target customer.
              </div>
            </div>
            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(13)}>
                <span className="faq-headline-text">
                  Do you report on desktop or mobile versions of split tests on
                  websites?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 13 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 13 })}
              >
                We report on both.
              </div>
            </div>
            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(3)}>
                <span className="faq-headline-text">
                  What kinds of tests do you see?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 3 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 3 })}
              >
                Everything from button copy to full scale redesigns. Our clients
                see what messaging resonates, pricing tests, what kind of social
                proof works best, approaches to layouts and more.
              </div>
            </div>
            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(14)}>
                <span className="faq-headline-text">
                  Do you report on tests happening in advertisements?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 14 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 14 })}
              >
                Yes. We assess what messages are tested in search engine ads and
                what messages are resonating with most.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(5)}>
                <span className="faq-headline-text">
                  How do you choose which companies to monitor?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 5 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 5 })}
              >
                We track companies that interest us and our customers.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(6)}>
                <span className="faq-headline-text">
                  If you aren’t tracking a company, can I ask you to?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 6 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 6 })}
              >
                Yes, just let us know and we will start tracking it for you.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(7)}>
                <span className="faq-headline-text">
                  Are these the only sectors you will cover?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 7 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 7 })}
              >
                Nope! We are adding companies and sectors every week.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(8)}>
                <span className="faq-headline-text">
                  Where do you get the data?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 8 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 8 })}
              >
                All of our analysis is based on publicly available data.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(9)}>
                <span className="faq-headline-text">
                  Do you collect any personally identifiable information?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 9 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 9 })}
              >
                Never. All of the data we collect is anonymous.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(10)}>
                <span className="faq-headline-text">
                  Do you get data from sketchy third party services?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 10 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 10 })}
              >
                No. It’s all based on publicly available data.
              </div>
            </div>

            <div className="faq">
              <div className="faq-headline" onClick={this.toggleFaq(11)}>
                <span className="faq-headline-text">
                  How do I get access to private beta?
                </span>
                <div className="faq-toggle">
                  {selectedFAQ === 11 ? (
                    <img src={minus} alt="collapse" />
                  ) : (
                    <img src={plus} alt="expand" />
                  )}
                </div>
              </div>
              <div
                className={cx({ "faq-detail": true, dn: selectedFAQ !== 11 })}
              >
                Go{" "}
                <a href="https://dowhatworks.typeform.com/to/Gq5K8S2E">here</a>{" "}
                to request access. We will be opening it up soon.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
