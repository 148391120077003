import React from "react";
import { Link } from "react-router-dom";
import * as cx from "classnames";
import * as querystring from "query-string";
import "./dashboard.css";
import Modal from "@material-ui/core/Modal";
import {
  getData,
  baseApiUrl,
  imageUrl,
  addCommas,
  niceNumbers,
  pickRandomElm,
  freeTrialImageUrl,
  randomHue,
} from "../../util";
import FakeDashboard from "../../components/FakeDashboard";
import DashboardHeader from "../../components/DashboardHeader";
import AccessDenied from "../../components/AccessDenied";
import MovieFone from "../../components/MovieFone";
import XIcon from "../../icons/x.svg";
import LockIcon from "../../icons/lock_white.svg";
import Screenshot from "../../img/free_trial_detail.jpg";
import FreeTrialSalesModal from "../../components/FreeTrialSalesModal";

class Dashboard extends React.Component {
  getFrom = () => {
    const { from } = querystring.parse(window.location.search);

    if (from) {
      return from;
    }
    return false;
  };
  state = {
    loading: true,
    csv_loading: false,
    counts: undefined,
    percents: [],
    hint: false,
    openModal: false,
    openEditModal: false,
    editSuccess: false,
    show_all_ab: false,
    show_all_sem: false,
    saved_tests: [],
    first: false,
    sem_filter_tlds: [],
    keywords: [],
    kw_groups: [],
    kws_filter: [],
    comp_lookup: {},
    total_sem_count: 0,
    total_sem_test_count: 0,
    total_unique_sem_count: 0,
    start: 0,
    from: this.getFrom(),
  };

  inputRef = React.createRef();
  foundFirst = false;
  triggerFoundTwice = false;
  canvasOptions = {};
  getData = shouldSkipCache => {
    const { id, newCall, no_cache } = querystring.parse(window.location.search);
    let report = null;

    if (id) {
      report = id;
    }
    const apiUrl = `${baseApiUrl}/v1/report?byModule=false${
      report ? `&report=${report}` : ""
    }&company=${
      this.props.match.params.company
    }&returnData=new_dashboard&newSem=true&newDash=true&doSavedTests=true&sortByDate=true&newTags=true${
      newCall ? "&newCall=true" : ""
    }${no_cache && no_cache === "true" ? "&noCache=true" : ""}`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      false,
      true,
      shouldSkipCache
    ).then(
      ({ err, res, data }) => {
        if (err || res.statusCode >= 400) {
          const errData = JSON.parse(res.body);
          if (errData && errData.error && errData.error === "No Subscription") {
            this.props.history.push(
              `/subscribe/${this.props.match.params.company}`
            );
            return;
          }
          this.setState({
            loading: true,
            authed: false,
            accessDenied: res.statusCode === 400,
          });
          return;
        }
        Object.keys(data.customer.dashboard).forEach(c => {
          let tests = [];
          const dash = data.customer.dashboard[c];
          dash.modules.forEach((m, i) => {
            if (m.type === "all_tests_link") {
              if (m.link === "?recent=saves") {
                m.count = data.saved_tests_ss.length;
              } else {
                m.count = data.customer.counts[`${m.link}`].count;
              }
            } else {
              m.count = (Math.random() * 200).toFixed(0);
            }
            if (m.type === "sem_link") {
              m.href = `/home/${this.props.match.params.company}/sem${
                m.link ? m.link : ""
              }`;
            }
            if (m.type === "all_tests_link") {
              m.href = `/home/${this.props.match.params.company}/report${m.link}`;

              if (m.link === "?recent=views" && data.recent_tests.length >= 5) {
                m.count = data.recent_tests.length;
                data.recent_tests.sort(() => (Math.random() >= 0.5 ? -1 : 1));
                data.recent_tests.forEach((s, i) => {
                  if (i < 5) {
                    let imgSrc, hue, blur;
                    if (data.customer.freeTrial) {
                      [imgSrc, hue, blur] = s.screenshots[0].src.split(":");
                    }
                    tests.push({
                      image: data.customer.freeTrial
                        ? s.screenshots[0].src
                        : imageUrl(s.screenshots[0].src, 300, 150),
                      type: m.type,
                      tag: m.tag,
                      link: m.link,
                      href: m.href,
                      imgSrc,
                      hue,
                      blur,
                    });
                  }
                });
              }
              if (
                m.link === "?new=collection" ||
                m.link === "?recent=saves" ||
                m.link === "?new=added"
              ) {
                if (data.customer.counts[m.link]) {
                  const dData = data.customer.counts[m.link];
                  m.count = dData.count;
                  dData.screenshots.sort(() => (Math.random() >= 0.5 ? -1 : 1));
                  dData.screenshots.forEach((s, i) => {
                    if (i < 5) {
                      let imgSrc, hue, blur;
                      tests.push({
                        image: data.customer.freeTrial
                          ? s
                          : imageUrl(s, 300, 150),
                        type: m.type,
                        tag: m.tag,
                        link: m.link,
                        href: m.href,
                        imgSrc,
                        hue,
                        blur,
                      });
                    }
                  });
                } else {
                  m.count = data.recent_tests.length;
                  data.saved_tests_ss.sort(() =>
                    Math.random() >= 0.5 ? -1 : 1
                  );
                  data.saved_tests_ss.forEach((s, i) => {
                    if (i < 5) {
                      let imgSrc, hue, blur;
                      tests.push({
                        image: data.customer.freeTrial
                          ? s
                          : imageUrl(s, 300, 150),
                        type: m.type,
                        tag: m.tag,
                        link: m.link,
                        href: m.href,
                        imgSrc,
                        hue,
                        blur,
                      });
                    }
                  });
                }
              }

              if (
                data.customer.counts[`${m.link}`].screenshots &&
                data.customer.counts[`${m.link}`].screenshots.length > 0
              ) {
                data.customer.counts[`${m.link}`].screenshots.sort((a, b) =>
                  Math.random() >= 0.5 ? -1 : 1
                );
                data.customer.counts[`${m.link}`].screenshots.forEach(
                  (s, i) => {
                    if (i < 5) {
                      let imgSrc, hue, blur;
                      tests.push({
                        image: data.customer.freeTrial
                          ? s
                          : imageUrl(s, 300, 150),
                        type: m.type,
                        tag: m.tag,
                        link: m.link,
                        href: m.href,
                        imgSrc,
                        hue,
                        blur,
                      });
                    }
                  }
                );
              }
            }
            if (m.type === "sem_link") {
              const qs = querystring.parse(m.link);
              let semData = data.sem_activity;
              if (qs && (qs.companies || qs.keywords)) {
                const companies = qs.companies ? qs.companies.split("|") : null;
                const keywords = qs.keywords ? qs.keywords.split("|") : null;

                semData = semData.filter(s => {
                  if (!s || !s.text) {
                    return false;
                  }
                  let found = false;
                  if (companies && companies.includes(s.tld)) {
                    found = true;
                  }

                  if (keywords) {
                    if (found && !keywords.includes(s.keyword)) {
                      found = false;
                    } else if (keywords.includes(s.keyword)) {
                      found = true;
                    }
                  }

                  return found;
                });
              }

              if (!data.customer.freeTrial && semData && semData.length > 0) {
                for (let i = 0; i < 5; i++) {
                  tests.push({
                    sems: [
                      pickRandomElm(semData),
                      pickRandomElm(semData),
                      pickRandomElm(semData),
                    ],
                    type: m.type,
                    tag: m.tag,
                    link: m.link,
                    href: m.href,
                  });
                }
              }
            }
          });

          tests.sort(() => (Math.random() >= 0.5 ? -1 : 1));
          const links = [];
          const newTests = [];
          const otherTests = [];
          tests.forEach(t => {
            if (!links.includes(t.link)) {
              links.push(t.link);
              newTests.push(t);
            } else {
              otherTests.push(t);
            }
          });
          otherTests.sort(() => (Math.random() >= 0.5 ? -1 : 1));
          data.customer.dashboard[c].tests_to_render = newTests.concat(
            otherTests.slice(0, 9 - newTests.length)
          );
        });

        data.variant_counts["Search messaging & landing pages"] =
          data.total_sem_count || 0;
        data.test_counts["Search messaging & landing pages"] =
          data.total_sem_test_count || 0;

        const ab_total = data.test_counts.total;

        const total_spend =
          ab_total * 10000 + data.total_unique_sem_count * 750;

        this.setState({
          loading: false,
          saved_tests: data.saved_tests,
          reportId: id ? id : data.report.id,
          report: report,
          total: data.test_counts.total + data.total_sem_test_count,
          counts: data.counts,
          data: data.data,
          customer: data.customer,
          dashboard: data.customer.dashboard,
          comp_lookup: data.comp_lookup,
          total_spend,
          editSuccess: false,
          openEditModal: false,
          company_count: data.companies.length,
          recent_activity: data.activity,
          sem_activity: data.sem_activity,
          sem_phrases: data.sem_phrases,
          total_sem_count: data.total_sem_count,
          total_sem_test_count: data.total_unique_sem_count,
          ab_total,
        });
      },
      () => {}
    );
    if (window.location.hash === "#winning-headlines") {
      this.props.history.push(`/home/${this.props.match.params.company}/sem`);
    }
  };
  openModal = e => {
    e.preventDefault();
    this.setState({ openModal: true });
  };
  closeModal = e => {
    this.setState({ openModal: false });
  };
  toggleHint = e => {
    e.preventDefault();
    this.setState({
      hint: !this.state.hint,
    });
  };
  componentDidMount() {
    if (this.props.user && this.props.user.customer.semOnly) {
      this.props.history.replace(
        `/home/${this.props.match.params.company}/sem`
      );
      return;
    }
    this.getData();
  }
  timeout = null;
  doHover = (d, type, tag) => e => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.state.hover_dash !== d || this.state.hover_tag !== tag) {
        this.setState({ hover_dash: d, hover_tag: tag });
      }
    }, 50);
  };

  closeRegModal = e => {
    e.preventDefault();
    this.setState({ from: false });
    this.props.history.replace(`/home/${this.props.match.params.company}`);
  };

  render() {
    const { loading, dashboard, reportId, customer } = this.state;
    const unlockedTests =
      customer && customer.freeTrial && customer.unlockedTests
        ? customer.unlockedTests.filter(
            unlockedTest => unlockedTest.unlockedBy !== "admin"
          ).length
        : null;

    if (loading) {
      return (
        <>
          <FakeDashboard company={this.props.match.params.company} newDash />
          {this.state.accessDenied && <AccessDenied />}
        </>
      );
    }
    const width = 600;
    const height = 500;

    let years = Math.round(
      (this.state.ab_total * 22 + this.state.total_sem_test_count * 7) / 365
    );

    if (years <= 0) {
      years = 1;
    }
    const dashb = {};
    const keys = Object.keys(dashboard).sort((a, b) =>
      dashboard[a].position >= dashboard[b].position ? 1 : -1
    );

    keys.forEach(k => {
      dashb[k] = dashboard[k];
    });

    return (
      <>
        <DashboardHeader
          user={this.props.user}
          customer={this.props.match.params.company}
          name={this.state.customer ? this.state.customer.name : null}
          customerData={this.state.customer}
          selected="dashboard"
        />
        <div className="pt4 document-spacing center tc flex">
          <div className="flex center">
            <div className="dash-infobox">
              <Link
                className="ui-link db"
                to={`/home/${this.props.match.params.company}/report`}
              >
                <div className="newdash-count dash-red">
                  {addCommas(this.state.ab_total)}
                </div>
                A/B Tests
              </Link>
            </div>
            {!this.state.customer.abOnly && (
              <div className="dash-infobox">
                <Link
                  className="ui-link db"
                  to={`/home/${this.props.match.params.company}/sem`}
                >
                  <div className="newdash-count dash-red">
                    {addCommas(this.state.total_sem_test_count)}
                  </div>
                  SEM Tests
                </Link>
              </div>
            )}
            <div className="dash-infobox">
              <a className="ui-link db" href=" #" onClick={this.openModal}>
                <div className="newdash-count dash-red">
                  {addCommas(Object.keys(this.state.comp_lookup).length)}
                </div>
                Companies
              </a>
            </div>
          </div>
        </div>
        <div className="fun-fact tc">
          <span className="fun-fact-text">Fun Fact:</span> It would take around{" "}
          <span className="dww-purple-box">
            {addCommas(years)} {years > 1 ? "years" : "year"}
          </span>{" "}
          and cost you about{" "}
          <span className="dww-purple-box">
            ${niceNumbers(this.state.total_spend)}
          </span>{" "}
          to run this many tests.
        </div>
        {Object.keys(dashb).map((d, i) => {
          const dash = dashboard[d];
          return (
            <div className="pt3 bg-white" key={`${i}-${dash.title}`}>
              <div className="pv4 document-spacing center flex">
                <div className="w-40 pr5">
                  <h3 className="pt2 pb4 newdash-head">{dash.title}</h3>
                  {dash.modules.map((m, i) => {
                    if (this.state.customer.abOnly && m.type === "sem_link") {
                      return <></>;
                    }
                    if (m.count > 0) {
                      return (
                        <Link
                          to={m.href}
                          className={cx({
                            "newdash-link": true,
                            hover:
                              this.state.hover_tag === m.link &&
                              this.state.hover_dash === d,
                          })}
                          onMouseOver={this.doHover(d, m.type, m.link)}
                          onMouseOut={this.doHover(null, null, null)}
                          key={`${m.link}-${i}`}
                        >
                          <span className="title">{m.title}</span>
                        </Link>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
                <div className="dashbox-pad dash-modules ph3 w-60 flex flex-wrap">
                  {dash.tests_to_render.map((test, x) => {
                    if (test.type === "sem_link") {
                      return (
                        <div
                          className={`dash-mosaic-item item-${x + 1}`}
                          key={`t-${x}`}
                          onMouseOver={this.doHover(d, test.type, test.link)}
                          onMouseOut={this.doHover(null, null, null)}
                        >
                          <Link
                            to={test.href}
                            className={cx({
                              "db ui-link dash-mosaic-item-img": true,
                              hover:
                                this.state.hover_tag === test.link &&
                                this.state.hover_dash === d,
                            })}
                            type={test.type}
                            dash={d}
                          >
                            {test.sems.map((s, i) => {
                              if (!s) {
                                return <></>;
                              }

                              return (
                                <span
                                  className={cx({
                                    "sem-box db": true,
                                    odd: i % 2 !== 0,
                                  })}
                                  key={`${i}-${s.text}`}
                                >
                                  <span className="sem-headline db">
                                    {s.text}
                                  </span>
                                  <span className="sem-company db">
                                    <span className="company-box">
                                      {s.company}
                                    </span>{" "}
                                    testing on "{s.term}"
                                  </span>
                                </span>
                              );
                            })}
                          </Link>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={`dash-mosaic-item item-${x + 1}`}
                          key={`t-${x}`}
                          onMouseOver={this.doHover(d, test.type, test.link)}
                          onMouseOut={this.doHover(null, null, null)}
                        >
                          <Link to={test.href} className="db">
                            {customer.freeTrial ? (
                              <span
                                className={cx({
                                  "db dash-mosaic-item-img relative": true,
                                  hover:
                                    this.state.hover_tag === test.link &&
                                    this.state.hover_dash === d,
                                })}
                                type={test.type}
                                dash={d}
                                style={{
                                  backgroundImage: `url(${test.image})`,
                                  opacity: 0.75,
                                }}
                                alt={test.link}
                              >
                                <span
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img src={LockIcon} />
                                </span>
                              </span>
                            ) : (
                              <span
                                className={cx({
                                  "db dash-mosaic-item-img": true,
                                  hover:
                                    this.state.hover_tag === test.link &&
                                    this.state.hover_dash === d,
                                })}
                                type={test.type}
                                dash={d}
                                style={{
                                  backgroundImage: `url(${test.image})`,
                                }}
                                alt={test.link}
                              />
                            )}
                          </Link>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          );
        })}
        {this.props.user.company === "admin" && (
          <Link
            to={`/admin/customers/dashboard/${this.props.match.params.company}/`}
            className="ttu ml3 f7"
          >
            Edit
          </Link>
        )}
        {this.state.from === "register" && customer.freeTrial && (
          <FreeTrialSalesModal
            doOpenModal
            isOnboarding
            unlockedTests={unlockedTests}
            unlock_limit={customer.unlock_limit}
          />
        )}
        {this.state.from === "register" && !customer.freeTrial && (
          <Modal
            open={true}
            onClose={this.closeRegModal}
            BackdropProps={{
              className: "dark-modal-bg",
            }}
          >
            <div
              className="box"
              style={{
                width: `${width}px`,
                height: `480px`,
                overflow: "hidden",
                top: (window.innerHeight - height) / 2,
                position: "absolute",
                left: (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div style={{ position: "relative" }}>
                <div className="bb b--black-05">
                  <div className="pv2 ph3 f3 dash-head">Getting started</div>
                </div>
                <div
                  style={{ margin: "24px auto 0", width: "560px" }}
                  dangerouslySetInnerHTML={{
                    __html: `<iframe width="560" height="315" src="https://www.youtube.com/embed/BP5ZHXvAcak?controls=0&autoplay=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                  }}
                />
              </div>
              <div className="pt3 center tc">
                <button
                  className="ui-button primary"
                  onClick={this.closeRegModal}
                >
                  Jump in
                </button>
              </div>
            </div>
          </Modal>
        )}
        {this.state.openModal && (
          <Modal open={true} onClose={this.closeModal}>
            <div
              className="box"
              style={{
                width: `${width}px`,
                height: `${height}px`,
                overflow: "hidden",
                top: (window.innerHeight - height) / 2,
                position: "absolute",
                left: (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div style={{ position: "relative" }}>
                <img
                  src={XIcon}
                  className="pointer modal-close"
                  style={{ position: "absolute", top: "8px", right: "8px" }}
                  onClick={this.closeModal}
                />
                <div className="bb b--black-05">
                  <div className="pv2 ph3 f3 dash-head">Tests From</div>
                </div>
                <div className="overflow-hidden">
                  <div
                    className="pb3 pl3 pr4 pt2 bb b--black-05 flex flex-wrap"
                    style={{ height: "350px", overflow: "auto" }}
                  >
                    {Object.keys(this.state.comp_lookup).map(c => (
                      <div className="w-25 pa2" key={`c-${c}`}>
                        {c}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pa3">
                  <div className="pt2">
                    <strong>
                      Not seeing a company you want to track?{" "}
                      <a
                        href="mailto:help@dowhatworks.io?subject=Company Request"
                        target="_blank"
                      >
                        Let us know
                      </a>
                      .
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
        <MovieFone
          user={this.props.user}
          company={this.props.match.params.company}
        />
      </>
    );
  }
}

export default Dashboard;
