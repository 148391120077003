import React from "react";
import TagFilter from "../../../components/TagFilter";
import request from "request";
import { baseApiUrl } from "../../../util";
class AddTagGroup extends React.Component {
  state = {
    loading: true,
    tagsToAdd: this.props.tagsToAdd || [],
    title: this.props.title || "",
  };
  filterTag = tag => {
    this.setState({ tagsToAdd: tag });
  };

  changeTitle = e => {
    this.setState({ title: e.currentTarget.value });
  };
  create = e => {
    e.preventDefault();
    const { tagsToAdd, title } = this.state;
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/tag_groups`,
        form: {
          title,
          tags: tagsToAdd.map(t => t.value).join("*|*"),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.props.callback();
          this.setState({ title: "", tagsToAdd: [] });
        }
      }
    );
  };
  edit = e => {
    e.preventDefault();
    const { tagsToAdd, title } = this.state;
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/tag_groups`,
        form: {
          id: this.props.id,
          title,
          tags: tagsToAdd.map(t => t.value).join("*|*"),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.props.callback();
        }
      }
    );
  };
  delete = e => {
    e.preventDefault();
    if (window.confirm("Sure you want to delete this?")) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/admin/tag_groups/delete`,
          form: {
            id: this.props.id,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.props.callback();
          }
        }
      );
    }
  };
  render() {
    const { tagsToAdd } = this.state;
    const { tags, editing } = this.props;
    return (
      <div className="mb3">
        <div>
          <label>Name</label>
        </div>
        <div className="mb2">
          <input
            type="text"
            onChange={this.changeTitle}
            defaultValue={this.props.title}
          />
        </div>
        <div className="mb2">
          <label>Tags</label>
        </div>
        <div className="mb2">
          <TagFilter
            options={tags.map(t => ({
              value: t.tag,
              label: t.tag,
            }))}
            callback={this.filterTag}
            defaultOptions={this.state.tagsToAdd}
            title="All Tags"
            isSticky={true}
            noSort
            fixAt={{
              width: 300,
              left: 12,
              top: 28,
            }}
            small
            title_slug="alltags"
          />
        </div>
        {tagsToAdd && (
          <div>
            {tagsToAdd.map(t => (
              <span className="pa2 bg--gray">{t.value}</span>
            ))}
          </div>
        )}
        <div className="mt3">
          {editing ? (
            <>
              <button onClick={this.edit}>Edit</button>
              <button onClick={this.delete} className="ml2 bg-red">
                delete
              </button>
            </>
          ) : (
            <button onClick={this.create}>Create</button>
          )}
        </div>
      </div>
    );
  }
}

export default AddTagGroup;
