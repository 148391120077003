import { useEffect, useState } from "react";
import {
  ALLOWED_CUSTOMER_FOR_SLACK_CONNECTION,
  baseApiUrl,
  encodeForm,
  fbAuth,
} from "../../util";
import * as cx from "classnames";
import * as querystring from "query-string";
import { Redirect } from "react-router-dom";
import Loading from "../../components/Loading";

const SlackCallback = props => {
  const qs = querystring.parse(window.location.search);
  const { returnUri, code, state, error: authError, error_description } = qs;

  const [error, setError] = useState(
    authError ? { message: error_description } : null
  );
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(null);

  if (!props.user) {
    return <Redirect to={`/login?returnUri=${window.location.href}`} />;
  }

  const { user } = props;

  const updateInfo = () =>
    new Promise((resolve, reject) => {
      fetch(`${baseApiUrl}/v1/user/slackCallback`, {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: user.token,
        },
        body: encodeForm({ code, state }),
      })
        .then(res => res.json())
        .then(data => {
          if (!data.success) {
            setLoading(false);
            setSuccess(false);
            setError({
              message: "Connection unsuccessfull, please try again!",
            });
            reject(true);
          } else {
            console.log(`${data}, connection successfull`);
            setLoading(false);
            setSuccess(true);
            resolve(true);
          }
        })
        .catch(e => {
          setLoading(false);
          setSuccess(false);
          setError({ message: "Connection unsuccessfull, please try again!" });
          reject(true);
        });
    });

  useEffect(() => {
    if (code) {
      setLoading(true);
      if (ALLOWED_CUSTOMER_FOR_SLACK_CONNECTION.includes(user.company)) {
        updateInfo();
      }
    } else {
      window.location.href = `/account?error=${"Something went wrong"}`;
    }
  }, []);
  useEffect(() => {
    if (success) {
      window.location.href = "/account?success=slack";
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      window.location.href = `/account?error=${
        error.message ? error.message : "Something went wrong"
      }`;
    }
  }, [error]);
  const labelClass = "db lh-copy black-80 b";
  const inputClass = "pa2 input-reset w-100 ba b--black-20 bg-white br2";
  const doKeywordsCallback = () => {};
  const toggleMenu = menu => e => {};
  // console.log({ user });

  return (
    <main className="pv4 mw7 center">
      <div
        className="box center br2 bg-white tl"
        style={{ position: "relative" }}
      >
        <div className="bb b--black-05 ph3 pt3 pb2">
          <h1>Connecting to Slack</h1>
        </div>
        <div className="tl pa3">
          {loading && (
            <p className="pt3">
              <Loading />
            </p>
          )}
        </div>
      </div>
    </main>
  );
};

export default SlackCallback;
