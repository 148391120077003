import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import ReportItemDisplay from "../../components/ReportItemDisplayNew";
import TagSearch from "../../components/TagSearch";
import { baseApiUrl } from "../../util";
import StarOn from "../../icons/favorite_on.svg";
import StarOff from "../../icons/favorite_off.svg";

class Alerts extends React.Component {
  state = {
    loading: true,
    customers: [],
    alerts: [],
    customer: null,
    tests: [],
    selected: [],
    customer_sectors: [],
    featured_test: null,
    new_customer: null,
    editing: null,
    default_name: new Date().toISOString().substr(0, 10),
    is_dirty: false,
    filter: "all",
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/customers?real=true`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        request(
          {
            url: `${baseApiUrl}/v1/admin/alerts`,
            headers: {
              Authorization: this.props.user.token,
            },
          },
          (err, res, data) => {
            const json2 = JSON.parse(data);
            this.setState({
              loading: false,
              customers: json.customers,
              alerts: json2.alerts,
              companies: json.companies,
              sectors: json.sectors,
            });
          }
        );
      }
    );
  };

  onChangeSector = sector => {
    setTimeout(() => {
      const companies = this.state.companies
        .filter(
          c => c.sectors && c.sectors.find(s => sector.find(x => x === s))
        )
        .map(c => c.name);

      this.setState({ sector: companies });
    }, 0);
  };
  onChange = e => {
    const customer = e.currentTarget.value;
    if (customer === "") {
      return;
    }
    this.setState({
      loading: true,
    });
    request(
      {
        url: `${baseApiUrl}/v1/admin/awesome_tests?company=${customer}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        const sectors = [];

        json.companies.forEach(c => {
          const company = this.state.companies.find(x => x.name === c);

          if (company && company.sectors) {
            company.sectors.forEach(s => {
              if (!sectors.includes(s)) {
                sectors.push(s);
              }
            });
          }
        });

        this.setState({
          loading: false,
          tests: json.tests,
          customer,
          customer_sectors: sectors,
          sector: json.companies,
          is_dirty: false,
        });
      }
    );
  };
  onChangeCopy = e => {
    const customer = e.currentTarget.value;
    this.setState({ new_customer: customer });
  };
  onChangeName = e => {
    this.setState({ name: e.currentTarget.value, is_dirty: true });
  };
  onChangeEdit = e => {
    const alertId = e.currentTarget.value;
    const alert = this.state.alerts.find(a => a.id === alertId);
    if (alert === "") {
      this.setState({
        loading: false,
        tests: [],
        customer: null,
        featured_test: null,
        name: null,
        selected: null,
        is_dirty: false,
      });
      return;
    }
    this.setState({
      loading: true,
    });
    request(
      {
        url: `${baseApiUrl}/v1/admin/awesome_tests?company=${alert.company}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);
        const sectors = [];

        json.companies.forEach(c => {
          const company = this.state.companies.find(x => x.name === c);

          if (company && company.sectors) {
            company.sectors.forEach(s => {
              if (!sectors.includes(s)) {
                sectors.push(s);
              }
            });
          }
        });

        this.setState({
          editing: alertId,
          loading: false,
          tests: json.tests,
          customer: alert.company,
          featured_test: alert.featured_test,
          name: alert.name,
          selected: alert.selected_tests,
          customer_sectors: sectors,
          sector: json.companies,
        });
      }
    );
  };
  edit = e => {
    this.setState({
      loading: true,
    });
    const { name, selected, featured_test, customer, editing } = this.state;
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/edit_alert`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: editing,
          name,
          selected: JSON.stringify(selected),
          featured_test,
          company: customer,
        },
      },
      (err, res, data) => {
        this.setState({
          loading: false,
        });
        if (err || res.statusCode >= 400) {
          alert("Looks like something went wrong!");
          return;
        }
        const json = JSON.parse(data);
        window.open(json.csv);
      }
    );
  };
  save = e => {
    this.setState({
      loading: true,
    });
    const {
      name,
      selected,
      featured_test,
      customer,
      new_customer,
      default_name,
    } = this.state;
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/add_alert`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          name: name || default_name,
          selected: JSON.stringify(selected),
          featured_test,
          company: new_customer || customer,
        },
      },
      (err, res, data) => {
        this.setState({
          loading: false,
        });
        if (err || res.statusCode >= 400) {
          alert("Looks like something went wrong!");
          return;
        }
        const json = JSON.parse(data);
        request(
          {
            url: `${baseApiUrl}/v1/admin/alerts`,
            headers: {
              Authorization: this.props.user.token,
            },
          },
          (err, res, data) => {
            const json2 = JSON.parse(data);
            this.setState({
              loading: false,
              alerts: json2.alerts,
            });
            window.open(json.csv);
          }
        );
      }
    );
  };

  doFilter = () => {
    const { sector, selected, featured_test, filter } = this.state;

    if (sector) {
      const filteredTests = this.state.tests.filter(t => {
        if (filter === "all" && sector.includes(t.company_name)) {
          return true;
        }

        if (selected.length) {
          if (selected.includes(t.id)) {
            return true;
          }
        }

        if (featured_test == t.id) {
          return true;
        }

        return false;
      });

      return filteredTests;
    } else {
      return this.state.tests;
    }
  };
  handleSelect = e => {
    const box = e.currentTarget;

    let { selected } = this.state;
    if (box.checked) {
      selected.push(box.value);
    } else {
      selected = selected.filter(x => x !== box.value);
    }

    this.setState({ selected, is_dirty: true });
  };
  sortBy = e => {
    this.setState({ sort: e.currentTarget.value });
  };

  onChangeFilter = filter => e => {
    this.setState({ filter });
  };
  featureTest = (id, feature) => e => {
    if (feature) {
      this.setState({ featured_test: id, is_dirty: true });
    } else {
      this.setState({ featured_test: null, is_dirty: true });
    }
  };
  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.sort === "ctime") {
      this.state.tests.sort((a, b) => (a.ctime >= b.ctime ? -1 : 1));
    } else if (this.state.sort === "ttime") {
      this.state.tests.sort((a, b) => {
        if (a.end_date && !b.end_date) {
          return 1;
        }
        if (!a.end_date && b.end_date) {
          return -1;
        }
        if (a.end_date && b.end_date) {
          return a.end_date >= b.end_date ? -1 : 1;
        }

        return a.start_date >= b.start_date ? -1 : 1;
      });
    } else {
      this.state.tests.sort((a, b) => (a.mtime >= b.mtime ? -1 : 1));
    }
    const tests = this.doFilter();
    return (
      <div className="overflow-hidden">
        <h1>Alerts</h1>
        <div className="pt3">
          <div>
            <div className="b">Edit an Alert</div>
            <select
              name="customer"
              onChange={this.onChangeEdit}
              value={this.state.editing}
            >
              <option value="">-- Pick an alert to edit --</option>
              {this.state.alerts.map(alert => (
                <option value={alert.id}>
                  {alert.company}: {alert.name}
                </option>
              ))}
            </select>
          </div>
          <div className="pt3">
            <div className="b">Add a new Alert</div>
            <select
              name="customer"
              onChange={this.onChange}
              value={this.state.customer}
            >
              <option value="">-- Pick a Company --</option>
              {this.state.customers.map(customer => (
                <option value={customer.key}>{customer.name}</option>
              ))}
            </select>
          </div>
          {(tests.length > 0 || this.state.filter !== "all") && (
            <div className="mt3">
              <div className="b">Sort tests by</div>
              <select
                name="sortby"
                onChange={this.sortBy}
                value={this.state.sort}
              >
                <option value="mtime">Last Modified Time</option>
                <option value="ctime">Create Time</option>
                <option value="ttime">Test Dates</option>
              </select>
              <TagSearch
                options={this.state.sectors.map(c => ({
                  label: c,
                  value: c,
                }))}
                defaultTags={this.state.customer_sectors.map(c => ({
                  label: c,
                  value: c,
                }))}
                callback={this.onChangeSector}
                placeholder="Filter by sector"
              />
              <div className="mt3">
                <b>Show:</b>{" "}
                {this.state.filter !== "all" ? (
                  <a href=" #" onClick={this.onChangeFilter("all")}>
                    All
                  </a>
                ) : (
                  <>All</>
                )}{" "}
                |{" "}
                {this.state.filter !== "selected" ? (
                  <a href=" #" onClick={this.onChangeFilter("selected")}>
                    Selected
                  </a>
                ) : (
                  <>Selected</>
                )}
              </div>
            </div>
          )}
          <div className="mt3">
            {tests.map((test, i) => {
              return (
                <div className="pt2">
                  Modified: {test.mtime} &middot; Created: {test.ctime}
                  <div className="flex">
                    <div style={{ paddingTop: "24px" }}>
                      <input
                        type="checkbox"
                        checked={this.state.selected.includes(test.id)}
                        onChange={this.handleSelect}
                        value={test.id}
                      />
                      {test.id === this.state.featured_test ? (
                        <img
                          src={StarOn}
                          alt="featured"
                          onClick={this.featureTest(test.id, false)}
                          width="20"
                          className="pointer"
                        />
                      ) : (
                        <img
                          src={StarOff}
                          alt="not featured"
                          onClick={this.featureTest(test.id, true)}
                          width="20"
                          className="pointer"
                        />
                      )}
                    </div>
                    <ReportItemDisplay
                      d={test}
                      company={test}
                      urlCompany="admin"
                      userCompany="admin"
                      i={i}
                      isAdminPage
                    />
                  </div>
                </div>
              );
            })}
            {this.state.tests && this.state.tests.length > 0 && (
              <div className="sticky-submit">
                {this.state.editing && (
                  <>
                    <span className="b mr1">Copy to</span>
                    <select
                      name="customer"
                      onChange={this.onChangeCopy}
                      value={this.state.new_customer || this.state.customer}
                    >
                      {this.state.customers.map(customer => (
                        <option value={customer.key}>{customer.name}</option>
                      ))}
                    </select>
                  </>
                )}
                {this.state.featured_test && (
                  <>
                    <span className="ml3 b mr3 green">Has Featured Test</span>
                    {this.state.selected.includes(this.state.featured_test) && (
                      <span className="red mr3">
                        Featured test is included in other tests
                      </span>
                    )}
                  </>
                )}
                {this.state.selected && this.state.selected.length > 0 && (
                  <span className="ml3 mr2">
                    Tests:{" "}
                    <span
                      className={`b ${
                        this.state.selected.length !== 3 ? "red" : "green"
                      }`}
                    >
                      {this.state.selected.length}
                    </span>
                  </span>
                )}
                <input
                  type="text"
                  placeholder="Name this alert"
                  value={this.state.name || this.state.default_name}
                  onChange={this.onChangeName}
                />
                <button
                  className="ui-button primary ml3"
                  onClick={
                    this.state.editing &&
                    (!this.state.new_customer ||
                      this.state.customer === this.state.new_customer)
                      ? this.edit
                      : this.save
                  }
                >
                  {this.state.editing ? (
                    <>
                      {this.state.new_customer &&
                      this.state.customer !== this.state.new_customer
                        ? `Copy`
                        : this.state.is_dirty
                        ? `Edit`
                        : `Download CSV`}
                    </>
                  ) : (
                    <>Create</>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Alerts;
