import React from "react";
import { Route, Link, Redirect } from "react-router-dom";
import "./admin.css";

import Admin from "./Admin";
import Users from "./Users";
import Emails from "./Emails";
import Alerts from "./Alerts";
import Reports from "./Reports";
import AddScreenshots from "./AddScreenshots";
import AddReport from "./AddReport";
import AddSem from "./AddSem";
import Tags from "./Tags";
import TagGroups from "./TagGroups";
import Companies from "./Companies";
import Customers from "./Customers";
import SemCustomers from "./SemCustomers";
import Keywords from "./Keywords";
import QueueDraft from "./QueueDraft";
import QueueCsv from "./QueueCsv";
import UrlLogCsv from "./UrlLogCsv";
import QueueDashboard from "./QueueDashboard";
import ContentDashboard from "./ContentDashboard";
import PublishingDashboard from "./PublishingDashboard";
import UserDashboard from "./UserDashboard";
import Sem from "./Sem";
import SemCompanies from "./SemCompanies";
import Sectors from "./Sectors";
import ShareTests from "./ShareTests";
import { Tag } from "reactstrap";

class Index extends React.Component {
  state = {};
  componentDidMount() {}
  render() {
    if (!this.props.user) {
      return <Redirect to={`/login?returnUri=${window.location.href}`} />;
    } else if (this.props.user.company !== "admin") {
      return <Redirect to="/" />;
    }
    return (
      <div className="tl pa3">
        <div className="pt3 pb3">
          <div>
            <b>Admin: </b>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/tests"
              title="see reports"
            >
              Customer Tests/SEM
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/tags"
              title="Edit tags"
            >
              Tags
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/companies"
              title="Edit companies"
            >
              Companies
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/sem_companies"
              title="Edit SEM companies"
            >
              SEM Companies
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/sectors"
              title="Edit sectors"
            >
              Sectors
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/share_tests"
              title="Edit Share tests"
            >
              Share Tests
            </Link>
          </div>
          <div className="pt3">
            <b>Queues: </b>
            <Link
              className="ml3 link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/draft"
              title="Draft Queue"
            >
              Draft
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/followup"
              title="Feedback Queue"
            >
              Screener Feedback
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/analyst_feedback"
              title="Approval Queue"
            >
              Writer Feedback
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/approval"
              title="Approval Queue"
            >
              Approval
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/publishing"
              title="Publishing Queue"
            >
              Publishing
            </Link>
            <span className="mr3">
              <b>|</b>
            </span>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/edit"
              title="Edit Queue"
            >
              Edit
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/hold"
              title="Hold Queue"
            >
              Hold
            </Link>
            <span className="mr3">
              <b>|</b>
            </span>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/live-check"
              title="Live Check"
            >
              Live Check Queue
            </Link>
            <span className="mr3">
              <b>|</b>
            </span>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/validate-end"
              title="Validation Queue"
            >
              Validation
            </Link>
            <span className="mr3">
              <b>|</b>
            </span>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/called-30"
              title="Called Tests Queue"
            >
              Called Tests (30 days)
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/called-60"
              title="Called Tests Queue"
            >
              Called Tests (60 days)
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/queue/rejected_called"
              title="Called Tests to Fix Queue"
            >
              Called Tests To Fix
            </Link>
          </div>
          <div className="pt3">
            <b>Dashboards:</b>
            <Link
              className="link dim gray f6 f5-ns dib ml3 mr3"
              to="/admin/content_dashboard"
              title="Content"
            >
              Content
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/user_dashboard"
              title="Activity"
            >
              Activity
            </Link>
            <Link
              className="link dim gray f6 f5-ns dib mr3"
              to="/admin/publishing_dashboard"
              title="Publishing"
            >
              Publishing
            </Link>
          </div>
        </div>
        <Route
          path="/admin"
          exact
          render={props => (
            <Admin
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/sectors"
          exact
          render={props => (
            <Sectors
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/url_log_csv"
          exact
          render={props => (
            <UrlLogCsv
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/queue_csv"
          exact
          render={props => (
            <QueueCsv
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/queue/dashboard"
          exact
          render={props => (
            <QueueDashboard
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/publishing_dashboard"
          exact
          render={props => (
            <PublishingDashboard
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/content_dashboard"
          exact
          render={props => (
            <ContentDashboard
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/user_dashboard"
          exact
          render={props => (
            <UserDashboard
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/queue/draft"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="draft_review"
            />
          )}
        />
        <Route
          path="/admin/queue/hold"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="draft_hold"
            />
          )}
        />
        <Route
          path="/admin/queue/edit"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="edit"
            />
          )}
        />
        <Route
          path="/admin/queue/rejected_called"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="rejected_called"
            />
          )}
        />
        <Route
          path="/admin/queue/called-30"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="called_30"
            />
          )}
        />
        <Route
          path="/admin/queue/validate-end"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="validate-end"
            />
          )}
        />
        <Route
          path="/admin/queue/called-60"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="called_60"
            />
          )}
        />
        <Route
          path="/admin/queue/publish"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="publish_review"
            />
          )}
        />
        <Route
          path="/admin/queue/publishing"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="publish_review"
            />
          )}
        />
        <Route
          path="/admin/queue/approval"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="approval_review"
            />
          )}
        />
        <Route
          path="/admin/queue/analyst_feedback"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="analyst_feedback"
            />
          )}
        />
        <Route
          path="/admin/queue/followup"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="analyst_review"
            />
          )}
        />
        <Route
          path="/admin/queue/live-check"
          exact
          render={props => (
            <QueueDraft
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="live-check"
            />
          )}
        />
        <Route
          path="/admin/sem_customers"
          exact
          render={props => (
            <SemCustomers
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/customers"
          exact
          render={props => (
            <Customers
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/customers/dashboard/:id"
          exact
          render={props => (
            <Customers
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/companies"
          exact
          render={props => (
            <Companies
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/share_tests"
          exact
          render={props => (
            <ShareTests
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />

        <Route
          path="/admin/reports/new"
          exact
          render={props => (
            <AddReport
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/sem/edit/:id"
          exact
          render={props => (
            <AddSem
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/sem/new"
          exact
          render={props => (
            <AddSem
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/reports"
          exact
          render={props => (
            <Reports
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/tests"
          exact
          render={props => (
            <Reports
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/reports/:id"
          exact
          render={props => (
            <Reports
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/screenshots/edit/:id"
          exact
          render={props => (
            <AddScreenshots
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/sem/:report"
          exact
          render={props => (
            <Sem {...props} user={this.props.user} authed={this.props.authed} />
          )}
        />
        <Route
          path="/admin/sem_companies"
          exact
          render={props => (
            <SemCompanies
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/screenshots/new"
          exact
          render={props => (
            <AddScreenshots
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/tag_groups"
          exact
          render={props => (
            <TagGroups
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/alerts"
          exact
          render={props => (
            <Alerts
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/keywords"
          exact
          render={props => (
            <Keywords
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/tags"
          exact
          render={props => (
            <Tags
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />

        <Route
          path="/admin/users"
          exact
          render={props => (
            <Users
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/admin/emails"
          exact
          render={props => (
            <Emails
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
      </div>
    );
  }
}

export default Index;
