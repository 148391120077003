import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import EditCompany from "./components/EditCompany";
import TagSearch from "../../components/TagSearch";
import { baseApiUrl } from "../../util";

class Companies extends React.Component {
  state = {
    loading: true,
    add: false,
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/companies`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        let sects = [{ display_name: "No Sector", key: "none" }];

        sects = sects.concat(json.new_sectors);

        this.setState({
          loading: false,
          companies: json.companies,
          customers: json.customers,
          sectors: json.sectors,
          new_sectors: sects,
        });
      }
    );
  };
  doAdd = e => {
    e.preventDefault();
    this.setState({ add: true });
  };
  doClose = e => {
    e.preventDefault();
    this.setState({ add: false });
  };
  changeSector = sectors => {
    this.setState({
      selectedSectors: sectors,
    });
  };

  filterBySectors = () => {
    const { selectedSectors } = this.state;

    if (selectedSectors && selectedSectors.length > 0) {
      const companies = this.state.companies.filter(c => {
        let filter = false;

        selectedSectors.forEach(ssect => {
          if (
            ssect === "none" &&
            (!c.new_sectors || c.new_sectors.length === 0)
          ) {
            filter = true;
          }

          if (c.new_sectors && c.new_sectors.includes(ssect)) {
            filter = true;
          }
        });
        return filter;
      });

      return companies;
    }
    return this.state.companies;
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const { sectors, new_sectors } = this.state;

    const companies = this.filterBySectors();

    return (
      <div>
        <div className="w-50 mt3 mb3">
          <TagSearch
            options={this.state.new_sectors.map(c => ({
              label: c.display_name,
              value: c.key,
            }))}
            callback={this.changeSector}
            placeholder="Filter by sector"
          />
        </div>
        <h1>Companies</h1>
        {this.state.add ? (
          <EditCompany
            new
            customers={sectors}
            new_sectors={new_sectors}
            token={this.props.user.token}
            callback={this.getData}
            spider
            closeCallback={this.doClose}
          />
        ) : (
          <a href=" #" className="ml2 mv4 b" onClick={this.doAdd}>
            Add a new company
          </a>
        )}
        {companies.map((company, i) => (
          <EditCompany
            tld={company.tld}
            name={company.name}
            key={company.tld}
            key={`${company.tld}-${i}`}
            customers={sectors}
            new_sectors={new_sectors}
            companyCustomers={company.sectors}
            companySectors={company.new_sectors}
            nicknames={company.nicknames}
            spider={company.spider}
            deactivated={company.status && company.status === "discontinued"}
            token={this.props.user.token}
            callback={this.getData}
          />
        ))}
      </div>
    );
  }
}

export default Companies;
