import React from "react";
import * as cx from "classnames";
import { baseApiUrl, getData, imageUrl } from "../util";
import NoteDisplay from "../components/NoteDisplay";
import ZoomIn from "../icons/ZoomIn.svg";
import ZoomOut from "../icons/ZoomOut.svg";

const options = {
  buttons: ["button", "buttons"],
  redesign: ["redesign", "redesigned"],
  "sign up": [
    "sign-up",
    "sign-up flow",
    "google signup/login",
    "signup",
    "signup fields",
    "facebook signup/login",
    "microsoft signup/login",
    "sign up fields",
    "contact",
    "steps in flow",
    "fields",
    "address field",
    "number of input fields per line",
    "field",
  ],
  "social proof": [
    "social",
    "logos",
    "company logo",
    "customer logos",
    "trending",
    "proof",
    "award callout",
    "popular",
    "customer reviews",
    "reviews",
    "customer review summary",
    "star rating",
    "star",
    "stars",
  ],
  everything: null,
};
class Game extends React.Component {
  state = {
    tests: [],
    allTests: [],
    test: 0,
    right: 0,
    wrong: 0,
    total: 10,
    zoomIn: false,
    zoomOut: true,
    option: "buttons",
  };
  componentDidMount() {
    this.getData();
  }
  reload = () => {
    window.location.reload();
  };
  pickRandom = (data, size) => {
    const len = data.length;

    const previous = [];
    const items = [];

    const selectRand = () => {
      let rand = Number(Math.random() * len).toFixed();

      if (previous.includes(rand)) {
        return selectRand();
      }
      previous.push(rand);

      return rand;
    };

    for (let i = 0; i < size; i++) {
      let rand = selectRand();
      items.push(data[rand]);
    }
    return items;
  };
  pickRandomScreenshot = ss => {
    const champ = ss[0];
    let loser = ss[1];

    if (ss.length === 2) {
      const arr = [champ, loser];

      return arr.sort((a, b) => {
        return Math.random() >= 0.5 ? -1 : 1;
      });
    }

    let ran = Number((Math.random() * ss.length - 1).toFixed()) + 1;
    const arr = [champ, ss[ran]];

    return arr.sort((a, b) => {
      return Math.random() >= 0.5 ? -1 : 1;
    });
  };
  getData = () => {
    const apiUrl = `${baseApiUrl}/v1/report?company=admin&newTags=true`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      false,
      true
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        this.setState({
          loading: true,
          authed: false,
          accessDenied: res.statusCode === 400,
        });
        return;
      }
      this.get10Tests(data.data);
    });
  };
  get10Tests = data => {
    const allTests = [];
    Object.keys(data).forEach(x => {
      data[x].splits.forEach(item => allTests.push(item));
    });
    let endedTests = allTests.filter(
      item =>
        item.status === "ended" && item.note_tags && item.note_tags.length > 0
    );

    if (this.state.option !== "everything") {
      endedTests = endedTests.filter(d => {
        let filtered = false;
        if (d.note_tags && d.note_tags.length > 0) {
          options[this.state.option].forEach(t => {
            if (
              !filtered &&
              (d.note_tags.includes(t) ||
                d.headline_tag.toLowerCase().indexOf(` ${t} `) !== -1 ||
                d.headline_tag.toLowerCase().indexOf(`${t} `) !== -1 ||
                d.headline_tag.toLowerCase().indexOf(` ${t}`) !== -1)
            ) {
              filtered = true;
            }
          });
        }
        return filtered;
      });
    }

    const tests = this.pickRandom(endedTests, 10);

    const contestants = tests.map(item => {
      return {
        company_name: item.company_name,
        headline_tag: item.headline_tag,
        screenshots: this.pickRandomScreenshot(item.screenshots),
      };
    });
    this.setState({
      tests: contestants,
      allTests: data,
    });
  };
  pickWinner = i => e => {
    const thisTest = this.state.tests[this.state.test];
    let pickedWinner = thisTest.screenshots[i].champ;

    this.setState({
      pickedWinner,
      showWinner: true,
      right: pickedWinner ? this.state.right + 1 : this.state.right,
      wrong: !pickedWinner ? this.state.wrong + 1 : this.state.wrong,
    });

    setTimeout(() => {
      let finished = false;

      let test = this.state.test + 1;

      if (test >= this.state.total) {
        finished = true;
        test = 9;
      }

      const score = ((this.state.right / this.state.total) * 100).toFixed();

      this.setState({ showWinner: false, test, finished, score });
    }, 1000);
  };
  zoomin = () => {
    this.setState({
      zoomIn: true,
      zoomOut: false,
    });
  };
  zoomout = () => {
    this.setState({
      zoomOut: true,
      zoomIn: false,
    });
  };
  handleOptions = e => {
    const { value } = e.currentTarget;

    this.setState(
      {
        option: value,
      },
      () => {
        this.get10Tests(this.state.allTests);
      }
    );
  };
  render() {
    if (!this.state || this.state.tests.length === 0) {
      return <b>Loading...</b>;
    }

    if (this.state.tests && this.state.tests.length > 0) {
      const thisTest = this.state.tests[this.state.test];

      return (
        <div className="mt3 center">
          <h1>Pick the winner</h1>
          <div className="mv3">
            {this.state.test + 1}/{this.state.total} | Right: {this.state.right}{" "}
            | Wrong: {this.state.wrong}
          </div>
          <div className="center">
            {this.state.finished ? (
              <>
                <h1>You're all done!</h1>
                {this.state.score <= 60 && (
                  <p>
                    You scored <span className="red">{this.state.score}%</span>
                  </p>
                )}
                {this.state.score > 60 && this.state.score < 80 && (
                  <p>
                    You scored{" "}
                    <span className="yellow">{this.state.score}%</span>
                  </p>
                )}
                {this.state.score >= 80 && (
                  <p>
                    You scored{" "}
                    <span className="green">{this.state.score}%</span>
                  </p>
                )}

                <p>
                  <a
                    onClick={this.reload}
                    className="pointer pointer button pa2 bg-white white br3"
                  >
                    Play again
                  </a>
                </p>
              </>
            ) : (
              <>
                {this.state.showWinner && (
                  <div>
                    {this.state.pickedWinner && (
                      <h1 className="green">You picked correctly!</h1>
                    )}
                    {!this.state.pickedWinner && (
                      <h1 className="red">You chose&hellip; Poorly.</h1>
                    )}
                  </div>
                )}
                {!this.state.showWinner && (
                  <div class="center">
                    <div className="mb3">
                      <select name="choices" onChange={this.handleOptions}>
                        {Object.keys(options).map(x => (
                          <option value={x} selected={x === this.state.option}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <span
                        onClick={this.zoomin}
                        className={cx({
                          "pointer icon-circle icon-circle-blue mr3": true,
                          "shadow-1": !this.state.zoomIn,
                          "icon-circle-gray": this.state.zoomIn,
                        })}
                      >
                        <img
                          src={ZoomIn}
                          width="18px"
                          alt="Zoom in"
                          style={{ marginBottom: "1px" }}
                        />
                      </span>
                      <span
                        onClick={this.zoomout}
                        className={cx({
                          "pointer icon-circle icon-circle-blue": true,
                          "shadow-1": !this.state.zoomOut,
                          "icon-circle-gray": this.state.zoomOut,
                        })}
                      >
                        <img
                          src={ZoomOut}
                          width="18px"
                          alt="Zoom out"
                          style={{ marginBottom: "1px" }}
                        />
                      </span>
                    </div>
                    <b>{thisTest.company_name}</b> is testing{" "}
                    {thisTest.headline_tag}
                    <div className="mt2">
                      <div className="flex flex-row items-start justify-center">
                        {thisTest.screenshots.map((s, i) => {
                          let notes = null;
                          if (s.notes && s.notes.length) {
                            s.notes.forEach((n, y) => {
                              if (!n || n.trim() === "") {
                                if (s.pos && s.pos[y]) {
                                  delete s.pos[y];
                                }
                              }
                            });
                            notes = s.notes.filter(n => n && n.trim() !== "");
                          }
                          return (
                            <div
                              className="mt3 mr3"
                              style={{
                                width: this.state.zoomOut ? "300px" : "600px",
                              }}
                            >
                              {notes && (
                                <div className="screenshotItem-bottom mb3">
                                  <div
                                    className={cx({
                                      "f6 tc-s screenshotItem-notesHeader": true,
                                    })}
                                  ></div>
                                  <div className="screenshotItem-notes">
                                    {notes.map((n, x) => {
                                      return (
                                        <div
                                          className={cx({
                                            "note-odd": x % 2 !== 0,
                                          })}
                                          key={`note__${i}_${x}`}
                                        >
                                          <NoteDisplay
                                            key={`${i}-${n}`}
                                            text={n}
                                            isAdminPage={this.props.isAdminPage}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-row items-start justify-center">
                        {thisTest.screenshots.map((s, i) => (
                          <img
                            src={imageUrl(s.src, 600, 0)}
                            width={this.state.zoomIn ? 600 : 300}
                            className="pointer mr3"
                            onClick={this.pickWinner(i)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
  }
}

export default Game;
