import request from "request";
import { baseApiUrl } from "../../../util";

const saveReport = (state, token, id, qid, callback) => {
  const screenshots = state.screenshots.filter(s => s.selected);
  const {
    url,
    company,
    company_name,
    report,
    childReports,
    status,
    scope,
    type,
    awesomeness,
    description,
    summary,
    tags,
    page_type,
    page_type_other,
    notes,
    notes_v2,
    start_date,
    end_date,
    headline_tag,
    module_name,
    highlight,
    related_test,
    global_no_winner,
    copy,
    last_seen_live,
    potential_end_date,
    potential_winner,
    potential_winner_other,
    serverMtime,
  } = state;
  let head = null;
  if (headline_tag) {
    head = typeof headline_tag === "string" ? headline_tag : headline_tag.value;
  }

  if (state.editing) {
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: `${baseApiUrl}/v1/screenshot/edit`,
        form: {
          id: id,
          url,
          company,
          company_name,
          report,
          status,
          scope,
          type,
          awesomeness,
          description,
          summary,
          tags: (tags && tags.join("*|*")) || null,
          childReports: (childReports && childReports.join("*|*")) || null,
          page_type: JSON.stringify(page_type),
          notes,
          start_date,
          end_date,
          headline_tag: head,
          challenge_v2: JSON.stringify(module_name),
          screenshots: JSON.stringify(screenshots),
          highlight,
          related_test,
          no_winner: global_no_winner,
          copy,
          notes_v2: notes_v2 ? JSON.stringify(notes_v2) : null,
          last_seen_live,
          potential_end_date,
          potential_winner,
          potential_winner_other,
          serverMtime,
        },
      },
      (err, resp) => {
        callback(err, resp);
      }
    );
  } else {
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: `${baseApiUrl}/v1/screenshot`,
        form: {
          url,
          qid,
          company,
          company_name,
          report,
          status,
          scope,
          type,
          awesomeness,
          description,
          summary,
          tags: tags.join("*|*"),
          childReports: (childReports && childReports.join("*|*")) || null,
          page_type: JSON.stringify(page_type),
          notes,
          start_date,
          end_date,
          headline_tag: head,
          challenge_v2: JSON.stringify(module_name),
          screenshots: JSON.stringify(screenshots),
          highlight,
          related_test,
          copy,
          last_seen_live,
          potential_end_date,
          potential_winner,
          potential_winner_other,
        },
      },
      (err, resp) => {
        callback(err, resp);
      }
    );
  }
};

const getScreenshotData = (id, token, callback, editing) => {
  const url = editing
    ? `${baseApiUrl}/v1/screenshots?id=${id}&editing=true`
    : `${baseApiUrl}/v1/screenshots?id=${id}`;
  request(
    {
      url,
      headers: {
        Authorization: token,
      },
    },
    (err, res, data) => {
      if (err) {
        callback(err, res, data);
        return;
      }
      const json = JSON.parse(data);
      const { company } = json.screenshots;
      request(
        {
          url: `${baseApiUrl}/reports?company=${company}`,
          headers: {
            Authorization: token,
          },
        },
        (err, res, data) => {
          json.reports = JSON.parse(data).reports;
          callback(err, res, json);
        }
      );
    }
  );
};
const getServerMTimeForTest = (id, token, callback) => {
  const url = `${baseApiUrl}/v1/screenshot/mtime?id=${id}`;
  request(
    {
      url,
      headers: {
        Authorization: token,
      },
    },
    (err, res, data) => {
      if (err) {
        callback(err, res, false);
        return;
      }
      const json = JSON.parse(data);
      callback(err, res, json.mtime);
    }
  );
};
const getScreenshots = (id, ctime, callback) => {
  fetch(
    `https://1bsdoamdrl.execute-api.us-east-1.amazonaws.com/dev/getScreenShots?id=${id}&ctime=${ctime}`
  ).then(response => {
    response.json().then(json => {
      console.info(json);
      const items = [];
      json.screens.fileLocations.forEach((file, i) => {
        const fn = file.split("/").pop();
        if (fn.endsWith(".png") || fn.endsWith(".jpg")) {
          const info = json.screens.comparison[fn];
          items.push({
            src: file,
            weight: `${Number(info.weight).toFixed(0)}%`,
            selected: true,
            champ: false,
          });
        }
      });
      json.items = items;
      callback(json);
    });
  });
};

export { saveReport, getScreenshotData, getScreenshots, getServerMTimeForTest };
