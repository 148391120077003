import React, { Component } from "react";
import { baseApiUrl, capitalize, addCommas, getCurrentTime } from "../util";
import cx from "classnames";
import request from "request";
import "./tagfilter.css";
import TagFilter from "./TagFilter";
import TagPicker from "./TagPicker";
import CompanyIcon from "../icons/companies_gray.svg";
import WhatsTestedIcon from "../icons/WhatsTested_gray.svg";
import CollectionsIcon from "../icons/Collections_gray.svg";
import PageIcon from "../icons/page.svg";
import DesktopIcon from "../icons/desktop_gray.svg";
import MobileIcon from "../icons/mobile_gray.svg";

class FakeOmniFilter extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.state = {
      showing: false,
      selectedTags: this.props.defaultOptions || [],
      synonym_match: this.props.synonyms
        ? Object.keys(this.props.synonyms)
        : undefined,
      title_slug: `fof-${getCurrentTime()}-${props.title_slug || ""}`,
      rect: { top: 0, left: 0 },
    };
  }

  render() {
    const { status, platform, isAdminPage } = this.props;
    const fixAt = { width: 300, right: -40, top: 32 };
    const { title_slug } = this.state;
    const statuses = [
      { label: "Live / Ended", value: "all" },
      { label: "Ended", value: "ended" },
      { label: "Live", value: "live" },
    ];
    const platforms = [
      { label: "Mobile / Desktop", value: "all" },
      { label: "Mobile", value: "mobile" },
      { label: "Desktop", value: "desktop" },
    ];

    return (
      <div
        className={cx({
          "tag-filter-container omnifilter-container flex": true,
          "f6 small-tag-filter": this.props.small,
        })}
        id={title_slug}
      >
        <div className="view-count">
          {isAdminPage ? (
            <span className="pill-purple b">&nbsp; &nbsp; &nbsp;</span>
          ) : (
            <>
              Viewing{" "}
              <span className="pill-purple b">&nbsp; &nbsp; &nbsp;</span> tests
            </>
          )}
        </div>
        {isAdminPage && (
          <TagFilter
            options={[]}
            icon={""}
            title="Sectors"
            isSticky={true}
            onRight
            noSort
            fixAt={fixAt}
            small
            disabled
            title_slug="sectors"
          />
        )}
        <TagFilter
          options={[]}
          synonyms={this.props.synonyms}
          fullText
          icon={WhatsTestedIcon}
          title="What's tested"
          isSticky={true}
          onRight
          noSort
          fixAt={fixAt}
          small
          disabled
          title_slug="whatstested"
        />
        <TagFilter
          options={[]}
          icon={CollectionsIcon}
          title="Collections"
          isSticky={true}
          onRight
          fixAt={fixAt}
          small
          disabled
          title_slug="collections"
        />
        <TagFilter
          options={[]}
          icon={CompanyIcon}
          title="Companies"
          isSticky={true}
          onRight
          fixAt={fixAt}
          small
          disabled
          title_slug="compnaies"
        />
        <TagFilter
          options={[]}
          icon={PageIcon}
          title="Pages"
          isSticky={true}
          onRight
          fixAt={fixAt}
          small
          disabled
          title_slug="pages"
        />
        <TagPicker
          options={[]}
          defaultOptions={{
            label: "Live / Ended",
            value: "all",
          }}
          noSort
          isSticky={true}
          onRight
          fixAt={{ width: "150px", right: -20, top: 32 }}
          small
          disabled
          title_slug="status"
        />
        <TagPicker
          options={[]}
          defaultOptions={{
            label: "Mobile / Desktop",
            value: "all",
          }}
          noSort
          isSticky={true}
          onRight
          fixAt={{ width: "150px", right: -44, top: 32 }}
          usePlatformIcons
          small
          disabled
          title_slug="paltform"
        />
      </div>
    );
  }
}

export default FakeOmniFilter;
