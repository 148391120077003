import React, { Component } from "react";
import { baseApiUrl } from "../../util";
import request from "request";
import UploadIcon from "../../icons/upload.svg";

const MAX_IMAGE_SIZE = 4000000;

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: null,
      error: null,
    };
  }
  onUpload = (image, name) => {
    request(
      {
        url: `${baseApiUrl}/upload?name=${name}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      async (err, resp, data) => {
        const response = JSON.parse(data);
        let binary = atob(image.split(",")[1]);
        let blobData = new Blob([binary], {
          type: "text/csv",
        });
        await fetch(response.uploadURL, {
          method: "PUT",
          body: blobData,
        });
        this.fileCount++;
        this.files.push(response.uploadURL.split("?")[0]);
        if (this.fileCount === this.numFiles) {
          this.props.callback(this.files);
          this.files = [];
          this.fileCount = 0;
          this.setState({
            loading: false,
            url: null,
            error: null,
          });
        }
      }
    );
  };
  fileCount = 0;
  numFiles = 0;
  files = [];
  createFile = file => {
    let reader = new FileReader();
    reader.onload = e => {
      console.info(e.target.result);
      if (!e.target.result.includes("data:text/csv")) {
        return alert("Wrong file type - CSV only.");
      }
      if (atob(e.target.result.split(",")[1]).length > MAX_IMAGE_SIZE) {
        return alert("CSV is too large - 4Mb maximum");
      }
      this.onUpload(e.target.result, file.name);
    };
    try {
      reader.readAsDataURL(file);
    } catch (e) {
      console.info(e);
    }
  };
  onFileChange = e => {
    this.setState({
      loading: true,
    });
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.numFiles = files.length;
    for (let f in files) {
      this.createFile(files[f]);
    }
  };
  render() {
    const { loading } = this.state;
    return (
      <>
        {loading && <span>Uploading&hellip;</span>}
        {!loading && (
          <>
            <label for="file-upload" className="pointer link">
              <img
                src={UploadIcon}
                alt="Upload"
                title="Upload CSV"
                style={{ height: "20px", marginLeft: "8px" }}
                className="pointer"
              />
            </label>
            <input
              type="file"
              name="upload"
              id="file-upload"
              onChange={this.onFileChange}
              style={{ display: "none" }}
            />
          </>
        )}
      </>
    );
  }
}

export default Upload;
