import React, { Component } from "react";
import request from "request";
import Modal from "@material-ui/core/Modal";
import TagFilter from "./TagFilter";
import BlueCheck from "../icons/check_blue.svg";
import KeywordIcon from "../icons/keywords_gray.svg";
import XIcon from "../icons/x.svg";
import Search from "../icons/search.svg";
import Edit from "../icons/edit_blue.svg";
import { baseApiUrl } from "../util";

class KeywordGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      keywords: this.props.keywords,
      add_kws: [],
      modalOpen: false,
      name: "",
      loaded: false,
      values: [],
      id: null,
      isEditing: false,
      name: "",
      raw_kws: [],
      edit_priority: props.editPriority,
      all_kws: [],
      showingEdit: true,
    };
    this.inputRef = React.createRef();
    this.editRef = React.createRef();
  }
  inputRef: null;
  editRef: null;

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({
      loaded: false,
    });
    request.get(
      {
        url: `${baseApiUrl}/v1/kw_group?report=${this.props.reportId}`,
        headers: {
          Authorization: this.props.token,
        },
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        const kws = [];
        if (json.kw_group) {
          json.kw_group.forEach(k => {
            k.keywords.forEach(kw => {
              if (!kws.includes(kw)) {
                kws.push(kw);
              }
            });
          });
        }
        let final_kws = this.props.keywords;
        kws.forEach(kw => {
          if (!final_kws.includes(kw)) {
            final_kws.push(kw);
          }
        });
        this.setState({
          options: json.kw_group,
          loaded: true,
          all_keywords: final_kws,
        });
      }
    );
  };

  open = () => {
    this.setState({
      modalOpen: true,
      isEditing: false,
      showingEdit: true,
    });
  };
  modalClose = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      modalOpen: false,
      name: "",
      id: "",
      isEditing: false,
      showEdit: false,
      priority: false,
    });
  };
  onChangeName = e => {
    this.setState({
      name: e.currentTarget.value,
    });
  };
  onChangePriority = e => {
    this.setState({
      priority: e.currentTarget.checked,
    });
  };

  changeKW = kw => e => {
    const { add_kws } = this.state;

    let final_kws;

    if (add_kws.includes(kw)) {
      final_kws = add_kws.filter(k => k !== kw);
    } else {
      add_kws.push(kw);
      final_kws = add_kws;
    }
    this.setState({ add_kws: final_kws });
  };
  callback = values => {
    const kws = [];

    values.forEach(v => {
      if (v.group) {
        v.value.forEach(k => {
          if (!kws.includes(k)) {
            kws.push(k);
          }
        });
      } else {
        kws.push(v.value);
      }
    });
    this.setState({ add_kws: kws, raw_kws: values });
    if (this.props.token) {
      request({
        url: `${baseApiUrl}/v1/t?url=${
          window.location.href
        }%3Fkw_groups=${values.map(v => v.label).join("%26")}`,
        headers: {
          Authorization: this.props.token,
        },
      });
    }
    this.props.callback(values);
  };
  doSave = e => {
    e.preventDefault();
    this.modalClose();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/kw_group`,
        form: {
          report: this.props.reportId,
          name: this.state.name,
          keywords: this.state.add_kws.join("*|*"),
          priority: this.state.priority || false,
        },
      },
      (err, resp) => {
        if (err) {
          alert(err);
        }
        this.getData();
      }
    );
  };
  kws = [];
  doEdit = e => {
    e.preventDefault();
    this.modalClose();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/kw_group/edit`,
        form: {
          id: this.state.id,
          name: this.state.name,
          keywords: this.state.add_kws.join("*|*"),
          priority: this.state.priority || false,
        },
      },
      (err, resp) => {
        if (err) {
          alert(err);
        }
        this.getData();
      }
    );
  };
  doDelete = tag => {
    this.modalClose();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/kw_group/delete`,
        form: {
          id: tag.id,
        },
      },
      (err, resp) => {
        if (err) {
          alert(err);
        }
        this.getData();
      }
    );
  };
  showEdit = tag => {
    this.kws = [...this.state.add_kws];

    this.setState({
      isEditing: true,
      showingEdit: false,
      id: tag.id,
      name: tag.label,
      add_kws: tag.value,
      priority: tag.priority || false,
      modalOpen: true,
      hasPriority: this.state.options.find(o => o.priority),
      edit_priority: false,
    });
  };
  timeout: null;
  onKWSearch = e => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const value = e.currentTarget.value.toLowerCase();
    this.timeout = setTimeout(() => {
      let keywords = this.state.all_keywords;

      if (value && value.trim() !== "") {
        keywords = this.state.all_keywords.filter(kw =>
          kw.toLowerCase().includes(value)
        );
      }

      this.setState({ keywords, search: value });
    }, 100);
  };

  clearSearch = e => {
    e.preventDefault();
    this.inputRef.current.value = "";
    this.inputRef.current.focus();
    this.setState({ value: null, keywords: this.state.all_keywords });
  };
  showEditInput = e => {
    this.setState({ showingEdit: true });
    setTimeout(() => {
      if (this.editRef && this.editRef.current) {
        this.editRef.current.focus();
      }
    }, 100);
  };

  render() {
    const { modalOpen, add_kws, loaded, name, isEditing, keywords, search } =
      this.state;

    const width = 600;

    const opts = this.state.options
      .map(o => ({
        label: o.name,
        value: o.keywords,
        group: true,
        id: o.id,
        priority: o.priority || false,
      }))
      .concat(this.props.keywords.map(k => ({ label: k, value: k })));
    if (!isEditing && this.state.edit_priority) {
      const tag = opts.find(o => o.priority);

      if (tag) {
        this.showEdit(tag);
      }
    }

    keywords.sort((a, b) => (a.toLowerCase() >= b.toLowerCase() ? 1 : -1));

    return (
      <>
        {loaded && (
          <TagFilter
            title="Keywords/Groups"
            icon={KeywordIcon}
            callback={this.callback}
            options={opts}
            addGroup
            editCallback={this.showEdit}
            deleteCallback={this.doDelete}
            defaultOptions={this.props.defaultOptions}
            groupAdd={this.open}
            onRight
            fixAt={{ width: 300, right: -40, top: 32 }}
            isSticky
            small
            title_slug="keywordsGroups"
          />
        )}
        {!loaded && <span>Keywords/Groups</span>}
        <Modal open={modalOpen} onClose={this.modalClose}>
          <div
            className="box"
            style={{
              width: `${width}px`,
              height: `auto`,
              overflow: "hidden",
              top: 150,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
              outline: 0,
            }}
          >
            <div style={{ position: "relative" }}>
              <img
                src={XIcon}
                className="pointer modal-close"
                style={{ position: "absolute", top: "8px", right: "8px" }}
                onClick={this.modalClose}
              />
              {isEditing ? (
                <div className="pv2 ph3 f3 dash-head">
                  Edit {name}
                  {!this.state.showingEdit && (
                    <img
                      src={Edit}
                      className="pointer ml2"
                      onClick={this.showEditInput}
                      style={{ width: "18px" }}
                    />
                  )}
                </div>
              ) : (
                <div className="pa2 ph3 f3 dash-head">Create an ad group</div>
              )}
              {this.state.showingEdit && (
                <div className="pb3 pl3 pr4 bb b--black-05">
                  <div className="flex">
                    <input
                      type="text"
                      onChange={this.onChangeName}
                      className="w-100 pa2 search-form"
                      placeholder="Name your ad group"
                      defaultValue={name}
                      ref={this.editRef}
                    />
                    {this.props.isAdmin && (
                      <label className="mt2 ml2 db nowrap">
                        <input
                          type="checkbox"
                          onChange={this.onChangePriority}
                          name="priority"
                          checked={this.state.priority}
                        />{" "}
                        Priority
                      </label>
                    )}
                  </div>
                </div>
              )}
              <div className="pv3 pl3 pr4 bb b--black-05">
                <div className="relative bigger-search">
                  <input
                    type="text"
                    onChange={this.onKWSearch}
                    className="w-100 pa2 search-form"
                    placeholder="Search keywords"
                    ref={this.inputRef}
                  />
                  {!search ? (
                    <img
                      src={Search}
                      className="search-form-icon c-text"
                      alt="search"
                      onClick={() => {
                        this.inputRef.current.focus();
                      }}
                    />
                  ) : (
                    <div
                      className="search-form-icon pointer"
                      onClick={this.clearSearch}
                    >
                      clear
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{ height: "319px", overflow: "auto" }}
                className="mv2 f6"
              >
                {add_kws && add_kws.length > 0 && (
                  <div className="pa2 flex flex-wrap tag-box bb b--black-05">
                    {add_kws.map(kw => (
                      <div className="w-25" key={`addkw-${kw}`}>
                        <div
                          onClick={this.changeKW(kw)}
                          className="flex pa2 pointer add-hover"
                        >
                          <div style={{ padding: "0 6px" }}>
                            <img
                              src={BlueCheck}
                              alt="check"
                              className="kw-check"
                            />
                          </div>
                          <div
                            className="w-90 kw-item dww-blue truncate"
                            title={kw}
                          >
                            {kw}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {(!keywords || keywords.length === 0) && (
                  <div className="pt4 pa2 tc gray f4">
                    Nothing matched "{this.state.search}".
                  </div>
                )}
                {keywords && keywords.length > 0 && (
                  <div className="pa2 flex flex-wrap tag-box">
                    {keywords.map(kw => (
                      <div className="w-25" key={kw}>
                        <div
                          onClick={this.changeKW(kw)}
                          className="flex pa2 pointer add-hover"
                        >
                          {add_kws.includes(kw) ? (
                            <div style={{ padding: "0 6px" }}>
                              <img
                                src={BlueCheck}
                                alt="check"
                                className="kw-check"
                              />
                            </div>
                          ) : (
                            <div
                              style={{ padding: "0 6px", width: "23px" }}
                            ></div>
                          )}
                          <div
                            className={`w-90 kw-item ${
                              add_kws.includes(kw) ? "dww-blue" : ""
                            }`}
                          >
                            {kw}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="pv2 ph3 bt b--black-05 tr">
                <a
                  href="# "
                  onClick={this.modalClose}
                  className="ui-button secondary mr2"
                >
                  Cancel
                </a>
                <a
                  href="# "
                  className="ui-button primary"
                  onClick={isEditing ? this.doEdit : this.doSave}
                >
                  {isEditing ? <>Save</> : <>Create ad group</>}
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default KeywordGroups;
