import React, { Component, useState } from "react";
import request from "request";
import { Link } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
import * as cx from "classnames";
import NoteDisplay from "./NoteDisplay";
import CollectionsInline from "./CollectionsInline";
import {
  baseApiUrl,
  getData,
  capitalize,
  copyTextToClipboard,
  getShareableURL,
  freeTrialImageUrl,
  randomBlur,
  randomHue,
  freeTrialImageStyles,
  getImage,
  COLLECTION_TYPES,
  parseNoteTag,
} from "../util";
import ChampIcon from "../icons/Champ.svg";
import ChampBig from "../icons/BigChamp.svg";
import LoserBig from "../icons/BigLoser.svg";
import ToggleOn from "../icons/ToggleOn.svg";
import ToggleOff from "../icons/ToggleOff.svg";
import LinkIcon from "../icons/url_blue.svg";
import { imageUrl, formatReportDate, tagLookup } from "../util";
import X from "../icons/red_x.svg";
import ZoomOut from "../icons/ZoomOut.svg";
import ZoomOutGray from "../icons/ZoomOut_gray.svg";
import DesktopIconOn from "../icons/desktop_white.svg";
import DesktopIconOff from "../icons/desktop_purple.svg";
import MobileIconOn from "../icons/mobile_white.svg";
import MobileIconOff from "../icons/mobile_purple.svg";
import BigArrowRL from "../icons/BigArrowRL.svg";
import Blur from "../icons/blur_ss.jpg";
import ArrowRight from "../icons/arrow_right_white.svg";
import ArrowLeft from "../icons/arrow_left_white.svg";
import ArrowWhite from "../icons/arrow_white.svg";
import WatchOffIcon from "../icons/watch_off.svg";
import WatchOnIcon from "../icons/watch_on.svg";
import ShareIcon from "../icons/share.svg";
import LockBg from "../icons/lock_bg.svg";
import EmptyImage from "../img/transparent.png";

import ShareTest from "./ShareTest";
import WatchUsersIcon from "../icons/watching_users.svg";
import AdminShareTest from "./AdminShareTest";
import FreeTrialActions from "./FreeTrialActions";
import UnlockTest from "./UnlockTest";
let touchstartX = 0;
let touchstartY = 0;
let touchendX = 0;
let touchendY = 0;

function handleGesure() {}

const zoomStylesMobile = [
  {
    maxHeight: "75vh",
    marginTop: "0px",
  },
  {
    maxWidth: "25vw",
    maxHeight: "130vh",
    marginTop: "0px",
  },
  {
    maxWidth: "25vw",
    marginTop: "0px",
  },
  {
    marginTop: "0px",
  },
];
const zoomStylesDesktop = [
  {
    maxHeight: "75vh",
  },
  {
    maxHeight: "140vh",
    marginTop: "0px",
  },
  {},
  {},
];

function LinkOrPopup(props) {
  const {
    isPopup,
    onClickPopup,
    onClickLink,
    to,
    renderAnchorOrLink = "link",
    ...childProps
  } = props;
  if (isPopup)
    return (
      <a href="#" onClick={e => onClickPopup(e)} {...childProps}>
        {props.children}
      </a>
    );
  else {
    if (renderAnchorOrLink === "anchor")
      return onClickLink ? (
        <a href={to} onClick={e => onClickLink(e)} {...childProps}>
          {props.children}
        </a>
      ) : (
        <a href={to} {...childProps}>
          {props.children}
        </a>
      );
    else {
      return onClickLink ? (
        <Link to={to} onClick={e => onClickLink(e)} {...childProps}>
          {props.children}
        </Link>
      ) : (
        <Link to={to} {...childProps}>
          {props.children}
        </Link>
      );
    }
  }
}
class CarouselNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      zoom: 2,
      currentImage: props.images[0],
      qs: window.location.search,
      zoomedOut: true,
      zoomedIn: false,
      sidebyside: true,
      newView: true,
      showHints: true,
      showInfo: false,
      initialNote: true,
      showNotes: false,
      doLoadedThing: true,
      didScroll: false,
      noteShow: null,
      kwData: null,
      savedTests: this.props.savedTests || [],
      analyses: this.props.analyses || [],
      addedTests: this.props.addedTests || [],
      screenshots: this.props.screenshots,
      allLoaded: false,
      justLoaded: false,
      expanded: true,
      didExpand: true,
      collections: this.props.collections,
      watchTest: props.screenshots.is_watching,
      isWatchAPILoading: false,
      sharedScreenshot: this.props.sharedScreenshot || false,
      showFreeTrialPopup: false,
      loadMore: false,
    };
  }
  offTimeout = null;
  imgLoaded = 0;
  collapseTimeout = null;
  collapse = e => {
    this.setState({ expanded: false });
    clearTimeout(this.collapseTimeout);
    this.collapseTimeout = setTimeout(() => {
      this.setState({ didCollapse: true });
    }, 70);
  };
  expand = e => {
    clearTimeout(this.collapseTimeout);
    this.setState({ expanded: true, didExpand: true, didCollapse: false });
  };
  onLoad = () => {
    setTimeout(() => {
      this.makeRectsOneImg();
      setTimeout(() => {
        let index = this.state.index ? this.state.index : 0;
        let note = this.state.noteShow ? this.state.noteShow : 0;
        this.showAndScrollNote(note)(null);
      }, 1500);
    }, 100);
  };
  zoom = zoomDirection => e => {
    let newZoom = 0;
    const { zoom } = this.state;
    if (zoomDirection === "out" && zoom > 0 && zoom !== 0) {
      newZoom = zoom - 1;
    } else if (zoomDirection === "in" && zoom < 3 && zoom !== 3) {
      newZoom = zoom + 1;
    }

    this.setState({
      zoom: newZoom,
    });

    if (newZoom === 3) {
      this.setState({
        zoomedOut: true,
      });
    } else {
      this.setState({
        zoomedOut: false,
      });
    }
    e.currentTarget.blur();
  };
  doOldView = e => {
    e.preventDefault();
    this.setState({ sidebyside: false, zoomedOut: false, zoomedIn: false });
  };
  next = e => {
    this.handleKeyEvent("right");
  };
  prev = e => {
    this.handleKeyEvent("left");
  };

  showInfo = e => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  handleKeyEvent = (key, e) => {
    const { index } = this.state;
    const { images } = this.props;
    const newIndex = key === "left" ? index - 1 : index + 1;
    if (newIndex < 0) {
      this.setState(
        {
          index: images.length - 1,
          currentImage: images[images.length - 1],
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
            this.scrollTo();
          }, 100);
        }
      );
    } else if (images.length === newIndex) {
      this.setState(
        {
          index: 0,
          currentImage: images[0],
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
            this.scrollTo();
          }, 100);
        }
      );
    } else {
      this.setState(
        {
          index: newIndex,
          currentImage: images[newIndex],
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
            this.scrollTo();
          }, 100);
        }
      );
    }
  };
  pickScreenshot = newIndex => e => {
    e.preventDefault();
    this.setState(
      {
        index: newIndex,
        currentImage: this.props.images[newIndex],
      },
      () => {
        setTimeout(() => {
          this.makeRectsOneImg();
          this.scrollTo();
        }, 100);
      }
    );
  };
  getHighestNote = (z = 0) => {
    let highest = 0;
    let highestPoint = 0;

    if (this.state.sidebyside) {
      this.state.screenshots.screenshots.forEach((x, i) => {
        if (x.posXY && x.posXY[z] && x.posXY[z].computedTop) {
          if (highestPoint === 0 || x.posXY[z].computedTop < highestPoint) {
            highest = i;
            highestPoint = x.posXY[z].computedTop;
          }
        }
      });
    } else {
      highest = this.state.index;
    }
    return highest;
  };
  doNoteScroll = x => {
    const container = document.getElementById("ss-modal-scroller-mobile");
    if (container) {
      let finalX = x === undefined ? this.getHighestNote(this.state.index) : x;
      const elm = document.getElementById(`dot-${this.state.index}-${finalX}`);
      let scroll = document.getElementById("ss-modal-scroller-mobile");
      if (elm) {
        scroll.scrollTo(0, elm.offsetTop - container.offsetHeight / 2);
      }
    } else {
      const elm = document.getElementById(`dot-${this.state.index}-${x}`);
      if (elm) {
        document
          .getElementById("ss-modal-scroller-mobile")
          .scrollTo(0, elm.offsetTop);
      }
    }
  };
  doSideBySide = e => {
    e.preventDefault();

    if (this.state.sidebyside) {
      this.setState(
        {
          zoomedIn: false,
          zoomedOut: false,
          sidebyside: false,
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
          }, 100);
        }
      );
    } else {
      this.setState(
        {
          zoomedIn: false,
          zoomedOut: true,
          sidebyside: true,
          // expanded: true,
          // didCollapse: false,
        },
        () => {
          if (this.state.allLoaded) {
            setTimeout(() => {
              this.makeRects();
            }, 200);
          }
        }
      );
    }
  };
  handlePickImage = i => e => {
    this.setState({
      index: i,
      currentImage: this.props.images[i],
    });
  };
  scrollTo = e => {
    const elm = document.querySelectorAll(".ss-selected")[0];
    let scroll = document.getElementById("ss-scroll");
    if (elm && scroll) {
      scroll.scrollTo(0, elm.offsetTop);
    }
  };
  zoomImage = i => e => {
    this.setState(
      {
        index: i,
        currentImage: this.props.images[i],
        zoomedIn: false,
        zoomedOut: true,
        sidebyside: false,
        zoom: 3,
        expanded: false,
        didCollapse: true,
      },
      () => {
        setTimeout(() => {
          this.makeRectsOneImg();
        }, 50);
      }
    );
  };
  visibleEvent = null;
  componentWillUnmount() {
    this.imgLoaded = 0;
    this.setState({ allLoaded: false });
    document.removeEventListener("visibilitychange", this.visibleEvent);
    document.removeEventListener("touchstart", this.touchStart);
    document.removeEventListener("touchend", this.touchEnd);
    clearTimeout(this.timeout);
    clearTimeout(this.timeout2);
    clearTimeout(this.timeout3);
    clearTimeout(this.timeout4);
    clearInterval(this.interval);
  }
  timeout: null;
  timeout2: null;
  timeout3: null;
  timeout4: null;
  visibleEvent = () => {
    if (!document.hidden && this.state.allLoaded) {
      this.setState({ doLoadedThing: false });
      this.timeout2 = setTimeout(() => {
        this.setState({ doLoadedThing: true });
        document.querySelectorAll("img.screenshot").forEach((el, i) => {
          el.classList.add("unloaded");

          setTimeout(() => {
            el.classList.remove("unloaded");
          }, 100 * i);
        });
        document.querySelectorAll("img.screenshot").forEach((el, i) => {
          setTimeout(() => {
            el.classList.remove("unloaded");
          }, 25);
        });
        setTimeout(() => {
          this.makeRects();
        }, 500);
      }, 150);
    }
  };
  swipeElm: null;
  tStartEvent: null;
  tEndEvent: null;
  interactions = {};
  touchStart = e => {
    let event = e.screenX ? e : e.changedTouches[0];
    if (!this.interactions[event.identifier]) {
      this.interactions[event.identifier] = {
        touchstartX: event.screenX,
        touchstartY: event.screenY,
      };
    }
    touchstartX = event.screenX;
    touchstartY = event.screenY;
  };
  touchEnd = e => {
    let event = e.screenX ? e : e.changedTouches[0];
    const zoom = window.visualViewport
      ? Math.round(window.visualViewport.scale * 100) / 100
      : 1;
    if (this.interactions[event.identifier]) {
      const { touchstartX, touchstartY } = this.interactions[event.identifier];
      const touchendX = event.screenX;
      const touchendY = event.screenY;
      let touchX = touchendX - touchstartX;
      let touchY = Math.abs(touchstartY - touchendY);

      if (touchY > Math.abs(touchX) || zoom > 1.1) {
        return true;
      }
      if (touchX <= -40) {
        this.next();
      }
      if (touchX >= 40) {
        this.prev();
      }
      this.interactions = {};
    } else {
      touchendX = event.screenX;
      touchendY = event.screenY;
      let touchX = touchendX - touchstartX;
      let touchY = Math.abs(touchstartY - touchendY);
      if (touchY > Math.abs(touchX) || zoom > 1.1) {
        return true;
      }
      if (touchX <= -40) {
        this.next();
      }
      if (touchX >= 40) {
        this.prev();
      }
    }
  };
  componentDidMount() {
    this.swipeElm = document.getElementById("ss-modal-scroller-mobile");

    if (this.swipeElm) {
      this.tStartEvent = this.swipeElm.addEventListener(
        "touchstart",
        this.touchStart,
        false
      );

      this.tEndEvent = this.swipeElm.addEventListener(
        "touchend",
        this.touchEnd,
        false
      );
    }

    if (!this.state.sharedScreenshot && this.props.url) {
      getData({
        url: `${baseApiUrl}/v1/sem/keywords?report=${this.props.reportId}&url=${this.props.url}`,
        headers: {
          Authorization: this.props.user.token,
        },
      }).then(({ err, res, data }) => {
        this.setState({
          kwData: data,
        });
      });
    }
    document.addEventListener("visibilitychange", this.visibleEvent);
  }
  interval: null;
  getBaseNote = note => {
    if (note) {
      return note.split(":")[0].trim();
    }
    return null;
  };
  scrollTimeout: null;
  makeRectsOneImg = () => {
    const actualImg = document.getElementById("big-ss");
    const rect = actualImg.getBoundingClientRect();
    const { screenshots } = this.state;
    const { posXY } = screenshots.screenshots[this.state.index];
    if (posXY) {
      posXY.forEach((p, x) => {
        if (screenshots.screenshots[this.state.index].notes[x]) {
          if (!p) {
            return;
          }
          if (p.y === 0 && p.x === 0) {
            p.computedTop = 10;
            p.computedLeft = rect.width / 2;
          } else {
            p.computedTop = rect.height / p.y;
            p.computedLeft = rect.width / p.x;
          }
        }
      });
    }
    this.setState({ screenshots: screenshots });
  };
  makeRects = () => {
    const images = document.querySelectorAll(".xx-img");

    images.forEach((img, i) => {
      const actualImg = img.querySelector("img.screenshot");
      const rect = actualImg.getBoundingClientRect();
      let posXY;
      if (
        this.state.screenshots &&
        this.state.screenshots.screenshots &&
        this.state.screenshots.screenshots[i]
      ) {
        posXY = this.state.screenshots.screenshots[i].posXY;
      }
      if (posXY) {
        posXY.forEach((p, x) => {
          if (
            this.state.screenshots.screenshots[i].notes &&
            this.state.screenshots.screenshots[i].notes[x]
          ) {
            if (!p) {
              return;
            }
            if (p.y === 0 && p.x === 0) {
              p.computedTop = 10;
              p.computedLeft = rect.width / 2;
            } else {
              p.computedTop = rect.height / p.y;
              p.computedLeft = rect.width / p.x;
            }
          }
        });
      }
    });
    images.forEach((img, i) => {
      let posXY;
      if (
        this.state.screenshots &&
        this.state.screenshots.screenshots &&
        this.state.screenshots.screenshots[i]
      ) {
        posXY = this.state.screenshots.screenshots[i].posXY;
      }
      if (posXY) {
        const collapsedIndex = [];
        const skipCollapse = posXY.find(x => x && x.collapsed);
        posXY.forEach((p, x) => {
          if (!p) {
            return;
          }
          if (
            this.state.screenshots.screenshots[i].notes &&
            this.state.screenshots.screenshots[i].notes[x]
          ) {
            this.state.screenshots.screenshots[i].posXY.forEach((ss, y) => {
              if (x === y || !ss) {
                return;
              }
              const topAbs = Math.abs(p.computedTop - ss.computedTop);
              const leftAbs = Math.abs(p.computedLeft - ss.computedLeft);
              if (
                this.getBaseNote(
                  this.state.screenshots.screenshots[i].notes[x]
                ) !==
                this.getBaseNote(this.state.screenshots.screenshots[i].notes[y])
              ) {
                return;
              }
              if (collapsedIndex.includes(y)) {
                return;
              }
              if (topAbs <= 30 && leftAbs <= 30) {
                ss.index = y;
                if (!collapsedIndex.includes(ss.index) && !skipCollapse) {
                  posXY[y].isCollapsed = true;
                  collapsedIndex.push(x);
                  if (!p.collapsed) {
                    p.collapsed = [ss];
                  } else {
                    p.collapsed.push(ss);
                  }
                }
              }
            });
          }
        });
      }
    });

    this.setState(
      {
        screenshots: this.state.screenshots,
        doInitialNotes: true,
      },
      () => {
        if (!this.state.justLoaded) {
          this.timeout4 = setTimeout(this.doNoteScroll, 1000);
          this.timeout3 = setTimeout(this.stopInitialNotes, 8000);
          this.setState({ justLoaded: true });
        }
      }
    );
  };
  stopInitialNotes = () => {
    this.setState({ doInitialNotes: false });
  };
  showAndScrollNote = x => e => {
    this.setState({ noteShow: `${x}` }, () => {
      this.doNoteScroll(x);
    });
  };
  showNote = x => e => {
    if (this.state.showNotes) {
      return;
    }
    clearTimeout(this.offTimeout);
    this.setState({ noteShow: `${x}` });
  };
  imgHideNote = () => {
    if (!this.state.initialNote && this.state.allLoaded) {
      this.hideNote(null, true);
    }
  };
  hideNote = (e, initialNote) => {
    if (this.state.showNotes) {
      return;
    }
    if (!this.state.initialNote && this.state.allLoaded) {
      this.offTimeout = setTimeout(() => {
        if (this.state.noteShow) {
          if (initialNote) {
            this.setState({ noteShow: null, initialNote: false });
          } else {
            this.setState({ noteShow: null });
          }
        }
      }, 200);
    }
  };
  showHints = e => {
    this.setState({ showHints: true, showNotes: false });
  };
  hideHints = e => {
    this.setState({ showHints: false, showNotes: false });
  };
  showNotes = e => {
    this.setState({ showHints: true, showNotes: true });
  };
  toggleNotes = on => e => {
    if (on) {
      this.hideHints(e);
    } else {
      this.showHints(e);
    }
  };
  checkSelected = ind => {
    const i = Number(this.state.noteShow);
    let posXY;
    if (
      this.state.screenshots &&
      this.state.screenshots.screenshots &&
      this.state.screenshots.screenshots[0]
    ) {
      posXY = this.state.screenshots.screenshots[0].posXY;
    }
    if (posXY[i] && posXY[i].collapsed) {
      const indexes = posXY[i].collapsed.map(z => z.index);
      return indexes.includes(ind);
    } else {
      return posXY.find((pxy, z) => {
        let ret = false;
        if (!pxy || !pxy.collapsed || ind !== z) {
          return ret;
        }
        pxy.collapsed.forEach(c => {
          if (`${c.index}` === this.state.noteShow) {
            ret = true;
          }
        });

        return ret;
      });
    }
  };
  tagInTags = tag => {
    const i = parseInt(this.state.noteShow);
    let found = false;
    this.state.screenshots.screenshots.forEach(ss => {
      if (ss.tags && ss.tags[i] && ss.tags[i].includes(tag)) {
        found = true;
      }
      if (ss.posXY && ss.posXY[i] && ss.posXY[i].collapsed) {
        ss.posXY[i].collapsed.forEach(c => {
          if (ss.tags[c.index] && ss.tags[c.index].includes(tag)) {
            found = true;
          }
        });
      }
    });

    return found;
  };

  findNoteForTag = tag => e => {
    e.preventDefault();
    const i = parseInt(this.state.noteShow);
    let found = false;
    let noteIndex = null;
    this.state.screenshots.screenshots.forEach(ss => {
      if (!found) {
        if (ss.tags) {
          ss.tags.forEach((tags, tagIndex) => {
            if (!found) {
              if (tags.includes(tag)) {
                found = true;
                noteIndex = tagIndex;
              }
            }
          });
        }
      }
    });
    if (found && Number.isInteger(noteIndex)) {
      this.showAndScrollNote(noteIndex)(e);
    }
  };

  triggerFreeTrialPopup = e => {
    e.preventDefault();
    this.setState({ showFreeTrialPopup: true });
    setTimeout(() => {
      this.setState({ showFreeTrialPopup: false });
    }, 100);
  };
  triggerUnlockModal = e => {
    e.preventDefault();
    this.setState({ showUnlockModal: true });
    setTimeout(() => {
      this.setState({ showUnlockModal: false });
    }, 100);
  };
  showMore = e => {
    e.preventDefault();
    this.setState({ loadMore: !this.state.loadMore });
  };
  handleSwiped = direction => e => {
    if (direction === "left") {
      this.next();
    }
    if (direction === "right") {
      this.prev();
    }
  };
  displayI = 0;
  shortUrl =
    window.location.origin +
    window.location.pathname.replace(`/home/${this.props.company}`, `/link`) +
    (window.location.search ? window.location.search : "") +
    (window.location.hash || "");
  render() {
    this.displayI = 0;
    const {
      currentImage,
      zoom,
      zoomedOut,
      sidebyside,
      expanded,
      didExpand,
      didCollapse,
    } = this.state;
    const { images, screenshots, showLinkPopup, sharedScreenshot, company } =
      this.props;
    const { start_date, end_date } = screenshots;
    const isTestCalled =
      screenshots.screenshots.find(s => s.champ === true) !== undefined;
    let lStyles =
      screenshots.type === "mobile"
        ? zoomStylesMobile[zoom]
        : zoomStylesDesktop[zoom];

    let allTests = [];
    if (this.state.addedTests && !this.state.sharedScreenshot) {
      allTests = this.state.addedTests;
    }
    const unlockedTests =
      this.props.customer &&
      this.props.customer.freeTrial &&
      this.props.customer.unlockedTests
        ? this.props.customer.unlockedTests.filter(
            unlockedTest => unlockedTest.unlockedBy !== "admin"
          ).length
        : null;

    const width =
      screenshots.type === "mobile" ? 375 : Math.ceil(window.innerWidth * 0.34);

    let baseUrl = `/home/${this.props.company}/`;
    const freeTrial = this.props.customer
      ? this.props.customer.freeTrial
      : false;

    const currentNote = Number(this.state.noteShow);
    const parsedTag = parseNoteTag(this.state.currentImage.notes[currentNote]);
    const filteredTags = screenshots.tags.filter(tag => this.tagInTags(tag));

    return (
      <>
        <div className="mobile-detail">
          <div
            style={{
              position: "absolute",
              top: "60px",
              width: "100vw",
              zIndex: 1000,
              background: "white",
              borderBottomLeftRadius: this.state.loadMore ? "0px" : "12px",
              borderBottomRightRadius: this.state.loadMore ? "0px" : "12px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px",
            }}
          >
            <div className="flex">
              <div className="company-box-mini" style={{ minWidth: "25%" }}>
                <LinkOrPopup
                  className="company"
                  to={`${baseUrl}report/?company=${encodeURIComponent(
                    screenshots.company_name
                  )}`}
                  onClickPopup={e => {
                    showLinkPopup();
                    e.preventDefault();
                  }}
                  isPopup={this.state.sharedScreenshot}
                  renderAnchorOrLink="anchor"
                  style={{ lineHeight: "17px" }}
                >
                  {screenshots.company_name}
                  {screenshots.country && screenshots.country !== "us" ? (
                    <>&middot;{screenshots.country.toUpperCase()}</>
                  ) : (
                    ""
                  )}
                </LinkOrPopup>
              </div>
              <div className="toggle-mini" style={{ minWidth: "30%" }}>
                {screenshots.related_test &&
                screenshots.related_test !== "none" &&
                screenshots.related_test !== "later" ? (
                  <div className="platform-toggle-container">
                    {screenshots.type === "desktop" && (
                      <>
                        <a
                          href={
                            !this.state.sharedScreenshot
                              ? `${baseUrl}screenshots/${
                                  screenshots.related_test
                                }${this.state.qs ? this.state.qs : ""}`
                              : "#"
                          }
                          title="See Mobile Test"
                          className="platform-toggle off mobile"
                          onClick={e => {
                            if (this.state.sharedScreenshot) {
                              showLinkPopup();
                              e.preventDefault();
                            }
                          }}
                        >
                          <img src={MobileIconOff} alt="Mobile" />
                        </a>
                        <span className="platform-toggle on desktop">
                          <img src={DesktopIconOn} alt="desktop" />
                        </span>
                      </>
                    )}
                    {screenshots.type === "mobile" && (
                      <>
                        <span className="platform-toggle on mobile">
                          <img src={MobileIconOn} alt="Mobile" />
                        </span>
                        <a
                          href={
                            this.state.sharedScreenshot
                              ? "#"
                              : `${baseUrl}screenshots/${
                                  screenshots.related_test
                                }${this.state.qs ? this.state.qs : ""}`
                          }
                          title="See Desktop Test"
                          className="platform-toggle off desktop"
                          onClick={e => {
                            if (this.state.sharedScreenshot) {
                              showLinkPopup();
                              e.preventDefault();
                            }
                          }}
                          // isPopup={this.state.sharedScreenshot}
                        >
                          <img src={DesktopIconOff} alt="Desktop" />
                        </a>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="w-100 tc">
                    {screenshots.type === "desktop" && (
                      <span
                        className="platform-toggle solo desktop"
                        style={{ width: "auto" }}
                      >
                        <img src={DesktopIconOn} alt="desktop" />
                      </span>
                    )}
                    {screenshots.type === "mobile" && (
                      <span className="platform-toggle solo">
                        <img src={MobileIconOn} alt="Mobile" />
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="dates-box-mini" style={{ borderRight: "none" }}>
                <div className="dates-box-mini-container">
                  {(start_date || end_date) && (
                    <>
                      {start_date && (
                        <div className="date-mini">
                          {formatReportDate(start_date, true)}
                        </div>
                      )}
                      {end_date ? (
                        <>
                          <img
                            src={BigArrowRL}
                            className="dates-arrow"
                            alt="arrow"
                          />
                          <div className="date-mini">
                            {formatReportDate(end_date, true)}
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={BigArrowRL}
                            className="dates-arrow"
                            alt="arrow"
                          />
                          <div className="date-mini tbd">TBD</div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="tc flex pv1"
              style={{
                backgroundColor: currentImage.champ
                  ? "rgba(80, 200, 176, 1)"
                  : "rgba(47, 51, 54, 1)",
                borderBottomLeftRadius: this.state.loadMore ? "0px" : "8px",
                borderBottomRightRadius: this.state.loadMore ? "0px" : "8px",
                color: "white",
              }}
            >
              <div className="pt1" onClick={this.prev}>
                <img src={ArrowLeft} height={32} />
              </div>
              <div className="db pt2 sb flex-grow">
                {currentImage.control ? (
                  <>Control</>
                ) : (
                  <>Variant {currentImage.varNum}</>
                )}
              </div>
              <div className="pt1" onClick={this.next}>
                <img src={ArrowRight} height={32} />
              </div>
            </div>
          </div>
          <div
            className={cx({
              "bg-white": true,
            })}
            style={{
              position: "absolute",
              bottom: "0px",
              height: this.state.loadMore ? "86.95vh" : "40vh",
              width: "100vw",
              zIndex: 1000,
              borderTopLeftRadius: this.state.loadMore ? "0px" : "8px",
              borderTopRightRadius: this.state.loadMore ? "0px" : "8px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px -2px 4px",
            }}
          >
            <div id="ss-header">
              <div className="notes-box">
                <div style={{ overflow: "hidden", width: "100vw" }}>
                  <div
                    className="pl2 note-container tl mb1 pt2"
                    style={{ overflowX: "scroll" }}
                  >
                    {screenshots.screenshots[0] &&
                      screenshots.screenshots[0].posXY &&
                      screenshots.screenshots[0].posXY.map((x, i) => {
                        if (
                          !x ||
                          x.isCollapsed ||
                          x === "" ||
                          !screenshots.screenshots[0].notes[i]
                        ) {
                          return <></>;
                        }
                        this.displayI++;
                        return (
                          <span
                            className={cx({
                              "sNote-box": true,
                              selected:
                                (this.state.showHints &&
                                  this.state.noteShow === `${i}`) ||
                                this.checkSelected(i),
                              disabled: !this.state.showHints,
                            })}
                            onClick={this.showAndScrollNote(i)}
                            key={`nb-${i}`}
                          >
                            {this.displayI}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="flex bb b--black-05 mb2">
                {currentImage.notes && currentImage.notes[currentNote] && (
                  <div
                    className="pv3 ph2 f5 tl"
                    style={{ flexGrow: 1, maxWidth: "85%" }}
                  >
                    {(parsedTag && parsedTag.desc) || ""}
                    {!this.state.sharedScreenshot &&
                      this.props.user.company === "admin" && (
                        <Link
                          className="ml2 ttu f8 b"
                          to={`/admin/screenshots/edit/${screenshots.id}?back=${window.location.href}`}
                        >
                          Edit
                        </Link>
                      )}
                  </div>
                )}
                <div
                  className="flex"
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "65px",
                  }}
                >
                  {isTestCalled && (
                    <>
                      {currentImage.champ ? (
                        <div
                          className="mr3"
                          style={{
                            width: "40px",
                          }}
                        >
                          <img src={ChampBig} alt="Champ" />
                          <div
                            className="green f8 b ttu tc"
                            style={{
                              marginTop: "-8px",
                              marginRight: "2px",
                              fontSize: "0.6rem",
                            }}
                          >
                            Winner
                          </div>
                        </div>
                      ) : (
                        <div
                          className="mr3"
                          style={{
                            width: "40px",
                          }}
                        >
                          <img src={LoserBig} alt="Loser" />
                          <div
                            className="red f8 b ttu tc"
                            style={{
                              marginTop: "-8px",
                              marginRight: "4px",
                              fontSize: "0.6rem",
                            }}
                          >
                            Loser
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <div className="mb2">
                  <div className="pa2 tl">
                    {filteredTags.map((tag, y) => {
                      if (!this.state.loadMore && y > 7) {
                        return <></>;
                      }
                      return (
                        <LinkOrPopup
                          to={`${baseUrl}report/?tag=${encodeURIComponent(
                            tag
                          )}&sort=relevance&from=detail`}
                          className="tag-highlight tag-highlight-on"
                          isPopup={this.state.sharedScreenshot}
                          onClickPopup={e => {
                            showLinkPopup();
                            e.preventDefault();
                          }}
                          onClickLink={e => {
                            window.scrollTo(0, 0);
                          }}
                          key={`tag-${tag}-${y}`}
                          renderAnchorOrLink="anchor"
                          onMouseOver={this.findNoteForTag(tag)}
                        >
                          <span className="tag-highlight-inner f7">
                            {tagLookup[tag] || tag}
                          </span>
                        </LinkOrPopup>
                      );
                    })}
                    {!this.state.loadMore && filteredTags.length > 8 && (
                      <a
                        href="#"
                        className="tag-highlight"
                        onClick={this.showMore}
                      >
                        <span className="tag-inner f7">
                          + {filteredTags.length - 8} More
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.loadMore && (
              <>
                <div className="mv3 tl">
                  <div className={cx("sb pl2 pb2 bb b--black-05 f7 ttu")}>
                    Description
                  </div>
                  <div className={cx("pa2")}>
                    <div className="f5 tl" id="ss-header-text">
                      {capitalize(screenshots.headline_tag)}
                    </div>
                  </div>
                </div>
                <div className="mv3 tl">
                  <div className={cx("sb pb2 bb b--black-05 pl2 f7 ttu")}>
                    Page type
                  </div>
                  <div className={cx("pa2")}>
                    {screenshots.page_type &&
                      screenshots.page_type.map((page_type, i) => (
                        <>
                          {i > 0 && ", "}
                          <LinkOrPopup
                            key={`pt-${i}`}
                            to={`${baseUrl}report/?page_type=${page_type}`}
                            className="dib"
                            onClickPopup={e => {
                              showLinkPopup();
                              e.preventDefault();
                            }}
                            onClickLink={e => {
                              window.scrollTo(0, 0);
                            }}
                            isPopup={this.state.sharedScreenshot}
                            renderAnchorOrLink="anchor"
                          >
                            {page_type}
                          </LinkOrPopup>
                        </>
                      ))}
                  </div>
                  {this.props.screenshots.no_of_tests &&
                  this.props.screenshots.no_of_tests > 2 ? (
                    <>
                      <div
                        className={cx("sb pb2 bb b--black-05 pl2 f7 ttu pt2")}
                      >
                        Tests on this page
                      </div>
                      <div className={cx("pa2")}>
                        {freeTrial && !screenshots.testUnlocked ? (
                          <p
                            className="pointer link blue pa0 pb0"
                            onClick={this.triggerFreeTrialPopup}
                          >
                            {this.props.screenshots.no_of_tests} tests
                          </p>
                        ) : (
                          <LinkOrPopup
                            key={`moretests`}
                            to={`${baseUrl}report/?url=${encodeURIComponent(
                              this.props.url
                            )}`}
                            className="dib"
                            onClickPopup={e => {
                              showLinkPopup();
                              e.preventDefault();
                            }}
                            onClickLink={e => {
                              window.scrollTo(0, 0);
                            }}
                            isPopup={this.state.sharedScreenshot}
                            renderAnchorOrLink="anchor"
                          >
                            {this.props.screenshots.no_of_tests} tests
                          </LinkOrPopup>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
            <div
              className="pt2 ph2 pb3 bg-white"
              style={{ position: "absolute", bottom: "0px", width: "100vw" }}
            >
              <a
                href="#"
                className="ui-button link dib w-100 tc"
                onClick={this.showMore}
              >
                {this.state.loadMore ? <>Less Info</> : <>More Info</>}
              </a>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              bottom: "42vh",
              margin: "0 auto",
              width: "100vw",
              zIndex: "200",
              display: "flex",
            }}
          >
            <div className="carousel-dots-container">
              {screenshots.screenshots.map((s, i) => (
                <div
                  className={cx({
                    "carousel-dot": true,
                    "carousel-dot-active": i === this.state.index,
                  })}
                  onClick={this.pickScreenshot(i)}
                ></div>
              ))}
            </div>
          </div>
          <div>
            <div
              className={cx({
                "pa3 bg-dww-dark-gray": true,
                "initial-load": this.state.doInitialNotes,
              })}
              style={{
                width: "100%",
                overflowX: "hidden",
                overflowY: "scroll",
                height: "50vh",
              }}
              id="ss-modal-scroller-mobile"
            >
              <>
                <div
                  className="w-100 tc"
                  style={{
                    margin: "94px 0 32px",
                  }}
                >
                  <div className="relative dib" id="big-ss-container">
                    <img
                      src={
                        freeTrial && !screenshots.testUnlocked
                          ? freeTrialImageUrl(
                              currentImage.src,
                              screenshots.type,
                              575,
                              0,
                              2
                            )
                          : imageUrl(currentImage.src, 575, 0, 2)
                      }
                      className="w-100-s next screenshot"
                      title="Click for next variant"
                      style={
                        freeTrial && !screenshots.testUnlocked
                          ? {
                              ...lStyles,
                              ...freeTrialImageStyles(currentImage.src),
                            }
                          : { lStyles }
                      }
                      alt={`Screenshot ${this.state.index}`}
                      onLoad={this.onLoad}
                      id="big-ss"
                    />
                    <>
                      {this.state.showHints &&
                        currentImage.posXY &&
                        currentImage.posXY.map((p, x) => {
                          if (!p) {
                            return <></>;
                          }
                          let left;
                          let onLeft = false;
                          let onRight = false;
                          const doMove =
                            (screenshots.type === "desktop" &&
                              images.length >= 3) ||
                            (screenshots.type === "mobile" &&
                              images.length >= 4);

                          if (
                            doMove &&
                            this.state.index === 0 &&
                            p.computedLeft - 115 < 0
                          ) {
                            left = p.computedLeft - 28;
                            onLeft = true;
                          } else if (
                            doMove &&
                            this.state.index === images.length - 1 &&
                            p.computedLeft - 115 > 125
                          ) {
                            left = Math.min(p.computedLeft - 115, 105);
                            onRight = true;
                            left = p.computedLeft - 172;
                          } else {
                            left = p.computedLeft - 100;
                          }

                          return currentImage.notes[x] ? (
                            <>
                              <div
                                className={cx({
                                  "f7 dww-dot": true,
                                  "dww-dot-enlarge":
                                    this.state.noteShow === `${x}` &&
                                    !this.state.loadMore,
                                })}
                                style={{
                                  top: p.computedTop,
                                  left: p.computedLeft,
                                }}
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.showNote(x)(e);
                                }}
                                id={`dot-${this.state.index}-${x}`}
                              ></div>
                              <div
                                className={cx({
                                  "f7 dww-dot-anim": true,
                                  "dww-dot-play-anim":
                                    this.state.noteShow === `${x}` &&
                                    !this.state.loadMore,
                                })}
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  this.showAndScrollNote(x)(e);
                                }}
                                style={{
                                  top: p.computedTop,
                                  left: p.computedLeft,
                                }}
                              ></div>
                            </>
                          ) : (
                            <></>
                          );
                        })}
                    </>
                  </div>
                </div>
                <KeyboardEventHandler
                  handleKeys={["left", "right"]}
                  onKeyEvent={this.handleKeyEvent}
                />
              </>
            </div>
          </div>
          {this.state.showFreeTrialPopup && (
            <FreeTrialPopup
              hidePopup={() => this.setState({ showFreeTrialPopup: false })}
            />
          )}
        </div>
      </>
    );
  }
}

export default CarouselNew;
