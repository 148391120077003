import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import AddTagGroup from "./components/AddTagGroup";
import { baseApiUrl } from "../../util";

class Tags extends React.Component {
  state = {
    loading: true,
    tags: [],
    tag_groups: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/all_tags`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          tags: json.tags,
          tag_groups: json.tag_groups,
        });
      }
    );
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <h1>Tag Groups</h1>
        <div>
          <h2>Add a new tag group</h2>
          <AddTagGroup
            tags={this.state.tags}
            user={this.props.user}
            callback={this.getData}
          />
          {this.state.tag_groups.map(t => (
            <AddTagGroup
              tags={this.state.tags}
              user={this.props.user}
              tagsToAdd={t.tags.map(tg => ({ label: tg, value: tg }))}
              title={t.title}
              id={t.id}
              editing
              callback={this.getData}
              key={t.id}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Tags;
