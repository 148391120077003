import React from "react";
import request from "request";
import * as querystring from "query-string";
import {
  baseApiUrl,
  COOKIE_EMAIL_SHARED,
  getCookieValue,
  getData,
} from "../../util";
import "./screenshots.css";
import Loading from "../../components/Loading";
import Carousel from "../../components/Carousel";
import CarouselNew from "../../components/CarouselNew";
import AccessDenied from "../../components/AccessDenied";
import { useParams } from "react-router";
import Modal from "@material-ui/core/Modal";
import * as cx from "classnames";
import X from "../../icons/red_x.svg";
import SadComputer from "../../icons/old/sadpage.svg";
import Success from "../../icons/old/champion.svg";

class SharedRequestAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareId: this.props.match.params.shareId,
      requestId: this.props.match.params.requestId,
      action: this.props.match.params.action,
      loading: true,
      error: false,
    };
  }
  componentDidMount() {
    request({
      url: `${baseApiUrl}/v1/t?url=${window.location.href}`,
      headers: {
        Authorization: "",
      },
    });
    getData({
      url: `${baseApiUrl}/v1/user/request_action?shareId=${this.state.shareId}&requestId=${this.state.requestId}&action=${this.state.action}`,
      headers: {
        Authorization: this.props.user.token,
      },
    })
      .then(({ err, res, data }) => {
        if (err) {
          this.setState({
            loading: false,
          });
        }
        this.setState({
          loading: false,
          error: data.error,
          message: data.message,
          name: data.name,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }
  componentWillUnmount() {
    document.body.style = "";
  }

  goToReport = e => {
    window.location = `${window.location.origin}/home/${this.props.match.params.company}/report`;
  };

  render() {
    let width = 600;
    let height = 380;
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.state.error) {
      return (
        <>
          <Modal open={true}>
            <div
              className="box tc"
              style={{
                width: `${width}px`,
                height: `${height}px`,
                overflow: "hidden",
                top: (window.innerHeight - height) / 2,
                position: "absolute",
                left: (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div className="pa4">
                <>
                  <img
                    src={SadComputer}
                    style={{ width: "60px" }}
                    alt="error"
                  />
                  <p className="f3 tc pt4">
                    Looks like the link is expired or invalid.
                  </p>

                  <div className="flex justify-around">
                    <button
                      className="primary br-pill bn ma3 b--moon-gray w-40"
                      style={{
                        color: "#fff",
                        padding: "6px 18px",
                        backgroundColor: "rgba(246,91,86,1)",
                      }}
                      onClick={this.goToReport}
                    >
                      Close
                    </button>
                  </div>
                </>
              </div>
            </div>
          </Modal>
        </>
      );
    }
    width = 640;
    height = 450;
    return (
      <>
        <Modal open={true}>
          <div
            className="box tc"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              overflow: "hidden",
              top: (window.innerHeight - height) / 2,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
              outline: 0,
            }}
          >
            <div className="pa4">
              <>
                <img
                  src={Success}
                  style={{
                    width: "48px",
                    height: "48px",
                  }}
                  alt="success"
                  className="moviefone-success"
                />
                <h1 className="tc pt4">
                  Request{" "}
                  {this.state.action === "approve" ? "Approved!" : "Rejected!"}
                </h1>
                {this.state.action === "approve" && (
                  <p className="f3 tc mv3">
                    We've sent {this.state.name} an email with instructions on
                    how to create an account.
                  </p>
                )}

                <div className="flex justify-around">
                  <button
                    className="primary br-pill bn ma3 b--moon-gray w-40"
                    style={{
                      color: "#fff",
                      padding: "6px 18px",
                      backgroundColor: "rgba(246,91,86,1)",
                    }}
                    onClick={this.goToReport}
                  >
                    Close
                  </button>
                </div>
              </>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default SharedRequestAction;
