import React, { Component } from "react";
import { fbAuth, loginLookup, baseApiUrl } from "../util";
import request from "request";
import { Redirect, Link } from "react-router-dom";
import * as cx from "classnames";
import * as qs from "query-string";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
      checkedSSO: false,
      isSSO: this.checkIsSSO(),
      returnUri: this.getReturnUri(),
      invite: this.getInvite(),
      reg_link: null,
    };
  }
  qs = null;
  parseQS = () => {
    if (this.qs) {
      return this.qs;
    }
    this.qs = qs.parse(window.location.search);

    return this.qs;
  };
  getInvite = () => {
    const { invite } = this.parseQS();
    return invite;
  };
  checkIsSSO = () => {
    const { sso } = this.parseQS();
    if (sso) {
      return true;
    }
    return false;
  };
  getReturnUri = () => {
    const { returnUri } = this.parseQS();
    return returnUri;
  };
  checkReturnUri = () => {
    request.get(
      {
        url: `${baseApiUrl}/v1/check_uri?uri=${this.getReturnUri()}`,
      },
      (err, res, data) => {
        if (res.statusCode === 200) {
          const json = JSON.parse(data);
          this.setState({ reg_link: json.reg_link });
        }
      }
    );
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.isSSO) {
      const { email, invite } = this.state;
      request.get(
        {
          url: `${baseApiUrl}/v1/sso/check_email?email=${email}${
            invite ? `&invite=${invite}` : ""
          }`,
        },
        (err, res, data) => {
          const json = JSON.parse(data);
          if (json.hasSSO) {
            window.location.replace(json.url);
            return;
          }
          this.setState({
            isSSO: false,
            loading: false,
            error: {
              message:
                "This email isn't associated with a Single Sign-on account. Please log in with an email and password.",
            },
          });
          return;
        }
      );
    } else {
      fbAuth
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then()
        .catch(error => {
          this.setState({ error, loading: false });
        });
    }
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  toggleSSO = sso => e => {
    e.preventDefault();

    this.setState({ isSSO: sso });
  };
  logout = e => {
    e.preventDefault();
    fbAuth.signOut();
  };
  componentDidMount() {
    if (this.state.invite) {
      request.get(
        {
          url: `${baseApiUrl}/v1/user/invite?id=${this.state.invite}`,
        },
        async (err, res, data) => {
          const json = JSON.parse(res.body);
          if (json.error) {
            this.setState({ error: json.error, loading: false });
          } else {
            this.setState({
              invite_email: json.invite.email,
              email: json.invite.email,
              loading: false,
            });
          }
        }
      );
    }
    if (this.getReturnUri()) {
      this.checkReturnUri();
    }
  }
  render() {
    const labelClass = "db fw4 lh-copy black-60 mt4";
    const inputClass = "pa2 input-reset w-100 ba b--black-20 bg-white br2";

    const { error, isSSO, returnUri, reg_link } = this.state;
    const { authed, user } = this.props;
    if (authed && user) {
      if (returnUri) {
        window.location.replace(returnUri);
        return <></>;
      } else {
        return <Redirect to={`/home/${user.company}/report`} />;
      }
    }
    return (
      <main className="pv4">
        <div className="mw6 center flex flex-wrap tl">
          <form onSubmit={this.handleSubmit} className="w-100">
            <div className="tab-menu flex">
              <a
                href=" #"
                className={cx({ selected: !isSSO })}
                onClick={this.toggleSSO(false)}
              >
                Log In
              </a>
              <a
                href=" #"
                className={cx({ selected: isSSO })}
                onClick={this.toggleSSO(true)}
              >
                Single Sign-on
              </a>
            </div>
            <div className="tab-menu-content">
              {error && <p className="dark-red pt3">{error.message}</p>}
              <div className="mt3">
                <label className={labelClass}>Email</label>
                <input
                  type="email"
                  onChange={this.handleKeyUp}
                  name="email"
                  className={inputClass}
                  defaultValue={this.state.invite_email}
                />
              </div>
              {!isSSO && (
                <div className="mt3">
                  <label className={labelClass}>Password</label>
                  <input
                    type="password"
                    onChange={this.handleKeyUp}
                    name="password"
                    className={inputClass}
                  />
                  <div className="tr f6 w-100 mt1">
                    <Link to="/pw_reset/">Forgot your password?</Link>
                  </div>
                </div>
              )}
              <div className="mt4">
                {!this.state.loading ? (
                  <input
                    type="submit"
                    value={`Log in`}
                    className="ui-button primary"
                  />
                ) : (
                  <button className="ui-button secondary" disabled>
                    Loading&hellip;
                  </button>
                )}
              </div>
            </div>
            {reg_link && (
              <div className="mt4 box">
                <div className="pa3 bb b--black-025 f3">
                  Need a log in for your team’s dashboard?
                </div>
                <div className="pa3">
                  <a href={reg_link} className="ui-button secondary">
                    Create an account
                  </a>
                </div>
              </div>
            )}
          </form>
        </div>
      </main>
    );
  }
}

export default Login;
