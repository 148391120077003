import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl } from "../../util";

class User extends React.Component {
  state = {
    name: this.props.name,
    email: this.props.email,
    company: this.props.company,
    email_prefs: this.props.email_prefs
      ? this.props.email_prefs
      : { ab_email: true, sem_email: false },
    uid: this.props.uid,
    saving: false,
    status: this.props.status,
  };
  doChange = e => {
    const { name, value, type, checked } = e.currentTarget;

    if (type === "checkbox") {
      const email_prefs = this.state.email_prefs;
      email_prefs[name] = !!checked;
      this.setState({ email_prefs });
    } else {
      this.setState({ [name]: value });
    }
  };
  changeCompany = e => {
    this.setState({ company: e.currentTarget.value });
  };
  submit = e => {
    const { name, email, company, email_prefs } = this.state;
    this.setState({ saving: true });
    e.preventDefault();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/user/edit`,
        form: {
          name,
          email,
          company,
          uid: this.props.uid,
          email_prefs: JSON.stringify(email_prefs),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.setState(
            {
              saving: false,
            },
            () => {
              this.props.callback();
            }
          );
        }
      }
    );
  };
  delete = e => {
    e.preventDefault();

    this.setState({ saving: true });

    if (
      window.confirm(`Are you sure you want to deactivate ${this.state.name}?`)
    ) {
      request.delete(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/user`,
          form: {
            uid: this.props.uid,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
              saving: false,
            });
            alert("Looks like something went wrong!");
            return;
          }
          if (!err) {
            this.setState(
              {
                saving: false,
              },
              () => {
                this.props.callback();
              }
            );
          }
        }
      );
    }
  };
  render() {
    return (
      <form onSubmit={this.submit} className="mb4 w-100">
        <div>
          <input
            type="text"
            value={this.state.name}
            name="name"
            onChange={this.doChange}
            className="mr2 pa1"
          />
          <input
            type="text"
            value={this.state.email}
            name="email"
            onChange={this.doChange}
            className="mr2 pa1"
          />
          <select
            name="company"
            value={this.state.company}
            onChange={this.changeCompany}
            className="w-25"
          >
            {this.props.customers.map(c => (
              <option value={c.key}>
                {c.name} ({c.key})
              </option>
            ))}
          </select>
        </div>
        <div className="pt2">
          <label className="mr3">
            <input
              type="checkbox"
              name="ab_email"
              className="mr2"
              checked={this.state.email_prefs.ab_email}
              onChange={this.doChange}
            />
            A/B Alert
          </label>
          <label>
            <input
              type="checkbox"
              name="sem_email"
              className="mr2"
              checked={this.state.email_prefs.sem_email}
              onChange={this.doChange}
            />
            SEM Alert
          </label>
        </div>
        {this.state.saving ? (
          <b>Saving...</b>
        ) : (
          <>
            <input type="submit" value="Save" className="ui-button primary" />
            {this.state.status === "deactivated" ? (
              <b className="gray ml3">Deactivated</b>
            ) : (
              <button className="ui-button secondary ml3" onClick={this.delete}>
                Deactivate
              </button>
            )}
          </>
        )}
      </form>
    );
  }
}

class Users extends React.Component {
  state = {
    loading: true,
    user: [],
    customers: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/users`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          users: json.users,
          customers: json.customers,
        });
      }
    );
  };
  handleCustomerChange = e => {
    this.setState({ customer: e.currentTarget.value });
  };

  filterUsers = () => {
    if (!this.state.customer) {
      return this.state.users;
    }

    return this.state.users.filter(u => u.company === this.state.customer);
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const { customers } = this.state;

    const filteredUsers = this.filterUsers();

    return (
      <div>
        <h1>Users Admin</h1>
        <div className="mb4">
          <select name="customers" onChange={this.handleCustomerChange}>
            <option value="">--- Filter by customer ---</option>
            <option value="admin">Admin</option>
            {customers.map(customer => (
              <option value={customer.key}>
                {customer.name} ({customer.key})
              </option>
            ))}
          </select>
        </div>
        {filteredUsers.map(user => (
          <User
            name={user.name}
            email={user.email}
            email_prefs={user.email_prefs}
            company={user.company}
            uid={user.id}
            customers={customers}
            key={user.id}
            user={this.props.user}
            status={user.status}
            callback={this.getData}
          />
        ))}
      </div>
    );
  }
}

export default Users;
