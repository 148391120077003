import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import cx from "classnames";
import querystring from "query-string";
import request from "request";

import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as synonyms from "../../../assets/synonyms.json";
import {
  baseApiUrl,
  getData,
  formatDateFromString,
  capitalize,
  addToStorage as addToStorageUtil,
  getFromStorage,
  generateShareURL,
  copyTextToClipboard,
  SHOW_ADMIN_BOX_KEY,
} from "../../../util";
import "../report.css";

import UnlockIcon from "../../../icons/lock_open_purple.svg";
import SortDown from "../../../icons/sort_down_white.svg";
import SortUp from "../../../icons/sort_up_white.svg";
import SortNone from "../../../icons/sort_white.svg";
import FilterIconWhite from "../../../icons/filter_white.svg";
import AutoIcon from "../../../icons/auto_analysis_blue.svg";

import DashboardHeader from "../../../components/DashboardHeader";
import Empty from "../../../components/Empty";
import Loading from "../../../components/Loading";
import FakeReportBox from "../../../components/FakeReportBox";
import AccessDenied from "../../../components/AccessDenied";
import CreateAnalysis from "../../../components/CreateAnalysis";
import TagSearch from "../../../components/TagSearch";
import MovieFone from "../../../components/MovieFone";
import ViewScreenshotsModal from "../ViewScreenshotsModal";

import FakeOmniFilter from "../../../components/FakeOmniFilter";
import OmniFilter from "../../../components/OmniFilter";
import TagPicker from "../../../components/TagPicker";
import NewFilters, {
  COUNTRIES,
  LOCATIONS,
} from "../../../components/NewFilters";
import MovieFoneInline from "../../../components/MovieFoneInline";
import ReportItemDisplayNew from "../../../components/ReportItemDisplayNew";
import IconOption from "../components/IconOption";
import EditIcon from "../../../icons/edit_blue.svg";
import SettingsIcon from "../../../icons/settings.svg";

import FilterIcon from "../../../icons/Filter_gray.svg";
import CompanyIcon from "../../../icons/companies_gray.svg";
import WhatsTestedIcon from "../../../icons/WhatsTested_gray.svg";
import CollectionsIcon from "../../../icons/Collections_gray_gray.svg";
import PageIcon from "../../../icons/page.svg";
import DesktopIcon from "../../../icons/desktop_gray.svg";
import X from "../../../icons/tinyX.svg";
import RED_X from "../../../icons/red_x.svg";
import AddIcon from "../../../icons/add_blue.svg";
import MinusIcon from "../../../icons/minus_red.svg";
import MobileIcon from "../../../icons/mobile_gray.svg";
import TimeIcon from "../../../icons/time.svg";
import ShareIcon from "../../../icons/share.svg";
import LinkIcon from "../../../icons/link_gray.svg";
import CopyCollection from "../../../components/CopyCollection";
import FreeTrialSalesModal from "../../../components/FreeTrialSalesModal";
import SearchIcon from "../../../icons/search_blue.svg";

import {
  Filters,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import FormGroup from "@material-ui/core/FormGroup";
import { FormControlLabel, Switch as SwitchComp } from "@material-ui/core";
import { registerCustomProtocol } from "linkifyjs";

const SORT_OPTIONS = [
  { label: "Oldest", value: "descending", disabled: false, onlyAdmin: false },
  { label: "Newest", value: "ascending", disabled: false, onlyAdmin: false },
  { label: "Relevance", value: "relevance", disabled: true, onlyAdmin: false },
  {
    label: "Last seen live",
    value: "last_seen_live",
    disabled: false,
    onlyAdmin: true,
  },
  {
    label: "Recently Published",
    value: "publish_date",
    disabled: false,
    onlyAdmin: true,
  },
];
const CACHE_NAME = "alltests-cache";
function ColFilter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={columnFilterValue?.[0] ?? ""}
          onChange={value => column.setFilterValue(old => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={columnFilterValue?.[1] ?? ""}
          onChange={value => column.setFilterValue(old => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map(value => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  );
}
const AllTestReport = props => {
  // console.log("All Test Report loaded");
  const location = useLocation();
  const currentLocation = useRef("");
  const componentLoaded = useRef(null);
  const refs = {
    qs: useRef(null),
    clearQS: useRef(false),
    filterDataDirty: useRef(true),
    filterData: useRef(null),
    testDisplayCount: useRef(0),
    scrollPos: useRef(0),
    scrollTO: useRef(null),
    analysisDirty: useRef(null),
    didFilterUpdate: useRef(false),
    testLoadingTimeout: useRef(null),
    gdTimeout: useRef(null),
    saveTimeout: useRef({}),
    analysisTimeout: useRef(null),
    publish_customer: useRef(null),
    count: useRef(0),
    showTests: useRef(100),
    firstLoad: useRef(null),
    getDataApiLoading: useRef(false),
    filterBarElement: useRef(null),
    showFilterBar: useRef(false),
    loadFilterOnly: useRef(null),
    strongFilter: useRef(false),
    filterState: useRef(null),
    skipData: useRef(false),
    showAdminBox: useRef(true),
  };
  const getCachedQS = (clearExistingQueryString = false) => {
    if (
      refs.qs.current !== null &&
      (!clearExistingQueryString || !refs.clearQS.current)
    ) {
      return refs.qs.current;
    } else {
      refs.qs.current = querystring.parse(window.location.search);
      refs.clearQS.current = false;
      return refs.qs.current;
    }
  };
  const getColumnFiltersQueryString = () => {
    const { columnFilters } = getCachedQS();
    if (columnFilters) {
      let data;
      try {
        data = JSON.parse(decodeURIComponent(columnFilters));
      } catch (e) {
        data = [];
      }
      return data;
    }
    return [];
  };

  const [customer, setCustomer] = useState(undefined);
  const [accessDenied, setAccessDenied] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [count, setCount] = useState(0);
  const [tests_loading, setTestsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creating_analysis, setCreatingAnalysis] = useState(false);
  const [clearing_tests, setClearingTests] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [testLoadingStage, setTestLoadingStage] = useState(1);
  const [loadedSavedTests, setLoadedSavedTests] = useState(false);
  const [doChangeLoad, setDoChangeLoad] = useState(false);
  // const [latestChanged, setLatestChanged] = useState(false);
  const [publishIds, setPublishIds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [tests, setTests] = useState([]);
  const [autoanalysis_data, setAutoanalysisData] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [mode, setMode] = useState("analysis");
  const [analysis_results, setAnalysisResults] = useState(null);
  const [showAutoAnalysisSettings, setShowAutoAnalysisSettings] =
    useState(false);
  const [autoAnalysisSettings, setAutoAnalysisSettings] = useState(null);
  const [showAdvancedAnalysis, setShowAdvancedAnalysis] = useState(false);
  const [showNewAdvancedAnalysis, setShowNewAdvancedAnalysis] = useState(false);
  const [newAdvancedAnalysis, setNewAdvancedAnalysis] = useState(false);
  const [advancedAnalysis, setAdvancedAnalysis] = useState({
    name: "",
    slug: "",
    allVariants: [],
    variants: [],
  });
  const [loadingAdvancedAnalyze, setLoadingAdvancedAnalyze] = useState(false);
  const [advancedAnalysesLoading, setAdvancedAnalysesLoading] = useState(false);
  const [advancedAnalyses, setAdvancedAnalyses] = useState(null);
  const [loadAV, setLoadAV] = useState(false);
  const [strongFilter, setStrongFilter] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const [editingAnalysis, setEditingAnalysis] = useState(false);
  const [editAutoAnalysis, setEditAutoAnalysis] = useState(null);
  const [new_analysis, setNewAnalysis] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [analysis_loading, setAnalysisLoading] = useState(false);
  const [showCopyCollection, setShowCopyCollection] = useState(false);
  const [showAllFilter, setShowAllFilter] = useState(false);
  const [csvRequestLoading, setCSVRequestLoading] = useState(false);
  const [tagsOfInterest, setTagsOfInterest] = useState([]);
  const [allAnalyses, setAllAnalyses] = useState({});
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState(
    getColumnFiltersQueryString()
  );
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [savingSelectedBetScore, setSavingSelectedBetScore] = useState(false);
  const [savingProperty, setSavingProperty] = useState(null);
  const [loadingAutoData, setLoadingAutoData] = useState(null);
  const [editSaving, setEditSaving] = useState(false);

  const onChangeAdvancedAnalysisName = e => {
    advancedAnalysis.name = e.target.value;
    advancedAnalysis.slug = e.target.value
      .toLowerCase()
      .replace(/[^\w]/gi, "_");
    setAdvancedAnalysis(advancedAnalysis);
  };
  const onEditAnalysisSetting = id => e => {
    e.preventDefault();
    const setting = autoAnalysisSettings.find(a => a.id === id);
    const { name, value } = e.currentTarget;
    if (!setting.setting) {
      setting.setting = {};
    }
    if (setting[name] !== undefined) {
      setting[name] = value;
    } else if (setting.setting[name] !== undefined) {
      setting.setting[name] = value;
    }
    setAutoAnalysisSettings(autoAnalysisSettings);
    if (name === "type" && id === "new") {
      setEditAutoAnalysis(null);
      setTimeout(() => {
        setEditAutoAnalysis("new");
      }, 100);
    }
  };
  const onSaveAnalysisSetting = id => e => {
    const setting = autoAnalysisSettings.find(a => a.id === id);
    e.preventDefault();
    setEditSaving(true);
    request.post(
      {
        url: `${baseApiUrl}/v1/analysis/setting`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          setting: JSON.stringify(setting),
        },
      },
      async (err, data) => {
        if (err) {
          alert("Something went wrong...");
        }
        setEditSaving(false);
        setEditAutoAnalysis(null);
      }
    );
  };
  const onDeleteAnalysisSetting = (id, advanced) => e => {
    e.preventDefault();
    setAdvancedAnalysesLoading(true);
    setLoadingAdvancedAnalyze(true);
    if (window.confirm("Are you sure you want to delete this?")) {
      request.delete(
        {
          url: `${baseApiUrl}/v1/analysis/setting`,
          headers: {
            Authorization: props.user.token,
          },
          form: {
            id,
          },
        },
        async (err, res, data) => {
          if (err) {
            alert("Something went wrong...");
          }
          const json = JSON.parse(data);
          if (json && json.data) {
            setAdvancedAnalysesLoading(false);
            setLoadingAdvancedAnalyze(false);
            if (advanced) {
              const final_data = json.data
                .filter(d => d.type === "advanced")
                .sort((a, b) => (a.name >= b.name.name ? -1 : 1));
              setAdvancedAnalyses(final_data);
            } else {
              const final_data = json.data
                .filter(d => d.type !== "advanced")
                .sort((a, b) => (a.name >= b.name.name ? -1 : 1));
              setAutoAnalysisSettings(final_data);
            }
          }
        }
      );
    }
  };
  const addAdvancedAnalysisProperty = e => {
    e.preventDefault();
    const av = advancedAnalysis;
    av.variants.push({
      name: "",
      conditions: [{ key: "", search_type: "", boolean_type: "" }],
    });
    setAdvancedAnalysis(av);
    setLoadAV(true);
    setTimeout(() => {
      setLoadAV(false);
    }, 200);
  };
  const onRemoveAdvancedAnalysisProperty = i => e => {
    e.preventDefault();
    const av = advancedAnalysis;
    av.variants = av.variants.filter((c, x) => x !== i);
    setAdvancedAnalysis(av);
    setLoadAV(true);
    setTimeout(() => {
      setLoadAV(false);
    }, 200);
  };
  const addAdvancedAnalysisCondition = i => e => {
    e.preventDefault();
    const av = advancedAnalysis;
    av.variants[i].conditions.push({
      key: "",
      search_type: "",
      boolean_type: "",
    });
    setAdvancedAnalysis(av);
    setLoadAV(true);
    setTimeout(() => {
      setLoadAV(false);
    }, 200);
  };
  const onChangeAdvancedAnalysisProperty = i => e => {
    const av = advancedAnalysis;
    av.variants[i][e.target.name] = e.target.value;
    setAdvancedAnalysis(av);
  };
  const onRemoveAdvancedAnalysisCondition = (i, z) => e => {
    e.preventDefault();
    const av = advancedAnalysis;
    av.variants[i].conditions = av.variants[i].conditions.filter(
      (c, x) => x !== z
    );
    setAdvancedAnalysis(av);
    setLoadAV(true);
    setTimeout(() => {
      setLoadAV(false);
    }, 200);
  };
  const onChangeAdvancedAnalysisCondition = (i, z) => e => {
    const av = advancedAnalysis;
    av.variants[i].conditions[z][e.target.name] = e.target.value;
    setAdvancedAnalysis(av);
  };
  const getAdvancedAnalysisQueryString = () => {
    const { advanced_analysis_id } = getCachedQS();
    if (advanced_analysis_id) {
      return advanced_analysis_id;
    }
    return undefined;
  };
  const getUrlQueryString = () => {
    const { url } = getCachedQS();
    if (url) {
      return url;
    }
    return false;
  };
  const getCompetitorsQueryString = () => {
    const { competitors } = getCachedQS();
    if (competitors) {
      return true;
    }
    return false;
  };
  const getDatesQueryString = () => {
    const { start_date, end_date, publish_start_date, publish_end_date } =
      getCachedQS();

    return {
      start_date,
      end_date,
      publish_start_date,
      publish_end_date,
    };
  };
  const getSortQueryString = () => {
    const { sort } = getCachedQS();
    if (sort) {
      return sort;
    }
    return null;
  };
  const getNoOfTagsQueryString = () => {
    const { noOfTags } = getCachedQS();
    if (noOfTags) {
      return noOfTags;
    }
    return "0";
  };
  const getWhoWonQueryString = () => {
    const { whoWon } = getCachedQS();
    return whoWon || "all";
  };
  const getFrom = () => {
    const { from } = getCachedQS();
    return from || false;
  };
  const getCountryQueryString = () => {
    const { country } = getCachedQS();
    return country ? country.split(",") : null;
  };
  const getLocationsQueryString = () => {
    const { locations } = getCachedQS();
    return locations || undefined;
  };
  const getWatchTestQueryString = () => {
    const { watch_test } = getCachedQS();
    return watch_test;
  };
  const getChallengeQueryString = () => {
    const { challenge } = getCachedQS();
    if (challenge) {
      return challenge.split("|");
    }
    return [];
  };
  const getPlatformQueryString = () => {
    const { platform } = getCachedQS();
    if (platform) {
      return platform;
    }
    return "all";
  };
  const getTagQueryString = () => {
    const { tag } = getCachedQS();
    if (tag) {
      const tagArr = tag.split("|");
      return {
        tagsToFilter: tagArr.map(t => t.toLowerCase()),
        originalTagsToFilter: tagArr.map(t => ({
          value: t.toLowerCase(),
          label: t,
          isGroup: false,
          id: "xxx",
          negative: t.startsWith("--"),
        })),
      };
    }
    return { tagsToFilter: [], originalTagsToFilter: [] };
  };
  const getAutoAnalysisIdQueryString = () => {
    const { autoanalysis_id } = getCachedQS();
    if (autoanalysis_id) {
      return autoanalysis_id;
    }
    return null;
  };
  const getBaseTagQueryString = () => {
    const { baseTag, exact_tag_search } = getCachedQS();
    if (baseTag && !exact_tag_search) {
      const tagArr = baseTag.split("|");
      return tagArr;
    }
    return null;
  };
  const getSectorQueryString = () => {
    const { sector } = getCachedQS();
    if (sector) {
      const tagArr = sector.split("$");
      return tagArr.map(t => t.toLowerCase());
    }
    return [];
  };
  const getRecentQueryString = () => {
    const { recent } = getCachedQS();

    if (recent) {
      return recent;
    }
    return null;
  };
  const getGreatQueryString = () => {
    const { great } = getCachedQS();

    if (great) {
      return true;
    }
    return null;
  };
  const getAnalysisQueryString = () => {
    const { analysis } = getCachedQS();

    if (analysis && analysis !== "new") {
      return analysis;
    }
    return null;
  };
  const getOnlyAnalysisQueryString = () => {
    const { onlyAnalysis } = getCachedQS();

    if (onlyAnalysis && props.match.params.company === "admin") {
      return true;
    }
    return false;
  };
  const getLoadNewTestsQueryString = () => {
    const { loadNewTests } = getCachedQS();

    if (loadNewTests && props.match.params.company === "admin") {
      return true;
    }
    return false;
  };
  const getPageTypeQueryString = () => {
    const { page_type } = getCachedQS();

    if (page_type) {
      const pageType = page_type.split("|");
      return pageType;
    }
    return [];
  };
  const getStatusQueryString = () => {
    const { status } = getCachedQS();

    if (status) {
      return status;
    }
    return null;
  };
  const getCollectionQueryString = () => {
    const { collection } = getCachedQS();

    if (collection) {
      const cols = collection.split("|");
      return cols;
    }
    if (props.match.params.tid) {
      return [props.match.params.tid];
    }
    return [];
  };
  const getTestTypeQueryString = () => {
    const { test_type } = getCachedQS();

    if (test_type) {
      return test_type;
    }
    return false;
  };
  const getCompanyQueryString = () => {
    const { company } = getCachedQS();

    if (company) {
      return company.split("|");
    }
    return [];
  };

  const getAutoAnalysisQueryString = () => {
    const { autoAnalysis } = getCachedQS();

    if (autoAnalysis && autoAnalysis === "true") {
      return true;
    } else {
      return false;
    }
  };

  const getExactQueryString = () => {
    const { exact_tag_search, baseTag, exactTag, nextTag } = getCachedQS();
    if (exact_tag_search) {
      return {
        exact_tag_search,
        baseTag,
        exactTag,
        nextTag,
      };
    } else {
      return {};
    }
  };

  const [autoAnalysis, setAutoAnalysis] = useState(
    getAutoAnalysisQueryString()
  );
  const getCacheCount = () => {
    const data = getFromStorage(CACHE_NAME);
    return data ? data.cache : null;
  };
  const getUrlParams = () => {
    if (window.location.href.indexOf("?") !== -1) {
      return `?${window.location.href.split("?")[1]}`;
    }
    return "";
  };
  refs.cacheCount = useRef(getCacheCount());
  refs.doNotReloadTests = useRef(false);
  const [urlParams, setUrlParams] = useState(getUrlParams());
  const [filterState, setFilterStateState] = useState({
    url: getUrlQueryString(),
    platform: getPlatformQueryString(),
    competitors: getCompetitorsQueryString(),
    compsToFilter: getCompanyQueryString(),
    challengesToFilter: getChallengeQueryString(),
    tagsToFilter: getTagQueryString().tagsToFilter,
    baseTagsToFilter: getBaseTagQueryString(),
    from: getFrom(),
    originalTagsToFilter: getTagQueryString().originalTagsToFilter,
    collectionsToFilter: props.match.params.tid
      ? [props.match.params.tid]
      : getCollectionQueryString(),
    typesToFilter: getPageTypeQueryString(),
    catsToFilter: [],
    statusToFilter: getStatusQueryString(),
    sectorsToFilter: getSectorQueryString(),
    recent: getRecentQueryString(),
    great: getGreatQueryString(),
    sortByDate: getSortQueryString(),
    onlyAnalysis: getOnlyAnalysisQueryString(),
    loadNewTests: getLoadNewTestsQueryString(),
    currentAnalysis: getAnalysisQueryString(),
    advancedAnalysisId: getAdvancedAnalysisQueryString(),
    start_date: getDatesQueryString().start_date,
    end_date: getDatesQueryString().end_date,
    publish_start_date: getDatesQueryString().publish_start_date,
    publish_end_date: getDatesQueryString().publish_end_date,
    noOfTags: getNoOfTagsQueryString(),
    whoWon: getWhoWonQueryString(),
    watch_test: getWatchTestQueryString(),
    country: getCountryQueryString(),
    autoAnalysis: getAutoAnalysisQueryString(),
    locations: getLocationsQueryString(),
    exact_data: getExactQueryString(),
    selectedAnalysis: null,
    state_live: true,
    state_ended: true,
    state_winner: true,
    latest: "all",
    expandTests: true,
    expandShow: true,
    expandAb: true,
    expandTypes: true,
    expandChallenges: true,
    filters: [],
    showCollection: false,
  });
  refs.filterState.current = filterState;
  const [dataState, setDataState] = useState({
    report: undefined,
    types: [],
    keywords: undefined,
    companies: [],
    collection_companies: [],
    collection_tags: [],
    challenges: [],
    categories: [],
    tags: [],
    recent_tests: [],
    saved_tests: null,
    exact_sata: {},
    note_tags: [],
    totalCompanies: 0,
    customers: [],
    sectors: [],
    sector_lookup: [],
    analysis: [],
  });
  const setFilterState = state => {
    refs.filterState.current = state;
    setFilterStateState(state);
  };
  const makeUrlParams = api => {
    const {
      compsToFilter,
      typesToFilter,
      originalTagsToFilter,
      baseTagsToFilter,
      challengesToFilter,
      collectionsToFilter,
      statusToFilter,
      sectorsToFilter,
      platform,
      sortByDate,
      selectedAnalysis,
      currentAnalysis,
      onlyAnalysis,
      loadNewTests,
      start_date,
      end_date,
      publish_start_date,
      publish_end_date,
      noOfTags,
      whoWon,
      country,
      locations,
      watch_test,
      recent,
      noCache,
      url,
      autoAnalysis,
      advancedAnalysisId,
    } = refs.filterState.current;
    const autoanalysis_id = getAutoAnalysisIdQueryString();
    const exact_data = getExactQueryString();
    const encodeAndJoin = arr => arr.map(a => encodeURIComponent(a)).join("|");
    const encodeAndJoinD = arr => arr.map(a => encodeURIComponent(a)).join("$");

    const cacheCount = getCacheCount();

    const urlArr = [];
    if (exact_data && exact_data.exact_tag_search) {
      Object.keys(exact_data).forEach(key => {
        if (exact_data[key] && exact_data[key] !== "undefined") {
          urlArr.push(`${key}=${exact_data[key]}`);
        }
      });
    }
    if (autoAnalysis === true) {
      urlArr.push("autoAnalysis=true");
    }
    if (statusToFilter && statusToFilter !== "all") {
      urlArr.push(`status=${statusToFilter}`);
    }
    if (sectorsToFilter.length > 0) {
      urlArr.push(`sector=${encodeAndJoinD(sectorsToFilter)}`);
    }
    if (compsToFilter.length > 0) {
      urlArr.push(
        `company=${encodeAndJoin(
          compsToFilter.map(c => {
            if (typeof c === "string") {
              return c;
            } else if (c.name) {
              return c.negative ? `--${c.name}` : c.name;
            }
          })
        )}`
      );
    }
    if (typesToFilter.length > 0) {
      urlArr.push(`page_type=${encodeAndJoin(typesToFilter)}`);
    }
    if (originalTagsToFilter.length > 0) {
      urlArr.push(
        `tag=${originalTagsToFilter
          .map(t => {
            const tagValue = t.isGroup ? t.label : t.value;
            return `${
              t.negative && !tagValue.startsWith("--") ? `--` : ``
            }${encodeURIComponent(tagValue)}`;
          })
          .join("|")}`
      );
    }
    if (baseTagsToFilter && baseTagsToFilter.length > 0) {
      urlArr.push(`baseTag=${encodeAndJoin(baseTagsToFilter)}`);
    }
    if (challengesToFilter.length > 0) {
      urlArr.push(`challenge=${encodeAndJoin(challengesToFilter)}`);
    }
    if (collectionsToFilter.length > 0) {
      urlArr.push(`collection=${encodeAndJoin(collectionsToFilter)}`);
    }
    if (platform === "mobile" || platform === "desktop") {
      urlArr.push(`platform=${encodeURIComponent(platform)}`);
    }

    if (sortByDate && sortByDate !== "ascending") {
      urlArr.push(`sort=${sortByDate}`);
    }

    if (selectedAnalysis || currentAnalysis) {
      if (currentAnalysis && !selectedAnalysis) {
        urlArr.push(`analysis=${currentAnalysis}`);
      } else {
        urlArr.push(`analysis=${selectedAnalysis.id}`);
      }
    }
    if (onlyAnalysis) {
      urlArr.push(`onlyAnalysis=true`);
    }
    if (loadNewTests) {
      urlArr.push(`loadNewTests=true`);
    }
    if (start_date) {
      urlArr.push(`start_date=${start_date}`);
    }
    if (end_date) {
      urlArr.push(`end_date=${end_date}`);
    }
    if (publish_start_date) {
      urlArr.push(`publish_start_date=${publish_start_date}`);
    }
    if (publish_end_date) {
      urlArr.push(`publish_end_date=${publish_end_date}`);
    }
    if (noOfTags && noOfTags !== "0" && noOfTags !== "1-6") {
      urlArr.push(`noOfTags=${noOfTags}`);
    }
    if (whoWon && whoWon !== "all") {
      urlArr.push(`whoWon=${whoWon}`);
    }
    if (country && country !== "all" && country.length > 0) {
      urlArr.push(`country=${country}`);
    }
    if (locations && locations.length > 0) {
      urlArr.push(`locations=${locations}`);
    }
    if (cacheCount && api) {
      urlArr.push(`cache=${cacheCount}`);
    }
    if (refs.qs.current && noCache) {
      urlArr.push(`noCache=true`);
    }
    if (watch_test) {
      urlArr.push(`watch_test=true`);
    }
    if (recent) {
      urlArr.push(`recent=views`);
    }
    if (url) {
      urlArr.push(`url=${encodeURIComponent(url)}`);
    }
    if (advancedAnalysisId) {
      urlArr.push(`advanced_analysis_id=${advancedAnalysisId}`);
    }
    if (columnFilters && columnFilters.length > 0) {
      urlArr.push(`columnFilters=${JSON.stringify(columnFilters)}`);
    }
    if (autoanalysis_id) {
      urlArr.push(`autoanalysis_id=${autoanalysis_id}`);
    }
    if (urlArr.length == 1 && urlArr[0].startsWith("sort=")) {
      urlArr.pop();
      setFilterState({
        ...filterState,
        sortByDate: null,
      });
    }

    return urlArr;
  };
  const transformAdvancedAnalysis = analysis => {
    analysis.variants.forEach(v => {
      v.conditions = [];
      v.name = v.key;
      v.must.forEach(x => {
        v.conditions.push({
          key: x.key,
          boolean_type: "must",
          search_type: x.type,
        });
      });
      v.should.forEach(x => {
        v.conditions.push({
          key: x.key,
          boolean_type: "should",
          search_type: x.type,
        });
      });
      if (v.must_not) {
        v.must_not.forEach(x => {
          v.conditions.push({
            key: x.key,
            boolean_type: "must_not",
            search_type: x.type,
          });
        });
      }
      if (v.should_not) {
        v.should_not.forEach(x => {
          v.conditions.push({
            key: x.key,
            boolean_type: "must_not",
            search_type: x.type,
          });
        });
      }
    });
    return analysis;
  };
  const getFilterdData = (
    skipFilters,
    skipSave,
    skipCache,
    saved_tests,
    skipLoading
  ) => {
    const filterObj = null;
    const filterArr = makeUrlParams(true);
    const params = filterArr.length > 0 ? `&${filterArr.join("&")}` : "";
    let analysisDirty = "";
    if (refs.analysisDirty.current) {
      analysisDirty = `&aCache=${refs.analysisDirty.current}`;
    }
    const { autoanalysis_id } = querystring.parse(window.location.search);
    let autoanalysisId = autoanalysis_id
      ? `&autoanalysis_id=${autoanalysis_id}`
      : "";
    const apiUrl = `${baseApiUrl}/v1/search_v2?cust=${props.match.params.company}&from=0&size=${refs.showTests.current}${params}${analysisDirty}${autoanalysisId}`;
    refs.getDataApiLoading.current = true;
    if (!skipLoading) {
      setLoading(true);
      if (refs.showTests.current <= 100) {
        setTestsLoading(true);
        setTestLoadingStage(1);
      }
    }
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: props.user.token,
        },
      },
      false,
      true,
      skipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          props.history.push(`/subscribe/${props.match.params.company}`);
          return;
        }
        if (!skipLoading) {
          setLoading(true);
          setTestsLoading(false);
          setTestLoadingStage(0);
        }
        refs.getDataApiLoading.current = false;

        setAccessDenied(res && res.stausCode === 400);
        clearTimeout(refs.testLoadingTimeout.current);

        return;
      }
      clearTimeout(refs.testLoadingTimeout.current);
      if (!skipLoading) {
        setTestsLoading(false);
        setTestLoadingStage(0);
        setLoading(false);
        setLoadedSavedTests(true);
        setDoChangeLoad(false);
      }
      setCount(data.total);
      refs.count.current = data.total;
      setTests(data.data);
      if (refs.filterState.current && refs.filterState.current.loadNewTests) {
        data.data["All Tests"].splits.sort((a, b) => {
          const aMissing =
            data.analysis_info.autoanalysis_data.missing_tests.includes(a.id);
          const bMissing =
            data.analysis_info.autoanalysis_data.missing_tests.includes(b.id);

          if (aMissing && !bMissing) {
            return -1;
          } else if (bMissing && !aMissing) {
            return 1;
          } else {
            return;
          }
        });
      }
      if (data.autoanalysis_data) {
        setAutoanalysisData(data.autoanalysis_data);
      } else if (data.analysis_info && data.analysis_info.autoanalysis_data) {
        setAutoanalysisData(data.analysis_info.autoanalysis_data);
      } else {
        setAutoanalysisData(null);
      }
      setTagsOfInterest(data.tagsOfInterest || []);
      if (data.advanced_analysis) {
        setAdvancedAnalysis(transformAdvancedAnalysis(data.advanced_analysis));
      }
      if (data.autoAnalyses) {
        setAllAnalyses(data.autoAnalyses);
      }
      refs.getDataApiLoading.current = false;
      if (data.total <= 50) {
        trackLowData(data.total);
      }
    });
  };
  const trackLowData = count => {
    const filterArr = makeUrlParams(true);
    const params = filterArr.length > 0 ? `&${filterArr.join("&")}` : "";
    request({
      url: `${baseApiUrl}/v1/t?url=${encodeURIComponent(
        `${window.location.href}&LOW_SEARCH_DATA_COUNT=${count}`
      )}`,
      headers: {
        Authorization: props.user.token,
      },
    });
  };
  const getAllFilters = (
    skipFilters,
    skipSave,
    skipCache,
    saved_tests,
    isNew = false
  ) => {
    const filterObj = null;
    const filterArr = makeUrlParams(true);
    const params = filterArr.length > 0 ? `&${filterArr.join("&")}` : "";
    const apiUrl = `${baseApiUrl}/v1/search_filter?cust=${props.match.params.company}&from=0&size=${refs.showTests.current}${params}`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: props.user.token,
        },
      },
      false,
      true,
      skipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          props.history.push(`/subscribe/${props.match.params.company}`);
          return;
        }
        setLoading(true);
        setAccessDenied(res && res.statusCode === 400);
        return;
      }
      const filters = props.saved_search ? data.search.search : filterObj;
      const selectedCompany = getCompanyQueryString();
      const { start_date, end_date, publish_start_date, publish_end_date } =
        getDatesQueryString();

      let compsToFilter = [];
      let newFilters = [];
      let collectionsToFilter;

      if (refs.filterState.current.url) {
        newFilters.push({
          label: "Page",
          value: refs.filterState.current.url,
          type: "url",
        });
      }
      if (refs.filterState.current.recent === "views") {
        newFilters.push({
          label: "Recent Views",
          value: "views",
          type: "recent",
        });
      }
      if (refs.filterState.current.great) {
        newFilters.push({
          label: "Great Tests",
          value: true,
          type: "great",
        });
      }
      if (refs.filterState.current.noOfTags !== "0") {
        newFilters.push({
          label: "No Of Tags",
          value: refs.filterState.current.noOfTags,
          type: "noOfTags",
        });
      }

      if (refs.filterState.current.baseTagsToFilter) {
        refs.filterState.current.baseTagsToFilter.forEach(tag => {
          newFilters.push({
            label: "Base Tag",
            name: tag,
            value: tag,
            type: "basetag",
          });
        });
      }

      if (selectedCompany) {
        compsToFilter = data.companies
          .filter(comp => {
            return (
              selectedCompany.includes(comp.name) ||
              selectedCompany.includes(`--${comp.name}`)
            );
          })
          .map(c => ({
            name: c.name,
            negative: selectedCompany.includes(`--${c.name}`),
          }));
        compsToFilter.forEach(c => {
          newFilters.push({
            label: c.name,
            value: c.name,
            type: "companies",
            negative: c.negative,
          });
        });
      }
      if (refs.filterState.current.competitors && data.customer.competitors) {
        compsToFilter = compsToFilter.concat(data.customer.competitors);
        newFilters.push({
          label: "Competitors",
          value: true,
          displayCount: data.customer.competitors.length,
          type: "competitors",
        });
        data.customer.competitors.forEach(c => {
          newFilters.push({
            label: c,
            value: c,
            type: "companies",
            hide: true,
            competitor: true,
          });
        });
      }
      // TODO: change all these lines to use state then query string
      if (refs.qs.current && refs.qs.current.challenge) {
        const chal = getChallengeQueryString();
        chal.forEach(c => {
          const isNegative = c.startsWith("--");
          if (isNegative) {
            c = c.substring(2);
          }
          newFilters.push({
            label: c,
            value: c,
            type: "challenges",
            negative: isNegative,
          });
        });
      }
      if (refs.qs.current && refs.qs.current.status) {
        const status = getStatusQueryString();
        newFilters.push({ label: status, value: status, type: "status" });
      }
      if (refs.qs.current && refs.qs.current.platform) {
        const platform = getPlatformQueryString();
        newFilters.push({ label: platform, value: platform, type: "platform" });
      }
      if (refs.qs.current && refs.qs.current.sector) {
        const sectors = getSectorQueryString();
        sectors.forEach(sector => {
          const isNegative = sector.startsWith("--");
          if (isNegative) {
            sector = sector.substring(2);
          }
          newFilters.push({
            label: sector.replace(/\|\|/gi, ">"),
            value: sector,
            type: "sectors",
            negative: isNegative,
          });
        });
      }
      if (
        (refs.qs.current && refs.qs.current.collection) ||
        props.match.params.tid
      ) {
        collectionsToFilter = getCollectionQueryString();
        collectionsToFilter.forEach(c => {
          const isNegative = c.startsWith("--");
          if (isNegative) c = c.substring(2);
          const collection = data.saved_tests.find(x => x.id === c);
          if (collection && collection.name) {
            newFilters.push({
              label: collection.name,
              value: c,
              type: "collections",
              negative: isNegative,
            });
          }
        });
      }
      let typesToFilter = [];
      if (refs.qs.current && refs.qs.current.page_type) {
        typesToFilter = getPageTypeQueryString();
        typesToFilter.forEach(c => {
          const isNegative = c.startsWith("--");
          if (isNegative) c = c.substring(2);
          newFilters.push({
            label: c,
            value: c,
            type: "page_types",
            negative: isNegative,
          });
        });
      }
      if (refs.qs.current && refs.qs.current.watch_test) {
        newFilters.push({
          label: "Watch Test",
          value: true,
          type: "watch_test",
        });
      }
      if (refs.qs.current && refs.qs.current.whoWon) {
        const whoWon = getWhoWonQueryString();
        newFilters.push({
          label: whoWon,
          value: whoWon,
          type: "whoWon",
        });
      }
      if (refs.qs.current && refs.qs.current.country) {
        const country = getCountryQueryString();
        country.forEach(c => {
          if (c.includes("--")) {
            const val = c.replace("--", "");
            newFilters.push({
              label: COUNTRIES.find(x => x.value === val).label,
              value: val,
              negative: true,
              type: "country",
            });
          } else {
            newFilters.push({
              label: COUNTRIES.find(x => x.value === c).label,
              value: c,
              type: "country",
              negative: false,
            });
          }
        });
      }
      if (refs.qs.current && refs.qs.current.locations) {
        let location = getLocationsQueryString();
        const isNegative = location.startsWith("--");
        if (isNegative) {
          location = location.substring(2);
        }
        newFilters.push({
          label: LOCATIONS.find(c => c.value === location).label,
          value: location,
          type: "location",
          negative: isNegative,
        });
      }
      const tagsToFilter = [];
      if (refs.qs.current && refs.qs.current.tag) {
        const tag = getTagQueryString().tagsToFilter;
        tag.forEach(t => {
          const isNegative = t.startsWith("--");
          if (isNegative) t = t.substring(2);
          const tText = t.toLowerCase();
          let tag = data.tags
            .filter(x => x.isGroup)
            .find(x => x.tag.toLowerCase() === tText);

          if (!tag) {
            tag = data.tags.find(x => x.tag.toLowerCase() === tText);
          }
          if (tag) {
            tag.type = "tags";
            tag.label = tag.tag;
            tag.negative = isNegative;
            newFilters.push(tag);
            if (typeof tag !== "string" && tag.isGroup) {
              tag.value.forEach(t => {
                tagsToFilter.push(t);
              });
            } else {
              tag.value = tText;
              tagsToFilter.push(t);
            }
          } else {
            newFilters.push({
              type: "tags",
              label: t,
              value: t,
              negative: isNegative,
            });
            tagsToFilter.push(t);
          }
        });
      }
      if (start_date) {
        newFilters.push({
          label: formatDateFromString(start_date),
          value: start_date,
          type: "start_date",
        });
      }
      if (end_date) {
        newFilters.push({
          label: formatDateFromString(end_date),
          value: end_date,
          type: "end_date",
        });
      }
      if (publish_start_date) {
        newFilters.push({
          label: formatDateFromString(publish_start_date),
          value: publish_start_date,
          type: "publish_start_date",
        });
      }
      if (publish_end_date) {
        newFilters.push({
          label: formatDateFromString(publish_end_date),
          value: publish_end_date,
          type: "publish_end_date",
        });
      }

      if (filters && !skipFilters) {
        if (!newFilters.length > 0) {
          filters.compsToFilter.forEach(c => {
            newFilters.push({ label: c, value: c, type: "companies" });
          });
          filters.challengesToFilter.forEach(c => {
            const isNegative = c.startsWith("--");
            if (isNegative) c = c.substring(2);
            newFilters.push({
              label: c,
              value: c,
              type: "challenges",
              negative: isNegative,
            });
          });
          const collectionsToFilter =
            filterState.collectionsToFilter.length > 0
              ? filterState.collectionsToFilter
              : filters.collectionsToFilter;
          if (collectionsToFilter) {
            if (data.saved_tests) {
              collectionsToFilter.forEach(c => {
                const collection = data.saved_tests.find(x => x.id === c);
                if (collection) {
                  collection.type = "collections";
                  newFilters.push({
                    label: collection.name,
                    value: c,
                    type: "collections",
                  });
                }
              });
            }
          }
          filters.originalTagsToFilter.forEach(c => {
            newFilters.push(c);
          });
          filters.typesToFilter.forEach(c => {
            newFilters.push({ label: c, value: c, type: "page_types" });
          });
          if (filters.platform && filters.platform !== "all") {
            newFilters.push({
              label: filters.platform,
              value: filters.platform,
              type: "platform",
            });
          }
          if (filters.statusToFilter && filters.statusToFilter !== "all") {
            newFilters.push({
              label: capitalize(filters.statusToFilter),
              value: filters.statusToFilter,
              type: "status",
            });
          }
        }
        setLoading(false);
        setCustomer(data.customer);
        setDataState({
          ...dataState,
          report: data.report,
          types: data.types,
          keywords: data.keywords,
          companies: data.companies,
          collection_companies: data.collection_companies,
          collection_tags: data.collection_tags,
          challenges: data.modules,
          categories: data.categories,
          tags: data.tags,
          recent_tests: data.recent_tests,
          saved_tests: data.saved_tests,
          note_tags: data.tags.map(n => {
            if (n.isGroup) {
              return {
                value: n.value,
                label: n.tag,
                isGroup: true,
                id: n.id,
                baseTag: n.baseTag,
                count: n.count,
              };
            } else {
              return {
                value: n.tag.toLowerCase(),
                label: n.baseTag ? `${n.tag}: All` : n.tag,
                baseTag: n.baseTag,
                count: n.count,
              };
            }
          }),
          //   totalCompanies: Object.keys(data.data).length,
          customers: data.customers,
          sectors: data.new_sectors,
          sector_lookup: data.sector_lookup,
          analysis: data.analysis,
        });
        //setFilters(newFilters);
        setDoChangeLoad(false);
        setFiltersLoaded(true);
        const collectionData = filterState.showCollection
          ? data.saved_tests
          : data.analysis;
        setFilterState({
          ...filterState,
          filters: newFilters,
          platform: filters.platform ? filters.platform : "all",
          latest: getPlatformQueryString(),
          state_live: filters.state_live,
          state_ended: filters.state_ended,
          state_winner: filters.state_winner,
          compsToFilter: compsToFilter || [],
          challengesToFilter:
            refs.qs.current && refs.qs.current.challenge
              ? getChallengeQueryString()
              : filters.challengesToFilter || [],
          tagsToFilter:
            refs.qs.current && refs.qs.current.tag
              ? tagsToFilter
              : filters.tagsToFilter || [],
          baseTagsToFilter:
            refs.qs.current && refs.qs.current.baseTag
              ? getBaseTagQueryString()
              : filters.baseTagsToFilter || [],
          originalTagsToFilter: filters.originalTagsToFilter || [],
          typesToFilter:
            typesToFilter.length > 0
              ? typesToFilter
              : filters.typesToFilter || [],
          catsToFilter: filters.catsToFilter || [],
          statusToFilter: filters.statusToFilter || null,
          collectionsToFilter:
            filterState.collectionsToFilter.length > 0
              ? filterState.collectionsToFilter
              : filters.collectionsToFilter,
          expandChallenges:
            refs.qs.current && refs.qs.current.challenge
              ? true
              : filters.expandChallenges === false
                ? false
                : true,
          start_date,
          end_date,
          publish_start_date,
          publish_end_date,
          advancedAnalysisId: filterState.advancedAnalysisId,
          selectedAnalysis:
            filterState.currentAnalysis && collectionData
              ? collectionData.find(a => a.id === filterState.currentAnalysis)
              : null,
        });
      } else {
        setLoading(false);
        setCustomer(data.customer);
        setDataState({
          ...dataState,
          report: data.report,
          keywords: data.keywords,
          companies: data.companies,
          collection_companies: data.collection_companies,
          collection_tags: data.collection_tags,
          challenges: data.modules,
          categories: data.categories,
          tags: data.tags,
          recent_tests: data.recent_tests,
          saved_tests: data.saved_tests,
          note_tags: data.tags.map(n => {
            if (n.isGroup) {
              return {
                value: n.value,
                label: n.tag,
                isGroup: true,
                id: n.id,
                baseTag: n.baseTag,
                count: n.count,
              };
            } else {
              return {
                value: n.tag.toLowerCase(),
                label: n.baseTag ? `${n.tag}: All` : n.tag,
                baseTag: n.baseTag,
                count: n.count,
              };
            }
          }),
          //   totalCompanies: Object.keys(data.data).length,
          customers: data.customers,
          sectors: data.new_sectors,
          sector_lookup: data.sector_lookup,
          analysis: data.analysis,
          types: data.types,
        });
        const collectionData = filterState.showCollection
          ? data.saved_tests
          : data.analysis;
        // setFilters(newFilters);
        setDoChangeLoad(false);
        setFiltersLoaded(true);
        setFilterState({
          ...filterState,
          filters: newFilters,
          compsToFilter: compsToFilter ? compsToFilter : [],
          tagsToFilter:
            refs.qs.current && refs.qs.current.tag ? tagsToFilter : [],
          baseTagsToFilter:
            refs.qs.current && refs.qs.current.baseTag
              ? getBaseTagQueryString()
              : [],
          originalTagsToFilter:
            refs.qs.current && refs.qs.current.tag
              ? getTagQueryString().originalTagsToFilter
              : [],
          challengesToFilter:
            refs.qs.current && refs.qs.current.challenge
              ? getChallengeQueryString()
              : [],
          start_date,
          end_date,
          publish_start_date,
          publish_end_date,
          selectedAnalysis:
            filterState.currentAnalysis && collectionData
              ? collectionData.find(a => a.id === filterState.currentAnalysis)
              : null,
        });
      }
      if (refs.firstLoad.current === null) {
        if (newFilters.length > 0) {
          refs.showFilterBar.current = true;
        }
        refs.firstLoad.current = true;
      }
      // if (refs.loadFilterOnly.current === true) {
      // }
    });
  };
  const getDataCSV = (skipFilters, skipSave, skipCache, saved_tests) => {
    if (csvRequestLoading) return;
    setCSVRequestLoading(true);
    const filterArr = makeUrlParams(true);
    if (filterArr.length == 0) {
      setCSVRequestLoading(false);
      alert("Please add filters before downloading csv");
      return;
    }
    const params = filterArr.length > 0 ? `&${filterArr.join("&")}` : "";
    let analysisDirty = "";
    if (refs.analysisDirty.current) {
      analysisDirty = `&aCache=${refs.analysisDirty.current}`;
    }
    const apiUrl = `${baseApiUrl}/v1/search_v2?cust=${props.match.params.company}&from=0&size=5000${params}${analysisDirty}&download_csv=true`;

    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: props.user.token,
        },
      },
      false,
      true,
      skipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        setCSVRequestLoading(false);
      } else {
        window.open(data.csvURL, "_blank");
        setCSVRequestLoading(false);
      }
    });
  };

  const handleScroll = e => {
    if (componentLoaded.current === false) return;
    clearTimeout(refs.scrollTO.current);

    if (
      window.scrollY + 20000 > document.body.offsetHeight &&
      refs.showTests.current < refs.count.current &&
      !refs.getDataApiLoading.current &&
      refs.doNotReloadTests.current === false
    ) {
      refs.showTests.current += 100;
      getFilterdData();
    }
    if (refs.doNotReloadTests.current === true) {
      refs.doNotReloadTests.current = false;
    }
    if (!refs.didFilterUpdate.current) {
      refs.scrollTO.current = setTimeout(() => {
        if (
          // filters &&
          // filters.length > 0 &&
          Math.abs(refs.scrollPos.current - window.scrollY) > 3
        ) {
          if (refs.scrollPos.current >= window.scrollY) {
            if (refs.showFilterBar.current) {
              refs.showFilterBar.current = false;
              if (refs.filterBarElement.current) {
                // refs.filterBarElement.current.style.display = "none";
                refs.filterBarElement.current.classList.add("header-animate");
              }
              setShowFilters(true);
            }
          } else {
            if (!refs.showFilterBar.current) {
              refs.showFilterBar.current = true;
              if (refs.filterBarElement.current) {
                // refs.filterBarElement.current.style.display = "";
                refs.filterBarElement.current.classList.remove(
                  "header-animate"
                );
              }
              setShowFilters(false);
            }
          }
        }
        refs.scrollPos.current = window.scrollY;
      }, 500);
    } else {
      refs.didFilterUpdate.current = false;
    }
  };
  const addToStorage = skipData => {
    const urlArr = makeUrlParams();
    const currentURLParams = urlArr.length > 0 ? `?${urlArr.join("&")}` : "";
    refs.skipData.current = skipData;
    if (!props.match.params.ssid) {
      if (skipData) {
        return props.history.push(
          `/home/${props.match.params.company}/report${currentURLParams}`
        );
      }
      if (currentURLParams !== urlParams) {
        props.history.push(
          `/home/${props.match.params.company}/report${currentURLParams}`
        );
        setUrlParams(currentURLParams);
        // setUrlParams(currentURLParams);
        // setDoChangeLoad(true);
        // setLoading(true);
      } else {
        // props.history.push(
        //   `/home/${props.match.params.company}/report${currentURLParams}`
        // );
      }

      // if (!skipData) {
      //   clearTimeout(refs.gdTimeout.current);
      //   refs.gdTimeout.current = setTimeout(() => {
      //     getFilterdData(true);
      //   }, 50);
      // }
    }
    // getCachedQS(true);
    // refs.clearQS.current = true;
  };
  const filterValueMapper = c => (c.negative ? `--${c.value}` : c.value);
  const filterCallback = filters => {
    const originalTagsToFilter = filters.filter(c => c.type === "tags");

    const tags = [];

    originalTagsToFilter.forEach(t => {
      if (t.isGroup) {
        t.value.forEach(val => {
          tags.push(t.negative ? `--${val}` : val);
        });
      } else {
        tags.push(t.negative ? `--${t.value}` : t.value);
      }
    });

    refs.didFilterUpdate.current = true;
    refs.filterDataDirty.current = true;
    if (filters.length == 1 && filters[0].type === "sort") {
      filters.pop();
    }
    let { sortByDate, tagsToFilter } = filterState;
    if (tags.length === 0 && tagsToFilter.length > 0) {
      sortByDate = "ascending";
    }
    if (tags.length > 0 && tagsToFilter.length === 0) {
      sortByDate = "relevance";
    }
    // setFilters(filters);
    refs.showFilterBar.current = filters.length > 0;
    setFilterState({
      ...filterState,
      filters: filters,
      compsToFilter: filters
        .filter(c => c.type === "companies")
        .map(filterValueMapper),
      challengesToFilter: filters
        .filter(c => c.type === "challenges")
        .map(filterValueMapper),
      tagsToFilter: tags,
      baseTagsToFilter: filters
        .filter(c => c.type === "basetag")
        .map(filterValueMapper),
      originalTagsToFilter,
      typesToFilter: filters
        .filter(c => c.type === "page_types")
        .map(filterValueMapper),
      collectionsToFilter: filters
        .filter(c => c.type === "collections")
        .map(filterValueMapper),
      sectorsToFilter: filters
        .filter(c => c.type === "sectors")
        .map(filterValueMapper),
      locations: filters
        .filter(c => c.type === "location")
        .map(filterValueMapper),
      country: filters.filter(c => c.type === "country").map(filterValueMapper),
      sortByDate,
    });
    addToStorage();
  };
  const handleStatusFilter = status => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "status"
    );
    if (status === "live" || status === "ended") {
      newFilters.push({ label: status, value: status, type: "status" });
    }
    setFilterState({
      ...filterState,
      filters: newFilters,
      statusToFilter: status,
    });
    // setFilters(newFilters);
    refs.showFilterBar.current = true;
    addToStorage();
    // setShowFilters(true);
  };
  const handlePlatformFilter = platform => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "platform"
    );
    if (platform === "desktop" || platform === "mobile") {
      newFilters.push({ label: platform, value: platform, type: "platform" });
    }
    setFilterState({
      ...filterState,
      filters: newFilters,
      platform,
    });
    // setFilters(newFilters);
    refs.showFilterBar.current = true;
    addToStorage();
    // setShowFilters(true);
  };
  const changeDateSort = val => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "sort"
    );
    if (val !== "ascending") {
      newFilters.push({ label: "Date", value: val, type: "sort" });
    }
    // setFilters(newFilters);
    setFilterState({
      ...filterState,
      filters: newFilters,
      sortByDate: val,
    });
    addToStorage();
  };
  const changePlatform = (e, count) => {
    refs.filterDataDirty.current = true;
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "platform"
    );
    if (e === "desktop" || e === "mobile") {
      newFilters.push({ label: e, value: e, type: "platform" });
    }
    // setFilters(newFilters);
    setFilterState({
      ...filterState,
      filters: newFilters,
      platform: e,
    });
    addToStorage();
  };
  const changeNoOfTags = val => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "noOfTags"
    );
    if (val && val !== "1-6") {
      newFilters.push({ label: "No Of Tags", value: val, type: "noOfTags" });
    }
    // setFilters(newFilters);
    setFilterState({
      ...filterState,
      filters: newFilters,
      noOfTags: val != "1-6" ? val : null,
    });
    addToStorage();
  };
  const changeWhoWon = val => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "whoWon"
    );
    if (val !== "all") {
      newFilters.push({ label: "WhoWon", value: val, type: "whoWon" });
    }
    // setFilters(newFilters);
    setFilterState({
      ...filterState,
      filters: newFilters,
      whoWon: val,
    });
    addToStorage();
  };
  const changeCountry = vals => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "country"
    );
    vals.forEach(val => {
      if (val !== "all") {
        newFilters.push({ label: "Country", value: val, type: "country" });
      }
    });
    setFilterState({
      ...filterState,
      filters: newFilters,
      country: vals,
    });
    addToStorage();
  };
  const changeLocation = val => {
    const newFilters = refs.filterState.current.filters.filter(
      f => f.type !== "location"
    );
    if (val !== "all") {
      newFilters.push({ label: "Page Location", value: val, type: "location" });
    }
    // setFilters(newFilters);
    setFilterState({
      ...filterState,
      filters: newFilters,
      locations: val,
    });
    addToStorage();
  };
  const toggleFilter = e => {
    e.preventDefault();
    setShowFilterSidebar(!showFilterSidebar);
  };
  const removeTag = tag => e => {
    let newFilters = refs.filterState.current.filters.filter(
      t => t.value !== tag.value || t.type !== tag.type
    );
    if (tag.type === "competitors") {
      newFilters = newFilters.filter(t => !t.competitor);
    }

    let filteredTags = newFilters
      .filter(f => f.type === tag.type)
      .map(f => f.value);

    if (filteredTags && filteredTags.length === 0) {
      filteredTags = null;
    }

    filterCallback(newFilters);
    if (tag.type === "platform") {
      setFilterState({
        ...refs.filterState.current,
        filters: newFilters,
        platform: "all",
      });
      addToStorage();
    } else if (tag.type === "status") {
      setFilterState({
        ...refs.filterState.current,
        filters: newFilters,
        statusToFilter: null,
      });
    } else if (tag.type === "sort") {
      changeDateSort(true);
    } else if (tag.type === "location") {
      setFilterState({
        ...refs.filterState.current,
        filters: newFilters,
        locations: filteredTags,
      });
    } else {
      setFilterState({
        ...refs.filterState.current,
        filters: newFilters,
        [tag.type]: filteredTags,
      });
    }
    addToStorage();
  };
  const clearAllFilters = e => {
    refs.didFilterUpdate.current = true;
    refs.filterDataDirty.current = true;
    refs.showFilterBar.current = false;
    // changeDateSort(true);
    setFilterState({
      ...filterState,
      filters: [],
      start_date: null,
      end_date: null,
      publish_start_date: null,
      publish_end_date: null,
      recent: null,
      platform: "all",
      statusToFilter: null,
      noOfTags: null,
      whoWon: null,
      country: null,
      location: null,
      watch_test: null,
      compsToFilter: [],
      challengesToFilter: [],
      tagsToFilter: [],
      baseTagsToFilter: [],
      originalTagsToFilter: [],
      typesToFilter: [],
      collectionsToFilter: [],
      sectorsToFilter: [],
      sortByDate: "ascending",
      url: null,
      country: null,
      locations: null,
    });
    addToStorage();
  };
  const toggleSearchFilter = tag => e => {
    let newFilters = refs.filterState.current.filters.map(t => {
      if (t.value === tag.value && t.type === tag.type) {
        return {
          ...t,
          negative: !t.negative,
        };
      } else {
        return t;
      }
    });

    filterCallback(newFilters);
  };
  const handleFilterContent = (vals, filterCount = 0) => {
    let newFilters;
    refs.filterDataDirty.current = true;
    newFilters = refs.filterState.current.filters.filter(
      x =>
        x.type !== "start_date" &&
        x.type !== "end_date" &&
        x.type !== "publish_start_date" &&
        X.type !== "publish_end_date"
    );
    if (vals.start_date) {
      filterCount++;
      newFilters.push({
        label: formatDateFromString(vals.start_date),
        value: vals.start_date,
        type: "start_date",
      });
    }
    if (vals.end_date) {
      filterCount++;
      newFilters.push({
        label: formatDateFromString(vals.end_date),
        value: vals.end_date,
        type: "end_date",
      });
    }
    if (vals.publish_start_date) {
      filterCount++;
      newFilters.push({
        label: formatDateFromString(vals.publish_start_date),
        value: vals.publish_start_date,
        type: "publish_start_date",
      });
    }
    if (vals.publish_end_date) {
      filterCount++;
      newFilters.push({
        label: formatDateFromString(vals.publish_end_date),
        value: vals.publish_end_date,
        type: "publish_end_date",
      });
    }
    // setFilters(newFilters);
    setFilterState({
      ...filterState,
      ...vals,
      filters: newFilters,
    });
    addToStorage();
  };
  const updateCache = () => {
    addToStorageUtil(CACHE_NAME, { cache: new Date().getTime() });
  };
  const watchTestCallback = (test_id, watch_test, index) => {
    // console.log(`requesting test ${test_id} watching ${watch_test}`);
    request.post(
      {
        url: `${baseApiUrl}/v1/user/watch_test`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          test_id,
          watch_test,
        },
      },
      err => {
        if (err) {
          alert("Something went wrong...");
          return;
        }
        // console.log(`watching test`);
        setTimeout(() => {
          const allTests = tests["All Tests"].splits.map((value, i) => {
            if (i == index) {
              return {
                ...value,
                is_watching: watch_test,
              };
            } else {
              return {
                ...value,
              };
            }
          });
          setTests({
            ["All Tests"]: {
              splits: [...allTests],
            },
          });
        }, 500);
      }
    );
  };
  const refreshSavedTests = (
    saved_tests,
    isNew,
    test_id,
    action,
    collection_id
  ) => {
    const updatedTests = tests["All Tests"].splits.map(test => {
      if (test.id === test_id) {
        const collections = test.collections;
        // const updatedCollections = test.updatedCollections;
        if (action === "save") {
          if (!collections.includes(collection_id)) {
            collections.push(collection_id);
          }
          return {
            ...test,
            collections,
            updatedCollections: collections,
          };
        } else if (action === "delete") {
          return {
            ...test,
            collections: collections.filter(item => item !== collection_id),
            updatedCollections: collections.filter(
              item => item !== collection_id
            ),
          };
        }
      } else {
        return test;
      }
    });
    updateCache();
    if (isNew) {
      setTimeout(() => {
        // refs.loadFilterOnly.current = true;
        addToStorage(true);
        //getAllFilters(true, true, true, saved_tests, isNew);
        setFiltersLoaded(false);
        getAllFilters(false, false, true);
      }, 10000);
    }
    refs.doNotReloadTests.current = true;
    setTests({
      ["All Tests"]: {
        splits: [...updatedTests],
      },
    });
    const updatedSavedTests = dataState.saved_tests.map(savedTest => {
      if (savedTest.id === collection_id) {
        const count =
          action === "save"
            ? savedTest.count + 1
            : action === "delete"
              ? savedTest.count - 1
              : savedTest.count;
        return { ...savedTest, count };
      } else {
        return savedTest;
      }
    });
    if (isNew) {
      const newCollection = saved_tests.find(
        collection => collection.id === collection_id
      );
      if (newCollection) {
        updatedSavedTests.push({
          ...newCollection,
          count: 1,
        });
      }
    }
    setDataState({
      ...dataState,
      saved_tests: updatedSavedTests,
    });
  };
  const updateCBs = e => {
    const checkboxes = document.querySelectorAll("input.admin-checkbox");

    const ids = [];

    checkboxes.forEach(cb => {
      if (cb.checked) {
        ids.push(cb.value);
      }
    });
    setPublishIds(ids);
  };
  const getAnalysisItems = doLoad => {
    updateCache();
    if (props.match.params.company !== "admin") {
      return;
    }
    if (doLoad) {
      setLoading(true);
      setAnalysisLoading(true);
    }
    if (!filterState.selectedAnalysis) {
      setFilterState({
        ...filterState,
        currentAnalysis: null,
        selectedAnalysis: null,
      });
      addToStorage();
      return;
    }
    const cache = getCacheCount();
    let cacheStr = "";
    if (cache) {
      cacheStr = `&cache=${cache}`;
    }
    request.get(
      {
        url: `${baseApiUrl}/v1/admin/analysis/item?analysis_key=${filterState.selectedAnalysis.id}${cacheStr}`,
        headers: {
          Authorization: props.user.token,
        },
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        if (json.items) {
          if (!doLoad) {
            setLoading(true);
            setTimeout(() => {
              setAnalysisData(json.items);
              setLoading(false);
              setAnalysisLoading(false);
            }, 100);
          } else {
            setAnalysisData(json.items);
            setLoading(false);
            setAnalysisLoading(false);
          }
        }
      }
    );
  };
  const updateAnalysis = (id, analysis, weighting, selected, analysis_d) => {
    if (!analysisData[id]) {
      analysisData[id] = {
        analysis,
        analysis_data: analysis_d,
        weighting: weighting || "1",
        selected,
        id: analysisData[id] ? analysisData[id].id : null,
      };
    } else {
      analysisData[id].analysis = analysis;
      analysisData[id].weighting = weighting || "1";
      analysisData[id].selected = selected;
      if (analysis_d) {
        analysisData[id].analysis_data = analysis_d;
      }
    }

    setAnalysisData(analysisData);
    clearTimeout(refs.saveTimeout.current.id);
    clearTimeout(refs.analysisTimeout.current);
    updateCache();

    refs.saveTimeout.current.id = setTimeout(() => {
      if (selected || (analysisData[id] && analysisData[id].id)) {
        request.post(
          {
            url: `${baseApiUrl}/v1/admin/analysis/item`,
            headers: {
              Authorization: props.user.token,
            },

            form: {
              item_id: id,
              analysis_key: filterState.selectedAnalysis.id,
              weighting: filterState.showCollection
                ? null
                : analysisData[id].weighting,
              selected,
              id: analysisData[id].id,
              analysis_data: filterState.showCollection
                ? null
                : JSON.stringify(analysisData[id].analysis_data || {}),
            },
          },
          err => {
            if (err) {
              alert("Something went wrong...");
              return;
            }
            updateCache();
            clearTimeout(refs.analysisTimeout.current);
            refs.analysisTimeout.current = setTimeout(() => {
              getAnalysisItems();
            }, 5000);
          }
        );
      }
    }, 1000);
  };
  const onCloseAutoAnalysisSettings = e => {
    if (e) {
      e.preventDefault();
    }
    setAutoAnalysisSettings(false);
  };
  const onCloseAnalysis = (e, reason) => {
    e.preventDefault();
    if (reason && reason !== "backdropClick") {
      return false;
    }
    setAnalysisResults(null);
    setLoading(false);
  };
  const editAnalysis = e => {
    e.preventDefault();
    setEditingAnalysis(true);
  };
  const onChangeAnalysis = vals => {
    const value = vals[0];
    if (value === filterState.currentAnalysis) {
      return;
    }
    if (value === "new" || value === "") {
      setFilterState({
        ...filterState,
        currentAnalysis: value,
        selectedAnalysis: null,
      });
      addToStorage();
    } else {
      const collections = filterState.showCollection
        ? dataState.saved_tests
        : dataState.analysis;
      const selectedAnalysis = collections.find(a => a.id === value);

      if (selectedAnalysis) {
        setFilterState({
          ...filterState,
          currentAnalysis: value,
          selectedAnalysis,
        });
        setAnalysisData(null);
        addToStorage();
      }
    }
  };
  const deleteAnalysis = (id, analysisName) => {
    if (
      window.confirm(
        `Are you sure you want to delete this analysis ${analysisName} ?`
      )
    ) {
      request.delete(
        {
          url: `${baseApiUrl}/v1/admin/analysis`,
          headers: {
            Authorization: props.user.token,
          },

          form: {
            id: id,
          },
        },
        err => {
          if (err) {
            alert("Something went wrong...");
            return;
          }
          setTimeout(() => {
            updateCache();
            setFilterState({
              ...filterState,
              currentAnalysis: null,
              selectedAnalysis: null,
            });
            addToStorage();
            getAllFilters(true, true, true);
          }, 1000);
        }
      );
    }
  };
  const findAnalysisName = key => {
    if (!filterState.selectedAnalysis.analysis_props) {
      return "";
    }
    const item = filterState.selectedAnalysis.analysis_props.find(
      a => a.key === key
    );

    if (item) {
      return item.name;
    } else {
      return "???";
    }
  };
  const analyze = e => {
    setDoChangeLoad(true);
    const { sectorsToFilter } = filterState;
    request.get(
      {
        url: `${baseApiUrl}/v1/admin/analyze?analysis_key=${
          filterState.selectedAnalysis.id
        }&strong_filter=${refs.strongFilter.current}${
          sectorsToFilter ? `&sectors=${sectorsToFilter.join("*|*")}` : ""
        }`,
        headers: {
          Authorization: props.user.token,
        },
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        if (json.analysis) {
          setAnalysisResults(json.analysis);
          setDoChangeLoad(false);
        } else {
          setDoChangeLoad(false);
        }
      }
    );
  };
  const toggleShowOnlyAnalysis = show => e => {
    e.preventDefault();
    setFilterState({
      ...filterState,
      onlyAnalysis: show,
    });
    addToStorage();
  };
  const changeMode = mode => e => {
    e.preventDefault();
    setMode(mode);
    setPublishIds([]);
  };
  const toggleCopyCollection = () => e => {
    e.preventDefault();
    setShowCopyCollection(!showCopyCollection);
  };
  const toggleAutoAnalysis = e => {
    e.preventDefault();
    const newVal = autoAnalysis ? false : true;
    setAutoAnalysis(newVal);
    let newFilterState;
    if (newVal === true) {
      newFilterState = {
        ...filterState,
        autoAnalysis: newVal,
        status: "ended",
        statusToFilter: "ended",
      };
    } else {
      newFilterState = {
        ...filterState,
        autoAnalysis: newVal,
      };
    }
    setFilterState(newFilterState);
    addToStorage();
  };
  const onCloseAutoAnalysis = e => {
    e.preventDefault();
    setShowAutoAnalysis(false);
  };
  const changeStrongFilter = e => {
    setStrongFilter(!strongFilter);
    refs.strongFilter.current = !strongFilter;
    analyze(null);
  };
  const onChangeCustomer = e => {
    const customer = e.currentTarget.value;
    setTimeout(() => {
      refs.publish_customer.current = customer;
    }, 0);
  };
  const publishToCustomer = e => {
    e.preventDefault();
    setPublishing(true);
    if (!refs.publish_customer.current) {
      alert("Pick a customer");
      setPublishing(false);
      return;
    }
    if (publishIds.length <= 0) {
      setPublishing(false);
      alert("Pick some tests");
      return;
    }
    setTimeout(() => {
      request.post(
        {
          url: `${baseApiUrl}/v1/admin/publish`,
          headers: {
            Authorization: props.user.token,
          },

          form: {
            ids: publishIds.join(","),
            report: refs.publish_customer.current,
          },
        },
        err => {
          if (err) {
            alert("Something went wrong...");
            return;
          }
          setPublishing(false);
        }
      );
    }, 0);
  };
  const copyShareUrl = url => {
    const shareUrl =
      url || generateShareURL(window.location.href, props.match.params.company);
    copyTextToClipboard(shareUrl)
      .then(() => {
        setUrlCopied(true);
        setTimeout(() => {
          setUrlCopied(false);
        }, 1500);
      })
      .catch(err => {
        setUrlCopied(false);
      });
  };

  const selectAll = () => e => {
    e.preventDefault();
    const updatedPublisIds = [];
    const data = tests["All Tests"] ? tests["All Tests"].splits : [];
    data.forEach(test => {
      updatedPublisIds.push(test.id);
    });
    setPublishIds(updatedPublisIds);
  };
  const clearAllSelected = () => e => {
    e.preventDefault();
    setPublishIds([]);
  };
  const closeRegModal = e => {
    e.preventDefault();
    filterState.from = false;
    setFilterState(filterState);
    props.history.replace(`/home/${props.match.params.company}/report`);
  };
  const onChangeShowCollection = e => {
    const showCollection = e.target.checked;
    setFilterState({
      ...filterState,
      showCollection,
      currentAnalysis: null,
      selectedAnalysis: null,
      onlyAnalysis: null,
    });
    addToStorage();
  };
  const onChangeSelectedBetScore = (id, betScore, property) => e => {
    const isSelected = e.target.checked;

    setSavingSelectedBetScore(true);
    setSavingProperty(property);
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/analysis/save_bet_score`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          analysis_key: id,
          selected_property: property,
          isSelected,
        },
      },
      (err, response, body) => {
        if (err) {
          alert("Something went wrong...");
          return;
        } else {
          const json = JSON.parse(body);
          setFilterState({
            ...refs.filterState.current,
            selectedAnalysis: json.result,
          });
        }
        setSavingSelectedBetScore(false);
      }
    );
  };
  useLayoutEffect(() => {
    if (props.user && props.user.customer.semOnly) {
      props.history.replace(`/home/${props.match.params.company}/sem`);
      return;
    }
    if (
      props.match.params.id === "modules" ||
      props.match.params.company === "new"
    ) {
      return;
    }
    getAllFilters(false);
    getFilterdData();
    return () => {
      refs.qs.current = null;
      refs.filterDataDirty.current = true;
      refs.filterData.current = null;
      refs.testDisplayCount.current = 0;
    };
  }, []);
  useLayoutEffect(() => {
    componentLoaded.current = true;
    if (!autoAnalysis) {
      window.addEventListener("scroll", handleScroll, false);
    }
    const data = localStorage.getItem(SHOW_ADMIN_BOX_KEY);
    if (data && data === "1") {
      refs.showAdminBox.current = true;
    } else {
      refs.showAdminBox.current = false;
    }
    return () => {
      componentLoaded.current = false;
      clearTimeout(refs.scrollTO.current);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  let filterTimeout;
  useEffect(() => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      addToStorage(true);
    }, 100);
  }, [columnFilters]);
  useEffect(() => {
    if (new_analysis) {
      getAllFilters(true, true, true);
      addToStorage();
      setNewAnalysis(false);
    }
  }, [new_analysis]);
  useEffect(() => {
    if (filterState.selectedAnalysis) {
      getAnalysisItems(true);
    }
  }, [filterState.selectedAnalysis]);
  useEffect(() => {
    setShowFilters(refs.showFilterBar.current);
  }, [refs.showFilterBar.current]);
  useEffect(() => {
    //console.log(testLoadingStage, `this is at use effect`);
    if (testLoadingStage === 1) {
      clearTimeout(refs.testLoadingTimeout.current);

      refs.testLoadingTimeout.current = setTimeout(() => {
        //console.log("setting testloading state to 2");
        setTestLoadingStage(2);
      }, 500);
    }
  }, [testLoadingStage]);
  useLayoutEffect(() => {
    if (!currentLocation.current) {
      currentLocation.current = location;
      return;
    }
    if (
      currentLocation.current.search !== location.search &&
      currentLocation.current.pathname !== "/home/admin/screenshot"
    ) {
      refs.clearQS.current = true;
      getCachedQS(true);
      let urlArr = makeUrlParams();
      let currentURLParams = urlArr.length > 0 ? `?${urlArr.join("&")}` : "";
      setUrlParams(currentURLParams);

      setFilterState({
        ...refs.filterState.current,
        url: getUrlQueryString(),
        platform: getPlatformQueryString(),
        competitors: getCompetitorsQueryString(),
        compsToFilter: getCompanyQueryString(),
        challengesToFilter: getChallengeQueryString(),
        tagsToFilter: getTagQueryString().tagsToFilter,
        baseTagsToFilter: getBaseTagQueryString(),
        originalTagsToFilter: getTagQueryString().originalTagsToFilter,
        collectionsToFilter: props.match.params.tid
          ? [props.match.params.tid]
          : getCollectionQueryString(),
        typesToFilter: getPageTypeQueryString(),
        statusToFilter: getStatusQueryString(),
        sectorsToFilter: getSectorQueryString(),
        recent: getRecentQueryString(),
        great: getGreatQueryString(),
        sortByDate: getSortQueryString(),
        onlyAnalysis: getOnlyAnalysisQueryString(),
        loadNewTests: getLoadNewTestsQueryString(),
        currentAnalysis: getAnalysisQueryString(),
        start_date: getDatesQueryString().start_date,
        end_date: getDatesQueryString().end_date,
        publish_start_date: getDatesQueryString().publish_start_date,
        publish_end_date: getDatesQueryString().publish_end_date,
        noOfTags: getNoOfTagsQueryString(),
        whoWon: getWhoWonQueryString(),
        watch_test: getWatchTestQueryString(),
        country: getCountryQueryString(),
        locations: getLocationsQueryString(),
      });
      if (!refs.skipData.current) {
        clearTimeout(refs.gdTimeout.current);
        refs.gdTimeout.current = setTimeout(() => {
          getFilterdData(true);
          getAllFilters(false);
        }, 10);
      } else {
        refs.skipData.current = false;
      }
    }
    currentLocation.current = location;
    // if (urlParams !== window.location.search) {
    // }
  }, [location]);
  const isEmpty =
    props.match.params.id === "modules" || props.match.params.company === "new";
  if (isEmpty) {
    return <Empty />;
  }

  const formatNoOfTags = value => {
    let [min, max] = value ? value.split("-") : [null, null];
    let formattedValue = "";
    if (min && max && max == min) {
      return max;
    }
    if (min && max && max == min && min == "6") {
      return "6+";
    }
    if (min && !max) {
      return `min ${min}`;
    }

    return `${min}-${max == "6" ? "6+" : max}`;
  };
  const width = 600;
  const height = 480;
  const data = tests["All Tests"] ? tests["All Tests"].splits : null;
  const displayFilters = refs.filterState.current.filters.filter(f => !f.hide);
  const sortOptions = SORT_OPTIONS.filter(sortOption =>
    props.user.company !== "admin" ? !sortOption.onlyAdmin : true
  ).map(item => ({
    ...item,
    disabled:
      filterState.tagsToFilter.length == 0 && item.value === "relevance"
        ? true
        : false,
  }));
  let defaultSortOptions = {};
  if (filterState.tagsToFilter.length > 0) {
    if (filterState.sortByDate) {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === filterState.sortByDate
      );
    } else {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === "relevance"
      );
    }
  } else {
    if (
      (filterState.sortByDate && filterState.sortByDate === "relevance") ||
      !filterState.sortByDate
    ) {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === "ascending"
      );
    } else {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === filterState.sortByDate
      );
    }
  }
  defaultSortOptions.disabled = false;
  if (displayFilters.length > 0 && !showFilters) {
    setShowFilters(true);
  }
  const unlockedTests =
    customer && customer.freeTrial && customer.unlockedTests
      ? customer.unlockedTests.filter(
          unlockedTest => unlockedTest.unlockedBy !== "admin"
        ).length
      : null;

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("tag", {
      cell: info => info.getValue(),
      header: () => <span>Tag</span>,
      footer: info => info.column.id,
      enableMultiSort: true,
      filterFn: "includesString",
    }),
    columnHelper.accessor(row => row.baseTag, {
      id: "baseTag",
      cell: info => <i>{info.getValue()}</i>,
      header: () => <span>Base Tag</span>,
      footer: info => info.column.id,
      enableMultiSort: true,
      filterFn: "includesString",
    }),
    columnHelper.accessor("count", {
      header: () => <span># Tests</span>,
      cell: info => info.getValue(),
      footer: info => info.column.id,
      enableMultiSort: true,
      filterFn: "inNumberRange",
    }),
    columnHelper.accessor("sector", {
      header: () => <span>Sector</span>,
      cell: info => info.getValue(),
      footer: info => info.column.id,
      enableMultiSort: true,
      filterFn: "includesString",
    }),
    columnHelper.accessor("page-type", {
      header: () => <span>Page type</span>,
      cell: info => info.getValue(),
      footer: info => info.column.id,
      enableMultiSort: true,
      filterFn: "includesString",
    }),
    columnHelper.accessor("betScore", {
      header: () => <span>Bet Score</span>,
      cell: info => info.getValue(),
      footer: info => info.column.id,
      enableMultiSort: true,
      filterFn: "inNumberRange",
    }),
  ];
  const percents = [19, 23, 9, 9, 25, 15];

  const table = useReactTable({
    data: tagsOfInterest,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnFilters,
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 1000,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const onAutoanalysisSave = (row, aux) => e => {
    const data = row.original;
    e.preventDefault();
    data.id = `${data.slug.replace(/[^\w]/gi, "_")}-${data.total_count}-${
      data.count
    }`;
    data.search_url = window.location.href;
    setLoadingAutoData(row.id);
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/autoanalysis/save`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          id: data.id,
          data: JSON.stringify(data),
        },
      },
      (err, resp) => {
        setLoadingAutoData(null);
        if (err || (resp && resp.statusCode >= 400)) {
          alert("Something went wrong...");
          return;
        }
        const data = JSON.parse(resp.body);

        const domain =
          window.location.port && window.location.port > 500
            ? `${window.location.protocol}//${window.location.domain}:${window.location.port}`
            : `${window.location.protocol}//${window.location.domain}`;
        const handler = window.open(
          `/home/admin/report?autoanalysis_id=${data.id}`,
          "_blank"
        );
      }
    );
  };
  const advancedAnalyze = e => {
    e.preventDefault();
    request.post(
      {
        url: `${baseApiUrl}/v1/analysis/setting/cache`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          setting: JSON.stringify(advancedAnalysis),
        },
      },
      async (err, data) => {
        if (err) {
          alert("Something went wrong...");
        }
        const json = JSON.parse(data.body);
        if (json && json.slug) {
          setTestsLoading(true);
          setFilterState({
            ...refs.filterState.current,
            advancedAnalysisId: json.slug,
          });
          addToStorage();
          setShowAdvancedAnalysis(false);
        }
      }
    );
  };
  const advancedAnalyzeById = id => e => {
    e.preventDefault();
    const data = advancedAnalyses.find(a => a.id === id);
    request.post(
      {
        url: `${baseApiUrl}/v1/analysis/setting/cache`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          setting: JSON.stringify(data),
        },
      },
      async (err, data) => {
        if (err) {
          alert("Something went wrong...");
        }
        const json = JSON.parse(data.body);
        if (json && json.slug) {
          setFilterState({
            ...refs.filterState.current,
            advancedAnalysisId: json.slug,
          });
          addToStorage();
          setShowAdvancedAnalysis(false);
          setTestsLoading(true);
        }
      }
    );
  };
  const saveAdvancedAnalyze = e => {
    e.preventDefault();
    setLoadingAdvancedAnalyze(true);
    request.post(
      {
        url: `${baseApiUrl}/v1/analysis/setting`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          setting: JSON.stringify(advancedAnalysis),
          advanced: true,
        },
      },
      async (err, res, data) => {
        if (err) {
          alert("Something went wrong...");
        }
        const json = JSON.parse(data);
        setLoadingAdvancedAnalyze(false);
        setAdvancedAnalyses(json.data.filter(a => a.type === "advanced"));
      }
    );
  };
  const collectionData = filterState.showCollection
    ? dataState.saved_tests
    : dataState.analysis;

  let search_url;
  if (autoanalysis_data && autoanalysis_data.search_url) {
    const qs = querystring.parse(autoanalysis_data.search_url.split("?")[1]);
    const sqs = [];
    Object.keys(qs).forEach(q => {
      if (q !== "status" && q !== "autoAnalysis" && q !== "sort") {
        sqs.push(`${q}=${qs[q]}`);
      }
    });
    search_url = sqs.join("&");
  }
  let analysisSetting;
  if (autoAnalysisSettings && editAutoAnalysis) {
    analysisSetting = autoAnalysisSettings.find(x => x.id === editAutoAnalysis);
    if (!analysisSetting && editAutoAnalysis === "new") {
      analysisSetting = { id: "new", type: "", name: "", setting: {} };
      autoAnalysisSettings.push(analysisSetting);
      setAutoAnalysisSettings(autoAnalysisSettings);
    }
  }
  return (
    <>
      <div
        style={props.match.params.ssid ? { overflow: "hidden!important" } : {}}
      >
        {!autoAnalysis && (
          <DashboardHeader
            customer={props.match.params.company}
            user={props.user}
            name={customer ? customer.name : null}
            customerData={customer}
            selected="report"
            reportId={props.match.params.id}
          />
        )}
        <div
          ref={refs.filterBarElement}
          className={cx({
            "sticky-ui-container header-shadow": true,
            "header-animate":
              displayFilters &&
              displayFilters.length > 0 &&
              (showFilters || refs.showFilterBar.current),
          })}
          style={autoAnalysis ? { position: "fixed", top: "0px" } : {}}
        >
          {filtersLoaded && data ? (
            <>
              <div className="document-spacing center flex tags-container">
                <OmniFilter
                  title_slug="omniFilter"
                  company={props.match.params.company}
                  token={props.user.token}
                  isAdmin={props.user.company === "admin"}
                  challenges={
                    dataState.challenges
                      ? dataState.challenges.map(c => ({
                          value: c.name,
                          label: c.name,
                          type: "challenges",
                          count: c.count,
                        }))
                      : null
                  }
                  companies={
                    dataState.companies
                      ? dataState.companies.map(c => ({
                          value: c.name,
                          label: c.name,
                          count: c.count,
                        }))
                      : null
                  }
                  tags={dataState.note_tags ? dataState.note_tags : null}
                  note_counts={
                    data["All Tests"] ? data["All Tests"].note_counts : {}
                  }
                  company_counts={
                    data["All Tests"] ? data["All Tests"].company_counts : {}
                  }
                  collections_counts={
                    data["All Tests"]
                      ? data["All Tests"].collections_counts
                      : {}
                  }
                  page_counts={
                    data["All Tests"] ? data["All Tests"].page_counts : {}
                  }
                  collections={
                    dataState.saved_tests
                      ? dataState.saved_tests.map(c => ({
                          label: c.name,
                          value: c.id,
                          count: c.count,
                        }))
                      : null
                  }
                  page_types={
                    dataState.types
                      ? dataState.types.map(c => ({
                          label: c.name,
                          value: c.name,
                          count: c.count,
                        }))
                      : null
                  }
                  sectors={
                    dataState.sectors
                      ? dataState.sectors.map(c => ({
                          label: c.display_name,
                          value: c.key,
                        }))
                      : null
                  }
                  synonyms={synonyms.default}
                  callback={filterCallback}
                  statusCallback={handleStatusFilter}
                  platformCallback={handlePlatformFilter}
                  defaultOptions={refs.filterState.current.filters}
                  isSticky={true}
                  noSort
                  showOne
                  fixAt={{
                    width: 1000,
                    right: "0px",
                  }}
                  count={count}
                  status={filterState.statusToFilter}
                  platform={filterState.platform}
                  isAdminPage={props.match.params.company === "admin"}
                />

                <div className="dib" style={{ paddingTop: "2px" }}>
                  <div
                    className="tag-filter-button solo-button"
                    onClick={toggleFilter}
                  >
                    <img
                      src={FilterIcon}
                      alt="filters"
                      className="icon"
                      height="12"
                    />
                  </div>
                </div>
              </div>
              {autoanalysis_data && (
                <div className="b--dww bt">
                  <div className="document-spacing center flex pa2 items-start relative f6">
                    <div
                      className={cx("filters flex", {
                        "show-all-filter": showAllFilter,
                      })}
                    >
                      {analysisData && (
                        <div className="b pr2">Auto-analysis</div>
                      )}
                      <div className="ph2 bl b--dww">
                        BetScore: {autoanalysis_data.betScore}
                        {autoanalysis_data.additionalBetscores && (
                          <>
                            /{autoanalysis_data.additionalBetscores[0].betScore}
                          </>
                        )}
                      </div>
                      <div className="bl b--dww ph2">
                        Wins: {autoanalysis_data.win_count}/
                        {autoanalysis_data.count}
                      </div>
                      {autoanalysis_data.missing_tests &&
                        autoanalysis_data.missing_tests.length > 0 && (
                          <div className="bl b--dww ph2">
                            {refs.filterState.current.loadNewTests ? (
                              <>{autoanalysis_data.missing_tests.length} New</>
                            ) : (
                              <a
                                href=" #"
                                className="b dark-green"
                                onClick={e => {
                                  e.preventDefault();
                                  setFilterState({
                                    ...refs.filterState.current,
                                    loadNewTests: true,
                                  });
                                  addToStorage();
                                }}
                              >
                                {autoanalysis_data.missing_tests.length} New
                              </a>
                            )}
                          </div>
                        )}

                      <div className="bl b--dww ph2">
                        Tag: {autoanalysis_data.tag}
                        {autoanalysis_data.additionalBetscores && (
                          <>/{autoanalysis_data.additionalBetscores[0].tag}</>
                        )}
                      </div>
                      <div className="bl b--dww ph2">
                        Base Tag: {autoanalysis_data.baseTag}
                      </div>
                      {autoanalysis_data["page-type"] ? (
                        <div className="bl b--dww ph2">
                          Page Type: {autoanalysis_data["page-type"]}
                        </div>
                      ) : (
                        <></>
                      )}
                      {autoanalysis_data.sector ? (
                        <div className="bl b--dww ph2">
                          Sector: {autoanalysis_data.sector}
                        </div>
                      ) : (
                        <></>
                      )}
                      {autoanalysis_data.search_url ? (
                        <div className="bl b--dww ph2 w-10">
                          <a
                            href={autoanalysis_data.search_url}
                            title={autoanalysis_data.search_url}
                            className="dib"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              textWrap: "nowrap",
                            }}
                          >
                            ?{search_url}
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {refs.filterState.current.loadNewTests &&
                        autoanalysis_data.missing_tests &&
                        autoanalysis_data.missing_tests.length > 0 && (
                          <div
                            className="bl b--dww ph2 w-10"
                            style={{ flexGrow: 1 }}
                          >
                            <a
                              href=" #"
                              className="f6 ui-button secondary tiny"
                              onClick={e => {
                                if (
                                  window.confirm(
                                    "You can't undo this, these test will disappear from this analysis forever."
                                  )
                                ) {
                                  setClearingTests(true);
                                  request.post(
                                    {
                                      headers: {
                                        "content-type":
                                          "application/x-www-form-urlencoded",
                                        Authorization: props.user.token,
                                      },
                                      url: `${baseApiUrl}/v1/analysis/clear_tests`,
                                      form: {
                                        id: filterState.selectedAnalysis.id,
                                      },
                                    },
                                    (error, response, data) => {
                                      setTimeout(() => {
                                        setClearingTests(false);
                                        window.location.reload();
                                      }, 1000);
                                    }
                                  );
                                }
                              }}
                            >
                              Clear un-added tests
                              {clearing_tests && (
                                <CircularProgress size={12} color="secondary" />
                              )}
                            </a>
                          </div>
                        )}
                      {!analysisData && (
                        <a
                          href=" #"
                          className="ui-button secondary tiny"
                          onClick={e => {
                            e.preventDefault();
                            if (creating_analysis) {
                              return false;
                            }
                            setCreatingAnalysis(true);
                            const all_tests = tests["All Tests"].splits.map(
                              test => ({
                                test_id: test.id,
                                analysis_data: test.screenshots.map(ss => ({
                                  name: ss.choose_variant
                                    ? ss.choose_variant.key
                                    : "",
                                  control: ss.control,
                                  winner: ss.champ,
                                })),
                                weighting: test.weight,
                              })
                            );
                            const formData = {
                              analysis_name: capitalize(autoanalysis_data.tag),
                              analysis_props: autoanalysis_data.variants,
                              selected_analysis: [],
                              notes: [],
                              selected_customer: null,
                              verified: false,
                              trr: undefined,
                              tests: all_tests,
                              autoanalysis_data,
                            };
                            request.post(
                              {
                                headers: {
                                  "content-type":
                                    "application/x-www-form-urlencoded",
                                  Authorization: props.user.token,
                                },
                                url: `${baseApiUrl}/v1/admin/analysis`,
                                form: {
                                  data: JSON.stringify(formData),
                                },
                              },
                              (error, response, data) => {
                                const json = JSON.parse(data);
                                setTimeout(() => {
                                  setCreatingAnalysis(false);
                                  window.location.replace(
                                    `/home/admin/report?analysis=${json.success}&onlyAnalysis=true`
                                  );
                                }, 5000);
                              }
                            );
                          }}
                        >
                          <span className="mr1">Create Analysis</span>
                          {creating_analysis && (
                            <CircularProgress size={12} color="secondary" />
                          )}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {displayFilters && displayFilters.length > 0 && (
                <div className="filter-tags">
                  <div className="document-spacing center flex pl2 pr2 items-start">
                    <div
                      className={cx("filters flex w-80", {
                        "show-all-filter": showAllFilter,
                      })}
                    >
                      {displayFilters
                        .slice(0, showAllFilter ? undefined : 6)
                        .map(f => {
                          let icon;
                          if (f.type === "sort") return;
                          if (f.type === "companies") {
                            icon = CompanyIcon;
                          } else if (
                            f.type === "collections" ||
                            f.type === "challenges"
                          ) {
                            icon = CollectionsIcon;
                          } else if (f.type === "tags") {
                            icon = WhatsTestedIcon;
                          } else if (f.type === "page_types") {
                            icon = PageIcon;
                          } else if (f.type === "url") {
                            icon = LinkIcon;
                          }
                          let text;
                          return (
                            <div
                              className={cx("f-tag", {
                                "f-tag-negative-bg":
                                  f.negative &&
                                  props.user.company === "admin" &&
                                  [
                                    "collections",
                                    "challenges",
                                    "tags",
                                    "companies",
                                    "sectors",
                                    "page_types",
                                    "country",
                                    "location",
                                  ].includes(f.type),
                                "f-tag-expanded": showAllFilter,
                                "f-tag-small": [
                                  "platform",
                                  "status",
                                  "noOfTags",
                                  "sort",
                                  "whoWon",
                                  "country",
                                  "location",
                                ].includes(f.type),
                              })}
                              key={`${f.type}-${f.id ? f.id : f.value}`}
                            >
                              <span className="f-tag-x" onClick={removeTag(f)}>
                                <img src={X} alt="remove" />
                              </span>
                              <span
                                className="f-tag-text"
                                title={capitalize(f.name ? f.name : f.label)}
                              >
                                {f.type === "start_date" && (
                                  <span className="f-status sb">From: </span>
                                )}
                                {f.type === "end_date" && (
                                  <span className="f-status sb">To: </span>
                                )}
                                {f.type === "publish_start_date" && (
                                  <span className="f-status sb">
                                    Published From:{" "}
                                  </span>
                                )}
                                {f.type === "publish_end_date" && (
                                  <span className="f-status sb">
                                    Published To:{" "}
                                  </span>
                                )}
                                {f.type === "status" && (
                                  <span className="f-status sb">
                                    {capitalize(f.name ? f.name : f.label)}
                                  </span>
                                )}
                                {f.type === "whoWon" && (
                                  <span className="f-status sb">
                                    {f.value === "no_winner"
                                      ? capitalize("no winner")
                                      : capitalize(f.value) + ` Won`}
                                  </span>
                                )}
                                {f.type === "country" && (
                                  <span className="f-status sb">
                                    {capitalize(
                                      COUNTRIES.find(
                                        country => country.value === f.value
                                      ).label
                                    )}
                                  </span>
                                )}
                                {f.type === "location" && (
                                  <span className="f-status sb">
                                    {capitalize(
                                      LOCATIONS.find(
                                        location => location.value === f.value
                                      ).label
                                    )}
                                  </span>
                                )}
                                {f.type === "noOfTags" && (
                                  <>
                                    {capitalize(
                                      `${formatNoOfTags(
                                        f.value
                                      )} variables tested`
                                    )}
                                  </>
                                )}
                                {f.type === "url" && (
                                  <>{f.value.split("://")[1]}</>
                                )}
                                {f.type !== "platform" &&
                                  f.type !== "status" &&
                                  f.type !== "noOfTags" &&
                                  f.type !== "sort" &&
                                  f.type !== "whoWon" &&
                                  f.type !== "url" &&
                                  f.type !== "location" &&
                                  f.type !== "country" && (
                                    <>
                                      {capitalize(f.name ? f.name : f.label)}{" "}
                                    </>
                                  )}
                                {f.type === "platform" &&
                                  f.value === "desktop" && (
                                    <img
                                      src={DesktopIcon}
                                      className="f-tag-icon"
                                      alt={f.type}
                                      style={{
                                        marginTop: "-2px",
                                        marginLeft: "2px",
                                      }}
                                    />
                                  )}

                                {f.type === "platform" &&
                                  f.value === "mobile" && (
                                    <img
                                      src={MobileIcon}
                                      className="f-tag-icon"
                                      style={{
                                        marginTop: "-2px",
                                        marginLeft: "2px",
                                      }}
                                      alt={f.type}
                                    />
                                  )}
                              </span>
                              {f.type === "recent" && (
                                <img
                                  src={TimeIcon}
                                  className="f-tag-icon"
                                  style={{
                                    marginLeft: "8px",
                                  }}
                                  alt={f.type}
                                />
                              )}
                              {icon && (
                                <img
                                  src={icon}
                                  className="f-tag-icon"
                                  alt={f.type}
                                />
                              )}
                              {f.displayCount && (
                                <span className="ml2 count-circle">
                                  {f.displayCount}
                                </span>
                              )}
                              {props.user.company === "admin" &&
                                [
                                  "collections",
                                  "challenges",
                                  "tags",
                                  "companies",
                                  "sectors",
                                  "page_types",
                                  "country",
                                  "location",
                                ].includes(f.type) && (
                                  <span
                                    className="f-tag-negative"
                                    onClick={toggleSearchFilter(f)}
                                  >
                                    <img
                                      src={
                                        f.negative || false
                                          ? AddIcon
                                          : MinusIcon
                                      }
                                      alt="remove"
                                      className="f-tag-icon"
                                      title={
                                        f.negative
                                          ? "Include in search"
                                          : "Exclude from search"
                                      }
                                    />
                                  </span>
                                )}
                            </div>
                          );
                        })}

                      {displayFilters.length > 6 &&
                        (props.user.company === "admin" ? (
                          <div
                            className="mt0 pt0 f-tag f-tag-more w-10 link underline hover-orange pointer nowrap"
                            onClick={e => {
                              setShowAllFilter(!showAllFilter);
                            }}
                          >
                            {showAllFilter
                              ? `Show less`
                              : `+${displayFilters.length - 6} more`}
                          </div>
                        ) : (
                          <div className="f-tag f-tag-more w-10 nowrap">
                            +{displayFilters.length - 6} more
                          </div>
                        ))}
                      {displayFilters.length > 0 &&
                        props.user.company === "admin" && (
                          <div
                            className="f-tag f-tag-more w-10 link underline hover-orange pointer nowrap"
                            onClick={clearAllFilters}
                          >
                            clear all
                          </div>
                        )}
                    </div>

                    <div
                      className="f5 flex items-center sort w-10"
                      style={{ paddingTop: "5px" }}
                    >
                      <TagPicker
                        options={sortOptions}
                        callback={changeDateSort}
                        defaultOptions={defaultSortOptions}
                        title=""
                        inline
                        absolute
                        disabledEffect
                        title_slug="sortbyDate"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="document-spacing center flex tags-container">
              <FakeOmniFilter
                synonyms={synonyms.default}
                isSticky={true}
                showOne
                fixAt={{
                  width: 1000,
                  right: "0px",
                }}
                isAdminPage={props.match.params.company === "admin"}
                title_slug="fakeOmniFilter"
              />
              <div className="dib" style={{ paddingTop: "2px" }}>
                <div className="tag-filter-button solo-button tag-filter-disabled">
                  <img
                    src={FilterIcon}
                    alt="filters"
                    className="icon"
                    height="12"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showFilterSidebar && (
          <NewFilters
            closeCallback={toggleFilter}
            callback={handleFilterContent}
            platformCallback={changePlatform}
            dateCallback={changeDateSort}
            dateValue={refs.filterState.current.sortByDate}
            filterCount={
              (refs.filterState.current.end_date ||
              refs.filterState.current.start_date
                ? 1
                : 0) +
              (refs.filterState.current.publish_end_date ||
              refs.filterState.current.publish_start_date
                ? 1
                : 0)
            }
            platform={refs.filterState.current.platform}
            isAdmin={props.user.company === "admin"}
            latest={refs.filterState.current.latest}
            end_date={refs.filterState.current.end_date}
            start_date={refs.filterState.current.start_date}
            publish_end_date={refs.filterState.current.publish_end_date}
            publish_start_date={refs.filterState.current.publish_start_date}
            noOfTagsCallback={changeNoOfTags}
            noOfTagsValue={refs.filterState.current.noOfTags}
            whoWonCallback={changeWhoWon}
            whoWon={refs.filterState.current.whoWon}
            countryChangeCallback={changeCountry}
            locationChangeCallback={changeLocation}
            locations={refs.filterState.current.locations}
            country={refs.filterState.current.country}
          />
        )}
        {!loading &&
          data &&
          Object.keys(data).length === 0 &&
          !autoAnalysis && (
            <div className="document-spacing center">
              <div
                className="b tc"
                style={{ paddingTop: "50px", paddingBottom: "36px" }}
              >
                Nothing found, try removing some filters.
              </div>
              <MovieFoneInline
                user={props.user}
                tagsToFilter={filterState.tagsToFilter}
                compsToFilter={filterState.compsToFilter}
                company={props.match.params.company}
              />
            </div>
          )}
        {customer && customer.freeTrial && (
          <div
            style={{
              background: "rgba(255,255,255,0.95)",
              lineHeight: 0,
              height: "72px",
              width: "74px",
              boxShadow:
                "0 0.46875rem 1.1875rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03)",
              borderRadius: "999px",
              position: "fixed",
              bottom: "24px",
              right: "24px",
              zIndex: "100",
              fontWeight: "bold",
              alignContent: "center",
              alignItems: "center",
            }}
            className="dww-purple flex"
          >
            <div
              style={{
                margin: "0 auto",
                alignContent: "center",
                alignItems: "center",
              }}
              className="flex"
            >
              <img src={UnlockIcon} />
              <div
                style={{
                  paddingTop: "5px",
                  fontSize: "12px",
                  paddingRight: "4px",
                }}
              >
                {unlockedTests}/{customer.unlock_limit}
              </div>
            </div>
          </div>
        )}
        {props.match.params.company === "admin" && autoAnalysis && (
          <div
            className="bg-white"
            style={{
              width: `100vw`,
              paddingTop: "60px",
              //height: `${window.innerHeight - 240}px`,
              //overflow: "scroll",
              //bottom: "85px",
              //position: "absolute",
              //left: "0px",
              //zIndex: "1002",
            }}
          >
            {!showAdvancedAnalysis &&
            (!tagsOfInterest ||
              tagsOfInterest.length === 0 ||
              tests_loading) ? (
              <>
                {tests_loading && (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100vh",
                      textAlign: "center",
                      top: "0px",
                      background: "rgba(0,0,0,0.05)",
                      zIndex: "100000",
                      overflow: "hidden",
                    }}
                  >
                    <Loading />
                  </div>
                )}
                {!tests_loading && tagsOfInterest.length === 0 && (
                  <div>
                    <p className="b red pa3">
                      We didn't find enough matching tests to create an analysis
                      from.
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="w-100">
                <div
                  className="tr pa1 f7"
                  style={{
                    position: "fixed",
                    top: "156px",
                    right: "6px",
                  }}
                >
                  <a
                    className="pl1 pointer"
                    onClick={e => {
                      e.preventDefault();
                      setShowAdvancedAnalysis(false);
                      setAdvancedAnalysesLoading(true);
                      setShowAutoAnalysisSettings(true);
                      request.get(
                        {
                          url: `${baseApiUrl}/v1/analysis/setting`,
                          headers: {
                            Authorization: props.user.token,
                          },
                        },
                        (err, res, data) => {
                          const json = JSON.parse(data);
                          setAdvancedAnalysesLoading(false);
                          if (json && json.data) {
                            setAutoAnalysisSettings(
                              json.data
                                .filter(d => d.type !== "advanced")
                                .sort((a, b) => (a.name >= b.name ? -1 : 1))
                            );
                          }
                        }
                      );
                    }}
                  >
                    <img src={SettingsIcon} />
                  </a>
                </div>
                {showAutoAnalysisSettings && (
                  <div
                    style={{
                      overflow: "auto",
                    }}
                    className="w-100 bg-white relative "
                  >
                    <div className="pa3 bb b--black-05">
                      <h2>Auto-analysis Settings</h2>
                    </div>
                    <div className="w-100">
                      {advancedAnalysesLoading && (
                        <div className="tc dww-grey">Loading&hellip;</div>
                      )}
                      {autoAnalysisSettings && (
                        <div className="pa3 flex flex-wrap">
                          <div className="pb2 w-25">
                            <a
                              href=" #"
                              onClick={e => {
                                e.preventDefault();
                                setEditAutoAnalysis("new");
                              }}
                            >
                              Add new
                            </a>
                            <form onSubmit={onSaveAnalysisSetting("new")}>
                              {editAutoAnalysis === "new" && (
                                <div className="bt bb pa3 mt3 mb3 b--black-025">
                                  <div className="pb2 w-15">
                                    <label className="db pb1">
                                      Analysis Type
                                    </label>
                                    <select
                                      name="type"
                                      onChange={onEditAnalysisSetting("new")}
                                      defaultValue={analysisSetting.type}
                                      required
                                    >
                                      <option value="">
                                        -- Pick a type --
                                      </option>
                                      <option value="single">
                                        Tag-based (single)
                                      </option>
                                      <option value="location">
                                        Location-based (beta)
                                      </option>
                                      <option value="group">Grouped tag</option>
                                    </select>
                                  </div>
                                  <div className="pb2 w-15">
                                    <label className="db pb1">Name</label>
                                    <input
                                      name="name"
                                      type="text"
                                      required
                                      onChange={onEditAnalysisSetting("new")}
                                      defaultValue={analysisSetting.name}
                                    />
                                  </div>
                                  {(analysisSetting.type === "location" ||
                                    analysisSetting.type === "single") && (
                                    <div className="pb2">
                                      <label className="db pb1">Tag</label>
                                      <input
                                        name="tag"
                                        type="text"
                                        placeholder="Tag"
                                        required
                                        defaultValue={
                                          analysisSetting.setting.tag
                                        }
                                        onChange={onEditAnalysisSetting("new")}
                                      />
                                    </div>
                                  )}
                                  {analysisSetting.type === "group" && (
                                    <>
                                      <div className="pb2">
                                        <label className="db pb1">Tag</label>
                                        <input
                                          type="text"
                                          name="tag"
                                          required
                                          defaultValue={
                                            analysisSetting.setting.tag
                                          }
                                          onChange={onEditAnalysisSetting(
                                            "new"
                                          )}
                                        />
                                      </div>
                                      <div className="pb2">
                                        <label className="db pb1">
                                          Reverse Tag
                                        </label>
                                        <input
                                          type="text"
                                          name="reverse"
                                          required
                                          defaultValue={
                                            analysisSetting.setting.reverse
                                          }
                                          onChange={onEditAnalysisSetting(
                                            "new"
                                          )}
                                        />
                                      </div>
                                      <div className="pb2">
                                        <label className="db pb1">
                                          Group name
                                        </label>
                                        <input
                                          type="text"
                                          name="group_tag"
                                          required
                                          defaultValue={
                                            analysisSetting.setting.group_tag
                                          }
                                          onChange={onEditAnalysisSetting(
                                            "new"
                                          )}
                                        />
                                      </div>
                                      <div className="pb2">
                                        <label className="db pb1">
                                          Ignore Tag (optional)
                                        </label>
                                        <input
                                          type="text"
                                          name="ignore"
                                          defaultValue={
                                            analysisSetting.setting.ignore
                                          }
                                          onChange={onEditAnalysisSetting(
                                            "new"
                                          )}
                                        />
                                      </div>
                                    </>
                                  )}
                                  {editSaving ? (
                                    <button
                                      className="ui-button secondary"
                                      disabled
                                    >
                                      Saving&hellip;
                                    </button>
                                  ) : (
                                    <input
                                      className="ui-button secondary"
                                      type="submit"
                                      value="Save"
                                    />
                                  )}
                                  <a
                                    href=" #"
                                    className="ml2"
                                    onClick={e => {
                                      e.preventDefault();
                                      setEditAutoAnalysis(null);
                                    }}
                                  >
                                    Close
                                  </a>
                                </div>
                              )}
                            </form>
                          </div>
                          {autoAnalysisSettings.map(analysis => {
                            return (
                              <div className="pb2 w-25">
                                {editAutoAnalysis === analysis.id &&
                                analysis.id !== "new" ? (
                                  <div className="bt bb pa3 mt3 mb3 b--black-025">
                                    <div className="pb2 w-15">
                                      <label className="db pb1">Name</label>
                                      <input
                                        name="name"
                                        type="text"
                                        defaultValue={capitalize(analysis.name)}
                                        onChange={onEditAnalysisSetting(
                                          analysis.id
                                        )}
                                      />
                                    </div>
                                    {(analysis.type === "location" ||
                                      analysis.type === "single") && (
                                      <div className="pb2">
                                        <label className="db pb1">Tag</label>
                                        <input
                                          name="tag"
                                          type="text"
                                          defaultValue={analysis.setting.tag}
                                          placeholder="Tag"
                                          onChange={onEditAnalysisSetting(
                                            analysis.id
                                          )}
                                        />
                                      </div>
                                    )}
                                    {analysis.type === "group" && (
                                      <>
                                        <div className="pb2">
                                          <label className="db pb1">Tag</label>
                                          <input
                                            type="text"
                                            name="tag"
                                            defaultValue={analysis.setting.tag}
                                            onChange={onEditAnalysisSetting(
                                              analysis.id
                                            )}
                                          />
                                        </div>
                                        <div className="pb2">
                                          <label className="db pb1">
                                            Reverse Tag
                                          </label>
                                          <input
                                            type="text"
                                            name="reverse"
                                            defaultValue={
                                              analysis.setting.reverse
                                            }
                                            onChange={onEditAnalysisSetting(
                                              analysis.id
                                            )}
                                          />
                                        </div>
                                        <div className="pb2">
                                          <label className="db pb1">
                                            Group name
                                          </label>
                                          <input
                                            type="text"
                                            name="group_tag"
                                            defaultValue={
                                              analysis.setting.group_tag
                                            }
                                            onChange={onEditAnalysisSetting(
                                              analysis.id
                                            )}
                                          />
                                        </div>
                                        <div className="pb2">
                                          <label className="db pb1">
                                            Ignore Tag (optional)
                                          </label>
                                          <input
                                            type="text"
                                            name="ignore"
                                            defaultValue={
                                              analysis.setting.ignore
                                            }
                                            onChange={onEditAnalysisSetting(
                                              analysis.id
                                            )}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {editSaving ? (
                                      <button
                                        className="ui-button secondary"
                                        disabled
                                      >
                                        Saving&hellip;
                                      </button>
                                    ) : (
                                      <button
                                        className="ui-button secondary"
                                        onClick={onSaveAnalysisSetting(
                                          analysis.id
                                        )}
                                      >
                                        Save
                                      </button>
                                    )}
                                    <a
                                      href=" #"
                                      className="ml2"
                                      onClick={e => {
                                        e.preventDefault();
                                        setEditAutoAnalysis(null);
                                      }}
                                    >
                                      Close
                                    </a>
                                  </div>
                                ) : (
                                  <>
                                    <img
                                      src={RED_X}
                                      className="pointer mr2"
                                      style={{ width: "12px" }}
                                      onClick={onDeleteAnalysisSetting(
                                        analysis.id
                                      )}
                                    />
                                    <img
                                      src={EditIcon}
                                      className="pointer mr2"
                                      sytyle={{ width: "12px" }}
                                      onClick={e => {
                                        e.preventDefault();
                                        setEditAutoAnalysis(analysis.id);
                                      }}
                                    />{" "}
                                    {capitalize(analysis.name)} &middot;{" "}
                                    <b>{analysis.type}</b>{" "}
                                  </>
                                )}
                                {/*
                                  &middot;{" "}
                                  <a
                                    href="#"
                                    onClick={advancedAnalyzeById(analysis.id)}
                                  >
                                    Run
                                  </a>
                                  */}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {showAdvancedAnalysis && (
                  <div
                    style={{
                      overflow: "auto",
                    }}
                    className="w-100 bg-white relative "
                  >
                    <div className="pa3 bb b--black-05">
                      <h2>Advanced Analysis</h2>
                    </div>
                    <div className="flex pa3">
                      <div className="w-50 pr3">
                        {newAdvancedAnalysis ? (
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              if (e.nativeEvent.submitter.value === "save") {
                                saveAdvancedAnalyze(e);
                              } else if (
                                e.nativeEvent.submitter.value === "analyze"
                              ) {
                                advancedAnalyze(e);
                              }
                            }}
                          >
                            <div className="pb3">
                              <input
                                type="text"
                                placeholder="Analysis Name"
                                name="name"
                                defaultValue={advancedAnalysis.name}
                                required
                                onChange={onChangeAdvancedAnalysisName}
                                className="f4 w-50 pa2 br2"
                                style={{ borderWidth: "1px" }}
                              />
                            </div>
                            <div
                              className="pb4 pl3"
                              style={{ paddingBottom: "80px" }}
                            >
                              {advancedAnalysis.variants.length > 0 && (
                                <>
                                  {advancedAnalysis.variants.map((v, i) => (
                                    <div>
                                      <input
                                        type="text"
                                        name="name"
                                        placeholder="Property Name"
                                        required
                                        defaultValue={v.name}
                                        onChange={onChangeAdvancedAnalysisProperty(
                                          i
                                        )}
                                      />
                                      <a
                                        href="#"
                                        className="red b f6 ml2"
                                        onClick={onRemoveAdvancedAnalysisProperty(
                                          i
                                        )}
                                      >
                                        x
                                      </a>
                                      {v.conditions.map((y, z) => (
                                        <div className="pa2">
                                          <select
                                            name="boolean_type"
                                            defaultValue={y.boolean_type}
                                            required
                                            onChange={onChangeAdvancedAnalysisCondition(
                                              i,
                                              z
                                            )}
                                            className="mr2"
                                          >
                                            <option value="">--</option>
                                            <option value="must">
                                              Must (and)
                                            </option>
                                            <option value="should">
                                              Should (or)
                                            </option>
                                            <option value="must_not">
                                              Not
                                            </option>
                                          </select>
                                          <select
                                            name="search_type"
                                            defaultValue={y.search_type}
                                            required
                                            onChange={onChangeAdvancedAnalysisCondition(
                                              i,
                                              z
                                            )}
                                            className="mr2"
                                          >
                                            <option value="">--</option>
                                            <option value="exact">
                                              Match Tag Exactly
                                            </option>
                                            <option value="fuzzy">
                                              Contain Tag Text
                                            </option>
                                            <option value="copy">
                                              Contain End Tag Text
                                            </option>
                                          </select>
                                          <input
                                            type="text"
                                            name="key"
                                            placeholder="Tag or Text"
                                            required
                                            defaultValue={y.key}
                                            onChange={onChangeAdvancedAnalysisCondition(
                                              i,
                                              z
                                            )}
                                          />
                                          <a
                                            href="#"
                                            className="red b f6 ml2"
                                            onClick={onRemoveAdvancedAnalysisCondition(
                                              i,
                                              z
                                            )}
                                          >
                                            x
                                          </a>
                                        </div>
                                      ))}
                                      <div className="pl2 mb3 pb2">
                                        <a
                                          href="#"
                                          className="ui-button secondary small"
                                          onClick={addAdvancedAnalysisCondition(
                                            i
                                          )}
                                        >
                                          Add a condition
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                              <a
                                href="#"
                                className="ui-button secondary small"
                                onClick={addAdvancedAnalysisProperty}
                              >
                                Add a property
                              </a>
                            </div>
                            <div
                              className="pa3 bg-white w-100 bt b--black-05 bb flex"
                              style={{ position: "fixed", bottom: "86px" }}
                            >
                              <div>
                                <button
                                  className="ui-button primary"
                                  type="submit"
                                  value="analyze"
                                >
                                  Analyze
                                </button>
                                {loadingAdvancedAnalyze ? (
                                  <button
                                    className="ui-button secondary ml3"
                                    disabled
                                  >
                                    Saving
                                  </button>
                                ) : (
                                  <button
                                    className="ui-button secondary ml3"
                                    type="submit"
                                    value="save"
                                  >
                                    Save
                                  </button>
                                )}
                                <a
                                  href=" #"
                                  className="ml2"
                                  onClick={e => {
                                    e.preventDefault();
                                    setShowAdvancedAnalysis(false);
                                  }}
                                >
                                  Close
                                </a>
                              </div>
                              {advancedAnalysis && advancedAnalysis.name && (
                                <div className="tr pr3" style={{ flexGrow: 1 }}>
                                  <button
                                    className="ui-button secondary"
                                    onClick={e => {
                                      e.preventDefault();

                                      setAdvancedAnalysis({
                                        name: "",
                                        slug: "",
                                        allVariants: [],
                                        variants: [],
                                      });
                                      setFilterState({
                                        ...refs.filterState.current,
                                        advancedAnalysisId: null,
                                      });
                                      addToStorage();
                                    }}
                                  >
                                    Clear Advanced Analysis
                                  </button>
                                </div>
                              )}
                            </div>
                          </form>
                        ) : (
                          <div className="pa3">
                            <button
                              className="ui-button primary"
                              onClick={e => {
                                e.preventDefault();
                                setNewAdvancedAnalysis(true);
                              }}
                            >
                              Add New
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="w-50">
                        {advancedAnalysesLoading && (
                          <div className="tc dww-grey">Loading&hellip;</div>
                        )}
                        {advancedAnalyses && (
                          <div className="pt3">
                            <div>
                              <h3>Saved Analyses</h3>
                            </div>
                            {advancedAnalyses.map(analysis => {
                              return (
                                <div>
                                  {analysis.name}{" "}
                                  <img
                                    src={EditIcon}
                                    className="pointer ml2"
                                    style={{ width: "16px" }}
                                    onClick={e => {
                                      e.preventDefault();
                                      setNewAdvancedAnalysis(true);
                                      setAdvancedAnalysis(
                                        transformAdvancedAnalysis(analysis)
                                      );
                                    }}
                                  />{" "}
                                  <img
                                    src={RED_X}
                                    className="pointer ml2"
                                    style={{ width: "12px" }}
                                    onClick={onDeleteAnalysisSetting(
                                      analysis.id,
                                      true
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!showAdvancedAnalysis && !showAutoAnalysisSettings && (
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      display: "block",
                    }}
                  >
                    <thead
                      style={{
                        display: "block",
                        position: "fixed",
                        top: "127px",
                        width: "99vw",
                        background: "rgba(0,0,0,0.8)",
                      }}
                      className="ph3"
                    >
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} style={{ display: "flex" }}>
                          {headerGroup.headers.map((header, i) => {
                            return (
                              <th
                                key={header.id}
                                colSpan={header.colSpan}
                                className="f7 white"
                                style={{
                                  width: `${percents[i]}%`,
                                  wordBreak: "break-all",
                                }}
                              >
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div className="pa1">
                                      <span
                                        onClick={e => {
                                          setTimeout(
                                            header.column.getToggleSortingHandler()(
                                              e
                                            ),
                                            10
                                          );
                                        }}
                                      >
                                        {
                                          {
                                            asc: (
                                              <img
                                                src={SortUp}
                                                width={14}
                                                className="pointer"
                                              />
                                            ),
                                            desc: (
                                              <img
                                                src={SortDown}
                                                width={14}
                                                className="pointer"
                                              />
                                            ),
                                          }[header.column.getIsSorted()]
                                        }
                                        {!header.column.getIsSorted() && (
                                          <img
                                            src={SortNone}
                                            className="mr1 pointer"
                                            height={10}
                                            style={{ opacity: "0.5" }}
                                          />
                                        )}
                                      </span>
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {header.column.getCanFilter() && (
                                        <img
                                          src={FilterIconWhite}
                                          width={13}
                                          className="ml1 pointer"
                                          onClick={() => {
                                            setSelectedFilter(
                                              selectedFilter === i ? null : i
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                    {header.column.getCanFilter() ? (
                                      <div
                                        style={{
                                          display:
                                            selectedFilter === i
                                              ? "block"
                                              : "none",
                                          position: "absolute",
                                          background: "#fff",
                                          padding: "10px",
                                        }}
                                      >
                                        <ColFilter
                                          column={header.column}
                                          table={table}
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      style={{
                        display: "block",
                        width: "99vw",
                        overflow: "auto",
                        marginTop: "30px",
                      }}
                      className="ph3"
                    >
                      {table.getRowModel().rows.map((row, z) => {
                        const qs = querystring.parse(window.location.search);
                        let baseTag, tag;
                        const tags = [];
                        row.getVisibleCells().map((cell, i) => {
                          const val = cell.getValue();
                          qs.autoAnalysis = null;
                          if (cell.column.id == "baseTag") {
                            baseTag = val;
                            tags.push(val);
                          }
                          if (cell.column.id === "count") {
                            qs.count = val;
                          }
                          if (cell.column.id === "tag") {
                            tag = val;
                            tags.push(val);
                          }
                          if (cell.column.id === "sector") {
                            if (val && val !== "") {
                              qs.sector = val;
                            }
                          }
                          if (cell.column.id === "page-type") {
                            if (val && val !== "") {
                              qs.page_type = val;
                            }
                          }
                        });
                        if (baseTag === "all") {
                          if (qs.tag) {
                            qs.tag = `${qs.tag}|~~${tag}`;
                          } else {
                            qs.tag = `~~${tag}`;
                          }
                        } else {
                          qs.baseTag = `${baseTag} ${tag}`;
                        }
                        qs.status = "ended";

                        const tagsToJoin = tags.filter(t => t !== "all");

                        const search_url = `/home/admin/report?tag=${encodeURIComponent(
                          row.original.search_tags
                            ? row.original.search_tags.join(" && ")
                            : tagsToJoin.join(" && ")
                        )}&status=ended&autoAnalysis=true`;

                        let analysisLink;

                        if (allAnalyses[row.original.slug]) {
                          analysisLink = `/home/admin/report?analysis=${
                            allAnalyses[row.original.slug]
                          }&onlyAnalysis=true`;
                        }

                        let urlArr = [];

                        Object.keys(qs).forEach(key => {
                          const val = qs[key];
                          if (val) {
                            urlArr.push(`${key}=${encodeURIComponent(val)}`);
                          }
                        });

                        let url = `?${urlArr.join("&")}`;
                        let hasAdditionalBetscores =
                          row.original.additionalBetscores &&
                          row.original.additionalBetscores.length > 0;

                        return (
                          <tr key={row.id} className="f6">
                            {row.getVisibleCells().map((cell, i) => (
                              <td
                                key={cell.id}
                                className={`pa1${
                                  z % 2 !== 0 ? " bg-black-05" : ""
                                }`}
                                style={
                                  cell.column.id !== "tag"
                                    ? {
                                        width: `${percents[i]}%`,
                                        wordBreak: "break-all",
                                      }
                                    : {
                                        width: `${percents[i]}%`,
                                      }
                                }
                              >
                                {cell.column.id === "count" ? (
                                  <>
                                    {loadingAutoData === row.id ? (
                                      <span className="f8">Saving&hellip;</span>
                                    ) : (
                                      <>
                                        <a
                                          href=" #"
                                          onClick={onAutoanalysisSave(
                                            row,
                                            false
                                          )}
                                          onAuxClick={onAutoanalysisSave(
                                            row,
                                            true
                                          )}
                                        >
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </a>
                                      </>
                                    )}{" "}
                                    &middot;{" "}
                                    <a
                                      href={search_url}
                                      title="Explore similar results"
                                    >
                                      <img src={SearchIcon} width={16} />
                                    </a>
                                    {analysisLink && (
                                      <>
                                        &middot;{" "}
                                        <a
                                          href={analysisLink}
                                          title="See Analysis"
                                          target="_blank"
                                        >
                                          <img src={AutoIcon} width={16} />
                                        </a>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                    {hasAdditionalBetscores &&
                                      cell.column.id === "betScore" && (
                                        <>
                                          <br />
                                          {
                                            row.original.additionalBetscores[0]
                                              .betScore
                                          }
                                        </>
                                      )}
                                    {hasAdditionalBetscores &&
                                      cell.column.id === "tag" && (
                                        <>
                                          <br />
                                          {
                                            row.original.additionalBetscores[0]
                                              .tag
                                          }
                                        </>
                                      )}
                                  </>
                                )}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        )}
        <div>
          {((tests_loading && testLoadingStage === 1) || doChangeLoad) &&
            !autoAnalysis && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100vh",
                  textAlign: "center",
                  background: "rgba(255,255,255,0.4)",
                  zIndex: "998",
                  overflow: "hidden",
                }}
              >
                <Loading />
              </div>
            )}
          {tests_loading && testLoadingStage === 2 && (
            <div>
              <div className="mt3">
                <FakeReportBox />
              </div>
              <div className="mt3">
                <FakeReportBox />
              </div>
              {accessDenied && <AccessDenied />}
            </div>
          )}
          {!tests_loading &&
            testLoadingStage === 0 &&
            !autoAnalysis &&
            data &&
            data.map((d, y) => {
              d.screenshots.sort((a, b) => (a.champ ? -1 : 1));
              d.savedTests = d.collections || [];
              const showNotes =
                d.screenshots &&
                Array.isArray(d.screenshots) &&
                d.screenshots.length > 0 &&
                d.screenshots[0].notes &&
                d.screenshots[0].notes.length > 0;
              if (showNotes && loadedSavedTests) {
                if (analysis_loading) {
                  return (
                    <div
                      key={`ri-${d.id}`}
                      style={{ width: "100%", height: "100%" }}
                    ></div>
                  );
                }
                return (
                  <ReportItemDisplayNew
                    d={d}
                    i={y}
                    showShare={customer && customer.hasAdmin}
                    freeTrial={customer && customer.freeTrial}
                    allUnlockedTests={
                      customer && customer.freeTrial && customer.unlockedTests
                    }
                    unlockedTests={unlockedTests}
                    unlock_limit={
                      customer && customer.freeTrial
                        ? customer.unlock_limit
                        : null
                    }
                    watchTestCallback={watchTestCallback}
                    userCompany={props.user.company}
                    urlCompany={props.match.params.company}
                    byModule={props.byModule}
                    key={`ri-${d.id}`}
                    token={props.user.token}
                    uid={props.user.uid}
                    user={props.user}
                    savedTests={
                      dataState.saved_tests && filtersLoaded
                        ? dataState.saved_tests
                        : null
                    }
                    analysis={dataState.analysis ? dataState.analysis : null}
                    urlParams={urlParams ? urlParams : []}
                    savedTestsCallback={refreshSavedTests}
                    getDataCallback={doFilters => {
                      updateCache();
                      setTimeout(() => {
                        //getFilterdData(false);
                        getFilterdData(true, true, true, null, true, true);
                        if (doFilters) {
                          getAllFilters();
                        }
                      }, 500);
                      setTimeout(() => {
                        updateCache();
                      }, 5000);
                    }}
                    getSearchData={async () => {}}
                    collectionId={props.match.params.tid}
                    isSavedTests={props.isSavedTests}
                    isAdmin={props.user.company === "admin"}
                    isAdminPage={props.match.params.company === "admin"}
                    onCBchange={updateCBs}
                    analysisMode={mode === "analysis"}
                    selectedAnalysis={
                      filterState.selectedAnalysis
                        ? filterState.selectedAnalysis
                        : null
                    }
                    collectionMode={
                      filterState.showCollection ? "collection" : "analysis"
                    }
                    updateData={updateAnalysis}
                    analysisData={
                      analysisData && analysisData[d.id]
                        ? analysisData[d.id]
                        : null
                    }
                    isChecked={publishIds.includes(d.id)}
                    showAdminBox={refs.showAdminBox.current}
                  />
                );
              } else {
                return <Empty key={`ri-${d.id}`}></Empty>;
              }
            })}
        </div>
        {props.match.params.company === "admin" && (
          <>
            {analysis_results && (
              <Modal open={true} onClose={onCloseAnalysis}>
                <div
                  className="box"
                  style={{
                    width: `90vw`,
                    height: `90vh`,
                    overflow: "scroll",
                    top: "3vh",
                    position: "absolute",
                    left: "3.5vw",
                  }}
                >
                  <div className="pa3 bb b--black-05">
                    <img
                      src={RED_X}
                      alt="close"
                      height={26}
                      onClick={onCloseAnalysis}
                      className=" pointer fr pa1 "
                    />

                    <h1>
                      Analysis for {filterState.selectedAnalysis.analysis_name}
                    </h1>
                    <label>
                      <input
                        type="checkbox"
                        onChange={changeStrongFilter}
                        checked={strongFilter}
                      />{" "}
                      Only use strong tests
                    </label>
                  </div>

                  {doChangeLoad ? (
                    <Loading />
                  ) : (
                    <div className="pa3">
                      {filterState.sectorsToFilter &&
                        filterState.sectorsToFilter.length !== 0 && (
                          <div className="mb3">
                            <span className="b">Filtering by sectors: </span>
                            {filterState.sectorsToFilter
                              .join(", ")
                              .replace(/\|\|/gi, ">>")}
                          </div>
                        )}
                      {Object.keys(analysis_results).map(key => {
                        const [selectedKey, selectedProperty] =
                          filterState.selectedAnalysis &&
                          filterState.selectedAnalysis.selected_property
                            ? filterState.selectedAnalysis.selected_property.split(
                                "||"
                              )
                            : [];
                        const aKeys = Object.keys(analysis_results[key]);

                        const hasEnough = Object.values(
                          analysis_results[key]
                        ).find(item => item.test_count >= 1);

                        if (!hasEnough) {
                          return <></>;
                        }

                        return (
                          <div className="pb4 flex flex-column">
                            <div className="flex flex-row">
                              <div className="w-60 pa2">
                                <h4>
                                  {key === "all" && <>Overall Score </>}
                                  {key !== "all" && <>{key}</>}
                                </h4>
                              </div>
                              {key === "all" && (
                                <div className="w-40 pa2">
                                  Finalize Bet Score
                                </div>
                              )}
                            </div>
                            {aKeys.map(skey => {
                              const item = analysis_results[key][skey];

                              return (
                                <div className="flex flex-row">
                                  <div className="w-60 pa2">
                                    <div className="pt2">
                                      <div className="pt2">
                                        {findAnalysisName(skey)} &middot; (
                                        {item.percentage}%, {item.win_count}/
                                        {item.test_count} tests won &middot;
                                        Control won{" "}
                                        {item.control_won_percentage}
                                        %)
                                      </div>
                                      Tests took {item.total_days} total days to
                                      run &middot; Median: {item.avg_days} days
                                      &middot; Shortest: {item.min_days} days
                                      &middot; Longest: {item.max_days} days
                                      {item.pval && (
                                        <div>
                                          Confidence:
                                          <span
                                            className={cx({
                                              b: true,
                                              green: item.pval >= 95,
                                              yellow:
                                                item.pval < 95 &&
                                                item.pval >= 90,
                                              red: item.pval < 90,
                                            })}
                                          >
                                            {" "}
                                            {item.pval}%
                                          </span>
                                        </div>
                                      )}
                                      {item.betScore && (
                                        <div>
                                          Bet Score:
                                          <span
                                            className={cx({
                                              b: true,
                                              "dark-green": item.betScore >= 80,
                                              green:
                                                item.betScore >= 60 &&
                                                item.betScore < 80,
                                              gray:
                                                item.betScore >= 40 &&
                                                item.betScore < 60,
                                              yellow:
                                                item.betScore >= 20 &&
                                                item.betScore < 40,
                                              red: item.betScore < 20,
                                            })}
                                          >
                                            {" "}
                                            {item.betScore !== "None"
                                              ? `${item.betScore}% - ${item.betScoreName}`
                                              : ""}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-40 pa2">
                                    <input
                                      type="radio"
                                      disabled={savingSelectedBetScore}
                                      checked={
                                        selectedKey &&
                                        selectedProperty &&
                                        selectedKey === key &&
                                        selectedProperty === skey
                                      }
                                      onChange={onChangeSelectedBetScore(
                                        filterState.selectedAnalysis.id,
                                        `${item.betScore}||${item.betScoreName}`,
                                        `${key}||${skey}`
                                      )}
                                    ></input>

                                    {savingSelectedBetScore &&
                                      savingProperty &&
                                      savingProperty === `${key}||${skey}` && (
                                        <span className="dark-green ph3">
                                          Saving
                                        </span>
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                      <div className="pb4">
                        <button
                          className="ui-button primary"
                          onClick={onCloseAnalysis}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            )}

            {(filterState.currentAnalysis === "new" ||
              filterState.currentAnalysis === "auto" ||
              editingAnalysis) && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `${width}px`,
                    height: `600px`,
                    overflow: "scroll",
                    top: (window.innerHeight - height) / 2,
                    position: "absolute",
                    left: (window.innerWidth - width) / 2,
                  }}
                >
                  <CreateAnalysis
                    user={props.user}
                    doClose={(editing, action) => {
                      if (editing) {
                        setEditingAnalysis(false);
                        getFilterdData(true, true, true, null, true);
                      } else {
                        setFilterState({
                          ...refs.filterState.current,
                          currentAnalysis: null,
                          selectedAnalysis: null,
                          onlyAnalysis: null,
                        });
                      }
                      updateCache();
                      if (action !== "cancel") {
                        setTimeout(() => {
                          updateCache();
                          setNewAnalysis(true);
                        }, 1000);
                      } else {
                        addToStorage(true);
                      }
                    }}
                    currentAnalysis={filterState.selectedAnalysis}
                    autoAnalysis={
                      filterState.currentAnalysis === "auto"
                        ? autoanalysis_data
                        : undefined
                    }
                    editing={editingAnalysis}
                    allAnalysis={[
                      ...dataState.analysis,
                      ...dataState.saved_tests,
                    ]}
                    type={
                      filterState.showCollection ? "collection" : "analysis"
                    }
                    parentType="report"
                    allCustomers={dataState.customers}
                  />
                </div>
              </Modal>
            )}
            {showCopyCollection && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `${width}px`,
                    height: `480px`,
                    overflow: "scroll",
                    top: (window.innerHeight - height) / 2,
                    position: "absolute",
                    left: (window.innerWidth - width) / 2,
                  }}
                >
                  <CopyCollection
                    user={props.user}
                    doClose={e => {
                      toggleCopyCollection()(e);
                    }}
                    allCustomers={dataState.customers}
                    allAnalysis={[
                      ...dataState.analysis,
                      ...dataState.saved_tests,
                    ]}
                  />
                </div>
              </Modal>
            )}
            <div className="sticky-submit flex justify-end">
              {urlCopied ? (
                <span className="f8 mr-auto">Copied!</span>
              ) : (
                <img
                  aria-label="share"
                  src={ShareIcon}
                  color="primary"
                  className="pointer mh3 mr-auto"
                  height="26"
                  width="26"
                  onClick={() => copyShareUrl("")}
                  style={{
                    marginTop: "7px",
                  }}
                />
              )}
              <div className="mr3">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <SwitchComp
                        checked={autoAnalysis}
                        onChange={toggleAutoAnalysis}
                        name="autoAnalysis"
                        color="primary"
                      />
                    }
                    label="Analyze"
                  />
                </FormGroup>
              </div>
              <div className="mr3 pt1">
                <a
                  className={`ui-button ${
                    showAdvancedAnalysis ? "primary" : "secondary"
                  } tiny pointer`}
                  onClick={e => {
                    if (!showAdvancedAnalysis) {
                      if (!autoAnalysis) {
                        toggleAutoAnalysis(e);
                      }
                      setShowAdvancedAnalysis(true);
                      setShowAutoAnalysisSettings(false);
                      setAdvancedAnalysesLoading(true);
                      request.get(
                        {
                          url: `${baseApiUrl}/v1/analysis/setting`,
                          headers: {
                            Authorization: props.user.token,
                          },
                        },
                        (err, res, data) => {
                          const json = JSON.parse(data);
                          setAdvancedAnalysesLoading(false);
                          if (json && json.data) {
                            setAdvancedAnalyses(
                              json.data.filter(d => d.type === "advanced")
                            );
                          }
                        }
                      );
                    } else {
                      setShowAdvancedAnalysis(false);
                      setAdvancedAnalysis({
                        name: "",
                        slug: "",
                        allVariants: [],
                        variants: [],
                      });
                      setFilterState({
                        ...refs.filterState.current,
                        advancedAnalysisId: null,
                      });
                      addToStorage();
                    }
                  }}
                >
                  Advanced Analysis
                </a>
              </div>
              <div className="mr3 pt1">
                <a
                  href=" #"
                  onClick={toggleCopyCollection()}
                  className="dib ui-button tiny secondary"
                >
                  Copy Collection
                </a>
              </div>
              <div className="mr3 pt1">
                {csvRequestLoading ? (
                  <a
                    href="#"
                    onClick={e => {}}
                    className="dib ui-button tiny secondary v-mid"
                  >
                    Downloading <CircularProgress size={12} color="secondary" />
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={e => {
                      getDataCSV(true, true, true, null);
                    }}
                    className="dib ui-button tiny secondary"
                  >
                    Download CSV
                  </a>
                )}
              </div>
              {mode === "analysis" && (
                <>
                  <div className="mr3 pt1">
                    <a
                      href=" #"
                      onClick={changeMode("publish")}
                      className="dib ui-button tiny secondary"
                    >
                      Publish Mode
                    </a>
                  </div>
                  {filterState.currentAnalysis &&
                    filterState.currentAnalysis !== "new" && (
                      <div className="mr3 pt1">
                        {filterState.onlyAnalysis ? (
                          <a
                            href=" #"
                            onClick={toggleShowOnlyAnalysis(false)}
                            className="dib ui-button tiny secondary"
                          >
                            Show all tests
                          </a>
                        ) : (
                          <a
                            href=" #"
                            onClick={toggleShowOnlyAnalysis(true)}
                            className="dib ui-button tiny secondary"
                          >
                            Only show tests in analysis
                          </a>
                        )}
                      </div>
                    )}
                  {filterState.currentAnalysis &&
                    filterState.currentAnalysis !== "new" && (
                      <div className="mr3 pt1">
                        <a
                          className="dib ui-button tiny secondary mr2"
                          onClick={editAnalysis}
                        >
                          Edit
                        </a>
                      </div>
                    )}
                  <div className="mr3 ">
                    {collectionData &&
                      collectionData.length > 0 &&
                      filtersLoaded &&
                      !tests_loading && (
                        <div
                          title={`${
                            filterState.showCollection
                              ? "Show Analyses"
                              : "Show Collections"
                          }`}
                        >
                          <FormControlLabel
                            value="collectionMode"
                            control={
                              <>
                                <SwitchComp
                                  color="primary"
                                  name="collectionMode"
                                  checked={filterState.showCollection}
                                  onChange={onChangeShowCollection}
                                />
                              </>
                            }
                            label=""
                            labelPlacement="start"
                          />
                        </div>
                      )}
                  </div>
                  <div className="mr3 w-25">
                    {collectionData &&
                      collectionData.length > 0 &&
                      filtersLoaded &&
                      !tests_loading &&
                      (filterState.currentAnalysis
                        ? filterState.selectedAnalysis
                        : true) && (
                        <TagSearch
                          defaultTags={
                            filterState.selectedAnalysis
                              ? [
                                  {
                                    label: filterState.selectedAnalysis.name,
                                    value: filterState.selectedAnalysis.id,
                                  },
                                ]
                              : null
                          }
                          options={[
                            {
                              label: `New ${
                                filterState.showCollection
                                  ? "Collection"
                                  : "Analysis"
                              }`,
                              value: "new",
                            },
                          ].concat(
                            collectionData.map(a => ({
                              label: a.name,
                              value: a.id,
                            }))
                          )}
                          singular
                          onBottom
                          callback={onChangeAnalysis}
                          optionComponent={IconOption}
                          onDeleteAnalysis={deleteAnalysis}
                        />
                      )}
                  </div>
                  <div className="flex-end">
                    {doChangeLoad ? (
                      <>Analyzing...</>
                    ) : (
                      <button
                        className={cx({
                          "ui-button": true,
                          disabled: !filterState.selectedAnalysis,
                          primary: filterState.selectedAnalysis,
                        })}
                        onClick={analyze}
                        disabled={!filterState.selectedAnalysis}
                      >
                        Analyze
                      </button>
                    )}
                  </div>
                </>
              )}
              {mode === "publish" && (
                <>
                  <div className="mr3 pt1">
                    <a
                      href=" #"
                      onClick={changeMode("analysis")}
                      className="dib ui-button tiny secondary mr2"
                    >
                      Analysis Mode
                    </a>
                  </div>
                  <div className="mr3 pt1"></div>
                  <div className="mr3 pt1"></div>
                  <div className="mr3 sticky-submit-box align-center">
                    Tests : <b className="mh2 ">{publishIds.length}</b> &middot;
                    <a
                      href=" #"
                      onClick={selectAll()}
                      className="dib tiny secondary mr2 ml1"
                    >
                      Select All
                    </a>
                    {publishIds.length !== 0 ? (
                      <>
                        /
                        <a
                          href=" #"
                          onClick={clearAllSelected()}
                          className="dib tiny secondary mr2 ml1"
                        >
                          Clear Selection
                        </a>
                      </>
                    ) : (
                      <>
                        / <p className="mr2 mb0 ml1">Clear Selection</p>
                      </>
                    )}
                  </div>
                  <div className="mr3">
                    <select
                      name="customers"
                      className="sticky-submit-box"
                      onChange={onChangeCustomer}
                    >
                      <option value="">-- Pick a customer --</option>
                      <option value="4a07cc72-a482-41cc-a68e-d649d3c1cc04">
                        Test
                      </option>
                      {dataState.customers.map(c => (
                        <option value={c.report} key={`c-${c.report}`}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-end">
                    {publishing ? (
                      <>Publishing...</>
                    ) : (
                      <button
                        className="ui-button primary"
                        onClick={publishToCustomer}
                      >
                        Publish
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {customer && (
          <>
            {filterState.from === "register" && customer.freeTrial && (
              <FreeTrialSalesModal
                doOpenModal
                isOnboarding
                unlockedTests={unlockedTests}
                unlock_limit={customer.unlock_limit}
              />
            )}
            {filterState.from === "register" && !customer.freeTrial && (
              <Modal
                open={true}
                onClose={closeRegModal}
                BackdropProps={{
                  className: "dark-modal-bg",
                }}
              >
                <div
                  className="box"
                  style={{
                    width: `${width}px`,
                    height: `480px`,
                    overflow: "hidden",
                    top: (window.innerHeight - height) / 2,
                    position: "absolute",
                    left: (window.innerWidth - width) / 2,
                    outline: 0,
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div className="bb b--black-05">
                      <div className="pv2 ph3 f3 dash-head">
                        Getting started
                      </div>
                    </div>
                    <div
                      style={{ margin: "24px auto 0", width: "560px" }}
                      dangerouslySetInnerHTML={{
                        __html: `<iframe width="560" height="315" src="https://www.youtube.com/embed/BP5ZHXvAcak?controls=0&autoplay=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                      }}
                    />
                  </div>
                  <div className="pt3 center tc">
                    <button
                      className="ui-button primary"
                      onClick={closeRegModal}
                    >
                      Jump in
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </>
        )}
        {data && Object.keys(data).length > 0 && (
          <MovieFone
            user={props.user}
            tagsToFilter={filterState.tagsToFilter}
            compsToFilter={filterState.compsToFilter}
            company={props.match.params.company}
          />
        )}
        {props.match.params.ssid && (
          <ViewScreenshotsModal
            id={props.match.params.ssid}
            company={props.match.params.company}
            user={props.user}
            history={props.history}
            collectionId={props.match.params.tid}
            isSavedTests={props.isSavedTests}
            urlParams={urlParams}
            testsCallback={getFilterdData}
            watchTestCallback={watchTestCallback}
            showShare={customer && customer.hasAdmin}
          />
        )}
      </div>
    </>
  );
};
export default AllTestReport;
