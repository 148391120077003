import React from "react";
import { Route } from "react-router-dom";
import Job from "./Job";
import Apply from "./Apply";

class Index extends React.Component {
  render() {
    return (
      <div className="tl pa3">
        <Route
          path="/careers/apply"
          exact
          render={props => (
            <Apply
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/careers/saas-sales"
          exact
          render={props => (
            <Job
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              url="https://docs.google.com/document/d/e/2PACX-1vQG8i3Npve0ODwl8hk2ENaJjq1SOdrqRBxOnTTgIPfZIJTXK5Yf7sDlDgXB8c8N0Vhp_auzoaZkwP4L/pub?embedded=true"
            />
          )}
        />
        <Route
          path="/careers/accountexec"
          exact
          render={props => (
            <Job
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              url="https://docs.google.com/document/d/e/2PACX-1vQbMOesZ_HRJuBxN8xxVbDGbjaPXimtqkExG4ujBaKSyXDwdGv1CQUzCG9UFNZ_qf2gc8igeK3VuOwt/pub?embedded=true"
            />
          )}
        />
        <Route
          path="/careers/analyst"
          exact
          render={props => (
            <Job
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              url="https://docs.google.com/document/d/e/2PACX-1vT3anr5jGbdDJE79bHXFyOr_BfRgpAYgfagy059XXefHW4p2TD1u6EHPfVHyaymZgL7xSdUcjkPwevb/pub?embedded=true"
            />
          )}
        />
      </div>
    );
  }
}

export default Index;
