import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl, handleError } from "../../util";

const ids = [
  "a1bc8b23-3724-45a0-b387-2013475f512d",
  "19cfd6c9-dcb4-48d7-a774-29a57b5ca776",
  "6a65e917-b1eb-4607-b7b6-f3fd26bf5377",
  "635ea9c9-15f6-40bf-8625-3477c268571d",
  "dc688b37-2fae-4632-bd31-322a52e8d63d",
  "a80162c6-2c2c-40b0-9f61-e4026bbbe929",
  "bb0f545d-3a13-45c8-b75d-7b3c6a4f0956",
  "2a7deb76-ce0a-4f2d-9561-12ace4a46196",
  "afb131cf-84e3-44b1-9d3c-5dead5daf867",
  " 64dd0ece-84dd-444c-a3a4-773598d7327a",
  "c26798c9-d112-4313-b966-b16da9b73319",
  "2143cffa-0a1c-4c2b-8f4e-989648f51370",
  "fc5717f1-d1ca-4298-932c-cfa79d04fb46",
  "9836f5b5-edb4-4416-bb8d-6d3904befbfc",
  "4a07cc72-a482-41cc-a68e-d649d3c1cc04",
  "64dd0ece-84dd-444c-a3a4-773598d7327a",
  "b49bf677-2475-4027-a11d-8d44b9f939e9",
  "579b14f8-3e0d-4501-b52f-23fca3adf291",
];

const Comps = ({ cust, c, z }) => {
  const customer = cust.comps[c];
  const queueDraft = customer.filter(
    x =>
      x.isQueue &&
      (!x.status ||
        x.status === "draft_review" ||
        x.status === "analyst_review")
  );
  const queueApproval = customer.filter(
    x => x.isQueue && x.status === "approval_review"
  );
  const queueReview = customer.filter(
    x =>
      x.isQueue &&
      (x.status === "analyst_review" || x.status === "analyst_feedback")
  );
  const last7Total = customer.filter(x => !x.isQueue && x.thisWeek);
  const last7Called = customer.filter(
    x => !x.isQueue && x.thisWeek && x.hasChamp
  );
  const last7Added = customer.filter(
    x => !x.isQueue && x.thisWeek && !x.hasChamp
  );
  const last14Total = customer.filter(x => !x.isQueue && x.lastWeek);
  const last14Called = customer.filter(
    x => !x.isQueue && x.lastWeek && x.hasChamp
  );
  const last14Added = customer.filter(
    x => !x.isQueue && x.lastWeek && !x.hasChamp
  );
  const last30Total = customer.filter(x => !x.isQueue && x.lastMonth);
  const last30Called = customer.filter(
    x => !x.isQueue && x.lastMonth && x.hasChamp
  );
  const last30Added = customer.filter(
    x => !x.isQueue && x.lastMonth && !x.hasChamp
  );
  return (
    <tr className={`comp ${z % 2 !== 0 ? "bg-stripe" : ""}`}>
      <td className="pa2">{c}</td>
      <td className="c-queue tc pa2">
        {queueDraft.length}/{queueReview.length}/{queueApproval.length}
      </td>
      <td className="c-7 tc pa2">
        {last7Total.length}/{last7Called.length}/{last7Added.length}
      </td>
      <td className="c-14 tc pa2">
        {last14Total.length}/{last14Called.length}/{last14Added.length}
      </td>
      <td className="c-30 tc pa2">
        {last30Total.length}/{last30Called.length}/{last30Added.length}
      </td>
    </tr>
  );
};

class QueueDraft extends React.Component {
  state = {
    items: [],
    loading: true,
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/queue_dashboard`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        const json = JSON.parse(data);
        const customer = json.dataByCust.total.data;
        const totalData = {};
        totalData.last7Total = customer.filter(x => !x.isQueue && x.thisWeek);
        totalData.last7Called = customer.filter(
          x => !x.isQueue && x.thisWeek && x.hasChamp
        );
        totalData.last7Added = customer.filter(
          x =>
            !x.isQueue &&
            x.thisWeek &&
            (x.activity_status === "new" || x.activity_status === "new_r")
        );
        totalData.last14Total = customer.filter(x => !x.isQueue && x.lastWeek);
        totalData.last14Called = customer.filter(
          x => !x.isQueue && x.lastWeek && x.hasChamp
        );
        totalData.last14Added = customer.filter(
          x =>
            !x.isQueue &&
            x.lastWeek &&
            (x.activity_status === "new" || x.activity_status === "new_r")
        );
        totalData.last30Total = customer.filter(x => !x.isQueue && x.lastMonth);
        totalData.last30Called = customer.filter(
          x => !x.isQueue && x.lastMonth && x.hasChamp
        );
        totalData.last30Added = customer.filter(
          x =>
            !x.isQueue &&
            x.lastMonth &&
            (x.activity_status === "new" || x.activity_status === "new_r")
        );

        totalData.queueDraft = customer.filter(
          x => x.isQueue && (!x.status || x.status === "draft_review")
        );
        totalData.queueReview = customer.filter(
          x =>
            x.isQueue &&
            (x.status === "analyst_review" || x.status === "analyst_feedback")
        );
        totalData.queueApproval = customer.filter(
          x => x.isQueue && x.status === "approval_review"
        );
        totalData.queuePublish = customer.filter(
          x => x.isQueue && x.status === "publish_review"
        );

        json.loading = false;

        this.setState({ ...json, totalData });
      }
    );
  };
  showComps = customer => e => {
    e.preventDefault();
    this.setState({ selectedCust: customer });
  };
  render() {
    const { dataByCust, totalData } = this.state;
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div>
        <h1>Queue Dashboard</h1>
        <div className="mt2 box pa3">
          <table className="w-100">
            <thead>
              <th className="bb pa2 b--light-gray">Customer</th>
              <th className="tc bb pa2 b--light-gray">
                In Queues
                <br />
                <span style={{ fontSize: "10px" }}>Draft/Review/Approval</span>
              </th>
              <th className="tc bb pa2 b--light-gray">
                Last 7 days <br />
                <span style={{ fontSize: "10px" }}>Total/Called/Published</span>
              </th>
              <th className="tc bb pa2 b--light-gray">
                Last 14 days <br />
                <span style={{ fontSize: "10px" }}>Total/Called/Published</span>
              </th>
              <th className="tc bb pa2 b--light-gray">
                Last 30 days <br />
                <span style={{ fontSize: "10px" }}>Total/Called/Published</span>
              </th>
            </thead>
            <tbody>
              {Object.keys(dataByCust)
                .sort((a, b) => {
                  if (a === "total") {
                    return 1;
                  }
                  if (b === "total") {
                    return -1;
                  }
                  return dataByCust[a].customer.name.toLowerCase() >=
                    dataByCust[b].customer.name.toLowerCase()
                    ? 1
                    : -1;
                })
                .map((i, z) => {
                  if (ids.includes(i)) {
                    return <></>;
                  }
                  if (i === "total") {
                    return <></>;
                  }

                  const customer = dataByCust[i];
                  const last7Total = customer.data.filter(
                    x => !x.isQueue && x.thisWeek
                  );
                  const last7Called = customer.data.filter(
                    x => !x.isQueue && x.thisWeek && x.hasChamp
                  );
                  const last7Added = customer.data.filter(
                    x =>
                      !x.isQueue &&
                      x.thisWeek &&
                      (x.activity_status === "new" ||
                        x.activity_status === "new_r")
                  );
                  const last14Total = customer.data.filter(
                    x => !x.isQueue && x.lastWeek
                  );
                  const last14Called = customer.data.filter(
                    x => !x.isQueue && x.lastWeek && x.hasChamp
                  );
                  const last14Added = customer.data.filter(
                    x =>
                      !x.isQueue &&
                      x.lastWeek &&
                      (x.activity_status === "new" ||
                        x.activity_status === "new_r")
                  );
                  const last30Total = customer.data.filter(
                    x => !x.isQueue && x.lastMonth
                  );
                  const last30Called = customer.data.filter(
                    x => !x.isQueue && x.lastMonth && x.hasChamp
                  );
                  const last30Added = customer.data.filter(
                    x =>
                      !x.isQueue &&
                      x.lastMonth &&
                      (x.activity_status === "new" ||
                        x.activity_status === "new_r")
                  );

                  const queueDraft = customer.data.filter(
                    x =>
                      x.isQueue &&
                      (!x.status ||
                        x.status === "draft_review" ||
                        x.status === "analyst_review")
                  );
                  const queueApproval = customer.data.filter(
                    x => x.isQueue && x.status === "approval_review"
                  );
                  const queueReview = customer.data.filter(
                    x =>
                      x.isQueue &&
                      (x.status === "analyst_review" ||
                        x.status === "analyst_feedback")
                  );
                  const queuePublish = customer.data.filter(
                    x => x.isQueue && x.status === "publish_review"
                  );
                  return (
                    <>
                      <tr className={`${z % 2 !== 0 ? "bg-stripe" : ""}`}>
                        <td className="pa2">
                          {" "}
                          {this.state.selectedCust ===
                          customer.customer.name ? (
                            <a
                              href=" #"
                              className="b"
                              onClick={this.showComps("")}
                            >
                              -
                            </a>
                          ) : (
                            <a
                              href=" #"
                              className="b"
                              onClick={this.showComps(customer.customer.name)}
                            >
                              +
                            </a>
                          )}
                          {customer.customer.name}
                        </td>
                        <td className="tc pa2">
                          {queueDraft.length}/{queueReview.length}/
                          {queueApproval.length}
                        </td>
                        <td className="tc pa2">
                          {last7Total.length}/{last7Called.length}/
                          {last7Added.length}
                        </td>
                        <td className="tc pa2">
                          {last14Total.length}/{last14Called.length}/
                          {last14Added.length}
                        </td>
                        <td className="tc pa2">
                          {last30Total.length}/{last30Called.length}/
                          {last30Added.length}
                        </td>
                      </tr>
                      {this.state.selectedCust === customer.customer.name &&
                        Object.keys(customer.comps)
                          .sort((a, b) =>
                            a.toLowerCase() >= b.toLowerCase() ? 1 : -1
                          )
                          .map((c, y) => (
                            <Comps c={c} y={y} cust={customer} z={z} />
                          ))}
                    </>
                  );
                })}
              <>
                <tr>
                  <td className="pa2 bt b--light-gray">
                    {" "}
                    {this.state.selectedCust === "total" ? (
                      <a href=" #" className="b" onClick={this.showComps("")}>
                        -
                      </a>
                    ) : (
                      <a
                        href=" #"
                        className="b"
                        onClick={this.showComps("total")}
                      >
                        +
                      </a>
                    )}
                    Total
                  </td>
                  <td className="tc pa2 bt b--light-gray">
                    {totalData.queueDraft.length}/{totalData.queueReview.length}
                    /{totalData.queueApproval.length}
                  </td>
                  <td className="tc pa2 bt b--light-gray">
                    {totalData.last7Total.length}/{totalData.last7Called.length}
                    /{totalData.last7Added.length}
                  </td>
                  <td className="tc pa2 bt b--light-gray">
                    {totalData.last14Total.length}/
                    {totalData.last14Called.length}/
                    {totalData.last14Added.length}
                  </td>
                  <td className="tc pa2 bt b--light-gray">
                    {totalData.last30Total.length}/
                    {totalData.last30Called.length}/
                    {totalData.last30Added.length}
                  </td>
                </tr>
                {this.state.selectedCust === "total" &&
                  Object.keys(dataByCust["total"].comps)
                    .sort((a, b) =>
                      a.toLowerCase() >= b.toLowerCase() ? 1 : -1
                    )
                    .map((c, y) => (
                      <Comps
                        c={c}
                        y={y}
                        cust={dataByCust["total"]}
                        z={"total"}
                      />
                    ))}
              </>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default QueueDraft;
