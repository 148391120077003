import React from "react";
import { Link } from "react-router-dom";
import * as querystring from "query-string";
import * as cx from "classnames";
import request from "request";
import "./dashboard.css";
import {
  loginLookup,
  getData,
  baseApiUrl,
  addCommas,
  isDemoComp,
} from "../../util";
import FakeDashboard from "../../components/FakeDashboard";
import DashboardHeader from "../../components/DashboardHeader";
import InfoPopup from "../../components/InfoPopup";
import AccessDenied from "../../components/AccessDenied";
import MovieFone from "../../components/MovieFone";
import TagFilter from "../../components/TagFilter";
import KeywordGroups from "../../components/KeywordGroups";
import CompaniesIcon from "../../icons/companies_gray.svg";
import GroupsIcon from "../../icons/keywords_gray.svg";
import KeywordIcon from "../../icons/keyword.svg";
import BlueArrowIcon from "../../icons/blue_arrow.svg";
import DownIcon from "../../icons/down_arrow.svg";
import SortIcon from "../../icons/sort.svg";
import SortUpIcon from "../../icons/SortUp.svg";
import SortDownIcon from "../../icons/SortDown.svg";
import DownloadIcon from "../../icons/Download.svg";
import X from "../../icons/tinyX.svg";

const Terms = ({ p, tld, company }) => (
  <div
    className="pa3 mt1 box f6 yellow-box"
    style={{
      width: "200px",
      whiteSpace: "normal",
    }}
  >
    <b>On keywords:</b>{" "}
    {p.terms.map((t, u) => {
      let tTld, tTerm;
      if (t.includes("~")) {
        const tArr = t.split("~");
        tTld = tArr[1];
        tTerm = tArr[0];
      } else {
        tTld = tld;
        tTerm = t;
      }
      return !tTld ? (
        <>
          {tTerm}
          {p.terms.length - 2 >= u && <>, </>}
        </>
      ) : (
        <>
          <Link
            to={`/home/${company}/sem_2/${tTld}/${encodeURIComponent(tTerm)}`}
          >
            {tTerm}
          </Link>
          {p.terms.length - 2 >= u && <>, </>}
        </>
      );
    })}
  </div>
);
const Sort = ({ activeSort, sort }) => (
  <>
    {activeSort === sort && <img src={SortUpIcon} alt="sort" className="pl1" />}
    {activeSort === `${sort}_reverse` && (
      <img src={SortDownIcon} alt="sort" className="pl1" />
    )}
    {activeSort !== `${sort}_reverse` && activeSort !== sort && (
      <img src={SortIcon} alt="sort" className="pl1" />
    )}
  </>
);
class SemDashboard extends React.Component {
  qs = null;
  getCachedQS = arg => {
    if (this.qs === null) {
      this.qs = querystring.parse(window.location.search);
    }
    if (arg) {
      if (this.qs[arg]) {
        if (arg === "sort") {
          return this.qs[arg];
        }
        return this.qs[arg].split("|");
      }
      return undefined;
    }
    return this.qs;
  };
  did_hash = false;
  state = {
    loading: true,
    csv_loading: false,
    counts: undefined,
    percents: [],
    hint: false,
    openModal: false,
    openEditModal: false,
    editSuccess: false,
    show_all_ab: false,
    show_all_sem: false,
    saved_tests: [],
    first: false,
    sem_tlds: [],
    sem_filter_tlds: this.getCachedQS("companies") || [],
    chunks: {},
    keywords: [],
    kw_groups: [],
    kws_filter: this.getCachedQS("keywords") || [],
    edit_priority: this.getCachedQS().editPriority || false,
    comp_lookup: {},
    total_sem_count: 0,
    total_sem_test_count: 0,
    total_unique_sem_count: 0,
    start: 0,
  };
  inputRef = React.createRef();
  foundFirst = false;
  triggerFoundTwice = false;
  canvasOptions = {};
  getData = shouldSkipCache => {
    const { id, newCall, byMonth } = querystring.parse(window.location.search);
    let report = null;

    if (id) {
      report = id;
    }
    const apiUrl = `${baseApiUrl}/v1/report?byModule=false${
      report ? `&report=${report}` : ""
    }&company=${
      this.props.match.params.company
    }&returnData=sem_new&newSem=true&newDash=true&doSavedTests=true${
      newCall ? "&newCall=true" : ""
    }byMonth=true`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      false,
      true,
      shouldSkipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        this.setState({
          loading: true,
          authed: false,
          accessDenied: res.statusCode === 400,
        });
        return;
      }

      this.setState({
        loading: false,
        saved_tests: data.saved_tests,
        reportId: id ? id : data.report.id,
        report: report,
        counts: data.counts,
        data: data.data,
        customer: data.customer,
        comp_lookup: data.comp_lookup,
        editSuccess: false,
        openEditModal: false,
        sem_phrases: data.sem_phrases,
        byMonth: byMonth === "true",
      });
    });
  };
  enterSemPhraseBox = e => {
    if (!this.foundFirst) {
      this.foundFirst = true;
      const elm = document.querySelectorAll(".this-week .hover-highlight");
      if (!elm || !elm[0]) {
        return;
      }
      const event = new MouseEvent("mouseover", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      elm[0].dispatchEvent(event);
      setTimeout(() => {
        const event = new MouseEvent("mouseout", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        elm[0].dispatchEvent(event);
      }, 3500);
    }
  };
  componentDidMount() {
    this.getData();
  }

  render() {
    const {
      loading,
      sem_activity,
      sem_phrases,
      show_all_sem,
      reportId,
      total_unique_sem_count,
      sort,
      chunks,
    } = this.state;

    let phraseKeys = null;

    if (sem_phrases) {
      phraseKeys = Object.keys(sem_phrases);

      phraseKeys.sort((a, b) => {
        let highA = 0;
        let highB = 0;

        const xa = sem_phrases[a]["This Week"];
        if (xa) {
          if (xa.dollar && xa.dollar.length > 0) {
            xa.dollar.forEach(d => {
              const amount = parseInt(d.text.replace("$", ""));
              if (amount > highA) {
                highA = amount + 1;
              }
            });
          }
          if (xa.percent && xa.percent.length > 0) {
            xa.percent.forEach(d => {
              const amount = parseInt(d.text.replace("%", "")) * 0.01;
              if (amount > highA) {
                highA = amount + 1;
              }
            });
          }
          if (xa.ship && xa.ship.length > 0) {
            const amount = 0.001;
            if (amount > highA) {
              highA = amount + 1;
            }
          }
        }

        const xb = sem_phrases[b]["This Week"];
        if (xb) {
          if (xb.dollar && xb.dollar.length > 0) {
            xb.dollar.forEach(d => {
              const amount = parseInt(d.text.replace("$", ""));
              if (amount > highB) {
                highB = amount + 1;
              }
            });
          }
          if (xb.percent && xb.percent.length > 0) {
            xb.percent.forEach(d => {
              const amount = parseInt(d.text.replace("%", "")) * 0.01;
              if (amount > highB) {
                highB = amount + 1;
              }
            });
          }
          if (xb.ship && xb.ship.length > 0) {
            const amount = 0.001;
            if (amount > highB) {
              highB = amount + 1;
            }
          }
        }

        return highA >= highB ? -1 : 1;
      });
    }

    const displayFilters = [];

    if (loading) {
      return (
        <>
          <FakeDashboard company={this.props.match.params.company} isSEM />
          {this.state.accessDenied && <AccessDenied />}
        </>
      );
    }
    return (
      <div className="mb4">
        <DashboardHeader
          customer={this.props.match.params.company}
          name={this.state.customer ? this.state.customer.name : null}
          customerData={this.state.customer}
          selected="discounts"
          reportId={this.props.match.params.id}
        />
        <div className="mt4 document-spacing center ph3">
          {this.props.match.params.company !== "adobe" &&
            this.props.match.params.company !== "changedotorg" &&
            this.props.match.params.company !== "thryv" &&
            sem_phrases && (
              <div
                className="mt4 box pb2"
                onMouseOver={this.enterSemPhraseBox}
                id="discount-dashboard"
              >
                <div class="bb b--dww pv2 ph3 mb3 f5">
                  <div className="dashbox-pad mb2">
                    <div className="f3 pt2 dash-head mb1">
                      Discount Dashboard
                    </div>
                    <div className="f5 dww-gray">
                      All the discounts in SEM we've discovered for your
                      keywords & companies. See{" "}
                      <Link
                        to={`/home/${this.props.match.params.company}/report?challenge=Testing%20offers%20%26%20promotions`}
                      >
                        discount A/B tests
                      </Link>
                      .
                    </div>
                  </div>
                </div>
                <div className="mt2 mb3 mh3">
                  <table className="w-100 f6 f8-s">
                    <thead>
                      <tr className="nowrap">
                        <td />
                        <td className="pa2 tc form-headline">1 month ago</td>
                        <td className="pa2 tc form-headline">1 week ago</td>
                        <td className="pa2 tc form-headline">This week</td>
                      </tr>
                    </thead>
                    <tbody>
                      {phraseKeys.map((company, i) => {
                        const item = sem_phrases[company];
                        let tld = this.state.comp_lookup[company];
                        return (
                          <tr
                            className={
                              i % 2 !== 0
                                ? "bg-stripe dt-row nowrap"
                                : "dt-row nowrap"
                            }
                          >
                            <td
                              className={
                                i % 2 !== 0
                                  ? "bg-stripe bg-stripe-left pa2 w-15 tc nowrap"
                                  : "pa2 w-15 tc nowrap"
                              }
                            >
                              <span className="nowrap cat-tag dib">
                                {company}
                              </span>
                            </td>
                            <td className="pa2 v-top w-28 tc">
                              {item["Last Month"] ? (
                                <div className="dib w-50">
                                  {item["Last Month"].dollar.length > 0 && (
                                    <div className="mb1">
                                      {item["Last Month"].dollar.map((p, i) => (
                                        <span key={`dlm-${p}-${i}`}>
                                          <InfoPopup trigger={p.text}>
                                            <Terms
                                              p={p}
                                              tld={tld}
                                              company={
                                                this.props.match.params.company
                                              }
                                            />
                                          </InfoPopup>
                                          {i <
                                          item["Last Month"].dollar.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}{" "}
                                      off
                                    </div>
                                  )}
                                  {item["Last Month"].percent.length > 0 && (
                                    <div className="mb1">
                                      {item["Last Month"].percent.map(
                                        (p, i) => (
                                          <span key={`plm-${p}-${i}`}>
                                            <InfoPopup trigger={p.text}>
                                              <Terms
                                                p={p}
                                                tld={tld}
                                                company={
                                                  this.props.match.params
                                                    .company
                                                }
                                              />
                                            </InfoPopup>
                                            {i <
                                            item["Last Month"].percent.length -
                                              1
                                              ? ", "
                                              : ""}
                                          </span>
                                        )
                                      )}{" "}
                                      off
                                    </div>
                                  )}
                                  {item["Last Month"].timeFree &&
                                    item["Last Month"].timeFree.length > 0 && (
                                      <div className="mb1">
                                        {item["Last Month"].timeFree.map(
                                          (p, i) => (
                                            <span key={`tlm-${p}-${i}`}>
                                              <InfoPopup trigger={p.text}>
                                                <Terms
                                                  p={p}
                                                  tld={tld}
                                                  company={
                                                    this.props.match.params
                                                      .company
                                                  }
                                                />
                                              </InfoPopup>
                                              {i <
                                              item["Last Month"].timeFree
                                                .length -
                                                1
                                                ? ", "
                                                : ""}
                                            </span>
                                          )
                                        )}{" "}
                                      </div>
                                    )}
                                  {item["Last Month"].ship.length > 0 && (
                                    <div className="mb1">
                                      <InfoPopup trigger="Free Shipping">
                                        <Terms
                                          p={item["Last Month"].ship[0]}
                                          tld={tld}
                                          company={
                                            this.props.match.params.company
                                          }
                                        />
                                      </InfoPopup>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="dib w-50">
                                  <i>No offers detected</i>
                                </div>
                              )}
                            </td>
                            <td className="pa2 v-top w-28 tc">
                              {item["Last Week"] ? (
                                <div className="dib w-50">
                                  {item["Last Week"].dollar.length > 0 && (
                                    <div className="mb1">
                                      {item["Last Week"].dollar.map((p, i) => (
                                        <span key={`dlw-${i}`}>
                                          <InfoPopup trigger={p.text}>
                                            <Terms
                                              p={p}
                                              tld={tld}
                                              company={
                                                this.props.match.params.company
                                              }
                                            />
                                          </InfoPopup>
                                          {i <
                                          item["Last Week"].dollar.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}{" "}
                                      off
                                    </div>
                                  )}
                                  {item["Last Week"].percent.length > 0 && (
                                    <div className="mb1">
                                      {item["Last Week"].percent.map((p, i) => (
                                        <span key={`plw-${i}`}>
                                          <InfoPopup trigger={p.text}>
                                            <Terms
                                              p={p}
                                              tld={tld}
                                              company={
                                                this.props.match.params.company
                                              }
                                            />
                                          </InfoPopup>
                                          {i <
                                          item["Last Week"].percent.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}{" "}
                                      off
                                    </div>
                                  )}
                                  {item["Last Week"].timeFree &&
                                    item["Last Week"].timeFree.length > 0 && (
                                      <div className="mb1">
                                        {item["Last Week"].timeFree.map(
                                          (p, i) => (
                                            <span key={`tlw-${i}`}>
                                              <InfoPopup trigger={p.text}>
                                                <Terms
                                                  p={p}
                                                  tld={tld}
                                                  company={
                                                    this.props.match.params
                                                      .company
                                                  }
                                                />
                                              </InfoPopup>
                                              {i <
                                              item["Last Week"].timeFree
                                                .length -
                                                1
                                                ? ", "
                                                : ""}
                                            </span>
                                          )
                                        )}{" "}
                                      </div>
                                    )}
                                  {item["Last Week"].ship.length > 0 && (
                                    <div className="mb1">
                                      <InfoPopup trigger="Free Shipping">
                                        <Terms
                                          p={item["Last Week"].ship[0]}
                                          tld={tld}
                                          company={
                                            this.props.match.params.company
                                          }
                                        />
                                      </InfoPopup>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="dib w-50">
                                  <i>No offers detected</i>
                                </div>
                              )}
                            </td>
                            <td
                              className={
                                i % 2 !== 0
                                  ? "bg-stripe bg-stripe-right pa2 v-top w-28 tc"
                                  : "pa2 v-top w-29 tc"
                              }
                            >
                              {item["This Week"] ? (
                                <div className="dib w-50 this-week">
                                  {item["This Week"].dollar.length > 0 && (
                                    <div className="mb1">
                                      {item["This Week"].dollar.map((p, i) => (
                                        <span key={`dtw-${i}`}>
                                          <InfoPopup trigger={p.text}>
                                            <Terms
                                              p={p}
                                              tld={tld}
                                              company={
                                                this.props.match.params.company
                                              }
                                            />
                                          </InfoPopup>
                                          {i <
                                          item["This Week"].dollar.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}{" "}
                                      off
                                    </div>
                                  )}
                                  {item["This Week"].percent.length > 0 && (
                                    <div className="mb1">
                                      {item["This Week"].percent.map((p, i) => (
                                        <span key={`ptw-${i}`}>
                                          <InfoPopup trigger={p.text}>
                                            <Terms
                                              p={p}
                                              tld={tld}
                                              company={
                                                this.props.match.params.company
                                              }
                                            />
                                          </InfoPopup>
                                          {i <
                                          item["This Week"].percent.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}{" "}
                                      off
                                    </div>
                                  )}
                                  {item["This Week"].timeFree &&
                                    item["This Week"].timeFree.length > 0 && (
                                      <div className="mb1">
                                        {item["This Week"].timeFree.map(
                                          (p, i) => (
                                            <span key={`ttw-${i}`}>
                                              <InfoPopup trigger={p.text}>
                                                <Terms
                                                  p={p}
                                                  tld={tld}
                                                  company={
                                                    this.props.match.params
                                                      .company
                                                  }
                                                />
                                              </InfoPopup>
                                              {i <
                                              item["This Week"].timeFree
                                                .length -
                                                1
                                                ? ", "
                                                : ""}
                                            </span>
                                          )
                                        )}{" "}
                                      </div>
                                    )}
                                  {item["This Week"].ship.length > 0 && (
                                    <div className="mb1">
                                      <InfoPopup trigger="Free Shipping">
                                        <Terms
                                          p={item["This Week"].ship[0]}
                                          tld={tld}
                                          company={
                                            this.props.match.params.company
                                          }
                                        />
                                      </InfoPopup>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="dib w-50">
                                  <i>No offers detected</i>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
        </div>
        <MovieFone
          user={this.props.user}
          company={this.props.match.params.company}
        />
      </div>
    );
  }
}

export default SemDashboard;
