import React from "react";
import * as querystring from "query-string";
import * as cx from "classnames";
import { Link } from "react-router-dom";
import { baseApiUrl, getData, imageUrl, getMonthText } from "../../util";
import Loading from "../../components/Loading";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SemChunkList from "../../components/SemChunkList";
import DashboardHeader from "../../components/DashboardHeader";
import "./viewsem.css";
import DesktopIcon from "../../icons/old/desktop.svg";
import MobileIcon from "../../icons/old/mobile.svg";
import DownArrow from "../../icons/old/down_arrow.svg";
import UpArrow from "../../icons/old/up_arrow.svg";
import Help from "../../icons/old/help_outlined.svg";
import HeadlineImg from "../../img/headline.png";
import SadPage from "../../icons/old/sadpage.svg";
import XIcon from "../../icons/x.svg";
import AccessDenied from "../../components/AccessDenied";
import MovieFone from "../../components/MovieFone";
import Modal from "@material-ui/core/Modal";

class ViewSem extends React.Component {
  state = {
    selectedItem: 0,
    selectedSSImg: "",
    report: undefined,
    data: undefined,
    keyword: undefined,
    loading: true,
    sortBy: "Keyword",
    showAll: false,
    sortReverse: false,
    displayData: [],
    weeksAgo: 0,
    lastDataWeek: 0,
    tests: null,
    location: null,
    modal: false,
  };
  componentDidMount() {
    const { weeksAgo, sort, view, byMonth } = querystring.parse(
      window.location.search
    );

    if (window.location.href.includes("#uk")) {
      const arr = window.location.href.split("#uk");
      window.location.replace(arr.join(""));
    }
    if (window.location.href.includes("#au")) {
      const arr = window.location.href.split("#au");
      window.location.replace(arr.join(""));
    }

    this.setState(
      {
        weeksAgo: weeksAgo || 0,
        sort: sort || "sov",
        view,
        byMonth: byMonth || false,
      },
      this.getData
    );
  }
  replaceSSData = url => {
    if (
      url ===
      "https://d39sdekjh6lrma.cloudfront.net/800x0/31a883ab-4658-4048-be26-8146f63bcc66/fffbeeb5-9130-4c31-ad1d-2965f250e569.jpg"
    ) {
      return "https://d39sdekjh6lrma.cloudfront.net/800x0/551c8f23-14b2-4898-9c62-9e27c16e3c13_peloton%201.jpg";
    }
    if (
      url ===
      "https://d39sdekjh6lrma.cloudfront.net/800x0/434d3b8f-d29d-4ce2-b5f9-7112798d686c/4ef0be96-ed26-40ce-aa64-f0467384d74d.jpg"
    ) {
      url =
        "https://d39sdekjh6lrma.cloudfront.net/800x00/434d3b8f-d29d-4ce2-b5f9-7112798d686c/4ef0be96-ed26-40ce-aa64-f0467384d74d.jpg";
    }

    return url;
  };

  getData = () => {
    const { location, newCall } = querystring.parse(window.location.search);
    const locString = location && location !== "us" ? `#${location}` : "";

    const newCallStr = newCall ? "&newCall=true" : "";
    let keyword;

    if (this.state.keyword) {
      keyword = this.state.keyword.replace("%2B", "+").toLowerCase();
    } else {
      keyword = this.props.match.params.keyword
        .replace("%2B", "+")
        .toLowerCase();
    }
    getData({
      url: `${baseApiUrl}/v1/sem?tld=${
        this.props.match.params.id
      }${encodeURIComponent(locString)}&weeksAgo=${
        this.state.weeksAgo
      }&reportId=${this.props.match.params.company}${newCallStr}${
        keyword ? `&term=${keyword}` : ""
      }&byMonth=true`,
      headers: {
        Authorization: this.props.user.token,
      },
    })
      .then(({ err, res, data }) => {
        const kws = data.terms || [];
        if (!keyword) {
          keyword = kws[0];
        }

        let noData = false;
        let lastDataWeek = 0;

        if (!kws.includes(keyword)) {
          kws.push(keyword);
        }
        kws.sort((a, b) => (a.toLowerCase() >= b.toLowerCase() ? 1 : -1));

        if (data.chunks && data.chunks.low_volume) {
          let lv = [];

          if (data.chunks.best) {
            lv = lv.concat(data.chunks.best);
          }
          if (data.chunks.ok) {
            lv = lv.concat(data.chunks.ok);
          }
          if (data.chunks.bad) {
            lv = lv.concat(data.chunks && data.chunks.bad);
          }
          data.chunks.low_volume = lv;
        }

        this.setState({
          loading: false,
          kwLoading: false,
          authed: true,
          report: data.report,
          customer: data.customer,
          company: data.company
            ? data.company
            : {
                name: this.props.match.params.id,
                tld: this.props.match.params.id,
              },
          noData: data.noData,
          lastDataWeek,
          keywords: kws,
          selectedSSImg: !noData ? data.lpScreenshot : null,
          selectedLP: !noData ? data.landingPage : null,
          tests: data.tests,
          keyword: keyword,
          chunks: data.chunks,
          count: data.chunks ? data.chunks.count : 0,
          low_volume: data.chunks ? data.chunks.low_volume : false,
        });
      })
      .catch(err => {
        if (err) {
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
      });
  };
  onChangeWeek = e => {
    this.setState({
      kwLoading: true,
    });
    this.setState(
      {
        weeksAgo: e.target.value,
      },
      this.getData
    );
  };
  handleWeekChange = e => {
    e.preventDefault();
    this.setState({
      kwLoading: true,
    });
    this.setState(
      {
        weeksAgo: this.state.lastDataWeek,
      },
      this.getData
    );
  };
  filterByKeyword = (semData, keyword) => {
    const data = semData.filter(d => {
      if (d["Keyword"] === keyword) {
        return d;
      }
      return null;
    });
    this.setState({
      keyword,
    });

    return this.sortBy("Current Status", data);
  };
  onFilterByKeyword = e => {
    const kw = e.target.value;
    this.setState(
      {
        keyword: kw,
        kwLoading: true,
      },
      () => {
        this.getData();
      }
    );
  };
  onHandleViewFilter = e => {
    this.setState({
      showAll: e.target.value === "summary" ? false : true,
    });
  };
  showLPs = e => {
    this.setState({
      showTests: !this.state.showTests,
    });
  };
  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    if (
      this.props.user.company !== "admin" &&
      this.props.user.company !== this.props.match.params.company
    ) {
      return <AccessDenied />;
    }
    if (
      this.props.user.company !== "admin" &&
      this.props.user.company !== this.props.match.params.company
    ) {
      return <AccessDenied />;
    }
    const { displayData, selectedItem, weeksAgo, lastDataWeek, tests } =
      this.state;

    return (
      <>
        <DashboardHeader
          customer={this.props.match.params.company}
          name={this.state.customer ? this.state.customer.name : null}
          customerData={this.state.customer}
          selected="sem"
        />
        <div className="mt4 document-spacing center">
          <div className="mt3 mb4 tl f7 b near-black flex">
            <h1 className="f3 dash-head f7-s ph1-s">
              <span className="cat-tag mr1">
                {this.state.company.name || this.state.company.tld}
              </span>{" "}
              testing {this.state.count} headlines
              <Select
                labelId="weeks-ago-label"
                id="weeks-ago"
                value={this.state.weeksAgo}
                onChange={this.onChangeWeek}
                className="ml2 bg-white dib pr3"
                variant="outlined"
              >
                <MenuItem value="0">this month</MenuItem>
                <MenuItem value="1">1 month ago</MenuItem>
                <MenuItem value="2">2 months ago</MenuItem>
              </Select>{" "}
              on
              <Select
                labelId="kw-label"
                id="kw"
                onChange={this.onFilterByKeyword}
                value={this.state.keyword}
                className="ml2 mr2 bg-white dib pr3"
                variant="outlined"
              >
                {this.state.keywords.map(k => (
                  <MenuItem value={k} key={k}>
                    {k}
                  </MenuItem>
                ))}
              </Select>
            </h1>
          </div>
          {this.state.noData && (
            <div className="flex flex-wrap">
              <p className="b">
                No headlines found for this month.
                {weeksAgo === 0 && lastDataWeek > 0 && (
                  <span>
                    {" "}
                    <a href="#foo" onClick={this.handleWeekChange}>
                      See ads
                    </a>{" "}
                    from {lastDataWeek}{" "}
                    {lastDataWeek === 1 ? "month" : "months"} ago.
                  </span>
                )}
              </p>
            </div>
          )}
          {this.state.kwLoading && (
            <div
              style={{
                position: "absolute",
                margin: "0 auto",
                width: "100%",
                zIndex: 999,
              }}
            >
              <Loading />
            </div>
          )}
          {!this.state.noData && (
            <div
              className="flex flex-wrap"
              style={{ opacity: this.state.kwLoading ? 0.5 : 1 }}
            >
              {this.state.low_volume ? (
                <div className="w-100-s w-60-ns pr4 pr0-s">
                  <SemChunkList
                    data={this.state.chunks.low_volume}
                    title={"All Headlines"}
                    onSelectCallback={this.onSelectItem}
                    showAll={this.state.showAll}
                    user={this.props.user}
                    status="low_volume"
                  />
                </div>
              ) : (
                <div className="w-100-s w-60-ns pr4 pr0-s">
                  <SemChunkList
                    data={this.state.chunks.best || []}
                    title={"Best bets"}
                    onSelectCallback={this.onSelectItem}
                    showAll={this.state.showAll}
                    user={this.props.user}
                    status="best"
                  />
                  {this.state.chunks.ok && this.state.chunks.ok.length > 0 && (
                    <SemChunkList
                      data={this.state.chunks.ok || []}
                      title={"More inspiration"}
                      onSelectCallback={this.onSelectItem}
                      showAll={this.state.showAll}
                      user={this.props.user}
                      status="try"
                    />
                  )}
                  {this.state.chunks.bad &&
                    this.state.chunks.bad.length > 0 && (
                      <SemChunkList
                        data={this.state.chunks.bad || []}
                        title={"Weak bets"}
                        onSelectCallback={this.onSelectItem}
                        showAll={this.state.showAll}
                        user={this.props.user}
                        status="weak"
                      />
                    )}
                </div>
              )}
              <div className="dn db-ns w-40">
                <div
                  className="box w-100 tc pt0"
                  style={{
                    minHeight: "80vh",
                    minWidth: "410px",
                  }}
                >
                  {tests && (
                    <>
                      <h3
                        className={cx({
                          "bb f5 b--black-05 pv2 ph3 mb2 tl": true,
                          pointer: tests.length > 0,
                        })}
                        onClick={this.showLPs}
                      >
                        {tests.length > 0 && (
                          <>
                            Landing page ({tests.length}{" "}
                            {tests.length !== 1 ? "tests" : "test"}){" "}
                            <img
                              src={!this.state.showTests ? DownArrow : UpArrow}
                              className="icon"
                              alt="Expand"
                              style={{ marginBottom: "-3px" }}
                            />
                          </>
                        )}
                        {(!tests.length || tests.length === 0) && (
                          <>Landing page</>
                        )}
                      </h3>
                      {this.state.showTests && (
                        <>
                          <div className="f6 pv2 tl i ph3">
                            Landing page tests from related keywords.
                          </div>
                          <div className="pa3 tl">
                            {tests.map(d => (
                              <Link
                                className="mb3 f6 ui-link db"
                                to={`/home/${this.props.match.params.company}/screenshots/${d.id}`}
                              >
                                {d.type === "desktop" && (
                                  <span>
                                    <img
                                      src={DesktopIcon}
                                      className="mr2"
                                      width="20px"
                                      alt="Desktop"
                                      style={{ marginBottom: "3px" }}
                                    />{" "}
                                  </span>
                                )}
                                {d.type === "mobile" && (
                                  <span>
                                    <img
                                      src={MobileIcon}
                                      className="mr2"
                                      width="20px"
                                      alt="Mobile"
                                      style={{ marginBottom: "3px" }}
                                    />
                                  </span>
                                )}
                                <span className="page-tag ml1">
                                  {d.headline_tag}
                                </span>{" "}
                                &middot;{" "}
                                {getMonthText(d.start_date, d.end_date)}
                              </Link>
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="ph2 pv3">
                    {this.state.selectedSSImg && (
                      <img
                        src={this.replaceSSData(
                          imageUrl(this.state.selectedSSImg, 400, 0)
                        )}
                        style={{ maxWidth: "400px" }}
                        alt="landing page"
                      />
                    )}
                    {!this.state.selectedSSImg && (
                      <div className="ph5 pv4 tc">
                        <div>
                          <img
                            src={SadPage}
                            height="48px"
                            width="48px"
                            className="mb3"
                            alt="Page not found"
                          />
                        </div>
                        <span className="b">
                          Landing page screenshot is currently unavailable.
                        </span>
                        <br />
                        <br />
                        {this.state.selectedLP &&
                          this.state.selectedLP.indexOf("google.com") ===
                            -1 && (
                            <a
                              href={this.state.selectedLP}
                              title={this.state.selectedLP}
                            >
                              See the live version of this landing page.
                            </a>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <MovieFone
          user={this.props.user}
          company={this.props.match.params.company}
        />
      </>
    );
  }
}

export default ViewSem;
