import React, { Component } from "react";
import { fbAuth } from "../../util";
import * as querystring from "query-string";
import Success from "../../icons/old/champion.svg";
import { Redirect } from "react-router-dom";
class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      error: null,
      success: false,
      code: querystring.parse(window.location.search).oobCode,
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    const that = this;
    this.setState({ error: null });
    fbAuth
      .confirmPasswordReset(this.state.code, this.state.password)
      .then(() => {
        that.setState({ success: true });
        setTimeout(() => {
          this.setState({ doRedir: true });
        }, 2000);
      })
      .catch(function (error) {
        that.setState({ error: error });
      });
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    let url = querystring.parse(window.location.search).continueUrl;
    if (this.state.doRedir) {
      window.location.href = url ? url : "https://app.dowhatworks.io/login";
    }
    const inputClass = "pa2 input-reset w-80 ba b--black-20 bg-white br2";

    const { error, code } = this.state;
    return (
      <main className="pv4">
        <div
          className="box mw6 center pa3 br2 flex flex-wrap bg-white tl"
          style={{ position: "relative" }}
        >
          <h1>Change Your Password</h1>
          {!code && (
            <p className="error">Hmm... This url doesn't look right.</p>
          )}
          {error && <p className="dark-red">{error.message}</p>}
          <form onSubmit={this.handleSubmit} className="w-100">
            {this.state.success && (
              <div className="pt2">
                <div className="w-80">
                  <img
                    src={Success}
                    style={{
                      width: "48px",
                      height: "48px",
                      position: "absolute",
                      right: "48px",
                      marginTop: "60px",
                    }}
                    alt="success"
                    className="moviefone-success"
                  />
                  <div className="dark-green b">
                    You password has been changed. We're redirecting you to the
                    login page.
                  </div>
                </div>
              </div>
            )}
            <div className="mt3">
              <input
                type="password"
                onChange={this.handleKeyUp}
                name="password"
                className={inputClass}
                placeholder="New password"
              />
            </div>
            <div className="mt4">
              <input
                type="submit"
                value="Change your password"
                className="ui-button primary"
              />
            </div>
          </form>
        </div>
      </main>
    );
  }
}

export default Password;
