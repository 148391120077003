import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl, handleError, addCommas, formatDate } from "../../util";
import cx from "classnames";

const times = [
  { name: "7 days", value: 7 },
  { name: "30 days", value: 30 },
];

class QueueDraft extends React.Component {
  state = {
    items: [],
    queues: {},
    loading: true,
    time: 7,
    view: "stats",
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/queue_dashboard/2?time=${this.state.time}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            error: true,
          });
          return;
        }
        const json = JSON.parse(data);
        const customerData = {
          last7: [],
          prev7: [],
          alert: [],
        };

        Object.keys(json.data.alert.customers).forEach(c => {
          const customer = json.customers[c];

          if (customer) {
            customerData.alert.push({
              name: customer.name,
              count: Number.parseInt(json.data.alert.customers[c]),
              called_count: Number.parseInt(
                json.data.alert.customers_called[c]
              ),
              key: c,
              sent_time: customer.sent_time,
              no_alert: customer.no_alert,
            });
          }
        });

        customerData.last7.sort((a, b) =>
          a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
        );

        customerData.prev7.sort((a, b) =>
          a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
        );
        customerData.alert.sort((a, b) =>
          a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
        );

        this.setState({
          loading: false,
          data: json.data,
          queues: json.queues,
          customers: json.customers,
          customers_called: json.customers_called,
          customerData,
        });
      }
    );
  };
  showComps = customer => e => {
    e.preventDefault();
    this.setState({ selectedCust: customer });
  };
  doChange = e => {
    this.setState({ time: e.currentTarget.value }, () => {
      this.getData();
    });
  };
  changeView = view => e => {
    e.preventDefault();
    this.setState({ view });
  };
  render() {
    const { data, view, customerData, queues } = this.state;
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.state.error) {
      return (
        <div className="mt3 box pa3">
          <h1 className="red">Looks like something went wrong.</h1>
          <p>Please try again in a few minutes.</p>
        </div>
      );
    }
    const first = data.last7;
    const second = data.prev7;
    return (
      <div>
        <h1>Content Dashboard</h1>
        <div className="pv3">
          <select name="time" onChange={this.doChange} value={this.state.time}>
            <option value="7">7 days</option>
            <option value="30">30 days</option>
          </select>
          <div className="pl3 dib">
            <div className="dib mr3 pr3 br b--black">
              {view === "stats" ? (
                <span>Stats</span>
              ) : (
                <a href=" #" onClick={this.changeView("stats")}>
                  Stats
                </a>
              )}
            </div>
            <div className="dib mr3 pr3 br b--black">
              {view === "customers" ? (
                <span>Published tests by customer</span>
              ) : (
                <a href=" #" onClick={this.changeView("customers")}>
                  Published tests by customer
                </a>
              )}
            </div>
            <div className="dib mr3 pr3">
              {view === "companies" ? (
                <span>Published tests by company</span>
              ) : (
                <a href=" #" onClick={this.changeView("companies")}>
                  Published tests by company
                </a>
              )}
            </div>
          </div>
        </div>
        {view === "stats" && (
          <div className="mt3 box">
            <div className="pa3 flex">
              <div className="w-50 pr3">
                <h3>Last {this.state.time} days</h3>
                <table className="f4">
                  <tr>
                    <td className="pv3 pr3">
                      <b>Queue items processed</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(first.processed)}</td>
                  </tr>
                  <tr>
                    <td className="pv3 pr3">
                      <b>Screener-created tests</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(first.created)}</td>
                  </tr>
                  <tr>
                    <td className="pv3 pr3">
                      <b>Tests published</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(first.published)}</td>
                  </tr>
                  <tr>
                    <td className="pv3 pr3">
                      <b>Tests called</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(first.called)}</td>
                  </tr>
                </table>
              </div>
              <div className="w-50 pr3">
                <h3>Previous {this.state.time} days</h3>
                <table className="f4">
                  <tr>
                    <td className="pv3 pr3">
                      <b>Queue items processed</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(second.processed)}</td>
                  </tr>
                  <tr>
                    <td className="pv3 pr3">
                      <b>Screener-created tests</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(second.created)}</td>
                  </tr>
                  <tr>
                    <td className="pv3 pr3">
                      <b>Tests published</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(second.published)}</td>
                  </tr>
                  <tr>
                    <td className="pv3 pr3">
                      <b>Tests called</b>
                    </td>
                    <td className="pv3 pl3">{addCommas(second.called)}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="pa3">
              <h3>Current State of Publishing Queues</h3>
              <div className="flex">
                <div className="w-50">
                  <table className="f4">
                    <tr>
                      <td className="pv3 pr3">
                        <b>Draft</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(queues.draft)}</td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Screener Feedback</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.screener_feedback)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Writer Feedback</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.analyst_feedback)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Approval</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(queues.approval)}</td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Publishing</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.publishing)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Validation</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.validation)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Live Test</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.live_check)}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="w-50 pr3">
                  <table className="f4">
                    <tr>
                      <td className="pv3 pr3">
                        <b>Hold</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(queues.hold)}</td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Edit</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(queues.edit)}</td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Called Tests</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(queues.called)}</td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>&middot; Called Tests 30 Days</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.called30Days)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>&middot; Called Tests 60 Days</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.called60Days)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pv3 pr3">
                        <b>Called Tests to Fix</b>
                      </td>
                      <td className="pv3 pl3">
                        {addCommas(queues.called_rejected)}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {view === "customers" && (
          <div className="mt2 box pa3 flex">
            <div className="w-100 pr3">
              <h3>Published tests by customer</h3>
              <table className="f4 w-100">
                <thead>
                  <th></th>
                  <th className="f6 pl3 dww-gray">Published/Called</th>
                </thead>
                <tbody>
                  {customerData.alert.map(customer => {
                    return (
                      <tr className="bb">
                        <td className="pv3 pr3">
                          <b>{customer.name}</b>
                          <div className="f7">
                            Last alert:{" "}
                            {formatDate(new Date(customer.sent_time))}
                          </div>
                          {customer.no_alert && (
                            <div className="f7">
                              <b>No A/B alert</b>
                            </div>
                          )}
                        </td>
                        <td
                          className={cx("pv3 pl3 v-top", {
                            red: customer.count + customer.called_count < 7,
                            green: customer.count + customer.called_count >= 7,
                          })}
                        >
                          {addCommas(customer.count)}/
                          {addCommas(
                            data.alert.customers_called[customer.key] || 0
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {view === "companies" && (
          <div className="mt2 box pa3 flex">
            <div className="w-50 pr3">
              <h3>Last {this.state.time} days</h3>
              <table className="f4">
                {data.last7.companies.map(c => {
                  return (
                    <tr>
                      <td className="pv3 pr3">
                        <b>{c.name}</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(c.count)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="w-50">
              <h3>Previous {this.state.time} days</h3>
              <table className="f4">
                {data.prev7.companies.map(c => {
                  return (
                    <tr>
                      <td className="pv3 pr3">
                        <b>{c.name}</b>
                      </td>
                      <td className="pv3 pl3">{addCommas(c.count)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default QueueDraft;
