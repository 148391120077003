import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import SadComputer from "../icons/old/sadpage.svg";

class AccessDenied extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const width = 600;
    const height = 300;

    return (
      <>
        <Modal open={true}>
          <div
            className="box tc"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              overflow: "hidden",
              top: (window.innerHeight - height) / 2,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
              outline: 0,
            }}
          >
            <div className="pa4">
              <img src={SadComputer} style={{ width: "60px" }} />
              <p className="f3 tc pt4">
                Looks like you don't have permission to access this.{" "}
              </p>
              <p className="f3 tc">
                If you want access, please{" "}
                <a href="mailto:andres@dowhatworks.io">email us</a>.
              </p>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default AccessDenied;
