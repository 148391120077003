import React from "react";
import { Link } from "react-router-dom";
import * as querystring from "query-string";
import * as cx from "classnames";
import * as cloneDeep from "lodash.clonedeep";
import request from "request";
import "./dashboard.css";
import {
  loginLookup,
  getData,
  baseApiUrl,
  addCommas,
  isDemoComp,
} from "../../util";
import FakeDashboard from "../../components/FakeDashboard";
import DashboardHeader from "../../components/DashboardHeader";
import InfoPopup from "../../components/InfoPopup";
import AccessDenied from "../../components/AccessDenied";
import MovieFone from "../../components/MovieFone";
import TagFilter from "../../components/TagFilter";
import KeywordGroups from "../../components/KeywordGroups";
import CompaniesIcon from "../../icons/companies_gray.svg";
import GroupsIcon from "../../icons/keywords_gray.svg";
import KeywordIcon from "../../icons/keyword.svg";
import BlueArrowIcon from "../../icons/blue_arrow.svg";
import DownloadIcon from "../../icons/Download.svg";
import X from "../../icons/tinyX.svg";
import Help from "../../icons/old/help_outlined.svg";
import XIcon from "../../icons/x.svg";
import HeadlineImg from "../../img/headline.png";
import Modal from "@material-ui/core/Modal";
import UploadCSV from "../../components/form/UploadCSV";

const Chunk = ({ i, hl, multi, badOnly }) => {
  let odd;

  if (multi) {
    odd = false;
  } else {
    odd = i % 2 !== 0;
  }
  return (
    <div
      className={cx({
        "pa3 flex": true,
        "bg-stripe": odd,
      })}
      style={{ width: "350px" }}
      title={`${hl.company.name} (${hl.sov})`}
    >
      <div>
        {badOnly ? (
          <div className="place-circle bg-dark-red white items-center">
            <div className="place-circle-number">&nbsp;</div>
          </div>
        ) : (
          <>
            {hl.status === "best" && (
              <div className="place-circle bg-green white items-center">
                <div className="place-circle-number">&nbsp;</div>
              </div>
            )}
            {hl.status === "ok" && (
              <div className="place-circle bg-light-green white items-center">
                <div className="place-circle-number">&nbsp;</div>
              </div>
            )}
            {hl.status === "borderline" && (
              <div className="place-circle bg-yellow white items-center">
                <div className="place-circle-number">&nbsp;</div>
              </div>
            )}
            {(hl.status === "worst" || hl.status === "bad") && (
              <div className="place-circle bg-dark-red white items-center">
                <div className="place-circle-number">&nbsp;</div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="pl3">{hl.display || hl.text}</div>
    </div>
  );
};

class SemDashboard extends React.Component {
  qs = null;
  getCachedQS = arg => {
    if (this.qs === null) {
      this.qs = querystring.parse(window.location.search);
    }
    if (arg) {
      if (this.qs[arg]) {
        return this.qs[arg].split("|");
      }
      return undefined;
    }
    return this.qs;
  };
  did_hash = false;
  state = {
    loading: true,
    csv_loading: false,
    counts: undefined,
    percents: [],
    hint: false,
    openModal: false,
    openEditModal: false,
    editSuccess: false,
    show_all_ab: false,
    show_all_sem: false,
    saved_tests: [],
    good_data: {},
    customer_data: {},
    first: false,
    sem_tlds: [],
    data: {},
    sem_filter_tlds: this.getCachedQS("companies") || [],
    chunks: [],
    keywords: [],
    sorted_kws: [],
    kw_groups: [],
    kws_filter: this.getCachedQS("keywords") || [],
    edit_priority: this.getCachedQS().editPriority || false,
    comp_lookup: {},
    total_sem_count: 0,
    total_sem_test_count: 0,
    total_unique_sem_count: 0,
    start: 0,
  };
  inputRef = React.createRef();
  foundFirst = false;
  triggerFoundTwice = false;
  canvasOptions = {};
  addStorage = () => {
    const { sem_filter_tlds, kws_filter } = this.state;

    const encodeAndJoin = arr =>
      arr
        .filter(a => a)
        .map(a => encodeURIComponent(a))
        .join("|");
    const urlArr = [];

    if (sem_filter_tlds && sem_filter_tlds.length > 0) {
      urlArr.push(`companies=${encodeAndJoin(sem_filter_tlds)}`);
    }

    if (kws_filter && kws_filter.length > 0) {
      urlArr.push(`keywords=${encodeAndJoin(kws_filter)}`);
    }
    const urlParams = urlArr.length > 0 ? `?${urlArr.join("&")}` : "";
    if (urlParams !== this.state.urlParams) {
      this.setState({ urlParams }, () => {
        this.props.history.push(
          `/home/${this.props.match.params.company}/sem_copy${urlParams}`
        );
      });
    } else {
      this.props.history.push(
        `/home/${this.props.match.params.company}/sem_copy${urlParams}`
      );
    }
  };
  filterSemByTldChunks = () => {
    const { keywords, kws_filter, sorted_kws } = this.state;

    const kws = sorted_kws.map(z => z.term);
    if (!kws_filter || kws_filter.length === 0) {
      return {
        filtered_keywords: kws,
      };
    }

    const filtered_keywords = kws.filter(term => kws_filter.includes(term));
    return { filtered_keywords };
  };
  addSemFilter = values => {
    const sem_filter_tlds = values.map(v => v.value);
    const { id } = querystring.parse(window.location.search);
    const report = id ? id : loginLookup[this.props.match.params.company];
    if (this.props.user.token) {
      request({
        url: `${baseApiUrl}/v1/t?url=${
          window.location.href
        }%3Fsem_companies=${sem_filter_tlds.join("%26")}`,
        headers: {
          Authorization: this.props.user.token,
        },
      });
    }
    this.setState(
      {
        sem_filter_tlds,
      },
      () => {
        this.addStorage(`${report}-dashboard-sem-company`, values);
      }
    );
  };
  makeAdGroupFilter = values => {
    const kws = [];

    values.forEach(v => {
      if (v.group) {
        v.value.forEach(k => {
          if (!kws.includes(k)) {
            kws.push(k);
          }
        });
      } else {
        kws.push(v.value);
      }
    });

    return kws;
  };
  addAdGroupFilter = values => {
    const report = this.state.reportId;
    const kws = this.makeAdGroupFilter(values);

    this.setState(
      {
        kws_filter: kws,
        init_kws_filter: values,
      },
      () => {
        this.addStorage(`${report}-dashboard-sem-kws`, values);
      }
    );
  };
  doHash = () => {
    if (this.state.loading) {
      return;
    }
    window.setTimeout(() => {
      if (window.location.hash) {
        const elm = document.getElementById(window.location.hash.split("#")[1]);
        if (elm) {
          window.scrollTo(0, elm.offsetTop - 90);
        }
      }
      this.did_hash = true;
    }, 200);
  };
  getData = shouldSkipCache => {
    const { id, newCall, byMonth } = querystring.parse(window.location.search);
    let report = null;

    if (id) {
      report = id;
    }
    const apiUrl = `${baseApiUrl}/v1/sem_dashboard/new?reportId=${this.props.match.params.company}`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      false,
      true,
      shouldSkipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 400) {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          this.props.history.push(
            `/subscribe/${this.props.match.params.company}`
          );
          return;
        }
        this.setState({
          loading: true,
          authed: false,
          accessDenied: res.statusCode === 400,
        });
        return;
      }

      const kws = Object.keys(data.customer_data || {});

      const keywords = this.getCachedQS("keywords");

      let init_kws_filter;

      if (keywords) {
        init_kws_filter = keywords.map(k => ({ label: k, value: k }));
      }

      const sortedChunks = [];

      let count = 0;

      this.setState({
        loading: false,
        init_kws_filter: init_kws_filter || [],
        saved_tests: data.saved_tests,
        report: report,
        customer: data.customer,
        editSuccess: false,
        openEditModal: false,
        customer_data: data.customer_data,
        good_data: data.good_data,
        keywords: data.keywords,
        sorted_kws: data.keywords.map((k, i) => ({ term: k, place: i })),
      });
    });
  };
  showAllSem = e => {
    e.preventDefault();
    this.setState({
      show_all_sem: true,
    });
  };
  showModal = e => {
    e.preventDefault();
    this.setState({ modal: true });
  };
  closeModal = e => {
    e.preventDefault();
    this.setState({ modal: false });
  };
  closeEditModal = e => {
    this.setState({ openEditModal: false });
  };
  componentDidMount() {
    this.getData();
  }
  removeTag = f => e => {
    e.preventDefault();
    const { sem_filter_tlds, kws_filter, init_kws_filter } = this.state;
    if (f.type === "company") {
      const new_sem_filter_tlds = sem_filter_tlds.filter(c => c !== f.value);

      this.setState(
        {
          sem_filter_tlds: new_sem_filter_tlds,
          init_sem_filter_tlds: new_sem_filter_tlds,
        },
        () => {
          this.addStorage();
        }
      );
    }
    if (f.type === "keyword") {
      const new_kws_filter = kws_filter.filter(c => c !== f.value);

      this.setState(
        {
          kws_filter: new_kws_filter,
          init_kws_filter: new_kws_filter.map(kw => ({ label: kw, value: kw })),
        },
        () => {
          this.addStorage();
        }
      );
    }
    if (f.type === "group") {
      const new_kws_filter = kws_filter.filter(c => !f.value.includes(c));
      const new_init_kws_filter = init_kws_filter.filter(c => c.id !== f.id);

      this.setState(
        {
          kws_filter: new_kws_filter,
          init_kws_filter: new_init_kws_filter,
        },
        () => {
          this.addStorage();
        }
      );
    }
  };

  render() {
    const { loading, reportId, good_data, customer_data } = this.state;

    const { filtered_keywords } = this.filterSemByTldChunks();

    const displayFilters = [];
    if (this.state.init_kws_filter) {
      this.state.init_kws_filter.forEach(kw => {
        if (kw.group) {
          displayFilters.push({
            label: kw.label,
            value: kw.value,
            type: "group",
            id: kw.id,
          });
        } else {
          displayFilters.push({
            label: kw.label,
            value: kw.value,
            type: "keyword",
          });
        }
      });
    }

    if (loading) {
      return (
        <>
          <FakeDashboard company={this.props.match.params.company} isSEM />
          {this.state.accessDenied && <AccessDenied />}
        </>
      );
    }
    return (
      <div className="mb4">
        <DashboardHeader
          customer={this.props.match.params.company}
          name={this.state.customer ? this.state.customer.name : null}
          customerData={this.state.customer}
          selected="sem"
        />
        <div
          className="header-shadow"
          style={{ position: "sticky", top: "65px", background: "#fff" }}
        >
          <div className="pl3 b--dww filter-container omnifilter-container document-spacing center">
            <div className="flex">
              <div
                style={{
                  marginLeft: "auto",
                }}
              >
                {this.state.keywords && (
                  <KeywordGroups
                    keywords={this.state.keywords}
                    callback={this.addAdGroupFilter}
                    token={this.props.user.token}
                    options={this.state.kw_groups}
                    defaultOptions={this.state.init_kws_filter}
                    reportId={this.state.customer.report}
                    isAdmin={this.props.user.company === "admin"}
                    editPriority={this.state.edit_priority}
                  />
                )}
              </div>
            </div>
          </div>
          {displayFilters && displayFilters.length > 0 && (
            <div
              className="ph3 b--dww filter-container omnifilter-container center"
              style={{ borderTop: "1px solid #ccced8" }}
            >
              <div className="center flex pl3 pr2 document-spacing">
                {displayFilters.slice(0, 5).map(f => {
                  let icon;

                  if (f.type === "company") {
                    icon = CompaniesIcon;
                  } else if (f.type === "keyword") {
                    icon = KeywordIcon;
                  } else if (f.type === "group") {
                    icon = GroupsIcon;
                  }
                  return (
                    <div
                      className="f-tag"
                      key={`${f.type}-${f.id ? f.id : f.value}`}
                    >
                      <span className="f-tag-x" onClick={this.removeTag(f)}>
                        <img src={X} alt="remove" />
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          maxWidth: "100px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        title={f.label}
                      >
                        {f.label}
                      </span>
                      {icon && (
                        <img src={icon} className="f-tag-icon" alt={f.type} />
                      )}
                    </div>
                  );
                })}
                {displayFilters.length > 5 && (
                  <div className="f-tag f-tag-more">
                    +{displayFilters.length - 5} more
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt4 document-spacing center">
          <div
            className="mt4 box pb2"
            style={{ minHeight: "600px" }}
            id="winning-headlines"
          >
            <div className="bb b--dww pv2 ph3">
              <div className="pv2 dashbox-pad flex">
                <div>
                  <div className="f3 dash-head">SEM Copy Optimization</div>
                  <div className="f5 dww-gray">
                    We've taken your <b className="dark-red">worst</b>{" "}
                    performing headlines by keyword and given you inspiration
                    from the <b className="green">best</b> performing headlines
                    from competitors.
                  </div>
                </div>
                <div className="pt3 tr" style={{ flexGrow: "1" }}>
                  <a href=" #" onClick={this.showModal}>
                    <img src={Help} />
                  </a>
                </div>
              </div>
            </div>
            {filtered_keywords && (
              <div className="ma3">
                {filtered_keywords.map((term, z) => {
                  const cust_obj = customer_data[term];
                  let good_obj, good_obj2;
                  if (good_data[term]) {
                    good_obj = good_data[term];
                  }
                  if (
                    Object.keys(customer_data[term] || {}).length === 0 &&
                    Object.keys(good_data[term] || {}).length === 0
                  ) {
                    return <></>;
                  }
                  let displayTerm = term;
                  if (term.includes("||")) {
                    const termArr = term.split("||");
                    displayTerm = `${termArr[0]} (${termArr[1]})`;
                  }
                  return (
                    <div className="ph2 mb4" key={`obj-${term}-${z}`}>
                      <h3
                        className="f3 dash-head"
                        style={{ textTransform: "capitalize" }}
                      >
                        <span className="q lq">&ldquo;</span>
                        {displayTerm}
                        <span className="q rq">&rdquo;</span>
                      </h3>
                      <div
                        className="flex scroller"
                        style={{ overflow: "auto" }}
                      >
                        <div
                          className="mr3 mb3 box ui-link db"
                          style={{ minWidth: "350px", width: "350px" }}
                        >
                          <div className="f4 pa3 bb b--black-05 mb2 pb2">
                            Your worst headlines
                          </div>
                          {cust_obj && (
                            <>
                              {cust_obj.map((hl, i) => (
                                <Chunk
                                  hl={hl}
                                  i={i}
                                  badOnly
                                  key={`bad-${term}-${z}-${i}`}
                                />
                              ))}
                            </>
                          )}
                          {(!cust_obj || cust_obj.length === 0) && (
                            <div className="pa3 dww-gray">
                              {!cust_obj ? (
                                <>Your ad volume on this keyword is low.</>
                              ) : (
                                <>
                                  You don't have underperforming headlines on
                                  this keyword.
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="mr3 mb3 box ui-link db"
                          style={{ minWidth: "700px", width: "700px" }}
                        >
                          <div className="f4 pa3 bb b--black-05 mb2 pb2">
                            Best bets from competitors
                          </div>
                          <div className="flex flex-wrap">
                            {good_obj && (
                              <>
                                {good_obj.map((hl, i) => (
                                  <Chunk
                                    hl={hl}
                                    i={i}
                                    multi
                                    key={`good-${term}-${z}-${i}`}
                                  />
                                ))}
                              </>
                            )}

                            {!good_obj && (
                              <div className="pa3 dww-gray">
                                There are no best bets on this keyword.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {this.state.modal && (
          <Modal open={true}>
            <div
              className="box"
              style={{
                width: `40vw`,
                top: "10vh",
                position: "absolute",
                left: "30vw",
                overflow: "auto",
              }}
            >
              <img
                src={XIcon}
                className="pointer modal-close"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                }}
                onClick={this.closeModal}
              />
              <div className="pa3 bb b--black-05">
                <div className="f3 dash-head">How does this work?</div>
              </div>
              <div className="pa3">
                <p>Search ads are made up of 2 to 3 headlines.</p>
                <div className="tc pb3">
                  <img
                    src={HeadlineImg}
                    style={{ width: "80%" }}
                    className="br4"
                  />
                </div>
                <p>
                  Headlines are ranked by likelihood to resonate by keyword.
                </p>
                <p>
                  Use the top headlines for inspiration when writing ad copy and
                  product headlines.{" "}
                </p>

                <div className="tc mt4">
                  <a
                    href=" #"
                    className="ui-button primary"
                    onClick={this.closeModal}
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default SemDashboard;
