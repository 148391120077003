import React from "react";
import * as querystring from "query-string";
import throttle from "lodash.throttle";
import { baseApiUrl, getData } from "../../util";
import "./screenshots.css";
import Loading from "../../components/Loading";
import CarouselNewMobile from "../../components/CarouselNewMobile";
import CarouselNew from "../../components/CarouselNew";
import AccessDenied from "../../components/AccessDenied";

class ViewScreenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: { screenshots: [] },
      company: this.props.match.params.company,
      customer: null,
      zoomedOut: false,
      loadedSavedTests: true,
      useMobile: this.useMobileComponent(),
    };
  }
  refreshSavedTests = savedTests => {
    this.setState({ loadedSavedTests: false }, () => {
      this.setState({ savedTests, loadedSavedTests: true });
    });
  };
  collectionSwitcher = val => {
    if (val === "all") {
      this.props.history.push(
        `/home/${this.props.match.params.company}/report`
      );
    } else {
      window.location = `/home/${this.props.match.params.company}/savee_test/${val}`;
    }
  };
  componentDidMount() {
    const { byModule } = querystring.parse(
      window.location.search.replace("?", "")
    );
    window.addEventListener("resize", this.resizeListener);
    getData({
      url: `${baseApiUrl}/v1/screenshots?id=${this.props.match.params.ssid}&company=${this.props.match.params.company}`,
      headers: {
        Authorization: this.props.user.token,
      },
    }).then(({ err, res, data }) => {
      if (res !== "cache") {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          this.props.history.push(
            `/subscribe/${this.props.match.params.company}`
          );
          return;
        }
      }
      if (err) {
        this.setState({
          loading: false,
        });
      }

      data.screenshots.screenshots.sort((a, b) => (a.champ ? -1 : 1));

      let varNum = 0;
      data.screenshots.screenshots.forEach(s => {
        if (!s.control) {
          varNum += 1;
          s.varNum = varNum;
        }
      });

      this.setState({
        loading: false,
        screenshots: data.screenshots,
        savedTests: data.savedTests,
        allTests: data.allTests,
        customer: data.customer,
        loadedSavedTests: true,
        byModule: byModule === "true" ? true : false,
        analyses: data.analyses,
      });
    });

    document.body.style = "overflow:hidden!important";
  }
  resizeListener = throttle(() => {
    this.setState({ useMobile: this.useMobileComponent() });
  });
  useMobileComponent = () => {
    let width = window.visualViewport
      ? window.visualViewport.width
      : window.innerWidth;
    return width < 800;
  };
  componentWillUnmount() {
    document.body.style = "";
    window.removeEventListener("resize", this.resizeListener);
  }
  closeModal = e => {
    e.stopPropagation();
    e.preventDefault();
    let url;
    if (this.props.urlParams) {
      if (this.props.newPage) {
        url = `/home/${this.props.match.params.company}/new/report${this.props.urlParams}`;
      } else {
        url = `/home/${this.props.match.params.company}/report${this.props.urlParams}`;
      }
    } else {
      if (this.props.newPage) {
        url = `/home/${this.props.match.params.company}/new/report${this.props.urlParams}`;
      } else {
        url = this.props.isSavedTests
          ? `/home/${this.props.match.params.company}/saved_test/${this.props.collectionId}`
          : `/home/${this.props.match.params.company}/report`;
      }
    }
    this.props.history.push(url);
  };
  watchTestCallback = is_watching => {
    this.setState(
      (prevState, props) => {
        return {
          screenshots: {
            ...prevState.screenshots,
            is_watching,
          },
        };
      },
      () => {
        this.props.watchTestCallback(this.state.screenshots.id, is_watching);
      }
    );
  };
  render() {
    if (
      this.props.user.company !== "admin" &&
      this.props.user.company !== this.props.match.params.company
    ) {
      return <AccessDenied />;
    }
    if (this.state.loading) {
      return <Loading />;
    }

    const { screenshots } = this.state;

    if (!screenshots || !screenshots.screenshots) {
      return <Loading />;
    }
    return (
      <>
        {screenshots && screenshots.screenshots.length > 0 ? (
          <>
            {screenshots.screenshots[0].notes &&
            screenshots.screenshots[0].notes.length > 0 ? (
              <>
                {this.state.useMobile ? (
                  <CarouselNewMobile
                    images={screenshots.screenshots}
                    url={screenshots.url}
                    screenshots={screenshots}
                    company={this.props.match.params.company}
                    customer={this.state.customer}
                    user={this.props.user}
                    id={this.props.match.ssid}
                    byModule={this.state.byModule}
                    reportId={this.state.customer.report}
                    savedTests={this.state.savedTests}
                    analyses={this.state.analyses}
                    addedTests={this.state.allTests.map(x => x.id)}
                    savedTestsCallback={data => {
                      this.refreshSavedTests(data);
                      setTimeout(() => {
                        this.props.testsCallback(false, false, true);
                      }, 250);
                    }}
                    isSavedTests={this.props.isSavedTests}
                    collectionId={this.props.collectionId}
                    didScroll={window.scrollY > 60}
                    urlParams={this.props.location.search}
                    collections={this.state.allTests}
                    newPage={this.props.newPage}
                    watchTestCallback={this.watchTestCallback}
                    history={this.props.history}
                    showShare={this.props.showShare}
                  />
                ) : (
                  <>
                    <div className="ss-modal-wrapper" />
                    <div
                      className="mt3 center"
                      onClick={this.closeModal}
                      id="ss-modal-scroller"
                    >
                      <div
                        className="modal-scroll-inner"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <CarouselNew
                          images={screenshots.screenshots}
                          url={screenshots.url}
                          screenshots={screenshots}
                          company={this.props.match.params.company}
                          customer={this.state.customer}
                          user={this.props.user}
                          id={this.props.match.params.ssid}
                          byModule={this.state.byModule}
                          reportId={this.state.customer.report}
                          savedTests={this.state.savedTests}
                          analyses={this.state.analyses}
                          addedTests={this.state.allTests.map(x => x.id)}
                          savedTestsCallback={data => {
                            this.refreshSavedTests(data);
                          }}
                          isSavedTests={this.props.isSavedTests}
                          collectionId={this.props.collectionId}
                          didScroll={window.scrollY > 60}
                          urlParams={this.props.location.search}
                          collections={this.state.allTests}
                          newPage={this.props.newPage}
                          watchTestCallback={this.watchTestCallback}
                          history={this.props.history}
                          showShare={this.props.showShare}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Loading />
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

export default ViewScreenshots;
