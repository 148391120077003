import React, { useEffect, useLayoutEffect, useState } from "react";
import request from "request";
import Loading from "../../components/Loading";
import {
  baseApiUrl,
  getData,
  formatDateAndTime,
  capitalize,
  cleanStr,
} from "../../util";
import { Link } from "react-router-dom";
import ToggleOn from "../../icons/ToggleOn.svg";
import ToggleOff from "../../icons/ToggleOff.svg";
import ToggleDisabled from "../../icons/ToggleDisabled.svg";
import UpArrow from "../../icons/up_arrow.svg";
import DownArrow from "../../icons/down_arrow.svg";
import EditIcon from "../../icons/edit_gray.svg";
import * as cx from "classnames";

const TagRow = props => {
  const { tag, index, user, onUpdateTag, onEditTag } = props;
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(tag.approved);
  const [isBaseTag, setIsBaseTag] = useState(tag.base_tag);
  const [restrictedAutoComplete, setRestrictedAutocomplete] = useState(
    tag.restrict_autocomplete
  );
  const [expanded, setExpanded] = useState(false);
  const [nextTags, setNextTags] = useState(tag.next_tags || []);
  const [showAddTagInput, setShowAddTagInput] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const updateTagApproved = (id, approved = null) => {
    if (loading) return;
    setLoading(true);
    let data = {
      id,
    };
    if (approved != null) {
      data.approved = approved;
    } else {
      data.approved = false;
    }
    if (isBaseTag !== null) {
      data.base_tag = isBaseTag;
    } else {
      data.base_tag = false;
    }
    updateTagData(data);
  };
  const updateTagBaseTag = (id, baseTag = null) => {
    if (loading) return;
    setLoading(true);
    let data = {
      id,
    };
    if (isApproved !== null) {
      data.approved = isApproved;
    } else {
      data.approved = false;
    }
    if (baseTag !== null) {
      data.base_tag = baseTag;
    } else {
      data.base_tag = false;
    }
    if (data.base_tag) {
      data.approved = true;
    }
    updateTagData(data);
  };
  const updateTagRestrictedAutocomplete = (
    id,
    restrictedAutoComplete = null
  ) => {
    if (loading) return;
    setLoading(true);
    let data = {
      id,
    };
    if (isApproved !== null) {
      data.approved = isApproved;
    } else {
      data.approved = false;
    }
    if (isBaseTag !== null) {
      data.base_tag = isBaseTag;
    } else {
      data.base_tag = false;
    }
    if (restrictedAutoComplete !== null) {
      data.restrict_autocomplete = restrictedAutoComplete;
    } else {
      data.restrict_autocomplete = false;
    }
    updateTagData(data);
  };
  const updateNextTag = (id, nextTag, approved = null) => {
    if (loading || approved === null || !id || !nextTag) return;
    setLoading(true);
    let data = {
      id,
      nextTag,
      approved,
    };
    updateNextTagData(data);
  };
  const updateTagData = data => {
    fetch(`${baseApiUrl}/v1/admin/updateTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: user.token,
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        setLoading(false);
        return response.json();
      })
      .then(body => {
        if (body.tag.approved) {
          setIsApproved(true);
        } else {
          setIsApproved(false);
        }
        if (body.tag.base_tag) {
          setIsBaseTag(true);
        } else {
          setIsBaseTag(false);
        }
        if (body.tag.restrict_autocomplete) {
          setRestrictedAutocomplete(true);
          setExpanded(true);
        } else {
          setRestrictedAutocomplete(false);
          setExpanded(false);
        }
        if (body.tag.next_tags) {
          setNextTags(body.tag.next_tags);
        }
        onUpdateTag(body.tag);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const updateNextTagData = data => {
    fetch(`${baseApiUrl}/v1/admin/updateNextTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: user.token,
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        setLoading(false);
        return response.json();
      })
      .then(body => {
        setNextTags(body.tag.next_tags);
        onUpdateTag(body.tag);
        setNewTag("");
        setShowAddTagInput(false);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const fetchNextTags = tag => e => {
    {
      setExpanded(true);
    }
  };
  const hideNextTags = e => {
    setExpanded(false);
  };
  const setTagEditable = e => {
    console.log(`edited tag`, { tag });
    onEditTag(tag);
  };
  const onClickAddTag = e => {
    setShowAddTagInput(true);
    setNewTag("");
  };
  const onCancelAddTag = e => {
    setShowAddTagInput(false);
    setNewTag("");
  };
  const onChangeNewTag = e => {
    const val = e.target.value;
    setNewTag(val);
  };
  const onSubmitAddTag = e => {
    if (!newTag || newTag.trim() === "") {
      setErrorMessage("tag is empty!");
      return;
    }
    const id = cleanStr(newTag);
    const existingNextTag = nextTags.some(record => record.tagKey === id);
    if (existingNextTag) {
      setErrorMessage("Tag already exist!");
      return;
    }
    updateNextTag(tag.id, newTag, true);
  };
  useLayoutEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  }, [errorMessage]);
  return (
    <>
      <div
        className={cx("w-100 flex justify-between tl pa2", {
          "bg-light-gray": index % 2 == 0,
        })}
      >
        <div className="w-50 tl">
          <a
            href={`/home/admin/report?exactTag=${tag.tag}&exact_tag_search=true`}
            target="_blank"
          >
            {tag.tag}
          </a>
        </div>

        <div className="w-10 tr">
          <img
            src={loading ? ToggleDisabled : isApproved ? ToggleOn : ToggleOff}
            className="toggle-icon"
            alt="Toggle off"
            onClick={() => updateTagApproved(tag.id, !isApproved)}
          />
        </div>

        <div className="w-10 tr">
          <img
            src={loading ? ToggleDisabled : isBaseTag ? ToggleOn : ToggleOff}
            className="toggle-icon"
            alt="Toggle off"
            onClick={() => updateTagBaseTag(tag.id, !isBaseTag)}
          />
        </div>

        <div className="w-10 tr">
          <img
            src={
              loading
                ? ToggleDisabled
                : restrictedAutoComplete
                  ? ToggleOn
                  : ToggleOff
            }
            className="toggle-icon"
            alt="Toggle off"
            onClick={() =>
              updateTagRestrictedAutocomplete(tag.id, !restrictedAutoComplete)
            }
          />
        </div>

        <div className="w-20 tc">
          <img
            src={expanded ? UpArrow : DownArrow}
            className="fr pointer ph1"
            alt="Toggle off"
            onClick={expanded ? hideNextTags : fetchNextTags(tag.id)}
            width="28px"
          />
          <img
            src={EditIcon}
            className="fr pointer ph1"
            alt="Edit Tag"
            onClick={setTagEditable}
            width="28px"
          />
        </div>
      </div>
      {expanded ? (
        <>
          {nextTags.map((record, index) => {
            return (
              <div
                className={cx("w-100 flex justify-between tl pa2 pl4 bb", {
                  "bg-near-white": index % 2 == 0,
                })}
              >
                <div className="w-50 tl">
                  <a
                    href={`/home/admin/report?exactTag=${tag.tag}&nextTag=${record.tag}&exact_tag_search=true`}
                    target="_blank"
                  >
                    {record.tag}
                  </a>
                </div>
                <div className="w-25 tc">
                  <img
                    src={
                      loading || !restrictedAutoComplete
                        ? ToggleDisabled
                        : record.approved
                          ? ToggleOn
                          : ToggleOff
                    }
                    className="toggle-icon"
                    alt="Toggle off"
                    onClick={() =>
                      updateNextTag(tag.id, record.tagKey, !record.approved)
                    }
                  />
                </div>
                <div className="w-25 tr">{record.count}</div>
              </div>
            );
          })}
          <div
            className={cx("w-100 flex justify-between tl pa2 pl4 bb", {
              "bg-near-white": nextTags && nextTags.length % 2 == 0,
            })}
          >
            {showAddTagInput ? (
              <>
                <div className="w-20 tl">
                  <input
                    type="text"
                    name="newTag"
                    value={newTag}
                    onChange={onChangeNewTag}
                  />
                </div>
                {errorMessage && (
                  <>
                    <div className="w-20 red vibration">{errorMessage}</div>
                  </>
                )}
                <div className="w-20 tr">
                  <button
                    className=" ui-button primary"
                    onClick={onSubmitAddTag}
                  >
                    Add
                  </button>
                </div>
                <div className="w-20 tr">
                  <button
                    className=" ui-button secondary"
                    onClick={onCancelAddTag}
                  >
                    cancel
                  </button>
                </div>
              </>
            ) : (
              <div className="w-50 tl">
                <button className=" ui-button primary" onClick={onClickAddTag}>
                  Add new tag
                </button>
              </div>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};
const Tags = props => {
  const { user } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [searchStr, setSearchStr] = useState(null);
  const [filter, setFilter] = useState("all");
  const [showAddTag, setShowAddTag] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [newTagKey, setNewTagKey] = useState("");
  const [newTagApproved, setNewTagApproved] = useState(true);
  const [newTagBaseTag, setNewTagBaseTag] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [editTag, setEditTag] = useState("");
  const [editedTagValue, setEditedTagValue] = useState("");
  const [editTagKey, setEditTagKey] = useState();
  const onUpdateTag = updatedTag => {
    const updatedTags = data.map(record => {
      if (record.id === updatedTag.id) {
        return updatedTag;
      } else {
        return record;
      }
    });
    setData(updatedTags);
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    fetch(`${baseApiUrl}/v1/admin/tagsV2`, {
      method: "GET",
      headers: {
        Authorization: user.token,
      },
    })
      .then(response => response.json())
      .then(json => {
        setData(json.result);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  const updateSearchStr = e => {
    const { value } = e.target;
    setSearchStr(value);
  };
  const onChangeFilter = e => {
    setFilter(e.target.value);
  };
  const TagsHeader = (
    <div className="w-100 flex justify-between tl pa2" key={"header-row"}>
      <div className="w-50 tl b">Tag</div>
      <div className="w-10 tr b">Approved</div>
      <div className="w-10 tr b">Base Tag</div>
      <div className="w-10 tr b">Restrict Autocomplete</div>
      <div className="w-20 tr b"></div>
    </div>
  );
  const filterFn = item => {
    if (filter === "all") {
      return true;
    } else if (filter === "approved") {
      return item.approved;
    } else if (filter === "base") {
      return item.base_tag;
    } else if (filter === "not_base") {
      return !item.base_tag;
    } else if (filter === "restrict_autocomplete") {
      return item.restrict_autocomplete;
    }
  };
  const showAddNewTag = state => e => {
    setShowAddTag(state);
    if (!state) {
      clearForm();
    }
  };
  const onAddTagSubmit = e => {
    if (!newTag || newTag.trim() === "") {
      setErrorMessage("Empty tag!!");
      return;
    }
    setLoading(true);
    const data = {
      tag: newTag,
      approved: newTagApproved,
      base_tag: newTagBaseTag,
    };

    fetch(`${baseApiUrl}/v1/admin/addTag`, {
      method: "POST",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          clearForm();
          getData();
        } else {
          if (json.tagExist) {
            setErrorMessage("Tag Already Exist!");
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  const onEditTagSubmit = e => {
    if (!editedTagValue || editedTagValue.trim() === "") {
      setErrorMessage("Empty tag!!");
      return;
    }
    setLoading(true);
    const data = {
      tag: editedTagValue,
      oldTagKey: editTagKey,
    };

    fetch(`${baseApiUrl}/v1/admin/editTag`, {
      method: "POST",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          clearEditTagForm();
          getData();
        } else {
          setErrorMessage("Error while editing tag!");
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  const onEditTag = tag => {
    setEditedTagValue(tag.tag);
    setEditTagKey(tag.id);
    clearForm();
  };
  const onCheckedApproved = e => {
    const isApprovedCheck = e.target.checked;
    setNewTagApproved(isApprovedCheck);
  };
  const onCheckedBasetag = e => {
    const isBasetagCheck = e.target.checked;
    setNewTagBaseTag(isBasetagCheck);
  };
  const onChangeNewTag = e => {
    const tag = e.target.value;
    setNewTag(tag);
  };
  const onChangeEditTagValue = e => {
    const tag = e.target.value;
    setEditedTagValue(tag);
  };
  const clearForm = () => {
    setShowAddTag(false);
    setNewTag("");
    setNewTagApproved(true);
    setNewTagBaseTag(true);
  };
  const clearEditTagForm = () => {
    setEditedTagValue("");
    setEditTagKey("");
  };
  const onEditTagCancel = e => {
    clearEditTagForm();
  };
  useLayoutEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  }, [errorMessage]);
  return loading && !data ? (
    <Loading />
  ) : (
    <>
      <p className="b f3">Tags</p>
      <div className="pt3 flex">
        <input
          type="text"
          placeholder="search"
          className={cx("ph2 w-70")}
          value={searchStr}
          onChange={updateSearchStr}
        />
        <div className="ph2">
          <select name="filter" onChange={onChangeFilter}>
            <option value="all" selected={searchStr === "all"}>
              All Tags
            </option>
            <option value="approved" selected={searchStr === "approved"}>
              Approved Tags
            </option>
            <option value="base" selected={searchStr === "base"}>
              Base Tags
            </option>
            <option value="not_base" selected={searchStr === "not_base"}>
              All Except base tags
            </option>
            <option
              value="restrict_autocomplete"
              selected={searchStr === "restrict_autocomplete"}
            >
              Tags with restrict autocomplete
            </option>
          </select>
        </div>
      </div>
      <div className="flex flex-column">
        <div
          className="w-100 flex justify-start tl pt3 flex-wrap"
          key={"add-tag-row"}
        >
          {errorMessage && (
            <>
              <div className="w-100 red vibration">{errorMessage}</div>
            </>
          )}
          {showAddTag ? (
            <>
              <div className="w-100">
                <input
                  type="text"
                  value={newTag}
                  onChange={onChangeNewTag}
                  placeholder="enter a new tag"
                  className="mv3"
                />
                <div>
                  <label for="newTagApproved" className="pr2">
                    Approved
                  </label>
                  <input
                    type="checkbox"
                    checked={newTagApproved}
                    onChange={onCheckedApproved}
                    id="newTagApproved"
                  />
                </div>
                <div>
                  <label for="newTagBasetag" className="pr2">
                    Base tag
                  </label>
                  <input
                    type="checkbox"
                    checked={newTagBaseTag}
                    onChange={onCheckedBasetag}
                    id="newTagBasetag"
                  />
                </div>
              </div>
              <button
                className={cx("ui-button primary", {
                  disabled: loading,
                })}
                onClick={onAddTagSubmit}
                disabled={loading}
              >
                Submit
              </button>
              <button
                className="mh3 ui-button secondary "
                onClick={showAddNewTag(false)}
              >
                Cancel
              </button>
            </>
          ) : editTagKey ? (
            <>
              <div className="w-100">
                <h3>Edit tag</h3>
                <input
                  type="text"
                  value={editedTagValue}
                  onChange={onChangeEditTagValue}
                  placeholder="enter a new tag"
                  className="mv3"
                />
                <div>
                  <label for="newTagApproved" className="pr2">
                    Approved
                  </label>
                  <input
                    type="checkbox"
                    disabled
                    checked={newTagApproved}
                    onChange={onCheckedApproved}
                    id="newTagApproved"
                  />
                </div>
                <div>
                  <label for="newTagBasetag" className="pr2">
                    Base tag
                  </label>
                  <input
                    type="checkbox"
                    disabled
                    checked={newTagBaseTag}
                    onChange={onCheckedBasetag}
                    id="newTagBasetag"
                  />
                </div>
              </div>
              <button
                className={cx("ui-button primary", {
                  disabled: loading,
                })}
                onClick={onEditTagSubmit}
                disabled={loading}
              >
                Save
              </button>
              <button
                className="mh3 ui-button secondary "
                onClick={onEditTagCancel}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              className=" ui-button primary"
              onClick={showAddNewTag(true)}
            >
              Add new tag
            </button>
          )}
        </div>
        {TagsHeader}
        {data &&
          data
            .filter(tag => {
              const result = filterFn(tag);
              if (!searchStr) {
                return result;
              }
              if (result && searchStr) {
                if (tag && tag.tag) {
                  return tag.tag
                    .toLowerCase()
                    .startsWith(searchStr.toLowerCase());
                } else {
                  return false;
                }
              } else {
                return true;
              }
            })
            .map((tag, index) => (
              <TagRow
                tag={tag}
                index={index}
                user={user}
                key={`${tag.id}`}
                onUpdateTag={onUpdateTag}
                onEditTag={onEditTag}
              />
            ))}
      </div>
    </>
  );
};

export default Tags;
