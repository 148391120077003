import React from "react";
import request from "request";
import { baseApiUrl, companyNames, formatDate } from "../../util";
import TextInput from "../../components/form/TextInput";

class AddScreenshots extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  state = {
    company: Object.keys(companyNames)[0],
    name: formatDate(new Date()),
    editing: this.props.match.params.id ? true : false,
    published: false,
  };
  submit = e => {
    e.preventDefault();
    const { company, name, published, blacklist } = this.state;
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: this.state.editing
          ? `${baseApiUrl}/report/edit`
          : `${baseApiUrl}/report`,
        form: {
          id: this.props.match.params.id,
          company,
          name,
          published,
          blacklist: blacklist.length > 0 ? blacklist.join("*|*") : undefined,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.props.history.push(`/admin/reports`);
        }
      }
    );
  };
  onKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handlePublishChange = e => {
    this.setState({
      published: this.state.published ? false : true,
    });
  };
  delete = e => {
    if (window.confirm("Are you sure you want to delete?")) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/report/delete`,
          form: {
            id: this.props.match.params.id,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.props.history.push(`/admin/reports`);
          }
        }
      );
    }
  };
  componentDidMount() {
    if (this.state.editing) {
      request(
        {
          url: `${baseApiUrl}/v1/admin/report?report_id=${this.props.match.params.id}`,
          headers: {
            Authorization: this.props.user.token,
          },
        },
        (err, res, data) => {
          if (err) {
            this.setState({
              loading: false,
            });
          }
          const json = JSON.parse(data);

          this.setState({
            loading: false,
            name: json.report.name,
            company: json.report.company,
            blacklist: json.report.blacklist || [],
            published: json.report.published || false,
          });
        }
      );
    }
  }
  addKW = e => {
    e.preventDefault();
    const blacklist = this.state.blacklist;
    if (this.state.keyword && this.state.keyword.trim() !== "") {
      blacklist.push(this.state.keyword);
    }
    this.setState(
      {
        blacklist,
        keyword: "",
      },
      () => {
        this.input.current.value = "";
        this.input.current.focus();
      }
    );
  };
  removeKW = kw => e => {
    e.preventDefault();

    const newBlackList = this.state.blacklist.filter(k => k !== kw);
    this.setState({ blacklist: newBlackList });
  };
  render() {
    return (
      <form onSubmit={this.submit}>
        {this.state.editing ? (
          <h1>Editing {this.state.name}</h1>
        ) : (
          <h1>Create a new report</h1>
        )}
        <div className="mb4">
          <TextInput
            label="Company"
            name="company"
            onChange={this.onKeyUp}
            value={this.state.company}
          />
          <TextInput
            label="Report name"
            name="name"
            value={this.state.name}
            onChange={this.onKeyUp}
          />
        </div>
        <div className="mb4">
          <label className="db fw4 lh-copy black-60 mt4">
            {" "}
            <input
              type="checkbox"
              name="published"
              value={this.state.published}
              checked={this.state.published}
              onChange={this.handlePublishChange}
            />{" "}
            Publish
          </label>
        </div>
        <div className="mb3">
          <div>
            <b>Keyword blacklist</b>
          </div>
          <input
            type="text"
            onChange={this.onKeyUp}
            name="keyword"
            ref={this.input}
          />
          <button className="ml2" onClick={this.addKW}>
            Add
          </button>
          {this.state.blacklist && (
            <div className="mt2">
              {this.state.blacklist.map(k => (
                <span className="pa2 bg-black-10 mr2 mb2 dib br2">
                  {k}{" "}
                  <a href=" #" onClick={this.removeKW(k)}>
                    x
                  </a>
                </span>
              ))}
            </div>
          )}
        </div>

        <button
          onClick={this.submit}
          className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 br2"
        >
          {this.state.editing ? "Save" : "Create report"}
        </button>
        {this.state.editing && (
          <button
            onClick={this.delete}
            className="b ph3 pv2 input-reset ba b--red bg-red white grow pointer f6 br2 ml3"
          >
            Delete
          </button>
        )}
      </form>
    );
  }
}

export default AddScreenshots;
