import React from "react";
import request from "request";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Document from "../../components/Document";
import { baseApiUrl, handleError } from "../../util";
import AbTestEmailPreview from "./components/AbTestEmailPreview";

class Reports extends React.Component {
  state = {};
  componentDidMount() {
    const { id } = this.props.match.params;
    let idStr = "";
    if (id === "new") {
      return;
    } else if (id) {
      idStr = `?report_id=${id}`;
    }
    request(
      {
        url: `${baseApiUrl}/v1/admin/reports${idStr}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          authed: true,
          reports: json.reports,
        });
      }
    );
  }
  clearCache = (report, company, onlySS) => e => {
    e.preventDefault();
    let url;
    if (onlySS) {
      url = `${baseApiUrl}/v1/cache_report?report=${report}&customer=${company}&onlySS=true`;
      request(
        {
          url,
          headers: {
            Authorization: this.props.user.token,
          },
        },
        (err, res, data) => {
          if (err) {
            alert("Something went wrong!");
          }
          if (data) {
            alert(
              "The cache is clearing now, and might take up to a minute to finish."
            );
          }
        }
      );
    } else {
      if (
        window.confirm(
          "Are you sure you want to clear SEM Tests? Please don't if you didn't add or remove any SEM companies."
        )
      ) {
        url = `${baseApiUrl}/v1/cache_report?report=${report}&customer=${company}`;
        request(
          {
            url,
            headers: {
              Authorization: this.props.user.token,
            },
          },
          (err, res, data) => {
            if (err) {
              alert("Something went wrong!");
            }
            if (data) {
              alert(
                "The cache is clearing now, and might take up to 5 minutes to finish."
              );
            }
          }
        );
      }
    }
  };
  sendEmails = e => {
    e.preventDefault();
    this.setState({ sending: true });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/send_ab_email`,
        form: {
          emails: this.state.emails
            .split(",")
            .map(e => e.trim())
            .join("*|*"),
          customer: this.state.selectedSend,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            sending: false,
          });
          alert("something went wrong!");
          return;
        }
        this.setState({ sending: false, selectedSend: null });
      }
    );
  };
  getEmailPreview = customer => e => {
    e.preventDefault();
    this.setState({ gettingPreview: true, loadPreview: customer });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/preview_ab_email`,
        form: {
          customer,
        },
      },
      (err, res, responseBody) => {
        if (err) {
          this.setState({
            error: true,
            gettingPreview: false,
          });
          alert("something went wrong!");
          return;
        }
        const { success, data } = JSON.parse(responseBody);
        this.setState({
          gettingPreview: false,
          previewData:
            success && data && data.final_tests ? data.final_tests : "No Data",
          allData: success && data && data.final_tests ? data.tests : {},
        });
      }
    );
  };
  selectSend = customer => e => {
    e.preventDefault();
    this.setState({
      selectedSend: customer,
    });
  };
  onChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  onClosePreview = e => {
    this.setState({
      previewData: null,
      loadPreview: null,
      allData: null,
    });
  };
  render() {
    const { reports } = this.state;
    if (this.props.match.params.id === "new") {
      return <></>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="pt3">
        <h1>Customer Tests/SEM Admin</h1>
        {reports && (
          <Document>
            <div>
              <div>
                <b className="f4">
                  <a
                    href={`/home/admin/report`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Demo (every test)
                  </a>{" "}
                  &middot;{" "}
                  <a
                    href={`/home/admin/report`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    All Tests
                  </a>{" "}
                  &middot;{" "}
                  <a
                    href={`/home/admin/sem`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SEM
                  </a>
                </b>
              </div>
              <div className="mb4">
                <a href="# " onClick={this.clearCache("admin", "admin", true)}>
                  Reset a/b cache
                </a>{" "}
                &middot;{" "}
                <a href="# " onClick={this.clearCache("admin", "admin")}>
                  Reset sem & a/b cache
                </a>
              </div>
            </div>
            {reports.map(company => {
              if (company.key === "all") {
                return <></>;
              }
              return (
                <div key={company.key}>
                  <div key={company.key}>
                    <div>
                      <b className="f4">
                        {company.name} &middot;{" "}
                        <a
                          href={`/home/${company.key}/report`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          All Tests
                        </a>{" "}
                        &middot;{" "}
                        <a
                          href={`/home/${company.key}/sem`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SEM
                        </a>
                      </b>
                    </div>
                    <div className="mb4">
                      <Link
                        to={`/admin/sem/${company.report}?company=${company.key}&name=${company.name}`}
                      >
                        Edit SEM
                      </Link>{" "}
                      &middot;{" "}
                      <a
                        href="# "
                        onClick={this.clearCache(
                          company.report,
                          company.key,
                          true
                        )}
                      >
                        Reset a/b cache
                      </a>{" "}
                      &middot;{" "}
                      <a
                        href="# "
                        onClick={this.clearCache(company.report, company.key)}
                      >
                        Reset sem & a/b cache
                      </a>
                      {this.state.selectedSend !== company.key ? (
                        <>
                          {" "}
                          &middot;{" "}
                          <a href="# " onClick={this.selectSend(company.key)}>
                            Send <b>test</b> New Web Tests email
                          </a>
                        </>
                      ) : (
                        <div>
                          <input
                            name="emails"
                            onChange={this.onChange}
                            defaultValue={
                              !this.state.emails
                                ? `${this.props.user.email},will@dowhatworks.io`
                                : this.state.emails
                            }
                            className="w-50"
                          />
                          {this.state.sending ? (
                            <>Sending...</>
                          ) : (
                            <button onClick={this.sendEmails}>Send</button>
                          )}
                        </div>
                      )}
                      {this.state.loadPreview === company.key ? (
                        this.state.gettingPreview ? (
                          <span> &middot; Getting preview</span>
                        ) : (
                          <div>
                            {this.state.previewData && (
                              <AbTestEmailPreview
                                previewData={this.state.previewData}
                                onClosePreview={this.onClosePreview}
                                allData={this.state.allData}
                              />
                            )}
                          </div>
                        )
                      ) : (
                        <>
                          {" "}
                          &middot;{" "}
                          <a
                            href="# "
                            onClick={this.getEmailPreview(company.key)}
                          >
                            Preview email
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </Document>
        )}
      </div>
    );
  }
}

export default Reports;
