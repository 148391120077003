import React from "react";
import CheckoutForm from "../../components/CheckoutForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import { stripeApiKey, stripeSkus } from "../../util";

class Subscribe extends React.Component {
  state = {
    loading: true,
    user: null,
    authed: false,
  };
  render() {
    return (
      <div className="tl pa3">
        <h1 style={{ fontSize: "2rem", fontWeight: "normal" }}>
          SEM Winners & Losers Report <br />
          <span className="f3 sb">Up to 10 Keywords</span>
        </h1>
        <div class="flex items-middle mb2" style={{ marginTop: "1.25rem" }}>
          <input
            type="radio"
            name="sku"
            checked
            className="mr2 pb1"
            value={stripeSkus.sem25}
            style={{ marginTop: "8px" }}
          />
          <div>
            <p className="mt0 f4">$100.00/month (recurring)</p>
          </div>
        </div>

        <StripeProvider apiKey={stripeApiKey}>
          <div>
            <Elements>
              <CheckoutForm user={this.props.user} sku={stripeSkus.sem25} />
            </Elements>
          </div>
        </StripeProvider>
      </div>
    );
  }
}

export default Subscribe;
