import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl } from "../../util";

class Companies extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {}
  getData = () => {
    this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/queue_csv`,
        headers: {
          Authorization: this.props.user.token
        }
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false
          });
          return;
        }
        const json = JSON.parse(data);
        window.open(json.csv);

        this.setState({
          loading: false
        });
      }
    );
  };
  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        <h1>Get Queue Activity Data</h1>
        <a href="# " onClick={this.getData}>
          Download CSV
        </a>
      </div>
    );
  }
}

export default Companies;
