import React, { Component } from "react";
import request from "request";
import { baseApiUrl } from "../util";
import NoteDisplay from "./NoteDisplay";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import X from "../icons/red_x.svg";
import { values } from "lodash";
class EditableAutocompleteV2 extends Component {
  constructor(props) {
    super(props);
    // this.props.items.sort(this.doSort);

    const allItems = this.props.items.map(item => {
      return {
        text: item.text,
        tl: item.tag,
        freq: parseInt(item.freq),
        tag: item.tag,
      };
    });
    const suggestionData = this.props.suggestionData;
    this.state = {
      items: allItems,
      allItems,
      subtags: this.props.subtags,
      value: this.props.initialValue,
      showSelector: false,
      dirty: false,
      hasFocus: true,
      isTl: true,
      itemHighlighted: 0,
      showSuggestion: suggestionData && suggestionData.showSuggestion,
      suggestedTags:
        suggestionData && suggestionData.suggestedTags
          ? suggestionData.suggestedTags
          : [],
      initialTags:
        suggestionData && suggestionData.initialTags
          ? suggestionData.initialTags
          : "",
    };
  }
  doSort = (a, b) => {
    return a.tag.toLowerCase().replace(/[^a-z0-9]/gi, "") >=
      b.tag.toLowerCase().replace(/[^a-z0-9]/gi, "")
      ? 1
      : -1;
  };

  select = item => e => {
    this.input.current.focus();
    if (!item) {
      return;
    }
    const val = `${item}: `;
    this.setState({
      value: val,
      showSelector: true,
    });
    this.handleChange({ currentTarget: { value: val } });
    // if (item.indexOf(":") === -1) {
    // } else {
    //   this.setState(
    //     {
    //       value: item,
    //     }
    //   );
    // }
  };
  selectSuggestion = item => {
    if (!item) {
      return;
    }
    this.setState(
      prevState => {
        const tags = prevState.value.split(":");
        tags.splice(1, 0, item);
        this.props.addSuggestionData({
          suggestedTags: prevState.suggestedTags.filter(
            tag => tag.text != item
          ),
          showSuggestion: true,
          initialTags: prevState.initialTags,
        });
        return {
          value: tags.map(item => item.trim()).join(": "),
          suggestedTags: prevState.suggestedTags.filter(
            tag => tag.text != item
          ),
          showSuggestion: true,
        };
      },
      () => {
        if (this.fetchSuggestionTimer) {
          clearTimeout(this.fetchSuggestionTimer);
        }
        this.fetchSuggestionTimer = setTimeout(() => {
          this.getSuggestedTags(this.state.value);
        }, 1000);
        this.props.callback(this.state.value);
      }
    );
    return false;
  };
  removeSuggestion = item => e => {
    e.preventDefault();
    e.stopPropagation();
    if (!item) {
      return;
    }
    this.setState(
      prevState => {
        const tags = prevState.value.split(":");
        tags.splice(1, 0, item);
        const suggestedTags = prevState.suggestedTags.filter(
          tag => tag.text != item
        );
        this.props.addSuggestionData({
          suggestedTags,
          showSuggestion: true,
          initialTags: prevState.initialTags,
        });
        return {
          value: tags.map(item => item.trim()).join(": "),
          suggestedTags,
          showSuggestion: true,
        };
      },
      () => {
        request(
          {
            url: `${baseApiUrl}/v1/tags/suggested?tagString=${encodeURI(
              this.state.initialTags
            )}&deletedTag=${item}`,
            method: "DELETE",
            headers: {
              Authorization: this.props.token,
            },
          },
          (err, resp, data) => {
            if (err || resp.statusCode >= 400) {
              return;
            }
          }
        );
      }
    );
    return false;
  };
  create = e => {
    if (this.state.value.split(":").length < 2) {
      alert(
        "All notes should be formatted in the style of: [Element changing]: [Change]: [what's changing text for display]. If you need a new top-level element, please talk to Will or Andres."
      );
      return;
    }
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/tags`,
        form: {
          headline: "note",
          tag: this.state.value,
        },
      },
      err => {
        if (err) {
          alert(err);
          return;
        }
        this.props.callback(this.state.value);
        return;
      }
    );
  };
  input = React.createRef();
  autoCompleteDiv = React.createRef();
  suggestedTagDiv = React.createRef();
  fetchSuggestionTimer = null;
  callbackTimer = null;

  handleFocus = e => {
    clearTimeout(this.blurTimeout);
    if (this.props.focusCallback) {
      this.props.focusCallback();
    }
    const { value } = e.currentTarget;

    if (!value || value.trim() === "") {
      this.setState({
        showSelector: false,
        isTl: true,
        hasFocus: true,
      });
    } else {
      this.handleChange({ currentTarget: { value } });
    }
  };
  handleChange = e => {
    const { value } = e.currentTarget;

    if (this.fetchSuggestionTimer) {
      clearTimeout(this.fetchSuggestionTimer);
    }
    if (value.split(":").length > 3) {
      this.fetchSuggestionTimer = setTimeout(() => {
        this.getSuggestedTags(value);
      }, 1000);
    }
    this.setState(
      prevState => {
        return {
          value,
          itemHighlighted: prevState.value
            ? value.trim().toLowerCase() == prevState.value.trim().toLowerCase()
              ? 0
              : prevState.itemHighlighted
            : 0,
        };
      },
      () => {
        const tlds = [];
        const vals = value.split(":");
        let items =
          value.trim() !== "" &&
          value.length > 2 &&
          value.trim().indexOf(":") === -1
            ? this.state.allItems.filter(item => {
                let found = true;

                vals.forEach(val => {
                  if (
                    item.text
                      .toLowerCase()
                      .indexOf(val.toLowerCase().trim()) === -1
                  ) {
                    found = false;
                  }
                });

                const tld = `${item.tl}: ${item.vary}: `;
                if (found && vals.length < 3) {
                  let tFound = true;

                  vals.forEach(val => {
                    if (
                      tld.toLowerCase().indexOf(val.toLowerCase().trim()) === -1
                    ) {
                      tFound = false;
                    }
                  });
                  if (tFound) {
                    item.tldFound = true;
                  }
                }
                return found;
              })
            : [];
        // console.log(`items before subtags`, items)
        if (this.state.subtags && value.trim().indexOf(":") !== -1) {
          const stArr = value.trim().split(":");
          const st = stArr.pop().trim();
          if (st.length > 1) {
            const displayValue =
              stArr.length > 0
                ? `${stArr.map(value => value.trim()).join(": ")}: `
                : "";
            const moreItems = this.state.subtags
              .filter(
                t => t.tag.trim().toLowerCase().indexOf(st.toLowerCase()) !== -1
              )
              .map(t => ({
                text: `${displayValue} ${t.text}`,
                freq: parseInt(t.freq),
              }));

            if (moreItems.length > 0) {
              items = items.concat(moreItems);
            }
          }
        }
        // console.log(`items after subtags filtering`, items)

        items = items.sort((a, b) => {
          if (a.freq && !b.freq) {
            return -1;
          } else if (!a.tldFound && b.tldFound) {
            return 1;
          } else {
            return a.freq <= b.freq ? 1 : -1;
          }
        });

        this.setState({
          items: items,
          showSelector: true,
          dirty: true,
        });
      }
    );
    if (this.callbackTimer) {
      clearTimeout(this.callbackTimer);
    }
    /*
    this.callbackTimer = setTimeout(() => {
      this.props.deleteSuggestionData();
      this.props.callback(this.state.value);
      //console.log(`value = ${value} and state value = ${this.state.value}`);
      // if (value != this.state.value) {
      // }
    }, 2000);
    */
  };
  handleKeyUp = e => {
    // console.log(`key up: `,e.key);
  };
  handleKeyDown = e => {
    // console.log(`key down: `,e.key);
    if (e.key == "Tab") {
      this.selectItem(e);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    if (e.key == "Enter") {
      this.selectItem(e);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    if (e.key == "ArrowUp") {
      this.setState(
        prevState => {
          // console.log(prevState);
          this.autoCompleteDiv.current.scrollTop =
            prevState.itemHighlighted * 40;
          const itemHighlighted =
            prevState.itemHighlighted == -1
              ? -1
              : prevState.itemHighlighted - 1;
          return {
            itemHighlighted,
            selectedValue: prevState.items[itemHighlighted],
          };
        },
        () => {
          e.stopPropagation();
          e.preventDefault();
          return false;
        }
      );
    }
    if (e.key == "ArrowDown") {
      this.setState(prevState => {
        // console.log(prevState);
        this.autoCompleteDiv.current.scrollTop = prevState.itemHighlighted * 40;
        const itemHighlighted =
          prevState.itemHighlighted > prevState.items.length
            ? prevState.items.length
            : prevState.itemHighlighted + 1;
        return {
          itemHighlighted,
          selectedValue: prevState.items[itemHighlighted],
        };
      });
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  };
  blurTimeout: null;
  handleBlur = e => {
    const value = e.currentTarget.value;
    this.blurTimeout = setTimeout(() => {
      this.setState(
        {
          hasFocus: false,
          value: value,
          showSelector: false,
          showSuggestion: false,
          itemHighlighted: -1,
        },
        () => {
          if (!this.state.hasFocus) {
            this.getSuggestedTags(this.state.value);
            this.props.callback(this.state.value);
            this.props.deleteSuggestionData();
          }
        }
      );
    }, 250);
  };
  selectItem = e => {
    const { itemHighlighted } = this.state;
    // console.log(this.state);
    this.input.current.focus();
    let selectedValue = this.state.items[itemHighlighted ? itemHighlighted : 0]; // = this.state.selectedValue;
    if (!selectedValue) {
      selectedValue =
        this.state.items.length > 0 ? this.state.items[0] : undefined;
    }
    if (selectedValue) {
      const selectedItem = selectedValue.text || selectedValue;
      if (selectedItem) {
        this.select(selectedItem)(e);
      }
    }
  };
  getSuggestedTags = (tags = "") => {
    if (this.fetchSuggestionTimer) {
      clearTimeout(this.fetchSuggestionTimer);
    }
    const initialTags = tags || this.state.value;
    request(
      {
        url: `${baseApiUrl}/v1/tags/suggested?tagString=${encodeURI(
          initialTags
        )}`,
        headers: {
          Authorization: this.props.token,
        },
      },
      (err, resp, data) => {
        if (err || resp.statusCode >= 400) {
          return;
        }

        const json = JSON.parse(data);
        this.setState({
          suggestedTags: json,
          showSuggestion: true,
          initialTags,
        });
        this.props.addSuggestionData({
          suggestedTags: json,
          showSuggestion: true,
          initialTags,
        });
      }
    );
    return false;
  };
  hideSuggestion = () => {
    this.setState({
      showSuggestion: false,
    });
    this.props.addSuggestionData({
      showSuggestion: false,
    });
    return false;
  };
  componentWillUnmount() {
    if (this.props.initialValue !== this.state.value) {
      this.props.callback(this.state.value);
    }
    clearTimeout(this.blurTimeout);
    clearTimeout(this.fetchSuggestionTimer);
    clearTimeout(this.callbackTimer);
  }
  render() {
    const { placeholder, initialValue } = this.props;
    const {
      value,
      items,
      showSelector,
      itemHighlighted,
      showSuggestion,
      suggestedTags,
    } = this.state;
    return (
      <div style={{ position: "relative" }} className="w-100">
        <TextareaAutosize
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          // defaultValue={initialValue}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          ref={this.input}
          className={this.props.className}
          style={{ padding: "4px 2px", margin: "1px", zIndex: 0 }}
        />
        <div
          className="autocomplete tl"
          ref={this.autoCompleteDiv}
          style={{
            display: showSelector && items.length > 0 ? "block" : "none",
            position: "absolute",
            left: "1px",
            marginTop: "-10px",
            minWidth: "100%",
            background: "white",
            zIndex: 1000,
            borderBottom: "1px solid #000",
            borderLeft: "1px solid #000",
            borderRight: "1px solid #000",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            paddingTop: "12px",
            paddingBottom: "12px",
            maxHeight: "200px",
            overflowX: "none",
            overflowY: "scroll",
          }}
        >
          {items.length > 0 && (
            <div>
              {items.map((item, i) => (
                <>
                  <div
                    onClick={this.select(item.text ? item.text : item)}
                    key={`aItem-${i}`}
                    className={classNames("pointer", "pa2", {
                      ba: itemHighlighted == i,
                    })}
                  >
                    <span>
                      <b>{item.text ? item.text : item.toString()}</b>
                    </span>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
        <div
          className="autocomplete tl pv2"
          ref={this.suggestedTagDiv}
          style={{
            display:
              showSuggestion && suggestedTags.length > 0 ? "flex" : "none",
            marginTop: "0px",
            maxWidth: "300px",
            background: "white",
            border: "1px solid #000",
            maxHeight: "200px",
            overflowY: "hidden",
            overflowX: "auto",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <span onClick={()=> this.hideSuggestion()}>hide suggestion</span> */}
          {suggestedTags.length > 0 && (
            <>
              {suggestedTags.map((item, i) => (
                <div
                  onClick={() =>
                    this.selectSuggestion(item.text ? item.text : item)
                  }
                  key={`aItem-${i}`}
                  className={classNames(
                    "inline-flex",
                    "f6",
                    "pointer",
                    "items-center",
                    "mh2",
                    "ph2",
                    "br-pill",
                    "ba",
                    "grow",
                    "black",
                    "bg-light-gray",
                    "b--light-gray",
                    {}
                  )}
                >
                  <span className="nowrap">
                    {item.text ? `${item.text}` : item.toString()}
                  </span>
                  <span>
                    <img
                      className={classNames(
                        "pointer",
                        "mh1",
                        "pa1",
                        "br-100",
                        "ba",
                        "grow"
                      )}
                      src={X}
                      alt="Close"
                      onClick={this.removeSuggestion(item.text)}
                    />
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
        {!this.state.showSuggestion && !!this.fetchSuggestionTimer && (
          <div>
            <span
              className="f7"
              onClick={() =>
                value.split(":").length > 3
                  ? this.getSuggestedTags(value)
                  : false
              }
            >
              {this.state.value.split(":").length < 3
                ? "input more tags to get suggestions"
                : "fetching suggestions..."}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default EditableAutocompleteV2;
