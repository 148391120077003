import { useEffect, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import {
  baseApiUrl,
  getData,
  capitalize,
  copyTextToClipboard,
  getShareableURL,
  getCustomerShareableURL,
} from "../util";
import ShareIcon from "../icons/share.svg";
import X from "../icons/red_x.svg";
import ContentCopyIcon from "../icons/content_copy.svg";
import ToggleOn from "../icons/ToggleOn.svg";
import ToggleOff from "../icons/ToggleOff.svg";
import SuccessImg from "../icons/old/champion.svg";
import cx from "classnames";
export function CompanyNameInput(props) {
  const { valueCallback, ...tprops } = props;
  const [inputValue, setInputValue] = useState("");

  const handleInput = e => {
    const formattedCompanyName = formatCompanyName(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue(formattedCompanyName);
    props.valueCallback(formattedCompanyName);
  };

  return (
    <input onChange={e => handleInput(e)} value={inputValue} {...tprops} />
  );
}
function formatCompanyName(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const name = value.replace(/\s+/g, "-").replace(/[^0-9a-zA-Z-]/g, "");

  return name;
}
const AdminShareTest = props => {
  const oldShareUrl = `${window.location.origin}/link/screenshots/${props.testId}`;
  const [shareUrlCopied, setShareUrlCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [shareURL, setShareURL] = useState("");
  const [shareTag, setShareTag] = useState("");
  const [email, setEmail] = useState("");
  const [shareName, setShareName] = useState("");
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [error, setError] = useState({});
  const clearShareUrlTimer = useRef(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [company, setCompany] = useState(props.company);
  const sharePopupDimen = {
    width: 400,
    height: 425,
  };
  useEffect(() => {
    if (shareUrlCopied || shareURL === "email") {
      clearTimeout(clearShareUrlTimer.current);
      clearShareUrlTimer.current = setTimeout(() => {
        if (shareURL === "email") {
          setShowPopup(false);
        }
        setShareURL("");
        setShareUrlCopied(false);
      }, 5000);
    }
  }, [shareUrlCopied, shareURL]);

  const toggleSharePopup = state => {
    const newState = state || !showPopup;
    setShowPopup(newState);
  };
  const toggleDropDown = state => {
    const newState = state || !showDropDown;
    setShowDropDown(newState);
  };
  const copyShareUrl = url => {
    copyTextToClipboard(url || shareURL)
      .then(() => {
        setShareUrlCopied(true);
        setTimeout(() => {
          setShareUrlCopied(false);
        }, 1500);
      })
      .catch(err => {
        setShareUrlCopied(false);
      });
  };
  const validateInput = () => {
    const errors = {};
    let isInputValid = true;
    if (shareTag.trim() === "") {
      errors["shareTag"] = true;
      isInputValid = false;
    }
    setError(errors);
    return isInputValid;
  };
  const generateShareLink = e => {
    if (requestLoading) return;
    if (!validateInput()) {
      return;
    }
    setRequestLoading(true);
    getData({
      url: `${baseApiUrl}/v1/user/share_test?test_id=${
        props.testId
      }&company=${shareTag.trim().toLowerCase()}&open=${shareLinkOpen}`,
      headers: {
        Authorization: props.user.token,
      },
    }).then(({ err, res, data }) => {
      setRequestLoading(false);
      if (err) {
        alert("Something went wrong, please try again");
      } else {
        const shareUrl = getShareableURL(data.shareId);
        if (data.shareType && data.shareType !== "email") {
          setShareURL(shareUrl);
        } else {
          setShareURL("email");
        }
      }
    });
  };
  const closeDropDown = e => {
    setShowDropDown(false);
  };

  return (
    <>
      <div
        className="dib c-box-inner collections-module pb1"
        style={{
          position: "relative",
          padding: "0px 4px",
        }}
      >
        <img
          aria-label="share"
          src={ShareIcon}
          color="primary"
          className="pointer share-icon"
          style={{ marginTop: props.detailPage ? "-1px" : "-8px" }}
          height="24"
          onClick={e => toggleSharePopup()}
          title="Share this test"
        />
      </div>
      <Modal open={showPopup} className="dww-modal-container">
        <div
          className="box"
          style={{
            width: `${sharePopupDimen.width}px`,
            height: `${sharePopupDimen.height}px`,
            overflow: "hidden",
            top: (window.innerHeight - sharePopupDimen.height) / 2,
            position: "absolute",
            left: (window.innerWidth - sharePopupDimen.width) / 2,
            outline: 0,
          }}
        >
          <div>
            <div className="pv3 ph4 bb b--black-05 flex items-center justify-between">
              <h3 className="mb0">Share this test</h3>
              <img
                aria-label="close"
                src={X}
                color="primary"
                className="pointer share-icon"
                height="20"
                width="20"
                onClick={e => {
                  setShowPopup(false);
                }}
              />
            </div>
            {shareURL === "email" ? (
              <div className="tc center">
                <div className="mb3 tc mt4">
                  <img
                    src={SuccessImg}
                    style={{
                      width: "78px",
                      height: "78px",
                    }}
                    alt="success"
                    className="moviefone-success mb4"
                  />
                  <h1 className="b f3 mb2">Email Sent!</h1>
                </div>
                <p className="tc f5 pa3">
                  If your colleague doesn't have a DoWhatWorks account, they'll
                  be prompted to request access from your dashboard admin(s).
                </p>
              </div>
            ) : (
              <>
                <div className="ph4 pv2">
                  Generate link to share this test publically, check{" "}
                  <span className="light-red">open link</span> if you are
                  sharing with prospective customer
                </div>
                <div
                  className={cx("ph4 pt1 pointer", {
                    dn: !shareURL,
                  })}
                  onClick={e => copyShareUrl()}
                >
                  <p
                    className="f6 black bg-moon-gray code pa1 pb0 tc"
                    style={{ wordWrap: "break-word" }}
                  >
                    {shareURL}
                    {shareUrlCopied ? (
                      <span className="f6 ml1">
                        Share link copied to your clipboard!
                      </span>
                    ) : (
                      <img
                        aria-label="share"
                        src={ContentCopyIcon}
                        color="primary"
                        className="pointer mh3 share-icon mb1"
                        height="20"
                        width="20"
                      />
                    )}
                  </p>
                </div>

                <div className="ph4 pv3 flex flex-wrap items-center justify-between">
                  <CompanyNameInput
                    type="text"
                    placeholder="Share tag"
                    className={cx("bg-light-gray  br3 w-60 pl2 pv2", {
                      "gray bn": !error["shareTag"],
                      "b--red": error["shareTag"],
                    })}
                    valueCallback={setShareTag}
                  />
                  Open link
                  <img
                    src={shareLinkOpen ? ToggleOn : ToggleOff}
                    className="toggle-icon"
                    alt="Toggle on"
                    onClick={e => {
                      setShareLinkOpen(!shareLinkOpen);
                    }}
                  />
                </div>

                <div className="ph4 pv3 tc">
                  <button
                    className="primary ui-button"
                    onClick={generateShareLink}
                  >
                    {requestLoading ? "Generating share link" : "Generate Link"}
                  </button>
                </div>
                <div>
                  <div className="ph4 b">Share link for customers:</div>
                  <div
                    className="ph4 pv3 pointer "
                    onClick={e => {
                      copyShareUrl(oldShareUrl);
                    }}
                  >
                    <p
                      className="f6 black bg-moon-gray code"
                      style={{ wordWrap: "break-word" }}
                    >
                      {oldShareUrl}
                      {shareUrlCopied ? (
                        <span className="f6 ml1">Copied!</span>
                      ) : (
                        <img
                          aria-label="share"
                          src={ContentCopyIcon}
                          color="primary"
                          className="pointer mh3 share-icon"
                          height="20"
                          width="20"
                        />
                      )}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AdminShareTest;
