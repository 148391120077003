import React, { Component } from "react";
import request from "request";
import { fbAuth, baseApiUrl } from "../../util";
import * as querystring from "query-string";
import Success from "../../icons/old/champion.svg";
class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: null,
      success: false,
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    const that = this;
    const qs = querystring.parse(window.location.search);
    request.get(
      {
        url: `${baseApiUrl}/v1/check_email?email=${this.state.email}`,
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        fbAuth
          .sendPasswordResetEmail(json.email, {
            url: qs.fromSem
              ? "https://sem.dowhatworks.io/login"
              : "https://app.dowhatworks.io/login",
          })
          .then(() => {
            this.setState({ success: true });
          })
          .catch(function (error) {
            that.setState({ error: true });
          });
      }
    );
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const labelClass = "db fw4 lh-copy black-60 mt4";
    const inputClass = "pa2 input-reset w-80 ba b--black-20 bg-white br2";

    const { error } = this.state;
    const qs = querystring.parse(window.location.search);
    return (
      <main className="pv4">
        <div
          className="box mw6 center pa3 br2 flex flex-wrap bg-white tl"
          style={{ position: "relative" }}
        >
          <h1>Reset Password Request</h1>
          <form onSubmit={this.handleSubmit} className="w-100">
            {this.state.success && (
              <div className="pt2">
                <div className="w-80">
                  <img
                    src={Success}
                    style={{
                      width: "48px",
                      height: "48px",
                      position: "absolute",
                      right: "48px",
                      marginTop: "60px",
                    }}
                    alt="success"
                    className="moviefone-success"
                  />
                  <div className="dark-green b">
                    Check your email for instructions on how to reset your
                    password.
                  </div>
                </div>
              </div>
            )}
            {error && (
              <p className="dark-red">
                Sorry, we couldn't find that email. Please try another email, or
                reach out to us at help@dowhatworks.io for assistance.
              </p>
            )}
            <div className="mt3">
              <input
                type="email"
                onChange={this.handleKeyUp}
                name="email"
                className={inputClass}
                placeholder="Email"
              />
            </div>
            <div className="mt4">
              <input
                type="submit"
                value="Reset your password"
                className="ui-button primary"
              />
            </div>
          </form>
        </div>
      </main>
    );
  }
}

export default Reset;
