import React from "react";
import request from "request";
import * as querystring from "query-string";
import { baseApiUrl } from "../../util";
import TextInput from "../../components/form/TextInput";
import Upload from "../../components/form/Upload";

const MAX_IMAGE_SIZE = 2000000;

class AddSem extends React.Component {
  state = {
    uploading: false,
    file: null,
    report: null,
    reports: [],
    graphs: []
  };
  createFile = file => {
    console.info(file);
    let reader = new FileReader();
    reader.onload = e => {
      if (!e.target.result.includes("data:text/csv")) {
        return alert("Wrong file type - CSV only.");
      }
      if (e.target.result.length > MAX_IMAGE_SIZE) {
        return alert("File is loo large - 4Mb maximum");
      }
      this.setState({
        data: e.target.result,
        uploading: false,
        filename: file.name
      });
      //this.onUpload();
    };
    reader.readAsDataURL(file);
  };
  onFileChange = e => {
    this.setState({
      uploading: true
    });
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createFile(files[0]);
  };
  submit = e => {
    e.preventDefault();
    const { data, name, report, graphs } = this.state;
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token
        },
        url: this.state.editing
          ? `${baseApiUrl}/sem/edit`
          : `${baseApiUrl}/sem`,
        form: {
          data,
          name,
          report,
          graphs: graphs.length > 0 ? graphs.join(",") : undefined
        }
      },
      err => {
        if (err) {
          this.setState({
            error: true
          });
          return;
        }
        if (!err) {
          this.props.history.push(`/admin/reports`);
        }
      }
    );
  };
  onKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  handlePublishChange = e => {
    this.setState({
      published: this.state.published ? false : true
    });
  };
  delete = e => {
    if (window.confirm("Are you sure you want to delete?")) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token
          },
          url: `${baseApiUrl}/sem/delete`,
          form: {
            id: this.props.match.params.id
          }
        },
        err => {
          if (err) {
            this.setState({
              error: true
            });
            return;
          }
          if (!err) {
            this.props.history.push(`/admin/reports`);
          }
        }
      );
    }
  };
  componentDidMount() {
    const { company } = querystring.parse(window.location.search);
    request(
      {
        url: `${baseApiUrl}/reports?company=${company}`,
        headers: {
          Authorization: this.props.user.token
        }
      },
      (err, res, data) => {
        const reports = JSON.parse(data).reports;
        this.setState({
          reports: reports,
          report: reports.length ? reports[0].id : undefined
        });
      }
    );
    if (this.state.editing) {
      request(
        {
          url: `${baseApiUrl}/sem_report?report=${this.props.match.params.id}`,
          headers: {
            Authorization: this.props.user.token
          }
        },
        (err, res, data) => {
          if (err) {
            this.setState({
              loading: false
            });
          }
          this.setState({
            loading: false
          });
        }
      );
    }
  }
  handleGraphUpload = files => {
    this.setState({
      graphs: files
    });
  };
  render() {
    const {
      editing,
      uploading,
      report,
      reports,
      filename,
      name,
      graphs
    } = this.state;
    return (
      <form onSubmit={this.submit}>
        {editing ? <h1>Editing {name}</h1> : <h1>Create a new SEM report</h1>}
        <TextInput
          label="Company Name"
          name="name"
          value={this.state.name}
          onChange={this.onKeyUp}
        />
        <div className="mt3">
          <label className="db fw4 lh-copy black-60 mt4">Add to report</label>
          <select
            name="report"
            onChange={this.onKeyUp}
            className="pa2 w-80 w-50-l b--black-20 bg-white br2"
            value={report}
          >
            {reports.map(report => {
              return (
                <option value={report.id} key={report.id}>
                  {report.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb4">
          <label className="db fw4 lh-copy black-60 mt4">Upload CSV</label>
          <p>
            {filename && <span>{filename}</span>}
            {uploading && <span>Uploading&hellip;</span>}
            {!uploading && !filename && (
              <input type="file" name="upload" onChange={this.onFileChange} />
            )}
          </p>
        </div>
        <div className="mb4">
          <label className="db fw4 lh-copy black-60 mt4">Upload Graphs</label>
          {graphs.length === 0 && (
            <Upload
              callback={this.handleGraphUpload}
              user={this.props.user}
              multiple
              hideTitle
            />
          )}
          {graphs.map(img => (
            <img src={img} width="200" className="mr2" alt="graph" />
          ))}
        </div>
        <div className="mb4">
          <label className="db fw4 lh-copy black-60 mt4">
            {" "}
            <input
              type="checkbox"
              name="published"
              value={this.state.published}
              checked={this.state.published}
              onChange={this.handlePublishChange}
            />{" "}
            Publish
          </label>
        </div>

        <button
          onClick={this.submit}
          className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 br2"
        >
          {editing ? "Save" : "Create report"}
        </button>
        {editing && (
          <button
            onClick={this.delete}
            className="b ph3 pv2 input-reset ba b--red bg-red white grow pointer f6 br2 ml3"
          >
            Delete
          </button>
        )}
      </form>
    );
  }
}

export default AddSem;
