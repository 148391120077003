import React, { Component } from "react";
import { baseApiUrl, getCurrentTime } from "../util";
import DownIcon from "../icons/DownArrow_gray.svg";
import UpIcon from "../icons/UpArrow_gray.svg";
import BlueCheck from "../icons/check_blue.svg";
import RedPlus from "../icons/red-plus.svg";
import RedDeleteIcon from "../icons/delete_red.svg";
import Search from "../icons/search.svg";
import ToggleGray from "../icons/toggle_gray.svg";
import ToggleSelected from "../icons/toggle_selected.svg";
import ToggleUnSelected from "../icons/toggle_unselected.svg";
import Empty from "./Empty";
import cx from "classnames";
import request from "request";
import "./tagfilter.css";

class TagFilter extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.state = {
      showing: false,
      andOps: this.props.andOps,
      selectedTags: this.getDefaultOptions(props),
      unselectedTags: this.props.options.sort(this.sort()),
      synonym_match: this.props.synonyms
        ? Object.keys(this.props.synonyms)
        : undefined,
      title_slug: `tf-${getCurrentTime()}-${props.title_slug || ""}`,
      rect: { top: 0, left: 0 },
      disabled: this.props.disabled,
      selected_tag: null,
      filterMax: 500,
      boolAnd: this.getIfCombinedTagAvailable(props),
    };
    this.filteredTags = this.props.options.sort(this.sort());
  }
  selected_tag: null;
  filteredTags: null;
  containerElm: null;
  scrollElm: null;
  scrollElmInner: null;
  scrollRect: null;
  getDefaultOptions({ andOps, defaultOptions, options, title_slug, ...props }) {
    const defaults = defaultOptions || [];
    if (andOps) {
      const selectedTags = this.parseCombinedTags(defaults, options);
      return selectedTags;
    } else {
      return defaults;
    }
  }
  parseCombinedTags(defaults, options) {
    const selectedTags = [];
    defaults.forEach(defaultTag => {
      if (defaultTag.value.indexOf(`&&`) !== -1) {
        const andTags = defaultTag.value.split(`&&`);
        andTags.forEach(andTag => {
          const foundTag = options.find((option, index) => {
            return option.value === andTag;
          });
          if (foundTag) {
            selectedTags.push(foundTag);
          }
        });
      } else {
        selectedTags.push(defaultTag);
      }
    });
    return selectedTags;
  }
  getIfCombinedTagAvailable({ andOps, defaultOptions = [] }) {
    if (defaultOptions && defaultOptions.length < 2) {
      return;
    }
    if (!andOps) {
      return false;
    } else {
      const foundTag = defaultOptions.find(
        tag => tag.value.indexOf(`&&`) !== -1
      );
      if (foundTag) {
        return true;
      } else {
        return false;
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.defaultOptions !== this.props.defaultOptions) {
      this.setState({
        selectedTags: this.getDefaultOptions(this.props),
        boolAnd:
          this.props.defaultOptions && this.props.defaultOptions.length >= 2
            ? this.getIfCombinedTagAvailable(this.props)
            : this.state.boolAnd,
      });
    }
  }
  sort = () => {
    if (this.props.noSort) {
      return () => {};
    } else if (this.props.addGroup) {
      return this.groupTagSort;
    } else {
      return this.tagSort;
    }
  };
  scroller: null;
  setSelectedTag = tag => {
    this.setState({ selected_tag: tag });
    this.selected_tag = tag;
  };
  scrollToElm = elm => {
    const rect = elm.getBoundingClientRect();
    if (!this.scroller) {
      const container = document.getElementById(this.state.title_slug);
      this.scroller = container.querySelector(".tag-menu-scroll");
    }
    setTimeout(() => {
      this.scroller.scrollTo(0, elm.offsetTop - (rect.height + 200));
    }, 100);
  };
  selectNext = tabbed => {
    const { selected_tag } = this.state;

    let selectedTag;

    if (!selected_tag || selected_tag.index >= this.filteredTags.length - 1) {
      selectedTag = this.filteredTags[0];
      selectedTag.index = 0;
    } else {
      const nextI = selected_tag.index + 1;
      selectedTag = this.filteredTags[nextI];
      selectedTag.index = nextI;
    }
    this.setSelectedTag(selectedTag);
    const elm = document.getElementById(`tag-${selectedTag.value}`);
    if (elm && !tabbed) {
      elm.focus();
      this.scrollToElm(elm);
    }
  };
  selectPrev = tabbed => {
    const { selected_tag } = this.state;

    let selectedTag;

    if (!selected_tag || selected_tag.index === 0) {
      const prevI = this.filteredTags.length - 1;
      selectedTag = this.filteredTags[prevI];
      selectedTag.index = prevI;
    } else {
      const prevI = selected_tag.index - 1;
      selectedTag = this.filteredTags[prevI];
      selectedTag.index = prevI;
    }
    this.setSelectedTag(selectedTag);
    const elm = document.getElementById(`tag-${selectedTag.value}`);
    if (elm && !tabbed) {
      elm.focus();
      this.scrollToElm(elm);
    }
  };
  handleKeyPress = e => {
    if (e.key === "ArrowDown" || (!e.shiftKey && e.key === "Tab")) {
      this.selectNext(e.key === "Tab");
    } else if (e.key === "ArrowUp" || (e.shiftKey && e.key === "Tab")) {
      this.selectPrev(e.key === "Tab");
    }
  };
  handleKeyUp = e => {
    if (e.key === "Escape") {
      e.preventDefault();
      this.close();
    }
    if (this.selected_tag && e.key === "Enter") {
      e.preventDefault();
      if (
        this.state.selectedTags.find(t => t.value === this.selected_tag.value)
      ) {
        this.doRemove(this.selected_tag.value, this.selected_tag.id);
      } else {
        this.doAdd(this.selected_tag.value, this.selected_tag.id);
      }
    }
  };
  handleChange = (newValue, actionMeta) => {};
  open = e => {
    e.preventDefault();
    const elm = document.getElementById(this.state.title_slug).parentElement;
    const rect = elm.getBoundingClientRect();
    this.selected_tag = null;
    this.scrollRect = null;
    this.scrollElm = null;
    this.setState(
      {
        showing: !this.state.showing,
        rect: {
          left: elm.offsetLeft,
          top: elm.offsetTop + rect.height,
          width: rect.width,
          x: rect.x,
          y: rect.y,
        },
        search: null,
        selected_tag: null,
        filterMax: 500,
      },
      () => {
        if (this.inputRef && this.inputRef.current) {
          this.inputRef.current.focus();
        }
      }
    );
  };
  removeFromTags = (tags, tag) => {
    let newTags;
    if (tag.id && tag.id !== "fulltext" && tag.id !== "xxx") {
      newTags = tags.filter(t => t.id !== tag.id);
    } else {
      newTags = tags.filter(t => t.value !== tag.value);
    }
    newTags.sort(this.sort);
    return newTags;
  };
  doAdd = (tag, id) => {
    if (!tag) {
      return;
    }

    const { selectedTags } = this.state;
    let fullTag;
    if (id === "fulltext") {
      fullTag = this.props.options.find(t => t.value === tag.toLowerCase());
      if (!fullTag) {
        fullTag = {
          value: tag.toLowerCase(),
          label: tag,
          fullText: true,
          id: "fulltext",
        };
      }
    } else if (id) {
      fullTag = this.props.options.find(t => t.id === id);
    } else {
      fullTag = this.props.options.find(t => t.value === tag);
    }
    if (this.shouldDisable(fullTag)) {
      return;
    }
    selectedTags.push(fullTag);
    selectedTags.sort(this.sort);
    if (!selectedTags || selectedTags.length === 0) {
      return;
    }
    if (this.state.boolAnd) {
      const combinedSelectedTag = {
        label: selectedTags.map(tag => tag.label).join("&&"),
        negative: false,
        type: "page_types",
        value: selectedTags.map(tag => tag.value).join("&&"),
      };
      setTimeout(() => this.props.callback([combinedSelectedTag]), 0);
    } else {
      setTimeout(() => this.props.callback(selectedTags), 0);
    }
  };
  add = (tag, id) => e => {
    if (e) {
      e.preventDefault();
    }
    this.doAdd(tag, id);
  };
  close = () => {
    this.setState({ showing: false, search: null });
  };
  editCallback = tag => e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.editCallback(tag);
  };
  deleteCallback = tag => e => {
    e.preventDefault();
    e.stopPropagation();

    if (window.confirm(`Are you sure you want to delete ${tag.label}?`)) {
      this.props.deleteCallback(tag);
    }
  };
  tagSort = () => {};
  //tagSort = (a, b) => (a.value.toLowerCase() >= b.value.toLowerCase() ? 1 : -1);
  groupTagSort = (a, b) => {
    if (a.group && !b.group) {
      return -1;
    } else if (!a.group && b.group) {
      return 1;
    } else {
      return a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1;
    }
  };
  hasTag = (tag, id) => {
    if (id === "fulltext") {
      return { label: tag, value: tag, id: "fulltext" };
    }
    if (id) {
      return this.state.selectedTags.find(t => t.id === id);
    } else {
      return this.state.selectedTags.find(t => t.value === tag);
    }
  };
  clearAll = () => {
    this.setState({
      selectedTags: [],
      showing: false,
    });
    setTimeout(() => this.props.callback([]), 0);
  };
  onChange = e => {
    let val;
    if (e.currentTarget.value.length > 0) {
      val = e.currentTarget.value;
    } else {
      val = null;
    }
    this.scrollRect = null;
    this.scrollElm = null;
    this.setState({ filterMax: 500, search: val }, () => {
      setTimeout(this.scrollUp, 100);
    });
  };
  scrollUp = () => {
    if (!this.containerElm) {
      this.containerElm = document.getElementById(this.state.title_slug);
    }
    if (!this.scrollElm) {
      this.scrollElm = this.containerElm.querySelector("div.tag-menu-scroll");
    }
    this.scrollElm.scrollTo(0, 0);
  };
  refine = tag => e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ search: `${tag.value}:` });
    this.inputRef.current.value = `${tag.value}:`;
  };
  clearSearch = e => {
    this.setState({ search: null });
    this.inputRef.current.value = "";
    this.inputRef.current.focus();
    this.setSelectedTag(null);
    setTimeout(this.scrollUp, 100);
  };
  remove = (tag, id) => e => {
    e.preventDefault();
    this.doRemove(tag, id);
  };
  doRemove = (tag, id) => {
    const { selectedTags } = this.state;
    let fullTag;
    if (id && id !== "xxx") {
      fullTag = this.props.options.find(t => t.id === id);
    } else {
      fullTag = this.props.options.find(t => t.value === tag);
    }
    if (!fullTag) {
      fullTag = { label: tag, value: tag, id: "fulltext" };
    }
    const newTags = this.removeFromTags(selectedTags, fullTag);

    this.setState({
      selectedTags: newTags,
    });

    if (this.state.boolAnd) {
      const combinedSelectedTag = {
        label: newTags.map(tag => tag.label).join("&&"),
        negative: false,
        type: "page_types",
        value: newTags.map(tag => tag.value).join("&&"),
      };
      setTimeout(() => this.props.callback([combinedSelectedTag]), 0);
    } else {
      setTimeout(() => this.props.callback(newTags), 0);
    }
  };
  addGroup = e => {
    e.preventDefault();
    this.props.groupAdd();
    this.setState({ showing: false });
  };
  filterTags = () => {
    if (!this.props.synonyms && !this.state.search) {
      return this.state.unselectedTags;
    }
    if (!this.props.synonyms) {
      return this.state.unselectedTags.filter(t => {
        return (
          t.label.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
        );
      });
    } else {
      const syns = [];
      this.state.synonym_match.forEach(m => {
        if (m.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
          this.props.synonyms[m].forEach(x => {
            if (!syns.includes(x)) {
              syns.push(x);
            }
          });
        }
      });
      return this.state.unselectedTags.filter(
        t =>
          t.label.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
            -1 ||
          syns.find(x => t.label.toLowerCase().indexOf(x.toLowerCase()) !== -1)
      );
    }
  };
  shouldDisable = tag => {
    const { includeOptions } = this.props;
    if (includeOptions) {
      if (!tag.isGroup) {
        return !includeOptions.includes(tag.value);
      } else {
        return !tag.value.find(t => includeOptions.includes(t));
      }
    }
    if (
      (tag && tag.count === 0) ||
      (this.props.counts &&
        (this.props.counts[tag.value] === 0 || this.props.counts[tag.id] === 0))
    ) {
      return true;
    }
    return false;
  };
  timeout = null;
  record404 = () => {
    if (this.props.token) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        request({
          url: `${baseApiUrl}/v1/t?url=${window.location.href}%3F404_tag_search=${this.state.search}`,
          headers: {
            Authorization: this.props.token,
          },
        });
      }, 200);
    }
  };
  searchSubmit = e => {
    e.preventDefault();
    if (this.props.fullText) {
      this.doAdd(this.state.search, "fulltext");
    } else {
      this.filteredTags = this.filterTags();

      const { filteredTags } = this;

      if (filteredTags && filteredTags.length > 0) {
        this.doAdd(filteredTags[0].value, filteredTags[0].id);
      }
    }
  };
  handleScroll = () => {
    if (this.state.filterMax >= this.filteredTags.length) {
      return;
    }
    if (!this.state.title_slug) {
      return;
    }
    if (!this.containerElm) {
      this.containerElm = document.getElementById(this.state.title_slug);
    }
    if (!this.scrollElm) {
      this.scrollElm = this.containerElm.querySelector("div.tag-menu-scroll");
      this.scrollElmInner = this.containerElm.querySelector(
        "div.tag-menu-scroll-container"
      );
    }

    if (!this.containerElm || !this.scrollElm || !this.scrollElmInner) {
      return;
    }

    if (!this.scrollRect) {
      this.scrollRect = this.scrollElmInner.getBoundingClientRect();
    }

    if (
      this.scrollRect.height - this.scrollElm.scrollTop <=
      this.scrollRect.height * 0.1
    ) {
      this.setState({
        filterMax: this.state.filterMax + 200,
      });
      this.scrollRect = null;
    }
  };
  toggleAndOperation = andOpsState => e => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedTags } = this.state;
    const { options } = this.props;
    if (selectedTags && selectedTags.length > 0) {
      if (andOpsState) {
        const combinedSelectedTag = {
          label: selectedTags.map(tag => tag.label).join("&&"),
          negative: false,
          type: "page_types",
          value: selectedTags.map(tag => tag.value).join("&&"),
        };
        setTimeout(() => this.props.callback([combinedSelectedTag]), 0);
      } else {
        const parsedSelectedTags = this.parseCombinedTags(
          selectedTags,
          options
        );
        setTimeout(() => this.props.callback(parsedSelectedTags), 0);
      }
    }
    this.setState({
      boolAnd: andOpsState,
    });
  };
  render() {
    const {
      selectedTags,
      unselectedTags,
      showing,
      title_slug,
      search,
      disabled,
      selected_tag,
      boolAnd,
    } = this.state;
    const {
      title,
      icon,
      addGroup,
      onRight,
      isSticky,
      skipSearch,
      fixAt,
      fullText,
      inline,
      counts,
      tabIndex,
    } = this.props;

    const iTabIndex = tabIndex ? tabIndex : 0;

    const searchIsSelected = selectedTags.find(t => t.label === search);

    if (showing) {
      if (search) {
        this.filteredTags = this.filterTags();
      } else {
        this.filteredTags = unselectedTags;
      }
    } else {
      this.filteredTags = [];
    }

    const filteredTags = this.filteredTags.slice(0, this.state.filterMax);

    if (filteredTags.length === 0 && search && search !== "") {
      this.record404();
    }

    let top = isSticky ? this.state.rect.y - 20 : this.state.rect.top + 8;

    let style;

    if (fixAt) {
      style = {
        width: fixAt.width,
        right: fixAt.right,
        top: fixAt.top,
        zIndex: 1000,
      };
    } else if (inline) {
      style = {};
    } else {
      style = {
        top: top,
        left: onRight ? null : this.state.rect.left,
        right: onRight ? this.state.rect.x : null,
        width: isSticky ? 300 : this.state.rect.width,
        zIndex: 1000,
      };
    }

    return (
      <>
        <div
          className={cx({
            "tag-filter-container": true,
            "f6 small-tag-filter": this.props.small,
            "kw-tag-filter": this.props.addGroup,
            "tf-inline": inline,
          })}
          id={title_slug}
        >
          <div className="tag-filter-selector">
            <a
              href="# "
              onClick={!disabled ? this.open : undefined}
              className={cx({
                "ui-link": true,
                nowrap: true,
                "tag-filter": true,
                "tag-filter-disabled": disabled,
              })}
              tabIndex={iTabIndex}
            >
              <span>
                <img src={icon} className="tf-icon" />
                {this.props.showOne && selectedTags.length > 0 ? (
                  <span className="tf-selected-tag">
                    {selectedTags[0].label}
                    {selectedTags.length > 1 && <>, &hellip;</>}
                  </span>
                ) : (
                  <>{title}</>
                )}
              </span>
              {selectedTags.length > 0 && (
                <>
                  {this.props.showOne && selectedTags.length > 1 ? (
                    <span className="count-muted">
                      +{selectedTags.length - 1}
                    </span>
                  ) : (
                    <>
                      {!this.props.showOne && (
                        <span className="ml2 count-circle">
                          {selectedTags.length}
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
              {showing ? (
                <img src={UpIcon} className="dd-icon" onClick={this.open} />
              ) : (
                <img
                  src={DownIcon}
                  className="dd-icon"
                  onClick={!disabled ? this.open : undefined}
                />
              )}
            </a>
          </div>
          {showing && (
            <div
              className="absolute"
              style={style}
              onKeyDown={this.handleKeyPress}
              onKeyUp={this.handleKeyUp}
            >
              <div className="relative">
                <div className="tf-tri-outer" />
                <div className="tf-tri" />
                <div
                  className={cx({ "bg-white tag-menu": true, box: !inline })}
                >
                  {!skipSearch && (
                    <>
                      <div className="pa2 ph3 search-form-container">
                        <form onSubmit={this.searchSubmit}>
                          <input
                            type="text"
                            onChange={this.onChange}
                            placeholder={`${title} Search`}
                            className="search-form"
                            ref={this.inputRef}
                            tabIndex={iTabIndex}
                          />
                          <input type="submit" className="dn" />
                        </form>
                        {!search ? (
                          <img
                            src={Search}
                            className="search-form-icon c-text"
                            alt="search"
                            onClick={() => {
                              this.inputRef.current.focus();
                            }}
                          />
                        ) : (
                          <div
                            className="search-form-icon pointer"
                            onClick={this.clearSearch}
                          >
                            clear
                          </div>
                        )}
                      </div>

                      {this.props.andOps && (
                        <div
                          className="ph3 pv1 f7 b ttu flex bb b--dww"
                          onClick={this.toggleAndOperation(!boolAnd)}
                        >
                          <div className="flex-grow"></div>
                          <div className="pointer">
                            <span
                              className={cx({
                                pr2: true,
                                gray: !boolAnd,
                                "dww-purple": boolAnd,
                              })}
                            >
                              And
                            </span>
                            <img
                              src={boolAnd ? ToggleSelected : ToggleUnSelected}
                              className=""
                              alt="boolAnd"
                              style={{ marginTop: "-1px" }}
                            />
                            <span
                              className={cx({
                                pl2: true,
                                gray: boolAnd,
                                "dww-purple": !boolAnd,
                              })}
                            >
                              Or
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className="tag-menu-scroll ph2"
                    onScroll={this.handleScroll}
                  >
                    <div className="tag-menu-scroll-container">
                      {selectedTags.length > 0 && (
                        <div className="bb b--black-05 mb2 pb2">
                          {selectedTags.map(tag => (
                            <Empty
                              key={`selected-${tag.id ? tag.id : tag.value}`}
                            >
                              {tag.fullText ? (
                                <div
                                  onClick={this.remove(tag.value, tag.id)}
                                  className="flex pa2 pointer add-hover"
                                >
                                  <div style={{ padding: "0 6px" }}>
                                    <img src={BlueCheck} alt="check" />
                                  </div>
                                  <div className="w-80 dww-blue ttc">
                                    &ldquo;{tag.label}&rdquo;
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {this.hasTag(tag.value, tag.id) && (
                                    <>
                                      <div
                                        onClick={this.remove(tag.value, tag.id)}
                                        className="flex pa2 pointer add-hover"
                                      >
                                        <div style={{ padding: "0 6px" }}>
                                          <img src={BlueCheck} alt="check" />
                                        </div>
                                        <div className="w-80 dww-blue ttc">
                                          {tag.label}{" "}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </Empty>
                          ))}
                        </div>
                      )}
                      {fullText && this.state.search && !searchIsSelected && (
                        <div
                          onClick={this.add(this.state.search, "fulltext")}
                          className="flex pa2 pointer add-hover"
                        >
                          <div
                            style={{ padding: "0 6px", width: "23px" }}
                          ></div>
                          <div className="w-80 ttc">
                            &ldquo;{this.state.search}&rdquo;
                          </div>
                        </div>
                      )}
                      {this.props.addGroup &&
                        filteredTags.length > 0 &&
                        filteredTags[0].group && (
                          <div className="flex pa2">
                            <div
                              style={{ padding: "0 6px", width: "23px" }}
                            ></div>
                            <div className="form-headline">Groups</div>
                          </div>
                        )}
                      {filteredTags.map((tag, i) => (
                        <div
                          key={`${tag.label}-${i}`}
                          className={cx({
                            disabled: this.shouldDisable(tag),
                          })}
                        >
                          {this.hasTag(tag.value, tag.id) ? (
                            <>
                              {i > 0 &&
                                filteredTags[i - 1].group &&
                                !tag.group && (
                                  <div className="form-headline pa2 bt b--dww flex">
                                    <div
                                      style={{
                                        padding: "0 6px",
                                        width: "23px",
                                      }}
                                    ></div>
                                    <div className="w-90">keywords</div>
                                  </div>
                                )}
                              <div
                                onClick={this.remove(tag.value, tag.id)}
                                id={`tag-${tag.value}`}
                                tabIndex={iTabIndex}
                                className={cx({
                                  "flex pa2 pointer add-hover": true,
                                  "add-selected":
                                    selected_tag &&
                                    selected_tag.value === tag.value,
                                })}
                              >
                                <div style={{ padding: "0 6px" }}>
                                  <img
                                    src={BlueCheck}
                                    alt="check"
                                    className={cx({ check: tag.baseTag })}
                                  />
                                </div>
                                <div className="w-90 dww-blue ttc">
                                  {tag.label}{" "}
                                  {tag.baseTag && (
                                    <span
                                      className="sup-tag"
                                      title="Refine by tag"
                                      onClick={this.refine(tag)}
                                    >
                                      &middot;&middot;&middot;
                                    </span>
                                  )}
                                </div>
                                {counts && (
                                  <div className="tr w-20">
                                    {tag.isGroup ? (
                                      <span className="tag-count">
                                        {tag.count && <>{tag.count}</>}
                                        {!tag.count && counts && (
                                          <>
                                            {counts[tag.id]
                                              ? counts[tag.id]
                                              : 0}
                                          </>
                                        )}
                                      </span>
                                    ) : (
                                      <span className="tag-count">
                                        {tag.count && <>{tag.count}</>}
                                        {!tag.count && counts && (
                                          <>
                                            {counts[tag.value]
                                              ? counts[tag.value]
                                              : 0}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                )}
                                {tag.group && (
                                  <>
                                    <a
                                      href=" #"
                                      onClick={this.editCallback(tag)}
                                      className="pl2 f7 ui-button secondary ui-button-small"
                                    >
                                      edit
                                    </a>
                                    <a
                                      href=" #"
                                      onClick={this.deleteCallback(tag)}
                                      className="ml1 pl2 f7 ui-button secondary ui-button-small"
                                      title="Delete keyword group"
                                    >
                                      <img
                                        src={RedDeleteIcon}
                                        alt="delete"
                                        style={{
                                          height: "12px",
                                          width: "12px",
                                        }}
                                      />
                                    </a>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {i > 0 &&
                                filteredTags[i - 1].group &&
                                !tag.group && (
                                  <div className="form-headline pa2 bt b--dww flex">
                                    <div
                                      style={{
                                        padding: "0 6px",
                                        width: "23px",
                                      }}
                                    ></div>
                                    <div className="w-90">keywords</div>
                                  </div>
                                )}
                              <div
                                onClick={this.add(tag.value, tag.id)}
                                id={`tag-${tag.value}`}
                                tabIndex={iTabIndex}
                                className={cx({
                                  "flex pa2 pointer add-hover": true,
                                  "add-selected":
                                    selected_tag &&
                                    selected_tag.value === tag.value,
                                })}
                              >
                                <div
                                  style={{ padding: "0 6px", width: "23px" }}
                                ></div>
                                <div className="w-80 ttc">
                                  {tag.label}{" "}
                                  {tag.baseTag && (
                                    <span
                                      className="sup-tag"
                                      title="Refine by tag"
                                      onClick={this.refine(tag)}
                                    >
                                      &middot;&middot;&middot;
                                    </span>
                                  )}
                                </div>
                                {counts && (
                                  <div className="tr w-20">
                                    {tag.isGroup ? (
                                      <span className="tag-count">
                                        {tag.count && <>{tag.count}</>}
                                        {!tag.count && (
                                          <>
                                            {counts[tag.id]
                                              ? counts[tag.id]
                                              : 0}
                                          </>
                                        )}
                                      </span>
                                    ) : (
                                      <span className="tag-count">
                                        {tag.count && <>{tag.count}</>}
                                        {!tag.count && (
                                          <>
                                            {counts[tag.value]
                                              ? counts[tag.value]
                                              : 0}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                )}
                                {tag.group && (
                                  <>
                                    <a
                                      href=" #"
                                      onClick={this.editCallback(tag)}
                                      className="pl2 f7 ui-button secondary ui-button-small"
                                    >
                                      edit
                                    </a>
                                    <a
                                      href=" #"
                                      onClick={this.deleteCallback(tag)}
                                      className="ml1 pl2 f7 ui-button secondary ui-button-small"
                                      title="Delete keyword group"
                                    >
                                      <img
                                        src={RedDeleteIcon}
                                        alt="delete"
                                        style={{
                                          height: "12px",
                                          width: "12px",
                                        }}
                                      />
                                    </a>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {!inline && (
                    <div className="pa3 bt b--dww flex">
                      {addGroup && (
                        <div className="w-40 flex align-middle">
                          <a
                            href=" #"
                            className="ui-link red-link"
                            onClick={this.addGroup}
                            style={{ marginTop: "7px" }}
                          >
                            <img
                              src={RedPlus}
                              alt="Create ad group"
                              className="pr1"
                            />
                            Create ad group
                          </a>
                        </div>
                      )}
                      <div
                        className={cx({
                          tr: true,
                          "w-60": addGroup,
                          "w-100": !addGroup,
                        })}
                      >
                        <button
                          className="ui-button secondary mr2"
                          onClick={this.clearAll}
                          tabIndex={iTabIndex}
                        >
                          Clear All
                        </button>
                        <button
                          className="ui-button primary"
                          onClick={this.close}
                          tabIndex={iTabIndex}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {showing && !inline && (
          <div className="tag-blocker" onClick={this.open}>
            &nbsp;
          </div>
        )}
      </>
    );
  }
}

export default TagFilter;
