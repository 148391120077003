import { useEffect, useRef, useState } from "react";
import cx from "classnames";
import querystring from "query-string";
import request from "request";

import Modal from "@material-ui/core/Modal";

import * as synonyms from "../../../assets/synonyms.json";
import {
  baseApiUrl,
  getData,
  formatDateFromString,
  capitalize,
  addToStorage as addToStorageUtil,
  getFromStorage,
  generateShareURL,
  copyTextToClipboard,
} from "../../../util";
import "../report.css";

import DashboardHeader from "../../../components/DashboardHeader";
import Empty from "../../../components/Empty";
import Loading from "../../../components/Loading";
import FakeReportBox from "../../../components/FakeReportBox";
import AccessDenied from "../../../components/AccessDenied";
import CreateAnalysis from "../../../components/CreateAnalysis";
import TagSearch from "../../../components/TagSearch";
import MovieFone from "../../../components/MovieFone";
import ViewScreenshotsModal from "../ViewScreenshotsModal";

import FakeOmniFilter from "../../../components/FakeOmniFilter";
import OmniFilter from "../../../components/OmniFilter";
import TagPicker from "../../../components/TagPicker";
import NewFilters from "../../../components/NewFilters";
import MovieFoneInline from "../../../components/MovieFoneInline";
import ReportItemDisplayNew from "../../../components/ReportItemDisplayNew";
import IconOption from "../components/IconOption";

import FilterIcon from "../../../icons/Filter_gray.svg";
import CompanyIcon from "../../../icons/companies_gray.svg";
import WhatsTestedIcon from "../../../icons/WhatsTested_gray.svg";
import CollectionsIcon from "../../../icons/Collections_gray_gray.svg";
import PageIcon from "../../../icons/page.svg";
import DesktopIcon from "../../../icons/desktop_gray.svg";
import X from "../../../icons/tinyX.svg";
import MobileIcon from "../../../icons/mobile_gray.svg";
import TimeIcon from "../../../icons/time.svg";
import ShareIcon from "../../../icons/share.svg";

const SORT_OPTIONS = [
  { label: "Oldest", value: "descending", disabled: false },
  { label: "Newest", value: "ascending", disabled: false },
  { label: "Relevance", value: "relevance", disabled: true },
];
const CACHE_NAME = "alltests-cache";

const AllTestRelated = props => {
  const [customer, setCustomer] = useState(undefined);
  const [accessDenied, setAccessDenied] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [count, setCount] = useState(0);
  const [tests_loading, setTestsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [testLoadingStage, setTestLoadingState] = useState(null);
  const [loadedSavedTests, setLoadedSavedTests] = useState(false);
  const [doChangeLoad, setDoChangeLoad] = useState(false);
  const [latestChanged, setLatestChanged] = useState(false);
  const [publishIds, setPublishIds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [tests, setTests] = useState([]);
  const [analysisData, setAnalysisData] = useState(null);
  const [mode, setMode] = useState("analysis");
  const [analysis_results, setAnalysisResults] = useState(null);
  const [strongFilter, setStrongFilter] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const [editingAnalysis, setEditingAnalysis] = useState(false);
  const [new_analysis, setNewAnalysis] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [analysis_loading, setAnalysisLoading] = useState(false);
  const refs = {
    qs: useRef(null),
    clearQS: useRef(false),
    filterDataDirty: useRef(true),
    filterData: useRef(null),
    testDisplayCount: useRef(0),
    scrollPos: useRef(0),
    scrollTO: useRef(null),
    analysisDirty: useRef(null),
    didFilterUpdate: useRef(false),
    testLoadingTimeout: useRef(null),
    gdTimeout: useRef(null),
    saveTimeout: useRef({}),
    analysisTimeout: useRef(null),
    publish_customer: useRef(null),
    count: useRef(0),
    showTests: useRef(100),
    firstLoad: useRef(true),
    getDataApiLoading: useRef(false),
    filterBarElement: useRef(null),
    showFilterBar: useRef(false),
  };

  const getCachedQS = (clearExistingQueryString = false) => {
    if (refs.qs.current !== null && !clearExistingQueryString) {
      return refs.qs.current;
    } else {
      refs.qs.current = querystring.parse(window.location.search);
      return refs.qs.current;
    }
  };
  const getCompetitorsQueryString = () => {
    const { competitors } = getCachedQS();
    if (competitors) {
      return true;
    }
    return false;
  };
  const getDatesQueryString = () => {
    const { start_date, end_date } = getCachedQS();

    return {
      start_date,
      end_date,
    };
  };
  const getSortQueryString = () => {
    const { sort } = getCachedQS();
    if (sort) {
      return sort;
    }
    return null;
  };
  const getNoOfTagsQueryString = () => {
    const { noOfTags } = getCachedQS();
    if (noOfTags) {
      return noOfTags;
    }
    return "0";
  };
  const getWhoWonQueryString = () => {
    const { whoWon } = getCachedQS();
    return whoWon || "all";
  };
  const getWatchTestQueryString = () => {
    const { watch_test } = getCachedQS();
    return watch_test;
  };
  const getChallengeQueryString = () => {
    const { challenge } = getCachedQS();
    if (challenge) {
      return challenge.split("|");
    }
    return [];
  };
  const getPlatformQueryString = () => {
    const { platform } = getCachedQS();
    if (platform) {
      return platform;
    }
    return "all";
  };
  const getTagQueryString = () => {
    const { tag } = getCachedQS();
    if (tag) {
      const tagArr = tag.split("|");
      return {
        tagsToFilter: tagArr.map(t => t.toLowerCase()),
        originalTagsToFilter: tagArr.map(t => ({
          value: t.toLowerCase(),
          label: t,
          isGroup: false,
          id: "xxx",
        })),
      };
    }
    return { tagsToFilter: [], originalTagsToFilter: [] };
  };
  const getSectorQueryString = () => {
    const { sector } = getCachedQS();
    if (sector) {
      const tagArr = sector.split("$");
      return tagArr.map(t => t.toLowerCase());
    }
    return [];
  };
  const getRecentQueryString = () => {
    const { recent } = getCachedQS();

    if (recent) {
      return recent;
    }
    return null;
  };
  const getGreatQueryString = () => {
    const { great } = getCachedQS();

    if (great) {
      return true;
    }
    return null;
  };
  const getAnalysisQueryString = () => {
    const { analysis } = getCachedQS();

    if (analysis && analysis !== "new") {
      return analysis;
    }
    return null;
  };
  const getOnlyAnalysisQueryString = () => {
    const { onlyAnalysis } = getCachedQS();

    if (onlyAnalysis && props.match.params.company === "admin") {
      return true;
    }
    return false;
  };
  const getPageTypeQueryString = () => {
    const { page_type } = getCachedQS();

    if (page_type) {
      const pageType = page_type.split("|");
      return pageType;
    }
    return [];
  };
  const getStatusQueryString = () => {
    const { status } = getCachedQS();

    if (status) {
      return status;
    }
    return null;
  };
  const getCollectionQueryString = () => {
    const { collection } = getCachedQS();

    if (collection) {
      const cols = collection.split("|");
      return cols;
    }
    if (props.match.params.tid) {
      return [props.match.params.tid];
    }
    return [];
  };
  const getTestTypeQueryString = () => {
    const { test_type } = getCachedQS();

    if (test_type) {
      return test_type;
    }
    return false;
  };
  const getCompanyQueryString = () => {
    const { company } = getCachedQS();

    if (company) {
      return company.split("|");
    }
    return false;
  };
  const getCacheCount = () => {
    const data = getFromStorage(CACHE_NAME);
    return data ? data.cache : null;
  };
  const getUrlParams = () => {
    if (window.location.href.indexOf("?") !== -1) {
      return `?${window.location.href.split("?")[1]}`;
    }
    return null;
  };
  refs.cacheCount = useRef(getCacheCount());
  const [urlParams, setUrlParams] = useState(getUrlParams());
  const [filterState, setFilterState] = useState({
    platform: getPlatformQueryString(),
    competitors: getCompetitorsQueryString(),
    compsToFilter: getCompanyQueryString(),
    challengesToFilter: getChallengeQueryString(),
    tagsToFilter: getTagQueryString().tagsToFilter,
    originalTagsToFilter: getTagQueryString().originalTagsToFilter,
    collectionsToFilter: props.match.params.tid
      ? [props.match.params.tid]
      : getCollectionQueryString(),
    typesToFilter: getPageTypeQueryString(),
    catsToFilter: [],
    statusToFilter: getStatusQueryString(),
    sectorsToFilter: getSectorQueryString(),
    recent: getRecentQueryString(),
    great: getGreatQueryString(),
    sortByDate: getSortQueryString(),
    onlyAnalysis: getOnlyAnalysisQueryString(),
    currentAnalysis: getAnalysisQueryString(),
    start_date: getDatesQueryString().start_date,
    end_date: getDatesQueryString().end_date,
    noOfTags: getNoOfTagsQueryString(),
    whoWon: getWhoWonQueryString(),
    watch_test: getWatchTestQueryString(),
    selectedAnalysis: null,
    state_live: true,
    state_ended: true,
    state_winner: true,
    latest: "all",
    expandTests: true,
    expandShow: true,
    expandAb: true,
    expandTypes: true,
    expandChallenges: true,
  });
  const [dataState, setDataState] = useState({
    report: undefined,
    types: [],
    keywords: undefined,
    companies: [],
    collection_companies: [],
    collection_tags: [],
    challenges: [],
    categories: [],
    tags: [],
    recent_tests: [],
    saved_tests: null,
    note_tags: [],
    totalCompanies: 0,
    customers: [],
    sectors: [],
    sector_lookup: [],
    analysis: [],
  });
  const makeUrlParams = api => {
    const {
      compsToFilter,
      typesToFilter,
      originalTagsToFilter,
      challengesToFilter,
      collectionsToFilter,
      statusToFilter,
      sectorsToFilter,
      platform,
      sortByDate,
      selectedAnalysis,
      currentAnalysis,
      onlyAnalysis,
      start_date,
      end_date,
      noOfTags,
      whoWon,
      watch_test,
      recent,

      noCache,
    } = filterState;
    const encodeAndJoin = arr => arr.map(a => encodeURIComponent(a)).join("|");
    const encodeAndJoinD = arr => arr.map(a => encodeURIComponent(a)).join("$");

    const cacheCount = getCacheCount();

    const urlArr = [];

    if (statusToFilter && statusToFilter !== "all") {
      urlArr.push(`status=${statusToFilter}`);
    }
    if (sectorsToFilter.length > 0) {
      urlArr.push(`sector=${encodeAndJoinD(sectorsToFilter)}`);
    }
    if (compsToFilter.length > 0) {
      urlArr.push(`company=${encodeAndJoin(compsToFilter)}`);
    }
    if (typesToFilter.length > 0) {
      urlArr.push(`page_type=${encodeAndJoin(typesToFilter)}`);
    }
    if (originalTagsToFilter.length > 0) {
      urlArr.push(
        `tag=${originalTagsToFilter
          .map(t => encodeURIComponent(t.isGroup ? t.label : t.value))
          .join("|")}`
      );
    }
    if (challengesToFilter.length > 0) {
      urlArr.push(`challenge=${encodeAndJoin(challengesToFilter)}`);
    }
    if (collectionsToFilter.length > 0) {
      urlArr.push(`collection=${encodeAndJoin(collectionsToFilter)}`);
    }
    if (platform === "mobile" || platform === "desktop") {
      urlArr.push(`platform=${encodeURIComponent(platform)}`);
    }

    if (sortByDate && sortByDate !== "ascending") {
      urlArr.push(`sort=${sortByDate}`);
    }

    if (selectedAnalysis || currentAnalysis) {
      if (currentAnalysis && !selectedAnalysis) {
        urlArr.push(`analysis=${currentAnalysis}`);
      } else {
        urlArr.push(`analysis=${selectedAnalysis.id}`);
      }
    }
    if (onlyAnalysis) {
      urlArr.push(`onlyAnalysis=true`);
    }
    if (start_date) {
      urlArr.push(`start_date=${start_date}`);
    }
    if (end_date) {
      urlArr.push(`end_date=${end_date}`);
    }
    if (noOfTags && noOfTags !== "0" && noOfTags !== "1-6") {
      urlArr.push(`noOfTags=${noOfTags}`);
    }
    if (whoWon && whoWon !== "all") {
      urlArr.push(`whoWon=${whoWon}`);
    }
    if (cacheCount && api) {
      urlArr.push(`cache=${cacheCount}`);
    }
    if (refs.qs.current && noCache) {
      urlArr.push(`noCache=true`);
    }
    if (watch_test) {
      urlArr.push(`watch_test=true`);
    }
    if (recent) {
      urlArr.push(`recent=views`);
    }
    if (urlArr.length == 1 && urlArr[0].startsWith("sort=")) {
      urlArr.pop();
      setFilterState({
        ...filterState,
        sortByDate: null,
      });
    }
    return urlArr;
  };
  const getFilterdData = (skipFilters, skipSave, skipCache, saved_tests) => {
    const filterObj = null;
    const filterArr = makeUrlParams(true);
    const collectionParams = filterArr.find(searchParam =>
      searchParam.startsWith("collection=")
    );
    if (!collectionParams) {
      setLoading(false);
      setLoadedSavedTests(true);
      setCount(0);
      refs.count.current = 0;
      setTests([]);
      // setAnalysisData(data.analysis_data || {});
      setTestsLoading(false);
      refs.getDataApiLoading.current = false;
      setDoChangeLoad(false);
      setTestLoadingState(2);
      return;
    }

    let analysisDirty = "";
    if (refs.analysisDirty.current) {
      analysisDirty = `&aCache=${refs.analysisDirty.current}`;
    }
    const apiUrl = `${baseApiUrl}/v1/fetchrelated_v1?cust=${props.match.params.company}&from=0&size=${refs.showTests.current}&${collectionParams}${analysisDirty}`;
    refs.getDataApiLoading.current = true;
    setTestsLoading(true);
    setLoading(true);
    setTestLoadingState(filtersLoaded ? 1 : 2);
    refs.testLoadingTimeout.current = setTimeout(() => {
      setTestLoadingState(2);
    }, 1000);
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: props.user.token,
        },
      },
      false,
      true,
      skipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          props.history.push(`/subscribe/${props.match.params.company}`);
          return;
        }
        setLoading(true);
        setAccessDenied(res && res.stausCode === 400);
        setTestsLoading(false);
        refs.getDataApiLoading.current = false;
        setTestLoadingState(0);
        return;
      }
      setLoading(false);
      setLoadedSavedTests(true);
      setCount(data.total);
      refs.count.current = data.total;
      setTests(data.data);
      setAnalysisData(data.analysis_data || {});
      setTestsLoading(false);
      refs.getDataApiLoading.current = false;
      setDoChangeLoad(false);
      setTestLoadingState(0);
    });
  };
  const getAllFilters = (skipFilters, skipSave, skipCache, saved_tests) => {
    const filterObj = null;
    const filterArr = makeUrlParams(true);
    const params = filterArr.length > 0 ? `&${filterArr.join("&")}` : "";
    const apiUrl = `${baseApiUrl}/v1/search_filter?cust=${props.match.params.company}&from=0&size=${refs.showTests.current}${params}`;
    getData(
      {
        url: apiUrl,
        headers: {
          Authorization: props.user.token,
        },
      },
      false,
      true,
      skipCache
    ).then(({ err, res, data }) => {
      if (err || res.statusCode >= 300) {
        const errData = JSON.parse(res.body);
        if (errData && errData.error && errData.error === "No Subscription") {
          props.history.push(`/subscribe/${props.match.params.company}`);
          return;
        }
        setLoading(true);
        setAccessDenied(res && res.statusCode === 400);
        return;
      }
      const filters =
        props.saved_search && !latestChanged ? data.search.search : filterObj;
      const selectedCompany = getCompanyQueryString();
      const { start_date, end_date } = getDatesQueryString();

      let compsToFilter = [];
      let newFilters = [];
      let collectionsToFilter;

      if (filterState.recent === "views") {
        newFilters.push({
          label: "Recent Views",
          value: "views",
          type: "recent",
        });
      }
      if (filterState.great) {
        newFilters.push({
          label: "Great Tests",
          value: true,
          type: "great",
        });
      }
      if (filterState.noOfTags !== "0") {
        newFilters.push({
          label: "No Of Tags",
          value: filterState.noOfTags,
          type: "noOfTags",
        });
      }

      if (selectedCompany) {
        compsToFilter = data.companies
          .filter(comp => {
            return selectedCompany.includes(comp.name);
          })
          .map(c => c.name);
        compsToFilter.forEach(c => {
          newFilters.push({ label: c, value: c, type: "companies" });
        });
      }
      if (filterState.competitors && data.customer.competitors) {
        compsToFilter = compsToFilter.concat(data.customer.competitors);
        newFilters.push({
          label: "Competitors",
          value: true,
          displayCount: data.customer.competitors.length,
          type: "competitors",
        });
        data.customer.competitors.forEach(c => {
          newFilters.push({
            label: c,
            value: c,
            type: "companies",
            hide: true,
            competitor: true,
          });
        });
      }
      // TODO: change all these lines to use state then query string
      if (refs.qs.current && refs.qs.current.challenge) {
        const chal = getChallengeQueryString();
        chal.forEach(c => {
          newFilters.push({ label: c, value: c, type: "challenges" });
        });
      }
      if (refs.qs.current && refs.qs.current.status) {
        const status = getStatusQueryString();
        newFilters.push({ label: status, value: status, type: "status" });
      }
      if (refs.qs.current && refs.qs.current.platform) {
        const platform = getPlatformQueryString();
        newFilters.push({ label: platform, value: platform, type: "platform" });
      }
      if (refs.qs.current && refs.qs.current.sector) {
        const sectors = getSectorQueryString();
        sectors.forEach(sector => {
          newFilters.push({
            label: sector.replace(/\|\|/gi, ">"),
            value: sector,
            type: "sectors",
          });
        });
      }
      if (
        (refs.qs.current && refs.qs.current.collection) ||
        props.match.params.tid
      ) {
        collectionsToFilter = getCollectionQueryString();
        collectionsToFilter.forEach(c => {
          const collection = data.saved_tests.find(x => x.id === c);
          if (collection && collection.name) {
            newFilters.push({
              label: collection.name,
              value: c,
              type: "collections",
            });
          }
        });
      }
      let typesToFilter = [];
      if (refs.qs.current && refs.qs.current.page_type) {
        typesToFilter = getPageTypeQueryString();
        typesToFilter.forEach(c => {
          newFilters.push({ label: c, value: c, type: "page_types" });
        });
      }
      if (refs.qs.current && refs.qs.current.watch_test) {
        newFilters.push({
          label: "Watch Test",
          value: true,
          type: "watch_test",
        });
      }
      if (refs.qs.current && refs.qs.current.whoWon) {
        const whoWon = getWhoWonQueryString();
        newFilters.push({
          label: whoWon,
          value: whoWon,
          type: "whoWon",
        });
      }
      const tagsToFilter = [];
      if (refs.qs.current && refs.qs.current.tag) {
        const tag = getTagQueryString().tagsToFilter;
        tag.forEach(t => {
          const tText = t.toLowerCase();
          let tag = data.tags
            .filter(x => x.isGroup)
            .find(x => x.tag.toLowerCase() === tText);

          if (!tag) {
            tag = data.tags.find(x => x.tag.toLowerCase() === tText);
          }
          if (tag) {
            tag.type = "tags";
            tag.label = tag.tag;
            newFilters.push(tag);
            if (typeof tag !== "string" && tag.isGroup) {
              tag.value.forEach(t => {
                tagsToFilter.push(t);
              });
            } else {
              tag.value = tText;
              tagsToFilter.push(t);
            }
          } else {
            newFilters.push({ type: "tags", label: t, value: t });
            tagsToFilter.push(t);
          }
        });
      }
      if (start_date) {
        newFilters.push({
          label: formatDateFromString(start_date),
          value: start_date,
          type: "start_date",
        });
      }
      if (end_date) {
        newFilters.push({
          label: formatDateFromString(end_date),
          value: end_date,
          type: "end_date",
        });
      }

      if (filters && !skipFilters) {
        if (!newFilters.length > 0) {
          filters.compsToFilter.forEach(c => {
            newFilters.push({ label: c, value: c, type: "companies" });
          });
          filters.challengesToFilter.forEach(c => {
            newFilters.push({ label: c, value: c, type: "challenges" });
          });
          const collectionsToFilter =
            filterState.collectionsToFilter.length > 0
              ? filterState.collectionsToFilter
              : filters.collectionsToFilter;
          if (collectionsToFilter) {
            if (data.saved_tests) {
              collectionsToFilter.forEach(c => {
                const collection = data.saved_tests.find(x => x.id === c);
                if (collection) {
                  collection.type = "collections";
                  newFilters.push({
                    label: collection.name,
                    value: c,
                    type: "collections",
                  });
                }
              });
            }
          }
          filters.originalTagsToFilter.forEach(c => {
            newFilters.push(c);
          });
          filters.typesToFilter.forEach(c => {
            newFilters.push({ label: c, value: c, type: "page_types" });
          });
          if (filters.platform && filters.platform !== "all") {
            newFilters.push({
              label: filters.platform,
              value: filters.platform,
              type: "platform",
            });
          }
          if (filters.statusToFilter && filters.statusToFilter !== "all") {
            newFilters.push({
              label: capitalize(filters.statusToFilter),
              value: filters.statusToFilter,
              type: "status",
            });
          }
        }
        setLoading(false);
        setCustomer(data.customer);
        setDataState({
          ...dataState,
          report: data.report,
          types: data.types,
          keywords: data.keywords,
          companies: data.companies,
          collection_companies: data.collection_companies,
          collection_tags: data.collection_tags,
          challenges: data.modules,
          categories: data.categories,
          tags: data.tags,
          recent_tests: data.recent_tests,
          saved_tests: data.saved_tests,
          note_tags: data.tags.map(n => {
            if (n.isGroup) {
              return {
                value: n.value,
                label: n.tag,
                isGroup: true,
                id: n.id,
                baseTag: n.baseTag,
                count: n.count,
              };
            } else {
              return {
                value: n.tag.toLowerCase(),
                label: n.baseTag ? `${n.tag}: All` : n.tag,
                baseTag: n.baseTag,
                count: n.count,
              };
            }
          }),
          //   totalCompanies: Object.keys(data.data).length,
          customers: data.customers,
          sectors: data.new_sectors,
          sector_lookup: data.sector_lookup,
          analysis: data.analysis,
        });
        setFilters(newFilters);
        setDoChangeLoad(false);
        setFiltersLoaded(true);
        setFilterState({
          ...filterState,
          platform: filters.platform ? filters.platform : "all",
          latest: getPlatformQueryString(),
          state_live: filters.state_live,
          state_ended: filters.state_ended,
          state_winner: filters.state_winner,
          compsToFilter: compsToFilter || [],
          challengesToFilter:
            refs.qs.current && refs.qs.current.challenge
              ? getChallengeQueryString()
              : filters.challengesToFilter || [],
          tagsToFilter:
            refs.qs.current && refs.qs.current.tag
              ? tagsToFilter
              : filters.tagsToFilter || [],
          originalTagsToFilter: filters.originalTagsToFilter || [],
          typesToFilter:
            typesToFilter.length > 0
              ? typesToFilter
              : filters.typesToFilter || [],
          catsToFilter: filters.catsToFilter || [],
          statusToFilter: filters.statusToFilter || null,
          collectionsToFilter:
            filterState.collectionsToFilter.length > 0
              ? filterState.collectionsToFilter
              : filters.collectionsToFilter,
          expandChallenges:
            refs.qs.current && refs.qs.current.challenge
              ? true
              : filters.expandChallenges === false
              ? false
              : true,
          start_date,
          end_date,
          selectedAnalysis:
            filterState.currentAnalysis && data.analysis
              ? data.analysis.find(a => a.id === filterState.currentAnalysis)
              : null,
        });
      } else {
        setLoading(false);
        setCustomer(data.customer);
        setDataState({
          ...dataState,
          report: data.report,
          keywords: data.keywords,
          companies: data.companies,
          collection_companies: data.collection_companies,
          collection_tags: data.collection_tags,
          challenges: data.modules,
          categories: data.categories,
          tags: data.tags,
          recent_tests: data.recent_tests,
          saved_tests: data.saved_tests,
          note_tags: data.tags.map(n => {
            if (n.isGroup) {
              return {
                value: n.value,
                label: n.tag,
                isGroup: true,
                id: n.id,
                baseTag: n.baseTag,
                count: n.count,
              };
            } else {
              return {
                value: n.tag.toLowerCase(),
                label: n.baseTag ? `${n.tag}: All` : n.tag,
                baseTag: n.baseTag,
                count: n.count,
              };
            }
          }),
          //   totalCompanies: Object.keys(data.data).length,
          customers: data.customers,
          sectors: data.new_sectors,
          sector_lookup: data.sector_lookup,
          analysis: data.analysis,
          types: data.types,
        });
        setFilters(newFilters);
        setDoChangeLoad(false);
        setFiltersLoaded(true);
        setFilterState({
          ...filterState,
          compsToFilter: compsToFilter ? compsToFilter : [],
          tagsToFilter:
            refs.qs.current && refs.qs.current.tag ? tagsToFilter : [],
          originalTagsToFilter:
            refs.qs.current && refs.qs.current.tag
              ? getTagQueryString().originalTagsToFilter
              : [],
          challengesToFilter:
            refs.qs.current && refs.qs.current.challenge
              ? getChallengeQueryString()
              : [],
          start_date,
          end_date,
          selectedAnalysis:
            filterState.currentAnalysis && data.analysis
              ? data.analysis.find(a => a.id === filterState.currentAnalysis)
              : null,
        });
      }
    });
  };
  const handleScroll = () => {
    clearTimeout(refs.scrollTO.current);

    if (
      window.scrollY + 20000 > document.body.offsetHeight &&
      refs.showTests.current < refs.count.current &&
      !refs.getDataApiLoading.current
    ) {
      refs.showTests.current += 100;
      getFilterdData();
    }

    if (!refs.didFilterUpdate.current) {
      refs.scrollTO.current = setTimeout(() => {
        if (
          // filters &&
          // filters.length > 0 &&
          Math.abs(refs.scrollPos.current - window.scrollY) > 3
        ) {
          if (refs.scrollPos.current <= window.scrollY) {
            console.log(
              "setting ref show filter bar",
              refs.showFilterBar.current
            );
            if (refs.showFilterBar.current) {
              refs.showFilterBar.current = false;
              if (refs.filterBarElement.current) {
                // refs.filterBarElement.current.style.display = "none";
                refs.filterBarElement.current.classList.add("header-animate");
              }
              setShowFilters(false);
            }
          } else {
            if (!refs.showFilterBar.current) {
              refs.showFilterBar.current = true;
              if (refs.filterBarElement.current) {
                // refs.filterBarElement.current.style.display = "";
                refs.filterBarElement.current.classList.remove(
                  "header-animate"
                );
              }
              setShowFilters(true);
            }
          }
        }
        refs.scrollPos.current = window.scrollY;
      }, 50);
    } else {
      refs.didFilterUpdate.current = false;
    }
  };
  const addToStorage = skipData => {
    const urlArr = makeUrlParams();
    const currentURLParams = urlArr.length > 0 ? `?${urlArr.join("&")}` : "";
    if (!props.match.params.ssid) {
      if (skipData) {
        return props.history.push(
          `/home/${props.match.params.company}/related${currentURLParams}`
        );
      }
      if (currentURLParams !== urlParams) {
        props.history.push(
          `/home/${props.match.params.company}/related${currentURLParams}`
        );
        setUrlParams(currentURLParams);
        setDoChangeLoad(true);
        setLoading(true);
      } else {
        props.history.push(
          `/home/${props.match.params.company}/related${currentURLParams}`
        );
      }

      if (!skipData) {
        clearTimeout(refs.gdTimeout.current);
        refs.gdTimeout.current = setTimeout(() => {
          getFilterdData(true);
        }, 50);
      }
    }
    refs.clearQS.current = true;
  };
  const filterCallback = filters => {
    const originalTagsToFilter = filters.filter(c => c.type === "tags");

    const tags = [];

    originalTagsToFilter.forEach(t => {
      if (t.isGroup) {
        t.value.forEach(val => {
          tags.push(val);
        });
      } else {
        tags.push(t.value);
      }
    });

    refs.didFilterUpdate.current = true;
    refs.filterDataDirty.current = true;
    if (filters.length == 1 && filters[0].type === "sort") {
      filters.pop();
    }
    let { sortByDate, tagsToFilter } = filterState;
    if (tags.length === 0 && tagsToFilter.length > 0) {
      sortByDate = "ascending";
    }
    if (tags.length > 0 && tagsToFilter.length === 0) {
      sortByDate = "relevance";
    }
    setFilters(filters);
    refs.showFilterBar.current = filters.length > 0;
    setFilterState({
      ...filterState,
      compsToFilter: filters
        .filter(c => c.type === "companies")
        .map(c => c.value),
      challengesToFilter: filters
        .filter(c => c.type === "challenges")
        .map(c => c.value),
      tagsToFilter: tags,
      originalTagsToFilter,
      typesToFilter: filters
        .filter(c => c.type === "page_types")
        .map(c => c.value),
      collectionsToFilter: filters
        .filter(c => c.type === "collections")
        .map(c => c.value),
      sectorsToFilter: filters
        .filter(c => c.type === "sectors")
        .map(c => c.value),
      sortByDate,
    });
  };
  const handleStatusFilter = status => {
    const newFilters = filters.filter(f => f.type !== "status");
    if (status === "live" || status === "ended") {
      newFilters.push({ label: status, value: status, type: "status" });
    }
    setFilterState({
      ...filterState,
      statusToFilter: status,
    });
    setFilters(newFilters);
    refs.showFilterBar.current = true;
    // setShowFilters(true);
  };
  const handlePlatformFilter = platform => {
    const newFilters = filters.filter(f => f.type !== "platform");
    if (platform === "desktop" || platform === "mobile") {
      newFilters.push({ label: platform, value: platform, type: "platform" });
    }
    setFilterState({
      ...filterState,
      platform,
    });
    setFilters(newFilters);
    refs.showFilterBar.current = true;
    // setShowFilters(true);
  };
  const changeDateSort = val => {
    const newFilters = filters.filter(f => f.type !== "sort");
    if (val !== "ascending") {
      newFilters.push({ label: "Date", value: val, type: "sort" });
    }
    setFilters(newFilters);
    setFilterState({
      ...filterState,
      sortByDate: val,
    });
  };
  const changePlatform = (e, count) => {
    refs.filterDataDirty.current = true;
    const newFilters = filters.filter(f => f.type !== "platform");
    if (e === "desktop" || e === "mobile") {
      newFilters.push({ label: e, value: e, type: "platform" });
    }
    setFilters(newFilters);
    setFilterState({
      ...filterState,
      platform: e,
    });
  };
  const changeNoOfTags = val => {
    const newFilters = filters.filter(f => f.type !== "noOfTags");
    if (val != "1-6") {
      newFilters.push({ label: "No Of Tags", value: val, type: "noOfTags" });
    }
    setFilters(newFilters);
    setFilterState({
      ...filterState,
      noOfTags: val != "1-6" ? val : null,
    });
  };
  const changeWhoWon = val => {
    const newFilters = filters.filter(f => f.type !== "whoWon");
    if (val !== "all") {
      newFilters.push({ label: "WhoWon", value: val, type: "whoWon" });
    }
    setFilters(newFilters);
    setFilterState({
      ...filterState,
      whoWon: val,
    });
  };
  const toggleFilter = e => {
    e.preventDefault();
    setShowFilterSidebar(!showFilterSidebar);
  };
  const removeTag = tag => e => {
    let newFilters = filters.filter(
      t => t.value !== tag.value || t.type !== tag.type
    );
    if (tag.type === "competitors") {
      newFilters = newFilters.filter(t => !t.competitor);
    }

    filterCallback(newFilters);
    if (tag.type === "start_date") {
      setFilterState({
        ...filterState,
        start_date: null,
      });
    }
    if (tag.type === "end_date") {
      setFilterState({
        ...filterState,
        end_date: null,
      });
    }
    if (tag.type === "recent") {
      setFilterState({
        ...filterState,
        recent: null,
      });
    }
    if (tag.type === "platform") {
      setFilterState({
        ...filterState,
        platform: "all",
      });
    }
    if (tag.type === "status") {
      setFilterState({
        ...filterState,
        statusToFilter: null,
      });
    }
    if (tag.type === "noOfTags") {
      setFilterState({
        ...filterState,
        noOfTags: null,
      });
    }
    if (tag.type === "sort") {
      changeDateSort(true);
    }
    if (tag.type === "whoWon") {
      setFilterState({
        ...filterState,
        whoWon: null,
      });
    }
    if (tag.type === "watch_test") {
      setFilterState({
        ...filterState,
        watch_test: null,
      });
    }
  };
  const handleFilterContent = (vals, filterCount = 0) => {
    let newFilters;
    refs.filterDataDirty.current = true;
    newFilters = filters.filter(
      x => x.type !== "start_date" && x.type !== "end_date"
    );
    if (vals.start_date) {
      filterCount++;
      newFilters.push({
        label: formatDateFromString(vals.start_date),
        value: vals.start_date,
        type: "start_date",
      });
    }
    if (vals.end_date) {
      filterCount++;
      newFilters.push({
        label: formatDateFromString(vals.end_date),
        value: vals.end_date,
        type: "end_date",
      });
    }
    setFilters(newFilters);
    setFilterState({
      ...filterState,
      ...vals,
    });
  };
  const updateCache = () => {
    addToStorageUtil(CACHE_NAME, { cache: new Date().getTime() });
  };
  const watchTestCallback = (test_id, watch_test, index) => {
    // console.log(`requesting test ${test_id} watching ${watch_test}`);
    request.post(
      {
        url: `${baseApiUrl}/v1/user/watch_test`,
        headers: {
          Authorization: props.user.token,
        },
        form: {
          test_id,
          watch_test,
        },
      },
      err => {
        if (err) {
          alert("Something went wrong...");
          return;
        }
        // console.log(`watching test`);
        setTimeout(() => {
          const allTests = tests["All Tests"].splits.map((value, i) => {
            if (i == index) {
              return {
                ...value,
                is_watching: watch_test,
              };
            } else {
              return {
                ...value,
              };
            }
          });
          setTests({
            ["All Tests"]: {
              splits: [...allTests],
            },
          });
        }, 500);
      }
    );
  };
  const refreshSavedTests = (saved_tests, isNew) => {
    setTimeout(() => {
      updateCache();
    }, 5000);
    setTimeout(() => {
      getAllFilters(true, true, true, saved_tests);

      if (isNew) {
        addToStorage(true);
      }
    }, 10000);
  };
  const updateCBs = e => {
    const checkboxes = document.querySelectorAll("input.admin-checkbox");

    const ids = [];

    checkboxes.forEach(cb => {
      if (cb.checked) {
        ids.push(cb.value);
      }
    });
    setPublishIds(ids);
  };
  const getAnalysisItems = doLoad => {
    updateCache();
    if (props.match.params.company !== "admin") {
      return;
    }
    if (doLoad) {
      setLoading(true);
      setAnalysisLoading(true);
    }
    if (!filterState.selectedAnalysis) {
      setFilterState({
        ...filterState,
        currentAnalysis: null,
        selectedAnalysis: null,
      });
      return;
    }
    const cache = getCacheCount();
    let cacheStr = "";
    if (cache) {
      cacheStr = `&cache=${cache}`;
    }
    request.get(
      {
        url: `${baseApiUrl}/v1/admin/analysis/item?analysis_key=${filterState.selectedAnalysis.id}${cacheStr}`,
        headers: {
          Authorization: props.user.token,
        },
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        if (json.items) {
          if (!doLoad) {
            setLoading(true);
            setTimeout(() => {
              setAnalysisData(json.items);
              setLoading(false);
              setAnalysisLoading(false);
            }, 100);
          } else {
            setAnalysisData(json.items);
            setLoading(false);
            setAnalysisLoading(false);
          }
        }
      }
    );
  };
  const updateAnalysis = (id, analysis, weighting, selected, analysis_d) => {
    analysisData[id] = {
      analysis,
      analysis_data: analysis_d,
      weighting,
      selected,
      id: analysisData[id] ? analysisData[id].id : null,
    };

    setAnalysisData(analysisData);

    clearTimeout(refs.saveTimeout.current.id);
    clearTimeout(refs.analysisTimeout.current);
    updateCache();

    refs.saveTimeout.current.id = setTimeout(() => {
      if (
        (weighting && selected) ||
        (analysisData[id] && analysisData[id].id)
      ) {
        request.post(
          {
            url: `${baseApiUrl}/v1/admin/analysis/item`,
            headers: {
              Authorization: props.user.token,
            },

            form: {
              item_id: id,
              analysis_key: filterState.selectedAnalysis.id,
              weighting,
              selected,
              id: analysisData[id].id,
              analysis_data: JSON.stringify(analysis_d || {}),
            },
          },
          err => {
            if (err) {
              alert("Something went wrong...");
              return;
            }
            updateCache();
            clearTimeout(refs.analysisTimeout.current);
            refs.analysisTimeout.current = setTimeout(() => {
              getAnalysisItems();
            }, 5000);
          }
        );
      }
    }, 1000);
  };
  const onCloseAnalysis = e => {
    e.preventDefault();
    setAnalysisResults(null);
    setLoading(false);
  };
  const editAnalysis = e => {
    e.preventDefault();
    setEditingAnalysis(true);
  };
  const onChangeAnalysis = vals => {
    const value = vals[0];

    if (value === "new" || value === "") {
      setFilterState({
        ...filterState,
        currentAnalysis: value,
        selectedAnalysis: null,
      });
    } else {
      const selectedAnalysis = dataState.analysis.find(a => a.id === value);

      if (selectedAnalysis) {
        setFilterState({
          ...filterState,
          currentAnalysis: value,
          selectedAnalysis,
        });
        setAnalysisData(null);
      }
    }
  };
  const deleteAnalysis = (id, analysisName) => {
    if (
      window.confirm(
        `Are you sure you want to delete this analysis ${analysisName} ?`
      )
    ) {
      request.delete(
        {
          url: `${baseApiUrl}/v1/admin/analysis`,
          headers: {
            Authorization: props.user.token,
          },

          form: {
            id: id,
          },
        },
        err => {
          if (err) {
            alert("Something went wrong...");
            return;
          }
          setTimeout(() => {
            updateCache();
            setFilterState({
              ...filterState,
              currentAnalysis: null,
              selectedAnalysis: null,
            });
            getAllFilters(true, true, true);
          }, 1000);
        }
      );
    }
  };
  const findAnalysisName = key => {
    if (!filterState.selectedAnalysis.analysis_props) {
      return "";
    }
    const item = filterState.selectedAnalysis.analysis_props.find(
      a => a.key === key
    );

    if (item) {
      return item.name;
    } else {
      return "???";
    }
  };
  const analyze = e => {
    setDoChangeLoad(true);
    const { sectorsToFilter } = filterState;
    request.get(
      {
        url: `${baseApiUrl}/v1/admin/analyze?analysis_key=${
          filterState.selectedAnalysis.id
        }&strong_filter=${strongFilter}${
          sectorsToFilter ? `&sectors=${sectorsToFilter.join("*|*")}` : ""
        }`,
        headers: {
          Authorization: props.user.token,
        },
      },
      (err, res, data) => {
        const json = JSON.parse(data);
        if (json.analysis) {
          setAnalysisResults(json.analysis);
          setDoChangeLoad(false);
        } else {
          setDoChangeLoad(false);
        }
      }
    );
  };
  const toggleShowOnlyAnalysis = show => e => {
    e.preventDefault();
    setFilterState({
      ...filterState,
      onlyAnalysis: show,
    });
  };
  const changeMode = mode => e => {
    e.preventDefault();
    setMode(mode);
  };
  const changeStrongFilter = e => {
    setStrongFilter(!strongFilter);
    analyze(null, false);
  };
  const onChangeCustomer = e => {
    const customer = e.currentTarget.value;
    setTimeout(() => {
      refs.publish_customer.current = customer;
    }, 0);
  };
  const publishToCustomer = e => {
    e.preventDefault();
    setPublishing(true);
    if (!refs.publish_customer.current) {
      alert("Pick a customer");
      setPublishing(false);
      return;
    }
    if (publishIds.length <= 0) {
      setPublishing(false);
      alert("Pick some tests");
      return;
    }
    setTimeout(() => {
      request.post(
        {
          url: `${baseApiUrl}/v1/admin/publish`,
          headers: {
            Authorization: props.user.token,
          },

          form: {
            ids: publishIds.join(","),
            report: refs.publish_customer.current,
          },
        },
        err => {
          if (err) {
            alert("Something went wrong...");
            return;
          }
          setPublishing(false);
        }
      );
    }, 0);
  };
  const copyShareUrl = url => {
    const shareUrl =
      url || generateShareURL(window.location.href, props.match.params.company);
    copyTextToClipboard(shareUrl)
      .then(() => {
        setUrlCopied(true);
        setTimeout(() => {
          setUrlCopied(false);
        }, 1500);
      })
      .catch(err => {
        setUrlCopied(false);
      });
  };
  useEffect(() => {
    if (refs.firstLoad.current) {
      refs.firstLoad.current = false;
    } else {
      addToStorage();
    }
  }, [filters, filterState]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (props.user && props.user.customer.semOnly) {
      props.history.replace(`/home/${props.match.params.company}/sem`);
      return;
    }
    if (
      props.match.params.id === "modules" ||
      props.match.params.company === "new"
    ) {
      return;
    }

    getAllFilters(false);
    getFilterdData();
    return () => {
      refs.qs.current = null;
      window.removeEventListener("scroll", handleScroll);
      refs.filterDataDirty.current = true;
      refs.filterData.current = null;
      refs.testDisplayCount.current = 0;
    };
  }, []);
  useEffect(() => {
    if (new_analysis) {
      getAllFilters(true, true, true);
      addToStorage();
    }
  }, [new_analysis]);
  useEffect(() => {
    if (filterState.selectedAnalysis) {
      getAnalysisItems(true);
    }
  }, [filterState.selectedAnalysis]);
  useEffect(() => {
    console.log("use effect called", refs.showFilterBar.current);
    setShowFilters(refs.showFilterBar.current);
  }, [refs.showFilterBar.current]);
  const isEmpty =
    props.match.params.id === "modules" || props.match.params.company === "new";
  if (isEmpty) {
    return <Empty />;
  }

  const formatNoOfTags = value => {
    let [min, max] = value.split("-");
    let formattedValue = "";
    if (min && max && max == min) {
      return max;
    }
    if (min && max && max == min && min == "6") {
      return "6+";
    }
    if (min && !max) {
      return `min ${min}`;
    }

    return `${min}-${max == "6" ? "6+" : max}`;
  };
  const width = 600;
  const height = 480;
  const data = tests["All Tests"] ? tests["All Tests"].splits : [];
  const displayFilters = filters.filter(f => !f.hide);
  const sortOptions = SORT_OPTIONS.map(item => ({
    ...item,
    disabled:
      filterState.tagsToFilter.length == 0 && item.value === "relevance"
        ? true
        : false,
  }));
  let defaultSortOptions;
  if (filterState.tagsToFilter.length > 0) {
    if (filterState.sortByDate) {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === filterState.sortByDate
      );
    } else {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === "relevance"
      );
    }
  } else {
    if (
      (filterState.sortByDate && filterState.sortByDate === "relevance") ||
      !filterState.sortByDate
    ) {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === "ascending"
      );
    } else {
      defaultSortOptions = SORT_OPTIONS.find(
        sortOption => sortOption.value === filterState.sortByDate
      );
    }
  }
  defaultSortOptions.disabled = false;
  if (displayFilters.length > 0 && !showFilters) {
    console.log("setting show filter state");
    // setShowFilters(true);
  }
  // console.log(props);
  return (
    <>
      <div
        style={props.match.params.ssid ? { overflow: "hidden!important" } : {}}
      >
        <DashboardHeader
          customer={props.match.params.company}
          name={customer ? customer.name : null}
          customerData={customer}
          selected="report"
          reportId={props.match.params.id}
        />
        <div
          ref={refs.filterBarElement}
          className={cx({
            "sticky-ui-container header-shadow": true,
            "header-animate":
              displayFilters &&
              displayFilters.length > 0 &&
              (showFilters || refs.showFilterBar.current),
          })}
        >
          {filtersLoaded ? (
            <>
              <div className="document-spacing center flex tags-container">
                <OmniFilter
                  title_slug="omniFilter"
                  company={props.match.params.company}
                  token={props.user.token}
                  challenges={
                    dataState.challenges
                      ? dataState.challenges.map(c => ({
                          value: c.name,
                          label: c.name,
                          type: "challenges",
                          count: c.count,
                        }))
                      : null
                  }
                  companies={
                    dataState.companies
                      ? dataState.companies.map(c => ({
                          value: c.name,
                          label: c.name,
                          count: c.count,
                        }))
                      : null
                  }
                  tags={dataState.note_tags ? dataState.note_tags : null}
                  note_counts={
                    data["All Tests"] ? data["All Tests"].note_counts : {}
                  }
                  company_counts={
                    data["All Tests"] ? data["All Tests"].company_counts : {}
                  }
                  collections_counts={
                    data["All Tests"]
                      ? data["All Tests"].collections_counts
                      : {}
                  }
                  page_counts={
                    data["All Tests"] ? data["All Tests"].page_counts : {}
                  }
                  collections={
                    dataState.saved_tests
                      ? dataState.saved_tests.map(c => ({
                          label: c.name,
                          value: c.id,
                          count: c.count,
                        }))
                      : null
                  }
                  page_types={
                    dataState.types
                      ? dataState.types.map(c => ({
                          label: c.name,
                          value: c.name,
                          count: c.count,
                        }))
                      : null
                  }
                  sectors={
                    dataState.sectors
                      ? dataState.sectors.map(c => ({
                          label: c.display_name,
                          value: c.key,
                        }))
                      : null
                  }
                  synonyms={synonyms.default}
                  callback={filterCallback}
                  statusCallback={handleStatusFilter}
                  platformCallback={handlePlatformFilter}
                  defaultOptions={filters}
                  isSticky={true}
                  noSort
                  showOne
                  fixAt={{
                    width: 1000,
                    right: "0px",
                  }}
                  count={count}
                  status={filterState.statusToFilter}
                  platform={filterState.platform}
                  isAdminPage={props.match.params.company === "admin"}
                />

                <div className="dib" style={{ paddingTop: "2px" }}>
                  <div
                    className="tag-filter-button solo-button"
                    onClick={toggleFilter}
                  >
                    <img
                      src={FilterIcon}
                      alt="filters"
                      className="icon"
                      height="12"
                    />
                  </div>
                </div>
              </div>
              {displayFilters && displayFilters.length > 0 && (
                <div className="filter-tags">
                  <div className="document-spacing center flex pl2 pr2">
                    <div className="filters flex">
                      {displayFilters.slice(0, 5).map(f => {
                        let icon;
                        if (f.type === "sort") return;
                        if (f.type === "companies") {
                          icon = CompanyIcon;
                        } else if (
                          f.type === "collections" ||
                          f.type === "challenges"
                        ) {
                          icon = CollectionsIcon;
                        } else if (f.type === "tags") {
                          icon = WhatsTestedIcon;
                        } else if (f.type === "page_types") {
                          icon = PageIcon;
                        }
                        return (
                          <div
                            className="f-tag"
                            key={`${f.type}-${f.id ? f.id : f.value}`}
                          >
                            <span className="f-tag-x" onClick={removeTag(f)}>
                              <img src={X} alt="remove" />
                            </span>
                            <span>
                              {f.type === "start_date" && (
                                <span className="f-status sb">From: </span>
                              )}
                              {f.type === "end_date" && (
                                <span className="f-status sb">To: </span>
                              )}
                              {f.type === "status" && (
                                <span className="f-status sb">
                                  {capitalize(f.name ? f.name : f.label)}
                                </span>
                              )}
                              {f.type === "whoWon" && (
                                <span className="f-status sb">
                                  {f.value === "no_winner"
                                    ? capitalize("no winner")
                                    : capitalize(f.value) + ` Won`}
                                </span>
                              )}
                              {f.type === "noOfTags" && (
                                <>
                                  {capitalize(
                                    `${formatNoOfTags(
                                      f.value
                                    )} variables tested`
                                  )}
                                </>
                              )}
                              {f.type !== "platform" &&
                                f.type !== "status" &&
                                f.type !== "noOfTags" &&
                                f.type !== "sort" &&
                                f.type !== "whoWon" && (
                                  <>{capitalize(f.name ? f.name : f.label)} </>
                                )}
                              {f.type === "platform" &&
                                f.value === "desktop" && (
                                  <img
                                    src={DesktopIcon}
                                    className="f-tag-icon"
                                    alt={f.type}
                                    style={{
                                      marginTop: "-2px",
                                      marginLeft: "2px",
                                    }}
                                  />
                                )}

                              {f.type === "platform" &&
                                f.value === "mobile" && (
                                  <img
                                    src={MobileIcon}
                                    className="f-tag-icon"
                                    style={{
                                      marginTop: "-2px",
                                      marginLeft: "2px",
                                    }}
                                    alt={f.type}
                                  />
                                )}
                            </span>
                            {f.type === "recent" && (
                              <img
                                src={TimeIcon}
                                className="f-tag-icon"
                                style={{
                                  marginLeft: "8px",
                                }}
                                alt={f.type}
                              />
                            )}
                            {icon && (
                              <img
                                src={icon}
                                className="f-tag-icon"
                                alt={f.type}
                              />
                            )}
                            {f.displayCount && (
                              <span className="ml2 count-circle">
                                {f.displayCount}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="f5 flex items-center sort">
                      <TagPicker
                        options={sortOptions}
                        callback={changeDateSort}
                        defaultOptions={defaultSortOptions}
                        title=""
                        inline
                        absolute
                        disabledEffect
                        title_slug="sortbyDate"
                      />
                    </div>
                    {displayFilters.length > 5 && (
                      <div className="f-tag f-tag-more w-20">
                        +{displayFilters.length - 5} more
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="document-spacing center flex tags-container">
              <FakeOmniFilter
                synonyms={synonyms.default}
                isSticky={true}
                showOne
                fixAt={{
                  width: 1000,
                  right: "0px",
                }}
                isAdminPage={props.match.params.company === "admin"}
                title_slug="fakeOmniFilter"
              />
              <div className="dib" style={{ paddingTop: "2px" }}>
                <div className="tag-filter-button solo-button tag-filter-disabled">
                  <img
                    src={FilterIcon}
                    alt="filters"
                    className="icon"
                    height="12"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showFilterSidebar && (
          <NewFilters
            closeCallback={toggleFilter}
            callback={handleFilterContent}
            platformCallback={changePlatform}
            dateCallback={changeDateSort}
            dateValue={filterState.sortByDate}
            filterCount={filterState.end_date || filterState.start_date ? 1 : 0}
            platform={filterState.platform}
            isAdmin={props.user.company === "admin"}
            latest={filterState.latest}
            end_date={filterState.end_date}
            start_date={filterState.start_date}
            noOfTagsCallback={changeNoOfTags}
            noOfTagsValue={filterState.noOfTags}
            whoWonCallback={changeWhoWon}
            whoWon={filterState.whoWon}
          />
        )}
        {!loading && data && Object.keys(data).length === 0 && (
          <div className="document-spacing center">
            <div
              className="b tc"
              style={{ paddingTop: "50px", paddingBottom: "36px" }}
            >
              Nothing found, try removing some filters.
            </div>
            <MovieFoneInline
              user={props.user}
              tagsToFilter={filterState.tagsToFilter}
              compsToFilter={filterState.compsToFilter}
              company={props.match.params.company}
            />
          </div>
        )}
        <div>
          {((tests_loading && testLoadingStage == 1 && filtersLoaded) ||
            doChangeLoad) && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100vh",
                textAlign: "center",
                background: "rgba(255,255,255,0.4)",
                zIndex: "100000",
                overflow: "hidden",
              }}
            >
              <Loading />
            </div>
          )}
          {tests_loading && testLoadingStage == 2 && (
            <>
              <div>
                <div className="mt3">
                  <FakeReportBox />
                </div>
                <div className="mt3">
                  <FakeReportBox />
                </div>
                {accessDenied && <AccessDenied />}
              </div>
            </>
          )}
          {data &&
            (mode === "analysis" ? analysisData : true) &&
            data.map((d, y) => {
              d.screenshots.sort((a, b) => (a.champ ? -1 : 1));
              d.savedTests = d.collections || [];
              const showNotes =
                d.screenshots[0].notes && d.screenshots[0].notes.length > 0;
              if (showNotes && loadedSavedTests) {
                if (analysis_loading) {
                  return (
                    <div
                      key={`ri-${d.id}`}
                      style={{ width: "100%", height: "100%" }}
                    ></div>
                  );
                }
                return (
                  <>
                    <ReportItemDisplayNew
                      d={d}
                      i={y}
                      watchTestCallback={watchTestCallback}
                      userCompany={props.user.company}
                      urlCompany={props.match.params.company}
                      byModule={props.byModule}
                      key={`ri-${d.id}`}
                      token={props.user.token}
                      uid={props.user.uid}
                      user={props.user}
                      savedTests={
                        dataState.saved_tests ? dataState.saved_tests : null
                      }
                      urlParams={urlParams ? urlParams : []}
                      savedTestsCallback={refreshSavedTests}
                      getDataCallback={doFilters => {
                        setTimeout(() => {
                          getFilterdData(false);
                          if (doFilters) {
                            getAllFilters();
                          }
                        }, 3000);
                        setTimeout(() => {
                          updateCache();
                        }, 5000);
                      }}
                      collectionId={props.match.params.tid}
                      isSavedTests={props.isSavedTests}
                      isAdmin={props.match.params.company === "admin"}
                      onCBchange={updateCBs}
                      analysisMode={mode === "analysis"}
                      selectedAnalysis={
                        filterState.selectedAnalysis
                          ? filterState.selectedAnalysis
                          : null
                      }
                      updateData={updateAnalysis}
                      analysisData={
                        analysisData[d.id] ? analysisData[d.id] : null
                      }
                    />
                    {d.highlights && (
                      <>
                        <div
                          className="pa1"
                          style={{ maxHeight: "100px", overflow: "scroll" }}
                        >
                          <p>Match</p>
                          {d.highlights.unique_tags.map(element => (
                            <p
                              className="pa0 ph2"
                              dangerouslySetInnerHTML={{ __html: element }}
                            ></p>
                          ))}
                        </div>
                        <div className="ma3 b">Score: {d.score}</div>
                      </>
                    )}
                  </>
                );
              } else {
                return <Empty key={`ri-${d.id}`}></Empty>;
              }
            })}
        </div>
        {props.match.params.company === "admin" && (
          <>
            {analysis_results && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `90vw`,
                    height: `90vh`,
                    overflow: "scroll",
                    top: "3vh",
                    position: "absolute",
                    left: "3.5vw",
                  }}
                >
                  <div className="pa3 bb b--black-05">
                    <h1>
                      Analysis for {filterState.selectedAnalysis.analysis_name}
                    </h1>
                    <label>
                      <input
                        type="checkbox"
                        onChange={changeStrongFilter}
                        checked={strongFilter}
                      />{" "}
                      Only use strong tests
                    </label>
                  </div>

                  {doChangeLoad ? (
                    <Loading />
                  ) : (
                    <div className="pa3">
                      {filterState.sectorsToFilter &&
                        filterState.sectorsToFilter.length !== 0 && (
                          <div className="mb3">
                            <span className="b">Filtering by sectors: </span>
                            {filterState.sectorsToFilter
                              .join(", ")
                              .replace(/\|\|/gi, ">>")}
                          </div>
                        )}
                      {Object.keys(analysis_results).map(key => {
                        const aKeys = Object.keys(analysis_results[key]);

                        const hasEnough = Object.values(
                          analysis_results[key]
                        ).find(item => item.test_count >= 1);

                        if (!hasEnough) {
                          return <></>;
                        }

                        return (
                          <div className="pb4">
                            <h4>
                              {key === "all" && <>Overall Score </>}
                              {key !== "all" && <>{key}</>}
                            </h4>
                            {aKeys.map(skey => {
                              const item = analysis_results[key][skey];

                              return (
                                <div className="pt2">
                                  <div className="pt2">
                                    {findAnalysisName(skey)} &middot;{" "}
                                    {item.percentage > 70 && (
                                      <span className="green b">Good Bet</span>
                                    )}
                                    {item.percentage < 70 &&
                                      item.percentage > 60 && (
                                        <span className="green b">
                                          Weak Good Bet
                                        </span>
                                      )}
                                    {item.percentage < 60 &&
                                      item.percentage >= 45 && (
                                        <span className="yellow b">Tossup</span>
                                      )}
                                    {item.percentage < 45 &&
                                      item.percentage > 35 && (
                                        <span className="red b">
                                          Weak Bad Bet
                                        </span>
                                      )}
                                    {item.percentage < 35 && (
                                      <span className="red b">Bad Bet</span>
                                    )}{" "}
                                    ({item.percentage}%, {item.win_count}/
                                    {item.test_count} tests won &middot; Control
                                    won {item.control_won_percentage}
                                    %)
                                  </div>
                                  Tests took {item.total_days} total days to run
                                  &middot; Median: {item.avg_days} days &middot;
                                  Shortest: {item.min_days} days &middot;
                                  Longest: {item.max_days} days
                                  {item.pval && (
                                    <div>
                                      Confidence:
                                      <span
                                        className={cx({
                                          b: true,
                                          green: item.pval >= 95,
                                          yellow:
                                            item.pval < 95 && item.pval >= 90,
                                          red: item.pval < 90,
                                        })}
                                      >
                                        {" "}
                                        {item.pval}%
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                      <button
                        className="ui-button primary"
                        onClick={onCloseAnalysis}
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
              </Modal>
            )}
            {(filterState.currentAnalysis === "new" || editingAnalysis) && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `${width}px`,
                    height: `480px`,
                    overflow: "scroll",
                    top: (window.innerHeight - height) / 2,
                    position: "absolute",
                    left: (window.innerWidth - width) / 2,
                  }}
                >
                  <CreateAnalysis
                    user={props.user}
                    doClose={editing => {
                      if (editing) {
                        setEditingAnalysis(false);
                        getFilterdData(true, true, true, null, true);
                      } else {
                        setFilterState({
                          ...filterState,
                          currentAnalysis: "",
                          selectedAnalysis: null,
                        });
                      }
                      updateCache();
                      setTimeout(() => {
                        updateCache();
                        setNewAnalysis(true);
                      }, 1000);
                    }}
                    currentAnalysis={filterState.selectedAnalysis}
                    editing={editingAnalysis}
                    allAnalysis={[
                      ...dataState.analysis,
                      ...dataState.saved_tests,
                    ]}
                  />
                </div>
              </Modal>
            )}
            <div className="sticky-submit flex justify-end">
              {urlCopied ? (
                <span className="f8 mr-auto">Copied!</span>
              ) : (
                <img
                  aria-label="share"
                  src={ShareIcon}
                  color="primary"
                  className="pointer mh3 mr-auto"
                  height="26"
                  width="26"
                  onClick={() => copyShareUrl("")}
                  style={{
                    marginTop: "7px",
                  }}
                />
              )}
              {mode === "analysis" && (
                <>
                  <div className="mr3 pt1">
                    <a
                      href=" #"
                      onClick={changeMode("publish")}
                      className="dib ui-button tiny secondary"
                    >
                      Publish Mode
                    </a>
                  </div>
                  {filterState.currentAnalysis &&
                    filterState.currentAnalysis !== "new" && (
                      <div className="mr3 pt1">
                        {filterState.onlyAnalysis ? (
                          <a
                            href=" #"
                            onClick={toggleShowOnlyAnalysis(false)}
                            className="dib ui-button tiny secondary"
                          >
                            Show all tests
                          </a>
                        ) : (
                          <a
                            href=" #"
                            onClick={toggleShowOnlyAnalysis(true)}
                            className="dib ui-button tiny secondary"
                          >
                            Only show tests in analysis
                          </a>
                        )}
                      </div>
                    )}
                  {filterState.currentAnalysis &&
                    filterState.currentAnalysis !== "new" && (
                      <div className="mr3 pt1">
                        <a
                          className="dib ui-button tiny secondary mr2"
                          onClick={editAnalysis}
                        >
                          Edit
                        </a>
                      </div>
                    )}
                  <div className="mr3 w-35">
                    {dataState.analysis && dataState.analysis.length > 0 && (
                      <TagSearch
                        defaultTags={
                          filterState.selectedAnalysis
                            ? [
                                {
                                  label: filterState.selectedAnalysis.name,
                                  value: filterState.currentAnalysis,
                                },
                              ]
                            : []
                        }
                        options={[{ label: "New", value: "new" }].concat(
                          dataState.analysis.map(a => ({
                            label: a.name,
                            value: a.id,
                          }))
                        )}
                        singular
                        onBottom
                        callback={onChangeAnalysis}
                        optionComponent={IconOption}
                        onDeleteAnalysis={deleteAnalysis}
                      />
                    )}
                  </div>
                  <div className="flex-end">
                    {doChangeLoad ? (
                      <>Analyzing...</>
                    ) : (
                      <button className="ui-button primary" onClick={analyze}>
                        Analyze
                      </button>
                    )}
                  </div>
                </>
              )}
              {mode === "publish" && (
                <>
                  <div className="mr3 pt1">
                    <a
                      href=" #"
                      onClick={changeMode("analysis")}
                      className="dib ui-button tiny secondary mr2"
                    >
                      Analysis Mode
                    </a>
                  </div>
                  <div className="mr3 sticky-submit-box">
                    Tests: <b className="ml2">{publishIds.length}</b>
                  </div>
                  <div className="mr3">
                    <select
                      name="customers"
                      className="sticky-submit-box"
                      onChange={onChangeCustomer}
                    >
                      <option value="">-- Pick a customer --</option>
                      <option value="4a07cc72-a482-41cc-a68e-d649d3c1cc04">
                        Test
                      </option>
                      {dataState.customers.map(c => (
                        <option value={c.report} key={`c-${c.report}`}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-end">
                    {publishing ? (
                      <>Publishing...</>
                    ) : (
                      <button
                        className="ui-button primary"
                        onClick={publishToCustomer}
                      >
                        Publish
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {data && Object.keys(data).length > 0 && (
          <MovieFone
            user={props.user}
            tagsToFilter={filterState.tagsToFilter}
            compsToFilter={filterState.compsToFilter}
            company={props.match.params.company}
          />
        )}
        {props.match.params.ssid && (
          <ViewScreenshotsModal
            id={props.match.params.ssid}
            company={props.match.params.company}
            user={props.user}
            history={props.history}
            collectionId={props.match.params.tid}
            isSavedTests={props.isSavedTests}
            urlParams={urlParams}
            testsCallback={getFilterdData}
            watchTestCallback={watchTestCallback}
          />
        )}
      </div>
    </>
  );
};
export default AllTestRelated;
