import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import EditCustomer from "./components/EditCustomer";
import Dashboard from "./Dashboard";
import { baseApiUrl, getCopyData } from "../../util";
import EditIcon from "../../icons/edit_blue.svg";
import cx from "classnames";
import { Link } from "react-router-dom";

class Companies extends React.Component {
  state = {
    loading: true,
    companies: [],
    customers: [],
    allCompanies: [],
    allCustomers: [],
    addNew: false,
    type: "real",
    editCompanyKey: null,
    toCopy: null,
  };
  componentDidMount() {
    this.getData();
  }
  showAddNew = e => {
    e.preventDefault();
    this.setState({ addNew: true });
  };
  getData = () => {
    this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/customers`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          companies: json.modules,
          allCompanies: json.companies,
          customers: json.customers.filter(c => c.real),
          allCustomers: json.customers,
          sectors: json.sectors,
          reports: json.reports.sort((a, b) =>
            `${a.company}: ${a.name}` >= `${b.company}: ${b.name}` ? 1 : -1
          ),
          new_sectors: json.new_sectors,
          plans: json.plans,
          timezones: json.timezones,
          prices: json.prices,
        });
      }
    );
  };

  changeType = e => {
    this.setState({ type: e.currentTarget.value });
  };

  filterCustomers = () => {
    const { type, allCustomers } = this.state;
    if (type === "real") {
      return allCustomers.filter(c => c.real);
    } else if (type === "demo") {
      return allCustomers.filter(c => c.demo);
    }
    return allCustomers;
  };

  handleEditCompany = companyKey => e => {
    this.setState({
      editCompanyKey: companyKey,
    });
  };
  closeEditCompany = e => {
    this.setState({
      editCompanyKey: null,
    });
  };
  render() {
    const customers = this.filterCustomers();
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.props.match.params.id) {
      const customer = this.state.allCustomers.find(
        c => c.key === this.props.match.params.id
      );
      return (
        <Dashboard
          dashboard={customer.dashboard}
          customer_name={customer.name}
          customer_key={customer.key}
          saveDashboard={this.saveDashboard}
          loading={false}
          token={this.props.user.token}
        />
      );
    }
    const { sectors, plans, reports, allCompanies, new_sectors, prices } =
      this.state;

    return (
      <div>
        <h1>Customers</h1>
        <div className="mv3">
          <b>Show: </b>
          <select
            name="type"
            onChange={this.changeType}
            value={this.state.type}
          >
            <option value="all">All Customers</option>
            <option value="real">Real Customers</option>
            <option value="demo">Demo Customers</option>
          </select>
        </div>
        {!this.state.addNew ? (
          <div className="mt3">
            <a href=" #" onClick={this.showAddNew} className="f4">
              Add New
            </a>
          </div>
        ) : (
          <EditCustomer
            new
            sectors={sectors}
            companies={allCompanies}
            new_sectors={new_sectors}
            reports={reports}
            plans={plans}
            token={this.props.user.token}
            timezones={this.state.timezones}
            allCompanies={this.state.allCompanies}
            prices={prices}
            callback={() => {
              setTimeout(() => {
                this.getData();
              }, 1000);
            }}
            copyCallback={data => {
              this.setState({ toCopy: data.data });
            }}
            toCopy={this.state.toCopy ? this.state.toCopy : null}
          />
        )}
        {customers.map((c, index) => {
          if (c.key === this.state.editCompanyKey) {
            return (
              <EditCustomer
                urlkey={c.key}
                tld={c.tld}
                name={c.name}
                key={c.key}
                plan={c.plan}
                report={c.report}
                real={c.real}
                demo={c.demo}
                sectors={sectors}
                customerCompanies={c.modules}
                customerProblems={c.problems}
                company={c}
                companies={allCompanies}
                addCompanies={c.add_companies}
                blockCompanies={c.block_companies}
                new_sectors={new_sectors}
                newSectors={c.new_sectors ? c.new_sectors.map(s => s) : null}
                competitors={c.competitors}
                impURLs={c.important_urls}
                dashboard={c.dashboard}
                tags={c.tags}
                negative_tags={c.negative_tags}
                reports={reports}
                plans={plans}
                prices={prices}
                price={c.sub}
                token={this.props.user.token}
                user={this.props.user}
                callback={() => {
                  setTimeout(() => {
                    this.getData();
                  }, 1000);
                }}
                timezones={this.state.timezones}
                timezone={c.timezone}
                paid={c.paid}
                freeTrial={c.freeTrial}
                semOnly={c.semOnly}
                abOnly={c.abOnly}
                invite={c.invite}
                closeEditCompany={this.closeEditCompany}
                index={index}
                totalUsers={c.totalUsers}
                adminUsers={c.adminUsers}
                seats={c.seats}
                unlock_limit={c.unlock_limit || 0}
                copyCallback={data => {
                  console.info("CALLLBACK!!!!", data);
                  this.setState({ toCopy: data });
                }}
                toCopy={this.state.toCopy ? this.state.toCopy : null}
              />
            );
          } else {
            return (
              <div
                className={cx(
                  "flex items-center pointer hide-child bb b--divider",
                  {
                    "test-box": index % 2 === 0,
                    "test-box-odd": index % 2 !== 0,
                  }
                )}
                onClick={this.handleEditCompany(c.key)}
              >
                <h4 className="pt3 mr2 grow">{c.name} </h4>
                <div className="pt3">
                  {" "}
                  {c.seats
                    ? `${c.totalUsers} of ${c.seats} users`
                    : `${c.totalUsers} users`}{" "}
                  ({c.adminUsers} {c.adminUsers === 1 ? `admin` : `admins`}){" "}
                  <Link to={`/account/users?id=${c.key}`}>Manage Users</Link>
                </div>

                <div className="tr flex justify-between items-end pt3 ml-auto">
                  <img
                    src={EditIcon}
                    alt="edit company"
                    className="icon child"
                    height="24"
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default Companies;
