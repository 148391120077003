import React from "react";
import * as cx from "classnames";
import { useDraggable } from "@dnd-kit/core";
import NewEditableAutocompleteV2 from "../../../components/NewEditableAutocompleteV2";
import DragIcon from "../../../icons/drag_indicator.svg";
export const DraggableNotes = React.memo(props => {
  const {
    img,
    i,
    posN,
    posI,
    user,
    n,
    changePos,
    note,
    serverNoteTags,
    addSuggestionData,
    deleteSuggestionData,
    suggestionBox,
    moveUp,
    moveDown,
    deleteNoteAndPos,
    changeHide,
    screenshots,
    doFullPagePos,
    resetPos,
    updateNotes,
  } = props;
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: props.id,
      data: {
        type: props.type || "",
        index: props.index,
        screenshotIndex: props.screenshotIndex,
      },
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      className={cx(
        {
          "bg-light-silver": isDragging,
          "opacity-95": isDragging,
          "z-max": isDragging,
        },
        "w-100"
      )}
      style={style}
    >
      <div
        className={cx("mb1 pb1 bb b--black-05", {
          "z-max": isDragging,
        })}
        key={`note-${i}-${n}`}
      >
        <div className="flex">
          <div className="w-1 pa1 red b">
            {n + 1}
            <label
              onClick={changePos(i, n)}
              className="flex"
              style={{ marginBottom: 0 }}
            >
              <span className="dib">
                {img.posXY && img.posXY[n] && img.posXY[n].x ? (
                  <input type="radio" checked={posI === i && posN === n} />
                ) : (
                  <span className="bg-red">
                    <input type="radio" checked={posI === i && posN === n} />
                  </span>
                )}
              </span>
            </label>
          </div>
          <div className={cx("w-90", {})}>
            <NewEditableAutocompleteV2
              placeholder="Note"
              items={serverNoteTags}
              token={user.token}
              subtags={serverNoteTags}
              callback={updateNotes(i, n)}
              focusCallback={changePos(i, n)}
              initialValue={note}
              key={`note_${note}`}
              cKey={`note_${note}`}
              className="f6 w-100"
              addSuggestionData={addSuggestionData(i, n)}
              deleteSuggestionData={deleteSuggestionData(i, n)}
              suggestionData={
                suggestionBox[i] && suggestionBox[i][n]
                  ? suggestionBox[i][n]
                  : null
              }
            />
          </div>
          <div className="w-10">
            <img
              src={DragIcon}
              className="icon"
              alt="drag"
              {...listeners}
              {...attributes}
            />
          </div>
          <a
            onClick={deleteNoteAndPos(i, n)}
            href="#"
            className="pl1 pt1 f7 red b"
          >
            X
          </a>
        </div>
        <div className="tl f7 flex" style={{ marginLeft: "22px" }}>
          <label className="flex">
            <input
              type="checkbox"
              onChange={changeHide(i, n)}
              checked={screenshots[i].hide && screenshots[i].hide.includes(n)}
              style={{ marginTop: "2px" }}
              className="mr1"
            />{" "}
            Hide from customers
          </label>
          <span className="ml2">&middot;</span>
          {img.posXY &&
          img.posXY[n] &&
          img.posXY[n].x === 0 &&
          img.posXY[n].y === 0 ? (
            <span className="f7 ml2">Full page</span>
          ) : (
            <a onClick={doFullPagePos(i, n)} className="f7 ml2" href=" #">
              Full page tag
            </a>
          )}
          <span className="ml2">&middot;</span>
          {img.pos &&
            img.pos[n] &&
            (!img.posXY || !img.posXY[n] || !img.posXY[n].x) && (
              <span className="ml2">{img.pos[n]}</span>
            )}
          {img.posXY && img.posXY[n] && img.posXY[n].x && (
            <a onClick={resetPos(i, n)} href=" #" className="ml2 f7">
              Reset all positions
            </a>
          )}
        </div>
      </div>
    </div>
  );
});
