import React from "react";
import request from "request";
import {
  CHALLENGES,
  COLLECTION_TYPES,
  baseApiUrl,
  challengeLookup,
} from "../util";
import { Link } from "react-router-dom";
import TagPicker from "./TagPicker";
import BookmarkIcon from "../icons/bookmark_off_solo.svg";
import AddToAnalysisIcon from "../icons/add_box_purple.svg";
import { addToStorage, getFromStorage } from "../util";
import UserIcon from "../icons/user.svg";
import xIcon from "../icons/red_x.svg";
import Modal from "@material-ui/core/Modal";
import InputCounter from "./InputCounter";
import * as cx from "classnames";

const CACHE_NAME = "alltests-cache";

class CollectionsInline extends React.Component {
  state = {
    show: false,
    name: undefined,
    id: null,
    deleteId: null,
    addedTests: this.props.d.savedTests,
    savedTests: this.props.savedTests,
    collections: this.props.collections,
    showDelete: false,
    onLoad: true,
    deleteName: "",
  };
  updateCache = () => {
    addToStorage(CACHE_NAME, { cache: new Date().getTime() });
  };
  showBookmark = e => {
    if (this.props.freeTrial) {
      this.props.triggerFreeTrialPopup(e);
    } else {
      this.setState({ show: !this.state.show });
    }
  };
  handleNameChange = val => {
    const id = this.state.id !== "new" ? undefined : this.state.id;
    this.setState({ name: val, id });
  };
  handleSelect = val => {
    this.setState({ id: val, name: undefined });
  };
  close = () => {
    this.setState({ show: false });
  };
  doStar = () => {
    const { token, urlCompany, d, uid } = this.props;
    const { addedTests } = this.state;
    const doDelete = addedTests && addedTests.includes(uid);
    const apiUrl = doDelete
      ? `${baseApiUrl}/v1/delete_favorite`
      : `${baseApiUrl}/v1/favorite`;
    const vals = {
      id: d.id,
      type: "abtest",
      company: urlCompany,
    };
    this.setState({
      show: false,
      name: "",
      doingFavCall: true,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: apiUrl,
        form: vals,
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          this.setState({ error: true });
          return;
        }
        this.updateCache();
        const data = JSON.parse(resp.body);
        let addedTests = data.savedTests.map(x => x.id);
        if (doDelete) {
          addedTests.filter(x => x !== uid);
        } else {
          addedTests.push(uid);
        }
        this.setState({
          savedTests: data.allTests,
          addedTests,
          doingFavCall: false,
        });
        d.savedTests = addedTests;
        if (
          this.props.getDataCallback &&
          doDelete &&
          uid === this.props.collectionId
        ) {
          this.props.getDataCallback(true, true, true);
        } else {
          this.props.savedTestsCallback(data.allTests);
        }
      }
    );
  };
  showDelete = (id, name) => e => {
    this.setState({
      showDelete: true,
      deleteId: id,
      deleteName: name,
    });
  };
  doDelete = id => e => {
    const { d, token, urlCompany } = this.props;
    const vals = {
      saved_id: id,
      id: d.id,
      company: urlCompany,
    };
    this.setState({
      deleteId: null,
      deleteName: null,
      showDelete: false,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: `${baseApiUrl}/v1/delete_saved_tests`,
        form: vals,
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          this.setState({ error: true });
          return;
        }
        const data = JSON.parse(resp.body);
        const addedTests = data.savedTests.map(x => x.id);
        this.updateCache();
        this.setState({
          savedTests: data.allTests,
          collections: data.savedTests,
          addedTests,
          doingSaveCall: false,
        });
        d.savedTests = addedTests;
        this.props.savedTestsCallback(data.allTests);
      }
    );
    this.setState({ name: "", doingSaveCall: true });
  };
  closeModal = () => {
    this.setState({ showDelete: false, onLoad: true });
  };
  doSave = () => {
    const { d, token, urlCompany } = this.props;
    const { id, name } = this.state;

    let sId = id;

    if (!sId) {
      const lastItem = getFromStorage(`last-test-${urlCompany}`);
      if (lastItem && lastItem.value) {
        sId = lastItem.value;
      }
    }

    const vals =
      sId && sId !== "new"
        ? {
            saved_id: sId,
            id: d.id,
            type: "abtest",
            company: urlCompany,
          }
        : {
            name,
            id: d.id,
            type: "abtest",
            company: urlCompany,
          };
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: `${baseApiUrl}/v1/saved_tests`,
        form: vals,
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          this.setState({ error: true });
          return;
        }
        this.updateCache();
        const data = JSON.parse(resp.body);
        const addedTests = data.savedTests.map(x => x.id);
        this.setState({
          savedTests: data.allTests,
          collections: data.savedTests,
          addedTests,
        });
        d.savedTests = addedTests;
        this.props.savedTestsCallback(data.allTests);
        if (id === "new") {
          const item = data.allTests.find(t => t.name === name);
          if (item) {
            addToStorage(`last-test-${urlCompany}`, {
              value: item.id,
              label: item.name,
            });
          }
        } else {
          addToStorage(`last-test-${urlCompany}`, { value: sId, label: "" });
        }
      }
    );
    this.setState({
      show: false,
      name: "",
      id: this.state.id === "new" ? null : this.state.id,
    });
  };
  closeErrorModal = () => {
    this.setState({ error: false });
  };
  render() {
    const { urlCompany, uid, freeTrial, triggerFreeTrialPopup, challenges } =
      this.props;

    const { addedTests, doingSaveCall, savedTests, collections } = this.state;

    const usedTests = savedTests.filter(
      t => addedTests && addedTests.includes(t.id)
    );
    const testsForDD = addedTests
      ? savedTests.filter(t => !addedTests.includes(t.id) && !t.isFav)
      : savedTests.filter(t => !t.isFav);

    let lastItem = getFromStorage(`last-test-${urlCompany}`);

    if (lastItem && lastItem.label === "") {
      const realItem = testsForDD.find(x => x.id === lastItem.value);

      if (realItem) {
        lastItem.label = realItem.name;
      } else {
        lastItem.id = undefined;
      }
      if (this.state.show === true) {
        if (lastItem.value !== this.state.id && this.state.onLoad) {
          this.setState({ id: lastItem.value, onLoad: false });
        } else if (this.state.onLoad) {
          this.setState({ onLoad: false });
        }
      }
    }

    const width = 600;
    const height = 200;
    const title =
      this.props.type === COLLECTION_TYPES.collection
        ? "Collections"
        : "Analysis";
    return (
      <div
        className={cx({
          mb0: this.props.type === COLLECTION_TYPES.collection,
          mb3: this.props.type === COLLECTION_TYPES.analysis,
        })}
      >
        <div className="sb pb2 bb b--black-05 pl2 f7 ttu">
          {title}
          <a
            className="ui-link pointer dib add-to-collection"
            onClick={this.showBookmark}
            style={{ float: "right" }}
          >
            <img
              src={
                this.props.type === COLLECTION_TYPES.collection
                  ? BookmarkIcon
                  : AddToAnalysisIcon
              }
              height={24}
              width={24}
              alt="Add to collection"
              title="Add to collection"
              className="pointer icon"
            />
          </a>
        </div>
        <div className="ph2 pt2 w-100">
          <div className="w-100 collections-inline">
            {this.state.show && (
              <div className="mb4">
                <div className="mb1">
                  {testsForDD && testsForDD.length > 0 ? (
                    <>
                      <div className="w-100">
                        <TagPicker
                          options={(this.props.type ===
                          COLLECTION_TYPES.collection
                            ? [
                                {
                                  label: "Add a new collection",
                                  value: "new",
                                },
                              ]
                            : []
                          ).concat(
                            testsForDD.map(t => ({
                              label: t.name,
                              value: t.id,
                            }))
                          )}
                          defaultOptions={lastItem}
                          callback={this.handleSelect}
                          autoPosition={
                            this.props.type === COLLECTION_TYPES.analysis
                          }
                          title={title}
                          isSticky
                          noSort
                          small
                          hasSearch
                          title_slug="collections"
                        />
                      </div>
                      {this.state.id === "new" && (
                        <>
                          <InputCounter
                            name="name"
                            className="tag-filter w-100"
                            placeholder="Name your collection"
                            callback={this.handleNameChange}
                            total={40}
                          />
                          <p className="f7 dww-gray tl">
                            <b>For example:</b> Landing page ideas, Signup page
                            ideas, Interesting CTAs for new home page.
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    this.props.type === COLLECTION_TYPES.collection && (
                      <>
                        <InputCounter
                          name="name"
                          className="tag-filter w-100"
                          placeholder="Name your collection"
                          callback={this.handleNameChange}
                          total={40}
                        />
                        <p className="f7 dww-gray tl">
                          <b>For example:</b> Landing page ideas, Signup page
                          ideas, Interesting CTAs for new home page.
                        </p>
                      </>
                    )
                  )}
                </div>
                <button onClick={this.doSave} className="ui-button primary">
                  Save
                </button>{" "}
                <a className="ml2 pointer" href=" #" onClick={this.close}>
                  close
                </a>
              </div>
            )}
            {this.props.type === COLLECTION_TYPES.collection &&
              challenges
                .filter(challenge => !!challenge)
                .map(challenge => (
                  <div
                    className="mb3 collections-item"
                    key={`challenge-${challenge}`}
                  >
                    <Link
                      to={`/home/${
                        this.props.company
                      }/report?challenge=${encodeURIComponent(challenge)}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {
                        CHALLENGES.find(ch => ch.value === challenge)
                          .collectionName
                      }
                    </Link>
                    <div className="dww-gray f7">
                      <img
                        src={UserIcon}
                        className="user-icon mr2"
                        alt="User"
                      />
                      DoWhatWorks HQ
                    </div>
                  </div>
                ))}
            {collections.map(collection => (
              <div className="mb3 collections-item" key={`c-${collection.id}`}>
                {this.props.type === COLLECTION_TYPES.analysis ? (
                  <Link
                    to={`/home/${this.props.company}/report?analysis=${collection.id}&from=detail&onlyAnalysis=true`}
                    style={{ textTransform: "capitalize" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {collection.name}
                  </Link>
                ) : (
                  <Link
                    to={`/home/${this.props.company}/report?collection=${collection.id}&from=detail`}
                    style={{ textTransform: "capitalize" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {collection.name}
                  </Link>
                )}
                <span
                  className="ml2 red pointer collections-delete"
                  onClick={this.showDelete(collection.id, collection.name)}
                >
                  <img
                    src={xIcon}
                    width="8"
                    height="8"
                    alt="remove"
                    title="Remove from collection"
                  />
                </span>
                {collection.user && (
                  <div className="dww-gray f7">
                    <img src={UserIcon} className="user-icon mr2" alt="User" />
                    {collection.user.name === "DoWhatWorks Team"
                      ? "DoWhatWorks HQ"
                      : collection.user.name}
                  </div>
                )}
              </div>
            ))}
            <Modal
              open={this.state.error ? true : false}
              className="dww-modal-container"
            >
              <div
                className="box"
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  overflow: "hidden",
                  top: (window.innerHeight - height) / 2,
                  position: "absolute",
                  left: (window.innerWidth - width) / 2,
                  outline: 0,
                }}
              >
                <div>
                  <h3 className="pv3 ph4 bb b--black-05">
                    Looks like something went wrong&hellip;
                  </h3>
                  <div className="ph4 pv2">Please try again.</div>
                  <div className="ph4 pv3">
                    <button
                      className="ui-button primary"
                      onClick={this.closeErrorModal}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal open={this.state.showDelete} className="dww-modal-container">
              <div
                className="box"
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  overflow: "hidden",
                  top: (window.innerHeight - height) / 2,
                  position: "absolute",
                  left: (window.innerWidth - width) / 2,
                  outline: 0,
                }}
              >
                <div>
                  <h3 className="pv3 ph4 bb b--black-05">Are you sure?</h3>
                  <div className="ph4 pv2">
                    Are you sure you want to remove this test from "
                    {this.state.deleteName}"?
                  </div>
                  <div className="ph4 pv3">
                    <button
                      className="ui-button secondary mr3"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="ui-button primary"
                      onClick={this.doDelete(this.state.deleteId)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default CollectionsInline;
