import React from "react";

class Job extends React.Component {
  render() {
    return (
      <div className="center tc w-80 w-100-m br3 overflow-hidden">
        <iframe
          src={this.props.url}
          frameborder="0"
          style={{ height: "80vh", width: "100%", margin: "0 auto" }}
          title="Job"
        ></iframe>
      </div>
    );
  }
}

export default Job;
