import React from "react";
import request from "request";
import cx from "classnames";
import { Link } from "react-router-dom";
import ReportDates from "./ReportDates";
import DesktopIcon from "../icons/Desktop.svg";
import MobileIcon from "../icons/Mobile.svg";
import UserIcon from "../icons/user.svg";
import ShareIcon from "../icons/share.svg";
import WatchOnIcon from "../icons/watch_on.svg";
import WatchOffIcon from "../icons/watch_off.svg";
import WatchUsersIcon from "../icons/watching_users.svg";
import AdminEdit from "../icons/edit_admin.svg";
import robotIcon from "../icons/robot.svg";
import BookmarkIconOn from "../icons/bookmark_on_solo.svg";
import ScreenshotItem from "./ScreenshotItem";
import Collections from "./Collections";
import FreeTrialActions from "./FreeTrialActions";
import UnlockTest from "./UnlockTest";
import Empty from "./Empty";
import ShareTest from "./ShareTest";
import {
  reportLookup,
  moduleLookup,
  capitalize,
  imageUrl,
  copyTextToClipboard,
  baseApiUrl,
  formatDateFromString,
  formatReportDate,
  getWinnerText,
  generateNoteText,
} from "../util";
import AdminShareTest from "./AdminShareTest";
import StarIcon from "../icons/star_solo.svg";

const status_map = {
  draft_review: "Draft",
  analyst_review: "Screener Feedback",
  analyst_feedback: "Writer Feedback",
  approval_review: "Approval",
  publish_review: "Publishing",
  draft_hold: "Hold",
};

const buildVariantTest = test => {
  if (!test.screenshots || !test.screenshots[0].choose_variant) {
    return null;
  }
  const variants = test.screenshots.map(t => {
    if (t.choose_variant) {
      t.choose_variant.name = t.choose_variant.key;
      return t.choose_variant;
    }
    return null;
  });
  return variants;
};

class ReportItemDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      name: undefined,
      id:
        props.savedTests && props.savedTests.length > 0
          ? props.savedTests[0].id
          : undefined,
      savedTests: props.savedTests,
      addedTests: props.d.savedTests || [],
      showAllCollections: false,
      preloaded: false,
      analysis: props.analysisData ? props.analysisData.analysis : null,
      weighting: props.analysisData
        ? props.analysisData.weighting
        : props.d.weight,
      checked: props.analysisData ? props.analysisData.selected : false,
      analysis_data:
        props.analysisData && props.analysisData.analysis_data
          ? props.analysisData.analysis_data
          : buildVariantTest(props.d),
      urlCopied: false,
      watchTest: props.d.is_watching,
      isWatchAPILoading: false,
      isChecked: props.isChecked || false,
      showAdminBox: true,
      showMoreNotes: this.props.isQueue,
      showMoreComments: this.props.isQueue,
    };
  }
  showAllCollections = e => {
    e.preventDefault();
    this.setState({ showAllCollections: true });
  };
  timeout = null;
  preloadImages = () => {
    if (this.props.freeTrial && !this.props.d.testUnlocked) return;
    this.timeout = setTimeout(() => {
      if (!this.state.preloaded) {
        this.setState({ preloaded: true });
        const { screenshots } = this.props.d;
        if (screenshots) {
          screenshots.forEach(ss => {
            new Image().src = imageUrl(ss.src, 575, 0, 2);
          });
        }
      }
    }, 500);
  };
  cancelPreload = () => {
    if (this.props.freeTrial && !this.props.d.testUnlocked) return;
    clearTimeout(this.timeout);
  };
  onChangeData = e => {
    const { type, name, value, checked } = e.currentTarget;
    if (type === "checkbox") {
      this.setState(
        prevState => {
          if (prevState.checked && !checked) {
            return {
              checked,
              weighting: "",
              analysis_data: [],
            };
          } else {
            return { checked: checked };
          }
        },
        () => {
          const { analysis, weighting, checked, analysis_data } = this.state;
          this.props.updateData(
            this.props.d.id,
            analysis,
            weighting,
            checked,
            analysis_data
          );
        }
      );
    } else {
      this.setState({ [name]: value }, () => {
        const { analysis, weighting, checked, analysis_data } = this.state;
        this.props.updateData(
          this.props.d.id,
          analysis,
          weighting,
          checked,
          analysis_data
        );
      });
    }
  };
  changeAnalysisData = data => {
    const { analysis, weighting, checked } = this.state;
    this.props.updateData(this.props.d.id, analysis, weighting, checked, data);
  };
  triggerUnlockModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showUnlockModal: true });
    setTimeout(() => {
      this.setState({ showUnlockModal: false });
    }, 100);
  };

  watchTestCallback = (test_id, watch_test) => {
    // console.log(`requesting test ${test_id} watching ${watch_test}`);
    this.setState({
      isWatchAPILoading: true,
      watchTest: watch_test,
    });
    request.post(
      {
        url: `${baseApiUrl}/v1/user/watch_test`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          test_id,
          watch_test,
        },
      },
      err => {
        if (err) {
          alert("Something went wrong...");
          this.setState({
            isWatchAPILoading: false,
            watchTest: !watch_test,
          });
          return;
        }
        // console.log(`watching test`);
        this.setState({
          isWatchAPILoading: false,
          watchTest: watch_test,
        });
      }
    );
  };
  render() {
    const {
      d,
      userCompany,
      urlCompany,
      byModule,
      isAdminPage,
      savedTests,
      i,
      isAdmin,
      selectedAnalysis,
      analysisMode,
      showAdminBox,
      analysis,
      collectionMode,
      //watchTestCallback,
    } = this.props;

    const maxCollections = 2;

    const { addedTests } = this.state;

    const usedTests = savedTests
      ? savedTests.filter(t => addedTests && addedTests.includes(t.id))
      : [];

    const testsDisplay = [];

    if (usedTests.length > 0) {
      if (d.savedTests && d.savedTests.length) {
        d.savedTests.forEach((t, i) => {
          const test = savedTests.find(x => t === x.id);
          if (test) {
            if (test.user && test.user.name) {
              test.displayName = test.user.name;
            } else {
              test.displayName =
                d.savedTestsName && d.savedTestsName[i]
                  ? d.savedTestsName[i]
                  : this.props.user.name;
            }
            testsDisplay.push(test);
          }
        });
      }
    }

    testsDisplay.sort((a, b) => (a.isFav ? 1 : -1));

    let partUrl;

    if (this.props.urlParams) {
      partUrl = `report/screenshots/${d.id}${this.props.urlParams}`;
    } else {
      partUrl = this.props.isSavedTests
        ? `report/saved_test/${this.props.collectionId}/screenshots/${d.id}`
        : `report/screenshots/${d.id}${byModule ? "?fromModule=true" : ""}`;
    }
    const url = `/home/${urlCompany}/${partUrl}`;
    const shortUrl = `${window.location.origin}/link/${partUrl}`;
    return (
      <div
        className={cx({
          "test-box": i % 2 === 0,
          "test-box-odd": i % 2 !== 0,
        })}
        onMouseOver={this.preloadImages}
        onMouseOut={this.cancelPreload}
      >
        <div
          className={cx({
            "db near-black block-link document-spacing center": true,
            pb1: isAdminPage,
            pb4: !isAdminPage,
          })}
        >
          {isAdmin && analysisMode && selectedAnalysis && (
            <div className="pt2 flex">
              <input
                type="checkbox"
                className="mr3 admin-checkbox"
                value={d.id}
                onChange={this.onChangeData}
                checked={this.state.checked}
              />
              {collectionMode === "analysis" && (
                <select
                  name="weighting"
                  onChange={this.onChangeData}
                  className="ml3"
                  value={this.state.weighting}
                >
                  <option value="">-- pick a weight --</option>
                  <option value="1">Normal</option>
                  <option value=".5">Weak</option>
                  <option value=".25">Weakest</option>
                  <option value="1.25">Strong</option>
                  <option value="1.5">Strongest</option>
                </select>
              )}
            </div>
          )}
          <div className="mb3 near-black bb b--divider item-header flex flex-row justify-around items-center">
            {isAdmin && !analysisMode && (
              <input
                type="checkbox"
                className="mr3 admin-checkbox"
                value={d.id}
                onChange={e => {
                  setTimeout(this.props.onCBchange(e), 0);
                }}
                checked={this.props.isChecked}
              />
            )}
            <div className="flex-grow">
              <Link to={url} className="ui-link">
                <span>{capitalize(d.headline_tag || d.headline)}</span>
              </Link>
            </div>
            <div
              style={{
                minWidth: "150px",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
              className="flex"
            >
              {this.props.freeTrial ? (
                <FreeTrialActions
                  testId={d.id}
                  user={{ ...this.props.user, token: this.props.token }}
                  company={urlCompany}
                  testUnlocked={this.props.d.testUnlocked}
                  unlockedTests={this.props.unlockedTests}
                  unlockedBy={
                    this.props.allUnlockedTests &&
                    this.props.allUnlockedTests.find(
                      t => t.unlockedBy && t.unlockedBy === "admin"
                    )
                      ? "admin"
                      : "user"
                  }
                  unlock_limit={this.props.unlock_limit}
                />
              ) : (
                <>
                  {userCompany === "admin" &&
                    this.props.isQueue &&
                    d.priorityCompany && (
                      <img
                        src={StarIcon}
                        height="20"
                        width="20"
                        className="share-icon"
                        alt="Priority Company"
                        title="Priority company"
                        // style={{ marginTop: "-4px" }}
                      />
                    )}
                  {userCompany === "admin" && (
                    <Link
                      to={`/admin/screenshots/edit/${d.id}?back=${window.location.href}`}
                      className="edit-link-icon db tc"
                      title="Edit Test"
                    >
                      <img
                        src={AdminEdit}
                        height="20"
                        width="20"
                        className="share-icon"
                        style={{ marginTop: "-4px" }}
                      />
                    </Link>
                  )}
                  {urlCompany === "admin" ? (
                    <AdminShareTest
                      testId={d.id}
                      user={{ ...this.props.user, token: this.props.token }}
                      company={urlCompany}
                    />
                  ) : this.props.showShare ? (
                    <ShareTest
                      testId={d.id}
                      user={{ ...this.props.user, token: this.props.token }}
                      company={urlCompany}
                    />
                  ) : null}
                  {userCompany === "admin" && (
                    <>
                      {d.isTestRecommended && (
                        <img
                          title="Recommended Test"
                          aria-label="recommended test"
                          src={robotIcon}
                          color="primary"
                          className={cx("pointer", "share-icon")}
                          height="32"
                          width="32"
                        />
                      )}
                    </>
                  )}
                  {savedTests && (
                    <Collections
                      savedTests={savedTests}
                      urlCompany={this.props.urlCompany}
                      uid={this.props.uid}
                      d={this.props.d}
                      token={this.props.token}
                      savedTestsCallback={this.props.savedTestsCallback}
                      collectionId={this.props.collectionId}
                      getDataCallback={this.props.getDataCallback}
                      showCollection={true}
                    />
                  )}
                  {savedTests && analysis && (
                    <Collections
                      savedTests={analysis}
                      urlCompany={this.props.urlCompany}
                      uid={this.props.uid}
                      d={this.props.d}
                      token={this.props.token}
                      savedTestsCallback={this.props.savedTestsCallback}
                      collectionId={this.props.collectionId}
                      getDataCallback={this.props.getDataCallback}
                      showAnalysis={true}
                    />
                  )}
                  <div>
                    <img
                      title={
                        this.state.watchTest
                          ? `Un-watch this test.`
                          : `Watch this test. We'll let you know when we call a winner.`
                      }
                      aria-label="watch"
                      src={this.state.watchTest ? WatchOnIcon : WatchOffIcon}
                      color="primary"
                      className={cx("pointer", {
                        "o-10": this.state.isWatchAPILoading,
                      })}
                      height="26"
                      width="26"
                      onClick={() =>
                        this.state.isWatchAPILoading
                          ? true
                          : this.watchTestCallback(d.id, !this.state.watchTest)
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={cx({
              "ph3 pb3 flex ss-container-parent": true,
              has2: d.screenshots && d.screenshots.length <= 2,
            })}
          >
            <div className="screenshot-container">
              {d.screenshots && (
                <ScreenshotItem
                  userCompany={userCompany}
                  screenshots={d.screenshots}
                  freeTrial={this.props.freeTrial}
                  testUnlocked={this.props.freeTrial ? d.testUnlocked : true}
                  id={d.id}
                  key={`ss-${d.id}`}
                  type={d.type}
                  isAdminPage={isAdminPage}
                  isQueue={this.props.isQueue}
                  url={url}
                  company={urlCompany}
                  selectedAnalysis={
                    isAdmin && analysisMode && selectedAnalysis
                      ? selectedAnalysis
                      : null
                  }
                  collectionMode={collectionMode}
                  changeData={this.changeAnalysisData}
                  analysis={this.state.analysis_data}
                  triggerUnlockModal={this.triggerUnlockModal}
                />
              )}
              {isAdmin && d.watch_users_count > 0 && (
                <div className="tl f5">
                  <img
                    src={WatchUsersIcon}
                    height="22"
                    aria-label="watching users count"
                    className="pr1"
                  />
                  {d.watch_users_count > 1
                    ? `${d.watch_users_count} users are `
                    : `a user is `}
                  watching this test
                </div>
              )}
            </div>
            <div className="w-25 ss-info-box-container">
              <div className="ss-info-box-all mt2">
                <div className="company-box">
                  <a
                    href={`/home/${urlCompany}/report?company=${d.company_name}`}
                    className="company"
                  >
                    {d.company_name}
                    {d.country && d.country !== "us" ? (
                      <> &middot; {d.country.toUpperCase()}</>
                    ) : (
                      ""
                    )}
                  </a>
                  <div className="floating-icon">
                    {d.type === "desktop" && (
                      <span>
                        <img
                          src={DesktopIcon}
                          width="48px"
                          alt="Desktop"
                          style={{ marginBottom: "3px" }}
                        />{" "}
                      </span>
                    )}
                    {d.type === "mobile" && (
                      <span>
                        <img
                          src={MobileIcon}
                          width="48px"
                          alt="Mobile"
                          style={{ marginBottom: "3px" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <ReportDates
                    start_date={d.start_date}
                    end_date={d.end_date}
                  />
                </div>

                {usedTests && usedTests.length > 0 && (
                  <div className="f7 pa2 tc mt2 collections-list">
                    <div className="box-header b pb2 dww-gray">Collections</div>
                    {testsDisplay.map((t, x) => {
                      if (
                        !this.state.showAllCollections &&
                        x >= maxCollections
                      ) {
                        return <Empty key={`${x}-${d.id}`} />;
                      }
                      return (
                        <div className="pb2" key={`${x}-${d.id}`}>
                          <a
                            href={`/home/${this.props.urlCompany}/saved_test/${t.id}`}
                            className=""
                          >
                            {t.isFav ? "Favorites" : t.name}
                          </a>
                          {t.displayName && (
                            <div className="dww-gray">
                              <img
                                src={UserIcon}
                                className="user-icon"
                                alt="User"
                              />{" "}
                              {t.displayName === "DoWhatWorks Team"
                                ? "DoWhatWorks HQ"
                                : t.displayName.split(" ")[0]}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {!this.state.showAllCollections &&
                      testsDisplay.length > maxCollections && (
                        <a
                          href=" #"
                          onClick={this.showAllCollections}
                          className="dww-gray ttu f8 b"
                        >
                          +{testsDisplay.length - maxCollections} more
                        </a>
                      )}
                  </div>
                )}
                {this.props.freeTrial && (
                  <div className="pt4 tc">
                    <UnlockTest
                      testId={d.id}
                      user={{ ...this.props.user, token: this.props.token }}
                      company={urlCompany}
                      testUnlocked={this.props.d.testUnlocked}
                      unlockedTests={this.props.unlockedTests}
                      unlockedBy={
                        this.props.allUnlockedTests &&
                        this.props.allUnlockedTests.find(
                          t => t.test_id === d.id
                        )
                      }
                      showModal={this.state.showUnlockModal}
                      unlock_limit={this.props.unlock_limit}
                      reloadCallback={() => {
                        this.props.getDataCallback();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isAdminPage && d.reps && (
            <div className="f6 ml3 ba b--blue pa2 br3">
              <b>Published to:</b>{" "}
              {d.reps.map((x, z) => (
                <>
                  {x}
                  {z !== d.reps.length - 1 ? ", " : ""}
                </>
              ))}
            </div>
          )}
          {isAdminPage && showAdminBox && (
            <div className="f6 mv2 db ba b--blue pa2 br3">
              {d.customerReports && (
                <div>
                  <b>Customers: </b>{" "}
                  {(d.customerReports ? d.customerReports : []).join(", ")}
                </div>
              )}
              <div>
                <b>Page type: </b>{" "}
                {Array.isArray(d.page_type)
                  ? d.page_type.join(", ")
                  : d.page_type}
              </div>

              {d.screenshots && !d.screenshots.find(s => s.control) && (
                <div>
                  <b>No control selected.</b>
                </div>
              )}
              {d.related_test && (
                <div className="mv2">
                  {d.related_test === "none" || d.related_test === "later" ? (
                    <b>Related test not added.</b>
                  ) : (
                    <a
                      href={`https://dowhatworks.io/admin/screenshots/edit/${d.related_test}`}
                    >
                      Edit related test
                    </a>
                  )}
                </div>
              )}
              {d.challenge_v2 && (
                <div>
                  <b>Challenges:</b>
                  {Array.isArray(d.challenge_v2)
                    ? d.challenge_v2
                        .map(challenge => {
                          if (typeof challenge === "string") {
                            return challenge;
                          }
                        })
                        .map((challenge, index) => (
                          <p key={`challenge-${index}`}>{challenge}</p>
                        ))
                    : d.challenge_v2}
                </div>
              )}
              {d.publish_date && (
                <div>
                  <b>Test Published On : </b> {formatReportDate(d.publish_date)}
                </div>
              )}
              {d.last_seen_live && (
                <div>
                  <b>Last seen Live: </b> {formatReportDate(d.last_seen_live)}
                </div>
              )}
              {d.potential_end_date && !d.end_date && (
                <>
                  <div>
                    <b>Potential end date: </b> {d.potential_end_date}
                  </div>
                  <div>
                    <b>Potential winner: </b>{" "}
                    {getWinnerText(
                      d.screenshots,
                      d.potential_winner,
                      d.potential_winner_other
                    )}
                  </div>
                </>
              )}
              {d.notes_v2 && (
                <div>
                  <b>Notes:</b>
                  {Array.isArray(d.notes_v2)
                    ? d.notes_v2
                        .map(note => {
                          if (typeof note === "string") {
                            return note;
                          } else if (typeof note === "object") {
                            return `${generateNoteText(note, d)}-${
                              note.user && note.user.name ? note.user.name : ""
                            } ${
                              note.mtime ? formatReportDate(note.mtime) : ""
                            }`;
                          }
                        })
                        .filter((note, index) =>
                          this.state.showMoreNotes ? true : index < 1
                        )
                        .map((note, index) => (
                          <p key={`note-${index}`}>{note}</p>
                        ))
                    : d.notes_v2}
                  <span
                    className="pointer blue"
                    onClick={e => {
                      this.setState({
                        showMoreNotes: !this.state.showMoreNotes,
                      });
                    }}
                  >
                    {this.state.showMoreNotes ? "show less" : " show more"}
                  </span>
                </div>
              )}

              {d.comments_v2 && (
                <div>
                  <b>Comments:</b>
                  {Array.isArray(d.comments_v2)
                    ? d.comments_v2
                        .map(comment => {
                          if (typeof comment === "string") {
                            return comment;
                          } else if (typeof comment === "object") {
                            return `${comment.comment}-${
                              comment.user && comment.user.name
                                ? comment.user.name
                                : ""
                            } ${
                              comment.mtime
                                ? formatReportDate(comment.mtime)
                                : ""
                            }`;
                          }
                        })
                        .filter((note, index) =>
                          this.state.showMoreComments ? true : index < 1
                        )
                        .map((text, index) => (
                          <p key={`comment-${index}`}>{text}</p>
                        ))
                    : d.comments_v2}
                  {d.comments_v2 && d.comments_v2.length > 0 && (
                    <span
                      className="pointer blue"
                      onClick={e => {
                        this.setState({
                          showMoreComments: !this.state.showMoreComments,
                        });
                      }}
                    >
                      {this.state.showMoreComments ? "show less" : " show more"}
                    </span>
                  )}
                </div>
              )}
              <div>
                <a href={d.url} target="_blank">
                  {d.url}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  componentWillUnmount() {
    this.cancelPreload();
    clearTimeout(this.timeout);
  }
}

export default ReportItemDisplay;
