import React from "react";
import * as cx from "classnames";
import TrendUpIcon from "../icons/old/trend_up_outlined.svg";
import TrendDownIcon from "../icons/old/trend_down_outlined.svg";
import TrendFlatIcon from "../icons/old/trend_flat_outlined.svg";
import DownArrow from "../icons/old/down_arrow.svg";
import UpArrow from "../icons/old/up_arrow.svg";
import Help from "../icons/old/help_outlined.svg";
import XIcon from "../icons/x.svg";
import HeadlineImg from "../img/headline.png";
import Modal from "@material-ui/core/Modal";
class SemList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showAllList: props.showAll,
      showHeadlines: null,
    };
  }

  handleShowAll = e => {
    e.preventDefault();
    this.setState({ showAllList: true });
  };
  handleShowLess = e => {
    e.preventDefault();
    this.setState({ showAllList: false });
  };
  handleShowHeadlines = id => e => {
    e.preventDefault();
    this.setState({ showHeadlines: id });
  };
  handleHideHeadlines = id => e => {
    e.preventDefault();
    this.setState({ showHeadlines: null });
  };
  showModal = e => {
    e.preventDefault();
    this.setState({
      modal: true,
    });
  };
  closeModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      modal: false,
    });
  };
  render() {
    const { data, title, showAll, status } = this.props;
    const { showAllList } = this.state;
    const count =
      showAll || showAllList || data.length <= 5
        ? data.length
        : `5 of ${data.length}`;

    const shouldShowAll = showAllList || showAll;
    return (
      <div className="box pb3 mb3">
        <div className="bb f5 b--black-05 pv2 ph3 mb2 flex">
          <div>
            <div className="f5 ssb">
              {title}
              {!title.startsWith("Winning") ? ` (${count})` : ""}
            </div>
            {status === "best" && (
              <div className="f6 dww-gray">Use these for inspiration.</div>
            )}
            {status === "try" && (
              <div className="f6 dww-gray">
                Consider testing concepts like these too.
              </div>
            )}
            {status === "weak" && (
              <div className="f6 dww-gray">Avoid these.</div>
            )}
            {status === "low_volume" && (
              <div className="f6 dww-gray">
                The ad volume is too low to rank these headlines.
              </div>
            )}
          </div>
          {status === "best" && (
            <div className="pt2 tr" style={{ flexGrow: "1" }}>
              <a href=" #" onClick={this.showModal}>
                <img src={Help} />
              </a>
            </div>
          )}
        </div>
        {count === 0 && <div className="pa3">No headlines this week.</div>}
        {data.length > 0 && (
          <div
            className={cx({
              "overflow-hidden": true,
              pb4: data.length < 5 && data.length > 1,
            })}
          >
            {data.map((d, i) => {
              if (!shouldShowAll && i > 4) {
                return <></>;
              }
              return (
                <div
                  key={`data_${i}`}
                  className={cx({
                    "bg-near-white": i % 2 !== 0,
                    relative: true,
                  })}
                >
                  <div className="flex">
                    <div className="pt3 pb3 pl3 w-1 flex items-center">
                      {status !== "low_volume" &&
                        (status === "best" || d.status === "best") && (
                          <div className="place-circle bg-green white items-center">
                            <div className="place-circle-number">
                              {d.place || i + 1}
                            </div>
                          </div>
                        )}
                      {status === "low_volume" ||
                      (d.psov === 0 && status !== "best" && status !== "ok") ? (
                        <div className="place-circle bg-black-30 white items-center">
                          <div className="place-circle-number">
                            {status === "low_volume" ? (
                              <>&nbsp;</>
                            ) : (
                              <>{d.place}</>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          {d.status === "ok" && (
                            <div className="place-circle bg-light-green white items-center">
                              <div className="place-circle-number">
                                {d.place}
                              </div>
                            </div>
                          )}
                          {d.status === "borderline" && (
                            <div className="place-circle bg-yellow white items-center">
                              <div className="place-circle-number">
                                {d.place}
                              </div>
                            </div>
                          )}
                          {(d.status === "worst" || d.status === "bad") && (
                            <div className="place-circle bg-dark-red white items-center">
                              <div className="place-circle-number">
                                {d.place}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="w-100 pa3 pr2">
                      <div className="w-100">
                        <span className="f6 pre" alt={`${d.sov.toFixed(2)}`}>
                          {d.text}
                        </span>
                      </div>
                    </div>
                    <div className="w-1 pa3 flex items-center">
                      {d.psov === 0 && (
                        <span
                          className={cx({
                            "black-30 ttu f8 b": true,
                          })}
                        >
                          NEW
                        </span>
                      )}
                      {d.psov !== 0 && (
                        <span>
                          {d.sov - d.psov > 1 && (
                            <img
                              src={TrendUpIcon}
                              width="24px"
                              style={{ maxWidth: "none" }}
                              alt="Trend up"
                              title={`Changed ${(d.sov - d.psov).toFixed(
                                2
                              )}% week over week`}
                            />
                          )}
                          {d.sov - d.psov < -1 && (
                            <img
                              src={TrendDownIcon}
                              width="24px"
                              alt="Trend down"
                              style={{ maxWidth: "none" }}
                              title={`Changed ${(d.sov - d.psov).toFixed(
                                2
                              )}% week over week`}
                            />
                          )}
                          {d.sov - d.psov <= 1 && d.sov - d.psov > -1 && (
                            <img
                              src={TrendFlatIcon}
                              width="24px"
                              alt="Trend flat"
                              style={{ maxWidth: "none", width: "24px" }}
                              title={`Changed ${(d.sov - d.psov).toFixed(
                                2
                              )}% week over week`}
                            />
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {data.length > 5 && !showAll && (
              <div className="tr ph3 pt2 bt b--black-10">
                {!shouldShowAll && (
                  <a
                    onClick={this.handleShowAll}
                    href="#foo"
                    className="f6 dark-gray b pointer ui-link"
                  >
                    Show all{" "}
                    <img src={DownArrow} className="icon pt1" alt="Show all" />
                  </a>
                )}
                {shouldShowAll && (
                  <a
                    onClick={this.handleShowLess}
                    href="#foo"
                    className="f6 dark-gray b pointer ui-link"
                  >
                    Show less{" "}
                    <img src={UpArrow} className="icon pt1" alt="Show less" />
                  </a>
                )}
              </div>
            )}
          </div>
        )}
        {this.state.modal && (
          <Modal open={true}>
            <div
              className="box"
              style={{
                width: `40vw`,
                top: "10vh",
                position: "absolute",
                left: "30vw",
                overflow: "auto",
              }}
            >
              <img
                src={XIcon}
                className="pointer modal-close"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                }}
                onClick={this.closeModal}
              />
              <div className="pa3 bb b--black-05">
                <div className="f3 dash-head">How does this work?</div>
              </div>
              <div className="pa3">
                <p>Search ads are made up of 2 to 3 headlines.</p>
                <div className="tc pb3">
                  <img
                    src={HeadlineImg}
                    style={{ width: "80%" }}
                    className="br4"
                  />
                </div>
                <p>
                  Headlines are ranked by likelihood to resonate by keyword.
                </p>
                <p>
                  Use the top headlines for inspiration when writing ad copy and
                  product headlines.{" "}
                </p>

                <div className="tc mt4">
                  <a
                    href=" #"
                    className="ui-button primary"
                    onClick={this.closeModal}
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default SemList;
