import React from "react";
import { useDroppable } from "@dnd-kit/core";

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: {
      type: props.type || "",
      index: props.index,
      screenshotIndex: props.screenshotIndex,
    },
  });
  const className = props.className;
  const style = {
    color: isOver ? "green" : undefined,
  };

  return (
    <div ref={setNodeRef} style={style} className={className}>
      {props.children}
    </div>
  );
}
