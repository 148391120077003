import React from "react";
import request from "request";
import * as querystring from "query-string";
import { Redirect } from "react-router-dom";
import { baseApiUrl } from "../../util";
import Register from "../../components/Register";

class Invite extends React.Component {
  state = {
    authed: false,
    loading: true,
    sub: null,
    id: querystring.parse(window.location.search).id,
    redirectUrl: querystring.parse(window.location.search).redirectUrl,
  };
  componentDidMount() {
    const { user, loading } = this.props;
    request.get(
      {
        url: `${baseApiUrl}/v1/user/invite?id=${this.state.id}`,
      },
      async (err, res, data) => {
        const json = JSON.parse(res.body);
        if (json.error) {
          this.setState({ error: json.error, loading: false });
        } else {
          this.setState({ invite: json.invite, loading: false });
        }
      }
    );
  }
  render() {
    const { sub, loading, id, error } = this.state;
    const { isRegister, user, match, authed } = this.props;
    if (loading) {
      return (
        <div className="loader">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="pt4">
          <div className="register center box flex flex-wrap tl w-75">
            <div className="w-100">
              <div className="flex bb b--dww pv2 ph3">
                <h1 className="f2 dash-head pt2">Something went wrong...</h1>
              </div>
              <div className="pa3 red b">{error}</div>
            </div>
          </div>
        </div>
      );
    }
    if (user && authed) {
      if (this.props.redirectUrl) {
        <Redirect to={this.props.redirectUrl} />;
      } else {
        return <Redirect to={`/home/${user.company}/report?from=register`} />;
      }
    }

    if (
      !user &&
      !authed &&
      this.state.invite &&
      this.state.invite.sso === true
    ) {
      return (
        <Redirect
          to={`/login/?sso=true&invite=${this.state.invite.id}&redirectUrl=${
            this.state.redirectUrl || ""
          }`}
        />
      );
    }
    return (
      <main className="pt4 pb4 tl">
        {!loading && (
          <Register
            id={this.state.id}
            isRegister
            isInvite
            invite_id={this.state.invite.id}
            email={this.state.invite.email}
            name={this.state.invite.name}
            company={this.state.invite.customer}
            redirectUrl={this.state.redirectUrl}
          />
        )}
      </main>
    );
  }
}

export default Invite;
