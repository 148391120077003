import React from "react";
import { Link } from "react-router-dom";
const Admin = () => (
  <div className="box pa3">
    <ul>
      <li>
        <Link className="link f5" to="/admin/tests" title="see reports">
          Customer Tests/SEM
        </Link>
      </li>

      <li>
        <Link className="link f5" to="/admin/tags" title="Edit tags">
          Tags
        </Link>
      </li>
      <li>
        <Link className="link f5" to="/admin/companies" title="Edit companies">
          Companies
        </Link>
      </li>
      <li>
        <Link className="link f5" to="/admin/sectors" title="Edit sectors">
          Sectors
        </Link>
      </li>
    </ul>

    <b>Queues: </b>
    <ul>
      <li>
        <Link className="link f5" to="/admin/queue/draft" title="Draft Queue">
          Draft
        </Link>
      </li>
      <li>
        <Link
          className="link f5"
          to="/admin/queue/followup"
          title="Feedback Queue"
        >
          Feedback
        </Link>
      </li>
      <li>
        <Link
          className="link f5"
          to="/admin/queue/approval"
          title="Approval Queue"
        >
          Approval
        </Link>
      </li>
      <li>
        <Link
          className="link f5"
          to="/admin/queue/called-30"
          title="Called Tests Queue"
        >
          Called Tests (30 days)
        </Link>
      </li>
      <li>
        <Link
          className="link f5"
          to="/admin/queue/called-60"
          title="Called Tests Queue"
        >
          Called Tests (60 days)
        </Link>
      </li>
      <li>
        <Link
          className="link f5"
          to="/admin/queue/rejected_called"
          title="Called Tests To Fix Queue"
        >
          Called Tests To Fix
        </Link>
      </li>
      <li>
        <Link className="link f5" to="/admin/queue/edit" title="Edit Queue">
          Edit Queue
        </Link>
      </li>
      <li>
        <Link
          className="link f5"
          to="/admin/queue/publishing"
          title="Edit Queue"
        >
          Publishing
        </Link>
      </li>
    </ul>
  </div>
);

export default Admin;
