import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import * as querystring from "query-string";
import ReportItemDisplay from "../../components/ReportItemDisplayNew";
import TagSearch from "../../components/TagSearch";
import {
  baseApiUrl,
  handleError,
  formatDateAndTime,
  formatDateFromString,
  formatReportDate,
} from "../../util";
import { FormControlLabel, Switch as SwitchComp } from "@material-ui/core";
import StarIcon from "../../icons/star_solo.svg";
import { status_map } from "./AddScreenshots";
const extraReports = {
  "6a65e917-b1eb-4607-b7b6-f3fd26bf5377": "Greatest Hits",
  "c7d9e8a9-96c6-43f5-8b84-720aecd503c8": "Top 200",
  "0a4c9147-17fb-406c-9680-4cf13b293e2c": "Top 10 Candidates",
};

class QueueDraft extends React.Component {
  state = {
    company: "all",
    selected_queue: "analyst_feedback",
    filterComps: [],
    id: null,
    comment: null,
    loading: true,
    showAddReport: "",
    allReports: [],
    sort:
      this.props.type === "draft_review"
        ? "mtime_r"
        : this.props.type === "live-check"
          ? "last_seen_r"
          : "mtime",
    items: [],
    selectedTests: [],
    redesignFilter: "all",
    priorityCompany: "all",
    sortByCompany: "",
    queueFilter: "all",
  };
  componentDidMount() {
    const { company } = querystring.parse(window.location.search);
    this.getData(company ? company : "all");
  }
  getData = company => {
    let url = `${baseApiUrl}/v1/admin/draft_queue?queue=${this.props.type}&company=${company}&redesignFilter=${this.state.redesignFilter}`;
    if (this.props.type === "called_30") {
      url = `${baseApiUrl}/v1/admin/called_30_queue?company=${company}&redesignFilter=${this.state.redesignFilter}`;
    } else if (this.props.type === "called_60") {
      url = `${baseApiUrl}/v1/admin/called_queue?company=${company}&redesignFilter=${this.state.redesignFilter}`;
    } else if (this.props.type === "rejected_called") {
      url = `${baseApiUrl}/v1/admin/called_queue?company=${company}&rejected=true&redesignFilter=${this.state.redesignFilter}`;
    } else if (this.props.type === "validate-end") {
      url = `${baseApiUrl}/v1/admin/validation-queue?company=${company}&redesignFilter=${this.state.redesignFilter}`;
    } else if (this.props.type === "live-check") {
      url = `${baseApiUrl}/v1/admin/live-check-queue?company=${company}&redesignFilter=${this.state.redesignFilter}`;
    }
    request(
      {
        url,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          company: company ? company : "all",
          loading: false,
          authed: true,
          items: json.data,
          customers: json.customers,
          companies: json.companies,
          sectors: json.sectors.sort(),
          allReports:
            json.reports.sort((a, b) =>
              `${a.company}: ${a.name.toLowerCase()}` >=
              `${b.company}: ${b.name.toLowerCase()}`
                ? 1
                : -1
            ) || [],
          //selectedTests: [],
        });
      }
    );
  };
  changeSector = values => {
    this.setState({ sector: values });
  };
  reject = id => e => {
    e.preventDefault();
    this.setState({ id, event: "reject", selected_queue: "analyst_feedback" });
  };
  rejectCalled = id => e => {
    e.preventDefault();
    this.setState({ id, event: "rejectCalled" });
  };
  approve = id => e => {
    e.preventDefault();
    this.setState({ id, event: "approve" });
  };
  sendToScreenerQueue = id => e => {
    e.preventDefault();
    this.setState({ id, event: "reject", selected_queue: "analyst_review" });
  };
  handleChange = e => {
    this.setState({
      comment: e.currentTarget.value,
    });
  };
  publish = item => e => {
    this.setState({ loading: true });
    request.post(
      {
        url: `${baseApiUrl}/admin/draft_queue/publish`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: item.id,
          company: item.company,
          reports: item.recs.map(i => i.report).join(","),
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        this.getData(this.state.company);
      }
    );
  };
  showMessage = (id, event) => e => {
    console.log("showMessage", { id, event });
    // return;
    e.preventDefault();
    this.setState({ id, event });
  };
  doRejectCalled = id => e => {
    this.setState({ loading: true });
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/reject_called_test`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: id,
          comment: this.state.comment,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        this.getData(this.state.company);
      }
    );
  };
  confirmCalled = id => e => {
    this.setState({ loading: true });
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/confirm_called_test`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: id,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        this.getData(this.state.company);
      }
    );
  };
  acceptEndDate = id => e => {
    e.preventDefault();
    this.setState({ id, event: "acceptEndDate" });
  };
  rejectEndDate = id => e => {
    e.preventDefault();
    this.setState({ id, event: "rejectEndDate" });
  };
  doAcceptEndDate = id => e => {
    const updatedItems = this.state.items.filter(i => i.id !== id);
    const allItems = this.state.items;
    this.setState({ loading: true, items: updatedItems, allItems });
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/accept_end_date`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: id,
          comment: this.state.comment,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          const items = this.state.allItems;
          this.setState({
            loading: false,
            authed: false,
            items,
          });
          alert("Error in accepting end date, please try again!");
        }
        setTimeout(() => {
          this.getData(this.state.company);
        }, 5000);
      }
    );
  };
  doRejectEndDate = id => e => {
    const updatedItems = this.state.items.filter(i => i.id !== id);
    const allItems = this.state.items;
    this.setState({ loading: true, items: updatedItems, allItems });
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/reject_end_date`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: id,
          comment: this.state.comment,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          const items = this.state.allItems;
          this.setState({
            loading: false,
            authed: false,
            items,
          });
          alert("Error in rejecting end date, please try again!");
        }
        setTimeout(() => {
          this.getData(this.state.company);
        }, 5000);
      }
    );
  };
  confirmAt30DaysCalled = id => e => {
    this.setState({ loading: true });
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/confirm_called_test_at_30`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: id,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        this.getData(this.state.company);
      }
    );
  };
  moveQueue = (q, id) => {
    this.setState({ loading: true });
    request.post(
      {
        url: `${baseApiUrl}/admin/draft_queue/`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          id: id ? id : this.state.id,
          comment: this.state.comment,
          queue: q,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        this.getData(this.state.company);
      }
    );
  };
  doMoveToPublishing = id => e => {
    this.moveQueue("publish_review", id);
  };
  doMove = e => {
    this.moveQueue("draft_review");
  };
  doApprove = id => e => {
    this.moveQueue("approval_review", id);
  };
  doPublish = id => e => {
    this.moveQueue("publish_review", id);
  };
  doDraft = id => e => {
    this.moveQueue("draft_review", id);
  };
  doEdit = id => e => {
    this.moveQueue("edit", id);
  };
  doHold = id => e => {
    this.moveQueue("draft_hold", id);
  };
  doReject = id => e => {
    this.moveQueue("analyst_review", id);
  };
  doAnalystReject = (id, moveToQueue) => e => {
    const queue = moveToQueue ? moveToQueue : this.state.selected_queue;
    this.moveQueue(queue ? queue : "analyst_review", id);
  };
  doRejectPublish = id => e => {
    this.moveQueue("draft_review", id);
  };
  doDelete = id => e => {
    e.preventDefault();
    if (window.confirm("Sure you want to remove this from the queue?")) {
      this.moveQueue("deleted", id);
    }
  };
  removeReport = (itemId, reportId) => e => {
    e.preventDefault();
    const items = this.state.items;
    const item = items.find(i => i.id === itemId);
    item.recs = item.recs.filter(r => r.report !== reportId);

    this.setState({ items });
  };
  onReportChange = e => {
    this.setState({
      add_child_report: e.currentTarget.value,
    });
  };
  showAddReport = itemId => e => {
    e.preventDefault();
    this.setState({
      showAddReport: itemId,
    });
  };
  doAddReport = e => {
    e.preventDefault();
    const items = this.state.items;
    const item = items.find(i => i.id === this.state.showAddReport);
    const report = this.state.allReports.find(
      r => r.id === this.state.add_child_report
    );
    item.recs.push({
      report: this.state.add_child_report,
      name: `${report.company}: ${report.name}`,
    });
    this.setState({ items });
  };
  addExtraReport = (itemId, reportId) => e => {
    e.preventDefault();
    const items = this.state.items;
    const item = items.find(i => i.id === itemId);
    const name = extraReports[reportId];
    item.recs.push({
      report: reportId,
      name,
    });
    this.setState({ items });
  };
  filterCompany = values => {
    this.setState({ filterComps: values });
  };
  handleSort = e => {
    this.setState({ sort: e.currentTarget.value });
  };
  doFilter = items => {
    const {
      filterComps,
      sector,
      companies,
      priorityCompany,
      statusFilter,
      queueFilter,
    } = this.state;
    let filteredItems = items;

    if (filterComps && filterComps.length > 0) {
      filteredItems = filteredItems.filter(q =>
        filterComps.includes(q.company_name)
      );
    }

    if (sector && sector.length > 0) {
      filteredItems = filteredItems.filter(q => {
        let ret = false;
        if (q.sectors) {
          sector.forEach(s => {
            if (q.sectors.includes(s)) {
              ret = true;
            }
          });
        }

        return ret;
      });
    }
    if (priorityCompany && priorityCompany.trim() === "only") {
      filteredItems = filteredItems.filter(item => item.priorityCompany);
    }
    if (statusFilter && statusFilter !== "all") {
      filteredItems = filteredItems.filter(
        item => item.status === statusFilter
      );
    }
    if (queueFilter && queueFilter !== "all") {
      filteredItems = filteredItems.filter(
        item => item.q_status === queueFilter
      );
    }
    return filteredItems;
  };
  mtimeSort = (a, b) => (a.mtime >= b.mtime ? 1 : -1);
  mtimeSortR = (a, b) => (a.mtime >= b.mtime ? -1 : 1);
  commentSort = (a, b) => {
    if (a.prev_q === "approval_review" && b.prev_q === "approval_review") {
      return a.mtime >= b.mtime ? 1 : -1;
    } else if (
      a.prev_q === "approval_review" &&
      b.prev_q === "approval_review"
    ) {
      return -1;
    } else if (
      a.comments_v2 &&
      a.comments_v2.length !== 0 &&
      b.comments_v2 &&
      b.comments_v2.length !== 0
    ) {
      return a.mtime >= b.mtime ? 1 : -1;
    } else if (a.comments_v2 && a.comments_v2.length > 0) {
      return -1;
    } else {
      return 1;
    }
  };
  startedSort = (a, b) => {
    return a.start_date >= b.start_date ? -1 : 1;
  };
  startedSortR = (a, b) => {
    return a.start_date >= b.start_date ? 1 : -1;
  };
  calledSort = (a, b) => {
    if (a.end_date && b.end_date) {
      return a.end_date >= b.end_date ? -1 : 1;
    }
    if (a.end_date && !b.end_date) {
      return a.end_date >= b.start_date ? -1 : 1;
    }
    if (!a.end_date && b.end_date) {
      return a.start_date >= b.end_date ? -1 : 1;
    } else {
      return a.start_date >= b.start_date ? -1 : 1;
    }
  };
  calledSortR = (a, b) => {
    if (a.end_date && b.end_date) {
      return a.end_date >= b.end_date ? 1 : -1;
    }
    if (a.end_date && !b.end_date) {
      return a.end_date >= b.start_date ? 1 : -1;
    }
    if (!a.end_date && b.end_date) {
      return a.start_date >= b.end_date ? 1 : -1;
    } else {
      return a.start_date >= b.start_date ? 1 : -1;
    }
  };
  lastSeenSort = (a, b) => {
    if (a.last_seen_live && b.last_seen_live) {
      return a.last_seen_live >= b.last_seen_live ? -1 : 1;
    }
    if (a.last_seen_live && !b.last_seen_live) {
      return -1;
    }
    if (!a.last_seen_live && b.last_seen_live) {
      return 1;
    } else {
      return 0;
    }
  };
  lastSeenSortR = (a, b) => {
    if (a.last_seen_live && b.last_seen_live) {
      return a.last_seen_live >= b.last_seen_live ? 1 : -1;
    }
    if (a.last_seen_live && !b.last_seen_live) {
      return 1;
    }
    if (!a.last_seen_live && b.last_seen_live) {
      return -1;
    } else {
      return 0;
    }
  };
  potentialEndDateSort = (a, b) => {
    if (a.potential_end_date && b.potential_end_date) {
      return a.potential_end_date >= b.potential_end_date ? -1 : 1;
    }
    if (a.potential_end_date && !b.potential_end_date) {
      return -1;
    }
    if (!a.potential_end_date && b.potential_end_date) {
      return 1;
    } else {
      return 0;
    }
  };
  potentialEndDateSortR = (a, b) => {
    if (a.potential_end_date && b.potential_end_date) {
      return a.potential_end_date >= b.potential_end_date ? 1 : -1;
    }
    if (a.potential_end_date && !b.potential_end_date) {
      return 1;
    }
    if (!a.potential_end_date && b.potential_end_date) {
      return -1;
    } else {
      return 0;
    }
  };
  changeQueue = e => {
    const { value } = e.currentTarget;
    this.setState({
      selected_queue: value,
    });
  };
  toggleSelectTest = item => e => {
    const testId = item.id;
    const isTestSelected = this.state.selectedTests.some(
      selectedTest => selectedTest.id == testId
    );
    if (isTestSelected && !e.target.checked) {
      this.setState(prevState => {
        return {
          selectedTests: prevState.selectedTests.filter(
            selectedTest => selectedTest.id != testId
          ),
        };
      });
    } else if (!isTestSelected && e.target.checked) {
      this.setState(prevState => {
        return {
          selectedTests: [
            ...prevState.selectedTests,
            {
              id: item.id,
              company: item.company,
              reports: item.recs.map(i => i.report),
              company_name: item.company_name,
            },
          ],
        };
      });
    }
  };
  publishMultipleTests = e => {
    this.setState({ loading: true });
    request.post(
      {
        url: `${baseApiUrl}/v1/admin/publish_multiple`,
        headers: {
          Authorization: this.props.user.token,
        },
        json: true,
        body: {
          selectedTests: this.state.selectedTests,
        },
      },
      (err, res, data) => {
        if (err || res.statusCode >= 400) {
          handleError(err, res);
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        this.setState({
          loading: false,
          authed: false,
          selectedTests: [],
        });
        this.getData(this.state.company);
      }
    );
  };
  updateCBs = e => {
    const testId = e.target.value;
    const item = this.state.items.find(item => item.id == testId);
    this.toggleSelectTest(item)(e);
  };
  onChangeRedesignFilter = e => {
    const redesignFilter = e.target.value;
    this.setState(
      {
        redesignFilter,
        loading: true,
      },
      () => {
        this.getData(this.state.company);
      }
    );
  };
  onChangeStatusFilter = e => {
    const statusFilter = e.target.value;
    this.setState({
      statusFilter,
    });
  };
  onChangePriorityCompanyFilter = e => {
    const priorityCompany = e.target.checked;
    this.setState({
      priorityCompany: priorityCompany ? "only" : "all",
    });
  };
  onChangeSortByCompany = e => {
    const sortByCompany = e.target.value;
    this.setState({
      sortByCompany,
    });
  };
  onChangeQueueFilter = e => {
    const queueFilter = e.target.value;
    this.setState({
      queueFilter,
    });
  };
  render() {
    const {
      items,
      id,
      comment,
      sort,
      event,
      redesignFilter,
      priorityCompany,
      sortByCompany,
      statusFilter,
      queueFilter,
    } = this.state;
    const { type } = this.props;
    if (this.state.loading) {
      return <Loading />;
    }
    items.forEach(item => {
      if (items.recs) {
        item.recs.sort((a, b) =>
          a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
        );
      }
    });
    let sortF = this.mtimeSort;

    if (sort === "mtime_r") {
      sortF = this.mtimeSortR;
    } else if (sort === "start") {
      sortF = this.startedSort;
    } else if (sort === "start_r") {
      sortF = this.startedSortR;
    } else if (sort === "end") {
      sortF = this.calledSort;
    } else if (sort === "end_r") {
      sortF = this.calledSortR;
    } else if (sort === "last_seen") {
      sortF = this.lastSeenSort;
    } else if (sort === "last_seen_r") {
      sortF = this.lastSeenSortR;
    } else if (sort === "comments") {
      sortF = this.commentSort;
    } else if (sort === "potential_end_date") {
      sortF = this.potentialEndDateSort;
    } else if (sort === "potential_end_date_r") {
      sortF = this.potentialEndDateSortR;
    }
    const filteredItems = this.doFilter(items);
    let finalItems = filteredItems.sort(sortF).slice(0, 500);
    if (sortByCompany !== "") {
      finalItems = finalItems.sort((a, b) => {
        const doesAPublishingToCompany =
          a.recs && a.recs.some(record => record.report === sortByCompany);
        const doesBPublishingToCompany =
          b.recs && b.recs.some(report => report.report === sortByCompany);

        if (doesAPublishingToCompany && doesBPublishingToCompany) {
          return 0;
        } else if (doesAPublishingToCompany && !doesBPublishingToCompany) {
          return -1;
        } else if (!doesAPublishingToCompany && doesBPublishingToCompany) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return (
      <>
        <div>
          <h1>
            {type === "draft_review" && <>Draft Queue</>}
            {type === "draft_hold" && <>Hold Queue</>}
            {type === "edit" && <>Edit Queue</>}
            {type === "analyst_review" && <>Screener Feedback Queue</>}
            {type === "analyst_feedback" && <>Writer Feedback Queue</>}
            {type === "approval_review" && <>Approval Queue</>}
            {type === "publish_review" && <>Publishing Queue</>}
            {type === "validate-end" && <>Validation Queue</>}
            {type === "live-check" && <>Live Check Queue</>}
          </h1>
          <div className="flex items-center justify-between">
            <div className="w-30 pb2 pr3">
              <TagSearch
                options={this.state.sectors.map(c => ({
                  label: c.display_name,
                  value: c.key,
                }))}
                callback={this.changeSector}
                placeholder="Filter by sector"
              />
            </div>
            <div className="w-30 pb2">
              <TagSearch
                options={this.state.companies.map(c => ({
                  label: c.name,
                  value: c.name,
                }))}
                callback={this.filterCompany}
                placeholder="Filter by company"
              />
            </div>
            <div className=" pl1 ">
              <select name="sort" onChange={this.handleSort}>
                <option value="mtime" selected={this.state.sort === "mtime"}>
                  Last changed (oldest)
                </option>
                <option
                  value="mtime_r"
                  selected={this.state.sort === "mtime_r"}
                >
                  Last changed (newest)
                </option>
                <option value="start" selected={this.state.sort === "start"}>
                  Started date (newest)
                </option>
                <option
                  value="start_r"
                  selected={this.state.sort === "start_r"}
                >
                  Started date (oldest)
                </option>
                <option value="end" selected={this.state.sort === "end"}>
                  Declared date (newest)
                </option>
                <option value="end_r" selected={this.state.sort === "end_r"}>
                  Declared date (oldest)
                </option>
                <option
                  value="last_seen"
                  selected={this.state.sort === "last_seen"}
                >
                  Last seen Live (newest)
                </option>
                <option
                  value="last_seen_r"
                  selected={this.state.sort === "last_seen_r"}
                >
                  Last seen Live (oldest)
                </option>
                {type === "validate-end" && (
                  <>
                    <option
                      value="potential_end_date_r"
                      selected={this.state.sort === "potential_end_date_r"}
                    >
                      May have ended (oldest)
                    </option>
                    <option
                      value="potential_end_date"
                      selected={this.state.sort === "potential_end_date"}
                    >
                      May have ended (newest)
                    </option>
                  </>
                )}
                <option
                  value="comments"
                  selected={this.state.sort === "comments"}
                >
                  Comments
                </option>
              </select>
            </div>
            <div className=" pl1">
              <select
                name="redesignFilter"
                onChange={this.onChangeRedesignFilter}
                value={redesignFilter}
              >
                <option value="all">Show everything</option>
                <option value="exclude">Exclude Redesigns</option>
                <option value="only">Only Redesigns</option>
              </select>
            </div>
            {type === "publish_review" && (
              <div className=" pl1">
                <select
                  name="statusFilter"
                  onChange={this.onChangeStatusFilter}
                  value={statusFilter}
                >
                  <option value="all">All Tests</option>
                  <option value="live">Live</option>
                  <option value="ended">Called</option>
                </select>
              </div>
            )}
            <div className=" pl1">
              <FormControlLabel
                value="only"
                control={
                  <>
                    <SwitchComp
                      color="primary"
                      name="priorityCompany"
                      checked={priorityCompany === "only"}
                      onChange={this.onChangePriorityCompanyFilter}
                    />
                    Priority
                    <img src={StarIcon} width="20" alt="priority company" />
                  </>
                }
                label=""
                labelPlacement="start"
              />
            </div>
          </div>
          {(type === "publish_review" ||
            type === "approval_review" ||
            type === "live-check" ||
            type === "validate-end") && (
            <div className="flex justify-between">
              <div className="w-40 pt1">
                <select
                  name="sortByCompany"
                  onChange={this.onChangeSortByCompany}
                  value={sortByCompany}
                >
                  <option value="">Sort by publishing company</option>
                  {this.state.allReports.map(report => {
                    return <option value={report.id}>{report.name}</option>;
                  })}
                </select>
              </div>
              {type === "validate-end" && (
                <div className="w-30 pt1 ">
                  <select
                    name="queueFilter"
                    onChange={this.onChangeQueueFilter}
                    value={queueFilter}
                  >
                    <option value="all">Filter by Queue</option>
                    {Object.keys(status_map).map(qType => {
                      return <option value={qType}>{status_map[qType]}</option>;
                    })}
                  </select>
                </div>
              )}
            </div>
          )}

          <div>
            {items &&
              finalItems.map((item, i) => (
                <div className="mb5" key={item.id}>
                  <div className="tr ttu f8 b" style={{ paddingRight: "13px" }}>
                    <a
                      href=" #"
                      onClick={this.doDelete(item.id)}
                      className="self-end"
                    >
                      remove from queue
                    </a>
                  </div>
                  <ReportItemDisplay
                    d={item}
                    company={item.company}
                    urlCompany="admin"
                    userCompany="admin"
                    i={i}
                    isAdminPage
                    showAdminBox
                    isQueue
                    isAdmin={type === "publish_review"}
                    onCBchange={this.updateCBs}
                  />
                  <a
                    href={`https://dww.netlify.app/screens/${encodeURIComponent(
                      item.url
                    )}?lookback=18&start_date=${item.start_date}&end_date=${
                      item.end_date
                    }`}
                  >
                    Check Netlify Screenshots
                  </a>{" "}
                  | <a href={item.url}>Check URL</a>
                  <p>
                    Last changed: {formatDateAndTime(new Date(item.mtime))}{" "}
                  </p>
                  {item.prev_q && <p>Previous Queue: {item.prev_q}</p>}
                  {(type === "publish_review" ||
                    type === "approval_review" ||
                    type === "live-check" ||
                    type === "validate-end") && (
                    <div className="mb3">
                      <div className="b">Reports</div>
                      <div>
                        {item.recs.map(r => (
                          <span
                            className="dib mr2"
                            key={`show-${item.id}-${r.report}`}
                          >
                            {r.name}{" "}
                            {type === "publish_review" ||
                            type === "approval_review" ? (
                              <a
                                href=" #"
                                onClick={this.removeReport(item.id, r.report)}
                              >
                                x
                              </a>
                            ) : (
                              <>&middot;</>
                            )}
                          </span>
                        ))}
                        {this.state.showAddReport !== item.id && (
                          <a
                            href=" #"
                            className="ml2"
                            onClick={this.showAddReport(item.id)}
                          >
                            Add another report
                          </a>
                        )}
                        <div>
                          {Object.keys(extraReports).map(r => {
                            if (!item.recs.find(i => i.report === r)) {
                              return (
                                <a
                                  href=" #"
                                  onClick={this.addExtraReport(item.id, r)}
                                  className="mr2"
                                  key={`xr-${item.id}-${r}`}
                                >
                                  Add {extraReports[r]}
                                </a>
                              );
                            }
                          })}
                        </div>
                        {this.state.showAddReport === item.id && (
                          <div>
                            <select
                              name="add_child_report"
                              onChange={this.onReportChange}
                              className="pa2 b--black-20 bg-white br2"
                              value={this.state.add_child_report}
                            >
                              {this.state.allReports.map(r => (
                                <option value={r.id} key={`add_${r.id}`}>
                                  {r.name}
                                </option>
                              ))}
                            </select>
                            <button onClick={this.doAddReport}>
                              Add report
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {type === "publish_review" && (
                    <div className="mt2">
                      <button onClick={this.showMessage(item.id, "publish")}>
                        Publish
                      </button>
                      <button
                        onClick={this.showMessage(item.id, "approval")}
                        className="ml3"
                      >
                        Move to approval queue
                      </button>{" "}
                      {this.state.event === "publish" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button className="ml2" onClick={this.publish(item)}>
                            Publish
                          </button>
                        </div>
                      )}
                      {this.state.event === "approval" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doApprove(item.id)}
                          >
                            Move to approval queue
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "approval_review" && (
                    <div className="mb3">
                      <button
                        onClick={this.showMessage(item.id, "moveToPublishing")}
                        className="mr3"
                      >
                        Move to Publishing queue
                      </button>
                      <button onClick={this.reject(item.id)}>
                        Request changes
                      </button>
                      {id === item.id && this.state.event === "reject" && (
                        <div className="mt2">
                          <select
                            name="queue"
                            value={this.state.selected_queue}
                            onChange={this.changeQueue}
                          >
                            <option value="analyst_review">
                              Screener Feedback
                            </option>
                            <option value="analyst_feedback">
                              Writer Feedback
                            </option>
                            <option value="draft_review">Draft</option>
                          </select>
                          <div className="mt2 flex">
                            <textarea
                              onChange={this.handleChange}
                              value={comment}
                            />
                            <button
                              className="ml2"
                              onClick={this.doAnalystReject(item.id)}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      )}
                      {id === item.id &&
                        this.state.event === "moveToPublishing" && (
                          <div className="mt2">
                            <div className="mt2 flex">
                              <textarea
                                onChange={this.handleChange}
                                value={comment}
                              />
                              <button
                                className="ml2"
                                onClick={this.doMoveToPublishing(item.id)}
                              >
                                Move to publishing
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                  {type === "draft_review" && (
                    <div className="mb3">
                      <button onClick={this.approve(item.id)}>
                        Move to approval queue
                      </button>{" "}
                      <button onClick={this.sendToScreenerQueue(item.id)}>
                        Request changes
                      </button>
                      <button
                        className="ml2"
                        onClick={this.showMessage(item.id, "draft_hold")}
                      >
                        Move to hold queue
                      </button>
                      <button
                        className="ml2"
                        onClick={this.showMessage(item.id, "moveToEdit")}
                      >
                        Move to edit queue
                      </button>
                      {this.state.event === "approve" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doApprove(item.id)}
                          >
                            Approve
                          </button>
                        </div>
                      )}
                      {this.state.event === "reject" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doReject(item.id)}
                          >
                            Request changes
                          </button>
                        </div>
                      )}
                      {this.state.event === "draft_hold" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doHold(item.id)}
                          >
                            Move to Hold queue
                          </button>
                        </div>
                      )}
                      {this.state.event === "moveToEdit" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doEdit(item.id)}
                          >
                            Move to Edit queue
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "edit" && (
                    <div className="mb3">
                      <button
                        onClick={this.showMessage(item.id, "moveToDraft")}
                      >
                        Move back to draft queue
                      </button>
                      {id === item.id && event === "moveToDraft" && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doDraft(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "draft_hold" && (
                    <div className="mb3">
                      <button
                        onClick={this.showMessage(item.id, "moveToDraft")}
                      >
                        Move back to draft queue
                      </button>
                      <button
                        className="ml2"
                        onClick={this.showMessage(item.id, "moveToEdit")}
                      >
                        Move to edit queue
                      </button>
                      {id === item.id && event === "moveToDraft" && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doDraft(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                      {id === item.id && event === "moveToEdit" && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doEdit(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "analyst_feedback" && (
                    <div className="mb3">
                      <button onClick={this.approve(item.id)}>
                        Move back to approval queue
                      </button>{" "}
                      {id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doApprove(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                      <button onClick={this.doDraft(item.id)}>
                        Move back to draft queue
                      </button>
                      <button className="ml2" onClick={this.doHold(item.id)}>
                        Move to hold queue
                      </button>
                      <button className="ml2" onClick={this.doEdit(item.id)}>
                        Move to edit queue
                      </button>
                      <button
                        className="ml2"
                        onClick={this.doAnalystReject(
                          item.id,
                          "analyst_review"
                        )}
                      >
                        Move to screener feedback
                      </button>
                    </div>
                  )}
                  {type === "analyst_review" && (
                    <div className="mb3">
                      <button onClick={this.reject(item.id)}>
                        Move back to draft queue
                      </button>
                      <button
                        className="ml2"
                        onClick={this.showMessage(item.id, "draft_hold")}
                      >
                        Move to hold queue
                      </button>
                      <button
                        className="ml2"
                        onClick={this.showMessage(item.id, "moveToEdit")}
                      >
                        Move to edit queue
                      </button>
                      {id === item.id && event === "reject" && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doDraft(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                      {this.state.event === "draft_hold" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doHold(item.id)}
                          >
                            Move to Hold queue
                          </button>
                        </div>
                      )}
                      {this.state.event === "moveToEdit" && id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doEdit(item.id)}
                          >
                            Move to Edit queue
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "rejected_called" && (
                    <div className="mb3">
                      <button onClick={this.confirmCalled(item.id)}>
                        Winner Fixed
                      </button>
                    </div>
                  )}
                  {type === "called_30" && (
                    <div className="mb3">
                      <button
                        onClick={this.confirmAt30DaysCalled(item.id)}
                        className="mr3"
                      >
                        Confirm Winner after 30 days
                      </button>
                      <button onClick={this.rejectCalled(item.id)}>
                        Winner is wrong
                      </button>
                      {id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doRejectCalled(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "called_60" && (
                    <div className="mb3">
                      <button
                        onClick={this.confirmCalled(item.id)}
                        className="mr3"
                      >
                        Confirm winner
                      </button>
                      <button onClick={this.rejectCalled(item.id)}>
                        Winner is wrong
                      </button>
                      {id === item.id && (
                        <div className="mt2 flex">
                          <textarea
                            onChange={this.handleChange}
                            value={comment}
                          />
                          <button
                            className="ml2"
                            onClick={this.doRejectCalled(item.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {type === "validate-end" && (
                    <div className="mb3">
                      <button
                        onClick={this.acceptEndDate(item.id)}
                        className="mr3"
                      >
                        Accept
                      </button>
                      <button onClick={this.rejectEndDate(item.id)}>
                        Reject
                      </button>
                      {id === item.id &&
                        this.state.event === "rejectEndDate" && (
                          <div className="mt2 flex">
                            <textarea
                              onChange={this.handleChange}
                              value={comment}
                            />
                            <button
                              className="ml2"
                              onClick={this.doRejectEndDate(item.id)}
                            >
                              Send
                            </button>
                          </div>
                        )}
                      {id === item.id &&
                        this.state.event === "acceptEndDate" && (
                          <div className="mt2 flex">
                            <textarea
                              onChange={this.handleChange}
                              value={comment}
                            />
                            <button
                              className="ml2"
                              onClick={this.doAcceptEndDate(item.id)}
                            >
                              Send
                            </button>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        {this.state.selectedTests.length > 0 && (
          <div className="sticky-submit flex justify-end items-center">
            <div className="flex-start mr1 pr2">
              Selected tests:{" "}
              <b className="ml2">{this.state.selectedTests.length}</b>
            </div>
            <div className="flex-end">
              <button
                className="ui-button primary"
                onClick={this.publishMultipleTests}
              >
                Publish Selected Tests
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default QueueDraft;
