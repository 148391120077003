import React from "react";
import request from "request";
import cx from "classnames";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import {
  baseApiUrl,
  problems,
  formatStripePrice,
  formatDomain,
  getCopyData,
  copyData,
} from "../../../util";
import TagSearch from "../../../components/TagSearch";
import X from "../../../icons/tinyX.svg";
import EditIcon from "../../../icons/edit_blue.svg";
import CheckIcon from "../../../icons/check_blue_v_2.svg";
import DeleteIcon from "../../../icons/delete_red.svg";
import cancelIcon from "../../../icons/x.svg";
import RedXIcon from "../../../icons/red_x.svg";
import AbTestEmailPreview from "./AbTestEmailPreview";
import CopyPaste from "../../../components/CopyPaste";

const ReportSelect = ({ reports, selectedReport, selectReport, company }) => (
  <select
    name={`report-${company}`}
    onChange={selectReport}
    value={selectedReport}
  >
    <option value="">--- select a report ---</option>
    {reports.map(r => (
      <option value={r.id} key={`${company}-${r.id}`}>
        {r.company}: {r.name}
      </option>
    ))}
  </select>
);
const TAG_WEIGHTINGS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const TAG_PREF_TYPE = [
  {
    name: "Customer Preference",
    value: "customer_pref",
  },
  {
    name: "Email Preference",
    value: "email_pref",
  },
];
const DEFAULT_TAG_PREF_TYPE = "customer_pref";
class Companies extends React.Component {
  state = {
    tags: (this.props.tags || []).map(tag => ({
      ...tag,
      prefType:
        tag.prefType ||
        TAG_PREF_TYPE.find(prefType => prefType.value === DEFAULT_TAG_PREF_TYPE)
          .value,
    })),
    negative_tags: (this.props.negative_tags || []).map(tag => ({
      ...tag,
      prefType:
        tag.prefType ||
        TAG_PREF_TYPE.find(prefType => prefType.value === DEFAULT_TAG_PREF_TYPE)
          .value,
    })),
    tld: this.props.tld || [],
    urlkey: this.props.urlkey,
    name: this.props.name,
    saving: false,
    customerCompanies: this.props.customerCompanies || [],
    customerProblems: this.props.customerProblems || [],
    addCompanies: this.props.addCompanies || [],
    blockCompanies: this.props.blockCompanies || [],
    selectedCompanies: [],
    dashboard: this.props.dashboard || {},
    plan: this.props.plan || "",
    report: this.props.report,
    competitors: (this.props.competitors || []).map(competitor =>
      typeof competitor === "string"
        ? {
            tld: competitor,
            weight: 10,
          }
        : competitor
    ),
    competitorWeight: 10,
    real: this.props.real || false,
    demo: this.props.demo || false,
    timezone: this.props.timezone || [],
    timeframe: this.props.timeframe || 180,
    newSectors: this.props.newSectors || [],
    paid: this.props.paid || false,
    freeTrial: this.props.freeTrial || false,
    price: this.props.price || null,
    semOnly: this.props.semOnly || false,
    abOnly: this.props.abOnly || false,
    invite: this.props.invite || false,
    weight: 1,
    negative_weight: 1,
    prefType: TAG_PREF_TYPE.find(
      prefType => prefType.value === DEFAULT_TAG_PREF_TYPE
    ).value,
    negativePrefType: TAG_PREF_TYPE.find(
      prefType => prefType.value === DEFAULT_TAG_PREF_TYPE
    ).value,
    editingTagIndex: null,
    editedWeight: null,
    editedPrefType: null,
    negativeEditingTagIndex: null,
    negativeEditedWeight: null,
    negativeEditedPrefType: null,
    seats: this.props.seats || 0,
    unlock_limit: this.props.unlock_limit || 0,
    totalUsers: this.props.totalUsers || 0,
    gettingPreview: false,
    loadPreview: null,
    previewData: null,
    allData: null,
    selectedSend: false,
    important_url: "",
    impURLWeight: 10,
    impURLs: this.props.impURLs || [],
    editingImpURLIndex: null,
    editedImpURLWeight: null,
  };
  input = React.createRef();
  tagInput = React.createRef();
  tld = React.createRef();
  findCustomerName = key => {
    const foundCust = this.props.customers.filter(c => c.key === key);
    return foundCust.name;
  };
  addCompany = (keys, newSectors) => {
    const custs = keys;
    const comps = [];
    this.props.allCompanies.forEach(c => {
      let found = false;

      custs.forEach(x => {
        if (!found && c.sectors && c.sectors.includes(x)) {
          found = true;
        }
      });

      if (found && !comps.find(x => c.tld === x.tld)) {
        comps.push(c);
      }
    });

    this.setState({
      customerCompanies: custs,
      selectedCompanies: comps.sort((a, b) =>
        a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
      ),
    });
  };
  changeTimezone = vals => {
    this.setState({ timezone: vals[0] });
  };
  handleForm = e => {
    e.preventDefault();
    const {
      tld,
      name,
      customerCompanies,
      customerProblems,
      addCompanies,
      blockCompanies,
      competitors,
      plan,
      report,
      tags,
      negative_tags,
      real,
      demo,
      timezone,
      newSectors,
      timeframe,
      selectedCompanies,
      urlkey,
      price,
      paid,
      semOnly,
      abOnly,
      invite,
      seats,
      freeTrial,
      unlock_limit,
      impURLs,
    } = this.state;
    this.setState({
      saving: true,
    });
    const tlds = typeof tld === "string" ? [tld] : tld;

    if (this.props.new) {
      if (!urlkey || !name) {
        alert("Needs to have a URL key and name!");
        this.setState({
          saving: false,
        });
        return;
      }
      if (newSectors) {
        this.props.companies.forEach(company => {
          if (company.new_sectors) {
            let foundSector = false;
            company.new_sectors.forEach(sector => {
              if (!sector) {
                return;
              }
              newSectors.forEach(newSector => {
                if (
                  newSector === sector ||
                  sector.startsWith(`${newSector} ||`)
                ) {
                  foundSector = true;
                }
              });
            });
            if (foundSector) {
              selectedCompanies.push(company);
            }
          }
        });
      }
      const form = {
        name,
        key: urlkey,
        companies: selectedCompanies
          .map(c => c.tld)
          .concat(addCompanies)
          .join("*|*"),
        customers: customerCompanies.join("*|*"),
        problems: customerProblems.join("*|*"),
        add_companies: addCompanies.join("*|*"),
        block_companies: blockCompanies.join("*|*"),
        competitors: JSON.stringify(
          competitors.map(competitor => ({
            tld: competitor.tld,
            weight: competitor.weight,
          }))
        ),
        new_sectors: newSectors.join("*|*"),
        timeframe,
        plan,
        report,
        tags: JSON.stringify(tags),
        negative_tags: JSON.stringify(negative_tags),
        timezone,
        real,
        demo,
        price,
        paid,
        freeTrial,
        semOnly,
        abOnly,
        tld: tlds.join("*|*"),
        invite,
        seats,
        unlock_limit,
        important_urls: JSON.stringify(impURLs),
      };
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/admin/customers`,
          form,
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.setState({
              saving: false,
            });
            this.props.callback();
          }
        }
      );
    } else {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/admin/customer`,
          form: {
            name,
            key: urlkey,
            customers: customerCompanies.join("*|*"),
            problems: customerProblems.join("*|*"),
            add_companies: addCompanies.join("*|*"),
            block_companies: blockCompanies.join("*|*"),
            competitors: JSON.stringify(
              competitors.map(competitor => ({
                tld: competitor.tld,
                weight: competitor.weight,
              }))
            ),
            new_sectors: newSectors.join("*|*"),
            plan,
            report,
            tags: JSON.stringify(tags),
            negative_tags: JSON.stringify(negative_tags),
            timezone,
            real,
            demo,
            price,
            paid,
            freeTrial,
            semOnly,
            abOnly,
            tld: tlds.join("*|*"),
            invite,
            seats,
            unlock_limit,
            important_urls: JSON.stringify(impURLs),
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.setState({
              saving: false,
            });
            this.props.callback();
          }
        }
      );
    }
  };
  onChange = e => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState({
        [name]: !!checked,
      });
    } else {
      let val;
      if (name === "tld") {
        val = formatDomain(value);
      } else {
        val = value;
      }
      this.setState({
        [name]: val,
      });
    }
  };
  onChangeTld = e => {
    const { name, value, type, checked } = e.target;
    let val;
    if (name === "tld") {
      val = formatDomain(value);
    } else {
      val = value;
    }
    this.setState({
      currentTld: val,
    });
  };

  addTld = e => {
    e.preventDefault();
    let { tld } = this.state;

    if (!tld) {
      tld = [];
    }

    if (typeof tld === "string") {
      tld = [tld];
    }

    tld.push(this.state.currentTld);

    this.setState(
      {
        currentTld: "",
        tld,
      },
      () => {
        this.tld.current.value = "";
        this.tld.current.focus();
      }
    );
  };
  removeTld = t => e => {
    e.preventDefault();
    const tlds =
      typeof this.state.tld === "string" ? [this.state.tld] : this.state.tld;
    const tld = tlds.filter(x => x !== t);

    this.setState({ tld });
  };

  changeCustomer = vals => {
    if (this.props.new) {
      this.addCompany(vals, true);
    } else {
      this.setState({ customerCompanies: vals });
    }
  };
  changeProblem = e => {
    const { name, checked } = e.target;
    checked ? this.addProblem(name) : this.removeProblem(name);
  };
  removeProblem = key => {
    const custs = this.state.customerProblems.filter(c => c !== key);
    this.setState({ customerProblems: custs });
  };
  addProblem = key => {
    const custs = this.state.customerProblems;
    custs.push(key);
    this.setState({ customerProblems: custs });
  };
  selectReport = e => {
    this.setState({ report: e.currentTarget.value });
  };
  showDashboard = e => {
    e.preventDefault();

    if (!this.state.showDashboard) {
      this.setState({ showDashboard: true });
    } else {
      this.setState({ showDashboard: false });
    }
  };
  onChangeAddCompany = vals => {
    this.setState({ addCompanies: vals });
  };
  onChangeBlockCompany = vals => {
    this.setState({ blockCompanies: vals });
  };
  onAddCompetitor = e => {
    e.preventDefault();
    const { selectedCompetitor: vals, competitorWeight } = this.state;
    if (!vals || vals.length === 0) {
      return;
    }
    this.setState(prevState => {
      const { competitors } = prevState;

      return {
        competitors: [
          ...competitors,
          ...vals
            .map(value => {
              const existingCompetitor = competitors.find(
                competitor => competitor.tld === value
              );
              return !existingCompetitor
                ? {
                    tld: value,
                    weight: competitorWeight || 10,
                  }
                : null;
            })
            .filter(c => c),
        ],
        selectedCompetitor: null,
        competitorWeight: 10,
      };
    });
  };
  onChangeCompetitor = vals => {
    this.setState({ selectedCompetitor: vals });
  };
  deleteCompetitor = i => e => {
    e.preventDefault();

    const { competitors } = this.state;

    const newCompetitors = competitors.filter((t, x) => x !== i);
    this.setState({ competitors: newCompetitors });
  };
  setCompetitorEditable =
    (i, editableState = true, competitor) =>
    e => {
      e.preventDefault();

      const { competitors } = this.state;

      const newCompetitors = competitors.map((t, x) =>
        x === i
          ? {
              ...t,
              edit: editableState,
            }
          : {
              ...t,
              edit: null,
            }
      );
      this.setState({
        competitors: newCompetitors,
        editingCompetitorIndex: editableState ? i : null,
        editedCompetitorWeight: competitor ? competitor.weight || 10 : null,
      });
    };
  saveCompetitor = i => e => {
    e.preventDefault();
    const { competitors } = this.state;
    const newCompetitors = competitors.map((t, x) =>
      x === i
        ? {
            ...t,
            edit: false,
            weight: this.state.editedCompetitorWeight,
          }
        : t
    );
    this.setState({
      competitors: newCompetitors,
      editingCompetitorIndex: null,
      editedCompetitorWeight: null,
    });
  };
  isValidUrl = string => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };
  onAddImpURL = e => {
    e.preventDefault();

    const { important_url, impURLWeight } = this.state;
    console.log({ important_url, impURLWeight });
    if (
      !important_url ||
      important_url.trim === "" ||
      !this.isValidUrl(important_url)
    ) {
      return;
    }
    this.setState(prevState => {
      const { impURLs } = prevState;

      return {
        impURLs: [
          ...impURLs,
          {
            url: important_url,
            weight: Number(impURLWeight),
          },
        ],
        important_url: "",
        impURLWeight: 10,
      };
    });
  };
  saveImpURL = i => e => {
    e.preventDefault();
    const { impURLs } = this.state;
    const newImpURLs = impURLs.map((t, x) =>
      x === i
        ? {
            ...t,
            edit: false,
            weight: Number(this.state.editedImpURLWeight),
          }
        : t
    );
    this.setState({
      impURLs: newImpURLs,
      editingImpURLIndex: null,
      editedImpURLWeight: null,
    });
  };
  setimpURLEditable =
    (i, editableState = true, impURL) =>
    e => {
      e.preventDefault();

      const { impURLs } = this.state;

      const newImpURLs = impURLs.map((t, x) =>
        x === i
          ? {
              ...t,
              edit: editableState,
            }
          : {
              ...t,
              edit: null,
            }
      );
      this.setState({
        impURLs: newImpURLs,
        editingImpURLIndex: editableState ? i : null,
        editedImpURLWeight: impURL ? impURL.weight || 10 : null,
      });
    };
  deleteImpURL = i => e => {
    e.preventDefault();

    const { impURLs } = this.state;

    const newImpURLs = impURLs.filter((t, x) => x !== i);
    this.setState({ impURLs: newImpURLs });
  };
  changeNewSectors = vals => {
    this.setState({ newSectors: vals });
  };
  onAddNegativeTag = e => {
    e.preventDefault();
    const stateTags = this.state.negative_tags || [];
    const negative_weight = this.state.negative_weight || 5;
    const prefType =
      this.state.negativePrefType ||
      TAG_PREF_TYPE.find(prefType => prefType.value === "customer_pref").value;
    const { negative_email_tags } = this.state;
    const url = negative_email_tags.split("?")[1];
    const tags = url.split("&");

    const tagsObj = {};
    tags.forEach(t => {
      const val = t.split("=");
      const vals = val[1].split("|");
      if (!tagsObj[val[0]]) {
        tagsObj[val[0]] = [];
      }
      vals.forEach(v => {
        tagsObj[val[0]].push(decodeURIComponent(v));
      });
    });
    tagsObj["weight"] = negative_weight;
    tagsObj["prefType"] = prefType;
    /*
      tagsObj: {
        tags: [tag1, tag2]
        page_type: [pagetype1, pagetype2]
        weight: 1
      }
    */
    stateTags.push(tagsObj);
    this.setState({ negative_tags: stateTags }, () => {
      this.input.current.value = "";
      this.input.current.focus();
    });
  };
  onAddTag = e => {
    e.preventDefault();
    const stateTags = this.state.tags || [];
    const weight = this.state.weight || 1;
    const prefType =
      this.state.prefType ||
      TAG_PREF_TYPE.find(prefType => prefType.value === "customer_pref").value;
    const { email_tags } = this.state;
    const url = email_tags.split("?")[1];
    const tags = url.split("&");

    const tagsObj = {};
    tags.forEach(t => {
      const val = t.split("=");
      const vals = val[1].split("|");
      if (!tagsObj[val[0]]) {
        tagsObj[val[0]] = [];
      }
      vals.forEach(v => {
        tagsObj[val[0]].push(decodeURIComponent(v));
      });
    });
    tagsObj["weight"] = weight;
    tagsObj["prefType"] = prefType;
    stateTags.push(tagsObj);
    this.setState({ tags: stateTags }, () => {
      this.tagInput.current.value = "";
      this.tagInput.current.focus();
    });
  };
  deleteNegativeTag = i => e => {
    e.preventDefault();

    const { negative_tags } = this.state;

    const newTags = negative_tags.filter((t, x) => x !== i);
    this.setState({ negative_tags: newTags });
  };
  deleteTag = i => e => {
    e.preventDefault();

    const { tags } = this.state;

    const newTags = tags.filter((t, x) => x !== i);
    this.setState({ tags: newTags });
  };
  setTagEditable =
    (i, editableState = true, tag) =>
    e => {
      e.preventDefault();

      const { tags } = this.state;

      const newTags = tags.map((t, x) =>
        x === i
          ? {
              ...t,
              edit: editableState,
            }
          : {
              ...t,
              edit: null,
            }
      );
      this.setState({
        tags: newTags,
        editingTagIndex: editableState ? i : null,
        editedWeight: tag ? tag.weight || 5 : null,
        editedPrefType: tag ? tag.prefType || DEFAULT_TAG_PREF_TYPE : null,
      });
    };
  saveTag = i => e => {
    e.preventDefault();
    const { tags } = this.state;
    const newTags = tags.map((t, x) =>
      x === i
        ? {
            ...t,
            edit: false,
            weight: this.state.editedWeight,
            prefType: this.state.editedPrefType,
          }
        : t
    );
    this.setState({
      tags: newTags,
      editingTagIndex: null,
      editedWeight: null,
      editedPrefType: null,
    });
  };
  setNegativeTagEditable =
    (i, editableState = true, tag) =>
    e => {
      e.preventDefault();

      const { negative_tags } = this.state;

      const newTags = negative_tags.map((t, x) =>
        x === i
          ? {
              ...t,
              edit: editableState,
            }
          : {
              ...t,
              edit: null,
            }
      );
      this.setState({
        negative_tags: newTags,
        negativeEditingTagIndex: editableState ? i : null,
        negativeEditedWeight: tag ? tag.weight || 5 : null,
        negativeEditedPrefType: tag
          ? tag.prefType || DEFAULT_TAG_PREF_TYPE
          : null,
      });
    };
  saveNegativeTag = i => e => {
    e.preventDefault();
    const { negative_tags } = this.state;
    const newTags = negative_tags.map((t, x) =>
      x === i
        ? {
            ...t,
            edit: false,
            weight: this.state.negativeEditedWeight,
            prefType: this.state.negativeEditedPrefType,
          }
        : t
    );
    this.setState({
      negative_tags: newTags,
      negativeEditingTagIndex: null,
      negativeEditedWeight: null,
      negativeEditedPrefType: null,
    });
  };
  getEmailPreview = customer => e => {
    e.preventDefault();
    this.setState({ gettingPreview: true, loadPreview: customer });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/admin/preview_ab_email`,
        form: {
          customer,
        },
      },
      (err, res, responseBody) => {
        if (err) {
          this.setState({
            error: true,
            gettingPreview: false,
          });
          alert("something went wrong!");
          return;
        }
        const { success, data } = JSON.parse(responseBody);
        this.setState({
          gettingPreview: false,
          previewData:
            success && data && data.final_tests ? data.final_tests : "No Data",
          allData: success && data && data.final_tests ? data.tests : {},
        });
      }
    );
  };
  onClosePreview = e => {
    this.setState({
      previewData: null,
      loadPreview: null,
      allData: null,
    });
  };
  selectSend = e => {
    e.preventDefault();
    this.setState({
      selectedSend: true,
    });
  };
  sendEmails = e => {
    e.preventDefault();
    this.setState({ sending: true });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/send_ab_email`,
        form: {
          emails: this.state.emails
            .split(",")
            .map(e => e.trim())
            .join("*|*"),
          customer: this.state.urlkey,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            sending: false,
          });
          alert("something went wrong!");
          return;
        }
        this.setState({ sending: false, selectedSend: null });
      }
    );
  };
  render() {
    const { urlkey, name, report, reports, sectors, new_sectors, prices } =
      this.props;
    const {
      saving,
      customerCompanies,
      real,
      timezone,
      newSectors,
      demo,
      paid,
      freeTrial,
      price,
      semOnly,
      abOnly,
      invite,
      weight,
      negative_weight,
      prefType,
      negativePrefType,
      editedPrefType,
      editedWeight,
      editingTagIndex,
      negativeEditedPrefType,
      negativeEditedWeight,
      negativeEditingTagIndex,
      seats,
      totalUsers,
      editingCompetitorIndex,
      editedCompetitorWeight,
      competitorWeight,
      unlock_limit,
      selectedSend,
      important_url,
      impURLWeight,
      editingImpURLIndex,
      editedImpURLWeight,
    } = this.state;
    let tld = this.state.tld;
    if (typeof tld === "string") {
      tld = [this.state.tld];
    }

    let displayNewSectors = [];

    if (newSectors.length > 0) {
      newSectors.forEach(sector => {
        const fullSector = new_sectors.find(s => s.key === sector);
        if (fullSector) {
          displayNewSectors.push(fullSector);
        }
      });
    }

    let addCompanies = [];
    let blockCompanies = [];
    let competitors = [];

    if (this.state.addCompanies && this.props.addCompanies) {
      addCompanies = this.props.companies.filter(c =>
        this.state.addCompanies.includes(c.tld)
      );
    }
    if (this.state.blockCompanies && this.props.blockCompanies) {
      blockCompanies = this.props.companies.filter(c =>
        this.state.blockCompanies.includes(c.tld)
      );
    }
    if (this.state.competitors && this.props.competitors) {
      competitors = this.props.companies.filter(c =>
        this.state.competitors.some(competitor => competitor.tld === c.tld)
      );
    }

    return (
      <form onSubmit={this.handleForm} className="pa2 mt4">
        <>
          {this.props.new ? (
            <>
              <h3 className="mb3">Add New Customer</h3>
              <input
                type="text"
                name="urlkey"
                placeholder="Customer URL key"
                value={this.state.urlkey}
                className="pa2"
                onChange={this.onChange}
              />
            </>
          ) : (
            <>{urlkey}</>
          )}
          <input
            type="text"
            name="name"
            defaultValue={name}
            placeholder="Customer Name"
            value={this.state.name}
            className="ml2 pa2"
            onChange={this.onChange}
          />
          <div>
            <h4 className="pt3">Customer Domains</h4>
            {tld && (
              <div className="mt3">
                {tld.map(t => (
                  <div className="dib br3 bg-black-05 pa1 mr2">
                    {t}{" "}
                    <a href=" #" className="ml2" onClick={this.removeTld(t)}>
                      x
                    </a>
                  </div>
                ))}
              </div>
            )}
            <input
              type="text"
              name="tld"
              placeholder="Customer domain (ex: google.com)"
              className="mt3 pa2 w-25"
              onChange={this.onChangeTld}
              value={this.state.currentTld}
              ref={this.tld}
            />
            <button
              onClick={this.addTld}
              className="ml2 ui-button secondary ui-button-small"
            >
              Add
            </button>
          </div>
          <div>
            <h4 className="pt3">Companies to add to publishing list</h4>
          </div>
          <TagSearch
            options={this.props.companies.map(c => ({
              label: `${c.name} (${c.tld})`,
              value: c.tld,
            }))}
            defaultTags={addCompanies.map(c => ({
              label: `${c.name} (${c.tld})`,
              value: c.tld,
            }))}
            callback={this.onChangeAddCompany}
            placeholder="Add Company"
            menuAtBottom
            copyData={getCopyData("publishing_companies")}
            title="publishing_companies"
            copyCallback={this.props.copyCallback}
          />
          <div>
            <h4 className="pt3">Companies to never publish</h4>
          </div>
          <TagSearch
            options={this.props.companies.map(c => ({
              label: `${c.name} (${c.tld})`,
              value: c.tld,
            }))}
            defaultTags={blockCompanies.map(c => ({
              label: `${c.name} (${c.tld})`,
              value: c.tld,
            }))}
            callback={this.onChangeBlockCompany}
            placeholder="Remove Company"
            menuAtBottom
            copyData={getCopyData("never_companies")}
            title="never_companies"
            copyCallback={this.props.copyCallback}
          />
          <div>
            <h4 className="pt3">Most important companies (Email recs)</h4>
          </div>
          <div className="flex mb2">
            <div className="w-50">
              <TagSearch
                options={this.props.companies.map(c => ({
                  label: `${c.name} (${c.tld})`,
                  value: c.tld,
                }))}
                defaultTags={[]}
                callback={this.onChangeCompetitor}
                placeholder="Most important companies"
                singular
                key={`competitor-${this.state.competitors.length}`}
              />
            </div>

            <div className="flex flex-columns items-center">
              <label className="b mh2 mb0">Weight</label>
              <select
                name="competitorWeight"
                onChange={this.onChange}
                value={competitorWeight}
                className="h-100"
              >
                {TAG_WEIGHTINGS.map(weight => (
                  <option value={weight}>{weight}</option>
                ))}
              </select>
            </div>

            <button
              onClick={this.onAddCompetitor}
              className="ui-button ui-button-small secondary ml2"
            >
              Add
            </button>
          </div>

          <div className="pt2">
            {this.state.competitors.map((competitor, i) => {
              return (
                <div
                  className="pr2 dib mb2"
                  key={`${this.props.tld}-${competitor}-${i}`}
                >
                  <span className="bg-black-05 pa1 mb1 br3 mr1">
                    {Object.keys(competitor).map((key, o) =>
                      key === "edit" ? (
                        <></>
                      ) : (
                        <span key={`${this.props.tld}-${competitor[key]}-${o}`}>
                          <b>{key === "tld" ? "company" : key}</b>:{" "}
                          {key === "tld"
                            ? this.props.companies.find(
                                company => company.tld === competitor[key]
                              ).name
                            : competitor[key]}
                          {o !== Object.keys(competitor).length - 1 ? (
                            <>&middot; </>
                          ) : (
                            <></>
                          )}
                        </span>
                      )
                    )}
                    {competitor.edit && i === editingCompetitorIndex ? (
                      <>
                        <select
                          name="editedCompetitorWeight"
                          onChange={this.onChange}
                          value={editedCompetitorWeight}
                        >
                          {TAG_WEIGHTINGS.map(weight => (
                            <option value={weight}>{weight}</option>
                          ))}
                        </select>

                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.saveCompetitor(i)}
                        >
                          <img
                            src={CheckIcon}
                            alt="save tag"
                            className="icon child"
                            height="24"
                          />
                        </a>
                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.setCompetitorEditable(i, false)}
                        >
                          <img
                            src={RedXIcon}
                            alt="cancle edit tag"
                            className="icon child"
                            height="12"
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.setCompetitorEditable(
                            i,
                            true,
                            competitor
                          )}
                        >
                          <img
                            src={EditIcon}
                            alt="edit tag"
                            className="icon child"
                            height="24"
                          />
                        </a>
                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.deleteCompetitor(i)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="edit tag"
                            className="icon child"
                            height="16"
                          />
                        </a>
                      </>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
          <CopyPaste
            copyCallback={(data, type) => {
              this.props.copyCallback(data);
              if (type === "paste") {
                this.setState({ competitors: data.data });
              }
            }}
            title={"important_companies"}
            options={this.state.competitors}
          />
          <h4 className="pt3">Sectors</h4>
          <TagSearch
            defaultTags={customerCompanies.map(c => ({
              label: c,
              value: c,
            }))}
            options={sectors.map(c => ({
              label: c,
              value: c,
            }))}
            callback={this.changeCustomer}
            placeholder="Sectors"
            copyData={getCopyData("sectors")}
            title="sectors"
            copyCallback={this.props.copyCallback}
          />
          <h4 className="pt3">New Sectors</h4>
          <TagSearch
            defaultTags={displayNewSectors.map(c => ({
              label: c.display_name,
              value: c.key,
            }))}
            options={new_sectors.map(c => ({
              label: c.display_name,
              value: c.key,
            }))}
            callback={this.changeNewSectors}
            placeholder="New Sectors"
            copyData={getCopyData("new_sectors")}
            title="new_sectors"
            copyCallback={this.props.copyCallback}
          />

          <div>
            <h4 className="pt3">Important URLs</h4>
          </div>
          <div className="flex mb2">
            <div className="w-50">
              <input
                type="url"
                name="important_url"
                placeholder="URL"
                onChange={this.onChange}
                className="w-100"
                value={important_url}
                // ref={this.tagInput}
              />
            </div>

            <div className="flex flex-columns items-center">
              <label className="b mh2 mb0">Weight</label>
              <select
                name="impURLWeight"
                onChange={this.onChange}
                value={impURLWeight}
                className="h-100"
              >
                {TAG_WEIGHTINGS.map(weight => (
                  <option value={weight}>{weight}</option>
                ))}
              </select>
            </div>

            <button
              onClick={this.onAddImpURL}
              className="ui-button ui-button-small secondary ml2"
            >
              Add
            </button>
          </div>
          <div className="pt2">
            {this.state.impURLs.map((impURL, i) => {
              return (
                <div
                  className="pr2 dib mb2"
                  key={`${this.props.tld}-${impURL.url}-${i}`}
                >
                  <span className="bg-black-05 pa1 mb1 br3 mr1">
                    {Object.keys(impURL).map((key, o) =>
                      key === "edit" ? (
                        <></>
                      ) : (
                        <span key={`${this.props.tld}-${impURL[key]}-${o}`}>
                          <b>{key}</b>: {impURL[key]}
                          {o !== Object.keys(impURL).length - 1 ? (
                            <>&middot; </>
                          ) : (
                            <></>
                          )}
                        </span>
                      )
                    )}
                    {impURL.edit && i === editingImpURLIndex ? (
                      <>
                        <select
                          name="editedImpURLWeight"
                          onChange={this.onChange}
                          value={editedImpURLWeight}
                        >
                          {TAG_WEIGHTINGS.map(weight => (
                            <option value={weight}>{weight}</option>
                          ))}
                        </select>

                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.saveImpURL(i)}
                        >
                          <img
                            src={CheckIcon}
                            alt="save tag"
                            className="icon child"
                            height="24"
                          />
                        </a>
                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.setimpURLEditable(i, false)}
                        >
                          <img
                            src={RedXIcon}
                            alt="cancle edit tag"
                            className="icon child"
                            height="12"
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.setimpURLEditable(i, true, impURL)}
                        >
                          <img
                            src={EditIcon}
                            alt="edit tag"
                            className="icon child"
                            height="24"
                          />
                        </a>
                        <a
                          href="#"
                          style={{ padding: "6px 6px" }}
                          onClick={this.deleteImpURL(i)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="edit tag"
                            className="icon child"
                            height="16"
                          />
                        </a>
                      </>
                    )}
                  </span>
                </div>
              );
            })}
          </div>

          <div className="mv2">
            <label className="db b">Subscription</label>
            <select name="price" onChange={this.onChange} value={price}>
              <option value="">-- Add a subscription --</option>
              {prices.map(price => (
                <option value={price.default_price}>
                  {price.name} {price.description} (
                  {formatStripePrice(
                    price.price_data ? price.price_data.unit_amount : 0
                  )}
                  )
                </option>
              ))}
            </select>
          </div>
          {!this.props.new && (
            <>
              <h4 className="pt3">Focuses (Email Recommendations)</h4>
              <input
                type="text"
                name="email_tags"
                placeholder="URL"
                onChange={this.onChange}
                className="w-50"
                ref={this.tagInput}
              />
              <div className="mv2 flex justify-start">
                <div className="mv2">
                  <label className="db b">Weight</label>
                  <select name="weight" onChange={this.onChange} value={weight}>
                    {TAG_WEIGHTINGS.map(weight => (
                      <option value={weight}>{weight}</option>
                    ))}
                  </select>
                </div>
                <div className="mv2 pl3">
                  <label className="db b">Preference</label>
                  <select
                    name="prefType"
                    onChange={this.onChange}
                    value={prefType}
                  >
                    {TAG_PREF_TYPE.map(prefType => (
                      <option value={prefType.value}>{prefType.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                onClick={this.onAddTag}
                className="ui-button ui-button-small secondary"
              >
                Add
              </button>
              <div className="pt2">
                {this.state.tags.map((tag, i) => {
                  return (
                    <div
                      className="pr2 dib mb2"
                      key={`${this.props.tld}-${tag}-${i}`}
                    >
                      <span className="bg-black-05 pa1 mb1 br3 mr1">
                        {Object.keys(tag).map((t, o) =>
                          t === "edit" ? (
                            <></>
                          ) : (
                            <span key={`${this.props.tld}-${tag[t]}-${o}`}>
                              {t !== "prefType" && (
                                <>
                                  <b>{t}</b>:{" "}
                                  {Array.isArray(tag[t])
                                    ? tag[t].join(", ")
                                    : tag[t]}{" "}
                                </>
                              )}
                              {t === "prefType" && (
                                <b className="green">
                                  {
                                    TAG_PREF_TYPE.find(
                                      prefType => prefType.value === tag[t]
                                    ).name
                                  }
                                </b>
                              )}
                              {o !== Object.keys(tag).length - 1 ? (
                                <>&middot; </>
                              ) : (
                                <></>
                              )}
                            </span>
                          )
                        )}
                        {tag.edit && i === editingTagIndex ? (
                          <>
                            <select
                              name="editedWeight"
                              onChange={this.onChange}
                              value={editedWeight}
                            >
                              {TAG_WEIGHTINGS.map(weight => (
                                <option value={weight}>{weight}</option>
                              ))}
                            </select>

                            <select
                              name="editedPrefType"
                              onChange={this.onChange}
                              value={editedPrefType}
                              className="ml1"
                            >
                              {TAG_PREF_TYPE.map(prefType => (
                                <option value={prefType.value}>
                                  {prefType.name}
                                </option>
                              ))}
                            </select>
                            <a
                              href="#"
                              style={{ padding: "6px 6px" }}
                              onClick={this.saveTag(i)}
                            >
                              <img
                                src={CheckIcon}
                                alt="save tag"
                                className="icon child"
                                height="24"
                              />
                            </a>
                            <a
                              href="#"
                              style={{ padding: "6px 6px" }}
                              onClick={this.setTagEditable(i, false)}
                            >
                              <img
                                src={RedXIcon}
                                alt="cancle edit tag"
                                className="icon child"
                                height="12"
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="#"
                              style={{ padding: "6px 6px" }}
                              onClick={this.setTagEditable(i, true, tag)}
                            >
                              <img
                                src={EditIcon}
                                alt="edit tag"
                                className="icon child"
                                height="24"
                              />
                            </a>
                            <a
                              href="#"
                              style={{ padding: "6px 6px" }}
                              onClick={this.deleteTag(i)}
                            >
                              <img
                                src={DeleteIcon}
                                alt="edit tag"
                                className="icon child"
                                height="16"
                              />
                            </a>
                          </>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
              <CopyPaste
                copyCallback={(data, type) => {
                  this.props.copyCallback(data);
                  if (type === "paste") {
                    this.setState({ tags: data.data, toCopy });
                  }
                }}
                title={"tags"}
                options={this.state.tags}
              />
              <h4 className="pt3">
                Negative Weighted Focuses (Email Recommendations)
              </h4>
              <input
                type="text"
                name="negative_email_tags"
                placeholder="URL"
                onChange={this.onChange}
                className="w-50"
                ref={this.input}
              />
              <div className="mv2 flex justify-start">
                <div className="mv2">
                  <label className="db b">Weight</label>
                  <select
                    name="negative_weight"
                    onChange={this.onChange}
                    value={negative_weight}
                  >
                    {TAG_WEIGHTINGS.map(weight => (
                      <option value={weight}>{weight}</option>
                    ))}
                  </select>
                </div>
                <div className="mv2 pl3">
                  <label className="db b">Preference</label>
                  <select
                    name="negativePrefType"
                    onChange={this.onChange}
                    value={negativePrefType}
                  >
                    {TAG_PREF_TYPE.map(prefType => (
                      <option value={prefType.value}>{prefType.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                onClick={this.onAddNegativeTag}
                className="ui-button secondary ui-button-small"
              >
                Add
              </button>
              <div className="pt2">
                {this.state.negative_tags.map((tag, i) => {
                  return (
                    <div
                      className="pr2 dib mb2"
                      key={`${this.props.tld}-${tag}-${i}`}
                    >
                      <span className="bg-black-05 pa1 mb1 br3 mr1">
                        {Object.keys(tag).map((t, o) =>
                          t === "edit" ? (
                            <></>
                          ) : (
                            <span key={`${this.props.tld}-${tag[t]}-${o}`}>
                              {t !== "prefType" && (
                                <>
                                  <b>{t}</b>:{" "}
                                  {Array.isArray(tag[t])
                                    ? tag[t].join(", ")
                                    : tag[t]}{" "}
                                </>
                              )}
                              {t === "prefType" && (
                                <b className="green">
                                  {
                                    TAG_PREF_TYPE.find(
                                      prefType => prefType.value === tag[t]
                                    ).name
                                  }
                                </b>
                              )}
                              {o !== Object.keys(tag).length - 1 ? (
                                <>&middot; </>
                              ) : (
                                <></>
                              )}
                            </span>
                          )
                        )}
                        {tag.edit && i === negativeEditingTagIndex ? (
                          <>
                            <select
                              name="negativeEditedWeight"
                              onChange={this.onChange}
                              value={negativeEditedWeight}
                            >
                              {TAG_WEIGHTINGS.map(weight => (
                                <option value={weight}>{weight}</option>
                              ))}
                            </select>

                            <select
                              name="negativeEditedPrefType"
                              onChange={this.onChange}
                              value={negativeEditedPrefType}
                              className="ml1"
                            >
                              {TAG_PREF_TYPE.map(prefType => (
                                <option value={prefType.value}>
                                  {prefType.name}
                                </option>
                              ))}
                            </select>
                            <a
                              href=" #"
                              style={{ padding: "6px 6px" }}
                              onClick={this.saveNegativeTag(i)}
                            >
                              <img
                                src={CheckIcon}
                                alt="save tag"
                                className="icon child"
                                height="24"
                              />
                            </a>
                            <a
                              href=" #"
                              style={{ padding: "6px 6px" }}
                              onClick={this.setNegativeTagEditable(i, false)}
                            >
                              <img
                                src={RedXIcon}
                                alt="cancle edit tag"
                                className="icon child"
                                height="12"
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href=" #"
                              style={{ padding: "6px 6px" }}
                              onClick={this.setNegativeTagEditable(
                                i,
                                true,
                                tag
                              )}
                            >
                              <img
                                src={EditIcon}
                                alt="edit tag"
                                className="icon child"
                                height="24"
                              />
                            </a>
                            <a
                              href=" #"
                              style={{ padding: "6px 6px" }}
                              onClick={this.deleteNegativeTag(i)}
                            >
                              <img
                                src={DeleteIcon}
                                alt="edit tag"
                                className="icon child"
                                height="16"
                              />
                            </a>
                          </>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
              <CopyPaste
                copyCallback={(data, type) => {
                  if (type === "paste") {
                    this.setState({ negative_tags: data.data });
                  }
                }}
                title={"negative_tags"}
                options={this.state.negative_tags}
              />
              <div className="mb3 mt4">
                <Link to={`/admin/customers/dashboard/${urlkey}`}>
                  Edit Dashboard Settings
                </Link>
              </div>
              <div>
                <ReportSelect
                  reports={reports}
                  selectedReport={report}
                  selectReport={this.selectReport}
                />
              </div>
            </>
          )}
          {this.props.new && (
            <div className="mv2">
              <label className="db b">Add tests from</label>
              <select
                name="timeframe"
                onChange={this.onChange}
                defaultValue={this.state.timeframe}
              >
                <option value="90">90 days back</option>
                <option value="180">180 days back</option>
                <option value="365">1 year</option>
                <option value="540">1.5 years</option>
                <option value="all">All</option>
              </select>
            </div>
          )}
          <div className="mt3">
            <label className="db b">Timezone (email)</label>
            <TagSearch
              singular
              options={this.props.timezones.map(c => ({
                label: c,
                value: c,
              }))}
              defaultTags={
                timezone ? { label: timezone, value: timezone } : null
              }
              callback={this.changeTimezone}
              placeholder="Timezone"
            />
          </div>
          <div className="mt2">
            <label>
              <input
                type="checkbox"
                name="real"
                onChange={this.onChange}
                className="mr2"
                defaultChecked={real}
              />{" "}
              Real customer
            </label>
            <label>
              <input
                type="checkbox"
                name="demo"
                onChange={this.onChange}
                className="ml3 mr2"
                defaultChecked={demo}
              />{" "}
              Demo customer
            </label>
            <label>
              <input
                type="checkbox"
                name="freeTrial"
                onChange={this.onChange}
                className="ml3 mr2"
                defaultChecked={freeTrial}
              />{" "}
              Free Trial
            </label>
            <label>
              <input
                type="checkbox"
                name="paid"
                onChange={this.onChange}
                className="ml3 mr2"
                defaultChecked={paid}
              />{" "}
              Paid
            </label>
            <label>
              <input
                type="checkbox"
                name="semOnly"
                onChange={this.onChange}
                className="ml3 mr2"
                defaultChecked={semOnly}
              />{" "}
              SEM-only customer
            </label>
            <label>
              <input
                type="checkbox"
                name="abOnly"
                onChange={this.onChange}
                className="ml3 mr2"
                defaultChecked={abOnly}
              />{" "}
              AB-only customer
            </label>
            <label>
              <input
                type="checkbox"
                name="invite"
                onChange={this.onChange}
                className="ml3 mr2"
                defaultChecked={invite}
              />{" "}
              Invite-only (no open join)
            </label>
          </div>
          <div className="mv2">
            <div className="dib w5">
              <label className="db b"># Seats</label>
              <input
                type="number"
                name="seats"
                value={this.state.seats}
                onChange={this.onChange}
              ></input>
            </div>
            <div className="dib w5">
              <label className="db b"># Trial tests</label>
              <input
                type="number"
                name="unlock_limit"
                value={this.state.unlock_limit}
                onChange={this.onChange}
              ></input>
            </div>
          </div>
        </>
        <input type="submit" value="Save" className="ui-button primary" />
        {saving && <span>Saving&hellip;</span>}
        <div className="fixed flex w-100 left-0 bottom-0 bg-white bt b--light-gray pa2 justify-between z-999 items-center">
          <div className="flex items-center w-75">
            <p className="f4 b ttc mh2 mb0 ">{urlkey}</p>
            <input
              type="button"
              value={
                this.state.gettingPreview
                  ? "Generating preview"
                  : "Preview email"
              }
              className={cx("ui-button mh1 mr-2", {
                disabled: this.state.gettingPreview,
                secondary: !this.state.gettingPreview,
              })}
              onClick={this.getEmailPreview(urlkey)}
            />
            {selectedSend ? (
              <div className="w-100">
                <input
                  name="emails"
                  onChange={this.onChange}
                  defaultValue={
                    !this.state.emails
                      ? `${this.props.user.email},will@dowhatworks.io`
                      : this.state.emails
                  }
                  className="w-80"
                />
                {this.state.sending ? (
                  <>Sending...</>
                ) : (
                  <button onClick={this.sendEmails}>Send</button>
                )}
              </div>
            ) : (
              <>
                {/* <a href="# " onClick={this.selectSend} className="ml1">
                  Send <b>test</b> New Web Tests email
                </a> */}
                <input
                  type="button"
                  value={"Send Test Web test email"}
                  className={cx("ui-button mh1 mr-2 secondary")}
                  onClick={this.selectSend}
                />
              </>
            )}
          </div>
          <div>
            {saving && <span>Saving&hellip; &nbsp;</span>}
            <input
              type="submit"
              value="Save"
              className="ui-button primary mh1 ml-2 "
            />
            <input
              type="button"
              value="Cancel"
              className="ui-button secondary mh1"
              onClick={this.props.closeEditCompany}
            />
          </div>
        </div>
        {this.state.previewData && (
          <Modal open={true}>
            <div
              className="box"
              style={{
                width: `90vw`,
                height: `90vh`,
                top: "5vh",
                position: "absolute",
                left: "5vw",
              }}
            >
              <div
                className=""
                style={{
                  overflow: "scroll",
                  margin: "1rem",
                  height: "88vh",
                }}
              >
                <div className="pa3 bb b--black-05 flex justify-between items-center">
                  <h1>Email Recommendations</h1>
                  <button
                    onClick={this.onClosePreview}
                    className="fr mr5 bg-light-red white f7 ba"
                  >
                    x
                  </button>
                </div>
                <div className="pa3">
                  <AbTestEmailPreview
                    previewData={this.state.previewData}
                    onClosePreview={this.onClosePreview}
                    showCloseButton={false}
                    allData={this.state.allData}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </form>
    );
  }
}

export default Companies;
