import React from "react";
import request from "request";
import { baseApiUrl, getCompanyName } from "../../util";
import MultiSelect from "../../components/MultiSelect";
import { Link } from "react-router-dom";
import * as querystring from "query-string";
class Sem extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }
  state = {
    loading: true,
    company: null,
    country: "us",
    submitting: false,
    blacklist: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const { company, name } = querystring.parse(window.location.search);
    request(
      {
        url: `${baseApiUrl}/v1/admin/sem?report=${this.props.match.params.report}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          submitting: false,
          sem: json.sem,
          companies: json.companies.map(c => ({
            label: `${c.name} (${c.tld})`,
            value: `${c.name}||${c.tld}`,
          })),
          customer: name || getCompanyName(company),
          blacklist: json.report.blacklist || [],
          report_name: name,
          all_terms: json.all_terms,
          customer_terms: json.customer_terms,
        });
      }
    );
  };
  onKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  addKW = e => {
    e.preventDefault();
    const { blacklist } = this.state;

    if (this.state.keyword && this.state.keyword.trim() !== "") {
      blacklist.push(this.state.keyword);
    }
    this.setState(
      {
        blacklist,
        keyword: "",
      },
      () => {
        this.input.current.value = "";
        this.input.current.focus();
      }
    );
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem/blacklist`,
        form: {
          id: this.props.match.params.report,
          blacklist: blacklist.join("*|*"),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          alert("something went wrong!");
          return;
        }
      }
    );
  };
  removeKW = kw => e => {
    e.preventDefault();

    const { blacklist } = this.state;
    const newBlackList = blacklist.filter(k => k !== kw);
    this.setState({ blacklist: newBlackList });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem/blacklist`,
        form: {
          id: this.props.match.params.report,
          blacklist: newBlackList.join("*|*"),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          alert("something went wrong!");
          return;
        }
      }
    );
  };
  changeCountry = e => {
    this.setState({
      country: e.currentTarget.value,
    });
  };
  delete = id => e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete?")) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/admin/sem/delete`,
          form: {
            id,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.setState({ loading: true });
            this.getData();
          }
        }
      );
    }
  };
  setCompany = values => {
    this.setState({
      company: values.value,
    });
  };
  setKeyword = values => {
    this.setState({ keyword: values.value });
  };
  saveKeyword = e => {
    e.preventDefault();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem/term`,
        form: {
          report: this.props.match.params.report,
          term: this.state.keyword,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.getData();
        }
      }
    );
  };
  deleteKeyword = id => e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete?")) {
      request.delete(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/admin/sem/term`,
          form: {
            id,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.getData();
          }
        }
      );
    }
  };
  add = () => {
    this.setState({
      submitting: true,
    });
    const arr = this.state.company.split("||");
    const tld = arr[1];
    const company = arr[0];
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem`,
        form: {
          report: this.props.match.params.report,
          company,
          tld,
          country: this.state.country,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.getData();
        }
      }
    );
  };

  render() {
    const { loading, sem, customer, submitting, all_terms, customer_terms } =
      this.state;
    return (
      <div className="box pa3">
        <h1>Edit SEM for {customer}</h1>
        {customer_terms && (
          <Link
            to={`/admin/sem_companies?terms=${customer_terms
              .map(t => encodeURIComponent(t.term))
              .join("||")}`}
          >
            See un-added sem companies
          </Link>
        )}
        <div className="mb3">
          <div>
            <b>Keyword blacklist</b>
          </div>
          <input
            type="text"
            onChange={this.onKeyUp}
            name="keyword"
            ref={this.input}
          />
          <button className="ml2" onClick={this.addKW}>
            Add
          </button>
          {this.state.blacklist && (
            <div className="mt2">
              {this.state.blacklist.map(k => (
                <span className="pa2 bg-black-10 mr2 mb2 dib br2">
                  {k}{" "}
                  <a href=" #" onClick={this.removeKW(k)}>
                    x
                  </a>
                </span>
              ))}
            </div>
          )}
        </div>
        {loading && <b>Loading...</b>}
        {!loading && sem && (
          <div className="flex">
            <div className="w-50">
              <div className="mb3">
                <h4>Companies</h4>
                <div className="flex mb2">
                  <div className="w-50 mr2">
                    <MultiSelect
                      options={this.state.companies}
                      token={this.props.user.token}
                      callback={this.setCompany}
                      singular={true}
                      className="w-100"
                    />
                  </div>
                  <div className="w-50">
                    <select name="country" onChange={this.changeCountry}>
                      <option value="us">US</option>
                      <option value="uk">UK</option>
                      <option value="au">Australia</option>
                    </select>
                  </div>
                </div>
                <button
                  onClick={this.add}
                  className="button"
                  disabled={submitting}
                >
                  {submitting ? <>Saving...</> : <>Save</>}
                </button>
              </div>
              <div>
                {sem.map(s => {
                  return (
                    <p>
                      {s.name} ({s.country || "us"}) &middot; ({s.tld})
                      <a href=" #" onClick={this.delete(s.id)}>
                        Delete
                      </a>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="w-50">
              <h4>Keywords</h4>
              <div className="mb3 flex">
                <MultiSelect
                  options={all_terms.map(c => ({
                    label: `${c.displayTerm}${
                      c.locale && c.locale !== "us" ? ` (${c.locale})` : ""
                    }`,
                    value: c.term,
                  }))}
                  token={this.props.user.token}
                  callback={this.setKeyword}
                  singular={true}
                  className="w-100"
                />
                <button
                  className="ml2 ui-button primary tiny"
                  onClick={this.saveKeyword}
                >
                  Save
                </button>
              </div>
              <div>
                {customer_terms.map(term => (
                  <div className="pt2">
                    {term.displayTerm}{" "}
                    {term.locale && term.locale !== "us" && (
                      <>({term.locale})</>
                    )}
                    <a
                      href=" #"
                      onClick={this.deleteKeyword(term.id)}
                      className="ml2 red"
                    >
                      x
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Sem;
