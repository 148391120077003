import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import { baseApiUrl } from "../../util";

class KW extends React.Component {
  state = {
    term: this.props.term,
    locale: this.props.locale,
  };
  input = React.createRef();
  changeTerm = e => {
    this.setState({ term: e.currentTarget.value });
  };
  changeLocale = e => {
    this.setState({ locale: e.currentTarget.value });
  };
  submit = e => {
    const { term, locale } = this.state;
    this.setState({ saving: true });
    e.preventDefault();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/keywords`,
        form: {
          term,
          locale,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.setState(
            {
              saving: false,
              displayTerm: "",
              term: "",
            },
            () => {
              if (this.input && this.input.current) {
                this.input.current.value = "";
                this.input.current.focus();
              }
              this.props.callback();
            }
          );
        }
      }
    );
  };
  delete = e => {
    e.preventDefault();

    this.setState({ saving: true });

    if (
      window.confirm(
        `Are you sure you want to deactivate "${this.state.term}"?`
      )
    ) {
      request.delete(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/v1/admin/keywords`,
          form: {
            term: this.state.term,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
              saving: false,
            });
            alert("Looks like something went wrong!");
            return;
          }
          if (!err) {
            this.props.callback();
          }
        }
      );
    }
  };
  render() {
    return (
      <form onSubmit={this.submit} className="mb4 w-40">
        {this.props.new ? (
          <>
            <input
              type="text"
              name="term"
              className="w-50 ph2 mr2"
              onChange={this.changeTerm}
              ref={this.input}
            />
            <select
              name="locale"
              onChange={this.changeLocale}
              value={this.state.locale}
            >
              <option value="us">US</option>
              <option value="au">AU</option>
              <option value="uk">UK</option>
            </select>
          </>
        ) : (
          <span>
            {this.props.displayTerm} &mdash;{" "}
            {this.state.locale ? this.state.locale : "US"}
          </span>
        )}
        {this.props.new ? (
          <input type="submit" value="Save" className="ml2 ui-button primary" />
        ) : (
          <>
            {this.props.status === "deactivated" ? (
              <b className="gray ml3">Deactivated</b>
            ) : (
              <>
                {this.state.saving ? (
                  <span>Deactivating&hellip;</span>
                ) : (
                  <input
                    type="button"
                    value="Deactivate"
                    className="ml2 ui-button primary"
                    onClick={this.delete}
                  />
                )}
              </>
            )}
          </>
        )}
      </form>
    );
  }
}

class Keywords extends React.Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/keywords`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          keywords: json.keywords,
        });
      }
    );
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const { keywords } = this.state;

    return (
      <div>
        <h1>Keywords Admin</h1>
        <KW new callback={this.getData} user={this.props.user} />

        {keywords.map(kw => (
          <KW
            term={kw.term}
            displayTerm={kw.displayTerm}
            locale={kw.locale}
            key={`term_${kw.term}`}
            callback={this.getData}
            user={this.props.user}
            status={kw.status}
          />
        ))}
      </div>
    );
  }
}

export default Keywords;
