import React from "react";

const Privacy = () => {
  return (
    <div style={{ textAlign: "left", padding: "20px" }}>
      <div>
        <p>
          <strong>
            <strong>PRIVACY POLICY</strong>
          </strong>
        </p>
        <p>
          <strong>Last updated September 30, 2019</strong>
        </p>
        <p></p>
        <p>
          <br />
        </p>
        <p>
          Thank you for choosing to be part of our community at Do What Works,
          LLC (“
          <strong>Company</strong>
          ”, “<strong>we</strong>
          ”, “<strong>us</strong>
          ”, or “<strong>our</strong>
          ”). We are committed to protecting your personal information and your
          right to privacy. If you have any questions or concerns about our
          policy, or our practices with regards to your personal information,
          please contact us at help@dowhatworks.io.
        </p>
        <p>
          When you visit our website https://dowhatworks.io , and use our
          services, you trust us with your personal information. We take your
          privacy very seriously. In this privacy policy , we describe our
          privacy policy . We seek to explain to you in the clearest way
          possible what information we collect, how we use it and what rights
          you have in relation to it. We hope you take some time to read through
          it carefully, as it is important. If there are any terms in this
          privacy policy that you do not agree with, please discontinue use of
          our Sites and our services.
        </p>
        <p>
          This privacy policy applies to all information collected through our
          website (such as https://dowhatworks.io ), and/or any related
          services, sales, marketing or events (we refer to them collectively in
          this privacy policy as the "<strong>Services</strong>
          ").
        </p>
        <p>
          <strong>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
          </strong>
        </p>
        <p></p>
        <p>
          <strong>TABLE OF CONTENTS</strong>
        </p>
        <p>
          <a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
        </p>
        <p>
          <a href="#infouse">2. HOW DO WE USE YOUR INFORMATION?</a>
        </p>
        <p>
          <a href="#infoshare">
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </a>
        </p>
        <p>
          <a href="#cookies">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </p>
        <p>
          <a href="#sociallogins">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </p>
        <p>
          <a href="#inforetain">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </p>
        <p>
          <a href="#infosafe">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </p>
        <p>
          <a href="#infominors">8. DO WE COLLECT INFORMATION FROM MINORS?</a>
        </p>
        <p>
          <a href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </p>
        <p>
          <a href="#DNT">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </p>
        <p>
          <a href="#caresidents">
            11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </p>
        <p>
          <a href="#policyupdates">12. DO WE MAKE UPDATES TO THIS POLICY?</a>
        </p>
        <p>
          <a href="#contact">13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a>
        </p>
        <p></p>
        <p id="infocollect">
          <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
        </p>
        <p></p>
        <p>
          <strong>Personal information you disclose to us</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            We collect personal information that you provide to us such as name,
            address, contact information, passwords and security data, payment
            information, and social media login data
          </em>
          .
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when registering at the Services expressing an interest in obtaining
          information about us or our products and services, when participating
          in activities on the Services or otherwise contacting us .
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Services , the choices you make and
          the products and features you use. The personal information we collect
          can include the following:
        </p>
        <p>
          <strong>Name and Contact Data.</strong>
          We collect your first and last name, email address, postal address,
          phone number, and other similar contact data.
        </p>
        <p>
          <strong>Credentials.</strong>
          We collect passwords, password hints, and similar security information
          used for authentication and account access.
        </p>
        <p>
          <strong>Payment Data.</strong>
          We collect data necessary to process your payment if you make
          purchases, such as your payment instrument number (such as a credit
          card number), and the security code associated with your payment
          instrument. All payment data is stored by Stripe. You may find their
          privacy policy link(s) here: https://www.stripe.com.
        </p>
        <p>
          <strong>Social Media Login Data.</strong>
          We provide you with the option to register using social media account
          details, like your Facebook, Twitter or other social media account. If
          you choose to register in this way, we will collect the Information
          described in the section called "
          <a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>"
          below.
        </p>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <p>
          <strong>Information automatically collected</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            Some information – such as IP address and/or browser and device
            characteristics – is collected automatically when you visit our
            Services.
          </em>
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Services . This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p></p>
        <div></div>
        <p id="infouse">
          <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            We process your information for purposes based on legitimate
            business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.
          </em>
        </p>
        <p>
          We use personal information collected via our Services for a variety
          of business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p></p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            <strong>To facilitate account creation and logon process.</strong>
            If you choose to link your account with us to a third party account
            *(such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and logon process for the performance of the
            contract. See the section below headed "
            <a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>" for
            further information.
          </li>
          <li>
            <strong>Fulfill and manage your orders.</strong>
            We may use your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Services.
          </li>
          <li>
            <strong>Request Feedback.</strong>
            We may use your information to request feedback and to contact you
            about your use of our Services.
          </li>
          <li>
            <strong>
              To enforce our terms, conditions and policies for Business
              Purposes, Legal Reasons and Contractual.
            </strong>
          </li>
          <li>
            <strong>To respond to legal requests and prevent harm.</strong>
            If we receive a subpoena or other legal request, we may need to
            inspect the data we hold to determine how to respond. We may use and
            store this information in aggregated and anonymized form so that it
            is not associated with individual end users and does not include
            personal information. We will not use identifiable personal
            information without your consent.
          </li>
        </ul>
        <p id="infoshare">
          <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            We only share information with your consent, to comply with laws, to
            provide you with services, to protect your rights, or to fulfill
            business obligations.
          </em>
        </p>
        <div></div>
        <div>
          We may process or share data based on the following legal basis:
        </div>
        <ul>
          <li>
            <strong>Consent:</strong>
            We may process your data if you have given us specific consent to
            use your personal information in a specific purpose.
          </li>
          <li>
            <strong>Legitimate Interests:</strong>
            We may process your data when it is reasonably necessary to achieve
            our legitimate business interests.
          </li>
          <li>
            <strong>Performance of a Contract:</strong>
            Where we have entered into a contract with you, we may process your
            personal information to fulfill the terms of our contract.
          </li>
          <li>
            <strong>Legal Obligations:</strong>
            We may disclose your information where we are legally required to do
            so in order to comply with applicable law, governmental requests, a
            judicial proceeding, court order, or legal process, such as in
            response to a court order or a subpoena (including in response to
            public authorities to meet national security or law enforcement
            requirements).
          </li>
          <li>
            <strong>Vital Interests:</strong>
            We may disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations
            of our policies, suspected fraud, situations involving potential
            threats to the safety of any person and illegal activities, or as
            evidence in litigation in which we are involved.
          </li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <ul>
          <li>
            <strong>
              Vendors, Consultants and Other Third-Party Service Providers.
            </strong>
            We may share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Services ,
            which will enable them to collect data about how you interact with
            the Services over time. This information may be used to, among other
            things, analyze and track data, determine the popularity of certain
            content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your
            information with third parties for their promotional purposes.
          </li>
          <li>
            <strong>Business Transfers.</strong>
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </li>
          <li>
            <strong>Third-Party Advertisers.</strong>
            We may use third-party advertising companies to serve ads when you
            visit the Services . These companies may use information about your
            visits to our Website(s) and other websites that are contained in
            web cookies and other tracking technologies in order to provide
            advertisements about goods and services of interest to you.
          </li>
          <li>
            <strong>Affiliates.</strong>
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this privacy policy .
            Affiliates include our parent company and any subsidiaries, joint
            venture partners or other companies that we control or that are
            under common control with us.
          </li>
        </ul>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <span id="cookies" />
          <strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
        </div>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            We may use cookies and other tracking technologies to collect and
            store your information.
          </em>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy .
        </p>
        <p></p>
        <p id="sociallogins">
          <strong>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            If you choose to register or log in to our services using a social
            media account, we may have access to certain information about you.
          </em>
        </p>
        <p>
          Our Services offer you the ability to register and login using your
          third party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile Information we receive may vary depending on the social media
          provider concerned, but will often include your name, e-mail address,
          friends list, profile picture as well as other information you choose
          to make public.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy policy or that are otherwise made clear to
          you on the Services . Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third
          party social media provider. We recommend that you review their
          privacy policy to understand how they collect, use and share your
          personal information, and how you can set your privacy preferences on
          their sites and apps.
        </p>
        <p></p>
        <p id="inforetain">
          <strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy policy unless otherwise required
            by law.
          </em>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy , unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us .
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize it, or, if
          this is not possible (for example, because your personal information
          has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until
          deletion is possible.
        </p>
        <div></div>
        <p id="infosafe">
          <strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </em>
        </p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure. Although we will do our best
          to protect your personal information, transmission of personal
          information to and from our Services is at your own risk. You should
          only access the services within a secure environment.
        </p>
        <p></p>
        <p id="infominors">
          <strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            We do not knowingly collect data from or market to children under 18
            years of age.
          </em>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services , you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services . If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we have collected from children under age 18, please
          contact us at help@dowhatworks.io.
        </p>
        <p></p>
        <p id="privacyrights">
          <strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
        </p>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>

          <em>
            You may review, change, or terminate your account at any time.
          </em>
        </p>
        <p>
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
        </p>
        <p>
          <strong>Account Information</strong>
        </p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Contact us using the contact information provided.</li>
          <li>Log into your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </p>
        <p>
          <strong>
            <u>Cookies and similar technologies:</u>
          </strong>
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. To
          opt-out of interest-based advertising by advertisers on our Services
          visit{" "}
          <a
            href="http://www.aboutads.info/choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Opting out of email marketing:</u>
          </strong>
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by
          contacting us using the details provided below. You will then be
          removed from the marketing email list – however, we will still need to
          send you service-related emails that are necessary for the
          administration and use of your account. To otherwise opt-out, you may:
        </p>
        <ul>
          <li>Contact us using the contact information provided.</li>
          <li>Log into your account settings and update your user account.</li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
