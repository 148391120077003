import React from "react";

const FakeReportBox = () => {
  return (
    <div className="db near-black block-link test-box has2">
      <div className="db near-black block-link f5 document-spacing center pb4">
        <p className="f5 mb3 near-black bb b--divider item-header ph2">
          <span
            className="fake-text bg-black-10 br2 ml1 mr1 w-30 dib"
            style={{ height: "30px" }}
          >
            &nbsp;
          </span>
        </p>
        <div className="ph3 pb3 flex has2">
          <div className="screenshot-container">
            <div className="shot-container">
              <div
                className="mt2 tl flex arrow-container mb3"
                style={{ position: "relative", zIndex: "2" }}
              >
                <div className="screenshotItem-box mr3">
                  <div className="img-box br2 center" alt="screenshot"></div>
                  <div className="screenshotItem-bottom">
                    <div className="f6 tc-s screenshotItem-notesHeader">
                      <span></span>
                    </div>
                    <div className="screenshotItem-notes">
                      <div className="">
                        <div className="note-tag-container flex">
                          <div className="note-tag-tag f7"></div>
                          <div className="note-tag-desc"></div>
                        </div>
                      </div>
                      <div className="note-odd">
                        <div className="note-tag-container flex">
                          <div className="note-tag-tag f7"></div>
                          <div className="note-tag-desc"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="screenshotItem-box mr3">
                  <div className="img-box br2 center" alt="screenshot"></div>
                  <div className="screenshotItem-bottom">
                    <div className="f6 tc-s screenshotItem-notesHeader">
                      <span></span>
                    </div>
                    <div className="screenshotItem-notes">
                      <div className="">
                        <div className="note-tag-container flex">
                          <div className="note-tag-tag f7"></div>
                          <div className="note-tag-desc"></div>
                        </div>
                      </div>
                      <div className="note-odd">
                        <div className="note-tag-container flex">
                          <div className="note-tag-tag f7"></div>
                          <div className="note-tag-desc"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-25 ss-info-box-container">
            <div className="ss-info-box-all mt2">
              <div className="company-box" style={{ height: "75px" }}>
                <div className="floating-icon tc center">
                  <span className="icon-circle dib" style={{ width: "45px" }}>
                    &nbsp;
                  </span>
                </div>
              </div>
              <div className="dates-box" style={{ height: "75px" }}>
                <div className="box-header"></div>
                <div></div>
              </div>
              <div
                className="dates-box rounded-bottom"
                style={{ height: "100px" }}
              >
                <div className="box-header"></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FakeReportBox;
