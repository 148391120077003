import React from "react";
import * as cx from "classnames";
import { useDraggable } from "@dnd-kit/core";
import NewEditableAutocompleteV2 from "../../../components/NewEditableAutocompleteV2";
import Upload from "../../../components/form/Upload";
import DragIcon from "../../../icons/drag_indicator.svg";
import { DraggableNotes } from "./DraggableNotes";
import { DDndContext } from "./DDndContext";
import { Droppable as DDroppable } from "./Droppable";

export const DraggableScreenshot = React.memo(props => {
  const {
    img,
    foo,
    selectFn,
    updateWeight,
    addNote,
    updateControl,
    updateChamp,
    sidebyside,
    scrollPosVert,
    handlePos,
    i,
    onLoad,
    posN,
    posI,
    onEditScreenshotImage,
    user,
    title,
    onDDragEndNotes,
    serverNoteTags,
    updateNotes,
    changePos,
    addSuggestionData,
    deleteSuggestionData,
    suggestionBox,
    moveUp,
    moveDown,
    deleteNoteAndPos,
    changeHide,
    screenshots,
    doFullPagePos,
    resetPos,
  } = props;
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: props.id,
      data: {
        type: props.type || "",
        index: props.index,
        screenshotIndex: props.screenshotIndex,
      },
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cx({
        "bg-light-silver": isDragging,
        "opacity-95": isDragging,
        "z-max": isDragging,
        "z-1": !isDragging,
      })}
    >
      <div
        className={cx({
          "dib pa2 ma2 ba bw2 tc be3": true,
          "b--white": !img.selected,
          "b--green": img.selected,
          "z-max": isDragging,
          "z-1": !isDragging,
        })}
        key={`i_${i}`}
        foo={foo}
      >
        <div className="flex">
          <label className="ma0 tc pl-5 w-90">
            <input
              type="checkbox"
              checked={img.selected}
              style={{ height: "20px", width: "20px" }}
              onChange={e => {
                selectFn(i, img.selected ? false : true);
              }}
            />{" "}
            <span className="b">
              {title || `Variant ${img.varNum}`} - {img.id}
            </span>
          </label>
          <div {...listeners} {...attributes}>
            <img src={DragIcon} className="tr" />
          </div>
        </div>
        <input
          name="percentage"
          value={img.weight}
          className="tc w-100 input-reset mb2"
          onChange={e => {
            updateWeight(i, e.currentTarget.value);
          }}
        />
        <DDndContext onDragEnd={onDDragEndNotes}>
          {img.notes && img.notes.length > 0 ? (
            img.notes.map((note, n) => {
              return (
                <DDroppable
                  id={`screenshot-${i}-note-drop-${n}`}
                  type={`notes`}
                  index={n}
                  screenshotIndex={i}
                  className="flex"
                >
                  <DraggableNotes
                    id={`screenshot-${i}-note-drag-${n}`}
                    type={`notes`}
                    index={n}
                    screenshotIndex={i}
                    i={i}
                    n={n}
                    img={img}
                    note={note}
                    changePos={changePos}
                    posI={posI}
                    posN={posN}
                    serverNoteTags={serverNoteTags}
                    user={user}
                    updateNotes={updateNotes}
                    addSuggestionData={addSuggestionData}
                    deleteSuggestionData={deleteSuggestionData}
                    suggestionBox={suggestionBox}
                    moveUp={moveUp}
                    moveDown={moveDown}
                    deleteNoteAndPos={deleteNoteAndPos}
                    changeHide={changeHide}
                    screenshots={screenshots}
                    doFullPagePos={doFullPagePos}
                    resetPos={resetPos}
                  ></DraggableNotes>
                </DDroppable>
              );
            })
          ) : (
            <div className="flex mb1">
              <div className="w-1 pa1 red b">1</div>
              <div className="w-60">
                <NewEditableAutocompleteV2
                  placeholder="Note"
                  items={serverNoteTags}
                  subtags={serverNoteTags}
                  className="w-100"
                  token={user.token}
                  callback={updateNotes(i, 0)}
                  initialValue={
                    img.notes && img.notes[i] ? img.notes[i][0] : undefined
                  }
                  focusCallback={changePos(i, 0)}
                  addSuggestionData={addSuggestionData(i, 0)}
                  deleteSuggestionData={deleteSuggestionData(i, 0)}
                  suggestionData={
                    suggestionBox[i] && suggestionBox[i][0]
                      ? suggestionBox[i][0]
                      : null
                  }
                />
              </div>
              <div className="w-30 pl1 pt1">
                <label onClick={changePos(i, 0)} className="flex">
                  <span className="dib pl3">
                    <input type="radio" checked={posI === i && posN === 0} />
                  </span>
                  <span className="f7 ml2 dib w-10">Add position</span>
                </label>
              </div>
            </div>
          )}
        </DDndContext>
        <div className="tl">
          <div className="mb2">
            <a href=" #" onClick={addNote(i)} className="sb f7">
              + add a tag
            </a>
          </div>
          <label className="mb1">
            <input
              type="checkbox"
              checked={img.control}
              onChange={e => {
                updateControl(i, img.control ? false : true, e);
              }}
            />{" "}
            Control
          </label>
          <label className="ml2">
            <input
              type="checkbox"
              checked={img.champ}
              onChange={e => {
                updateChamp(i, img.champ ? false : true, e);
              }}
            />{" "}
            Winner
          </label>
        </div>
        {!sidebyside && (
          <div
            className="pick-image-container image-scrolly"
            onScroll={scrollPosVert}
          >
            {img && (
              <img
                src={img.src}
                alt="Pick"
                onClick={handlePos(i)}
                id={`screenshot-${i}`}
                onLoad={onLoad}
              />
            )}
            {img && img.posXY ? (
              img.posXY.map((x, z) =>
                img.notes && img.notes[z] && x !== "" ? (
                  <div
                    className={cx({
                      "ba b--red bw1 b little-shadow": true,
                      "b--red red": i !== posI || z !== posN,
                      "b--blue blue": i === posI && z === posN,
                    })}
                    style={{
                      background: "rgba(255,255,255,0.5)",
                      width: "20px",
                      height: "20px",
                      position: "absolute",
                      top: x ? x.origY : 0,
                      left: x ? x.origX : 0,
                      fontSize: "11px",
                      borderRadius: "99px",
                      marginLeft: "-10px",
                      marginTop: "-10px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={handlePos(i, z, true)}
                    key={`notep-${x}-${z}`}
                  >
                    {z + 1}
                  </div>
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )}
          </div>
        )}
        <a
          href={img.src}
          className="f7 link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download image
        </a>
        <div className="tl">
          <p className="f5 pr2 b mv0">Replace screenshot</p>
          <Upload
            callback={onEditScreenshotImage(img.id)}
            user={user}
            hideTitle
          />
        </div>
      </div>
    </div>
  );
});
