import React, { Component } from "react";
import * as cx from "classnames";
import HelpIcon from "../icons/old/help_outlined.svg";
class InfoPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: false,
    };
  }
  hiding: false;
  showing: false;
  showHint = e => {
    e.preventDefault();
    this.setState({
      showing: true,
    });
  };
  hideHint = e => {
    console.info("hide");
    this.setState({
      showing: false,
    });
  };
  render() {
    return (
      <>
        {this.props.trigger ? (
          <span
            onMouseOver={this.showHint}
            onMouseOut={this.hideHint}
            className={cx({
              "hover-highlight pointer": true,
              "hover-hightlight-selected": this.state.showing,
            })}
          >
            {this.props.trigger}
          </span>
        ) : (
          <img
            src={HelpIcon}
            className="icon pointer"
            style={{ width: "10px", paddingBottom: "6px" }}
            onMouseOver={this.showHint}
            onMouseOut={this.hideHint}
            alt="Help"
          />
        )}
        <div
          onMouseOver={this.showHint}
          onMouseOut={this.hideHint}
          className={cx({
            "hint tl": true,
            dn: !this.state.showing,
          })}
        >
          {this.props.children}
        </div>
      </>
    );
  }
}

export default InfoPopup;
