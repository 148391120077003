import React, { Component, useState } from "react";
import request from "request";
import { Link } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
import * as cx from "classnames";
import * as querystring from "query-string";
import NoteDisplay from "./NoteDisplay";
import CollectionsInline from "./CollectionsInline";
import {
  baseApiUrl,
  getData,
  capitalize,
  copyTextToClipboard,
  getShareableURL,
  freeTrialImageUrl,
  randomBlur,
  randomHue,
  freeTrialImageStyles,
  getImage,
  COLLECTION_TYPES,
} from "../util";
import ChampIcon from "../icons/Champ.svg";
import ChampBig from "../icons/BigChamp.svg";
import LoserBig from "../icons/BigLoser.svg";
import ToggleOn from "../icons/ToggleOn.svg";
import ToggleOff from "../icons/ToggleOff.svg";
import LinkIcon from "../icons/url_blue.svg";
import { imageUrl, formatReportDate, tagLookup } from "../util";
import X from "../icons/red_x.svg";
import ZoomOut from "../icons/ZoomOut.svg";
import ZoomOutGray from "../icons/ZoomOut_gray.svg";
import DesktopIconOn from "../icons/desktop_white.svg";
import DesktopIconOff from "../icons/desktop_purple.svg";
import MobileIconOn from "../icons/mobile_white.svg";
import MobileIconOff from "../icons/mobile_purple.svg";
import BigArrowRL from "../icons/BigArrowRL.svg";
import Blur from "../icons/blur_ss.jpg";
import ArrowRight from "../icons/arrow_right.svg";
import ArrowLeft from "../icons/arrow_left.svg";
import ArrowWhite from "../icons/arrow_white.svg";
import WatchOffIcon from "../icons/watch_off.svg";
import WatchOnIcon from "../icons/watch_on.svg";
import ShareIcon from "../icons/share.svg";
import LockBg from "../icons/lock_bg.svg";
import EmptyImage from "../img/transparent.png";

import ShareTest from "./ShareTest";
import WatchUsersIcon from "../icons/watching_users.svg";
import AdminShareTest from "./AdminShareTest";
import FreeTrialActions from "./FreeTrialActions";
import UnlockTest from "./UnlockTest";

const zoomStylesMobile = [
  {
    maxHeight: "75vh",
    marginTop: "0px",
  },
  {
    maxWidth: "25vw",
    maxHeight: "130vh",
    marginTop: "0px",
  },
  {
    maxWidth: "25vw",
    marginTop: "0px",
  },
  {
    marginTop: "0px",
  },
];
const zoomStylesDesktop = [
  {
    maxHeight: "75vh",
  },
  {
    maxHeight: "140vh",
    marginTop: "0px",
  },
  {},
  {},
];

function LinkOrPopup(props) {
  const {
    isPopup,
    onClickPopup,
    onClickLink,
    to,
    renderAnchorOrLink = "link",
    ...childProps
  } = props;
  if (isPopup)
    return (
      <a href="#" onClick={e => onClickPopup(e)} {...childProps}>
        {props.children}
      </a>
    );
  else {
    if (renderAnchorOrLink === "anchor")
      return onClickLink ? (
        <a href={to} onClick={e => onClickLink(e)} {...childProps}>
          {props.children}
        </a>
      ) : (
        <a href={to} {...childProps}>
          {props.children}
        </a>
      );
    else {
      return onClickLink ? (
        <Link to={to} onClick={e => onClickLink(e)} {...childProps}>
          {props.children}
        </Link>
      ) : (
        <Link to={to} {...childProps}>
          {props.children}
        </Link>
      );
    }
  }
}
class CarouselNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      zoom: 2,
      currentImage: props.images[0],
      qs: this.buildQS(),
      zoomedOut: true,
      zoomedIn: false,
      sidebyside: true,
      newView: true,
      showHints: true,
      showInfo: false,
      initialNote: true,
      showNotes: false,
      doLoadedThing: true,
      didScroll: false,
      noteShow: "0",
      kwData: null,
      savedTests: this.props.savedTests || [],
      analyses: this.props.analyses || [],
      addedTests: this.props.addedTests || [],
      screenshots: this.props.screenshots,
      allLoaded: false,
      justLoaded: false,
      expanded: true,
      didExpand: true,
      collections: this.props.collections,
      watchTest: props.screenshots.is_watching,
      isWatchAPILoading: false,
      sharedScreenshot: this.props.sharedScreenshot || false,
      showFreeTrialPopup: false,
    };
  }
  buildQS = () => {
    const qs = querystring.parse(window.location.search);
    if (!qs.back) {
      qs.back = 2;
    } else {
      qs.back = Number(qs.back) + 1;
    }
    return `?${Object.keys(qs)
      .map(q => `${q}=${qs[q]}`)
      .join("&")}`;
  };
  offTimeout = null;
  imgLoaded = 0;
  collapseTimeout = null;
  collapse = e => {
    this.setState({ expanded: false });
    clearTimeout(this.collapseTimeout);
    this.collapseTimeout = setTimeout(() => {
      this.setState({ didCollapse: true });
    }, 70);
  };
  expand = e => {
    clearTimeout(this.collapseTimeout);
    this.setState({ expanded: true, didExpand: true, didCollapse: false });
  };
  onLoad = () => {
    this.imgLoaded++;

    if (this.imgLoaded >= this.state.screenshots.screenshots.length) {
      this.setState(
        {
          allLoaded: true,
        },
        () => {
          clearInterval(this.interval);
          if (this.state.doLoadedThing) {
            this.setState({ doLoadedThing: false });
          }
          setTimeout(() => {
            this.setState({ doLoadedThing: true });
            document.querySelectorAll("img.screenshot").forEach((el, i) => {
              el.classList.add("unloaded");
              setTimeout(() => {
                el.classList.remove("unloaded");
              }, 25);
            });
            setTimeout(() => {
              this.makeRects();
            }, 100);
          }, 125);
        }
      );
    }
  };
  zoom = zoomDirection => e => {
    let newZoom = 0;
    const { zoom } = this.state;
    if (zoomDirection === "out" && zoom > 0 && zoom !== 0) {
      newZoom = zoom - 1;
    } else if (zoomDirection === "in" && zoom < 3 && zoom !== 3) {
      newZoom = zoom + 1;
    }

    this.setState({
      zoom: newZoom,
    });

    if (newZoom === 3) {
      this.setState({
        zoomedOut: true,
      });
    } else {
      this.setState({
        zoomedOut: false,
      });
    }
    e.currentTarget.blur();
  };
  doOldView = e => {
    e.preventDefault();
    this.setState({ sidebyside: false, zoomedOut: false, zoomedIn: false });
  };
  next = e => {
    this.handleKeyEvent("right");
  };
  prev = e => {
    this.handleKeyEvent("left");
  };

  showInfo = e => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  handleNoteKeyEvent = (key, e) => {
    const { noteShow, screenshots } = this.state;
    const noteNum = parseInt(noteShow, 10) || 0;
    if (!screenshots.screenshots[0].notes) {
      return;
    }
    const newIndex = key === "left" ? noteNum - 1 : noteNum + 1;
    if (newIndex < 0) {
      this.setState(
        {
          noteShow: `${screenshots.screenshots[0].notes.length - 1}`,
        },
        () => {
          this.doNoteScroll(screenshots.screenshots[0].notes.length - 1);
        }
      );
    } else if (screenshots.screenshots[0].notes.length === newIndex) {
      this.setState(
        {
          noteShow: "0",
        },
        () => {
          this.doNoteScroll(0);
        }
      );
    } else {
      this.setState(
        {
          noteShow: `${newIndex}`,
        },
        () => {
          this.doNoteScroll(newIndex);
        }
      );
    }
  };

  handleKeyEvent = (key, e) => {
    const { index } = this.state;
    const { images } = this.props;
    const newIndex = key === "left" ? index - 1 : index + 1;
    if (newIndex < 0) {
      this.setState(
        {
          index: images.length - 1,
          currentImage: images[images.length - 1],
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
            this.scrollTo();
          }, 100);
        }
      );
    } else if (images.length === newIndex) {
      this.setState(
        {
          index: 0,
          currentImage: images[0],
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
            this.scrollTo();
          }, 100);
        }
      );
    } else {
      this.setState(
        {
          index: newIndex,
          currentImage: images[newIndex],
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
            this.scrollTo();
          }, 100);
        }
      );
    }
  };
  getHighestNote = (z = 0) => {
    let highest = 0;
    let highestPoint = 0;

    if (this.state.sidebyside) {
      this.state.screenshots.screenshots.forEach((x, i) => {
        if (x.posXY && x.posXY[z] && x.posXY[z].computedTop) {
          if (highestPoint === 0 || x.posXY[z].computedTop < highestPoint) {
            highest = i;
            highestPoint = x.posXY[z].computedTop;
          }
        }
      });
    } else {
      highest = this.state.index;
    }
    return highest;
  };
  doNoteScroll = (i = 0) => {
    const container = document.getElementById("ss-modal-scroller");
    if (container) {
      const x = this.getHighestNote(i);
      const elm = document.getElementById(`dot-${x}-${i}`);
      let scroll = document.getElementById("ss-modal-scroller");
      if (elm) {
        scroll.scrollTo(scroll.scrollLeft, elm.offsetTop);
      }
    } else {
      const elm = document.getElementById(`dot-${this.state.index}-${i}`);
      if (elm) {
        document.getElementById("ss-modal-scroller").scrollTo(0, elm.offsetTop);
      }
    }
  };
  doSideBySide = e => {
    e.preventDefault();

    if (this.state.sidebyside) {
      this.setState(
        {
          zoomedIn: false,
          zoomedOut: false,
          sidebyside: false,
        },
        () => {
          setTimeout(() => {
            this.makeRectsOneImg();
          }, 100);
        }
      );
    } else {
      this.setState(
        {
          zoomedIn: false,
          zoomedOut: true,
          sidebyside: true,
          // expanded: true,
          // didCollapse: false,
        },
        () => {
          if (this.state.allLoaded) {
            setTimeout(() => {
              this.makeRects();
            }, 200);
          }
        }
      );
    }
  };
  handlePickImage = i => e => {
    this.setState({
      index: i,
      currentImage: this.props.images[i],
    });
  };
  scrollTo = e => {
    const elm = document.querySelectorAll(".ss-selected")[0];
    let scroll = document.getElementById("ss-scroll");
    if (elm && scroll) {
      scroll.scrollTo(0, elm.offsetTop);
    }
  };
  zoomImage = i => e => {
    this.setState(
      {
        index: i,
        currentImage: this.props.images[i],
        zoomedIn: false,
        zoomedOut: true,
        sidebyside: false,
        zoom: 3,
        expanded: false,
        didCollapse: true,
      },
      () => {
        setTimeout(() => {
          this.makeRectsOneImg();
        }, 50);
      }
    );
  };
  visibleEvent = null;
  componentWillUnmount() {
    this.imgLoaded = 0;
    this.setState({ allLoaded: false });
    document.removeEventListener("visibilitychange", this.visibleEvent);
    clearTimeout(this.timeout);
    clearTimeout(this.timeout2);
    clearTimeout(this.timeout3);
    clearTimeout(this.timeout4);
    clearInterval(this.interval);
  }
  timeout: null;
  timeout2: null;
  timeout3: null;
  timeout4: null;
  visibleEvent = () => {
    if (!document.hidden && this.state.allLoaded) {
      this.setState({ doLoadedThing: false });
      this.timeout2 = setTimeout(() => {
        this.setState({ doLoadedThing: true });
        document.querySelectorAll("img.screenshot").forEach((el, i) => {
          el.classList.add("unloaded");

          setTimeout(() => {
            el.classList.remove("unloaded");
          }, 100 * i);
        });
        document.querySelectorAll("img.screenshot").forEach((el, i) => {
          setTimeout(() => {
            el.classList.remove("unloaded");
          }, 25);
        });
        setTimeout(() => {
          this.makeRects();
        }, 500);
      }, 150);
    }
  };
  componentDidMount() {
    if (!this.state.sharedScreenshot && this.props.url) {
      getData({
        url: `${baseApiUrl}/v1/sem/keywords?report=${this.props.reportId}&url=${this.props.url}`,
        headers: {
          Authorization: this.props.user.token,
        },
      }).then(({ err, res, data }) => {
        this.setState({
          kwData: data,
        });
      });
    }
    this.timeout = setTimeout(() => {
      const headline = document.getElementById("ss-header-text");
      const text = document.getElementById("headline-text");
      const headlineRect = headline.getBoundingClientRect();
      const textRect = text.getBoundingClientRect();
      if (headline) {
        if (headlineRect.width - 50 <= textRect.width) {
          document.getElementById("ss-header").classList.add("do-hover");
        }
      }
      this.makeRects();
    }, 200);
    document.addEventListener("visibilitychange", this.visibleEvent);
  }
  interval: null;
  getBaseNote = note => {
    if (note) {
      return note.split(":")[0].trim();
    }
    return null;
  };
  scrollTimeout: null;
  makeRectsOneImg = () => {
    const actualImg = document.getElementById("big-ss");
    const rect = actualImg.getBoundingClientRect();
    const { screenshots } = this.state;
    const { posXY } = screenshots.screenshots[this.state.index];
    if (posXY) {
      posXY.forEach((p, x) => {
        if (screenshots.screenshots[this.state.index].notes[x]) {
          if (!p) {
            return;
          }
          if (p.y === 0 && p.x === 0) {
            p.computedTop = 10;
            p.computedLeft = rect.width / 2;
          } else {
            p.computedTop = rect.height / p.y;
            p.computedLeft = rect.width / p.x;
          }
        }
      });
    }
    this.setState({ screenshots: screenshots });
  };
  makeRects = () => {
    const images = document.querySelectorAll(".xx-img");

    images.forEach((img, i) => {
      const actualImg = img.querySelector("img.screenshot");
      const rect = actualImg.getBoundingClientRect();
      let posXY;
      if (
        this.state.screenshots &&
        this.state.screenshots.screenshots &&
        this.state.screenshots.screenshots[i]
      ) {
        posXY = this.state.screenshots.screenshots[i].posXY;
      }
      if (posXY) {
        posXY.forEach((p, x) => {
          if (
            this.state.screenshots.screenshots[i].notes &&
            this.state.screenshots.screenshots[i].notes[x]
          ) {
            if (!p) {
              return;
            }
            if (p.y === 0 && p.x === 0) {
              p.computedTop = 10;
              p.computedLeft = rect.width / 2;
            } else {
              p.computedTop = rect.height / p.y;
              p.computedLeft = rect.width / p.x;
            }
          }
        });
      }
    });
    images.forEach((img, i) => {
      let posXY;
      if (
        this.state.screenshots &&
        this.state.screenshots.screenshots &&
        this.state.screenshots.screenshots[i]
      ) {
        posXY = this.state.screenshots.screenshots[i].posXY;
      }
      if (posXY) {
        const collapsedIndex = [];
        const skipCollapse = posXY.find(x => x && x.collapsed);
        posXY.forEach((p, x) => {
          if (!p) {
            return;
          }
          if (
            this.state.screenshots.screenshots[i].notes &&
            this.state.screenshots.screenshots[i].notes[x]
          ) {
            this.state.screenshots.screenshots[i].posXY.forEach((ss, y) => {
              if (x === y || !ss) {
                return;
              }
              const topAbs = Math.abs(p.computedTop - ss.computedTop);
              const leftAbs = Math.abs(p.computedLeft - ss.computedLeft);
              if (
                this.getBaseNote(
                  this.state.screenshots.screenshots[i].notes[x]
                ) !==
                this.getBaseNote(this.state.screenshots.screenshots[i].notes[y])
              ) {
                return;
              }
              if (collapsedIndex.includes(y)) {
                return;
              }
              if (topAbs <= 30 && leftAbs <= 30) {
                ss.index = y;
                if (!collapsedIndex.includes(ss.index) && !skipCollapse) {
                  posXY[y].isCollapsed = true;
                  collapsedIndex.push(x);
                  if (!p.collapsed) {
                    p.collapsed = [ss];
                  } else {
                    p.collapsed.push(ss);
                  }
                }
              }
            });
          }
        });
      }
    });

    this.setState(
      {
        screenshots: this.state.screenshots,
        doInitialNotes: true,
      },
      () => {
        if (!this.state.justLoaded) {
          this.timeout4 = setTimeout(this.doNoteScroll, 1000);
          this.timeout3 = setTimeout(this.stopInitialNotes, 8000);
          this.setState({ justLoaded: true });
        }
      }
    );
  };
  stopInitialNotes = () => {
    this.setState({ doInitialNotes: false });
  };
  showAndScrollNote = (i, x) => e => {
    this.setState({ noteShow: `${x}` }, () => {
      this.doNoteScroll(x);
    });
  };
  showNote = x => e => {
    if (this.state.showNotes) {
      return;
    }
    clearTimeout(this.offTimeout);
    this.setState({ noteShow: `${x}` });
  };
  imgHideNote = () => {
    if (!this.state.initialNote && this.state.allLoaded) {
      this.hideNote(null, true);
    }
  };
  hideNote = (e, initialNote) => {
    if (this.state.showNotes) {
      return;
    }
    if (!this.state.initialNote && this.state.allLoaded) {
      this.offTimeout = setTimeout(() => {
        if (this.state.noteShow) {
          if (initialNote) {
            this.setState({ noteShow: null, initialNote: false });
          } else {
            this.setState({ noteShow: null });
          }
        }
      }, 200);
    }
  };
  showHints = e => {
    this.setState({ showHints: true, showNotes: false });
  };
  hideHints = e => {
    this.setState({ showHints: false, showNotes: false });
  };
  showNotes = e => {
    this.setState({ showHints: true, showNotes: true });
  };
  toggleNotes = on => e => {
    if (on) {
      this.hideHints(e);
    } else {
      this.showHints(e);
    }
  };
  checkSelected = ind => {
    const i = Number(this.state.noteShow);
    let posXY;
    if (
      this.state.screenshots &&
      this.state.screenshots.screenshots &&
      this.state.screenshots.screenshots[0]
    ) {
      posXY = this.state.screenshots.screenshots[0].posXY;
    }
    if (posXY[i] && posXY[i].collapsed) {
      const indexes = posXY[i].collapsed.map(z => z.index);
      return indexes.includes(ind);
    } else {
      return posXY.find((pxy, z) => {
        let ret = false;
        if (!pxy || !pxy.collapsed || ind !== z) {
          return ret;
        }
        pxy.collapsed.forEach(c => {
          if (`${c.index}` === this.state.noteShow) {
            ret = true;
          }
        });

        return ret;
      });
    }
  };
  tagInTags = tag => {
    const i = parseInt(this.state.noteShow);
    let found = false;
    this.state.screenshots.screenshots.forEach(ss => {
      if (ss.tags && ss.tags[i] && ss.tags[i].includes(tag)) {
        found = true;
      }
      if (ss.posXY && ss.posXY[i] && ss.posXY[i].collapsed) {
        ss.posXY[i].collapsed.forEach(c => {
          if (ss.tags[c.index] && ss.tags[c.index].includes(tag)) {
            found = true;
          }
        });
      }
    });

    return found;
  };

  findNoteForTag = tag => e => {
    e.preventDefault();
    const i = parseInt(this.state.noteShow);
    let found = false;
    let noteIndex = null;
    this.state.screenshots.screenshots.forEach(ss => {
      if (!found) {
        if (ss.tags) {
          ss.tags.forEach((tags, tagIndex) => {
            if (!found) {
              if (tags.includes(tag)) {
                found = true;
                noteIndex = tagIndex;
              }
            }
          });
        }
      }
    });
    if (found && Number.isInteger(noteIndex)) {
      this.showAndScrollNote(0, noteIndex)(e);
    }
  };

  watchTestCallback = (test_id, watch_test) => {
    this.setState({
      isWatchAPILoading: true,
      watchTest: watch_test,
    });
    request.post(
      {
        url: `${baseApiUrl}/v1/user/watch_test`,
        headers: {
          Authorization: this.props.user.token,
        },
        form: {
          test_id,
          watch_test,
        },
      },
      err => {
        if (err) {
          alert("Something went wrong...");
          this.setState({
            isWatchAPILoading: false,
            watchTest: !watch_test,
          });
          return;
        }
        this.setState({
          isWatchAPILoading: false,
          // watchTest: watch_test,
        });
      }
    );
  };
  triggerFreeTrialPopup = e => {
    e.preventDefault();
    this.setState({ showFreeTrialPopup: true });
    setTimeout(() => {
      this.setState({ showFreeTrialPopup: false });
    }, 100);
  };
  triggerUnlockModal = e => {
    e.preventDefault();
    this.setState({ showUnlockModal: true });
    setTimeout(() => {
      this.setState({ showUnlockModal: false });
    }, 100);
  };
  displayI = 0;
  shortUrl =
    window.location.origin +
    window.location.pathname.replace(`/home/${this.props.company}`, `/link`) +
    (window.location.search ? window.location.search : "") +
    (window.location.hash || "");
  render() {
    this.displayI = 0;
    const {
      currentImage,
      zoom,
      zoomedOut,
      sidebyside,
      expanded,
      didExpand,
      didCollapse,
    } = this.state;
    const { images, screenshots, showLinkPopup, sharedScreenshot, company } =
      this.props;
    const { start_date, end_date } = screenshots;
    const isTestCalled =
      screenshots.status === "ended" &&
      screenshots.screenshots.find(s => s.champ === true) !== undefined;
    let lStyles =
      screenshots.type === "mobile"
        ? zoomStylesMobile[zoom]
        : zoomStylesDesktop[zoom];

    let allTests = [];
    if (this.state.addedTests && !this.state.sharedScreenshot) {
      allTests = this.state.addedTests;
    }
    const unlockedTests =
      this.props.customer &&
      this.props.customer.freeTrial &&
      this.props.customer.unlockedTests
        ? this.props.customer.unlockedTests.filter(
            unlockedTest => unlockedTest.unlockedBy !== "admin"
          ).length
        : null;

    const width =
      screenshots.type === "mobile" ? 375 : Math.ceil(window.innerWidth * 0.34);

    let baseUrl = `/home/${this.props.company}/`;
    const freeTrial = this.props.customer
      ? this.props.customer.freeTrial
      : false;
    return (
      <>
        <div
          className={cx({
            "ss-container-new": true,
            "ss-container-new-scrolled": this.props.didScroll,
          })}
        >
          <div
            className={cx({
              "ss-header": true,
              "ss-header-new": this.state.newView,
            })}
            id="ss-header"
          >
            <div className="ss-header-new-inner">
              <h1 className="ss-header-text" id="ss-header-text">
                <div className="headline-text">
                  <span id="headline-text">
                    {capitalize(screenshots.headline_tag)}
                  </span>
                </div>
                <div className="ss-header-text-after"></div>
              </h1>
              <div className="info-container">
                <div className="info-container-inner">
                  {freeTrial && !screenshots.testUnlocked ? (
                    <img
                      src={LinkIcon}
                      height="17"
                      alt="link"
                      className="link-icon pointer"
                      onClick={this.triggerFreeTrialPopup}
                    />
                  ) : (
                    <a
                      href={
                        screenshots.url
                          ? screenshots.url
                              .replace("#uk", "")
                              .replace("#au", "")
                          : "#"
                      }
                      title={`${
                        screenshots.url
                          ? screenshots.url
                              .replace("#uk", "")
                              .replace("#au", "")
                          : ""
                      }`}
                      className="ml3"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src={LinkIcon}
                        height="17"
                        alt="link"
                        className="link-icon"
                      />
                    </a>
                  )}
                </div>
              </div>
              <div className="company-box-mini">
                <LinkOrPopup
                  className="company"
                  to={`${baseUrl}report/?company=${encodeURIComponent(
                    screenshots.company_name
                  )}`}
                  onClickPopup={e => {
                    showLinkPopup();
                    e.preventDefault();
                  }}
                  isPopup={this.state.sharedScreenshot}
                  renderAnchorOrLink="anchor"
                >
                  {screenshots.company_name}
                  {screenshots.country && screenshots.country !== "us" ? (
                    <>&middot;{screenshots.country.toUpperCase()}</>
                  ) : (
                    ""
                  )}
                </LinkOrPopup>
              </div>
              <div className="toggle-mini">
                {screenshots.related_test &&
                screenshots.related_test !== "none" &&
                screenshots.related_test !== "later" ? (
                  <div className="platform-toggle-container">
                    {screenshots.type === "desktop" && (
                      <>
                        <a
                          href={
                            !this.state.sharedScreenshot
                              ? `${baseUrl}screenshots/${
                                  screenshots.related_test
                                }${this.state.qs ? this.state.qs : ""}`
                              : "#"
                          }
                          title="See Mobile Test"
                          className="platform-toggle off mobile"
                          onClick={e => {
                            if (this.state.sharedScreenshot) {
                              showLinkPopup();
                              e.preventDefault();
                            }
                          }}
                        >
                          <img src={MobileIconOff} alt="Mobile" />
                        </a>
                        <span className="platform-toggle on desktop">
                          <img src={DesktopIconOn} alt="desktop" />
                        </span>
                      </>
                    )}
                    {screenshots.type === "mobile" && (
                      <>
                        <span className="platform-toggle on mobile">
                          <img src={MobileIconOn} alt="Mobile" />
                        </span>
                        <a
                          href={
                            this.state.sharedScreenshot
                              ? "#"
                              : `${baseUrl}screenshots/${
                                  screenshots.related_test
                                }${this.state.qs ? this.state.qs : ""}`
                          }
                          title="See Desktop Test"
                          className="platform-toggle off desktop"
                          onClick={e => {
                            if (this.state.sharedScreenshot) {
                              showLinkPopup();
                              e.preventDefault();
                            }
                          }}
                          // isPopup={this.state.sharedScreenshot}
                        >
                          <img src={DesktopIconOff} alt="Desktop" />
                        </a>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="w-100 tc">
                    {screenshots.type === "desktop" && (
                      <span
                        className="platform-toggle solo desktop"
                        style={{ width: "auto" }}
                      >
                        <img src={DesktopIconOn} alt="desktop" />
                      </span>
                    )}
                    {screenshots.type === "mobile" && (
                      <span className="platform-toggle solo">
                        <img src={MobileIconOn} alt="Mobile" />
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="dates-box-mini">
                <div className="dates-box-mini-container">
                  {(start_date || end_date) && (
                    <>
                      {start_date && (
                        <div className="date-mini">
                          {formatReportDate(start_date)}
                        </div>
                      )}
                      {end_date ? (
                        <>
                          <img
                            src={BigArrowRL}
                            className="dates-arrow"
                            alt="arrow"
                          />
                          <div className="date-mini">
                            {formatReportDate(end_date)}
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={BigArrowRL}
                            className="dates-arrow"
                            alt="arrow"
                          />
                          <div className="date-mini tbd">TBD</div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <a
                onClick={e => {
                  if (this.state.sharedScreenshot) {
                    window.location.href = "https://www.dowhatworks.io/";
                    return;
                  }
                  const { back } = querystring.parse(window.location.search);
                  if (this.props.history.length > 1) {
                    e.preventDefault();
                    if (back) {
                      let backNum = Number(back) * -1;
                      this.props.history.go(backNum);
                    } else {
                      this.props.history.goBack();
                    }
                  }
                }}
                className="red-x-box pointer"
              >
                <img src={X} alt="Close" className="pointer" />
              </a>
            </div>
            {!expanded && (
              <>
                <div className="expand-box" onClick={this.expand}>
                  <img
                    src={ArrowLeft}
                    className="icon expand-icon"
                    alt="expand"
                  />
                </div>
                <div className="dn db-ns mb1 expand-toolbox">
                  <img
                    src={this.state.showHints ? ToggleOn : ToggleOff}
                    className="toggle-icon mt1"
                    alt="Toggle off"
                    onClick={this.toggleNotes(this.state.showHints)}
                  />

                  {!this.state.sidebyside && (
                    <div className="mv2">
                      <span
                        onClick={
                          !this.state.sidebyside ? this.doSideBySide : null
                        }
                        title="View side-by-side"
                        className={cx({
                          "zoomOut icon-circle ": true,
                          "icon-circle-gray": sidebyside,
                        })}
                      >
                        <img
                          src={ZoomOutGray}
                          width="32px"
                          alt="Side by side view"
                        />
                      </span>
                    </div>
                  )}
                  <div className="mt3 flex flex-column items-stretch content-center hints-container-sidebar">
                    {screenshots.screenshots[0] &&
                      screenshots.screenshots[0].posXY &&
                      screenshots.screenshots[0].posXY.map((x, i) => {
                        if (
                          !x ||
                          x.isCollapsed ||
                          x === "" ||
                          !screenshots.screenshots[0].notes[i]
                        ) {
                          return <></>;
                        }
                        this.displayI++;
                        return (
                          <span
                            className={cx({
                              "sNote-box": true,
                              selected:
                                (this.state.showHints &&
                                  this.state.noteShow === `${i}`) ||
                                this.checkSelected(i),
                              disabled: !this.state.showHints,
                            })}
                            onClick={this.showAndScrollNote(0, i)}
                            onMouseOver={this.showNote(i)}
                            key={`nb-${i}`}
                          >
                            {this.displayI}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
            <div
              className={cx({
                "ss-control-box f6": true,
                dn: didCollapse,
              })}
            >
              <div className="collapse-box-icon">
                <img
                  src={ArrowRight}
                  className="icon collapse-icon"
                  onClick={this.collapse}
                  alt="collapse"
                />
              </div>
              <div
                className={cx({
                  "ss-control-box-inner": true,
                  "ss-control-box-anim": !expanded,
                  "ss-control-box-anim-in": expanded && didExpand,
                })}
                style={{ paddingBottom: "60px" }}
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    className="collapse-box flex items-center"
                    style={{
                      justifyContent: "space-between",
                      minWidth: "80px",
                    }}
                  >
                    {!this.state.sidebyside && (
                      <span
                        onClick={
                          !this.state.sidebyside ? this.doSideBySide : null
                        }
                        title="View side-by-side"
                        className={cx({
                          "pointer icon-circle mr0": true,
                          "icon-circle-gray": sidebyside,
                        })}
                      >
                        <img
                          src={ZoomOutGray}
                          width="26px"
                          alt="Side by side view"
                        />
                      </span>
                    )}
                    {freeTrial ? (
                      <FreeTrialActions
                        hideCollections
                        showModal={this.state.showFreeTrialPopup}
                        testId={screenshots.id}
                        user={this.props.user}
                        company={this.props.company}
                        testUnlocked={
                          this.props.customer && this.props.customer.freeTrial
                            ? screenshots.testUnlocked
                            : true
                        }
                        unlockedTests={unlockedTests}
                        unlock_limit={
                          this.props.customer && this.props.customer.freeTrial
                            ? this.props.customer.unlock_limit
                            : null
                        }
                      />
                    ) : (
                      <>
                        {this.props.company === "admin" ? (
                          <AdminShareTest
                            testId={screenshots.id}
                            user={this.props.user}
                            company={this.props.company}
                            detailPage
                          />
                        ) : this.props.showShare ? (
                          <ShareTest
                            testId={screenshots.id}
                            user={this.props.user}
                            company={this.props.company}
                            detailPage
                          />
                        ) : null}
                        {this.state.sharedScreenshot && (
                          <a
                            href=" #"
                            onClick={e => {
                              e.preventDefault();
                              showLinkPopup();
                            }}
                          >
                            <img
                              src={ShareIcon}
                              style={{ marginTop: "-5px" }}
                            />
                          </a>
                        )}
                        <img
                          title={
                            this.state.watchTest
                              ? `Un-watch this test.`
                              : `Watch this test. We'll let you know when we call a winner.`
                          }
                          aria-label="watch"
                          src={
                            this.state.watchTest ? WatchOnIcon : WatchOffIcon
                          }
                          color="primary"
                          className={cx("pointer", "share-icon", {
                            "o-10": this.state.isWatchAPILoading,
                          })}
                          height="26"
                          width="26"
                          onClick={e => {
                            if (!this.state.sharedScreenshot) {
                              this.state.isWatchAPILoading
                                ? false
                                : this.watchTestCallback(
                                    screenshots.id,
                                    !screenshots.is_watching
                                  );
                            } else {
                              showLinkPopup();
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="mv3">
                  <div className={cx("sb pb2 bb b--black-05 pl2 f7 ttu")}>
                    Page type
                  </div>
                  <div className={cx("pa2")}>
                    {screenshots.page_type &&
                      screenshots.page_type.map((page_type, i) => (
                        <>
                          {i > 0 && ", "}
                          <LinkOrPopup
                            key={`pt-${i}`}
                            to={`${baseUrl}report/?page_type=${page_type}`}
                            className="dib"
                            onClickPopup={e => {
                              showLinkPopup();
                              e.preventDefault();
                            }}
                            onClickLink={e => {
                              window.scrollTo(0, 0);
                            }}
                            isPopup={this.state.sharedScreenshot}
                            renderAnchorOrLink="anchor"
                          >
                            {page_type}
                          </LinkOrPopup>
                        </>
                      ))}
                  </div>
                  {this.props.screenshots.no_of_tests &&
                  this.props.screenshots.no_of_tests > 2 ? (
                    <>
                      <div
                        className={cx("sb pb2 bb b--black-05 pl2 f7 ttu pt2")}
                      >
                        Tests on this page
                      </div>
                      <div className={cx("pa2")}>
                        {freeTrial && !screenshots.testUnlocked ? (
                          <p
                            className="pointer link blue pa0 pb0"
                            onClick={this.triggerFreeTrialPopup}
                          >
                            {this.props.screenshots.no_of_tests} tests
                          </p>
                        ) : (
                          <LinkOrPopup
                            key={`moretests`}
                            to={`${baseUrl}report/?url=${encodeURIComponent(
                              this.props.url
                            )}`}
                            className="dib"
                            onClickPopup={e => {
                              showLinkPopup();
                              e.preventDefault();
                            }}
                            onClickLink={e => {
                              window.scrollTo(0, 0);
                            }}
                            isPopup={this.state.sharedScreenshot}
                            renderAnchorOrLink="anchor"
                          >
                            {this.props.screenshots.no_of_tests} tests
                          </LinkOrPopup>
                        )}
                      </div>
                    </>
                  ) : null}
                  {this.props.customer &&
                    this.props.customer.freeTrial &&
                    !screenshots.testUnlocked && (
                      <div className="pb3 tc">
                        <UnlockTest
                          testId={screenshots.id}
                          user={this.props.user}
                          company={company}
                          testUnlocked={screenshots.testUnlocked}
                          unlockedTests={unlockedTests}
                          showModal={this.state.showUnlockModal}
                          unlockedBy={
                            this.props.customer &&
                            this.props.customer.allUnlockedTests &&
                            this.props.customer.allUnlockedTests.find(
                              t => t.test_id === d.id
                            )
                          }
                          unlock_limit={
                            this.props.customer &&
                            this.props.customer.unlock_limit
                              ? this.props.customer.unlock_limit
                              : 0
                          }
                        />
                      </div>
                    )}
                  <div className="notes-box">
                    <div className="sb pb2 bb b--black-05 pl2 f7 ttu">
                      What's tested
                      <img
                        src={this.state.showHints ? ToggleOn : ToggleOff}
                        className="toggle-icon"
                        alt={`Toggle ${this.state.showHints ? "off" : "on"}`}
                        onClick={this.toggleNotes(this.state.showHints)}
                      />
                    </div>
                    <div className="pl2 note-container tl">
                      {screenshots.screenshots[0] &&
                        screenshots.screenshots[0].posXY &&
                        screenshots.screenshots[0].posXY.map((x, i) => {
                          if (
                            !x ||
                            x.isCollapsed ||
                            x === "" ||
                            !screenshots.screenshots[0].notes[i]
                          ) {
                            return <></>;
                          }
                          this.displayI++;
                          return (
                            <span
                              className={cx({
                                "sNote-box": true,
                                selected:
                                  (this.state.showHints &&
                                    this.state.noteShow === `${i}`) ||
                                  this.checkSelected(i),
                                disabled: !this.state.showHints,
                              })}
                              onClick={this.showAndScrollNote(0, i)}
                              onMouseOver={this.showNote(i)}
                              key={`nb-${i}`}
                            >
                              {this.displayI}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="mb3">
                  <div className="sb pb2 bb b--black-05 pl2 f7 ttu">
                    Explore related tests{" "}
                  </div>
                  <div className="pa2 tl">
                    {screenshots.tags.map((tag, y) => (
                      <LinkOrPopup
                        to={`${baseUrl}report/?tag=${encodeURIComponent(
                          tag
                        )}&sort=relevance&from=detail`}
                        className={
                          this.tagInTags(tag) &&
                          (!freeTrial ||
                            (freeTrial && screenshots.testUnlocked))
                            ? "tag-highlight tag-highlight-on"
                            : "tag-highlight"
                        }
                        isPopup={this.state.sharedScreenshot}
                        onClickPopup={e => {
                          showLinkPopup();
                          e.preventDefault();
                        }}
                        onClickLink={e => {
                          window.scrollTo(0, 0);
                        }}
                        key={`tag-${tag}-${y}`}
                        renderAnchorOrLink="anchor"
                        onMouseOver={this.findNoteForTag(tag)}
                      >
                        <span className="tag-highlight-inner">
                          {tagLookup[tag] || tag}
                        </span>
                      </LinkOrPopup>
                    ))}
                  </div>
                </div>
                {!this.state.sharedScreenshot && (
                  <CollectionsInline
                    type={COLLECTION_TYPES.collection}
                    detailView
                    savedTests={this.state.savedTests}
                    urlCompany={this.props.company}
                    uid={this.props.user.uid}
                    d={{
                      savedTests: allTests,
                      id: screenshots.id,
                    }}
                    itemId={this.props.id}
                    token={this.props.user.token}
                    savedTestsCallback={this.props.savedTestsCallback}
                    collections={this.props.collections.filter(collection => {
                      return collection.analysis_props
                        ? collection.analysis_props.length === 0
                        : true;
                    })}
                    challenges={screenshots.challenge_v2 || []}
                    company={this.props.company}
                    freeTrial={freeTrial}
                    triggerFreeTrialPopup={this.triggerFreeTrialPopup}
                  />
                )}
                {this.props.company === "admin" && (
                  <CollectionsInline
                    type={COLLECTION_TYPES.analysis}
                    detailView
                    savedTests={this.state.analyses}
                    urlCompany={this.props.company}
                    uid={this.props.user.uid}
                    d={{
                      savedTests: allTests,
                      id: screenshots.id,
                    }}
                    itemId={this.props.id}
                    token={this.props.user.token}
                    savedTestsCallback={this.props.savedTestsCallback}
                    collections={this.props.collections.filter(collection => {
                      return (
                        collection.analysis_props &&
                        collection.analysis_props.length > 0
                      );
                    })}
                    challenges={screenshots.challenge_v2 || []}
                    company={this.props.company}
                    freeTrial={freeTrial}
                    triggerFreeTrialPopup={this.triggerFreeTrialPopup}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx({
            "flex flex-wrap": true,
            "ss-box-long": this.state.newView,
          })}
        >
          <div
            className={cx({
              "pa3 bg-dww-dark-gray": true,
              "w-100-s w-65": !zoomedOut,
              "initial-load": this.state.doInitialNotes,
            })}
            style={expanded ? { width: "77.5%" } : { width: "100%" }}
            id={sidebyside ? "ss-container" : ""}
          >
            {sidebyside && (
              <div
                className={cx({
                  "overflow-auto nowrap tc": true,
                  loaded: this.state.doLoadedThing,
                  unloaded: !this.state.doLoadedThing,
                })}
                id="sxs-scroll"
                style={{
                  minHeight: "70vh",
                  marginTop: this.state.newView ? "0" : "-41px",
                  padding: this.state.newView ? "0px 16px 64px 16px" : "",
                }}
              >
                <div className="ss-img-container">
                  {images.map((img, i) => {
                    return (
                      <div
                        className={cx({
                          ml4: i !== 0,
                          "dib tc ss-img-wrapper": true,
                          mr4: images.length > 2 && i === images.length - 1,
                        })}
                        style={{
                          width: `${width}px`,
                          opacity: this.state.doLoadedThing ? 1 : 0.1,
                          position: "relative",
                        }}
                        key={img.src}
                      >
                        <div className="screenshotItem-notesHeaderContainer">
                          <div
                            className={cx({
                              "mb1 screenshotItem-notesHeader": true,
                              "screenshotItem-notesHeader-green": img.champ,
                            })}
                          >
                            <span>
                              {img.control
                                ? "Control"
                                : `Variant ${img.varNum}`}
                              {!isTestCalled && <> &mdash; {img.weight}</>}
                            </span>
                          </div>
                        </div>
                        <div className="xx-img relative">
                          {freeTrial && !screenshots.testUnlocked ? (
                            <div
                              className="ss-background"
                              style={{
                                backgroundImage: freeTrial
                                  ? null
                                  : `url(${Blur})`,
                                backgroundSize: "contain",
                                width: width,
                                height: img.posXY
                                  ? `${width * img.posXY[0].hR}px`
                                  : "auto",
                              }}
                            >
                              <div
                                className="pointer"
                                style={{
                                  backgroundColor: "rgba(0,0,0,0.1)",
                                  backgroundImage: `url(${LockBg})`,
                                  backgroundSize: "100% 300px",
                                  height: img.posXY
                                    ? `${width * img.posXY[0].hR}px`
                                    : "auto",
                                  width: `${width}px`,
                                  position: "absolute",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                onClick={this.triggerUnlockModal}
                                title={"Unlock this test"}
                              ></div>
                              <img
                                className="v-top pointer screenshot"
                                alt={`Screenshot ${i}`}
                                onLoad={this.onLoad}
                                onClick={this.triggerUnlockModal}
                                onMouseOver={this.imgHideNote}
                                src={img.src}
                                style={{
                                  height: img.posXY
                                    ? `${width * img.posXY[0].hR}px`
                                    : "auto",
                                  minWidth: width,
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className="ss-background"
                              style={{
                                backgroundImage: `url(${Blur})`,
                                backgroundSize: "contain",
                                width: width,
                                height: img.posXY
                                  ? `${width * img.posXY[0].hR}px`
                                  : "auto",
                              }}
                            >
                              <img
                                className="v-top zoomIn screenshot"
                                alt={`Screenshot ${i}`}
                                onLoad={this.onLoad}
                                onClick={this.zoomImage(i)}
                                onMouseOver={this.imgHideNote}
                                src={imageUrl(img.src, 575, 0, 2)}
                                style={{
                                  height: img.posXY
                                    ? `${width * img.posXY[0].hR}px`
                                    : "auto",
                                }}
                              />
                            </div>
                          )}
                          {this.state.showHints &&
                            img.posXY &&
                            img.posXY.map((p, x) => {
                              if (!p) {
                                return <></>;
                              }
                              let left = 0;
                              let onLeft = false;
                              let onRight = false;
                              const doMove =
                                (screenshots.type === "desktop" &&
                                  images.length >= 3) ||
                                (screenshots.type === "mobile" &&
                                  images.length >= 4);

                              if (p.computedLeft) {
                                if (
                                  doMove &&
                                  i === 0 &&
                                  p.computedLeft - 115 < 0
                                ) {
                                  left = p.computedLeft - 28;
                                  onLeft = true;
                                } else if (
                                  doMove &&
                                  i === images.length - 1 &&
                                  p.computedLeft - 115 > 125
                                ) {
                                  left = Math.min(p.computedLeft - 115, 105);
                                  onRight = true;
                                  left = p.computedLeft - 172;
                                } else {
                                  left = p.computedLeft - 100;
                                }
                              }

                              return img.notes &&
                                img.notes[x] &&
                                !p.isCollapsed ? (
                                <div
                                  className="dww-dot-container"
                                  key={`nc-${i}-${x}`}
                                >
                                  <div
                                    className={cx({
                                      "f7 dww-dot": true,
                                    })}
                                    style={{
                                      top: p.computedTop,
                                      left: p.computedLeft,
                                    }}
                                    id={`dot-${i}-${x}`}
                                    onMouseOver={this.showNote(x)}
                                    onMouseOut={this.hideNote}
                                  ></div>
                                  <div
                                    className="f7 dww-dot-anim"
                                    style={{
                                      top: p.computedTop,
                                      left: p.computedLeft,
                                    }}
                                  ></div>
                                  <div
                                    className={cx({
                                      "note-hover": true,
                                      "on-left": onLeft,
                                      "on-right": onRight,
                                      isChamp: img.champ,
                                      dn: this.state.noteShow !== `${x}`,
                                      flex:
                                        this.state.showNotes ||
                                        this.state.noteShow === `${x}` ||
                                        img.posXY.find((pxy, z) => {
                                          let ret = false;
                                          if (
                                            !pxy ||
                                            !pxy.collapsed ||
                                            x !== z
                                          ) {
                                            return ret;
                                          }
                                          pxy.collapsed.forEach(c => {
                                            if (
                                              `${c.index}` ===
                                              this.state.noteShow
                                            ) {
                                              ret = true;
                                            }
                                          });

                                          return ret;
                                        }),
                                    })}
                                    style={{
                                      top:
                                        p && p.computedTop
                                          ? p.computedTop - 15
                                          : 0,
                                      left,
                                    }}
                                    onMouseOver={this.showNote(x)}
                                    onMouseOut={this.hideNote}
                                  >
                                    {screenshots.hasChamp && (
                                      <>
                                        {img.champ ? (
                                          <img
                                            src={ChampBig}
                                            alt="champ"
                                            className="floating-champ"
                                          />
                                        ) : (
                                          <img
                                            src={LoserBig}
                                            alt="loser"
                                            className="floating-champ"
                                          />
                                        )}
                                      </>
                                    )}
                                    <div
                                      className={cx({
                                        "note-box": true,
                                        "note-box-champ": screenshots.hasChamp,
                                      })}
                                      onClick={
                                        freeTrial && !screenshots.testUnlocked
                                          ? this.triggerUnlockModal
                                          : () => {}
                                      }
                                      title={
                                        freeTrial && !screenshots.testUnlocked
                                          ? `Unlock test`
                                          : ""
                                      }
                                    >
                                      <NoteDisplay
                                        key={`${i}-${x}`}
                                        text={img.notes[x]}
                                        isTiny
                                        freeTrial={
                                          freeTrial && !screenshots.testUnlocked
                                        }
                                      />
                                      {p.collapsed &&
                                        p.collapsed.map(pc => (
                                          <div className="note-box-divider">
                                            <NoteDisplay
                                              key={`${i}-${pc.index}`}
                                              text={img.notes[pc.index]}
                                              isTiny
                                              freeTrial={
                                                freeTrial &&
                                                !screenshots.testUnlocked
                                              }
                                            />
                                          </div>
                                        ))}
                                    </div>
                                    <div className="note-triangle"></div>
                                    <div className="note-triangle-outer"></div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!sidebyside && (
              <>
                <div className="w-100 tc carousel" onClick={this.next}>
                  <div className="db mv1 center b">
                    {!isTestCalled && (
                      <span className="b">{currentImage.weight} - </span>
                    )}
                    {currentImage.control ? (
                      <>Control</>
                    ) : (
                      <>Variant {currentImage.varNum}</>
                    )}
                    {currentImage.champ && (
                      <span>
                        <img
                          src={ChampIcon}
                          alt="Champ"
                          className="ml2"
                          style={{ width: "20px", margin: "-3px 0 0 8px" }}
                        />
                      </span>
                    )}{" "}
                  </div>
                  <div className="relative dib" id="big-ss-container">
                    <img
                      src={
                        freeTrial && !screenshots.testUnlocked
                          ? freeTrialImageUrl(
                              currentImage.src,
                              screenshots.type,
                              575,
                              0,
                              2
                            )
                          : imageUrl(currentImage.src, 575, 0, 2)
                      }
                      className="w-100-s next screenshot"
                      title="Click for next variant"
                      style={
                        freeTrial && !screenshots.testUnlocked
                          ? {
                              ...lStyles,
                              ...freeTrialImageStyles(currentImage.src),
                            }
                          : { lStyles }
                      }
                      alt={`Screenshot ${this.state.index}`}
                      id="big-ss"
                    />
                    <>
                      {this.state.showHints &&
                        currentImage.posXY &&
                        currentImage.posXY.map((p, x) => {
                          if (!p) {
                            return <></>;
                          }
                          let left;
                          let onLeft = false;
                          let onRight = false;
                          const doMove =
                            (screenshots.type === "desktop" &&
                              images.length >= 3) ||
                            (screenshots.type === "mobile" &&
                              images.length >= 4);

                          if (
                            doMove &&
                            this.state.index === 0 &&
                            p.computedLeft - 115 < 0
                          ) {
                            left = p.computedLeft - 28;
                            onLeft = true;
                          } else if (
                            doMove &&
                            this.state.index === images.length - 1 &&
                            p.computedLeft - 115 > 125
                          ) {
                            left = Math.min(p.computedLeft - 115, 105);
                            onRight = true;
                            left = p.computedLeft - 172;
                          } else {
                            left = p.computedLeft - 100;
                          }

                          return currentImage.notes[x] && !p.isCollapsed ? (
                            <div className="dww-dot-container">
                              <div
                                className={cx({
                                  "f7 dww-dot": true,
                                })}
                                style={{
                                  top: p.computedTop,
                                  left: p.computedLeft,
                                }}
                                id={`dot-${this.state.index}-${x}`}
                                onMouseOver={this.showNote(x)}
                                onMouseOut={this.hideNote}
                              ></div>
                              <div
                                className="f7 dww-dot-anim"
                                style={{
                                  top: p.computedTop,
                                  left: p.computedLeft,
                                }}
                              ></div>
                              <div
                                className={cx({
                                  "note-hover": true,
                                  "on-left": onLeft,
                                  "on-right": onRight,
                                  dn: this.state.noteShow !== `${x}`,
                                  flex:
                                    this.state.showNotes ||
                                    this.state.noteShow === `${x}` ||
                                    currentImage.posXY.find((pxy, z) => {
                                      let ret = false;
                                      if (!pxy || !pxy.collapsed || x !== z) {
                                        return ret;
                                      }
                                      pxy.collapsed.forEach(c => {
                                        if (
                                          `${c.index}` === this.state.noteShow
                                        ) {
                                          ret = true;
                                        }
                                      });

                                      return ret;
                                    }),
                                })}
                                style={{
                                  top: p.computedTop - 15,
                                  left,
                                }}
                                onMouseOver={this.showNote(x)}
                                onMouseOut={this.hideNote}
                              >
                                <div className="note-box">
                                  <NoteDisplay
                                    key={`${this.state.index}-${x}`}
                                    text={currentImage.notes[x]}
                                    isTiny
                                    freeTrial={
                                      freeTrial && !screenshots.testUnlocked
                                    }
                                  />
                                  {p.collapsed &&
                                    p.collapsed.map(pc => (
                                      <div
                                        className="note-box-divider"
                                        key={`${this.state.index}-${pc.index}`}
                                      >
                                        <NoteDisplay
                                          text={currentImage.notes[pc.index]}
                                          isTiny
                                          freeTrial={
                                            freeTrial &&
                                            !screenshots.testUnlocked
                                          }
                                        />
                                      </div>
                                    ))}
                                  <Link
                                    className="note-button"
                                    to={`${baseUrl}report/?tag=${currentImage.notes[
                                      x
                                    ]
                                      .split(":")[0]
                                      .trim()}&sort=relevance&from=detail`}
                                  >
                                    See all{" "}
                                    {currentImage.notes[x].split(":")[0].trim()}{" "}
                                    tests
                                  </Link>
                                  {screenshots.hasChamp && (
                                    <>
                                      {currentImage.champ ? (
                                        <img
                                          src={ChampBig}
                                          alt="champ"
                                          className="floating-champ"
                                        />
                                      ) : (
                                        <img
                                          src={LoserBig}
                                          alt="loser"
                                          className="floating-champ"
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="note-triangle"></div>
                                <div className="note-triangle-outer"></div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          );
                        })}
                    </>
                  </div>
                </div>
                <KeyboardEventHandler
                  handleKeys={["left", "right"]}
                  onKeyEvent={this.handleKeyEvent}
                />
              </>
            )}
            {!this.state.sharedScreenshot &&
              this.props.user.company === "admin" && (
                <div className="edit-box f8 ttu b ph4">
                  <Link
                    to={`/admin/screenshots/edit/${screenshots.id}?back=${window.location.href}`}
                  >
                    Edit
                  </Link>
                  {screenshots.watch_users_count > 0 && (
                    <p className="ttc">
                      <img
                        src={WatchUsersIcon}
                        height="22"
                        aria-label="watching users count"
                        className="pr1"
                      />
                      {screenshots.watch_users_count > 1
                        ? `${screenshots.watch_users_count} users are `
                        : `a user is `}
                      watching this test
                    </p>
                  )}
                </div>
              )}
          </div>
          {sidebyside && (
            <KeyboardEventHandler
              handleKeys={["left", "right"]}
              onKeyEvent={this.handleNoteKeyEvent}
            />
          )}
        </div>
        {/* {this.state.showFreeTrialPopup && (
          <FreeTrialPopup
            hidePopup={() => this.setState({ showFreeTrialPopup: false })}
          />
        )} */}
      </>
    );
  }
}

export default CarouselNew;
