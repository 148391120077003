import React, { Component } from "react";
import request from "request";
import { baseApiUrl } from "../util";

import CreatableSelect from "react-select/creatable";

class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: null,
    };
  }
  handleChange = (newValue, actionMeta) => {
    if (!newValue) {
      this.setState({
        options: [],
      });
      return;
    }
    if (this.props.singular) {
      this.props.callback(newValue);
    } else {
      this.props.callback(newValue.map(tag => tag.value));
    }
    this.setState({
      options: newValue,
    });
    if (actionMeta.action === "create-option") {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/tags`,
          form: {
            headline: this.props.note
              ? "note"
              : this.props.headline
              ? true
              : false,
            tag: this.props.singular
              ? newValue.value
              : newValue[newValue.length - 1].value,
          },
        },
        err => {
          if (err) {
            return;
          }
          console.log(`Added..`);
          if (this.props.singular) {
            console.info("callback1!");
            this.props.callback(newValue);
          } else {
            console.info("callback2!");
            this.props.callback(newValue.map(tag => tag.value));
          }
          return;
        }
      );
    }
  };
  render() {
    return (
      <CreatableSelect
        isMulti={this.props.singular ? false : true}
        onChange={this.handleChange}
        options={this.props.options}
        value={this.state.options || this.props.defaultTags}
        placeholder={this.props.placeholder}
        initialValue={this.props.defaultTags}
        className={`basic-multi-select w-80 w-50-l ${this.props.className}`}
      />
    );
  }
}

export default MultiSelect;
