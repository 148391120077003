import React, { Component } from "react";
import { baseApiUrl } from "../../util";
import request from "request";

const MAX_IMAGE_SIZE = 4000000;

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: null,
      error: null,
    };
  }
  onUpload = (image, name) => {
    request(
      {
        url: `${baseApiUrl}/upload?name=${encodeURIComponent(name)}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      async (err, resp, data) => {
        const response = JSON.parse(data);
        let binary = atob(image.split(",")[1]);
        let array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let blobData = new Blob([new Uint8Array(array)], {
          type: "image/jpeg",
        });
        await fetch(response.uploadURL, {
          method: "PUT",
          body: blobData,
        });
        this.fileCount++;
        this.files.push(response.uploadURL.split("?")[0]);
        if (this.fileCount === this.numFiles) {
          this.props.callback(this.files);
          this.files = [];
          this.fileCount = 0;
          this.setState({
            loading: false,
            url: null,
            error: null,
          });
        }
      }
    );
  };
  fileCount = 0;
  numFiles = 0;
  files = [];
  createImage = file => {
    let reader = new FileReader();
    reader.onload = e => {
      if (
        !e.target.result.includes("data:image/jpeg") &&
        !e.target.result.includes("data:image/png")
      ) {
        this.setState({ loading: false });
        return alert("Wrong file type - JPG and PNG only.");
      }
      if (atob(e.target.result.split(",")[1]).length > MAX_IMAGE_SIZE) {
        this.setState({ loading: false });
        return alert("Image is loo large - 4Mb maximum");
      }
      this.onUpload(e.target.result, file.name);
    };
    reader.readAsDataURL(file);
  };
  onFileChange = e => {
    this.setState({
      loading: true,
    });
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.numFiles = files.length;
    for (let f in files) {
      if (typeof files[f] === "object") {
        this.createImage(files[f]);
      }
    }
  };
  render() {
    const { loading } = this.state;
    const { className } = this.props;
    return (
      <div className={"pv2 pb4 " + className}>
        {!this.props.hideTitle && (
          <p>
            <b>Upload a new screenshot</b>
          </p>
        )}
        <p>
          {loading && <span>Uploading&hellip;</span>}
          {!loading && (
            <input
              type="file"
              name="upload"
              onChange={this.onFileChange}
              multiple={this.props.multiple}
            />
          )}
        </p>
      </div>
    );
  }
}

export default Upload;
