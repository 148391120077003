import React, { Component } from "react";
import { baseApiUrl } from "../../util";
import request from "request";
class EmailPrefs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      saving: false,
      ab_email: this.props.user.prefs ? this.props.user.prefs.ab_email : true,
      sem_email: this.props.user.prefs
        ? this.props.user.prefs.sem_email
        : false,
    };
  }
  handleSubmit = e => {
    this.setState({ saving: true });
    e.preventDefault();
    const { ab_email, sem_email } = this.state;

    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/user/prefs`,
        form: {
          prefs: JSON.stringify({ ab_email, sem_email }),
        },
      },
      (err, data) => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          return;
        }
        if (!err) {
          const json = JSON.parse(data.body);
          this.setState({
            saving: false,
            saved: true,
            ab_email: json.user.email_prefs.ab_email,
            sem_email: json.user.email_prefs.sem_email,
          });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 1500);
        }
      }
    );
  };
  handleChange = e => {
    const { name } = e.target;
    this.setState({
      [name]: e.target.checked,
    });
  };
  render() {
    const { error, saving, saved, ab_email, sem_email } = this.state;
    return (
      <main className="pv4">
        <div
          className="box center br2 bg-white tl"
          style={{ position: "relative" }}
        >
          <div className="bb b--black-05 ph3 pt3 mb3 pb2">
            <h1>Email Preferences</h1>
          </div>
          <div className="ph3 pb3 pt2">
            {error && (
              <p className="dark-red">
                Whoops, looks like something went wrong. Please try again.
              </p>
            )}
            <form onSubmit={this.handleSubmit} className="w-100">
              <div className="mt3">
                <label>
                  <input
                    type="checkbox"
                    onChange={this.handleChange}
                    name="ab_email"
                    checked={ab_email}
                    className="mr2"
                  />
                  Web Test Alert
                </label>
              </div>
              <div className="mt3">
                <label>
                  <input
                    type="checkbox"
                    onChange={this.handleChange}
                    name="sem_email"
                    className="mr2"
                    checked={sem_email}
                  />
                  SEM Winners & Losers Report (priority keywords)
                </label>
              </div>
              <div className="mt4">
                <input
                  type="submit"
                  value="Update"
                  className="ui-button primary"
                  disabled={saving}
                />
                {saving && (
                  <span className="ml2 b dww-gray">Saving&hellip;</span>
                )}
                {saved && <span className="ml2 b green">Saved.</span>}
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
}

export default EmailPrefs;
