import React from "react";
import request from "request";
import { fbAuth, baseApiUrl, setRefreshTime, getRefreshTime } from "./util";
import "./App.css";
import * as cx from "classnames";
import Index from "./pages/Index";
import Jobs from "./pages/careers/Index";
import Admin from "./pages/admin/Index";
import Subscribe from "./pages/subscribe/NewSubscribe";
import Game from "./pages/Game";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Success from "./pages/subscribe/Success";
import Login from "./pages/Login";
import SSO from "./pages/account/SSO";
import SSOCallback from "./pages/account/SSOCallback";
import Reset from "./pages/account/Reset";
import EmailPrefs from "./pages/account/EmailPrefs";
import Users from "./pages/account/Users";
import User from "./pages/account/User";
import Password from "./pages/account/Password";
import Home from "./pages/home/Index";
import Invite from "./pages/invite/Index";
import PageWrapper from "./components/PageWrapper";
import Error from "./components/Error";
import UpdateCC from "./pages/account/UpdateCC";
import Header from "./components/Header";
import ArrowWhite from "./icons/arrow_white.svg";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import ViewSharedScreenshot from "./pages/home/ViewSharedScreenshot";
import RequestAccess from "./pages/RequestAccess";
import SlackCallback from "./pages/account/SlackCallback";
import Screeners from "./pages/account/Screeners";

const USER_ACTIVITY_INTERVAL = 10 * 60 * 1000;
const USER_AUTH_INTERVAL = 900000;
class App extends React.Component {
  state = {
    loading: true,
    user: null,
    authed: false,
    logoutRedir: false,
    isHome: window.location.pathname === "/" || window.location.pathname === "",
    isCustomerHome:
      window.location.pathname.indexOf("/home/") !== -1 ||
      window.location.pathname.startsWith("/admin/screenshots/"),
    awsError: false,
  };
  time = new Date().getTime();
  visibleEvent = () => {
    if (!document.hidden && this.state.user) {
      const newTime = new Date().getTime();
      clearInterval(this.refresh);
      this.refresh = setInterval(() => {
        if (this.state.user) {
          this.getUserData(this.state.user, true);
        }
      }, USER_AUTH_INTERVAL);
      if (newTime - this.time >= USER_AUTH_INTERVAL) {
        this.getUserData(this.state.user, true);
        this.time = newTime;
      }
      clearInterval(this.trackUser);
      this.trackUser = setInterval(() => {
        this.trackUserActivity(this.state.user, true);
      }, USER_ACTIVITY_INTERVAL);
      if (newTime - this.time >= USER_ACTIVITY_INTERVAL) {
        this.trackUserActivity(this.state.user, true);
        this.time = newTime;
      }
    }
  };
  componentDidMount() {
    setRefreshTime();
    this.removeListener = fbAuth.onAuthStateChanged(async user => {
      if (user) {
        await this.getUserData(user);
      } else {
        this.setState({
          loading: false,
          authed: false,
          user: null,
        });
      }
    });
    document.addEventListener("visibilitychange", this.visibleEvent);
    this.refresh = setInterval(() => {
      if (this.state.user) {
        this.getUserData(this.state.user, true);
      }
    }, 1800000);
    this.trackUser = setInterval(() => {
      if (this.state.user) {
        this.trackUserActivity(this.state.user, true);
      }
    }, USER_ACTIVITY_INTERVAL);

    window.onerror = function (msg, b, c, v, error) {
      if (!this.error) {
        this.error = true;

        clearInterval(this.refresh);
        clearInterval(this.trackUser);
        if (!document.hidden) {
          request.post(
            {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
              url: `${baseApiUrl}/v1/error`,
              form: {
                error: `${msg} - ${b} - ${c}`,
                url: window.location.href,
                user: this.state.user ? this.state.user.id : null,
                browser: window.navigator.userAgent,
              },
            },
            (err, resp) => {}
          );
        }
      }
    };
  }
  error: false;
  componentWillUnmount() {
    this.removeListener();
    document.removeEventListener("visibilitychange", this.visibleEvent);
    clearInterval(this.refresh);
    clearInterval(this.trackUser);
  }
  handleLogout = e => {
    fbAuth.signOut();
    window.location.replace("/");
  };
  udTimes = 0;
  getUserData = async (user, onRefresh) => {
    const token = await user.getIdToken(true);
    const lastRefresh = getRefreshTime();
    const lastRefreshStr = lastRefresh ? `?lastRefresh=${lastRefresh}` : "";
    request(
      {
        url: `${baseApiUrl}/v1/user${lastRefreshStr}`,
        headers: {
          Authorization: token,
        },
      },
      (err, res, data) => {
        if (err || res.errorCode >= 500 || res.statusCode >= 400) {
          if (err) {
            this.setState({
              loading: false,
              authed: false,
              awsError: true,
            });
            return;
          }
          this.setState({
            loading: false,
            authed: false,
          });
          return;
        }
        const json = JSON.parse(data);
        if (!json.user) {
          if (this.udTimes < 3) {
            setTimeout(() => {
              this.udTimes += 1;
              this.getUserData(user);
            }, 200);
          }
          return;
        }
        if (json.user && json.user.sem_id && !json.user.company) {
          window.location.replace("https://sem.dowhatworks.io/sem");
          return;
        }
        user.token = token;
        user.name = json.user ? json.user.name : "";
        user.company = json.user ? json.user.company : "";
        user.prefs = json.user ? json.user.email_prefs : null;
        user.customer = json.user ? json.user.customer : null;
        user.permissions =
          json.user && json.user.permissions ? json.user.permissions : null;

        this.onRefresh = onRefresh;

        this.setState({
          loading: false,
          authed: true,
          user,
        });
      }
    );
  };
  trackUserActivity = async user => {
    request(
      {
        url: `${baseApiUrl}/v1/userActivity?url=${window.location.href}`,
        headers: {
          Authorization: user.token,
        },
      },
      (err, res, data) => {}
    );
  };
  setIsHome = (isHome, isCustomerHome) => {
    this.setState({
      isHome,
      isCustomerHome,
    });
  };
  setIsCustomerHome = isCustomerHome => {
    this.setState({
      isCustomerHome,
    });
  };
  onRefresh = false;

  removeListener = () => {};
  scrollToTop = e => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  populateEmail = () => {
    document.getElementById("contact-us").href = `mailto:${atob(
      "aGVscEBkb3doYXR3b3Jrcy5pbw=="
    )}`;
  };
  render() {
    const { loading, authed, user, isHome, isCustomerHome } = this.state;
    if (user && user.token && !this.onRefresh) {
      request({
        url: `${baseApiUrl}/v1/t?url=${window.location.href}`,
        headers: {
          Authorization: user.token,
        },
      });
    }
    if (this.onRefresh) {
      this.onRefresh = false;
    }
    if (loading) {
      return (
        <div className="App">
          {!isHome && (
            <Header
              handleLogout={this.handleLogout}
              user={user}
              loading={true}
            />
          )}
          <div className="page">
            <div
              className={cx({
                document: !isHome && !isCustomerHome,
                center: true,
              })}
            >
              <div className="loader">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (isHome && !user) {
      window.location.replace("https://dowhatworks.io");
      return <></>;
    }
    return (
      <div className="App">
        <Error user={user} awsError={this.state.awsError}>
          <Router>
            <PageWrapper
              callback={this.setIsHome}
              token={user && user.token ? user.token : null}
              company={user && user.company ? user.company : null}
              loggedOut={!user}
            >
              {!isHome && (
                <Header
                  handleLogout={this.handleLogout}
                  user={user}
                  isHome={isHome}
                  loading={loading}
                />
              )}
              <div className="page">
                <div
                  className={cx({
                    document: !isHome && !isCustomerHome,
                    center: true,
                  })}
                >
                  {loading && (
                    <div className="loader">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                  {!loading && (
                    <>
                      <Route
                        path="/"
                        exact
                        render={props => (
                          <Index
                            {...props}
                            user={user}
                            authed={authed}
                            handleLogout={this.handleLogout}
                          />
                        )}
                      />
                      <Route
                        path="/link/:next?"
                        strict
                        render={props => {
                          const { match, location } = props;
                          if (!user || !user.company) {
                            return (
                              <Redirect
                                to={`/login?returnUri=${window.location.href}`}
                              />
                            );
                          }
                          const { company } = user;
                          const url =
                            location.pathname.replace(
                              "link",
                              `home/${company}`
                            ) +
                            location.search +
                            location.hash;
                          return <Redirect to={url} />;
                        }}
                      />
                      <Route
                        path="/share/:next?"
                        strict
                        render={props => {
                          return (
                            <RequestAccess
                              {...props}
                              redirectUrl={`/login?returnUri=${window.location.href}`}
                              user={user}
                            />
                          );
                        }}
                      />
                      <Route
                        path="/account/email"
                        exact
                        render={props => (
                          <EmailPrefs {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/account"
                        exact
                        render={props => (
                          <User {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/account/users"
                        exact
                        render={props => (
                          <Users {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/account/users/screeners"
                        exact
                        render={props => (
                          <Screeners {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/account/update_cc"
                        exact
                        render={props => (
                          <UpdateCC {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/subscribe/"
                        exact
                        render={props => (
                          <Subscribe {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/subscribe/:company"
                        exact
                        render={props => (
                          <Subscribe {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/onboarding_game"
                        exact
                        render={props => (
                          <Game {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/invite"
                        exact
                        render={props => (
                          <Invite
                            {...props}
                            user={user}
                            authed={authed}
                            isRegister
                          />
                        )}
                      />
                      <Route
                        path="/register/:company"
                        exact
                        render={props => (
                          <Subscribe
                            {...props}
                            user={user}
                            authed={authed}
                            isRegister
                          />
                        )}
                      />
                      <Route
                        path="/success"
                        exact
                        render={props => (
                          <Success {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/home/:company"
                        render={props => (
                          <Home {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/admin"
                        render={props => (
                          <Admin {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route path="/terms" exact component={Terms} />
                      <Route path="/privacy" exact component={Privacy} />
                      <Route
                        path="/login"
                        exact
                        render={props => (
                          <Login {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/sso/register"
                        exact
                        render={props => (
                          <SSO {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/login/sso/callback"
                        exact
                        render={props => (
                          <SSOCallback {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/pw_reset"
                        exact
                        render={props => (
                          <Reset {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/password"
                        exact
                        render={props => (
                          <Password {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/careers"
                        render={props => (
                          <Jobs {...props} user={user} authed={authed} />
                        )}
                      />
                      <Route
                        path="/view/:test/:share?"
                        render={props => (
                          <ViewSharedScreenshot
                            {...props}
                            user={user}
                            authed={authed}
                          />
                        )}
                      />

                      <Route
                        path="/slack/auth/callback"
                        exact
                        render={props => (
                          <SlackCallback
                            {...props}
                            user={user}
                            authed={authed}
                          />
                        )}
                      />
                    </>
                  )}
                </div>
                {user && authed && !isHome && (
                  <div className="pa2 b">
                    Have some feedback or a question?{" "}
                    <a href="mailto:help@dowhatworks.io">Let us know</a>.
                  </div>
                )}
              </div>
              <footer
                style={{ fontSize: ".75em" }}
                className="bt b--black-20 pv3"
              >
                <div className="document-spacing tl center flex">
                  <div className="w-70 tc-s">
                    <span className="foot-text mr4 copyright">
                      © DoWhatWorks 2023
                    </span>
                    <div className="foot-links">
                      <Link to="/terms" className="mr4 foot-text">
                        Terms of Service
                      </Link>
                      <Link to="/privacy" className="mr4 foot-text">
                        Privacy Policy
                      </Link>
                      {!this.state.isHome ? (
                        <a
                          href="https://www.blog.dowhatworks.io/"
                          className="foot-text mr4"
                        >
                          Blog
                        </a>
                      ) : (
                        <a
                          href="https://www.blog.dowhatworks.io/"
                          className="foot-text mr4 dib-s dn"
                        >
                          Blog
                        </a>
                      )}
                      <a
                        href="https://www.blog.dowhatworks.io/careers"
                        className="foot-text mr4"
                      >
                        Careers
                      </a>
                      <a
                        href=""
                        className="foot-text"
                        onMouseEnter={this.populateEmail}
                        id="contact-us"
                        target="_blank"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                  <div className="w-30 tr">
                    <a href="#" onClick={this.scrollToTop} className="to-top">
                      Back to Top <img src={ArrowWhite} />
                    </a>
                  </div>
                </div>
              </footer>
            </PageWrapper>
          </Router>
        </Error>
      </div>
    );
  }
}

export default App;
