import React, { Component } from "react";
import request from "request";
import { baseApiUrl, fbAuth } from "../../util";
import * as qs from "query-string";
import Register from "../../components/Register";
import { Redirect } from "react-router-dom";

class SSO extends Component {
  state = {
    redir: null,
    error: null,
  };
  componentDidMount() {
    const { code } = qs.parse(window.location.search);
    request.post(
      {
        url: `${baseApiUrl}/v1/sso/auth/callback?code=${code}`,
      },
      async (err, res, data) => {
        if (err || res.statusCode >= 400) {
          const json = JSON.parse(data);
          this.setState({ error: json.error });
          return;
        }
        const json = JSON.parse(data);
        if (json.type === "login") {
          await fbAuth.signInWithCustomToken(json.token);
          this.props.history.push("/");
          return;
        }
        this.setState({
          code: json.code,
          type: json.type,
          profile: json.profile,
          company: json.company,
          invite: json.invite,
        });
      }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <div className="pt4">
          <div className="mw6 mt4 box pa3 center tl">
            <h1>Something went wrong</h1>

            <p className="red b mv3">{this.state.error}</p>
          </div>
        </div>
      );
    }
    if (this.props.user && this.props.authed) {
      return (
        <Redirect
          to={`/home/${this.props.user.company}/report?from=register`}
        />
      );
    }
    if (this.state.type === "register") {
      return (
        <div className="pt4">
          <Register
            company={this.state.company}
            isRegister
            isSSO
            name={`${this.state.profile.first_name} ${this.state.profile.last_name}`}
            email={this.state.profile.email}
            profile={this.state.profile}
            invite={this.state.invite}
            history={this.props.history}
          />
        </div>
      );
    }
    return <></>;
  }
}

export default SSO;
