import React from "react";

const labelClass = "db fw4 lh-copy black-60 mt4";
const inputClass = "pa2 input-reset w-80 w-50-l ba b--black-20 bg-white br2";

const TextInput = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  onFocus,
}) => {
  return (
    <div className="mt3">
      <label className={labelClass}>{label}</label>
      <input
        type={type}
        onChange={onChange}
        name={name}
        className={inputClass}
        value={value}
        onFocus={onFocus}
      />
    </div>
  );
};

export default TextInput;
