import React from "react";
import request from "request";
import { baseApiUrl } from "../../util";
import Loading from "../../components/Loading";
import TagSearch from "../../components/TagSearch";
import * as cx from "classnames";
import Delete from "../../icons/delete_red.svg";
import Add from "../../icons/add_blue.svg";
import Edit from "../../icons/edit_blue.svg";

const expanders = {};

class Sector extends React.Component {
  state = {
    expanded: false,
    editing: false,
    creating: false,
    name: this.props.name,
    new_name: null,
    companies: this.props.companies || [],
  };
  expand = () => {
    expanders[this.props.s_key] = true;
    this.setState({ expanders });
  };
  contract = () => {
    expanders[this.props.s_key] = false;
    this.setState({ expanders });
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  deleteCompany = tld => e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to remove this?")) {
      this.setState({ saving: true });
      request.delete(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/admin/sector/company`,
          form: {
            tld,
            sector: this.props.s_key,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.props.saveCallback();
            this.setState({
              saving: false,
            });
          }
        }
      );
    }
  };
  delete = e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to remove this?")) {
      request.delete(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/admin/sector`,
          form: {
            id: this.props.id,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            alert("something went wrong!");
          }
          if (!err) {
            this.props.saveCallback();
            this.setState({
              saving: false,
            });
          }
        }
      );
    }
  };
  add = e => {
    e.preventDefault();
    this.setState({ creating: true });
  };
  addCancel = e => {
    e.preventDefault();
    this.setState({ creating: false });
  };
  editCancel = e => {
    e.preventDefault();
    this.setState({ editing: false });
  };
  doAdd = e => {
    e.preventDefault();
    if (this.state.new_name) {
      let key;
      if (this.props.id !== "new") {
        const keyArr = this.props.s_key.split(" || ");
        keyArr.push(this.state.new_name);
        key = keyArr.join(" || ");
      } else {
        key = this.state.new_name;
      }
      this.setState({ saving: true });
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/admin/sector`,
          form: {
            key,
          },
        },
        err => {
          this.setState({
            saving: false,
            creating: false,
          });
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            this.props.saveCallback();
          }
        }
      );
    } else {
      alert("Fill this out!");
    }
  };
  edit = e => {
    e.preventDefault();
    this.setState({ editing: true });
  };
  addCompany = e => {
    e.preventDefault();
    this.setState({ adding: true });
  };
  setSector = vals => {
    this.setState({ company: vals[0] });
  };
  saveCompany = e => {
    e.preventDefault();
    if (!this.state.company) {
      alert("Add a company");
      return;
    }
    this.setState({ saving: true });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/admin/sector/company`,
        form: {
          tld: this.state.company,
          sector: this.props.s_key,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.props.saveCallback();
        }
        this.setState({
          saving: false,
          company: null,
        });
      }
    );
  };
  doEdit = () => {
    if (this.state.name) {
      this.setState({ saving: true });
      const nameArr = this.props.s_key.split(" || ");
      let name;
      if (nameArr.length > 1) {
        nameArr[nameArr.length - 1] = this.state.name;
        name = nameArr.join(" || ");
      } else {
        name = this.state.name;
      }
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.token,
          },
          url: `${baseApiUrl}/v1/admin/sectors`,
          form: {
            id: this.props.id,
            new_key: name,
          },
        },
        err => {
          if (err) {
            this.setState({
              error: true,
            });
            return;
          }
          if (!err) {
            setTimeout(() => {
              this.setState({
                saving: false,
                editing: false,
              });
              this.props.saveCallback();
            }, 250);
          }
        }
      );
    } else {
      alert("Fill this out!");
    }
  };
  render() {
    const { editing, creating, adding, saving } = this.state;
    const { name, sectors, id, allCompanies, companies, s_key } = this.props;

    if (id === "new") {
      return (
        <div className="ml4 mt2 flex align-items-center">
          <input
            name="new_name"
            onChange={this.handleKeyUp}
            placeholder="Add a new sector"
          />
          {this.state.saving ? (
            <span className="f7 ml1">Saving...</span>
          ) : (
            <>
              <input
                type="submit"
                className="f7 ml1"
                value="Add"
                onClick={this.doAdd}
              />
            </>
          )}
        </div>
      );
    }
    return (
      <div className="ml4 pb3">
        <div className="flex mt3 align-items-center">
          {editing ? (
            <>
              {saving ? (
                <>saving...</>
              ) : (
                <>
                  <input
                    name="name"
                    defaultValue={name}
                    onChange={this.handleKeyUp}
                  />
                  <input
                    type="submit"
                    className="f7 ml1"
                    value="Save"
                    onClick={this.doEdit}
                  />
                  <a href=" #" className="f7 ml1" onClick={this.editCancel}>
                    cancel
                  </a>
                </>
              )}
            </>
          ) : (
            <>
              {expanders[s_key] ? (
                <div onClick={this.contract}>
                  {sectors.length > 0 ? (
                    <span className="b">- </span>
                  ) : (
                    <span>&nbsp; </span>
                  )}
                  <span className={cx({ pointer: sectors.length > 0 })}>
                    <>{name}</>
                  </span>
                </div>
              ) : (
                <div onClick={this.expand}>
                  {sectors.length > 0 ? (
                    <span className="b">+ </span>
                  ) : (
                    <span>&nbsp; </span>
                  )}

                  <span className={cx({ pointer: sectors.length > 0 })}>
                    {name}
                  </span>
                </div>
              )}
            </>
          )}
          <img
            src={Edit}
            className="ml2 pointer icon"
            style={{ height: "18px" }}
            onClick={this.edit}
            alt="edit"
          />
          {sectors.length === 0 && (
            <img
              src={Delete}
              className="ml2 pointer icon"
              style={{ height: "18px" }}
              onClick={this.delete}
              alt="delete"
            />
          )}
          <a href=" #" className="f7 ml2" onClick={this.add}>
            <img
              src={Add}
              className="mr1 pointer icon"
              style={{ height: "18px" }}
              alt="add"
            />
            Add sector
          </a>
          <a href=" #" className="f7 ml2" onClick={this.addCompany}>
            <img
              src={Add}
              className="mr1 pointer icon"
              style={{ height: "18px" }}
              alt="add"
            />
            Add company
          </a>
        </div>
        {adding && (
          <div className="ml3 mt2 flex">
            {this.state.saving ? (
              <span className="f7">Saving...</span>
            ) : (
              <form onSubmit={this.saveCompany} className="w-100 flex">
                <div className="w-50 mr2">
                  <TagSearch
                    options={allCompanies.map(c => ({
                      label: `${c.name} (${c.tld})`,
                      value: c.tld,
                    }))}
                    callback={this.setSector}
                    placeholder="Companies"
                    singular
                  />
                </div>
                <input type="submit" value="Save" />
              </form>
            )}
          </div>
        )}
        {creating && (
          <div className="ml3 mt2 flex align-items-center">
            <input name="new_name" onChange={this.handleKeyUp} />
            {this.state.saving ? (
              <span className="f7 ml1">Saving...</span>
            ) : (
              <>
                <input
                  type="submit"
                  className="f7 ml1"
                  value="Add"
                  onClick={this.doAdd}
                />
                <a
                  href=" #"
                  className="f7 ml1"
                  style={{ padding: "6px" }}
                  onClick={this.addCancel}
                >
                  cancel
                </a>
              </>
            )}
          </div>
        )}
        {companies && companies.length > 0 && (
          <div className="mt3 ml4">
            {companies.map(c => (
              <div className="mt2 flex" key={`${c.tld}~${id}`}>
                <b>
                  {c.name} ({c.tld})
                </b>
                <img
                  src={Delete}
                  className="ml2 pointer icon"
                  style={{ height: "18px" }}
                  onClick={this.deleteCompany(c.tld)}
                  alt="delete"
                />
              </div>
            ))}
          </div>
        )}
        {expanders[s_key] && sectors.length > 0 && (
          <>
            {sectors.map((sector, y) => {
              return (
                <Sector
                  name={sector.name}
                  sectors={sector.sectors}
                  id={sector.id}
                  s_key={sector.key}
                  saveCallback={this.props.saveCallback}
                  token={this.props.token}
                  allCompanies={this.props.allCompanies}
                  companies={sector.companies}
                  key={sector.id}
                  expanderCallback={this.props.expanderCallback}
                />
              );
            })}
          </>
        )}
      </div>
    );
  }
}

class Sectors extends React.Component {
  state = {
    sectors: [],
    companies: [],
    loading: true,
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    //this.setState({ loading: true });
    request(
      {
        url: `${baseApiUrl}/v1/admin/sectors`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          alert("Something went wrong!");
          return;
        }
        const json = JSON.parse(data);

        json.sectors.push({ name: "New Sector", sectors: [], id: "new" });

        this.setState({
          loading: false,
          sectors: json.sectors,
          companies: json.companies.companies,
        });
      }
    );
  };
  render() {
    const { sectors, companies } = this.state;

    return (
      <div>
        <h1>Sectors</h1>
        <div className="mt2 box pa3">
          {Object.keys(sectors).length > 0 ? (
            <>
              {Object.keys(sectors).map(s => {
                const sector = sectors[s];
                return (
                  <Sector
                    name={sector.name}
                    sectors={sector.sectors}
                    id={sector.id}
                    s_key={sector.key}
                    saveCallback={() => {
                      setTimeout(() => {
                        this.getData();
                      }, 250);
                    }}
                    allCompanies={companies}
                    companies={sector.companies}
                    token={this.props.user.token}
                    key={sector.id}
                  />
                );
              })}
            </>
          ) : (
            <div>
              <Loading />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Sectors;
