import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import * as cx from "classnames";
import X from "../icons/red_x.svg";
import TagFilter from "./TagFilter";
import TagPicker from "./TagPicker";
import { capitalize } from "../util";
export const NO_OF_TAGS_VALUES = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6+", value: 6 },
];
export const WHO_WON_VALUES = [
  { label: "All", value: "all" },
  { label: "Control", value: "control" },
  { label: "Variant", value: "variant" },
  { label: "No Winner", value: "no_winner" },
];
export const COUNTRIES = [
  { label: "US / United States", value: "us", default: false },
  { label: "UK / United Kingdom", value: "uk", default: false },
  { label: "AU / Australia", value: "au", default: false },
  { label: "FR / France", value: "fr", default: false },
  { label: "DE / Germany", value: "de", default: false },
];

export const LOCATIONS = [
  { label: "Top of Page", value: "top" },
  { label: "Middle of Page", value: "middle" },
  { label: "Bottom of Page", value: "bottom" },
];

class NewFilters extends React.Component {
  constructor(props) {
    super(props);
    let [minTags, maxTags] = props.noOfTagsValue
      ? props.noOfTagsValue.split("-")
      : [0, 6];
    if (!minTags || minTags === 0) {
      minTags = 0;
    }
    if (!maxTags) {
      maxTags = 6;
    }
    this.state = {
      companies: null,
      note_tags: null,
      typesToFilter: props.typesToFilter,
      filterCount: props.filterCount ? props.filterCount : 0,
      platform: props.platform || "all",
      clearing: false,
      isClosing: false,
      end_date: props.end_date,
      start_date: props.start_date,
      publish_end_date: props.publish_end_date,
      publish_start_date: props.publish_start_date,
      no_of_tags: [minTags, maxTags],
      minTags,
      maxTags,
      country: props.country ? props.country : null,
    };
  }

  returnVals = {
    end_date: this.props.end_date,
    start_date: this.props.start_date,
    publish_end_date: this.props.publish_end_date,
    publish_start_date: this.props.publish_start_date,
  };
  groupBy = group => {
    this.props.groupCallback(group);
  };
  doTime = group => {
    this.props.timeCallback(group);
  };
  doClear = e => {
    this.returnVals = {
      start_date: null,
      end_date: null,
      publish_start_date: null,
      publish_end_date: null,
    };

    this.setState(
      {
        filterCount: 0,
        clearing: true,
        start_date: null,
        end_date: null,
        publish_start_date: null,
        publish_end_date: null,
      },
      () => {
        this.props.callback(this.returnVals, 0);
        this.setState({ clearing: false });
        this.doClose(e);
      }
    );
  };
  changeTimeout = null;
  onChangeDate = e => {
    const { name, value } = e.currentTarget;

    this.returnVals[name] = value;
    this.setState({ [name]: value }, () => {
      this.doCallback();
    });
  };
  callbackTimeout = null;
  doCallback = () => {
    clearTimeout(this.callbackTimeout);
    this.callbackTimeout = setTimeout(() => {
      let count = 0;
      Object.keys(this.returnVals).forEach(x => {
        if (this.returnVals[x]) {
          count++;
        }
      });

      this.setState({ filterCount: count }, () => {
        this.props.callback(this.returnVals, count);
      });
    }, 1100);
  };
  doClose = e => {
    this.setState({ isClosing: true }, () => {
      setTimeout(() => {
        this.props.closeCallback(e);
      }, 500);
    });
  };
  doDateSort = val => {
    this.props.dateCallback(val);
  };
  doChangeNoOfTags = val => {
    this.props.noOfTagsCallback(val);
  };

  doChangeWhoWon = val => {
    this.props.whoWonCallback(val);
  };
  doChangeCountry = val => {
    this.props.countryChangeCallback(val);
  };
  doChangeLocation = val => {
    this.props.locationChangeCallback(val);
  };
  checkTagValueValid = () => {
    const { minTags, maxTags } = this.state;
    return minTags >= 0 && maxTags >= 0 && minTags <= maxTags;
  };
  handleNoOfTagsChanged = newValue => {
    console.log({ newValue });
    //return;
    const [minTags, maxTags] = newValue;
    this.setState({
      minTags,
      maxTags,
      no_of_tags: newValue,
    });
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }
    this.changeTimeout = setTimeout(() => {
      this.props.noOfTagsCallback(newValue.join("-"));
    }, 250);
  };

  valuetext = value => {
    if (value == 6) {
      return "6+";
    }
    return value;
  };
  marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6+",
  };
  render() {
    let platformDefaultOpts = { label: "All", value: "all" };
    if (this.props.platform === "desktop") {
      platformDefaultOpts = { label: "Desktop", value: "desktop" };
    } else if (this.props.platform === "mobile") {
      platformDefaultOpts = { label: "Mobile", value: "mobile" };
    }
    let whoWonDefaultOpts =
      this.props.whoWon === "all"
        ? { label: "All", value: "all" }
        : WHO_WON_VALUES.find(item => item.value == this.props.whoWon);
    const isTagValid = this.checkTagValueValid();
    let countryDefaultOpts = [];
    if (this.props.country && Array.isArray(this.props.country)) {
      const countries = this.props.country.filter(c => c && c !== "all");
      countryDefaultOpts = countries.map(c =>
        COUNTRIES.find(country => c === country.value)
      );
    }
    let locationDefaultOpts = !this.props.locations
      ? LOCATIONS.find(location => location.default)
      : LOCATIONS.find(location => location.value === this.props.location);
    return (
      <div
        className={cx({
          "sticky-filters-container": true,
          "sticky-filters-container-out": this.state.isClosing,
          "sticky-filters-start": !this.props.didScroll,
        })}
        onClick={this.doClose}
      >
        <div
          className={cx({
            "sticky-filters": true,
            "sticky-filters-out": this.state.isClosing,
          })}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="tr">
            <img
              src={X}
              className="filter-x"
              alt="close"
              onClick={e => {
                e.stopPropagation();
                this.doClose(e);
              }}
            />
          </div>
          <div className="filter-header bb b--black-05">More Filters</div>
          {!this.state.clearing && (
            <>
              {this.props.isAdmin && (
                <div className="mb3 pb3 bb b--black-05">
                  <TagPicker
                    options={WHO_WON_VALUES}
                    callback={this.doChangeWhoWon}
                    defaultOptions={whoWonDefaultOpts}
                    title="Who won"
                    inline
                    title_slug="whowon"
                  />
                </div>
              )}
              <div className="mb3 pb4 bb b--black-05 pl2">
                <label className="f4 pb1"># of variables tested</label>
                <div className="mr3 ml2">
                  <Slider
                    range={[1, 2, 3, 4, 5, 6]}
                    defaultValue={this.state.no_of_tags}
                    onChangeComplete={this.handleNoOfTagsChanged}
                    valueLabelDisplay="off"
                    step={1}
                    marks={this.marks}
                    min={1}
                    max={6}
                    railStyle={{ backgroundColor: "#ccc" }}
                    handleStyle={{
                      backgroundColor: "#007bff",
                      boxShadow: "0 0 0 3px #007bff",
                      borderColor: "#007bff",
                      marginTop: "-6px",
                      opacity: "0.6",
                    }}
                    railStyle={{ backgroundColor: "#ccc", height: "2px" }}
                    trackStyle={{
                      backgroundColor: "#007bff",
                      opacity: 0.4,
                      height: "2px",
                    }}
                    dotStyle={{
                      backgroundColor: "#ccc",
                      boxShadow: "0 0 0 1px #ccc",
                      borderColor: "#ccc",
                      bottom: "-1px",
                    }}
                    activeDotStyle={{
                      backgroundColor: "#007bff",
                      boxShadow: "0 0 0 1px #007bff",
                      borderColor: "#007bff",
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="mb3 pb3 bb b--black-05 pl2">
            <label className="f4">Tests that ran</label>

            <div className="b">From</div>
            <div>
              <input
                type="date"
                name="start_date"
                onChange={this.onChangeDate}
                className="pa2 input-reset w-100 ba b--black-20 bg-white br2"
                defaultValue={this.props.start_date}
                max={
                  this.state.end_date
                    ? this.state.end_date
                    : new Date().toISOString().substr(0, 10)
                }
              />
            </div>
            <div className="pt3">
              <div className="b">To</div>
              <input
                type="date"
                name="end_date"
                onChange={this.onChangeDate}
                className="pa2 input-reset w-100 ba b--black-20 bg-white br2"
                defaultValue={this.props.end_date}
                min={this.state.start_date ? this.state.start_date : null}
                max={new Date().toISOString().substr(0, 10)}
              />
            </div>
          </div>
          {this.props.isAdmin && (
            <div className="mb3 pb3 bb b--black-05 pl2">
              <label className="f4">Tests Published</label>

              <div className="b">From</div>
              <div>
                <input
                  type="date"
                  name="publish_start_date"
                  onChange={this.onChangeDate}
                  className="pa2 input-reset w-100 ba b--black-20 bg-white br2"
                  defaultValue={this.props.publish_start_date}
                  max={
                    this.state.publish_end_date
                      ? this.state.publish_end_date
                      : new Date().toISOString().substr(0, 10)
                  }
                />
              </div>
              <div className="pt3">
                <div className="b">To</div>
                <input
                  type="date"
                  name="publish_end_date"
                  onChange={this.onChangeDate}
                  className="pa2 input-reset w-100 ba b--black-20 bg-white br2"
                  defaultValue={this.props.publish_end_date}
                  min={
                    this.state.publish_start_date
                      ? this.state.publish_start_date
                      : null
                  }
                  max={new Date().toISOString().substr(0, 10)}
                />
              </div>
            </div>
          )}
          {this.props.isAdmin && (
            <>
              <div className="mb3 pb3 bb b--black-05">
                <TagPicker
                  options={LOCATIONS}
                  callback={this.doChangeLocation}
                  defaultOptions={locationDefaultOpts}
                  title="Page Test Location"
                  inline
                  title_slug="location"
                />
              </div>
            </>
          )}
          <div className="mb3 pb3 bb b--black-05">
            <TagPicker
              options={COUNTRIES}
              callback={this.doChangeCountry}
              defaultOptions={countryDefaultOpts}
              title="Country"
              inline
              title_slug="country"
              multiselect
            />
          </div>
          <button
            className="ui-button primary"
            onClick={e => {
              e.stopPropagation();
              this.doClose(e);
            }}
          >
            Close
          </button>
          {this.state.filterCount > 0 && (
            <button
              className="ui-button secondary ml3"
              onClick={e => {
                e.stopPropagation();
                this.doClear(e);
              }}
            >
              Clear All
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default NewFilters;
