import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as cx from "classnames";
import { baseApiUrl } from "../util";
import request from "request";
import TagSearch from "./TagSearch";
const ADMIN_CUSTOMER = { label: "Admin", value: "admin" };
class CopyCollection extends React.Component {
  state = {
    collection_name: "",
    customers: this.props.allCustomers,
    saving: false,
    analysis: this.props.allAnalysis,
    selected_collections: [],
    selected_customer: [ADMIN_CUSTOMER.value],
    show_error: false,
    error: false,
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  isInputValid = () => {
    const { collection_name, selected_collections, selected_customer } =
      this.state;
    return collection_name.trim() !== "" && selected_collections.length > 0;
  };
  handleSubmit = e => {
    e.preventDefault();
    if (!this.isInputValid()) {
      this.setState({
        show_error: true,
      });
      return false;
    }
    const { collection_name, selected_collections, selected_customer } =
      this.state;
    this.setState({ saving: true });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/copyCollections`,
        form: {
          data: JSON.stringify({
            collection_name,
            selected_collections,
            selected_customer,
          }),
        },
      },
      (err, data) => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          return;
        }
        if (!err) {
          const json = JSON.parse(data.body);
          if (json.error) {
            this.setState({
              error: true,
              saving: false,
            });
            return;
          }
          this.props.doClose(e);
        }
      }
    );
  };
  onChangeCollections = vals => {
    this.setState({ selected_collections: vals });
  };
  onSelectCustomer = vals => {
    this.setState({ selected_customer: vals });
  };
  onAlertClose = () => {
    this.setState({
      error: false,
    });
  };
  render() {
    const { collection_name, saving } = this.state;
    return (
      <>
        <div className="pa3 bb b--black-05">
          <h1>Copy Collection</h1>
        </div>
        <div className="pa3">
          <form onSubmit={this.handleSubmit}>
            {this.state.show_error && (
              <div className="mt3 light-red f6">Please add Collection name</div>
            )}
            <div className="pt3">
              <input
                type="text"
                placeholder="Collection name"
                className={cx("pa2 w-100", {
                  "ba b--light-red": collection_name.trim() === "",
                })}
                name="collection_name"
                value={collection_name}
                onChange={this.handleKeyUp}
              />
            </div>

            <>
              <div className="mt3">
                <h4>Copy tests from analysis/collections</h4>
              </div>
              <div className="mt3">
                {this.state.analysis && this.state.analysis.length > 0 && (
                  <TagSearch
                    options={this.state.analysis.map(a => ({
                      label:
                        a.analysis_props && a.analysis_props.length > 0
                          ? `Analysis: ${a.name}`
                          : `Collection: ${a.name}`,
                      value: a.id,
                    }))}
                    onBottom
                    callback={this.onChangeCollections}
                  />
                )}
              </div>
            </>
            <>
              <div className="mt3">
                <h4>Publish collection to customer</h4>
              </div>
              <div className="mt3">
                {this.state.customers && this.state.customers.length > 0 && (
                  <TagSearch
                    options={[
                      ADMIN_CUSTOMER,
                      ...this.state.customers.map(a => ({
                        label: a.name,
                        value: a.key,
                      })),
                    ]}
                    onBottom
                    callback={this.onSelectCustomer}
                    defaultTags={[ADMIN_CUSTOMER.value]}
                    singular
                  />
                )}
              </div>
            </>
            <div className="pt4 flex">
              {saving ? (
                <button className="ui-button disabled" disabled>
                  Saving...
                </button>
              ) : (
                <>
                  <input
                    type="submit"
                    value={this.props.editing ? "Save" : "Create"}
                    className="ui-button primary"
                  />
                  <a
                    href=" #"
                    onClick={e => {
                      this.props.doClose(e);
                    }}
                    style={{ paddingTop: "6px" }}
                    className="ml3"
                  >
                    Cancel
                  </a>
                </>
              )}
            </div>
          </form>
          <Dialog
            open={this.state.error}
            onClose={this.onAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Failed to copy collection."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Error occured while copying collection, please try again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onAlertClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

export default CopyCollection;
