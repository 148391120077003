import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import ViewSemNew from "./ViewSemNew";
import SEM from "./SEMnew";
import SEMcopy from "./SEMcopy";
import Discounts from "./Discounts";
import DashboardNew from "./NewDashboard";
// import AllTestReport from "./components/AllTestReportConditional";
import AllTestReport from "./v2/AllTests";
import AllTestRelated from "./v2/AllTestsRelated";
import Collections from "./Collections";
import SharedRequestAction from "./SharedRequestAction";
import ViewScreenshots from "./ViewScreenshotsModal";

class Index extends React.Component {
  render() {
    if (!this.props.user) {
      console.log(`redirecting from home to login`, this.props);
      return <Redirect to={`/login?returnUri=${window.location.href}`} />;
    }
    return (
      <div className="tl">
        <Route
          path="/home/:company"
          exact
          render={props => (
            <DashboardNew
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              new
            />
          )}
        />
        <Route
          path="/home/:company/sem"
          exact
          render={props => (
            <SEM
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              new
            />
          )}
        />
        <Route
          path="/home/:company/sem_copy"
          exact
          render={props => (
            <SEMcopy
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              new
            />
          )}
        />
        <Route
          path="/home/:company/discounts"
          exact
          render={props => (
            <Discounts
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              new
            />
          )}
        />
        <Route
          path="/home/:company/sem/:reportid"
          exact
          render={props => (
            <SEM
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              new
            />
          )}
        />
        <Route
          path="/home/:company/saved_test/:tid"
          exact
          render={props => (
            <AllTestReport
              {...props}
              isSavedTests
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/home/:company/new/report"
          exact
          key="all-tests-iiii"
          render={props => (
            <AllTestReport
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              key="all-tests"
            />
          )}
        />
        <Switch>
          <Route
            path="/home/:company/report"
            exact
            key="all-tests"
            render={props => (
              <AllTestReport
                {...props}
                user={this.props.user}
                authed={this.props.authed}
                key="all-tests"
              />
            )}
          />
          <Route
            path="/home/:company/report/screenshots/:ssid"
            exact
            key="view-ss-1"
            render={props => (
              <ViewScreenshots
                {...props}
                user={this.props.user}
                authed={this.props.authed}
                key="view-ss-2"
              />
            )}
          />
        </Switch>
        <Route
          path="/home/:company/related"
          exact
          key="all-tests-related"
          render={props => (
            <AllTestRelated
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              key="all-tests"
            />
          )}
        />
        <Route
          path="/home/:company/saved_test/:tid/screenshots/:ssid"
          exact
          render={props => (
            <AllTestReport
              {...props}
              isSavedTests
              user={this.props.user}
              authed={this.props.authed}
              key="all-tests-vi"
            />
          )}
        />
        <Route
          path="/home/:company/saved_search/:id"
          exact
          render={props => (
            <AllTestReport
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              saved_search
              key="all-tests-vii"
            />
          )}
        />
        <Route
          path="/home/:company/sem_2/:id"
          exact
          render={props => (
            <ViewSemNew
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/home/:company/sem_2/:id/:keyword"
          exact
          render={props => (
            <ViewSemNew
              {...props}
              user={this.props.user}
              authed={this.props.authed}
            />
          )}
        />
        <Route
          path="/home/:company/screenshots/:ssid"
          exact
          key="view-ss-1"
          render={props => (
            <ViewScreenshots
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              key="view-ss-2"
            />
          )}
        />
        <Route
          path="/home/:company/collections"
          exact
          key="collections-library"
          render={props => (
            <Collections
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="collection"
            />
          )}
        />
        <Route
          path="/home/:company/analyses"
          exact
          key="analyses library"
          render={props => (
            <Collections
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="analysis"
            />
          )}
        />
        <Route
          path="/home/:company/request-access/:shareId/:requestId/:action"
          exact
          key="request access"
          render={props => (
            <SharedRequestAction
              {...props}
              user={this.props.user}
              authed={this.props.authed}
              type="analysis"
            />
          )}
        />
      </div>
    );
  }
}

export default Index;
