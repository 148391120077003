import React from "react";
import CheckoutForm from "../../components/CheckoutForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import { stripeApiKey, stripeSkus } from "../../util";

class Subscribe extends React.Component {
  state = {
    loading: true,
    user: null,
    authed: false,
  };
  render() {
    return (
      <div className="tl pa3">
        <h1>Self-service LIVE TEST subscription</h1>
        <div class="flex items-top mb2">
          <input
            type="radio"
            name="sku"
            checked
            className="mr2 pb1"
            value={stripeSkus.admin}
          />
          <div>
            <p className="mt0">
              <b> $5.00/month (recurring)</b>
            </p>
            <p>
              <i>10 companies</i>
            </p>
          </div>
        </div>

        <StripeProvider apiKey={stripeApiKey}>
          <div>
            <Elements>
              <CheckoutForm user={this.props.user} sku={stripeSkus.admin} />
            </Elements>
          </div>
        </StripeProvider>
      </div>
    );
  }
}

export default Subscribe;
