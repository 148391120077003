import { useEffect, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { LinearProgress, CircularProgress } from "@material-ui/core";
import {
  baseApiUrl,
  getData,
  capitalize,
  copyTextToClipboard,
  getShareableURL,
  getCustomerShareableURL,
} from "../util";
import ShareIcon from "../icons/share.svg";
import X from "../icons/red_x.svg";
import ContentCopyIcon from "../icons/content_copy.svg";
import EmailRedIcon from "../icons/email_purple.svg";
import EmailGrayIcon from "../icons/email_gray.svg";
import LinkRedIcon from "../icons/link_purple.svg";
import LinkGrayIcon from "../icons/link_gray.svg";
import SuccessImg from "../icons/old/champion.svg";
import cx from "classnames";

const ShareTest = props => {
  const [shareUrlCopied, setShareUrlCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [shareURL, setShareURL] = useState("");
  const [email, setEmail] = useState("");
  const [shareName, setShareName] = useState("");
  const [error, setError] = useState({});
  const clearShareUrlTimer = useRef(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [company, setCompany] = useState(props.company);
  const sharePopupDimen = {
    width: 400,
    height: 425,
  };
  useEffect(() => {
    if (shareUrlCopied || shareURL === "email") {
      clearTimeout(clearShareUrlTimer.current);
      clearShareUrlTimer.current = setTimeout(() => {
        if (shareURL === "email") {
          setShowPopup(false);
        }
        setShareURL("");
        setShareUrlCopied(false);
      }, 2500);
    }
  }, [shareUrlCopied, shareURL]);

  const toggleSharePopup = state => {
    const newState = state || !showPopup;
    if (!newState) {
      reset();
    }
    setShowPopup(newState);
  };
  const toggleDropDown = state => {
    const newState = state || !showDropDown;
    if (!newState) {
      reset();
    }
    setShowDropDown(newState);
  };
  const copyShareUrl = url => {
    copyTextToClipboard(url || shareURL)
      .then(() => {
        setShareUrlCopied(true);
        setTimeout(() => {
          setShareUrlCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.log(err);
        setShareUrlCopied(false);
      });
  };
  const validateInput = () => {
    const errors = {};
    let isInputValid = true;
    if (
      !email ||
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      errors["email"] = true;
      isInputValid = false;
    }
    if (shareName.trim() === "") {
      errors["shareName"] = true;
      if (isInputValid) isInputValid = false;
    }
    setError(errors);
    return isInputValid;
  };
  const generateShareLink = (
    e,
    inputRequired = true,
    copyGeneratedLink = true
  ) => {
    console.log("generate share link called with ", {
      e,
      inputRequired,
      copyGeneratedLink,
    });
    if (requestLoading) return;
    if (!validateInput() && inputRequired) {
      return;
    }
    setRequestLoading(true);
    getData({
      url: `${baseApiUrl}/v1/user/share_test?test_id=${
        props.testId
      }&email=${encodeURIComponent(email)}&name=${encodeURIComponent(
        shareName
      )}&customer=${company}`,
      headers: {
        Authorization: props.user.token,
      },
    }).then(({ err, res, data }) => {
      setRequestLoading(false);
      if (err) {
        alert("Something went wrong, please try again");
      } else {
        const shareUrl = getCustomerShareableURL(data.shareId);
        if (data.shareType && data.shareType !== "email") {
          setShareURL(shareUrl);
          if (copyGeneratedLink) copyShareUrl(shareUrl);
        } else {
          setShareURL("email");
          setEmail("");
          setShareName("");
        }
      }
    });
  };
  const closeDropDown = e => {
    setShowDropDown(false);
  };
  const reset = () => {
    setShareURL("");
    setEmail("");
    setShareName("");
    setError({});
  };
  return (
    <>
      <div
        className="c-box-inner"
        style={{
          position: "relative",
          padding: "0px 4px",
        }}
      >
        <img
          aria-label="share"
          src={ShareIcon}
          color="primary"
          className="pointer share-icon"
          style={{
            marginTop: props.detailPage ? "-4px" : "-8px",
            padding: "0 1px",
          }}
          height="24"
          onClick={e => toggleDropDown()}
          title="Share this test"
        />

        {showDropDown && (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: "4",
                width: "100vw",
                height: "100vh",
                top: "0px",
                left: "0px",
              }}
              onClick={e => {
                reset();
                toggleDropDown();
              }}
            ></div>
            <div
              className="bg-white br3 box f5 tc"
              style={{
                width: "155px",
                position: "absolute",
                right: "-60px",
                top: "-6px",
                zIndex: "5",
              }}
            >
              {shareURL ? (
                <div
                  className="dww-purple b fadein2"
                  style={{ padding: "25px 0" }}
                >
                  Copied!
                </div>
              ) : (
                <div
                  className="flex pa3"
                  style={{ justifyContent: "space-between" }}
                >
                  <button
                    className={cx("ui-button action icon", {
                      disabled: requestLoading,
                    })}
                    title="Copy test link"
                    onClick={e => {
                      return generateShareLink(e, false, true);
                    }}
                  >
                    <>
                      <img
                        aria-label="share"
                        src={requestLoading ? LinkGrayIcon : LinkRedIcon}
                        className="pointer share-icon"
                        width={28}
                        height={28}
                      />
                      {requestLoading && <LinearProgress />}
                    </>
                  </button>
                  <button
                    className="ui-button action icon"
                    title="Send test by email"
                    onClick={e => {
                      toggleDropDown(false);
                      toggleSharePopup();
                    }}
                  >
                    <img
                      aria-label="share"
                      src={EmailRedIcon}
                      className="pointer share-icon"
                      height="26"
                      width="26"
                    />
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Modal open={showPopup} className="dww-modal-container">
        <div
          className="box"
          style={{
            width: `${sharePopupDimen.width}px`,
            height: `${sharePopupDimen.height}px`,
            overflow: "hidden",
            top: (window.innerHeight - sharePopupDimen.height) / 2,
            position: "absolute",
            left: (window.innerWidth - sharePopupDimen.width) / 2,
            outline: 0,
          }}
        >
          <div>
            <div className="pv3 ph4 bb b--black-05 flex items-center justify-between">
              <h3 className="mb0">Share this test</h3>
              <img
                aria-label="close"
                src={X}
                color="primary"
                className="pointer share-icon"
                height="20"
                width="20"
                onClick={e => {
                  setShowPopup(false);
                }}
              />
            </div>
            {shareURL === "email" ? (
              <div className="tc center">
                <div className="mb3 tc mt4">
                  <img
                    src={SuccessImg}
                    style={{
                      width: "78px",
                      height: "78px",
                    }}
                    alt="success"
                    className="moviefone-success mb4"
                  />
                  <h1 className="b f3 mb2">Email Sent!</h1>
                </div>
                <p className="tc f5 pa3">
                  If your colleague doesn't have a DoWhatWorks account, they can
                  request access from your dashboard admin.
                </p>
              </div>
            ) : (
              <>
                <div className="ph4 pv2">
                  We'll send an email with a link to this test. If your
                  colleague doesn't have a DoWhatWorks account, we'll help them
                  request access from your dashboard admin.
                </div>
                <div
                  className={cx("ph4 pt1", {
                    pointer: shareURL !== "email",
                    dn: !shareURL,
                  })}
                  onClick={e =>
                    !shareURL || shareURL !== "email"
                      ? generateShareLink(e)
                      : null
                  }
                >
                  <p
                    className="f6 black bg-moon-gray code pa1 pb0 tc"
                    style={{ wordWrap: "break-word" }}
                  >
                    {shareUrlCopied ? (
                      <span className="f6 ml1">
                        Share link copied to your clipboard!
                      </span>
                    ) : (
                      <>
                        "Click to copy share url"
                        <img
                          aria-label="share"
                          src={ContentCopyIcon}
                          color="primary"
                          className="pointer share-icon mb1"
                          height="20"
                          width="20"
                        />
                      </>
                    )}
                  </p>
                </div>

                <div className="ph4 pv3 flex flex-column items-start w-100">
                  <input
                    onChange={e => setShareName(e.target.value)}
                    value={shareName}
                    type="text"
                    placeholder="Name"
                    className={cx(
                      "w-100 b--white pb2 pt2 pl2 f6 mv2 bg-light-gray br3",
                      {
                        "gray bn": !error["shareName"],
                        "b--red": error["shareName"],
                      }
                    )}
                  />
                  <input
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    placeholder="Email"
                    className={cx(
                      "w-100 b--white pb2 pt2 pl2 f6 mv2 bg-light-gray br3",
                      {
                        "gray bn": !error["email"],
                        "b--red": error["email"],
                      }
                    )}
                  />
                </div>

                <div className="ph4 pv3 tc">
                  <button
                    className="primary ui-button"
                    onClick={generateShareLink}
                    disabled={requestLoading}
                  >
                    {requestLoading ? "Sending Email..." : "Send Email"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ShareTest;
