import React from "react";
import CreatableSelect from "react-select/creatable";
import request from "request";
import Loading from "../../components/Loading";
import CreateEditCollection from "../../components/CreateAnalysis";
import {
  baseApiUrl,
  capitalize,
  formatDateFromString,
  formatReportDate,
  getBetScoreName,
} from "../../util";
import EditIcon from "../../icons/edit_blue.svg";
import ExpandIcon from "../../icons/expand.svg";
import CollapseIcon from "../../icons/contract.svg";
import cx from "classnames";
import sortAsc from "../../icons/SortDown.svg";
import sortDesc from "../../icons/SortUp.svg";
import noSort from "../../icons/sort.svg";
import copyIcon from "../../icons/content_copy.svg";
import DeleteIcon from "../../icons/delete_red.svg";
import robotIcon from "../../icons/robot.svg";
import VerifiedBlueIcon from "../../icons/verified_blue.svg";
import VerifiedGrayIcon from "../../icons/verified_gray.svg";
import OpenLinkIcon from "../../icons/open_link.svg";
import AutoIcon from "../../icons/auto_analysis_blue.svg";
class Collections extends React.Component {
  state = {
    loading: true,
    collections: [],
    analysis: [],
    addNew: false,
    editCollectionId: null,
    filterdData: [],
    sort: {
      order: "desc",
      field: "last_modified",
    },
    searchString: {},
    analysisFilter: "all",
    copyCollection: null,
    allTags: [],
  };
  componentDidMount() {
    this.getData();
  }
  showAddNew = e => {
    e.preventDefault();
    this.setState({ addNew: true });
  };
  getData = () => {
    this.setState({
      loading: true,
      editCollectionId: null,
      searchString: {},
    });
    request(
      {
        url: `${baseApiUrl}/v1/admin/collections`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        this.setState({
          loading: false,
          collections: json.collections || [],
          analysis: json.analysis || [],
          customers: json.customers || [],
          allTags: json.allTags || [],
        });
      }
    );
  };

  changeType = e => {
    this.setState({ type: e.currentTarget.value });
  };

  handleEditCompany = collectionId => e => {
    this.setState({
      editCollectionId: collectionId,
    });
  };
  closeEditCompany = e => {
    this.setState({
      editCollectionId: null,
    });
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  filterDataFn = collection => {
    const { searchString } = this.state;
    if (searchString && Array.isArray(searchString)) {
      let found = false;
      searchString.forEach(searchTerm => {
        if (
          searchTerm.__isNew__ &&
          searchTerm.value.length > 2 &&
          collection.name &&
          collection.name
            .toLowerCase()
            .indexOf(searchTerm.value.toLowerCase()) >= 0
        ) {
          found = true;
        } else if (
          collection.tags &&
          collection.tags.some(
            tag => tag.tag.toLowerCase() === searchTerm.value
          )
        ) {
          found = true;
        }
      });
      return found;
    } else {
      return true;
    }
  };
  deleteAnalysis = (id, collectionName) => e => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${this.props.type} ${collectionName} ?`
      )
    ) {
      request.delete(
        {
          url: `${baseApiUrl}/v1/admin/analysis`,
          headers: {
            Authorization: this.props.user.token,
          },

          form: {
            id,
          },
        },
        err => {
          if (err) {
            alert("Something went wrong...");
            return;
          }
          setTimeout(() => {
            this.getData();
          }, 1000);
        }
      );
    }
  };
  handleCopyCollection = collection => e => {
    this.setState({
      addNew: true,
      copyCollection: collection,
    });
  };
  sortDataFn = (a, b) => {
    const { sort } = this.state;
    const fieldArray = sort.field.split(".");
    const fieldType = typeof a[fieldArray[0]];
    const filedTypeB = typeof b[fieldArray[0]];

    if (fieldType === "number" || filedTypeB === "number") {
      return sort.order === "asc"
        ? (a[fieldArray[0]] || 0) - (b[fieldArray[0]] || 0)
        : (b[fieldArray[0]] || 0) - (a[fieldArray[0]] || 0);
    }
    if (fieldType === "string" || filedTypeB === "string") {
      const strA = a[fieldArray[0]];
      const strB = b[fieldArray[0]];
      return this.sortStringFn(strA, strB, sort.order);
    }
    if (fieldType === "object") {
      if (
        !a[fieldArray[0]] ||
        !b[fieldArray[0]] ||
        !a[fieldArray[0]][fieldArray[1]] ||
        !b[fieldArray[0]][fieldArray[1]]
      ) {
        return 0;
      }
      const fieldType = typeof a[fieldArray[0]][fieldArray[1]];
      if (fieldType === "number")
        return sort.order === "asc"
          ? a[fieldArray[0]][fieldArray[1]] - b[fieldArray[0]][fieldArray[1]]
          : b[fieldArray[0]][fieldArray[1]] - a[fieldArray[0]][fieldArray[1]];
      if (fieldType === "string") {
        const strA = a[fieldArray[0]][fieldArray[1]];
        const strB = b[fieldArray[0]][fieldArray[1]];
        return this.sortStringFn(strA, strB, sort.order);
      }
    }
    if (fieldType === "boolean" || filedTypeB === "boolean") {
      let result;
      if (sort.order === "asc") {
        result = Number(!!b[fieldArray[0]]) - Number(!!a[fieldArray[0]]);
      } else {
        result = Number(!!a[fieldArray[0]]) - Number(!!b[fieldArray[0]]);
      }
      // console.log({ result, a: !!a[fieldArray[0]], b: !!b[fieldArray[0]] });
      return result;
    }
  };
  sortStringFn = (a, b, sortOrder = "asc") => {
    if (!a && !b) {
      return 0;
    }
    if (sortOrder === "asc") {
      if (a && !b) {
        return -1;
      }
      if (!a && b) {
        return 1;
      }
      if (a.toUpperCase() < b.toUpperCase()) return -1;
      else if (a.toUpperCase() > b.toUpperCase()) return 1;
      else return 0;
    } else {
      if (b && !a) {
        return -1;
      }
      if (a && !b) {
        return 1;
      }
      if (a.toUpperCase() < b.toUpperCase()) return 1;
      else if (a.toUpperCase() > b.toUpperCase()) return -1;
      else return 0;
    }
  };
  changeAnalysisFilter = e => {
    this.setState({ analysisFilter: e.target.value });
  };
  changeSort = field => e => {
    const { sort } = this.state;
    if (field === sort.field) {
      this.setState({
        sort: {
          order: sort.order === "asc" ? "desc" : "asc",
          field: sort.field,
        },
      });
    } else {
      this.setState({
        sort: {
          order: "asc",
          field,
        },
      });
    }
  };
  onChangeAddTag = (value, selectAction) => {
    this.setState({
      searchString: value,
    });
  };
  formatCreateLabel = value => {
    return `search for "${value}"`;
  };
  filterSearchOption = (option, inputValue) => {
    if (!inputValue) {
      return true;
    }
    if (option && option.data) {
      if (option.data.__isNew__) {
        return true;
      } else {
        if (option.value.startsWith(inputValue)) {
          return true;
        }
      }
    } else {
      return false;
    }
  };
  isValidNewOption = (inputVal, b, c, d) => {
    return inputVal;
  };
  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const {
      collections,
      analysis,
      customers,
      searchString,
      sort,
      analysisFilter,
    } = this.state;
    let data =
      this.props.type && this.props.type.toLowerCase() === "collection"
        ? collections.filter(this.filterDataFn).sort(this.sortDataFn)
        : analysis.filter(this.filterDataFn).sort(this.sortDataFn);

    if (analysisFilter && analysisFilter !== "all") {
      data = data.filter(a => {
        if (analysisFilter === "auto") {
          if (a.autoanalysis_data) {
            return true;
          }
        }
        if (analysisFilter === "auto_new") {
          if (
            a.autoanalysis_data &&
            a.autoanalysis_data.missing_tests &&
            a.autoanalysis_data.missing_tests.length > 0
          ) {
            return true;
          }
        }
        if (analysisFilter === "classic") {
          if (!a.autoanalysis_data) {
            return true;
          }
        }
      });
    }

    return (
      <div className="ml3 pa3">
        <h1>
          {this.props.type && this.props.type === "collection"
            ? "Collections"
            : "Analyses"}
        </h1>
        <div className="pt3 flex">
          {/* <input
            type="text"
            placeholder="search"
            className={cx("pa2 w-70")}
            name="searchString"
            value={searchString}
            onChange={this.handleKeyUp}
          /> */}
          <CreatableSelect
            options={this.state.allTags.map(item => ({
              label: capitalize(item),
              value: item,
            }))}
            placeholder="Search"
            onChange={this.onChangeAddTag}
            className="pa2 w-70"
            isClearable
            formatCreateLabel={this.formatCreateLabel}
            backspaceRemovesValue={true}
            filterOption={this.filterSearchOption}
            isValidNewOption={this.isValidNewOption}
            createOptionPosition="first"
            isMulti
          />
          {this.props.type === "analysis" && (
            <select
              name="filter"
              onChange={this.changeAnalysisFilter}
              className="ml3"
            >
              <option value="all">All Analyses</option>
              <option value="classic">Classic Analyses</option>
              <option value="auto">Auto Analyses</option>
              <option value="auto_new">Auto Analyses w/ New Tests</option>
            </select>
          )}
        </div>
        {!this.state.addNew ? (
          <div className="mt3">
            <a href=" #" onClick={this.showAddNew} className="f6 b">
              Add New
            </a>
          </div>
        ) : (
          <CreateEditCollection
            user={this.props.user}
            doClose={(editing, action, data) => {
              this.setState({ addNew: false, copyCollection: null });
              if (action !== "cancel") {
                setTimeout(() => {
                  this.getData();
                }, 1000);
              }
            }}
            editing={false}
            allAnalysis={[...collections, ...analysis]}
            type={this.props.type || "collection"}
            parentType="library"
            copyFrom={this.state.copyCollection}
            allCustomers={customers}
            allTags={this.state.allTags}
          />
        )}
        <div
          className={cx("flex  pointer hide-child bb b--divider")}
          key="header"
        >
          <h5
            className="pt3 center tl w-35 f6 ttu sb"
            onClick={this.changeSort("name")}
          >
            Name{" "}
            {sort.field === "name" ? (
              <img
                src={sort.order === "asc" ? sortAsc : sortDesc}
                height={16}
                className="ma2"
              />
            ) : (
              <img src={noSort} height={16} className="ma2" />
            )}
          </h5>
          {this.props.type && this.props.type === "analysis" && (
            <>
              <h5
                className="pt3 center tr w-5 f6 ttu sb"
                onClick={this.changeSort("verified")}
              >
                <img
                  src={VerifiedBlueIcon}
                  alt="Verified Analysis"
                  className="icon tc "
                  height="24"
                />
                {sort.field === "verified" ? (
                  <img
                    src={sort.order === "asc" ? sortAsc : sortDesc}
                    height={16}
                    className="ma2"
                  />
                ) : (
                  <img src={noSort} height={16} className="ma2" />
                )}
              </h5>
              <h5 className="pt3 center tc w-5 f6 ttu sb">
                <span className="dib" style={{ paddingTop: "8px" }}>
                  TRR
                </span>
              </h5>
            </>
          )}
          <h5
            className="pt3 center tc w-5 f6 ttu sb"
            onClick={this.changeSort("no_of_tests")}
          >
            #
            {sort.field === "no_of_tests" ? (
              <img
                src={sort.order === "asc" ? sortAsc : sortDesc}
                height={16}
                className="ma2"
              />
            ) : (
              <img src={noSort} height={16} className="ma2" />
            )}
          </h5>
          {this.props.type && this.props.type === "analysis" && (
            <>
              <h5 className="pt3 center tc w-10 f6 ttu sb">
                <span className="dib" style={{ paddingTop: "8px" }}>
                  Winner
                </span>
              </h5>
              <h5
                className="pt3 center tl w-10 f6 ttu sb"
                onClick={this.changeSort("selected_bet_score")}
              >
                BetScore
                {sort.field === "selected_bet_score" ? (
                  <img
                    src={sort.order === "asc" ? sortAsc : sortDesc}
                    height={16}
                    className="ma2"
                  />
                ) : (
                  <img src={noSort} height={16} className="ma2" />
                )}
              </h5>
            </>
          )}
          <h5
            className="pt3 center tc w-15 f6 ttu sb"
            onClick={this.changeSort("last_modified_by.name")}
          >
            Modified by
            {sort.field === "last_modified_by.name" ? (
              <img
                src={sort.order === "asc" ? sortAsc : sortDesc}
                height={16}
                className="ma2"
              />
            ) : (
              <img src={noSort} height={16} className="ma2" />
            )}
          </h5>
          <h5
            className="pt3 center tc w-15 f6 ttu sb"
            onClick={this.changeSort("last_modified")}
          >
            Modified on{" "}
            {sort.field === "last_modified" ? (
              <img
                src={sort.order === "asc" ? sortAsc : sortDesc}
                height={16}
                className="ma2"
              />
            ) : (
              <img src={noSort} height={16} className="ma2" />
            )}
          </h5>
          <h5
            className="pt3 center tc w-15 f6 ttu sb"
            onClick={this.changeSort("ctime")}
          >
            Created on
            {sort.field === "ctime" ? (
              <img
                src={sort.order === "asc" ? sortAsc : sortDesc}
                height={16}
                className="ma2"
              />
            ) : (
              <img src={noSort} height={16} className="ma2" />
            )}
          </h5>
          <h5 className="pt3 center tc w-10 f6 ttu sb"></h5>
        </div>
        {data.map((c, index) => {
          // console.info(c);
          if (c.id === this.state.editCollectionId) {
            return (
              <CreateEditCollection
                user={this.props.user}
                doClose={(editing, action, data) => {
                  this.setState({
                    editCollectionId: null,
                  });
                  if (action !== "cancel") {
                    setTimeout(() => {
                      this.getData();
                    }, 1000);
                  }
                }}
                currentAnalysis={c}
                editing={true}
                allAnalysis={[...collections, ...analysis]}
                type={this.props.type || "collection"}
                parentType="library"
                inline
                key={c.id}
                allTags={this.state.allTags}
              />
            );
          } else {
            return (
              <div
                className={cx(
                  "flex  pointer hide-child bb b--divider grow-icon-parent",
                  {
                    "test-box": index % 2 === 0,
                    "test-box-odd": index % 2 !== 0,
                  }
                )}
                onClick={this.handleEditCompany(c.id)}
                key={c.id}
              >
                <h6 className="pt3 tl w-30 flex">
                  <a
                    href={
                      this.props.type === "analysis"
                        ? `/home/admin/report?analysis=${c.id}&onlyAnalysis=true`
                        : `/home/admin/report?collection=${c.id}`
                    }
                    target="_blank"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    className="flex"
                  >
                    {c.autoanalysis_data && (
                      <img src={AutoIcon} className="pr2" />
                    )}
                    <div>{c.name}</div>
                  </a>
                </h6>
                {this.props.type === "analysis" && (
                  <div className="pt3 tc w-5 sb">
                    {c.autoanalysis_data &&
                      c.autoanalysis_data.missing_tests &&
                      c.autoanalysis_data.missing_tests.length > 0 && (
                        <a
                          target="_blank"
                          href={`/home/admin/report?analysis=${c.id}&onlyAnalysis=true&loadNewTests=true`}
                          className="dark-green"
                        >
                          {c.autoanalysis_data.missing_tests.length} New
                        </a>
                      )}
                  </div>
                )}
                {this.props.type && this.props.type === "analysis" && (
                  <>
                    <div className="pt3 tc w-5">
                      <h6 onClick={this.handleEditCompany(c.id)}>
                        <img
                          src={c.verified ? VerifiedBlueIcon : VerifiedGrayIcon}
                          alt="Verified Analysis"
                          className="icon tc "
                          height="24"
                          onClick={this.handleEditCompany(c.id)}
                        />
                      </h6>
                    </div>
                    <div className="pt3 tc w-5">
                      <h6 className="tc">
                        {c.trr && c.trr.trim() !== "" && (
                          <a
                            href={c.trr ? c.trr : "#"}
                            target="_blank"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            TRR
                            <img
                              src={OpenLinkIcon}
                              className="icon"
                              width="12"
                              style={{ marginTop: "-8px", marginLeft: "4px" }}
                            />
                          </a>
                        )}
                      </h6>
                    </div>
                  </>
                )}
                <h6 className="pt3 tc w-5">
                  <a
                    target="_blank"
                    href={
                      this.props.type === "analysis"
                        ? `/home/admin/report?analysis=${c.id}&onlyAnalysis=true`
                        : `/home/admin/report?collection=${c.id}`
                    }
                  >
                    {c.no_of_tests || "0"}
                  </a>
                </h6>
                {this.props.type && this.props.type === "analysis" && (
                  <>
                    <h6 className="pt3 center tc w-10">
                      {c.selected_property_name || ""}
                    </h6>
                    <h6 className="pt3 center tc w-10">
                      {c.selected_bet_score && (
                        <>
                          <span
                            className={cx("ph2", {
                              b: true,
                              "dark-green": c.selected_bet_score >= 80,
                              green:
                                c.selected_bet_score >= 60 &&
                                c.selected_bet_score < 80,
                              gray:
                                c.selected_bet_score >= 40 &&
                                c.selected_bet_score < 60,
                              yellow:
                                c.selected_bet_score >= 20 &&
                                c.selected_bet_score < 40,
                              red: c.selected_bet_score < 20,
                            })}
                            title={getBetScoreName(c.selected_bet_score)}
                          >
                            {Math.round(c.selected_bet_score)}
                          </span>
                        </>
                      )}
                    </h6>
                  </>
                )}
                <h6 className="pt3  tc  w-15">
                  {c.last_modified_by ? c.last_modified_by.name : ""}
                </h6>
                <h6 className="pt3  tc  w-15">
                  {c.last_modified ? formatReportDate(c.last_modified) : ""}
                </h6>
                <h6 className="pt3  tc w-15">{formatReportDate(c.ctime)}</h6>
                <div className="flex w-10 justify-around items-center">
                  <img
                    src={ExpandIcon}
                    alt="edit collection"
                    className="icon tc grow-icon"
                    height="24"
                    onClick={this.handleEditCompany(c.id)}
                  />
                  <img
                    src={copyIcon}
                    alt="edit collection"
                    className="icon  tc "
                    height="24"
                    onClick={this.handleCopyCollection(c)}
                  />
                  <img
                    src={DeleteIcon}
                    alt="delete collection"
                    className="icon tc"
                    height={24}
                    onClick={this.deleteAnalysis(c.id, c.name)}
                  />
                  {this.props.type === "collection" && (
                    <a
                      href={`/home/admin/related?collection=${c.id}`}
                      target="_blank"
                    >
                      <img
                        src={robotIcon}
                        alt="show related tests"
                        className="icon tc"
                        height={24}
                      />
                    </a>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default Collections;
