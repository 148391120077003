import React, { useState } from "react";
import request from "request";

import {
  baseApiUrl,
  getData,
  formatDate,
  formatReportDate,
  CHALLENGES,
  correctScreenshotsWeight,
  generateNoteText,
} from "../../util";
import {
  saveReport,
  getScreenshotData,
  getScreenshots,
  getServerMTimeForTest,
} from "./helpers";
import * as querystring from "query-string";
import * as cx from "classnames";
import DownArrow from "../../icons/old/down_arrow.svg";
import UpArrow from "../../icons/old/up_arrow.svg";
import TextInput from "../../components/form/TextInput";
import Upload from "../../components/form/Upload";
import CopyIcon from "../../icons/copy.svg";
import NewEditableAutocomplete from "../../components/NewEditableAutocomplete";
import NewEditableAutocompleteV2 from "../../components/NewEditableAutocompleteV2";
import NewEditableAutocompleteHeadline from "../../components/NewEditableAutocompleteHeadline";
import Loading from "../../components/Loading";
import X from "../../icons/red_x.svg";
import EditIcon from "../../icons/edit_blue.svg";
import SaveIcon from "../../icons/save.svg";
import DeleteIcon from "../../icons/delete_red.svg";
import UndoIcon from "../../icons/undo.svg";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Droppable as DDroppable } from "./components/Droppable";
import { DDndContext } from "./components/DDndContext";
import { DraggableScreenshot } from "./components/DraggableScreenshot";
import { Modal } from "@material-ui/core";
const pageTypes = [
  "Content Page",
  "Donation Page",
  "Product Details Page",
  "Find Locations Page",
  "Gift Page",
  "Home Page",
  "How-it-works Page",
  "Insurance Page",
  "Menu Page",
  "Navigation",
  "Personalization / Quiz Page",
  "Pricing Page",
  "Quiz",
  "Refer a Friend",
  "Reviews Page",
  "Sales Page",
  "Search Results",
  "SEM Landing Page",
  "Shopping / Browse Page",
  "Shopping Cart",
  "Shopping Page",
  "Signup Page",
  "Sitewide",
  "Supplier Acquisition Page",
  "other",
];

export const status_map = {
  draft_review: "Draft",
  analyst_review: "Screener Feedback",
  analyst_feedback: "Writer Feedback",
  approval_review: "Approval",
  publish_review: "Publishing",
  draft_hold: "Hold",
  edit: "Edit",
  deleted: "Deleted",
  published: "Published",
  called_test_30_days: "Called before 30 days",
  called_test_rejected: "Called Test Rejected",
  followup: "Followup",
  live: "Live",
};
const EditableNote = ({
  noteData,
  index,
  isEditable,
  onNoteSave,
  data,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setDeleted] = useState(
    noteData.deleted ? noteData.deleted : false
  );
  const [note, setNote] = useState(
    noteData && noteData.note ? noteData.note : null
  );
  const getNoteText = (noteData, data) => {
    if (!noteData) {
      return "";
    }
    return `${generateNoteText(noteData, data)} ${
      noteData.user ? `-${noteData.user.name}` || `` : ``
    } ${noteData.mtime ? formatReportDate(noteData.mtime) : ``}`;
  };

  const [noteText, setNoteText] = useState(getNoteText(noteData, data));
  const setEditable = isEditable => e => {
    setIsEditing(isEditable);
  };
  const onDeleteNote = e => {
    setIsEditing(false);
    onNoteSave(
      {
        ...noteData,
        deleted: true,
      },
      index
    );
  };
  const onEditNote = e => {
    if (e.target.value.trim() !== "") {
      setNote(e.target.value);
    }
  };
  const saveNote = e => {
    e.preventDefault();
    if (note.trim() === "") {
      alert("note can not be emply, please add note or revert to original");
      return;
    } else {
      setIsEditing(false);
      setNoteText(
        getNoteText({
          ...noteData,
          note,
          mtime: new Date().toISOString(),
        })
      );
      onNoteSave(
        {
          ...noteData,
          note,
        },
        index
      );
    }
  };
  const revertToOriginal = e => {
    setNote(noteData.originalNote);
    const revertedNote = {
      ...noteData,
      note: noteData.originalNote,
    };
    if (revertedNote.deleted) {
      delete revertedNote.deleted;
    }
    onNoteSave(revertedNote, index);
    setNoteText(getNoteText(noteData));
    setIsEditing(true);
  };
  return (
    <div
      className={cx("flex pa1  justify-between ", {
        dim: !isEditing,
        "items-center": !isEditing,
      })}
    >
      {isEditing ? (
        <>
          <p className="mb0 w-80">
            <textarea name="note" onChange={onEditNote} cols={45}>
              {note}
            </textarea>
          </p>
          <div className="flex justify-between w-20">
            <img src={SaveIcon} className="pointer pa3" onClick={saveNote} />
            <img
              src={DeleteIcon}
              className="pointer pa3"
              onClick={onDeleteNote}
            />
          </div>
        </>
      ) : (
        <>
          <p
            className={cx("mb0", {
              strike: noteData.deleted ? noteData.deleted : false,
            })}
          >
            {noteText}
          </p>
          {isEditable && (
            <div className="flex justify-between w-20">
              <img
                src={UndoIcon}
                className="pointer pa3"
                onClick={revertToOriginal}
              />
              <img
                src={EditIcon}
                className="pointer"
                onClick={setEditable(true)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
EditableNote.propTypes = {
  noteData: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  index: PropTypes.number,
  isEditable: PropTypes.bool,
  onNoteSave: PropTypes.func,
  data: PropTypes.object,
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  // styles we need to apply on draggables
  ...draggableStyle,
});

class AddScreenshots extends React.Component {
  state = {
    screenshots: [],
    company: "",
    company_name: "",
    url: "",
    reports: [],
    childReports: [],
    report: null,
    status: "live",
    scope: "m",
    type: "desktop",
    page_type: [],
    page_type_other: "",
    awesomeness: 3,
    description: "",
    summary: "",
    headline_tag: null,
    module_name: [],
    serverNoteTags: [],
    tags: [],
    notes: "",
    notes_v2: [],
    defaultTags: [],
    editing: this.props.match.params.id ? true : false,
    showPageTypeOther: false,
    companies: [],
    allReports: [],
    showAddReport: false,
    start_date: null,
    end_date: null,
    mtime: new Date(),
    highlight: false,
    doLoadedThing: true,
    global_no_control: false,
    posN: 0,
    related_test: null,
    sidebyside: true,
    isFetchingRelatedTest: false,
    fetchedRelatedTest: null,
    showFetchedRelatedTest: false,
    errorFetchRelatedTest: null,
    showNoteDeleteDialog: false,
  };
  findCompanyName = (companies, tld, url) => {
    if (tld === "co.uk") {
      tld = url.split("/")[2].split(".").slice(-3).join(".");
    }
    if (tld === "com.au") {
      tld = url.split("/")[2].split(".").slice(-3).join(".");
    }
    const real_url = url.split("/").slice(2).join("/").split("?")[0];
    let name_item_url = companies.find(c => {
      return c.tld && real_url.startsWith(c.tld);
    });
    if (!name_item_url) {
      name_item_url = companies.find(c => c.tld === tld);
    }
    if (!name_item_url) {
      name_item_url = companies.find(
        c => c.tld && c.tld.split("?")[0] === real_url
      );
    }
    if (name_item_url) {
      return name_item_url.name;
    }

    return "";
  };
  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const screenshots = reorder(
      this.state.screenshots,
      result.source.index,
      result.destination.index
    );

    this.setState({
      screenshots,
    });
  };
  visibleEvent = () => {
    if (!document.hidden && this.state.allLoaded) {
      this.setState({ doLoadedThing: false });
      setTimeout(() => {
        this.setState({ doLoadedThing: true });
      }, 50);
    }
  };
  getTags = () => {
    request(
      {
        url: `${baseApiUrl}/v1/tags/filter?text=*`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, resp, data) => {
        if (err || resp.statusCode >= 400) {
          setTimeout(this.getTags, 100);
          return;
        }

        const json = JSON.parse(data);
        this.setState({
          serverNoteTags: json,
        });
      }
    );
  };
  getRelatedTest = () => {
    const { url, related_test, showFetchedRelatedTest } = this.state;
    if (related_test) return;
    if (!url) {
      this.setState({
        isFetchingRelatedTest: false,
        showFetchedRelatedTest: false,
        errorFetchRelatedTest: "missing input",
      });
      return;
    }
    this.setState({
      isFetchingRelatedTest: true,
      showFetchedRelatedTest: true,
      errorFetchRelatedTest: null,
    });
    request(
      {
        url: `${baseApiUrl}/v1/get_related_test?url=${encodeURIComponent(url)}`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, resp, data) => {
        if (err || resp.statusCode >= 400) {
          setTimeout(this.getTags, 100);
          return;
        }
        const json = JSON.parse(data);
        this.setState({
          fetchedRelatedTest:
            json.data && json.data.length === 1 ? json.data[0] : null,
          isFetchingRelatedTest: false,
        });
      }
    );
  };
  onRelatedTestSelected = selectedTest => e => {
    if (selectedTest) {
      this.setState({
        related_test: selectedTest.id,
        showFetchedRelatedTest: false,
      });
    } else {
      this.setState({
        showFetchedRelatedTest: false,
        errorFetchRelatedTest: null,
      });
    }
  };
  componentDidMount() {
    const { id, ctime, company } = querystring.parse(window.location.search);
    getData(
      {
        url: `${baseApiUrl}/admin/reports/list`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      true
    ).then(({ err, data }) => {
      if (!err) {
        this.setState({
          allReports: data.reports.sort((a, b) => {
            return `${a.name}`.toLowerCase() > `${b.name}`.toLowerCase()
              ? 1
              : -1;
          }),
        });
      }
    });
    this.getTags();
    getData(
      {
        url: `${baseApiUrl}/v1/admin/companies`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      true
    ).then(({ err, data }) => {
      if (err) {
        this.setState({
          loading: false,
        });
        return;
      }

      if (this.state.editing) {
        getScreenshotData(
          this.props.match.params.id,
          this.props.user.token,
          (err, resp, json) => {
            const {
              url,
              company,
              company_name,
              report,
              childReports,
              screenshots,
              status,
              scope,
              type,
              page_type,
              awesomeness,
              description,
              summary,
              tags,
              notes,
              notes_v2,
              start_date,
              end_date,
              mtime,
              headline_tag,
              challenge_v2,
              highlight,
              related_test,
              q_status,
              pub_status,
              no_winner,
              last_seen_live,
              potential_end_date,
              potential_winner,
              potential_winner_other,
            } = json.screenshots;
            // let pageType = page_type;
            let showPageTypeOther = false;
            let page_type_other = "";

            // if (!page_type) {
            //   pageType = pageTypes[0];
            // }

            // if (page_type && !pageTypes.includes(page_type)) {
            //   showPageTypeOther = true;
            //   pageType = "other";
            //   page_type_other = page_type;
            // }

            let varNum = 0;

            screenshots.forEach((ss, i) => {
              //ss.id = `ss_${i}`;
              if (!ss.control) {
                varNum += 1;
                ss.varNum = varNum;
              }
              if (!ss.notes) {
                ss.notes = [""];
              }
              if (!ss.posXY) {
                ss.posXY = [""];
              }
            });
            const mTime = Date.parse(mtime);
            const savedState = JSON.parse(
              localStorage.getItem(encodeURI(window.location.pathname)) || null
            );
            const savedMTime =
              savedState && savedState.mtime
                ? Date.parse(savedState.mtime)
                : false;
            if (savedMTime && savedMTime > mTime) {
              this.setState({
                ...savedState,
                page_type:
                  typeof savedState.page_type === "string"
                    ? [savedState.page_type]
                    : savedState.page_type,
                module_name:
                  typeof savedState.module_name === "string"
                    ? [savedState.module_name]
                    : savedState.module_name,
                loading: false,
                authed: true,
                mtime: new Date(savedState.mtime),
                serverMtime: mtime,
                companies: data.companies,
              });
            } else {
              const notesV2 = Array.isArray(notes_v2)
                ? notes_v2.map(noteData => {
                    if (typeof noteData === "string") {
                      return {
                        note: noteData,
                        mtime: null,
                        user: null,
                        originalNote: noteData,
                      };
                    } else {
                      return {
                        ...noteData,
                        originalNote: noteData.note,
                      };
                    }
                  })
                : notes_v2;
              let last_seen;
              if (last_seen_live && last_seen_live.trim() !== "") {
                last_seen = "last-seen";
              } else if (
                potential_end_date &&
                potential_end_date.trim() != ""
              ) {
                last_seen = "may-have-ended";
              }
              this.setState({
                loading: false,
                authed: true,
                screenshots,
                url,
                company,
                company_name,
                headline_tag,
                status: status || this.state.status,
                scope: scope || this.state.scope,
                type: type || this.state.type,
                awesomeness: awesomeness || this.state.awesomeness,
                description,
                summary,
                reports: json.reports.sort((a, b) => {
                  if (a.name.toLowerCase() >= b.name.toLowerCase()) {
                    return 1;
                  } else {
                    return -1;
                  }
                }),
                childReports: childReports || [],
                companies: data.companies,
                report,
                tags,
                mtime: new Date(mtime),
                // notes:"",
                notes_v2: notesV2,
                start_date,
                end_date,
                module_name: challenge_v2 || [],
                page_type,
                showPageTypeOther,
                page_type_other,
                q_status,
                pub_status,
                global_no_control: screenshots.find(s => s.control)
                  ? false
                  : true,
                global_no_winner: no_winner,
                defaultTags:
                  (tags && tags.map(tag => ({ value: tag, label: tag }))) || [],
                defaultHeadlineTag: headline_tag || null,
                highlight,
                related_test: related_test !== "later" ? related_test : null,
                potential_end_date,
                last_seen_live,
                potential_winner,
                potential_winner_other:
                  potential_winner && potential_winner.trim() === "other"
                    ? potential_winner_other
                    : null,
                last_seen,
                serverMtime: mtime,
              });
            }
            this.clearLocalStorageTimer();
            this.clearRefreshServerMTime();
            this.setupLocalStorageTimer(encodeURI(window.location.pathname));
            this.setupRefetchServerMTime();
          },
          true
        );
      } else {
        if (id && ctime) {
          getScreenshots(id, ctime, json => {
            let varNum = 0;
            json.items.forEach((ss, i) => {
              ss.id = `ss_${i}`;
              if (!ss.control) {
                varNum += 1;
                ss.varNum = varNum;
              }
              if (!ss.notes) {
                ss.notes = [""];
              }
              if (!ss.posXY) {
                ss.posXY = [""];
              }
            });

            this.setState({
              screenshots: correctScreenshotsWeight(json.items),
              url: json.screens.url,
              company_name: this.findCompanyName(
                data.companies,
                json.screens.tld,
                json.screens.url
              ),
              type: json.screens.isMobile ? "mobile" : "desktop",
              company,
              companies: data.companies,
              ssUrl: json.url,
              page_type: json.screens.landingPage ? ["SEM Landing Page"] : [],
            });
          });
        }
        request(
          {
            url: `${baseApiUrl}/reports?company=${company}`,
            headers: {
              Authorization: this.props.user.token,
            },
          },
          (err, resp, data) => {
            if (err || resp.statusCode >= 400) {
              return;
            }
            const reports = JSON.parse(data).reports;
            reports.sort((a, b) => {
              if (a.name.toLowerCase() >= b.name.toLowerCase()) {
                return 1;
              } else {
                return -1;
              }
            });
            const savedState = JSON.parse(
              localStorage.getItem(`new-${id}`) || "{}"
            );
            this.setState({
              reports: reports,
              report: reports.length ? reports[0].id : undefined,
              ...savedState,
              mtime: new Date(savedState.mtime),
            });
            this.clearLocalStorageTimer();
            this.setupLocalStorageTimer(`new-${id}`);
          }
        );
      }
    });
    document.addEventListener("visibilitychange", this.visibleEvent);
  }
  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.visibleEvent);
    this.clearLocalStorageTimer();
  }
  setupLocalStorageTimer = key => {
    this.clearLocalStorageTimer();
    this.saveStateTimer = setInterval(() => {
      this.setState(
        {
          isSavingToLocalStorage: true,
          localStorageKey: key,
        },
        () => {
          this.saveStateToLocalStorage(this.state, key);
        }
      );
      setTimeout(() => {
        this.setState({
          isSavingToLocalStorage: false,
        });
      }, 1000);
    }, 30 * 1000);
  };
  clearLocalStorageTimer = () => {
    if (this.saveStateTimer) {
      clearInterval(this.saveStateTimer);
    }
  };
  setupRefetchServerMTime = () => {
    this.clearRefreshServerMTime();
    this.fetchServerMtime = setInterval(() => {
      getServerMTimeForTest(
        this.props.match.params.id,
        this.props.user.token,
        (err, res, latestServerMTime) => {
          const { serverMtime } = this.state;
          if (
            latestServerMTime &&
            serverMtime &&
            serverMtime < latestServerMTime
          ) {
            this.setState({
              serverHasNewData: true,
            });
          } else {
            this.setState({
              serverHasNewData: false,
            });
          }
        }
      );
    }, 600 * 1000);
  };
  clearRefreshServerMTime = () => {
    if (this.fetchServerMtime) {
      clearInterval(this.fetchServerMtime);
    }
  };
  interval = null;
  imgLoaded = 0;
  saveStateTimer = null;
  fetchServerMtime = null;
  onLoad = () => {
    this.imgLoaded++;

    if (
      !this.state.allLoaded &&
      this.imgLoaded >= this.state.screenshots.length
    ) {
      this.setState(
        {
          allLoaded: true,
        },
        () => {
          clearInterval(this.interval);
          this.interval = setTimeout(() => {
            this.recalculatePoses(true);
          }, 250);
        }
      );
    }
  };
  setTags = tags => {
    this.setState(
      {
        tags,
      },
      this.getTags
    );
  };
  setNoteTag = (i, n) => tag => {
    const { tags, screenshots } = this.state;
    const hasTag = tags.find(t => t === tag.value);

    if (!hasTag) {
      tags.push(tag.value);
    }

    const defaultTags = tags.map(t => ({ label: t, value: t }));

    if (!screenshots[i].tags) {
      screenshots[i].tags = {};
    }
    if (screenshots[i].tags[n]) {
      screenshots[i].tags[n] = tag.value;
    } else {
      screenshots[i].tags[n] = tag.value;
    }
    this.setState({ defaultTags, screenshots, tags }, this.getTags);
  };
  setHeadlineTag = headline_tag => {
    this.setState(
      {
        headline_tag,
      },
      this.getTags
    );
  };
  delete = e => {
    if (window.confirm("Are you sure you want to delete?")) {
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: this.props.user.token,
          },
          url: `${baseApiUrl}/screenshot/delete`,
          form: {
            id: this.props.match.params.id,
          },
        },
        (err, resp) => {
          if (err || resp.statusCode >= 400) {
            window.scrollTo(0, 0);
            return;
          }
          this.props.history.push(`/home/admin/report`);
        }
      );
    }
  };
  submit = e => {
    const {
      start_date,
      end_date,
      module_name,
      company_name,
      related_test,
      screenshots,
      page_type,
      last_seen_live,
      potential_end_date,
      last_seen,
      potential_winner,
      potential_winner_other,
      editing,
      loading,
    } = this.state;
    if (loading) {
      return;
    }
    if (!this.state.report || this.state.report === "") {
      alert("Pick a report to send to!");
      return;
    }
    if (!company_name || company_name === "") {
      const elm = document.getElementById("company_name");
      window.scroll(0, elm.offsetTop);
      alert(
        "No company chosen. You might need to add a new company into the company admin."
      );
      return;
    }
    if (!start_date || (start_date && end_date && start_date > end_date)) {
      const elm = document.getElementById("date-pickers");
      window.scroll(0, elm.offsetTop);
      alert("Please make sure the start date and end date are added correctly");
      return;
    }
    let noControl = screenshots.find(s => s.control);
    let hasWinner = screenshots.find(s => s.champ);

    if (end_date && !hasWinner && !this.state.global_no_winner) {
      const elm = document.getElementById("screenshots");
      window.scroll(0, elm.offsetTop);
      alert("Please pick a winner or indicate there is no winner");
      return;
    }
    if (!noControl && !this.state.global_no_control) {
      const elm = document.getElementById("screenshots");
      window.scroll(0, elm.offsetTop);
      alert("Please indicate if control or if there is no control.");
      return;
    }

    let screenshotsDone =
      screenshots.filter(ss => ss.selected).length > 1 ? true : false;

    if (!screenshotsDone) {
      const elm = document.getElementById("screenshots");
      window.scroll(0, elm.offsetTop);
      alert("Looks like there are less than 2 screenshots selected.");
      return;
    }

    screenshots.forEach(ss => {
      if (screenshotsDone) {
        if (ss.selected) {
          if (!ss.weight || ss.weight === "" || !ss.notes) {
            screenshotsDone = false;
          }
          if (ss.notes) {
            ss.notes.forEach((n, i) => {
              if (n === "" || !ss.posXY || !ss.posXY[i] || ss.posXY[i] === "") {
                screenshotsDone = false;
              }
            });
          }
        }
      }
    });
    if (!screenshotsDone) {
      const elm = document.getElementById("screenshots");
      window.scroll(0, elm.offsetTop);
      alert("Looks like the screenshots are missing some information");
      return;
    }
    if (!related_test) {
      const elm = document.getElementById("related_test");
      window.scroll(0, elm.offsetTop);
      alert("Please choose a related test or none");
      return;
    }
    if (this.state.id && related_test === id) {
      const elm = document.getElementById("related_test");
      window.scroll(0, elm.offsetTop);
      alert("Related test is this test, it should be a different test!");
      return;
    }
    if (!editing && !last_seen) {
      alert("Add last seen live or potential end date for this test");
      return;
    }
    if (
      last_seen &&
      last_seen === "last-seen" &&
      (!last_seen_live || last_seen_live.trim() === "")
    ) {
      alert("Select last seen live date");
      return;
    }
    if (
      last_seen &&
      last_seen === "may-have-ended" &&
      (!potential_end_date || potential_end_date.trim() === "")
    ) {
      alert("Select potential end date");
      return;
    }
    if (
      last_seen &&
      last_seen === "may-have-ended" &&
      (!potential_winner || potential_winner.trim() === "")
    ) {
      alert(`Please pick a potential winner or indicate there is no winner`);
      return;
    }
    if (
      last_seen &&
      last_seen === "may-have-ended" &&
      potential_winner &&
      potential_winner.trim() !== "" &&
      potential_winner.trim() !== "no-winner" &&
      potential_winner.trim() !== "other"
    ) {
      const screenshotSelected = screenshots.find(
        ss => ss.id === potential_winner
      );
      if (!screenshotSelected) {
        alert(`Please pick a potential winner or indicate there is no winner`);
        return;
      }
    }
    if (
      potential_winner &&
      potential_winner.trim() === "other" &&
      (!potential_winner_other || potential_winner_other.trim() === "")
    ) {
      alert(`Please fill this out`);
      return;
    }
    this.setState({
      loading: true,
    });
    const { back, qid } = querystring.parse(window.location.search);
    setTimeout(() => {
      saveReport(
        this.state,
        this.props.user.token,
        this.props.match.params.id,
        qid,
        (err, resp) => {
          if (err || resp.statusCode >= 400) {
            this.setState({
              error: true,
              loading: false,
            });
            window.scrollTo(0, 0);
            return;
          }
          const json = JSON.parse(resp.body);
          this.clearLocalStorageTimer();
          this.clearStateFromLocalStorage(this.state.localStorageKey, true);
          setTimeout(() => {
            this.props.history.push(`/home/admin/screenshots/${json.id}`);
          }, 5000);
        }
      );
    }, 1500);
  };
  select = (i, selected) => {
    const screenshots = this.state.screenshots;
    screenshots[i].selected = selected;
    this.setState({
      screenshots: screenshots,
    });
  };
  updateWeight = (i, weight) => {
    const screenshots = this.state.screenshots;
    screenshots[i].weight = weight;
    this.setState({
      screenshots: screenshots,
    });
  };
  updateChamp = (i, champ, event) => {
    const { screenshots, global_no_winner } = this.state;
    if (champ) {
      let otherWinner = screenshots.find((s, index) => s.champ && index !== i);
      if (global_no_winner) {
        const elm = document.getElementById("screenshots");
        window.scroll(0, elm.offsetTop);
        alert(`"No winner" is selected, please uncheck to select a winner.`);
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if (otherWinner) {
        const elm = document.getElementById("screenshots");
        window.scroll(0, elm.offsetTop);
        alert(
          "You've already picked a winner, please uncheck it to select a new one."
        );
        event.stopPropagation();
        event.preventDefault();
        return;
      }
    }
    screenshots[i].champ = champ;
    let varNum = 0;
    screenshots.forEach((ss, i) => {
      if (!ss.control && !ss.champ) {
        varNum += 1;
        ss.varNum = varNum;
      }
    });
    this.setState({
      screenshots: screenshots,
    });
  };
  updateControl = (i, control, event) => {
    const { screenshots, global_no_control } = this.state;

    if (control) {
      let otherControl = screenshots.find(
        (s, index) => s.control && index !== i
      );
      if (global_no_control) {
        const elm = document.getElementById("screenshots");
        window.scroll(0, elm.offsetTop);
        alert(`"No control" is selected, please uncheck to select a control.`);
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if (otherControl) {
        const elm = document.getElementById("screenshots");
        window.scroll(0, elm.offsetTop);
        alert(
          "You've already picked a control, please uncheck it to select a new one."
        );
        event.stopPropagation();
        event.preventDefault();
        return;
      }
    }
    screenshots[i].control = control;
    let varNum = 0;
    screenshots.forEach((ss, i) => {
      if (!ss.control && !ss.champ) {
        varNum += 1;
        ss.varNum = varNum;
      }
    });

    this.setState({
      screenshots: screenshots,
    });
  };
  updateNoControl = e => {
    if (e.currentTarget.checked) {
      const { screenshots } = this.state;
      let control = screenshots.find(s => s.control);

      if (control) {
        const elm = document.getElementById("screenshots");
        window.scroll(0, elm.offsetTop);
        alert("Please remove control from variants if there is no control.");
        return;
      }
    }

    this.setState({
      global_no_control: e.currentTarget.checked,
    });
  };
  updateNoWinner = e => {
    if (e.currentTarget.checked) {
      const { screenshots } = this.state;

      let hasWinner = screenshots.find(s => s.champ);

      if (hasWinner) {
        const elm = document.getElementById("screenshots");
        window.scroll(0, elm.offsetTop);
        alert("Please remove winner from variants if there is no winner.");
        return;
      }
    }
    this.setState({
      global_no_winner: e.currentTarget.checked,
    });
  };
  updatePos = (i, n) => e => {
    const screenshots = this.state.screenshots;
    const { value } = e.currentTarget;
    if (screenshots[i].pos) {
      screenshots[i].pos[n] = value;
    } else {
      screenshots[i].pos = [value];
    }
    this.setState({
      screenshots: screenshots,
    });
  };
  updateNotes = (i, n) => note => {
    //console.info("UPDATE!!!!!");
    const screenshots = this.state.screenshots;
    if (screenshots[i].notes) {
      screenshots[i].notes[n] = note;
    } else {
      screenshots[i].notes = [note];
    }
    this.setState(
      {
        screenshots: screenshots,
      },
      this.getTags
    );
  };
  showDeleteNoteAndPos = (i, n) => e => {
    this.setState({
      deleteNoteI: i,
      deleteNoteN: n,
      showNoteDeleteDialog: true,
    });
    e.preventDefault();
    e.stopPropagation();
  };
  deleteNoteAndPos = (deleteSingleNote = true, deleteAllNotes = false) => {
    const screenshots = this.state.screenshots;
    const { deleteNoteI: i, deleteNoteN: n } = this.state;
    if (i === undefined || i === null || n === undefined || n === null) {
      return;
    }
    if (deleteSingleNote && !deleteAllNotes) {
      const updatedScreenshots = deleteNoteForScreenshot(screenshots, i, n);

      this.setState({
        screenshots: updatedScreenshots,
        showNoteDeleteDialog: false,
        deleteNoteI: null,
        deleteNoteN: null,
      });
    } else if (!deleteSingleNote && deleteAllNotes) {
      let updatedScreenshots = screenshots;
      screenshots.forEach((screenshots, index) => {
        updatedScreenshots = deleteNoteForScreenshot(
          updatedScreenshots,
          index,
          n
        );
      });
      this.setState({
        screenshots: updatedScreenshots,
        showNoteDeleteDialog: false,
        deleteNoteI: null,
        deleteNoteN: null,
      });
    }

    function deleteNoteForScreenshot(screenshots, i, n) {
      delete screenshots[i].notes[n];
      if (screenshots[i].pos) {
        delete screenshots[i].pos[n];
      }
      if (screenshots[i].posXY) {
        delete screenshots[i].posXY[n];
      }

      screenshots[i].notes = screenshots[i].notes.filter(
        x => x !== null && x !== undefined
      );
      if (screenshots[i].pos) {
        screenshots[i].pos = screenshots[i].pos.filter(
          x => x !== null && x !== undefined
        );
      }
      if (screenshots[i].posXY) {
        screenshots[i].posXY = screenshots[i].posXY.filter(
          x => x !== null && x !== undefined
        );
      }
      return screenshots;
    }
  };
  addNote = i => e => {
    e.preventDefault();
    e.stopPropagation();
    const screenshots = this.state.screenshots;
    screenshots.forEach(ss => {
      if (ss) {
        if (!ss.notes) {
          ss.notes = [""];
          ss.posXY = [""];
        } else {
          ss.notes.push("");
          if (!ss.posXY) {
            ss.posXY = [""];
          }
          ss.posXY.push("");
        }
      }
    });
    this.setState({
      screenshots: screenshots,
    });
  };
  changeHide = (i, n) => e => {
    const screenshots = this.state.screenshots;
    if (screenshots[i].hide) {
      const newSS = [];
      screenshots[i].hide.forEach(x => {
        if (!newSS.includes(x)) {
          newSS.push(x);
        }
      });
      screenshots[i].hide = newSS;
    }
    if (e.currentTarget.checked) {
      if (screenshots[i].hide) {
        screenshots[i].hide.push(n);
      } else {
        screenshots[i].hide = [n];
      }
    } else if (screenshots[i].hide) {
      if (screenshots[i].hide.length === 1) {
        screenshots[i].hide = [];
      } else {
        screenshots[i].hide = screenshots[i].hide.filter(x => {
          return x !== n;
        });
      }
    }
    this.setState({
      screenshots: screenshots,
    });
  };
  onSelectPageType = (existing_page_type, i, isOther) => e => {
    // check if the current page type value is exist in state
    if (this.state.page_type.some(page_type => page_type === e.target.value)) {
      //window.scroll(0, e.target.offsetTop);
      alert("Page type already added!!");
      return false;
    }
    this.setState({
      page_type: this.state.page_type.map((item, index) =>
        i === index ? e.target.value : item
      ),
    });
  };
  onSelectChallenge = (existing_page_type, i, isOther) => e => {
    // check if the current challenge value is exist in state
    if (
      this.state.module_name.some(module_name => module_name === e.target.value)
    ) {
      //window.scroll(0, e.target.offsetTop);
      alert("challenge already added!!");
      return false;
    }
    this.setState({
      module_name: this.state.module_name.map((item, index) =>
        i === index ? e.target.value : item
      ),
    });
  };
  dateTO = null;
  checkDate = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    if (this.dateTO) {
      clearTimeout(this.dateTO);
    }

    this.dateTO = setTimeout(() => {
      const { start_date, end_date } = this.state;
      if (name === "start_date" && end_date && end_date < value) {
        alert("Please pick a start date before the end date!");
        this.setState({
          start_date: start_date,
        });
        return;
      }
      if (name === "end_date" && start_date && value < start_date) {
        alert("Please pick an end date after the start date!");
        this.setState({
          end_date: end_date,
        });
        return;
      }
    }, 500);
  };
  onKeyUp = e => {
    const { name, value } = e.target;
    if (name === "end_date") {
      if (value !== "") {
        this.setState({
          [name]: value,
          status: "ended",
        });
      } else {
        this.setState({
          [name]: value,
          status: "live",
        });
      }
    } else if (name === "page_type") {
      this.setState({
        [name]: this.state.page_type.includes(value)
          ? this.state.page_type
          : [...this.state.page_type, value],
      });
    } else if (name === "challenge") {
      this.setState({
        [name]: this.state.module_name.includes(value)
          ? this.state.module_name
          : [...this.state.module_name, value],
      });
    } else if (name === "last_seen_live") {
      if (value !== "") {
        this.setState({
          [name]: value,
        });
      } else {
        this.setState({
          [name]: value,
          last_seen: "",
        });
      }
    } else if (name === "potential_end_date") {
      if (value !== "") {
        this.setState({
          [name]: value,
        });
      } else {
        this.setState({
          [name]: value,
          last_seen: "",
          potential_winner: "",
          potential_winner_other: "",
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  onCopyChange = e => {
    const { name, checked } = e.currentTarget;

    this.setState({ copy: checked });
  };
  onUpload = file => {
    const screenshots = this.state.screenshots;
    const screenshotFiles = Array.isArray(file) ? file : [file];
    const newNotes = screenshots
      .map(ss => ss.notes)
      .reduce((acc, current) => {
        return acc.length > (current ? current.length : 0)
          ? acc || []
          : current || [];
      }, [])
      .map(n => "");

    screenshotFiles.forEach(f => {
      screenshots.push({
        src: f,
        weight: "",
        selected: true,
        notes: newNotes,
      });
    });
    let varNum = 0;

    screenshots.forEach((ss, i) => {
      if (!ss.id) {
        ss.id = `ss_${i}`;
      }
      if (!ss.control) {
        varNum += 1;
        ss.varNum = varNum;
      }
    });
    this.setState({
      screenshots: screenshots,
    });
  };
  onEditScreenshotImage = id => file => {
    const { screenshots } = this.state;
    this.setState(
      {
        screenshots: screenshots.map(ss => {
          if (ss.id == id) {
            return {
              ...ss,
              src: Array.isArray(file) ? file[0] : file,
              selected: true,
            };
          } else {
            return ss;
          }
        }),
      },
      () => {
        setTimeout(() => this.recalculatePoses(true), 200);
      }
    );
  };
  addAReport = e => {
    e.preventDefault();
    this.setState({
      showAddReport: true,
    });
  };
  doAddReport = e => {
    const childReports = this.state.childReports;
    const reportToPush = this.state.add_child_report
      ? this.state.add_child_report
      : this.state.allReports[0].id;
    childReports.push(reportToPush);
    this.setState({
      childReports,
    });
  };
  removeReport = id => e => {
    const childReports = this.state.childReports;
    const newChildReports = childReports.filter(r => r !== id);
    this.setState({
      childReports: newChildReports,
    });
  };
  moveUp = (i, n) => e => {
    e.preventDefault();
    const screenshots = Object.assign({}, this.state).screenshots;

    if (n === 0) {
      return;
    }
    if (this.suggestionBox[i] && this.suggestionBox[i][n]) {
      this.addSuggestionData(i, n - 1)(this.suggestionBox[i][n]);
      this.deleteSuggestionData(i, n)();
    }
    const note = screenshots[i].notes.splice(n, 1)[0];
    screenshots[i].notes.splice(n - 1, 0, note);
    if (screenshots[i].posXY) {
      const pos = screenshots[i].posXY.splice(n, 1)[0];
      screenshots[i].posXY.splice(n - 1, 0, pos);
    }
    if (screenshots[i].pos) {
      const pos = screenshots[i].pos.splice(n, 1)[0];
      screenshots[i].pos.splice(n - 1, 0, pos);
    }
    if (screenshots[i].foo && screenshots[i].foo[n]) {
      screenshots[i].foo[n]++;
    } else if (screenshots[i].foo) {
      screenshots[i].foo[n] = 1;
    } else {
      screenshots[i].foo = [];
      screenshots[i].foo[n] = 1;
    }
    if (screenshots[i].hide) {
      screenshots[i].hide.forEach((h, x) => {
        if (n === h) {
          screenshots[i].hide[x] = n - 1;
        }
      });
    }

    this.setState(
      {
        screenshots,
      },
      () => {}
    );
  };
  moveDown = (i, n) => e => {
    e.preventDefault();
    const screenshots = Object.assign({}, this.state).screenshots;

    if (n >= screenshots[i].notes.length - 1) {
      return;
    }
    if (this.suggestionBox[i] && this.suggestionBox[i][n]) {
      this.addSuggestionData(i, n + 1)(this.suggestionBox[i][n]);
      this.deleteSuggestionData(i, n)();
    }
    const note = screenshots[i].notes.splice(n, 1)[0];
    screenshots[i].notes.splice(n + 1, 0, note);
    if (screenshots[i].posXY) {
      const pos = screenshots[i].posXY.splice(n, 1)[0];
      screenshots[i].posXY.splice(n + 1, 0, pos);
    }
    if (screenshots[i].pos) {
      const pos = screenshots[i].pos.splice(n, 1)[0];
      screenshots[i].pos.splice(n + 1, 0, pos);
    }
    if (screenshots[i].foo && screenshots[i].foo[n]) {
      screenshots[i].foo[n]++;
    } else if (screenshots[i].foo) {
      screenshots[i].foo[n] = 1;
    } else {
      screenshots[i].foo = [];
      screenshots[i].foo[n] = 1;
    }

    if (screenshots[i].hide) {
      screenshots[i].hide.forEach((h, x) => {
        if (n === h) {
          screenshots[i].hide[x] = n + 1;
        }
      });
    }

    this.setState({
      screenshots,
    });
  };
  handleHighlight = e => {
    this.setState({
      highlight: !!e.currentTarget.checked,
    });
  };
  calculatePos = (rect, e) => {
    const origX = e.clientX - rect.x;
    const origY = e.clientY - rect.y;
    const x = rect.width / origX;
    const y = rect.height / origY;
    const wR = rect.width / rect.height;
    const hR = rect.height / rect.width;
    return { x, y, origX, origY, wR, hR };
  };
  calculateWidthAndHeight = rect => {
    const wR = rect.width / rect.height;
    const hR = rect.height / rect.width;
    return { wR, hR };
  };
  doFullPagePos = (i, n) => ev => {
    ev.preventDefault();
    const target = document.getElementById(`screenshot-${i}`);
    const rect = target.getBoundingClientRect();
    const e = { clientX: 0, clientY: 0 };
    const vals = this.calculatePos(rect, e);
    vals.x = 0;
    vals.y = 0;
    vals.origX = 0;
    vals.origY = 0;
    const screenshots = this.state.screenshots;
    let noPos = true;
    noPos = screenshots.find(s => s.posXY && s.posXY[n]);
    if (screenshots[i].posXY) {
      screenshots[i].posXY[n] = vals;
    } else {
      screenshots[i].posXY = [];
      screenshots[i].posXY[n] = vals;
    }
    if (!noPos) {
      screenshots.forEach((s, i) => {
        const newRect = document
          .getElementById(`screenshot-${i}`)
          .getBoundingClientRect();
        rect.height = newRect.height;
        rect.width = newRect.width;
        const newVals = this.calculatePos(rect, e);
        newVals.x = 0;
        newVals.y = 0;
        newVals.origX = 0;
        newVals.origY = 0;
        if (s.posXY) {
          s.posXY[n] = newVals;
        } else {
          s.posXY = [];
          s.posXY[n] = newVals;
        }
      });
    }
    this.setState({
      screenshots: screenshots,
      posI: i,
      posN: n,
    });
  };
  fixImages = e => {
    e.preventDefault();
    this.setState({ doLoadedThing: false });
    setTimeout(() => {
      this.setState({ doLoadedThing: true });
    }, 100);
  };
  recalculatePoses = doWrite => {
    const images = document.querySelectorAll(".pick-image-container");
    const screenshots = this.state.screenshots;

    screenshots.forEach((s, i) => {
      if (s.posXY) {
        const rect = images[i].querySelector("img").getBoundingClientRect();
        const { wR, hR } = this.calculateWidthAndHeight(rect);
        s.posXY.forEach(pos => {
          if (pos && pos !== "") {
            if (doWrite) {
              pos.wR = wR;
              pos.hR = hR;
            }
            pos.origY = rect.height / pos.y;
            pos.origX = rect.width / pos.x;
          }
        });
      }
    });
    this.setState({ screenshots });
  };
  handlePos = (i, n, selectPos) => e => {
    const { posI, posN } = this.state;

    if (posI !== i || (n !== undefined && posN !== n)) {
      if (selectPos) {
        this.setState({ posI: i, posN: n });
        return;
      }
      alert("Please select the correct screenshot.");
      return;
    }
    let target;
    if (e.currentTarget.tagName !== "IMG") {
      target = e.currentTarget.parentNode.querySelector("img");
    } else {
      target = e.currentTarget;
    }
    const rect = target.getBoundingClientRect();
    const vals = this.calculatePos(rect, e);
    const screenshots = this.state.screenshots;
    let noPos = true;
    noPos = screenshots.find(s => s.posXY && s.posXY[posN]);
    if (screenshots[i].posXY) {
      screenshots[i].posXY[posN] = vals;
    } else {
      screenshots[i].posXY = [];
      screenshots[i].posXY[posN] = vals;
    }
    if (!noPos) {
      screenshots.forEach((s, i) => {
        const newRect = document
          .getElementById(`screenshot-${i}`)
          .getBoundingClientRect();
        rect.height = newRect.height;
        rect.width = newRect.width;
        const newVals = this.calculatePos(rect, e);
        if (s.posXY) {
          s.posXY[posN] = newVals;
        } else {
          s.posXY = [];
          s.posXY[posN] = newVals;
        }
      });
    }
    this.setState({
      screenshots: screenshots,
    });
  };
  onRTChange = e => {
    const val = e.currentTarget.value;
    if (val === "none") {
      this.setState({ related_test: "none" });
    } else if (val === "later") {
      this.setState({ related_test: "later" });
    } else if (val === "on") {
      this.setState({ related_test: null });
    } else if (val.trim() !== "") {
      const id = val.split("?")[0].split("/").pop();
      if (this.props.match.params.id && this.props.match.params.id === id) {
        alert("This is the test you're editing!");
        return;
      }
      if (id.match(/-/g) && id.match(/-/g).length === 4) {
        this.setState({ related_test: id });
      } else {
        alert("Hmm.... doesn't look like a screenshot URL.");
        return;
      }
    } else {
      this.setState({ related_test: val });
    }
  };
  changePos = (i, n) => e => {
    this.setState({ posN: n, posI: i });
  };
  resetPos = (i, n) => e => {
    e.preventDefault();
    const pos = this.state.screenshots[i].posXY[n];

    this.state.screenshots.forEach((s, z) => {
      if (z !== i) {
        const target = document.getElementById(`screenshot-${z}`);
        const rect = target.getBoundingClientRect();

        const origX = pos.origX;
        const origY = pos.origY;
        const x = rect.width / origX;
        const y = rect.height / origY;
        const wR = rect.width / rect.height;
        const hR = rect.height / rect.width;
        s.posXY[n] = { x, y, origX, origY, wR, hR };
      }
    });

    this.setState({ screenshots: this.state.screenshots });
  };
  clearAllTags = e => {
    e.preventDefault();
    if (
      window.confirm(
        "This will remove all the tags on every screenshot. Are you sure?"
      )
    ) {
      const { screenshots } = this.state;
      screenshots.forEach(ss => {
        ss.notes = [];
        ss.posXY = [];
      });
      this.setState({ screenshots });
    }
  };
  scrollTimeout = null;
  scrollVertTimeout = null;
  dirtyTimeout = null;
  scrollDirty = false;
  scrollPos = e => {
    if (!this.scrollDirty) {
      const { scrollLeft, id } = e.currentTarget;
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }

      this.scrollTimeout = setTimeout(() => {
        this.scrollDirty = true;
        if (id === "image-box") {
          const elm = document.getElementById("screenshots");
          if (elm) {
            elm.scrollTo(scrollLeft * 0.735, elm.scrollTop);
          }
        } else {
          const elm = document.getElementById("image-box");
          if (elm) {
            elm.scrollTo(scrollLeft * 1.265, elm.scrollTop);
          }
        }
      }, 100);
      this.dirtyTimeout = setTimeout(() => {
        this.scrollDirty = false;
      }, 150);
    }
  };
  scrollPosVert = e => {
    if (this.scrollVertTimeout) {
      clearTimeout(this.scrollVertTimeout);
    }
    this.scrollVertTimeout = setTimeout(() => {
      const scrollTop = e.target.scrollTop;
      document.querySelectorAll(".image-scrolly").forEach(elm => {
        if (elm) {
          elm.scrollTo(0, scrollTop);
        }
      });
    }, 50);
  };
  switchView = view => e => {
    e.preventDefault();

    if (view === "sidebyside") {
      this.setState(
        { sidebyside: true },
        () => {
          this.recalculatePoses();
        },
        200
      );
    } else {
      this.setState(
        { sidebyside: false },
        () => {
          this.recalculatePoses();
        },
        200
      );
    }
  };
  // this is an object which keeps suggestions data for notes
  suggestionBox = {};
  addSuggestionData = (screenshotIndex, notesIndex) => data => {
    this.suggestionBox = {};
    this.suggestionBox[screenshotIndex] = {
      [notesIndex]: data,
    };
  };
  deleteSuggestionData = (screenshotIndex, notesIndex) => () => {
    if (
      this.suggestionBox[screenshotIndex] &&
      this.suggestionBox[screenshotIndex][notesIndex]
    )
      delete this.suggestionBox[screenshotIndex][notesIndex];
  };
  saveStateToLocalStorage = (
    value,
    key = encodeURI(window.location.pathname)
  ) => {
    try {
      const {
        screenshots,
        url,
        company,
        company_name,
        headline_tag,
        status,
        scope,
        type,
        awesomeness,
        description,
        summary,
        reports,
        childReports,
        report,
        tags,
        notes,
        notes_v2,
        start_date,
        end_date,
        module_name,
        page_type,
        showPageTypeOther,
        page_type_other,
        q_status,
        pub_status,
        global_no_winner,
        highlight,
        related_test = related_test !== "later" ? related_test : null,
        last_seen,
        last_seen_live,
        potential_end_date,
        potential_winner,
        potential_winner_other,
      } = this.state;
      const global_no_control = screenshots.find(s => s.control) ? false : true;
      const defaultTags =
        (tags && tags.map(tag => ({ value: tag, label: tag }))) || [];
      const defaultHeadlineTag = headline_tag || null;

      const dataToStore = {
        screenshots,
        url,
        company,
        company_name,
        headline_tag,
        status,
        scope,
        type,
        awesomeness,
        description,
        summary,
        reports,
        childReports,
        report,
        tags,
        notes,
        notes_v2,
        start_date,
        end_date,
        module_name,
        page_type,
        showPageTypeOther,
        page_type_other,
        q_status,
        pub_status,
        highlight,
        related_test,
        global_no_control,
        global_no_winner,
        defaultTags,
        defaultHeadlineTag,
        last_seen,
        last_seen_live,
        potential_end_date,
        potential_winner,
        potential_winner_other,
      };

      const oldData = localStorage.getItem(key);

      if (oldData !== JSON.stringify(dataToStore)) {
        dataToStore.mtime = new Date().toISOString();
        localStorage.setItem(key, JSON.stringify(dataToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  clearStateFromLocalStorage = (
    key = encodeURI(window.location.pathname),
    skipConfirm
  ) => {
    if (skipConfirm) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.log(error);
      }
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to clear the data?\n\nYou can't undo this."
      )
    ) {
      try {
        localStorage.removeItem(key);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };
  addNotesV2 = (note, index) => {
    const notesV2 = this.state.notes_v2;
    if (notesV2.length > index) {
      notesV2[index] = {
        ...note,
        edited: true,
      };
    }
    this.setState({
      notes_v2: notesV2,
    });
  };
  setScreenshotsEqualWeight = e => {
    const screenshots = this.state.screenshots;
    const selectedScreenshotsCount = screenshots.filter(
      ss => ss.selected
    ).length;
    if (selectedScreenshotsCount > 0) {
      const eqWeight = 100 / selectedScreenshotsCount;
      this.setState({
        screenshots: screenshots.map(screenshot => {
          if (screenshot.selected) {
            return {
              ...screenshot,
              weight: `${Math.round(eqWeight)}%`,
            };
          } else {
            return screenshot;
          }
        }),
      });
    }
  };
  doMainReportEdit = e => {
    e.preventDefault();
    this.setState({ editing_main_report: true });
  };
  confirmCopyTest = e => {
    if (!this.state.editing) {
      return false;
    }
    const confirmCopy = confirm("Are you sure want to copy this test?");
    if (confirmCopy) {
      this.setState({ loading: true });
      request(
        {
          url: `${baseApiUrl}/v1/screenshot/clone?id=${this.props.match.params.id}`,
          headers: {
            Authorization: this.props.user.token,
          },
        },
        (err, resp, data) => {
          if (err || resp.statusCode >= 400) {
            this.setState({
              error: true,
              loading: false,
            });
            return;
          }

          const json = JSON.parse(data);
          if (json.newId) {
            window.location.href = `/admin/screenshots/edit/${json.newId}`;
          }
        }
      );
    }
  };
  onDDragEndScreenshot = event => {
    // console.log({ event });
    const { active, over } = event;
    if (
      active &&
      active.data &&
      active.data.current.type === "screenshot" &&
      over &&
      over.data &&
      over.data.current.type === "screenshot"
    ) {
      const screenshots = reorder(
        this.state.screenshots,
        active.data.current.index,
        over.data.current.index
      );

      this.setState({
        screenshots,
      });
    }
  };
  onDDragEndNotes = event => {
    // console.log({ event });
    const { active, over } = event;
    if (
      active &&
      over &&
      active.data &&
      active.data.current.type === "notes" &&
      over.data &&
      over.data.current.type === "notes"
    ) {
      const screenshots = this.state.screenshots;
      const notes = screenshots[active.data.current.screenshotIndex].notes;
      const posXY = screenshots[active.data.current.screenshotIndex].posXY;
      const reOrderedNotes = reorder(
        notes,
        active.data.current.index,
        over.data.current.index
      );
      const reOrderedPosXY = reorder(
        posXY,
        active.data.current.index,
        over.data.current.index
      );
      screenshots[active.data.current.screenshotIndex].notes = reOrderedNotes;
      screenshots[active.data.current.screenshotIndex].posXY = reOrderedPosXY;
      this.setState({
        screenshots,
      });
    }
  };
  toggleLastSeenLive = e => {
    console.log(
      `currenttarget`,
      e.currentTarget,
      `value`,
      e.currentTarget.value
    );
    const value = e.currentTarget.value;
    const lastSeenLive =
      value === "last-seen" ? this.state.last_seen_live : null;
    const potentialEndDate =
      value === "may-have-ended" ? this.state.potential_end_date : null;
    this.setState({
      last_seen: value,
      last_seen_live: lastSeenLive,
      potential_end_date: potentialEndDate,
    });
  };
  clearLastSeenAndPotentialDate = e => {
    this.setState({
      last_seen: null,
      last_seen_live: null,
      potential_end_date: null,
      potential_winner: "",
      potential_winner_other: "",
    });
  };
  onCloseDeleteNoteDialog = e => {
    this.setState({
      showNoteDeleteDialog: false,
      deleteNoteI: null,
      deleteNoteN: null,
    });
  };
  onDeleteSingleNote = e => {
    this.deleteNoteAndPos(true, false);
  };
  onDeleteIthNoteFromAllScreenshot = e => {
    this.deleteNoteAndPos(false, true);
  };
  render() {
    const {
      screenshots,
      company,
      reports,
      editing,
      error,
      url,
      mtime,
      posN,
      posI,
      q_status,
      pub_status,
      sidebyside,
      related_test,
      showNoteDeleteDialog,
      loading,
    } = this.state;
    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "lightblue" : "transparent",
      display: "flex",
      padding: grid,
      overflow: "auto",
      minWidth: 375 * screenshots.length,
    });
    if (this.state.loading) {
      return <Loading />;
    }
    const width = 300;
    const height = 240;
    return (
      <div>
        {this.state.serverHasNewData && (
          <p className="white reload-mtime fixed top-2 w-100 bg-red mt2 ml0 pl0 z-999">
            <b>
              The test you are editing has updates on server, your local changes
              will be overwritten after reloading
              <label
                onClick={() => {
                  this.clearStateFromLocalStorage(this.state.localStorageKey);
                }}
                className="fw4 lh-copy  mt1 ml1 pointer underline"
              >
                Reload data
              </label>
            </b>
          </p>
        )}
        <h1>
          {editing ? "Edit " : "Add "} Test for {company}
        </h1>
        {url && (
          <div className="mb2">
            <a
              href={`https://dww.netlify.app/screens/${encodeURIComponent(
                url
              )}#${formatDate(mtime)}`}
            >
              Back to netlify view
            </a>
          </div>
        )}
        <div className={cx({ flex: sidebyside })}>
          <div className={cx({ "w-40 pr3": sidebyside })}>
            {this.state.isSavingToLocalStorage &&
              this.state.localStorageKey && (
                <div
                  className="f5 b pa3"
                  style={{
                    position: "fixed",
                    right: "0px",
                    bottom: "0px",
                    background: "rgba(255,255,255,0.5)",
                    zIndex: "2000",
                  }}
                >
                  Saving locally...
                </div>
              )}
            {error && (
              <p className="dark-red">
                <b>Looks like something went wrong&hellip;</b>
              </p>
            )}

            <label
              onClick={() => {
                this.clearStateFromLocalStorage(this.state.localStorageKey);
              }}
              className="fw4 lh-copy black-60 mt1 pointer underline"
            >
              Clear locally stored test data
            </label>
            <label className="db fw4 lh-copy black-60 mt4">Company</label>
            <div className="mt3 w-50" id="company_name">
              <select
                name="company_name"
                value={this.state.company_name}
                onChange={this.onKeyUp}
                style={{ maxWidth: "100%" }}
              >
                <option value="">-- pick a company --</option>
                {this.state.companies &&
                  this.state.companies
                    .filter(c => c.name)
                    .sort((a, b) =>
                      a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
                    )
                    .map((c, i) => (
                      <option value={c.name} key={`${c.name}-${c.tld}-${i}`}>
                        {c.name} ({c.tld})
                      </option>
                    ))}
              </select>
              {this.state.editing && (
                <p className="f6">Current company: {this.state.company_name}</p>
              )}
            </div>

            <TextInput
              label="URL"
              name="url"
              value={this.state.url}
              onChange={this.onKeyUp}
              id="copy"
            />
            <div className="mt3 flex flex-column" id="page_type">
              <label className="db fw4 lh-copy black-60 mt4">Page Type</label>
              {this.state.page_type && Array.isArray(this.state.page_type)
                ? this.state.page_type.map((page_type, i) => {
                    const isPageTypeOther =
                      (page_type &&
                        page_type.trim().toLowerCase() === "other") ||
                      (!pageTypes.includes(page_type) &&
                        page_type.trim() !== "");
                    return (
                      <div>
                        <select
                          name="page_type"
                          onChange={this.onSelectPageType(page_type, i)}
                          className="pa2 w-80 w-50-l b--black-20 bg-white br2"
                          value={isPageTypeOther ? "other" : page_type}
                        >
                          <option value="">--- Pick a page type ---</option>
                          {pageTypes.map(page => (
                            <option value={page} key={page}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <span>
                          {" "}
                          <img
                            className={classNames(
                              "pointer",
                              "mh1",
                              "pa1",
                              "grow"
                            )}
                            src={X}
                            alt="Close"
                            onClick={e =>
                              this.setState({
                                page_type: this.state.page_type.filter(
                                  item => item !== page_type
                                ),
                              })
                            }
                          />
                        </span>
                        {isPageTypeOther && (
                          <div>
                            <input
                              type="text"
                              className="mt2 pa2 w-80 w-50-l b--black-20 bg-white br2"
                              name="page_type_other"
                              value={page_type}
                              onChange={this.onSelectPageType(
                                page_type,
                                i,
                                true
                              )}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
                : null}
              <label
                className="db link blue fw4 lh-copy mt1 pointer underline-hover"
                onClick={e =>
                  this.setState({
                    page_type: [...this.state.page_type, ""],
                  })
                }
              >
                Add more
              </label>
            </div>
            <div className="mt3 w-100">
              <label className="db fw4 lh-copy black-60 mt4">
                Headline tag
              </label>

              <textarea
                defaultValue={this.state.defaultHeadlineTag}
                onChange={this.onKeyUp}
                className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                name="headline_tag"
              />
            </div>
            <div className="mt3 flex flex-column" id="module_name">
              <label className="db fw4 lh-copy black-60 mt3">Challenge</label>
              {this.state.module_name && Array.isArray(this.state.module_name)
                ? this.state.module_name.map((module_name, i) => {
                    return (
                      <div>
                        <select
                          name="module_name"
                          onChange={this.onSelectChallenge(module_name, i)}
                          className="pa2 w-80 w-50-l b--black-20 bg-white br2"
                          value={module_name}
                        >
                          <option value="">--- Pick a Challenge ---</option>
                          {CHALLENGES.map(challenge => (
                            <option
                              value={challenge.value}
                              key={challenge.value}
                            >
                              {challenge.text}
                            </option>
                          ))}
                        </select>
                        <span>
                          {" "}
                          <img
                            className={classNames(
                              "pointer",
                              "mh1",
                              "pa1",
                              "grow"
                            )}
                            src={X}
                            alt="Close"
                            onClick={e =>
                              this.setState({
                                module_name: this.state.module_name.filter(
                                  item => item !== module_name
                                ),
                              })
                            }
                          />
                        </span>
                      </div>
                    );
                  })
                : null}
              <label
                className="db link blue fw4 lh-copy mt1 pointer underline-hover"
                onClick={e =>
                  this.setState({
                    module_name: [...this.state.module_name, ""],
                  })
                }
              >
                Add more
              </label>
            </div>
            <div className="mt3">
              <label className="db fw4 lh-copy black-60 mt4">Test status</label>
              <select
                name="status"
                onChange={this.onKeyUp}
                className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                value={this.state.status}
              >
                <option value="live">Live</option>
                <option value="ended">Ended</option>
              </select>
            </div>
            <div className="mt3 flex" id="date-pickers">
              <div className="w-50">
                <label className="db fw4 lh-copy black-60 mt4">
                  Test started
                </label>
                <input
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.onKeyUp}
                />
              </div>
              <div className="w-50">
                <label className="db fw4 lh-copy black-60 mt4">
                  Test called
                </label>
                <input
                  type="date"
                  value={this.state.end_date}
                  name="end_date"
                  onChange={this.onKeyUp}
                />
              </div>
            </div>
            <div className="mt3 flex flex-column" id="last-seen-live">
              <div className="flex justify-between w-100">
                <label for="last-seen" className="db">
                  <input
                    type="radio"
                    name="last_seen"
                    value="last-seen"
                    id="last-seen"
                    className="mr2"
                    checked={
                      this.state.last_seen &&
                      this.state.last_seen === "last-seen"
                    }
                    onChange={this.toggleLastSeenLive}
                  />
                  Last seen live
                </label>
                <label for="may-have-ended" className="db">
                  <input
                    type="radio"
                    name="last_seen"
                    value="may-have-ended"
                    id="may-have-ended"
                    className="mr2"
                    checked={
                      this.state.last_seen &&
                      this.state.last_seen === "may-have-ended"
                    }
                    onChange={this.toggleLastSeenLive}
                  />
                  May have ended
                </label>
                <a href="# " onClick={this.clearLastSeenAndPotentialDate}>
                  clear
                </a>
              </div>
              <div className="flex justify-between">
                {this.state.last_seen &&
                  this.state.last_seen === "last-seen" && (
                    <div>
                      <label className="db fw4 lh-copy black-60 ">
                        Last seen live
                      </label>
                      <input
                        type="date"
                        value={this.state.last_seen_live}
                        name="last_seen_live"
                        onChange={this.onKeyUp}
                      />
                    </div>
                  )}
                {this.state.last_seen &&
                  this.state.last_seen === "may-have-ended" && (
                    <>
                      <div>
                        <label className="db fw4 lh-copy black-60 ">
                          Potential end date
                        </label>
                        <input
                          type="date"
                          value={this.state.potential_end_date}
                          name="potential_end_date"
                          onChange={this.onKeyUp}
                        />
                      </div>

                      <div>
                        <label className="db fw4 lh-copy black-60 ">
                          Potential Winner
                        </label>
                        <select
                          name="potential_winner"
                          onChange={this.onKeyUp}
                          value={this.state.potential_winner}
                        >
                          <option value="">Select Potential winner</option>
                          {this.state.screenshots.map(ss => {
                            let title = ss && ss.control ? `Control` : ``;
                            title +=
                              ss && ss.champ
                                ? title.length == 0
                                  ? `Champion`
                                  : ` & Champion`
                                : ``;
                            return (
                              <option value={ss.id}>
                                {title || `Variant ${ss.varNum}`} - {ss.id}
                              </option>
                            );
                          })}
                          <option value="no-winner">No Winner</option>
                          <option value="other">Other</option>
                        </select>
                        {this.state.potential_winner &&
                          this.state.potential_winner.trim() === "other" && (
                            <div>
                              <label className="db fw4 lh-copy black-60 ">
                                Potential winner
                              </label>
                              <input
                                type="text"
                                value={this.state.potential_winner_other}
                                name="potential_winner_other"
                                onChange={this.onKeyUp}
                              />
                            </div>
                          )}
                      </div>
                    </>
                  )}
              </div>
            </div>
            <p className="pt3 tr w-100">
              {!sidebyside ? (
                <a href="# " onClick={this.switchView("sidebyside")}>
                  Switch to side-by-side view
                </a>
              ) : (
                <a href="# " onClick={this.switchView("inline")}>
                  Switch to inline view
                </a>
              )}{" "}
              &middot;{" "}
              <a href="# " onClick={this.clearAllTags}>
                Clear All Tags
              </a>{" "}
              &middot;{" "}
              <a href=" #" onClick={this.fixImages}>
                Fix images
              </a>
            </p>
            <div
              className={cx({
                "mt4 flex ss-edit-box": true,
                loaded: this.state.doLoadedThing,
              })}
              id="screenshots"
              onScroll={this.scrollPos}
            >
              <DDndContext onDragEnd={this.onDDragEndScreenshot}>
                {screenshots.map((img, i) => {
                  let title = img && img.control ? `Control` : ``;
                  title +=
                    img && img.champ
                      ? title.length == 0
                        ? `Champion`
                        : ` & Champion`
                      : ``;
                  return (
                    <DDroppable
                      id={`screenshot-${i}-droppable`}
                      className="flex flex-row"
                      type={"screenshot"}
                      index={i}
                    >
                      <DraggableScreenshot
                        id={`screenshot-drag-${i}`}
                        type={"screenshot"}
                        i={i}
                        index={i}
                        img={img}
                        foo={this.state.foo}
                        title={title}
                        selectFn={this.select}
                        updateWeight={this.updateWeight}
                        addNote={this.addNote}
                        updateControl={this.updateControl}
                        updateChamp={this.updateChamp}
                        sidebyside={sidebyside}
                        scrollPosVert={this.scrollPosVert}
                        handlePos={this.handlePos}
                        onLoad={this.onLoad}
                        posN={posN}
                        posI={posI}
                        onEditScreenshotImage={this.onEditScreenshotImage}
                        user={this.props.user}
                        onDDragEndNotes={this.onDDragEndNotes}
                        serverNoteTags={this.state.serverNoteTags}
                        updateNotes={this.updateNotes}
                        changePos={this.changePos}
                        addSuggestionData={this.addSuggestionData}
                        deleteSuggestionData={this.deleteSuggestionData}
                        suggestionBox={this.suggestionBox}
                        moveUp={this.moveUp}
                        moveDown={this.moveDown}
                        deleteNoteAndPos={this.showDeleteNoteAndPos}
                        changeHide={this.changeHide}
                        screenshots={screenshots}
                        doFullPagePos={this.doFullPagePos}
                        resetPos={this.resetPos}
                      ></DraggableScreenshot>
                    </DDroppable>
                  );
                })}
              </DDndContext>
            </div>
            <div>
              <input
                type="button"
                className=" mb1"
                onClick={this.setScreenshotsEqualWeight}
                value="Make percentage equal"
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="no_control"
                  className="mr1"
                  onChange={this.updateNoControl}
                  checked={this.state.global_no_control}
                />
                No control
              </label>
              <label>
                <input
                  type="checkbox"
                  name="no_winner"
                  className="ml3 mr1"
                  onChange={this.updateNoWinner}
                  checked={this.state.global_no_winner}
                />
                No winner
              </label>
            </div>
            <div>
              <Upload
                callback={this.onUpload}
                user={this.props.user}
                multiple={true}
              />
            </div>
            <div>
              <label className="db fw4 lh-copy black-60 mt3">
                Publishing Status:{" "}
                <span className="b">
                  {this.state.report
                    ? reports.find(r => r.id === this.state.report).name
                    : null}
                </span>{" "}
                {!this.state.editing_main_report && (
                  <a href=" #" onClick={this.doMainReportEdit}>
                    Edit
                  </a>
                )}
              </label>
              {this.state.editing_main_report && (
                <select
                  name="report"
                  onChange={this.onKeyUp}
                  className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                  value={this.state.report}
                >
                  {reports.map(report => {
                    return (
                      <option value={report.id} key={report.id}>
                        {report.name}
                      </option>
                    );
                  })}
                </select>
              )}
              <div>
                {this.state.childReports && (
                  <div className="mt2 mb1 b">Published to customers:</div>
                )}
                {this.state.allReports &&
                  this.state.childReports &&
                  this.state.childReports.map(rid => {
                    const report_data = this.state.allReports.find(
                      r => r.id === rid
                    );
                    const name = report_data
                      ? `${report_data.name}`
                      : "Unknown";

                    if (report_data) {
                      return (
                        <p key={`child_${report_data.id}`}>
                          {name}{" "}
                          <button onClick={this.removeReport(report_data.id)}>
                            X
                          </button>
                        </p>
                      );
                    }
                    return null;
                  })}
                <div>
                  <a href="# " onClick={this.addAReport} className="mt2">
                    Add to another customer
                  </a>
                </div>
                {this.state.showAddReport && (
                  <>
                    <select
                      name="add_child_report"
                      onChange={this.onKeyUp}
                      className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                      value={this.state.add_child_report}
                    >
                      {this.state.allReports.map(r => (
                        <option value={r.id} key={`add_${r.id}`}>
                          {r.name}
                        </option>
                      ))}
                    </select>
                    <button onClick={this.doAddReport}>Add</button>
                  </>
                )}
                {this.state.editing && (
                  <div className="mt2">
                    <b>Queue status: </b>
                    {pub_status !== "live" && (
                      <>{status_map[q_status] || "Draft"}</>
                    )}
                    {pub_status === "live" && <>Published</>}
                  </div>
                )}
              </div>
            </div>
            <div className="mt3">
              <label className="db fw4 lh-copy black-60 mt4">
                Browser Type
              </label>
              <select
                name="type"
                onChange={this.onKeyUp}
                className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                value={this.state.type}
              >
                <option value="desktop">Desktop</option>
                <option value="mobile">Mobile</option>
              </select>
            </div>
            <div className="mt3" id="related_test">
              {this.state.related_test === "none" ||
              this.state.related_test === "later" ? (
                <>
                  <label className="db fw4 lh-copy black-60 mt4">
                    Related{" "}
                    {this.state.type === "mobile" ? "Desktop" : "Mobile"} Test
                    URL
                  </label>
                  <p>
                    {this.state.related_test === "none" ? (
                      <b>No related test</b>
                    ) : (
                      <b>Related test will be added later</b>
                    )}
                  </p>
                  <a
                    href=" #"
                    onClick={e => {
                      e.preventDefault();
                      this.onRTChange({ currentTarget: { value: "on" } });
                    }}
                  >
                    Add related test
                  </a>
                </>
              ) : (
                <>
                  <TextInput
                    label={`Related ${
                      this.state.type === "mobile" ? "Desktop" : "Mobile"
                    } Test URL`}
                    name="url"
                    value={
                      this.state.related_test
                        ? `https://app.dowhatworks.io/home/admin/screenshots/${this.state.related_test}`
                        : null
                    }
                    onFocus={this.getRelatedTest}
                    onChange={this.onRTChange}
                  />
                  {this.state.errorFetchRelatedTest &&
                    this.state.errorFetchRelatedTest === "missing input" && (
                      <>
                        {" "}
                        <div
                          className="mt0 light-red ba b--black pa1 test-row"
                          onClick={this.onRelatedTestSelected(
                            this.state.fetchedRelatedTest
                          )}
                        >
                          Missing url
                        </div>
                      </>
                    )}
                  {this.state.showFetchedRelatedTest && (
                    <div
                      className="mt0 ba bb--black pa1 pointer shadow-4 test-row"
                      onClick={this.onRelatedTestSelected(
                        this.state.fetchedRelatedTest
                      )}
                    >
                      {this.state.isFetchingRelatedTest && (
                        <>Fetching related test</>
                      )}
                      {!this.state.isFetchingRelatedTest &&
                        this.state.fetchedRelatedTest && (
                          <>
                            <p className="mb0">
                              <a
                                href={`/home/admin/report/screenshots/${this.state.fetchedRelatedTest.id}`}
                                target="_blank"
                              >
                                {this.state.fetchedRelatedTest.headline}
                              </a>
                              &middot;
                              <span className="mh2">
                                Started &middot; &nbsp;
                                {this.state.fetchedRelatedTest.start_date}
                              </span>
                            </p>
                          </>
                        )}
                      {!this.state.isFetchingRelatedTest &&
                        !this.state.fetchedRelatedTest && (
                          <>
                            <p className="mb0">
                              No releated test found{" "}
                              <span className="f6">
                                click to hide this message
                              </span>
                            </p>
                            {/* <p className="mb0 w-20 underline red dib">X</p> */}
                          </>
                        )}
                    </div>
                  )}
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.onRTChange({ currentTarget: { value: "none" } });
                    }}
                    href=" #"
                    className="link pointer"
                  >
                    No related test
                  </a>{" "}
                  |{" "}
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.onRTChange({ currentTarget: { value: "later" } });
                    }}
                    href=" #"
                    className="link pointer"
                  >
                    I'll add the related test later
                  </a>
                </>
              )}
            </div>
            <div className="flex">
              <div className="mt3 w-50">
                <label className="db fw4 lh-copy black-60 mt4">
                  Scope of test
                </label>
                <select
                  name="scope"
                  onChange={this.onKeyUp}
                  className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                  value={this.state.scope}
                >
                  <option value="s">Small</option>
                  <option value="m">Medium</option>
                  <option value="l">Large</option>
                </select>
              </div>
              <div className="mt3 w-50">
                <label className="db fw4 lh-copy black-60 mt4">
                  Awesomeness
                </label>
                <select
                  name="awesomeness"
                  onChange={this.onKeyUp}
                  className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                  value={this.state.awesomeness}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div className="mt3">
              <label className="db fw4 lh-copy black-60 mt4">
                Notes (not visible to clients)
              </label>
              <div>
                <b>Notes: </b>
                {Array.isArray(this.state.notes_v2) ? (
                  this.state.notes_v2.map((note, index) => (
                    <EditableNote
                      noteData={note}
                      index={index}
                      isEditable={
                        note.autoGenerated
                          ? false
                          : note.user
                            ? note.user.id === this.props.user.uid
                            : true
                      }
                      onNoteSave={this.addNotesV2}
                      data={this.state}
                    />
                  ))
                ) : (
                  <EditableNote
                    noteData={this.state.notes_v2}
                    onNoteSave={this.addNotesV2}
                    index={0}
                    isEditable={true}
                  />
                )}
              </div>
              <textarea
                name="notes"
                onChange={this.onKeyUp}
                className="pa2 w-100 w-50-l b--black-20 bg-white br2"
                value={this.state.notes}
              ></textarea>
            </div>

            {/*
        <div className="mt3">
          <label
            className="db fw4 lh-copy black-60 mt4"
            onClick={e => e.stopPropagation()}
          >
            Highlight{" "}
            <input
              type="checkbox"
              checked={this.state.highlight}
              value="true"
              onChange={this.handleHighlight}
              name="highlight"
            />
          </label>
        </div>
        */}
            {this.state.editing && (
              <div className="mt3">
                <button
                  onClick={this.confirmCopyTest}
                  className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 br2"
                >
                  Duplicate Test <img src={CopyIcon} />
                </button>
              </div>
            )}
            <div className="mt3">
              {related_test &&
                related_test !== "none" &&
                related_test !== "later" && (
                  <div className="mb1">
                    <label for="copy">
                      <input
                        type="checkbox"
                        name="copy"
                        onChange={this.onCopyChange}
                        className="mr2"
                        id="copy"
                      />
                      Copy Tags to related test
                    </label>
                    {this.state.copy && (
                      <div className="mb1">
                        <b className="red">
                          Please make sure the order of the screenshots is the
                          same!!
                        </b>
                      </div>
                    )}
                  </div>
                )}
              <button
                onClick={this.submit}
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 br2"
              >
                {editing ? "Save" : "Create post"}
              </button>
              {editing && (
                <button
                  onClick={this.delete}
                  className="b ph3 pv2 input-reset ba b--red bg-red white grow pointer f6 br2 ml3"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
          {sidebyside && (
            <div className="w-60">
              <div
                className="image-box"
                id="image-box"
                onScroll={this.scrollPos}
              >
                <div
                  className="image-box-inner flex"
                  style={{ width: 475 * screenshots.length }}
                >
                  {screenshots.map((img, i) => {
                    let title = img && img.control ? `Control` : ``;
                    title +=
                      img && img.champ
                        ? title.length == 0
                          ? `Champion`
                          : ` & Champion`
                        : ``;
                    return (
                      <div className="tc image-box-img">
                        <b>{title || `Variant ${img.varNum}`}</b>
                        <div className="pick-image-container">
                          {img && (
                            <img
                              src={img.src}
                              alt="Pick"
                              onClick={this.handlePos(i)}
                              id={`screenshot-${i}`}
                              onLoad={this.onLoad}
                            />
                          )}
                          {img && img.posXY ? (
                            img.posXY.map((x, z) =>
                              img.notes && img.notes[z] && x !== "" ? (
                                <div
                                  className={cx({
                                    "ba b--red bw1 b little-shadow": true,
                                    "b--red red": i !== posI || z !== posN,
                                    "b--blue blue": i === posI && z === posN,
                                  })}
                                  style={{
                                    background: "rgba(255,255,255,0.5)",
                                    width: "20px",
                                    height: "20px",
                                    position: "absolute",
                                    top: x ? x.origY : 0,
                                    left: x ? x.origX : 0,
                                    fontSize: "11px",
                                    borderRadius: "99px",
                                    marginLeft: "-10px",
                                    marginTop: "-10px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  onClick={this.handlePos(i, z, true)}
                                >
                                  {z + 1}
                                </div>
                              ) : (
                                <></>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal open={showNoteDeleteDialog}>
          <div
            className="box"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              overflow: "scroll",
              top: (window.innerHeight - height) / 2,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
            }}
          >
            <div className="pa3 bb b--black-05">
              <img
                src={X}
                alt="close"
                height={26}
                onClick={this.onCloseDeleteNoteDialog}
                className=" pointer fr pa1 "
              />
              <h2>Delete Note</h2>
              <div className="flex flex-column">
                <button
                  onClick={this.onDeleteSingleNote}
                  className="b ph3 pv2 input-reset ba b--black bg-red grow pointer f6 br2"
                >
                  This Note
                </button>
                <button
                  onClick={this.onDeleteIthNoteFromAllScreenshot}
                  className="b ph3 pv2 input-reset ba b--black bg-red grow pointer f6 br2"
                >
                  All notes in this position
                </button>
                <button
                  onClick={this.onCloseDeleteNoteDialog}
                  className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 br2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddScreenshots;
