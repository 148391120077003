import React from "react";
import { Link } from "react-router-dom";
import {
  getImage,
  imageUrl,
  imageUrlCrop,
  isMigratedComp,
  pickRandomElm,
  randomBlur,
  randomHue,
} from "../util";
import LeftArrowIcon from "../icons/LeftArrow.svg";
import RightArrowIcon from "../icons/RightArrow.svg";
import NoteDisplay from "./NoteDisplay";
import ChampIcon from "../icons/Champ.svg";
import LockIcon from "../icons/lock_white.svg";
import cx from "classnames";

const Empty = () => <></>;

class ScreenshotItem extends React.Component {
  state = {
    loading: true,
    user: null,
    authed: false,
    start: 0,
    stop: 3,
    more: {},
    analysis: this.props.analysis || {},
  };
  moveLeft = e => {
    e.stopPropagation();
    e.preventDefault();
    let newStart = this.state.start;

    if (newStart > 0) {
      this.setState({
        start: newStart - 1,
      });
    }
  };
  moveRight = e => {
    e.stopPropagation();
    e.preventDefault();
    let newStart = this.state.start;

    if (newStart < this.props.screenshots.length - 1) {
      this.setState({
        start: newStart + 1,
      });
    }
  };
  onChangeData = i => e => {
    const { analysis } = this.state;

    analysis[i] = {
      name: e.currentTarget.value,
      winner: this.props.screenshots[i].champ,
      control: this.props.screenshots[i].control,
    };
    this.setState({ analysis });

    this.props.changeData(analysis);
  };
  showAllNotes = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ stop: 99 });
  };
  render() {
    const {
      screenshots,
      isAdminPage,
      type,
      id,
      selectedAnalysis,
      collectionMode,
    } = this.props;
    const { start, stop } = this.state;

    let varNum = 0;

    return (
      <>
        {screenshots.length > 2 && (
          <>
            {start > 0 && (
              <div className="arrow arrow-left pointer">
                <img
                  src={LeftArrowIcon}
                  style={{ height: "50px", width: "50px" }}
                  onClick={this.moveLeft}
                  alt="Left"
                />
              </div>
            )}
            {start < screenshots.length - 2 && (
              <div className="arrow arrow-right pointer">
                <img
                  src={RightArrowIcon}
                  onClick={this.moveRight}
                  style={{ height: "50px", width: "50px" }}
                  alt="Right"
                />
              </div>
            )}
          </>
        )}
        <div
          className={cx({
            "shot-container": true,
            "do-box-fade": screenshots.length > 2,
          })}
        >
          <div
            className="mt2 tl flex arrow-container mb3"
            style={{ position: "relative", zIndex: 2 }}
          >
            {screenshots.map((s, i) => {
              if (!s.control) {
                varNum += 1;
              }
              if (i < start || i > start + 2) {
                return <Empty key={`ssi_${id}_${i}`} />;
              }
              let notes = null;

              if (s.notes && s.notes.length) {
                s.notes.forEach((n, y) => {
                  if (!n || n.trim() === "") {
                    if (s.pos && s.pos[y]) {
                      delete s.pos[y];
                    }
                  }
                });
                notes = s.notes.filter(n => n && n.trim() !== "");
              }
              let poses = null;
              if (s.pos && s.pos.length) {
                poses = s.pos.filter(p => p !== undefined || p !== null);
              }
              const [img, blur, hue] =
                this.props.freeTrial && !this.props.testUnlocked
                  ? s.src.split(":")
                  : [false, false, false];
              return (
                <div key={`ssi_${id}_${i}`}>
                  {selectedAnalysis && collectionMode === "analysis" && (
                    <div className="tc center pb2">
                      <select
                        name="analysis"
                        onChange={this.onChangeData(i)}
                        value={
                          this.state.analysis[i] && this.state.analysis[i].name
                            ? this.state.analysis[i].name
                            : ""
                        }
                      >
                        <option value="">-- pick a property --</option>
                        {selectedAnalysis.analysis_props.map(p => (
                          <option value={p.key} key={p.key}>
                            {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div
                    className={cx({
                      "screenshotItem-box mr3": true,
                      "ss-first": i === 0,
                    })}
                  >
                    <Link to={this.props.url} className="ui-link db center">
                      {this.props.freeTrial && !this.props.testUnlocked ? (
                        <div
                          className="img-box center relative"
                          style={{
                            width: type === "mobile" ? "200px" : "460px",
                            overflow: "hidden",
                            paddingTop: type === "mobile" ? "12px" : null,
                          }}
                          alt="screenshot"
                        >
                          <div
                            style={{
                              backgroundColor: "rgba(0,0,0,0.75)",
                              width: type === "mobile" ? "200px" : "100%",
                              height: "100%",
                              position: "absolute",
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: "65px",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                            }}
                            title={
                              "Variant Screenshots are only available to subscribers"
                            }
                          >
                            <img
                              src={LockIcon}
                              height={100}
                              style={{
                                zIndex: 2,
                                opacity: 0.6,
                              }}
                              onClick={e => {
                                e.preventDefault();
                                this.props.triggerUnlockModal();
                              }}
                              title="Unlock this test"
                            />
                          </div>
                          <div
                            className="img-box"
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: "0.5",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                              backgroundImage: `url(${s.src})`,
                              backgroundSize: "cover",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className="img-box br2 center"
                          style={{
                            width: type === "mobile" ? "200px" : "460px",
                            marginTop: type === "mobile" ? "12px" : null,
                            backgroundImage: `url(
                                          ${
                                            s.posXY &&
                                            s.posXY[0] &&
                                            s.posXY[0].y
                                              ? imageUrlCrop(
                                                  s.src,
                                                  type === "mobile" ? 200 : 375,
                                                  300,
                                                  s.posXY[0].y
                                                )
                                              : imageUrl(
                                                  s.src,
                                                  type === "mobile" ? 200 : 375,
                                                  300
                                                )
                                          })`,
                          }}
                          alt="screenshot"
                        ></div>
                      )}
                    </Link>
                    {notes && (
                      <div
                        className="screenshotItem-bottom"
                        style={
                          this.props.freeTrial && !this.props.testUnlocked
                            ? { zIndex: 9 }
                            : {}
                        }
                      >
                        <div
                          className={cx({
                            "f6 tc-s screenshotItem-notesHeader": true,
                            "screenshotItem-notesHeader-green": s.champ,
                          })}
                        >
                          <span>
                            {s.control ? "Control" : `Variant ${varNum}`}
                          </span>
                          {isAdminPage && <> &mdash; {s.weight}</>}
                          {s.champ && (
                            <img
                              src={ChampIcon}
                              alt="Champ"
                              className="screenshotItem-icon"
                            />
                          )}
                        </div>
                        <div
                          className="screenshotItem-notes"
                          style={
                            this.props.freeTrial && !this.props.testUnlocked
                              ? { zIndex: 9 }
                              : {}
                          }
                        >
                          {notes.map((n, x) => {
                            if (x >= stop && stop !== 0) {
                              return <Empty key={`empty_${id}_${i}_${x}`} />;
                            }
                            return (
                              <div
                                className={cx({
                                  "note-odd": x % 2 !== 0,
                                })}
                                key={`note_${id}_${i}_${x}`}
                              >
                                <NoteDisplay
                                  key={`${i}-${n}-${id}`}
                                  text={n}
                                  pos={poses && poses[x] ? poses[x] : null}
                                  isAdminPage={
                                    this.props.isAdminPage && this.props.isQueue
                                  }
                                  company={this.props.company}
                                  freeTrial={
                                    this.props.freeTrial &&
                                    !this.props.testUnlocked
                                  }
                                  triggerUnlockModal={
                                    this.props.triggerUnlockModal
                                  }
                                />
                              </div>
                            );
                          })}
                          {notes.length > stop && (
                            <div className="f7 pa2 tc">
                              <Link
                                to={this.props.url}
                                className="ui-link db center dww-gray"
                                onClick={
                                  this.props.isAdminPage
                                    ? this.showAllNotes
                                    : () => {}
                                }
                              >
                                +{notes.length - stop} more
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
export default ScreenshotItem;
