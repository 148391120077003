import React from "react";
import request from "request";
import { baseApiUrl } from "../../../util";
import TagSearch from "../../../components/TagSearch";
import Modal from "@material-ui/core/Modal";
class Companies extends React.Component {
  state = {
    tld: this.props.tld,
    name: this.props.name,
    nicknames: this.props.nicknames ? this.props.nicknames.join(",") : "",
    editing: this.props.new,
    saving: false,
    open: this.props.new,
    newOpen: false,
    spider: this.props.spider,
    companyCustomers: this.props.companyCustomers || [],
    companySectors: this.props.companySectors
      ? this.props.companySectors
          .map(s => this.props.new_sectors.find(x => x.key === s))
          .filter(s => s && s !== "")
      : [],
    showModal: false,
  };
  findCustomerName = key => {
    const foundCust = this.props.customers.filter(c => c.key === key);
    return foundCust.name;
  };
  onChangeTld = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
        .replace("https://", "")
        .replace("http://", "")
        .replace(/\/+$/, "")
        .toLowerCase(),
    });
  };
  removeCompany = e => {
    e.preventDefault();
    this.setState({
      saving: true,
      showModal: false,
    });
    const { tld, remove_urls } = this.state;

    request.delete(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/admin/company`,
        form: {
          tld: tld,
          remove_urls,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.props.callback();
          this.setState({
            saving: false,
          });
        }
      }
    );
  };
  handleForm = e => {
    e.preventDefault();
    this.setState({
      saving: true,
    });
    const { tld, name, companyCustomers, companySectors, spider, nicknames } =
      this.state;
    if (!tld || !name) {
      alert("Please fill out Url Key and Name");
    }
    let url = this.props.new
      ? `${baseApiUrl}/v1/admin/companies`
      : `${baseApiUrl}/v1/admin/company`;
    let actualSectors = companySectors;

    if (companySectors[0] && companySectors[0].key) {
      actualSectors = companySectors.map(c => c.key);
    }
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url,
        form: {
          name,
          tld: tld,
          customers: companyCustomers.join("*|*"),
          new_sectors: actualSectors.join("*|*"),
          nicknames: nicknames
            .split(",")
            .map(n => n.trim())
            .filter(n => n !== "")
            .join("*|*"),
          spider,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.props.callback();
          this.setState({
            saving: false,
            tld: "",
            name: "",
            companyCustomers: [],
            companySectors: [],
            nicknames: "",
            spider,
            editing: false,
          });
        }
      }
    );
  };
  onChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  onCheckChange = e => {
    this.setState({
      spider: !this.state.spider,
    });
  };
  changeCustomer = vals => {
    this.setState({ companyCustomers: vals });
  };
  changeSectors = vals => {
    this.setState({ companySectors: vals });
  };
  removeCustomer = key => {
    const custs = this.state.companyCustomers.filter(c => c !== key);
    this.setState({ companyCustomers: custs });
  };
  addCustomer = key => {
    const custs = this.state.companyCustomers;
    custs.push(key);
    this.setState({ companyCustomers: custs });
  };
  open = e => {
    e.preventDefault();
    this.setState({ open: true });
  };
  showDeleteModal = e => {
    this.setState({ showModal: true });
  };
  closeModal = e => {
    this.setState({ showModal: false });
  };
  removeUrls = e => {
    this.setState({ remove_urls: e.currentTarget.checked });
  };
  doEdit = e => {
    e.preventDefault();
    this.setState({ editing: true });
  };
  stopEdit = e => {
    e.preventDefault();
    this.setState({ editing: false });
  };
  render() {
    const { tld, name, customers, new_sectors, deactivated, nicknames } =
      this.props;
    const { saving, companyCustomers, open, companySectors, editing } =
      this.state;

    const width = 600;
    const height = 500;
    if (editing) {
      return (
        <form onSubmit={this.handleForm} className="pa2 mb3">
          {saving && <span>Saving&hellip;</span>}
          {!saving && editing && (
            <>
              {this.props.new ? (
                <>
                  <h3>Add a new company</h3>
                  <input
                    type="text"
                    name="tld"
                    value={this.state.tld}
                    placeholder="domain / url"
                    className="pa2"
                    onChange={this.onChangeTld}
                  />
                </>
              ) : (
                <>{tld}</>
              )}
              <input
                type="text"
                name="name"
                placeholder="Company name"
                defaultValue={name}
                value={this.state.name}
                className="pa2 ml2"
                onChange={this.onChange}
              />
              {!this.props.new && (
                <>
                  {this.props.deactivated ? (
                    <span className="ml3 gray pointer">Deactivated</span>
                  ) : (
                    <span
                      onClick={this.showDeleteModal}
                      className="ml3 red pointer"
                    >
                      Deactivate
                    </span>
                  )}
                </>
              )}
              <div>
                <div className="mt2">
                  <input
                    type="text"
                    name="nicknames"
                    placeholder="Other names (comma separated)"
                    defaultValue={nicknames}
                    value={this.state.nicknames}
                    className="pa2 w-50"
                    onChange={this.onChange}
                  />
                </div>
                <div className="mt2">
                  <h2>Sectors</h2>
                  <TagSearch
                    defaultTags={companyCustomers.map(c => ({
                      label: c,
                      value: c,
                    }))}
                    options={customers.map(c => ({
                      label: c,
                      value: c,
                    }))}
                    callback={this.changeCustomer}
                    placeholder="Sectors"
                  />
                </div>
                <div className="mt2">
                  <h2>New Sectors</h2>
                  <TagSearch
                    defaultTags={companySectors.map(c => ({
                      label: c.display_name,
                      value: c.key,
                    }))}
                    options={new_sectors.map(c => ({
                      label: c.display_name,
                      value: c.key,
                    }))}
                    callback={this.changeSectors}
                    placeholder="New Sectors"
                  />
                </div>

                <div className="mt2">
                  <label>
                    <input
                      type="checkbox"
                      onChange={this.onCheckChange}
                      name="spider"
                      checked={this.state.spider}
                    />{" "}
                    Spider Company
                  </label>
                </div>
              </div>

              <div className="mt2">
                {this.props.new ? (
                  <input type="submit" value="Create" />
                ) : (
                  <input type="submit" value="Save" />
                )}
                <a
                  href=" #"
                  onClick={
                    this.props.new ? this.props.closeCallback : this.stopEdit
                  }
                >
                  cancel
                </a>
              </div>
            </>
          )}
          <Modal open={this.state.showModal} onClose={this.closeModal}>
            <div
              className="box"
              style={{
                width: `${width}px`,
                overflow: "hidden",
                top: (window.innerHeight - height) / 2,
                position: "absolute",
                left: (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div className="bb b--black-05">
                <h1 className="pa3">Deactivate {this.state.name}?</h1>
              </div>
              <div className="pa3">
                <div className="pv3">
                  <label>
                    <input
                      type="checkbox"
                      name="remove_urls"
                      onChange={this.removeUrls}
                      className="mr2"
                    />{" "}
                    Also deactivate URLs associated with the company
                  </label>
                </div>
                <div className="pv2">
                  <button
                    className="ui-button primary mr3"
                    onClick={this.removeCompany}
                  >
                    Deactivate
                  </button>

                  <button
                    className="ui-button secondary"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </form>
      );
    }
    return (
      <div className="pa2 mb3">
        <div>
          <span className="b">{name}</span> ({tld})
          {this.props.deactivated && (
            <span className="ml3 gray pointer">Deactivated</span>
          )}
          <a href=" #" onClick={this.doEdit} className="ml3">
            Edit
          </a>
        </div>
        <div>
          <b>Sectors: </b>{" "}
          {(!companySectors || companySectors.length === 0) && <>No sector</>}
          {companySectors.map((sect, i) => (
            <span key={`${tld}-${sect.display_name}-${i}`}>
              {i !== 0 && <> &middot;</>} {sect.display_name}
            </span>
          ))}
        </div>
        <div>
          <b>Spider: </b> {this.state.spider ? <>Yes</> : <>No</>}
        </div>
      </div>
    );
  }
}

export default Companies;
