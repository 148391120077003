import React, { Component } from "react";
import DownIcon from "../icons/DownArrow_gray.svg";
import UpIcon from "../icons/UpArrow_gray.svg";
import DownIconBlue from "../icons/dropdown_blue.svg";
import UpIconBlue from "../icons/dropdown_blue_up.svg";
import BlueCheck from "../icons/check_blue.svg";
import "./tagfilter.css";
import DesktopIcon from "../icons/desktop_gray.svg";
import MobileIcon from "../icons/mobile_gray.svg";
import DesktopIconSelected from "../icons/Desktop_blue.svg";
import MobileIconSelected from "../icons/Mobile_blue.svg";
import Search from "../icons/search.svg";
import editIcon from "../icons/edit_blue.svg";
import saveIcon from "../icons/Champ.svg";
import cancelIcon from "../icons/x.svg";

import cx from "classnames";
import { getCurrentTime } from "../util";

class TagPicker extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.state = {
      showing: false,
      tags: this.props.noSort
        ? this.props.options
        : this.props.options.sort(this.tagSort),
      title_slug: `tp-${getCurrentTime()}-${props.title_slug || ""}`,
      rect: { top: 0, left: 0 },
      tagHeight: 0,
      selected: this.props.defaultOptions
        ? Array.isArray(this.props.defaultOptions)
          ? this.props.defaultOptions
          : [this.props.defaultOptions]
        : null,
      search: null,
      editable: this.props.editable,
      disabled: this.props.disabled,
      absolute: this.props.absolute || false,
    };
  }
  handleChange = (newValue, actionMeta) => {};
  open = e => {
    e.preventDefault();
    const elm = document.getElementById(this.state.title_slug).parentElement;
    const rect = elm.getBoundingClientRect();
    if (this.props.autoPosition) {
      this.getListSize();
    }
    this.setState({
      showing: this.state.showing === false ? true : false,
      rect: {
        left: elm.offsetLeft,
        top: elm.offsetTop + rect.height,
        width: rect.width,
        x: rect.x,
        y: rect.y,
      },
    });
  };
  noList: false;
  getListSize = () => {
    setTimeout(() => {
      const elm = document.getElementById(this.state.title_slug);
      if (elm) {
        const listElm = elm.querySelectorAll(".tag-menu");
        if (listElm && listElm[0]) {
          const rect = listElm[0].getBoundingClientRect();
          this.noList = false;
          this.setState({ tagHeight: rect.height });
        }
      } else if (!noList) {
        this.noList = true;
        this.getListSize();
      }
    }, 100);
  };
  choose = tag => e => {
    if (this.props.multiselect) {
      let newSelected = this.state.selected;
      if (newSelected && newSelected.find(t => t && t.value === tag.value)) {
        newSelected = newSelected.filter(t => t && t.value !== tag.value);
      } else {
        newSelected.push(tag);
      }

      newSelected = newSelected.filter(t => t && t.value !== "all");

      this.setState({ selected: newSelected, edit: null });
      this.props.callback(newSelected.map(s => s.value));
    } else {
      this.setState({ selected: [tag], edit: null });
      this.props.callback(tag.value);
      this.close();
    }
  };
  close = () => {
    this.setState({ showing: false });
  };
  onChange = e => {
    if (e.currentTarget.value.length > 0) {
      this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    } else {
      this.setState({ [e.currentTarget.name]: null });
    }
  };
  filterTags = () => {
    if (this.props.hasSearch && this.state.search) {
      return this.state.tags.filter(
        t =>
          t.label &&
          t.label.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      );
    } else {
      return this.state.tags;
    }
  };
  doSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !this.state.selectedTags.find(
        t => t.label.toLowerCase() === this.state.search.toLowerCase()
      )
    ) {
      this.add(this.state.search, "fulltext")();
    }
  };
  tagSort = (a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1);
  rect = null;
  inputRef = React.createRef();
  clearSearch = e => {
    this.setState({ search: null });
    this.inputRef.current.value = "";
    this.inputRef.current.focus();
  };
  onEdit = tag => e => {
    this.setState({ edit: tag, tagEdit: tag.label });
  };
  onCancelEdit = e => {
    this.setState({ edit: null });
  };

  onSaveTag = tag => async e => {
    e.preventDefault();
    e.stopPropagation();
    const updatedTags = this.state.tags.map(t => {
      if (t.value === tag.value) {
        return {
          label: this.state.tagEdit,
          value: t.value,
        };
      } else {
        return t;
      }
    });
    this.setState({ tags: updatedTags, edit: null });
    this.props
      .onTagSave(tag.value, this.state.tagEdit)
      .then(data => {})
      .catch(error => {
        const updatedTags = this.state.tags.map(t => {
          if (t.value === tag.value) {
            return {
              label: tag.label,
              value: t.value,
            };
          } else {
            return t;
          }
        });
        this.setState({ tags: updatedTags, edit: null });
      });
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (
      Array.isArray(nextProps.defaultOptions) &&
      JSON.stringify(nextProps.defaultOptions) !==
        JSON.stringify(this.props.defaultOptions)
    ) {
      this.propsUpdated = true;
      return true;
    }
    if (
      nextProps.defaultOptions &&
      nextProps.defaultOptions.value &&
      this.props.defaultOptions &&
      this.props.defaultOptions.value &&
      nextProps.defaultOptions.value !== this.props.defaultOptions.value
    ) {
      this.propsUpdated = true;
      return true;
    }
    if (!this.propsUpdated) this.propsUpdated = false;
    return !Object.is(this.state, nextState);
  }
  autoPositionPosition: null;
  onBottom: false;

  componentDidMount() {
    if (this.props.autoPosition) {
      const domNode = document.getElementById(this.state.title_slug);
      if (domNode) {
        const rect = domNode.getBoundingClientRect();
        this.onBottom = rect.bottom >= window.innerHeight * 0.66;
        this.bottom = rect.bottom;
      }
    }
  }

  render() {
    const { showing, title_slug, search, edit, disabled, selected } =
      this.state;
    const {
      title,
      onRight,
      isSticky,
      fixAt,
      icon,
      inline,
      hasSearch,
      disabledEffect,
      defaultOptions,
    } = this.props;
    const tags = disabledEffect ? this.props.options : this.filterTags();

    let top = isSticky ? this.state.rect.y - 20 : this.state.rect.top + 8;

    let style;

    if (fixAt) {
      style = {
        width: fixAt.width,
        right: fixAt.right,
        top: fixAt.top,
      };
    } else {
      if (this.onBottom) {
        style = {
          bottom: this.state.tagHeight,
          left: this.state.rect.left - 24,
          width: isSticky ? 306 : this.state.rect.width,
        };
      } else {
        style = {
          top: "-4px",
          left: onRight ? null : this.state.rect.left - 24,
          right: onRight ? this.state.rect.x : null,
          width: isSticky ? 306 : this.state.rect.width,
        };
      }
    }

    let selectedFinal = this.propsUpdated ? defaultOptions : selected;
    if (selectedFinal && !Array.isArray(selectedFinal)) {
      selectedFinal = [selectedFinal];
    }
    if (selectedFinal) {
      selectedFinal = selectedFinal.filter(s => s && s.label);
    }
    if (selectedFinal && selectedFinal.length === 0) {
      selectedFinal = null;
    }

    return (
      <>
        <div
          className={cx({
            "tag-filter-container": true,
            "f6 small-tag-filter": this.props.small,
            "tf-inline": inline,
          })}
          id={title_slug}
        >
          <div className="tag-filter-selector">
            <a
              href="# "
              onClick={!disabled ? this.open : undefined}
              className={cx({
                "ui-link": true,
                nowrap: true,
                "tag-filter": true,
                "tag-filter-disabled": disabled,
              })}
            >
              {icon && (
                <img src={icon} className="tf-icon" style={{ width: "18px" }} />
              )}
              <span className="tag-filter-text">
                <span>{title}</span>
                {selectedFinal && (
                  <>
                    {this.props.usePlatformIcons ? (
                      <>
                        {selectedFinal[0].value === "desktop" && (
                          <img src={DesktopIcon} height="17" />
                        )}
                        {selectedFinal[0].value === "mobile" && (
                          <img src={MobileIcon} height="17" />
                        )}
                        {selectedFinal[0].value === "all" && (
                          <span
                            className="flex"
                            style={{ paddingTop: "2px", marginBottom: "-2px" }}
                          >
                            <img src={DesktopIcon} height="15" />
                            <span
                              style={{
                                padding: "0 3px 2px 4px",
                                marginTop: "-2px",
                              }}
                            >
                              /
                            </span>
                            <img
                              src={MobileIcon}
                              height="17"
                              style={{ marginTop: "-1px" }}
                            />
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="tag-selected-text">
                        {selectedFinal &&
                        selectedFinal[0] &&
                        selectedFinal.length > 1 ? (
                          <>
                            {selectedFinal
                              .filter(s => s)
                              .map(s => s.label)
                              .join(", ")}
                          </>
                        ) : (
                          <>{selectedFinal[0].label}</>
                        )}
                      </span>
                    )}
                  </>
                )}
              </span>
              {showing ? (
                <img
                  src={this.props.isBlue ? UpIconBlue : UpIcon}
                  className="dd-icon"
                  onClick={this.open}
                />
              ) : (
                <img
                  src={this.props.isBlue ? DownIconBlue : DownIcon}
                  className="dd-icon"
                  onClick={!disabled ? this.open : undefined}
                />
              )}
            </a>
          </div>
          {showing && (
            <div
              className={cx({
                absolute: !this.props.inline || this.props.absolute,
                "tag-picker-list-container": true,
              })}
              style={style}
            >
              <div className="relative">
                {this.props.hasArrow && (
                  <>
                    <div className="tf-tri-outer" style={{ right: "15px" }} />
                    <div className="tf-tri" style={{ right: "15px" }} />
                  </>
                )}
                <div
                  className={cx({
                    "bg-white": true,
                    "tag-menu": !this.props.absolute,
                    box: !inline,
                  })}
                  style={{ width: "auto" }}
                >
                  {hasSearch && (
                    <div className="pa2 ph3 search-form-container">
                      <form onSubmit={this.doSubmit}>
                        <input
                          type="text"
                          onChange={this.onChange}
                          placeholder={`Filter results`}
                          className="search-form"
                          ref={this.inputRef}
                          name="search"
                        />
                        <input type="submit" className="dn" />
                      </form>
                      {!search ? (
                        <img
                          src={Search}
                          className="search-form-icon c-text"
                          alt="search"
                          onClick={() => {
                            this.inputRef.current.focus();
                          }}
                        />
                      ) : (
                        <div
                          className="search-form-icon pointer"
                          onClick={this.clearSearch}
                        >
                          clear
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className="tag-menu-scroll"
                    style={{ marginRight: "0px" }}
                  >
                    {tags.map((tag, i) => {
                      return (
                        <div key={`${tag.label}-${i}`}>
                          <>
                            <div
                              className={cx("flex pa2 pr3 ", {
                                pointer: !tag.disabled,
                                "add-hover": !tag.disabled,
                              })}
                            >
                              {selectedFinal &&
                              selectedFinal.find(s => s.value === tag.value) ? (
                                <div style={{ padding: "0 6px" }}>
                                  <img src={BlueCheck} alt="check" />
                                </div>
                              ) : (
                                <div
                                  style={{ padding: "0 6px", width: "23px" }}
                                ></div>
                              )}
                              {this.props.usePlatformIcons ? (
                                <div className="w-90 flex">
                                  {tag.value === "desktop" && (
                                    <img
                                      src={
                                        selectedFinal &&
                                        selectedFinal[0].value === "desktop"
                                          ? DesktopIconSelected
                                          : DesktopIcon
                                      }
                                      height="17"
                                      style={{ marginLeft: "4px" }}
                                      onClick={this.choose(tag)}
                                    />
                                  )}
                                  {tag.value === "mobile" && (
                                    <img
                                      src={
                                        selectedFinal &&
                                        selectedFinal[0].value === "mobile"
                                          ? MobileIconSelected
                                          : MobileIcon
                                      }
                                      height="17"
                                      style={{ marginLeft: "8px" }}
                                      onClick={this.choose(tag)}
                                    />
                                  )}
                                  {tag.value === "all" && (
                                    <div
                                      style={{ paddingLeft: "4px" }}
                                      className="flex"
                                      onClick={this.choose(tag)}
                                    >
                                      {!selectedFinal ||
                                      selectedFinal[0].value === "all" ? (
                                        <>
                                          <img
                                            src={DesktopIconSelected}
                                            height="14"
                                          />
                                          <span
                                            style={{
                                              padding: "0 4px 2px 3px",
                                              marginTop: "-2px",
                                            }}
                                            className="dww-blue"
                                          >
                                            /
                                          </span>
                                          <img
                                            src={MobileIconSelected}
                                            height="16"
                                            style={{ marginTop: "-1px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img src={DesktopIcon} height="14" />
                                          <span
                                            style={{
                                              padding: "0 4px 2px 3px",
                                              marginTop: "-2px",
                                            }}
                                          >
                                            /
                                          </span>
                                          <img
                                            src={MobileIcon}
                                            height="16"
                                            style={{ marginTop: "-1px" }}
                                          />
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {edit && edit.value === tag.value ? (
                                    <div
                                      className={cx({
                                        "w-90": true,
                                        "dww-blue":
                                          selectedFinal &&
                                          selectedFinal.find(
                                            s => s.value === tag.value
                                          ),
                                      })}
                                    >
                                      <form onSubmit={this.onSaveTag(tag)}>
                                        <input
                                          type="text"
                                          name="tagEdit"
                                          onChange={this.onChange}
                                          value={this.state.tagEdit}
                                        />
                                        <img
                                          style={{ padding: "0 6px" }}
                                          src={saveIcon}
                                          alt="save-tag"
                                          id="save-icon"
                                          onClick={this.onSaveTag(tag)}
                                        />
                                        <img
                                          style={{ padding: "0 6px" }}
                                          src={cancelIcon}
                                          alt="cancel-edit"
                                          id="cancel-icon"
                                          onClick={this.onCancelEdit}
                                        />
                                      </form>
                                    </div>
                                  ) : (
                                    <>
                                      <div
                                        className={cx({
                                          "w-90": true,
                                          gray: tag.disabled,
                                          "dww-blue":
                                            selectedFinal &&
                                            selectedFinal.find(
                                              s => s.value === tag.value
                                            ) &&
                                            !tag.disabled,
                                        })}
                                      >
                                        <div
                                          onClick={
                                            !tag.disabled
                                              ? this.choose(tag)
                                              : null
                                          }
                                          className="flex"
                                        >
                                          {tag.label}
                                        </div>
                                      </div>
                                      {tag.value !== "new" &&
                                      this.state.editable ? (
                                        <img
                                          style={{ padding: "0 6px" }}
                                          src={editIcon}
                                          alt="edit-tag"
                                          id="edit-icon"
                                          onClick={this.onEdit(tag)}
                                        />
                                      ) : null}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {showing && !inline && (
          <div className="tag-blocker" onClick={this.open}>
            &nbsp;
          </div>
        )}
      </>
    );
  }
}

export default TagPicker;
