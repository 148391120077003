import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div style={{ textAlign: "left", padding: "20px" }} id="terms">
      <p>
        <h3>Do What Works</h3>
      </p>
      <p>
        <h3>Terms of Service</h3>
      </p>
      <p>
        <b>Last updated: Aug 11, 2022</b>
      </p>
      <p>
        These Terms of Service (this &ldquo;Agreement&rdquo; constitute a
        legally binding agreement between the user (&ldquo;User&rdquo; or
        &ldquo;you&rdquo;) of the Do What Works website and services on the one
        hand and and Do What Works Inc., a Delaware corporation, with principal
        offices at 100 Red Hill Rd, Middletown, NJ 07748 (&ldquo;DWW,&rdquo;
        &ldquo;we,&rdquo; or &ldquo;us&rdquo;) on the other, as described
        herein.
      </p>
      <p>
        Please make sure you fully understand the contents of this Agreement. If
        you have any doubts about any of your rights and obligations resulting
        from your acceptance of this Agreement, please consult us or obtain
        legal support.
      </p>
      <p>
        IF YOU DO NOT AGREE WITH ANY PROVISION OF THIS AGREEMENT, YOU MAY
        NEITHER CONTINUE WITH THE PROCESS OF REGISTRATION NOR FURTHER USE THE
        SERVICES.
      </p>
      <ol start="1">
        <li>
          <b>Acceptance of Agreement.</b>
          <ol type="a">
            <li>
              <b>Binding Agreement.</b> By using the DWW website located at{" "}
              <a href="https://www.dowhatworks.io">
                {" "}
                https://www.dowhatworks.io
              </a>{" "}
              (the &ldquo;Site&rdquo; and/or the Services (as that term is
              defined in Section 2, below), you represent that (1) you have
              read, understand, and agree to be bound by this Agreement, (2) you
              are of legal age to form a binding contract with DWW, (3) all
              registration information you submit is truthful and accurate and
              that you will maintain the accuracy of such information, (4) your
              registration does not and will not violate any applicable law or
              regulation, and (5) you have the authority to enter into this
              Agreement personally or on behalf of the company or other
              organization you have named as the user, and to bind that entity
              to this Agreement. In the event you are agreeing to this Agreement
              on behalf of a company or organization, &ldquo;you,&rdquo;
              &ldquo;your,&rdquo; and &ldquo;yours&rdquo; will refer to the
              entity you are representing.
            </li>
            <li>
              <b>No minors.</b>
              &nbsp; The Site and Services are not intended for use by children,
              and those under the age of 18 should not proceed to use the Site.
              If you are using the Site you agree that you are at least 18 years
              old or have parental consent for using the Site. Notwithstanding
              the foregoing, in case you are less than 16 years old, you are
              strictly prohibited from using the Site. By using the Site, you
              warrant to us that you are above the age of 16. In addition,
              parents or guardians of children over the age of 16 should be
              aware that the Site is designed to appeal to a broad audience.
              Accordingly, it is your responsibility to determine whether any
              portion of the Site is inappropriate for your child. We do not
              knowingly collect personally identifiable information from
              children under 16 years of age.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="2">
        <li>
          <b>SERVICES</b>
          <ol type="a">
            <li>
              <b>Definition. </b>
              The &ldquo;Services&rdquo; consist of a suite of online marketing
              and management tools accessible at and/or through the Site, and
              are used for monitoring and assessing the efficacy of website,
              email, and search advertising assets. Among other things, the
              Services enable Users to (a) review third party websites and
              assets, (b) obtain information related to the ongoing online
              advertising campaigns, (c) generate reports and analytics on web
              pages, email, and search advertising assets and/or other online
              advertising campaigns, and (d) access an extensive array of
              resources, including but not limited to, an online platform.
            </li>
            <li>
              <b>Changes. </b>
              We reserve the right to change the terms or specifications of any
              Services in our discretion, with or without prior written notice
              to the Users, by replacement of text of this Agreement or
              description of paid subscription plans on the Site or by written
              notice to you. Any changes will take effect immediately unless
              otherwise stated in the notice of change. If any amendment is
              unacceptable to you, your only recourse is to terminate relations
              with DWW. Your continued use of the Services following our notice
              of change will constitute a binding acceptance of the Agreement,
              as amended.
            </li>
            <li>
              <b>Additional Services. </b>
              Unless explicitly stated otherwise, any new features that augment
              or enhance the currently offered Services, including the release
              of new DWW services, shall be subject to this Agreement.
            </li>
            <li>
              <b>Right to Use Services. </b>
              <span>
                DWW hereby grants you permission to use the Services and the
                Site solely as set forth in this Agreement and in the manner set
                forth on the Site. &nbsp;As necessary, the Services may require
                you to login (using username and password and/or other
                authentication). &nbsp;In the event of any conflict between the
                terms of this Agreement and the Site, the terms of this
                Agreement shall prevail. Any use of the Services other than as
                set forth in this Agreement or in violation of any term of this
                Agreement will result in suspension or revocation of your use
                privileges in our sole discretion.
              </span>
            </li>
            <li>
              <b>Support and Enhancements. </b>
              <ol type="i">
                <li>
                  <b>Technical Support. </b> You will be entitled access to
                  customer support personnel by email for reporting issues of
                  all types associated with the Services.
                </li>
                <li>
                  <b>Modifications and Enhancements. </b>
                  DWW will utilize commercially reasonable steps to furnish
                  upgrades for the Services if, as, and when publicly released
                  by Company during the term of the Agreement.
                </li>
              </ol>
            </li>
            <li>
              <b>Suspension or Termination of Services. </b>
              Notwithstanding anything stated or implied to the contrary in this
              Agreement, we may at any time, without derogating from our other
              rights under this Agreement, applicable law or otherwise, suspend
              or terminate any or all of the Services, effective immediately
              upon issuance of a written notice. Such suspension or termination
              may also apply, as the case may be, to specific jurisdictions,
              lines of business and otherwise or to a specific customer or a
              group of Users. Notwithstanding the foregoing, solely with respect
              to Users of paid Services, whenever reasonably possible, such
              Users may be given up to thirty (30) days after notice of
              suspension or termination of their User account to back-up the
              data stored in their account before it may be removed entirely
              from our servers.
            </li>
            <li>
              <b> Third Party Services. </b>
              We reserve the right to use third party service providers in the
              provisions of all or part of the Services including, but not
              limited to, hosting providers, payment processing services,
              information and communication services, analytics services,
              internet advertising platforms, advertising service providers and
              platforms. Where any of the aforementioned services are provided
              by third parties, the User may be subject to such third
              party&rsquo;s terms and conditions. We accept no responsibility
              for services provided by any third party.
            </li>
            <li>
              <b>Changes and Errors. </b>
              Prices and availability of products and services are subject to
              change without notice. Errors will be corrected where discovered,
              and DWW reserves the right to revoke any stated offer and to
              correct any errors, inaccuracies, or omissions, including after an
              order has been submitted, whether or not the order has been
              confirmed and whether or not you have been charged.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="3">
        <li>
          <b>REGISTRATION, ACCOUNT, AND USE</b>
          <ol type="a">
            <li>
              <b> Use of the Services. </b>
              You may use the Services either as a registered or as an
              unregistered User. However, you may not use the Services, either
              as a registered or an unregistered User if you are not of legal
              age to enter into a contract in your jurisdiction or if do not
              have the authority to accept this Agreement. If you are under 18,
              please do not attempt to register for the Services or send any
              information about yourself to us, including your name, address,
              telephone number, or email address. If we learn that we have
              collected personal information from anyone under age 18 without
              verification of parental consent, we will delete that information
              as quickly as possible.
              <sup>
                <a href="#cmnt7"></a>
              </sup>
            </li>
            <li>
              <b>Acceptance. </b>
              By using the Services, you accept the terms of this Agreement and
              you fully authorize us to obtain, process, store, use and transmit
              your personal data in accordance with our Privacy Policy, which
              forms an integral part of this Agreement.
            </li>
            <li>
              <b>User Representations. </b>
              By using the Services, you represent and warrant that (a) all
              registration information you submit is truthful and accurate; (b)
              you will maintain the accuracy of such information; (c) you are at
              least 18 years of age and have the capacity and authority to enter
              into this Agreement; and (d) your use of the Services does not
              violate any applicable law or regulation.
            </li>
            <li>
              <b>Registration; Billing. </b>
              To register as a User, you have to create a user account on the
              Site by following registration procedures and instructions set
              forth therein. There is no cost to create a DWW User account.
              However, in order to access certain paid features of the Services,
              you will be required to provide billing details. As a registered
              User, you agree to notify us promptly of any changes to your
              billing details. User account is intended and designed for use by
              an individual user, unless otherwise stated in your DWW
              subscription plan. You may not give access to your User account to
              additional users, in excess of the number of users specified in
              your DWW subscription plan. If DWW detects multiple users
              frequently accessing the same User account from various locations,
              devices, IP addresses, DWW may immediately suspend or terminate
              this User account in its sole discretion.
            </li>
            <li>
              <b>Login, Password.</b>
              You are solely and fully responsible for the maintenance of all of
              your DWW user accounts, including, but not limited to, your User
              login and password.
            </li>
            <li>
              <b>User Responsibilities. </b>
              You are responsible for all of the following with respect to your
              use of the Services:
              <ol type="i">
                <li>
                  maintaining the security of your User account and all the
                  activity that occurs on your User account;
                </li>
                <li>
                  maintaining accurate account information at all times,
                  including a valid email address and billing information and
                  updating such information as necessary;
                </li>
                <li>
                  obtaining access to the Services, and that access may involve
                  third-party fees (such as Internet service provider or airtime
                  charges); and
                </li>
                <li>
                  obtaining and maintaining all equipment necessary to access
                  the Services.
                </li>
              </ol>
            </li>
            <li>
              <b>Prohibited Uses. </b>
              You are expressly prohibited from using the Services in any of the
              following ways or for any of the following purposes, or from
              taking any of the following actions:
              <ol type="i">
                <li>
                  to attempt to gain unauthorized access to any portion or
                  feature of the Site, or any other systems or networks
                  connected to the Site or to any DWW server, or to any of the
                  services offered on or through the Site, by hacking, password
                  &ldquo;mining&rdquo; or any other illegitimate means;
                </li>
                <li>to frame or mirror any part of the Site;</li>
                <li>
                  to probe, scan or test the vulnerability of the Site or any
                  network connected to the Site, nor breach the security or
                  authentication measures on the Site or any network connected
                  to the Site;
                </li>
                <li>
                  to probe, scan, or test the vulnerability of any
                  Property&rsquo;s infrastructure, network security, data
                  security or privacy compliance;
                </li>
                <li>
                  to perform any form of security research related to a
                  Property;
                </li>
                <li>
                  to knowingly violate the terms of service, security, or
                  privacy policies of any third party;
                </li>
                <li>
                  to take any action that imposes an unreasonable or
                  disproportionately large load on the infrastructure of the
                  Site or its systems or networks, or any systems or networks
                  connected to the Site;
                </li>
                <li>
                  use any device, software or routine to interfere or attempt to
                  interfere with the proper working of the Site or any
                  transaction being conducted on the Site, or with any other
                  person&rsquo;s use of the Site;
                </li>
                <li>
                  forge headers or otherwise manipulate identifiers in order to
                  disguise the origin of any message or transmittal you send to
                  DWW on or through the Site or any Service offered on or
                  through the Site;
                </li>
                <li>
                  hold yourself out as, or pretend that you are or that you
                  represent, someone else, or impersonate any other individual
                  or entity, including, but not limited to, DWW and/or its
                  employees;
                </li>
                <li>
                  use the Site or the Services, or post any messages or content,
                  for any purpose that is unlawful or prohibited by this
                  Agreement, or to solicit the performance of any illegal
                  activity or other activity which infringes the rights of DWW
                  or third parties;
                </li>
                <li>
                  circumvent or manipulate our fee structure, the billing
                  process, or fees owed to us;
                </li>
                <li>
                  copy, modify, or distribute rights or content from the
                  Services or our copyrights and trademarks;
                </li>
                <li>
                  use the Services in order to provide similar services to any
                  third party or for competing with our Service;
                </li>
                <li>
                  use our Services or content on any platform or website not
                  provided and approved by us, make any misrepresentations or
                  abuse our Services, or otherwise violate anyone&rsquo;s rights
                  or any applicable laws. Failure to abide by any of these rules
                  may bring us to cancel your account and stop providing you
                  with any Services;
                </li>
                <li>
                  use the Services for any illegal or unauthorized purpose,
                  including in any way that violates copyright or other
                  applicable laws; or
                </li>
                <li>
                  use the Services in a way that is detrimental to the operation
                  of the Services or the access or use of the Services by anyone
                  else. You will not upload or transmit viruses, worms or any
                  other destructive code. The restriction in this Section
                  applies to any use that interferes or attempts to interfere
                  with the normal operations of the Services, including by
                  hacking, deleting, augmenting or altering the Services.
                </li>
              </ol>
            </li>
            <li>
              <b>Permission Required. </b>
              You may not, without our prior written permission and, solely with
              respect to lease, resale and sublicense, except as may be
              specifically allowed under your paid subscription plan, (i) copy,
              distribute (including by framing any of the Services on any web
              site), modify, enhance, translate, reproduce, sell, resell,
              sublicense, rent, lease, or otherwise attempt to exploit the
              Services or any data resulting therefrom; (ii) decompile,
              disassemble, reverse engineer, or otherwise attempt to discover
              the source code; (iii) make derivative works of the Services; (iv)
              remove, obscure, or alter any copyright notice, trademarks, or
              other proprietary rights notices affixed to or contained within or
              accessed in conjunction with or through the Site or Services; (v)
              modify another website so as to falsely imply that it is
              associated with the Services, DWW or any other DWW products or
              services; or (vi) make the Site or Services or any part thereof
              available to others in a service undertaking or outsourcing
              arrangement or for any other commercial time-sharing, data
              processing or other third party use.
            </li>
            <li>
              <b>Special Access and Testing. </b>
              If you are invited or clearly provided with access to beta testing
              new tools and resources, which are not made available to our users
              broadly, you should not rely on, nor expect, the continued
              availability of these new tools and resources. Any such access to
              beta test new tools and resources, if any, is conditioned upon
              your agreement not to disclose any information about these new
              tools and resources or your experience with using them to third
              parties.
            </li>
            <li>
              <b>Assignment. </b>A User may not assign, transfer, exchange, pool
              or barter any of its rights or obligations under this Agreement or
              the User account, unless expressly permitted by DWW in writing.
              Any violation of the foregoing restrictions is grounds for
              immediate User account termination. For changes in access under
              corporate subscriptions to the Services we may require from you a
              detailed explanation of changes in your circumstances, along with
              confirmation of employment and other reasonable information and
              relevant documents.
            </li>
            <li>
              <b>De-Registration. </b>
              You may delete your User account at any time. Note that doing so
              will delete all your data and information stored on DWW servers
              and DWW will bear no responsibility for such loss of data or
              information. All fees for Services incurred prior to
              de-registration will be due and owing, until paid in full, such
              de-registration notwithstanding.
            </li>
            <li>
              <b>Termination by DWW. </b>
              DWW reserves the right to terminate any User account for abusive
              or fraudulent activity, for failure to comply with this Agreement,
              or for any other reason in its sole discretion.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="4">
        <li>
          <b>FEES</b>
          <ol type="a">
            <li>
              <b>Service Fees. </b>
              With respect to paid Services, User will be charged the fees as we
              agree, or as otherwise offered on the Site or otherwise by DWW for
              a particular subscription plan (the “Fees”). The Fees, unless
              explicitly shown during the process of purchasing a subscription
              plan and following confirmation thereof, are exclusive of value
              added tax and any additional or other taxes, charges or duties
              which may be imposed in connection with any and all payments made
              or due hereunder and shall, if applicable, be borne, respectively
              registered and duly declared by User.
            </li>
            <li>
              <b>Promotional Giveaways. </b>
              From time to time we may offer promotional giveaways of the
              Services, subject to the specific rules that we will announce at
              the time of any such promotional giveaway. Users shall bear sole
              responsibility for any and all income tax consequences that may
              result from their winning any such giveaway. Further, solely to
              the extent required by applicable laws, Users agree to submit to
              us duly completed tax forms, to enable us to make all required
              filings with tax authorities.
            </li>
            <li>
              <b>Change in Fees. </b>
              We may change the Fees and/or introduce new charges in addition to
              the Fees in our sole discretion upon thirty (30) days&rsquo; prior
              written notice to the User. Notwithstanding the foregoing, we may
              increase the Fees, immediately and with contemporaneous notice, in
              the event of (a) any change in the services or fees of our third
              party service providers; (b) changes in the Services which are
              made at your request; or (c) delays and/or other issues due to
              User failure to fulfill User obligations or due to User request to
              delay work for any reason.
            </li>
            <li>
              <b>Payment. </b>
              Any Fees shall be prepaid one month or one year in advance, or as
              otherwise offered on the Site for a particular subscription plan,
              at the option of the User by credit card or another payment method
              accepted on the Site. All prepaid amounts and service plans will
              be reflected in the User account. Any bank fees and charges shall
              be borne solely by User.
            </li>
            <li>
              <b>Refund policy. </b>
              We provide paid Services on a prepaid basis. Users may discontinue
              their use of any Services at any time in accordance with
              instructions posted on the Site. The date and time of any
              cancelation of paid Services shall be the date and time on which
              the User completes the full cancelation process. The Fees for the
              Services may be refundable in whole or in part as set forth herein
              or otherwise provided in writing by DWW.
            </li>
            <li>
              <b>Bad faith activities. </b>
              Repeated registrations or/and subscriptions for paid Services and
              cancellations, followed by requests for refunds, by a User may, in
              our sole discretion, be deemed to be in bad faith, and we reserve
              the right to withhold service to any such offending User and
              refuse any refund otherwise available to such User.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="5">
        <li>
          <b>INTELLECTUAL PROPERTY</b>
          <ol type="a">
            <li>
              <b>All Rights Reserved. </b>
              User acknowledges and agrees that all rights, title, and interest
              to, any and all intellectual property rights of all types or
              nature whatsoever, including, without limitation, patent,
              copyright, trademark, data base rights as well as moral rights,
              know-how and trade secrets (and any licenses in connection with
              any of the same), whether or not registered or capable of
              registration, and whether subsisting in any specific country or
              countries or any other part of the world, in the Services, the
              platform used to provide the Services (technology, hardware,
              software, etc.), any code or software (SDK, API, etc.) which may
              be provided to User or for User's use under this Agreement and any
              work products created and/or delivered herein and related
              documentation (forming the DWW Site and Services) are and will
              remain solely and exclusively our property and/or the property of
              DWW, DWW licensors or affiliates or any other applicable third
              party. User is granted no title or ownership rights in the DWW
              Site or Services. User's right to use the Site, Services and any
              part thereof is strictly limited to the provisions of this
              Agreement and we reserve all rights not expressly granted herein,
              and User may not use any intellectual property rights of DWW, DWW
              licensors or affiliates or any other applicable third party for
              any other purpose.
            </li>
            <li>
              <b>DWW Marks. </b>
              DO WHAT WORKS and the DO WHAT WORKS logo, and the look and feel of
              the materials provided in connection with the Services are
              trademarks, DBAs, and trade dress and/or service marks
              (collectively, the “Marks”) of DWW and/or our affiliates and
              subsidiaries. Other marks, graphics, icons, names and logos used
              or displayed on or through the Site and/or Services are
              trademarks, trade dress and/or service marks are the property of
              their respective owners, who may or may not be affiliated with,
              connected to, or sponsored by us and may be subject to such third
              parties’ terms and conditions. User may not use any metatags or
              any other “hidden text” utilizing any of the aforementioned
              trademarks, trade dress and/or service marks without our and
              respective owner’s prior written permission. User’s right to use
              the Marks is strictly limited to the manner of use as instructed
              and approved by us, which right may be revoked or changed at any
              time at our (or the respective owners’ or licensors’) sole
              discretion. User will accordingly change or remove such display of
              materials immediately upon request by us or the respective Mark
              owners or licensors. User acknowledges and agrees that User shall
              not contest the ownership of the Marks on the Site for any reason.
              User’s permission to use or display of Marks will terminate
              effective upon the termination of this Agreement, suspension of
              the Services or upon notification by us or the respective owner or
              licensor to discontinue such use or display.
            </li>
            <li>
              <b>User&rsquo;s Marks</b>. User hereby grants us a worldwide,
              non-exclusive, unlimited and royalty-free license to use
              User&rsquo;s brands, names, logos, trademarks, trade names and
              service marks as used by User for informational and advertising
              purposes only.
            </li>
            <li>
              <b>Third Party Content Ownership</b>. &nbsp;The Site and Services
              may contain links to other independent third-party websites
              (&ldquo;Properties&rdquo;) and content (including, without
              limitation, text, photographs, images, graphics, designs, audio,
              video, games, applications, software, and files) owned by, or
              originating from, third parties (&ldquo;Third Party
              Content&rdquo;). Such Properties and Third Party Content are not
              under DWW&rsquo;s control, and DWW is not responsible for and does
              not endorse the content of such Properties, including any Third
              Party Content and/or information or materials contained on such
              Properties. DWW&rsquo;s inclusion of any Properties and Third
              Party Content on the Site and/or in the Services does not imply
              approval, partnership, or endorsement of such Properties or Third
              Party Content by DWW. If you follow a link to a Property or
              otherwise access a third party website, you do so solely at your
              own risk, and neither this Agreement nor DWW&rsquo;s Privacy
              Policy and other policies and practices apply to your use or
              access of such Properties. Reference to any products, services,
              content, or other information, whether by trade name, trademark,
              service mark, manufacturer, supplier, or otherwise, does not
              constitute or imply sponsorship, endorsement, or recommendation
              by, or any affiliation with, DWW. You will need to make your own
              independent judgment regarding your interaction with Properties.
              You are solely responsible for complying with all of the terms and
              conditions and privacy policies applicable to your use of such
              Properties or Third Party Content. DWW makes no representations
              and/or warranties concerning the Properties and/or Third Party
              Content, and User uses such properties and materials at its own
              risk.
            </li>
            <li>
              <b>DMCA. </b>
              If you believe that any material on, in, or connected to the Site
              constitutes copyright infringement, you shall provide DWW with the
              following information: (a) an electronic or physical signature of
              the person authorized to act on behalf of the owner of the
              copyrighted work; (b) an identification of the copyrighted work
              and the location on the Site of the allegedly infringing work; (c)
              a written statement that you have a good faith belief that the
              disputed use is not authorized by the owner, its agent, or the
              law; (d) your name and contact information, including telephone
              number and email address; and (e) a statement by you that the
              above information in your notice is accurate and, under penalty of
              perjury, that you are the copyright owner or authorized to act on
              the copyright owner&rsquo;s behalf.
              <p>
                Our DMCA Agent for notice of claims of copyright infringement
                is:
              </p>
              <p>
                legal@dowhatworks.io
                <br />
                <br />
                Do What Works <br />
                Attn: Legal Department <br />
                <br />
                100 Red Hill Rd
                <br />
                Middletown, NJ 07748 <br />
                (862) 256-3455
              </p>
            </li>
            <li>
              <b>Feedback. </b>
              Users are under no obligation to give DWW any ideas, suggestions,
              comments or other feedback related to the Site, the Services, or
              the business or operations of DWW. If any User shares ideas,
              suggestions, comments, or other feedback with DWW, DWW will own
              such idea, suggestion, comment or feedback. User hereby assigns
              all of User&rsquo;s right, title, and interest in such idea,
              suggestion, comment, or feedback to DWW and agrees that DWW will
              be free to use and implement same, without restriction or
              obligation of any kind, without, however, any obligation to do so.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="6">
        <li>
          <b>TERMINATION</b>
          <ol type="a">
            <li>
              <b> Termination right. </b>
              DWW may terminate this Agreement at any time in its sole
              discretion with 30 days prior written notice to you.
            </li>
            <li>
              <b>Effect of termination. </b>
              Upon termination of this Agreement, all rights of the affected
              User with respect to the use of Site or Services shall terminate
              immediately.
            </li>
            <li>
              <b> Survival. </b>
              Upon any termination of this Agreement for any reason, all
              provisions regarding indemnification, warranty, liability and
              limits thereon, and confidentiality and protection of proprietary
              rights and trade secrets, and any provisions which expressly or by
              their nature are required to survive such termination in order to
              achieve their purpose, shall so survive until it shall no longer
              be necessary for them to survive in order to achieve their
              purpose.
            </li>
            <li>
              <b>Additional actions resulting from termination. </b>
              <ol type="i">
                <li>
                  DWW may disclose any information we have about you (including
                  your identity) if we determine that such disclosure is
                  necessary in connection with any investigation or complaint
                  regarding your use of the Site and/or Services, or to
                  identify, contact, or bring legal action against someone who
                  may be causing injury to or interference with (either
                  intentionally or unintentionally) DWW&rsquo;s rights or
                  property, or the rights or property of visitors to or users of
                  the Site and/or Services, including DWW&rsquo;s customers. DWW
                  reserves the right at all times to disclose any information
                  that DWW deems necessary to comply with any applicable law,
                  regulation, legal process, or governmental request. DWW also
                  may disclose your information when we determine that
                  applicable law requires or permits such disclosure, including
                  exchanging information with other companies and organizations
                  for fraud protection purposes.
                </li>
                <li>
                  You acknowledge and agree that DWW may preserve any
                  transmittal or communication by you with DWW through the Site
                  or any service offered on or through the Site, and may also
                  disclose such data, if required to do so by law or if DWW
                  determines that such preservation or disclosure is reasonably
                  necessary either to (1) comply with legal process, (2) enforce
                  this Agreement, (3) respond to claims that any such data
                  violates the rights of others, or (4) protect the rights,
                  property, or personal safety of DWW, its agents and employees,
                  users of the Site and/or Services, and the public.
                </li>
                <li>
                  You agree that DWW may, in its sole discretion and without
                  prior notice, terminate your access to the Site and/or
                  Services and/or block your future access to the Site and/or
                  Services, if we determine that you have violated this
                  Agreement or other agreements or guidelines which may be
                  associated with your use of any of our Services. You also
                  agree that any violation by you of this Agreement will
                  constitute an unlawful and unfair business practice, and will
                  cause irreparable harm to DWW, for which monetary damages
                  would be inadequate, and you consent to DWW obtaining any
                  injunctive or equitable relief that DWW deems necessary or
                  appropriate in such circumstances. These remedies are in
                  addition to any other remedies DWW may have at law or in
                  equity.
                </li>
                <li>
                  If DWW does take any legal action against you as a result of
                  your violation of this Agreement, DWW will be entitled to
                  recover from you, and you agree to pay, all reasonable
                  attorneys&rsquo; fees and costs of such action, in addition to
                  any other relief granted to DWW. You agree that DWW will not
                  be liable to you or to any third party for termination of your
                  access to the Site and/or Services as a result of any
                  violation of this Agreement.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="7">
        <li>
          <b>WARRANTY DISCLAIMER &amp; LIMITATION OF LIABILITY. </b>
          <ol type="a">
            <li>
              <b>Disclaimer. </b>
              EXCEPT WHERE PROHIBITED BY LAW, THE SERVICES AND THE SITE ARE
              PROVIDED &ldquo;AS-IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; AND WE
              EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
              ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT
              THE SERVICES OR THE SITE (A) WILL MEET YOUR REQUIREMENTS; (B) WILL
              BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
              BASIS; OR (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER
              HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. WE FURTHER MAKE NO
              WARRANTIES OR REPRESENTATIONS REGARDING THE ACCURACY OR
              COMPLETENESS OF THE CONTENT ON ANY SITES LINKED TO ON THE SITE.
              &nbsp;YOU USE THE SITE AND SERVICES AT YOUR OWN RISK.
            </li>
            <li>
              <b>Limitation of Liability. </b>
              In no event shall DWW, its officers, directors, employees, or
              agents, be liable to you or to any third party for any direct,
              indirect, incidental, special, punitive, or consequential damages
              whatsoever arising from or related to either this Agreement, or
              use of the Services or the Site. Our liability to you for any
              damages arising from or related to this Agreement, will at all
              times be limited to the greater of (a) fifty dollars ($50) or (b)
              amounts you have paid to us in the prior 12 months (if any). The
              existence of more than one claim will not enlarge this limit. The
              foregoing limitation of liability shall apply to the fullest
              extent permitted by law in the applicable jurisdiction.
            </li>
            <li>
              <b>Third Party Products and Services. </b>
              DWW does not warrant, endorse, guarantee, or assume responsibility
              for any product or service advertised or offered by a third party
              through the Site or any hyperlinked website or featured in any
              banner or other advertising, and DWW will not be a party to or in
              any way be responsible for monitoring any transaction between you
              and third-party providers of products or services.
            </li>
            <li>
              <b>Other Jurisdictions. </b>
              We make no representations that the Services or the Site are
              appropriate or available for use in all locations. Those who
              access or use the Services or the Site from jurisdictions
              prohibiting such use, do so at their own volition and are
              responsible for compliance with local law.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="8">
        <li>
          <b>INDEMNITY. </b>
          You agree to defend, indemnify and hold harmless DWW and its officers,
          directors, employees and agents, from and against any and all claims,
          damages, obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney&rsquo;s fees) arising from: (a)
          your use of and access to the Services and the Site; (b) your
          violation of any term of this Agreement; or (c) your violation of any
          third party right, including without limitation any copyright,
          property, or privacy right. This defense and indemnification
          obligation will survive the termination of this Agreement and your use
          of the Services and the Site.
        </li>
        <li>
          <b>EXPORT RESTRICTIONS. </b>
          Exports, re-exports, and transfers of DWW products and services,
          including technology, software, software source code, technical data,
          related technology, and the direct products thereof, including the
          Site content and the Services (the &ldquo;DWW Items&rdquo;) are
          subject to US export controls and sanctions, the most important of
          which are administered by the Commerce Department&rsquo;s Bureau of
          Industry and Security (&ldquo;BIS&rdquo;) under its Export
          Administration Regulations (&ldquo;EAR&rdquo;), the Treasury
          Department&rsquo;s Office of Foreign Assets Controls
          (&ldquo;OFAC&rdquo;) under its Foreign Assets Control Regulations, and
          other applicable export control laws and regulations of non-U.S.
          government agencies. You may not access, download, distribute, use,
          export, or re-export the DWW Items in violation of United States
          export laws or regulations, or in violation of any other applicable
          laws or regulations. You agree to comply with all export laws and
          restrictions and regulations of any United States or foreign agency or
          authority, and not to directly or indirectly provide or otherwise make
          available the DWW items in violation of any such restrictions, laws or
          regulations, or without all necessary approvals, including, without
          limitation, for the development, design, manufacture or production of
          nuclear, chemical or biological weapons of mass destruction nor will
          you use the DWW Items for a military end-use or a military end-user in
          China, Russia or any other country designated in EAR Supplement No. 1
          to Part 740, Country Group D1. The DWW Items may not be downloaded or
          otherwise provided or made available, either directly or indirectly,
          (i) into Iran, Libya, North Korea, Sudan, Myanmar, Syria or any other
          country subject to U.S. trade sanctions, to individuals or entities
          controlled by such countries, or to nationals or residents of such
          countries other than nationals who are lawfully admitted permanent
          residents of countries not subject to such sanctions; or (ii) to
          anyone on the U.S. Treasury Department&#39;s list of Specially
          Designated Nationals and Blocked Persons or the U.S. Commerce
          Department&#39;s Table of Denial Orders. By agreeing to this
          Agreement, you agree to the foregoing and represent and warrant that
          you are not located in, under the control of, or a national or
          resident of any such country or on any such list and that you will not
          share the DWW Items with anyone whose status is described in items (i)
          and (ii) above.
        </li>
        <li>
          <b>GENERAL PROVISIONS</b>
          <ol type="a">
            <li>
              <b>Assignment. </b>
              This Agreement, any part thereof or any rights or obligations
              under it may not be novated, assigned, outsourced or transferred
              by you without our advance written consent, but may be assigned by
              us without restriction or limitations. Any assignment or transfer
              in violation of the aforementioned provisions shall be deemed null
              and void. Subject to the forgoing, the provisions of this
              Agreement shall be binding upon and inure to the benefit of the
              parties hereto and their respective successors and assigns.
            </li>
            <li>
              <b>Force Majeure. </b>
              We shall not be liable for failing or delaying performance of our
              obligations resulting from any condition beyond our reasonable
              control, including but not limited to, use of third parties&rsquo;
              equipment or services, communications failure, governmental
              action, war, acts of terrorism, earthquake, fire, flood or other
              acts of God, labor conditions, power failures, and Internet
              disturbances.
            </li>
            <li>
              <b> Governing Law and Jurisdiction. </b>
              This Agreement and any dispute arising between the parties in
              connection with it shall be governed by the laws of the State of
              New York without reference to its conflicts of law provisions
              and/or principles. The federal and/or state courts in the State of
              New York, as applicable, shall have exclusive jurisdiction in any
              legal proceedings resulting or connected with this Agreement, and
              the User hereby irrevocably submits to such exclusive
              jurisdiction. However, this shall not prevent us from bringing any
              action in the court of any other jurisdiction for injunctive or
              similar relief.
            </li>
            <li>
              <b>Electronic Notices. </b>
              You agree to receive communications from us in an electronic form.
              Electronic notices will be delivered to your email address, which
              you used for registration purposes, as it may be subsequently
              changed by you by written notice to us. All communications in
              electronic format will be considered to be &ldquo;in
              writing&rdquo; and to have been received on the day that we send
              them. We reserve the right, but assume no obligation, to provide
              communications in paper format.
            </li>
            <li>
              <b>Entire Agreement. </b>
              This Agreement, together with the{" "}
              <a href="/privacy">Privacy Policy</a>, shall constitute the entire
              agreement between you and DWW concerning your use of the Site and
              the Services.
            </li>
            <li>
              <b>No Waiver. </b>
              No failure or delay by a party to exercise any right or remedy
              provided under this Agreement or by law shall constitute a waiver
              of that (or any other) right or remedy, nor preclude or restrict
              its further exercise. No single or partial exercise of such right
              or remedy shall preclude or restrict the further exercise of that
              (or any other) right or remedy; and will not be construed as a
              waiver of any subsequent breach or default under the same or any
              other provision of this Agreement.
            </li>
            <li>
              <b>Severability. </b>
              All the provisions of this Agreement are distinct and severable.
              If any provision of this Agreement (or part of any provision) is
              found by any court or other authority of competent jurisdiction to
              be invalid, unenforceable or illegal, this shall not impair the
              operation of this Agreement or affect the other provisions which
              are valid.
            </li>
          </ol>
        </li>
      </ol>
      <ol start="11">
        <li>
          <b>PRIVACY. </b>
          Use of the Services is also governed by our{" "}
          <a href="/privacy">Privacy Policy</a>, the provisions of which are
          adopted herein by reference so when we refer to this Agreement we also
          refer to the Privacy Policy.
        </li>
        <li>
          <b>CONTACT. </b>
          We are always available to be reached by email at help@dowhatworks.io
        </li>
      </ol>
      <p>
        Last updated: <b>10/1/2019</b>
      </p>
    </div>
  );
};

export default Terms;
