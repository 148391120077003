import Eye from "../icons/eye_green.svg";
import Alert from "../icons/exclamation_purple.svg";
import Chart from "../icons/chart_blue.svg";
import X from "../icons/red_x.svg";
import Modal from "@material-ui/core/Modal";
import Screenshot from "../img/free_trial_screenshots.png";
import LockIcon from "../icons/lock_purple.svg";
import Bg from "../img/free_trial_gradient.png";
import { useEffect, useState, useLayoutEffect } from "react";
const height = 450;
const width = 800;

const FreeTrialSalesModal = ({
  doOpenModal,
  unlockedTests,
  unlock_limit,
  modalCallback,
  isOnboarding,
}) => {
  const [modal, setModal] = useState(doOpenModal || false);
  const openModal = e => {
    e.preventDefault();
    setModal(true);
    if (modalCallback) {
      modalCallback(true);
    }
  };
  const closeModal = e => {
    e.preventDefault();
    setModal(false);
    if (modalCallback) {
      modalCallback(false);
    }
  };

  return (
    <Modal
      open={modal ? true : false}
      className="dww-modal-container free-trial-actions"
    >
      <div
        className="box"
        style={{
          width: `${width}px`,
          height: `auto`,
          overflow: "hidden",
          top: "100px",
          position: "absolute",
          left: (window.innerWidth - width) / 2,
          outline: 0,
        }}
      >
        <div className="relative">
          {unlockedTests >= unlock_limit && (
            <div
              className="f5 b pa2 dww-purple tc"
              style={{ background: "rgba(0, 0, 0, 0.05)" }}
            >
              <img src={LockIcon} style={{ marginTop: "-2px" }} /> Looks like
              you've run out of tests to unlock!
            </div>
          )}
          <div
            className="tc"
            style={{
              overflow: "hidden",
              display: "flex",
            }}
          >
            <div style={{ top: "6px", right: "12px", position: "absolute" }}>
              <img
                src={X}
                alt="Close"
                className="pointer"
                onClick={closeModal}
              />
            </div>
            <div className="w-50 flex">
              <div
                style={{
                  backgroundImage: `url("${Bg}")`,
                  backgroundSize: "contain",
                  overflow: "hidden",
                  width: "74%",
                }}
              >
                <img
                  src={Screenshot}
                  style={{
                    width: "100%",
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: "49%",
                  }}
                />
              </div>
            </div>
            <div className="pa3 w-50 tl">
              {isOnboarding ? (
                <>
                  <h3
                    className="pv3 f1 tc b"
                    style={{
                      lineHeight: "3.25rem",
                    }}
                  >
                    Welcome!
                  </h3>

                  <div className="pv3">
                    <div>Your team is currently on a free trial.</div>
                    <div className="mt3">
                      DoWhatWorks has gifted you <b>{unlock_limit}</b> tests, so
                      you can see what our platform has to offer.
                    </div>
                    <div className="mt3">
                      Unlock the tests in this dashboard to see images of what's
                      tested, what wins and what loses. Plus get more new tests
                      every week. Get ideas. Prioritize your backlog. Riff on
                      winners to make better bets.
                    </div>
                  </div>
                  <div className="ph4 pv3 tc mt3 mb3">
                    <a
                      className="ui-button modal-action pointer"
                      onClick={closeModal}
                    >
                      Explore
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <h3
                    className="pv3 f1 tc b"
                    style={{
                      lineHeight: "3.25rem",
                    }}
                  >
                    Want to <br />
                    see more?
                  </h3>
                  <div className="flex">
                    <div style={{ width: "100px" }}>
                      <img
                        src={Eye}
                        style={{ minWidth: "24px", marginTop: "-1px" }}
                      />
                    </div>
                    <div className="pl1">
                      <b className="dww-green">See everything.</b> Unlock the
                      tests in this dashboard to see images of what's tested,
                      what wins and what loses. Plus get more new tests every
                      week. Get ideas. Prioritize your backlog. Riff on winners
                      to make better bets.
                    </div>
                  </div>
                  <div className="mt3 flex">
                    <div style={{ width: "52px" }}>
                      <img
                        src={Alert}
                        style={{ width: "22px", marginTop: "-3px" }}
                      />
                    </div>
                    <div className="pl1">
                      <b className="dww-purple">Get Alerts.</b> Be in the know
                      about what companies are testing and what wins in your
                      industry with ongoing alerts.
                    </div>
                  </div>
                  <div className="mt3 flex">
                    <div style={{ width: "58px" }}>
                      <img src={Chart} style={{ width: "24px" }} />
                    </div>
                    <div className="pl1">
                      <b className="dww-blue">Custom Analysis.</b> Ask our
                      research team to help you answer your burning questions
                      using all <b>17k+</b> tests in our vault.
                    </div>
                  </div>
                  <div className="ph4 pv3 tc mt3">
                    <a
                      href="mailto:andres@dowhatworks.io?subject=Subscribing to DoWhatWorks"
                      className="ui-button modal-action"
                      target="_blank"
                    >
                      Get In Touch
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FreeTrialSalesModal;
