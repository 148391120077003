import React from "react";
import { Link } from "react-router-dom";
import request from "request";
import { baseApiUrl } from "../../util";

const types = ["all_tests_link", "sem_link", "link"];
class Companies extends React.Component {
  state = {
    dashboard: this.props.dashboard,
    key: this.props.customer_key,
    loading: this.props.loading,
  };

  onChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  onDashboardTitleChange = key => e => {
    const { value } = e.currentTarget;

    const { dashboard } = this.state;

    const dash = dashboard[key];

    dash.title = value;

    this.setState({ dashboard, dashboardIsDirty: true });
  };
  cleanUrlText = (value, type, link_type) => {
    let val = value;
    if (
      val &&
      val.startsWith("https:") &&
      type === "link" &&
      (link_type === "all_tests_link" || link_type === "sem_link")
    ) {
      if (val.includes("?")) {
        val = `?${val.split("?")[1]}`;
      } else if (val.includes("#")) {
        val = `#${val.split("#")[1]}`;
      }
    }

    return val;
  };
  onDashboardChange = (type, index, key) => e => {
    e.preventDefault();
    const { dashboard } = this.state;
    const { value } = e.target;
    if (type === "delete") {
      if (window.confirm("Sure you want to delete?")) {
        dashboard[index].modules = dashboard[index].modules.filter(
          (x, i) => i !== key
        );
      }
    } else {
      dashboard[index].modules[key][type] = this.cleanUrlText(
        value,
        type,
        dashboard[index].modules[key].type
      );

      if (type === "type") {
        dashboard[index].modules[key]["link"] = this.cleanUrlText(
          dashboard[index].modules[key]["link"],
          "link",
          "all_tests_link"
        );
      }
    }
    this.setState({ dashboard, dashboardIsDirty: true });
  };
  addNewDashboardItem = index => e => {
    e.preventDefault();
    const { dashboard } = this.state;
    dashboard[index].modules.push({ title: "", type: "" });
    this.setState({
      dashboard,
    });
  };
  addNewDashboardSection = e => {
    e.preventDefault();
    const { dashboard, add_key } = this.state;
    dashboard[add_key.toLowerCase()] = {
      title: "",
      modules: [],
    };
    this.setState(
      {
        dashboard,
      },
      () => {
        setTimeout(() => {
          const elm = document.getElementById(add_key).getBoundingClientRect();
          window.scrollTo(0, elm.top);
        }, 300);
      }
    );
  };
  save = e => {
    this.setState({ loading: true });
    const { dashboard } = this.state;

    Object.keys(dashboard).forEach((d, i) => {
      dashboard[d].position = i;
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/admin/customer/dashboard`,
        form: {
          key: this.props.customer_key,
          dashboard: JSON.stringify(dashboard),
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
          });
          return;
        }
        if (!err) {
          this.setState({
            saving: false,
          });
          this.saveCallback();
        }
      }
    );
  };
  saveCallback = () => {
    this.setState({ loading: false });
  };
  moveUp = (key, i) => e => {
    const dashboard = this.state.dashboard;
    const dash = dashboard[key];

    const item = dash.modules.splice(i, 1);
    dash.modules.splice(i - 1, 0, item[0]);
    this.setState({ dashboard, dashboardIsDirty: true });
  };
  moveDown = (key, i) => e => {
    const dashboard = this.state.dashboard;
    const dash = dashboard[key];
    const item = dash.modules.splice(i, 1);
    dash.modules.splice(i + 1, 0, item[0]);
    this.setState({ dashboard, dashboardIsDirty: true });
  };
  moveDownSection = i => e => {
    const dashboard = this.state.dashboard;
    const dbKeys = Object.keys(this.state.dashboard);
    const item = dbKeys.splice(i, 1);
    dbKeys.splice(i + 1, 0, item[0]);

    const newDB = {};

    dbKeys.forEach((key, i) => {
      dashboard[key].position = i;
      newDB[key.toLowerCase()] = dashboard[key];
    });

    this.setState({ dashboard: newDB, dashboardIsDirty: true });
  };
  moveUpSection = i => e => {
    const dashboard = this.state.dashboard;
    const dbKeys = Object.keys(this.state.dashboard);
    const item = dbKeys.splice(i, 1);
    dbKeys.splice(i - 1, 0, item[0]);

    const newDB = {};

    dbKeys.forEach((key, i) => {
      dashboard[key].position = i;
      newDB[key.toLowerCase()] = dashboard[key];
    });

    this.setState({ dashboard: newDB, dashboardIsDirty: true });
  };
  onRemoveSection = d => e => {
    if (window.confirm("Are you sure you want to remove this section?")) {
      const { dashboard } = this.state;

      delete dashboard[d];

      this.setState({ dashboard });
    }
  };
  render() {
    const { dashboard } = this.state;
    const dash = {};
    const keys = Object.keys(this.state.dashboard).sort((a, b) =>
      dashboard[a].position >= dashboard[b].position ? 1 : -1
    );

    keys.forEach(k => {
      dash[k] = dashboard[k];
    });

    return (
      <div>
        <h1>{this.props.customer_name} Overview Sections</h1>
        <div className="mb3">
          <Link to="/admin/customers">back</Link>
          <div className="mt3 mb5">
            <input
              type="text"
              name="add_key"
              onChange={this.onChange}
              placeholder="Key"
              className="mr2"
            />
            <button
              onClick={this.addNewDashboardSection}
              className="ui-button primary"
            >
              Add new section{" "}
            </button>
          </div>
          {Object.keys(dash).map((d, i) => {
            const dashboard = dash[d];
            return (
              <div id={d} key={d}>
                <div className="flex mt3 pt3">
                  <div className="w-1 pr3">
                    {i !== 0 && (
                      <div className="pb2">
                        <button
                          className="ui-button small secondary up"
                          title="Move up"
                          onClick={this.moveUpSection(i)}
                        >
                          &#x2191;
                        </button>
                      </div>
                    )}
                    {i < Object.keys(this.state.dashboard).length - 1 && (
                      <div>
                        <button
                          className="ui-button small secondary down"
                          title="Move down"
                          onClick={this.moveDownSection(i)}
                        >
                          &#x2193;
                        </button>
                      </div>
                    )}
                  </div>
                  <h3>
                    <div>
                      <label>Section Title</label>
                    </div>
                    <input
                      type="text"
                      value={dashboard.title}
                      onChange={this.onDashboardTitleChange(d)}
                      name={`title_${d}`}
                    />
                    <div className="mt2">
                      <a
                        href=" #"
                        onClick={this.onRemoveSection(d)}
                        className="ui-button secondary tiny"
                      >
                        remove section
                      </a>
                    </div>
                  </h3>
                </div>
                {dashboard.modules.map((m, z) => {
                  return (
                    <div
                      className="pt3 ml5 mb3 bb b--black-05 flex"
                      name={`module|${i}|${z}`}
                    >
                      <div className="w-50">
                        <div>
                          <label>Title</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            name={`title|${i}|${z}`}
                            value={m.title}
                            className="mb3 w-75"
                            onChange={this.onDashboardChange("title", d, z)}
                          />
                        </div>
                        <div>
                          <label>Link</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            name={`link|${d}|${z}`}
                            value={m.link}
                            className="mb3 w-75"
                            onChange={this.onDashboardChange("link", d, z)}
                          />
                        </div>
                        <div className="mt2 mb4">
                          <select
                            name={`type|${d}|${z}`}
                            onChange={this.onDashboardChange("type", d, z)}
                            value={m.type}
                          >
                            <option value="">--- Select ---</option>
                            {types.map(t => (
                              <option value={t}>{t}</option>
                            ))}
                          </select>
                          <div className="mt2">
                            <a
                              href=" #"
                              onClick={this.onDashboardChange("delete", d, z)}
                              className="ui-button secondary tiny"
                            >
                              remove
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="w-50">
                        {z !== 0 && (
                          <div className="pb2 pt2">
                            <button
                              className="ui-button small secondary up"
                              title="Move up"
                              onClick={this.moveUp(d, z)}
                            >
                              &#x2191;
                            </button>
                          </div>
                        )}
                        {z < dashboard.modules.length - 1 && (
                          <div>
                            <button
                              className="ui-button small secondary down"
                              title="Move down"
                              onClick={this.moveDown(d, z)}
                            >
                              &#x2193;
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="mv3 ml5">
                  <a
                    href=" #"
                    onClick={this.addNewDashboardItem(d)}
                    className="ui-button secondary"
                  >
                    Add new
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sticky-submit">
          <div className="tr">
            {this.state.loading ? (
              <p>Saving...</p>
            ) : (
              <>
                {this.state.dashboardIsDirty ? (
                  <button className="ui-button primary" onClick={this.save}>
                    Save
                  </button>
                ) : (
                  <button className="ui-button disabled">Save</button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Companies;
