import React from "react";
import * as cx from "classnames";
class InputCounter extends React.Component {
  state = {
    show: false,
    value: this.props.value || "",
    charsLeft: this.props.value
      ? this.props.total - this.props.value.length
      : this.props.total,
  };
  handleChange = e => {
    const count = e.currentTarget.value.length;
    const charsLeft = this.props.total - count;

    let value = e.currentTarget.value;

    if (charsLeft <= 0) {
      value = this.state.value;
    }

    this.setState({
      value,
      charsLeft,
      closeToEnd: charsLeft < 10,
    });

    this.props.callback(value);
  };
  render() {
    return (
      <div>
        <input
          name={this.props.name}
          className={this.props.className}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          maxLength={this.props.total}
          defaultValue={this.props.value}
          ref={this.props.inputRef}
        />
        <div
          className={cx({
            "char-counter f7 tr": true,
            "dww-gray": !this.state.closeToEnd,
            red: this.state.closeToEnd,
          })}
        >
          {this.state.charsLeft}
        </div>
      </div>
    );
  }
}

export default InputCounter;
