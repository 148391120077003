import React from "react";
import { formatReportDate } from "../util";
import BigArrow from "../icons/BigArrow.svg";
import BigArrowRL from "../icons/BigArrowRL.svg";
import cx from "classnames";

const ReportDates = ({ start_date, end_date, longView }) => (
  <>
    {(start_date || end_date) && (
      <>
        {start_date && (
          <div
            className={cx({
              "dates-box": true,
            })}
          >
            <div className="box-header">Detected</div>
            <div>{formatReportDate(start_date)}</div>
          </div>
        )}
        {end_date ? (
          <>
            {longView && (
              <img src={BigArrowRL} className="big-arrow" alt="arrow" />
            )}
            <div className="dates-box">
              {!longView && (
                <img src={BigArrow} className="big-arrow" alt="arrow" />
              )}
              <div className="box-header">Declared</div>
              <div>{formatReportDate(end_date)}</div>
            </div>
          </>
        ) : (
          <>
            {longView && (
              <img src={BigArrowRL} className="big-arrow" alt="arrow" />
            )}
            <div className="dates-box dates-box-last">
              {!longView && (
                <img src={BigArrow} className="big-arrow" alt="arrow" />
              )}
              <div className="box-header">Declared</div>
              <div>TBD</div>
            </div>
          </>
        )}
      </>
    )}
  </>
);

export default ReportDates;
