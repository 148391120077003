import React, { useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import * as qs from "query-string";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./header.css";
import { SHOW_ADMIN_BOX_KEY } from "../util";

const Header = ({ isHome, handleLogout, user, loading }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const disableCache = e => {
    e.preventDefault();
    const currentQS = qs.parse(window.location.search);
    currentQS["no_cache"] = true;
    const qsString = Object.keys(currentQS)
      .map(q => `${q}=${currentQS[q]}`)
      .join("&");
    console.info(qsString);
    window.location.replace(
      `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qsString}`
    );
  };
  const toggleAdminboxCookie = e => {
    e.preventDefault();
    const data = localStorage.getItem(SHOW_ADMIN_BOX_KEY);
    if (data) {
      localStorage.removeItem(SHOW_ADMIN_BOX_KEY);
      window.location.reload();
    } else {
      localStorage.setItem(SHOW_ADMIN_BOX_KEY, "1");
      window.location.reload();
    }
  };
  return (
    <header
      className={cx({
        "App-header": true,
        " sticky-app-header home-header": isHome,
      })}
    >
      <nav
        className="header-inner border-box ph1 ph3-ns pv2 flex center"
        id="header-button"
      >
        <div className="w-40 tl flex items-center" style={{ height: "60px" }}>
          <a
            className={cx({
              "b f5 ui-link dim logo": true,
              black: isHome,
              white: !isHome,
            })}
            href="https://dowhatworks.io/"
            title="Home"
          >
            DoWhatWorks
          </a>
        </div>
        {!loading && (
          <div className="w-60 tr dropdown-parent">
            <div>
              {isHome && (
                <div className="mr3 pr3 dib pv2 header-box">
                  <div
                    className={cx({
                      "f5 dib header-button": true,
                      "dn-s": user,
                    })}
                  >
                    <a
                      href="https://dowhatworks.typeform.com/to/Gq5K8S2E"
                      className="ui-button primary ui-button-home mr3"
                    >
                      Learn More
                    </a>
                  </div>
                  <a
                    href="https://www.blog.dowhatworks.io/"
                    className="header-link dim dn-s"
                  >
                    Blog
                  </a>
                </div>
              )}
              {user ? (
                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dib">
                  <DropdownToggle caret>{user.name}</DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem href={`/account`}>Account</DropdownItem>
                    <DropdownItem href={`/home/${user.company}`}>
                      Dashboard
                    </DropdownItem>
                    {user.company === "admin" && (
                      <DropdownItem href="/admin">Admin</DropdownItem>
                    )}
                    {user.company === "admin" && (
                      <DropdownItem href="/home/admin/collections">
                        Collections Library
                      </DropdownItem>
                    )}
                    {user.company === "admin" && (
                      <DropdownItem href="/home/admin/analyses">
                        Analysis Library
                      </DropdownItem>
                    )}
                    <DropdownItem
                      href={`/home/${user.company}/report?watch_test=true`}
                    >
                      My Watched Tests
                    </DropdownItem>
                    {user.company === "admin" && (
                      <DropdownItem href="#" onClick={disableCache}>
                        Disable Cache
                      </DropdownItem>
                    )}
                    {user.company === "admin" && (
                      <DropdownItem href="#" onClick={toggleAdminboxCookie}>
                        Show/Hide admin box
                      </DropdownItem>
                    )}
                    {user.customer &&
                      user.permissions &&
                      user.permissions.role === "admin" && (
                        <DropdownItem href="/account/users">
                          Manage Team
                        </DropdownItem>
                      )}
                    <DropdownItem
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Link
                  to="/login"
                  className={cx({
                    "link f6 f5-l dib": true,
                    "white ui-link": !isHome,
                  })}
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
