import React, { Component, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import request from "request";
import * as querystring from "query-string";
import * as cx from "classnames";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { COUNTRIES, baseApiUrl, encodeForm } from "../../util";
import Loading from "../../components/Loading";
import X from "../../icons/red_x.svg";
import EditIcon from "../../icons/edit_blue.svg";
import TagSearch from "../../components/TagSearch";
import SortableItem from "../../components/SortableItem";
import { displayName } from "react-keyboard-event-handler";
const labelClass = "db fw4 lh-copy black-60 mt4";
const inputClass = "pa2 input-reset ba b--black-20 bg-white br2 mr3";

class ScreenersC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      loading: true,
      invite: {},
      successUser: false,
      deactivate: null,
      inviteDelete: null,
      loadingUser: false,
      inviteLoading: false,
      inviteSucess: false,
      addInviteError: null,
      deactivating: false,
    };
  }
  getUsers = () => {
    const { id } = querystring.parse(window.location.search);

    if (!this.props.user || !this.props.user.token) {
      window.location.replace(`/login?returnUri=${window.location}`);
      return;
    }
    request.get(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/users${id ? `?customer=${id}` : ""}`,
      },
      (err, data) => {
        if (data.statusCode > 400 && data.statusCode < 500) {
          this.setState({ access_error: true, loading: false });
        } else if (data.statusCode >= 500) {
          this.setState({ error: true, loading: false });
        }
        const json = JSON.parse(data.body);
        this.setState({
          loading: false,
          users: json.users,
          invites: json.invites,
          id,
        });
      }
    );
  };
  componentDidMount() {
    this.getUsers();
  }
  onInvite = e => {
    e.preventDefault();
    this.setState({ inviteLoading: true, addInviteError: null });
    const { email, name, permissions } = this.state.invite;
    if (!email || !name || !permissions) {
      alert("Please fill out all the info");
      return;
    }
    fetch(`${baseApiUrl}/v1/invite/ab`, {
      headers: {
        Authorization: this.props.user.token,
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      body: encodeForm({
        email,
        name,
        permissions,
        customer: this.state.id ? this.state.id : this.props.user.company,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          this.setState({ inviteLoading: false, addInviteError: data.error });
          return;
        }
        setTimeout(() => {
          this.getUsers();
        }, 750);

        this.setState(
          {
            inviteLoading: false,
            invite: { name: "", email: "" },
            inviteSuccess: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                inviteSuccess: false,
              });
            }, 2000);
          }
        );
      })
      .catch(e => {
        console.error(e);
      });
  };
  onDeactivateInvite = id => e => {
    const invite = this.state.invites.find(i => i.id === id);
    this.setState({ inviteDelete: invite });
  };
  handleChange = e => {
    const { name } = e.target;
    this.setState({
      [name]: e.target.checked,
    });
  };
  changeRoleUser = id => e => {
    if (e.currentTarget.value !== "") {
      this.setState({
        loadingUser: id,
      });
      fetch(`${baseApiUrl}/v1/user/edit/permissions`, {
        headers: {
          Authorization: this.props.user.token,
          "content-type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body: encodeForm({
          id: id,
          permissions: e.currentTarget.value,
        }),
      })
        .then(res => res.json())
        .then(data => {
          this.setState({ loadingUser: null, successUser: id }, () => {
            setTimeout(() => {
              this.setState({ successUser: null });
            }, 2000);
          });
        })
        .catch(e => {});
    }
  };
  onDeactivate = id => e => {
    const user = this.state.users.find(i => i.id === id);
    this.setState({ deactivate: user });
  };
  onCancelDeactivate = e => {
    this.setState({ deactivate: null });
  };
  onShowInvite = e => {
    e.preventDefault();
    this.setState({ showInvite: true });
  };
  onCancelInvite = e => {
    e.preventDefault();
    this.setState({ inviteDelete: null });
  };
  onChange = e => {
    const { name, value } = e.currentTarget;
    const { invite } = this.state;
    invite[name] = value;
    this.setState({
      invite,
    });
  };
  doRemoveInvite = e => {
    const { inviteDelete } = this.state;
    e.preventDefault();
    this.setState({ removingInvite: true });
    fetch(`${baseApiUrl}/v1/invite/sem`, {
      headers: {
        Authorization: this.props.user.token,
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "DELETE",
      body: encodeForm({
        id: inviteDelete.id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ removingInvite: false, inviteDelete: null }, () => {
          this.getUsers();
        });
      })
      .catch(e => {
        this.setState({ inviteError: true });
      });
  };
  doDeactivate = e => {
    const { deactivate } = this.state;
    e.preventDefault();
    this.setState({ deactivating: true });
    fetch(`${baseApiUrl}/v1/user`, {
      headers: {
        Authorization: this.props.user.token,
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "DELETE",
      body: encodeForm({
        uid: deactivate.id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ deactivating: false, deactivate: null }, () => {
          this.getUsers();
        });
      })
      .catch(e => {
        this.setState({ inviteError: true });
      });
  };
  render() {
    const {
      error,
      saving,
      saved,
      ab_email,
      sem_email,
      showInvite,
      invites,
      users,
      successUser,
      loadingUser,
      inviteLoading,
      inviteError,
      inviteSuccess,
      inviteDelete,
      removingInvite,
      userDelete,
      deactivate,
      addInviteError,
      access_error,
      invite,
      loading,
      deactivating,
    } = this.state;

    const { user } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (access_error) {
      return (
        <main className="pv4">
          <div className="box center br2 bg-white tl pa4 tc">
            <h1 className="mb4">You don't have access to this</h1>
            <p className="f4">
              Please ask the main point of contact at your company for access.
            </p>
            <p className="f4">
              You an also email us at{" "}
              <a href="mailto:help@dowhatworks.io">help@dowhatworks.io</a> with
              any questions.
            </p>
          </div>
        </main>
      );
    }

    return (
      <main className="pv4">
        <div
          className="box center br2 bg-white tl"
          style={{ position: "relative" }}
        >
          <div className="bb b--black-05 ph3 pt3 mb3 pb2">
            <h1>Manage Screeners</h1>
          </div>
          <div className="ph3 pb3 pt2">
            {user.permissions && user.permissions.role === "admin" && (
              <>
                {showInvite ? (
                  <form className="pv2" onSubmit={this.onInvite}>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email"
                      className={inputClass}
                      onChange={this.onChange}
                      value={invite.email}
                    />{" "}
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      className={inputClass}
                      onChange={this.onChange}
                      value={invite.name}
                    />
                    <select
                      onChange={this.onChange}
                      value={invite.permissions ? invite.permissions : null}
                      className="pa2 ba b--black-20 bg-white br2 mr3"
                      name="permissions"
                    >
                      <option value="">-- Choose a role --</option>
                      <option value="screener">Screener</option>
                      <option value="member">Team Member</option>
                      <option value="admin">Admin</option>
                    </select>
                    {inviteLoading ? (
                      <span className="grey">Inviting&hellip;</span>
                    ) : (
                      <input
                        type="submit"
                        value="Invite"
                        className="ui-button primary"
                      />
                    )}
                    {inviteSuccess && (
                      <div className="green b">Invite Sent!</div>
                    )}
                    {addInviteError && (
                      <div className="red b mt1">{addInviteError}</div>
                    )}
                  </form>
                ) : (
                  <div className="pv2">
                    <a
                      href=" #"
                      onClick={this.onShowInvite}
                      className="ui-button primary"
                    >
                      + Invite Colleauges
                    </a>
                  </div>
                )}
              </>
            )}
            {error && (
              <p className="dark-red">
                Whoops, looks like something went wrong. Please try again.
              </p>
            )}
            <div className="flex">
              {user.customer.invite && (
                <div className="pr5 mt3 w-50">
                  <h3>Invites</h3>
                  {!invites ||
                    (invites.length === 0 && (
                      <div className="mv3 gray">No active invites.</div>
                    ))}
                  {invites && invites.length > 0 && (
                    <>
                      {invites.map(user => {
                        return (
                          <div>
                            <div className="mb3 flex">
                              <div className="mr3 pa2" style={{ flexGrow: 1 }}>
                                {user.name} ({user.email})
                              </div>
                              <div className="tr">
                                <select
                                  onChange={this.onChange}
                                  value={
                                    user.permissions
                                      ? user.permissions.role
                                      : null
                                  }
                                  className="pa2 ba b--black-20 bg-white br2 mr3"
                                >
                                  <option value="screener">Screener</option>
                                  <option value="member">Team Member</option>
                                  <option value="admin">Admin</option>
                                </select>
                              </div>
                              <div className="ml2 pv2">
                                <a
                                  href=" #"
                                  onClick={this.onDeactivateInvite(user.id)}
                                  className="red"
                                >
                                  <img
                                    src={X}
                                    height={12}
                                    width={12}
                                    style={{ height: "12px", width: "12px" }}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}
              {users && users.length > 0 && (
                <div
                  className={cx({
                    "mt3 w-50": user.customer.invite,
                    "w-60": !user.customer.invite,
                  })}
                >
                  {this.props.user.customer.invite && <h3>Team</h3>}
                  {users.map(user => {
                    return (
                      <div className="mb3">
                        <div className="flex">
                          {user.status === "deactivated" ? (
                            <>
                              <div
                                className="mr3 pv2 gray"
                                title={`${user.name} (${user.email})`}
                                style={{
                                  flexGrow: 1,
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {user.name} ({user.email})
                              </div>
                              <div className="tr red pv2">Deactivated</div>
                            </>
                          ) : (
                            <>
                              <div
                                className="mr3 pv2"
                                title={`${user.name} (${user.email})`}
                                style={{
                                  flexGrow: 1,
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {user.name} ({user.email})
                              </div>
                              <div className="tr">
                                <select
                                  onChange={this.changeRoleUser(user.id)}
                                  defaultValue={
                                    user.permissions
                                      ? user.permissions.role
                                      : null
                                  }
                                  className="pa2 ba b--black-20 bg-white br2 mr3"
                                >
                                  <option value="">Select a Role</option>
                                  <option value="screener">Screener</option>
                                  <option value="member">Team Member</option>
                                  <option value="admin">Admin</option>
                                </select>
                              </div>
                              <div className="ml2 pv2">
                                <a
                                  href=" #"
                                  onClick={this.onDeactivate(user.id)}
                                  className="red"
                                >
                                  <img
                                    src={X}
                                    style={{ height: "12px", width: "12px" }}
                                  />
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                        {successUser === user.id && (
                          <div className="pt1 green b tr">Saved.</div>
                        )}
                        {loadingUser === user.id && (
                          <div className="pt1 gray b tr">Saving&hellip;</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {inviteDelete && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `500px`,
                    top: "10vh",
                    position: "absolute",
                    left: "40%",
                    overflow: "auto",
                  }}
                >
                  <div className="pa3 f4">
                    Are you sure you want to remove the invite for{" "}
                    {inviteDelete.name} ({inviteDelete.email})?
                  </div>
                  {inviteError && (
                    <div className="pa3 red b">
                      Hmm&hellip; Something went wrong. Please try again.
                    </div>
                  )}
                  <div className="tc pa3 mb4">
                    {removingInvite ? (
                      <>
                        <span className="gray">Removing&hellip;</span>
                      </>
                    ) : (
                      <>
                        <a
                          href=" #"
                          className="ui-button primary"
                          onClick={this.doRemoveInvite}
                        >
                          Remove
                        </a>
                        <a
                          href=" #"
                          className="ml2"
                          onClick={this.onCancelInvite}
                        >
                          Cancel
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </Modal>
            )}
            {deactivate && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `500px`,
                    top: "10vh",
                    position: "absolute",
                    left: "50%",
                    overflow: "auto",
                  }}
                >
                  <div className="pa3 f4">
                    Are you sure you want to deactivate
                    {deactivate.name}'s ({deactivate.email}) account?
                  </div>
                  {inviteError && (
                    <div className="pa3 red b">
                      Hmm&hellip; Something went wrong. Please try again.
                    </div>
                  )}
                  <div className="tc pa3 mb4">
                    {deactivating ? (
                      <>
                        <span className="gray">Deactivating&hellip;</span>
                      </>
                    ) : (
                      <>
                        <a
                          href=" #"
                          className="ui-button primary"
                          onClick={this.doDeactivate}
                        >
                          Deactivate
                        </a>
                        <a
                          href=" #"
                          className="ml2"
                          onClick={this.onCancelDeactivate}
                        >
                          Cancel
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </main>
    );
  }
}
const MultiPreferenceComp = props => {
  const {
    title,
    options,
    keyField = "",
    idField = "",
    defaultOptions,
    callback,
  } = props;
  const [current, setCurrent] = useState(null);
  const [selected, setSelected] = useState(defaultOptions || []);
  const removeItem = id => e => {
    console.log(`called with id`, id);
    e.preventDefault();
    const updatedSelected = selected.filter(item => item[idField] !== id);
    setSelected(updatedSelected);
    callback(updatedSelected);
  };
  const onSelectCurrentOption = vals => {
    console.log({ vals });
    if (vals && Array.isArray(vals) && vals.length > 0) {
      const current = findOption(vals[0], options);
      console.log({ [`found option`]: current });
      if (current) {
        setCurrent(current);
      }
    } else {
      setCurrent(null);
    }
  };
  const onAddCurrentOption = e => {
    e.preventDefault();
    if (current) {
      // const { name, tld } = includeCompany;
      const updatedSelected = [
        ...selected,
        {
          ...current,
          priority: (selected.length || 0) + 1,
        },
      ];
      console.log({ updatedSelected });
      setSelected(updatedSelected);
      setCurrent(null);
      callback(updatedSelected);
    }
  };
  const findOption = (id, options) => {
    if (
      !options ||
      !Array.isArray(options) ||
      (Array.isArray(options) && options.length === 0)
    ) {
      return false;
    } else {
      return options.find(option => option[idField] === id);
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragEnd = event => {
    const { active, over } = event;
    console.log({ active, over });
    if (active.id !== over.id) {
      const oldIndex = selected.findIndex(s => s[idField] === active.id);
      const newIndex = selected.findIndex(s => s[idField] === over.id);
      console.log({ oldIndex, newIndex });
      const updatedSelected = arrayMove(selected, oldIndex, newIndex).map(
        (s, index) => ({ ...s, priority: index + 1 })
      );
      console.log(updatedSelected.map(c => c.priority));
      setSelected(updatedSelected);
      callback(updatedSelected);
    }
  };
  return (
    <>
      <div>
        <h4 className="pt3">{title}</h4>
      </div>
      <div className="flex flex-column">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={options.map(c => c[keyField])}
            strategy={verticalListSortingStrategy}
          >
            {/* {items.map(id => (
              <SortableItem key={id} id={id} />
            ))} */}

            {selected.map(option => {
              return (
                <SortableItem
                  key={option[keyField]}
                  id={option[keyField]}
                  item={option}
                  title={option.title} //TODO: add title to all options passed like companies, sectors, countries
                  removeItem={removeItem}
                ></SortableItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
      <div className="flex justify-start items-start">
        <div className="w-70">
          <TagSearch
            key={current ? current[keyField] : ""}
            options={options.map(option => ({
              label: option.title,
              value: option[idField],
            }))}
            defaultTags={
              current
                ? [
                    {
                      label: current.title,
                      value: current[idField],
                    },
                  ]
                : []
            }
            callback={onSelectCurrentOption}
            placeholder="Add Company"
            menuAtBottom
            title="include companies"
            singular={true}
          />
        </div>
        <button
          className={cx("ui-button w-10 mh2", {
            primary: current,
          })}
          onClick={onAddCurrentOption}
        >
          Add
        </button>
      </div>
    </>
  );
};
const EditScreener = props => {
  const {
    user,
    companies,
    sectors,
    newSectors,
    onSaveScreener,
    onCloseScreenerPref,
  } = props;
  const [userIncludeCompanies, setUserIncludeCompanies] = useState(
    user.pref && user.pref.include_companies ? user.pref.include_companies : []
  );
  const [userExlcudeCompanies, setUserExcludeCompanies] = useState(
    user.pref && user.pref.exclude_companies ? user.pref.exclude_companies : []
  );

  const [userIncludeSectors, setUserIncludeSectors] = useState(
    user.pref && user.pref.include_sectors ? user.pref.include_sectors : []
  );
  const [userExcludeSectors, setUserExcludeSectors] = useState(
    user.pref && user.pref.exclude_sectors ? user.pref.exclude_sectors : []
  );

  const [userIncludeCountries, setUserIncludeCountries] = useState(
    user.pref && user.pref.include_countries ? user.pref.include_countries : []
  );
  const [userExcludeCountries, setUserExcludeCountries] = useState(
    user.pref && user.pref.exclude_countries ? user.pref.exclude_countries : []
  );

  const [excludePriorityCompanies, setExcludePriorityCompanies] = useState(
    user.pref && user.pref.exclude_priority_companies
      ? user.pref.exclude_priority_companies
      : false
  );

  const [excludeHighTestingCompanies, setExcludeHighTestingCompanies] =
    useState(
      user.pref && user.pref.exclude_hightesting_companies
        ? user.pref.exclude_hightesting_companies
        : false
    );

  const [
    numberOfCompaniesAssignedAtCheckIn,
    setNumberOfCompaniesAssignedAtCheckIn,
  ] = useState(
    user.pref && user.pref.numberOfCompaniesAssignedAtCheckIn
      ? user.pref.numberOfCompaniesAssignedAtCheckIn
      : 5
  );

  const [numberOfRetainCompaniesAllowed, setNumberOfRetainCompaniesAllowed] =
    useState(
      user.pref && user.pref.numberOfRetainCompaniesAllowed
        ? user.pref.numberOfRetainCompaniesAllowed
        : 5
    );
  const [allowRetainMoreCompanies, setAllowRetainMoreCompanies] = useState(
    user.pref && user.pref.allow_retain_more_companies
      ? user.pref.allow_retain_more_companies
      : false
  );

  const findCompany = (tld, companies) => {
    if (
      !companies ||
      !Array.isArray(companies) ||
      (Array.isArray(companies) && companies.length === 0)
    ) {
      return false;
    } else {
      return companies.find(company => company.tld === tld);
    }
  };
  const findNewSector = (key, newSectors) => {
    if (
      !newSectors ||
      !Array.isArray(newSectors) ||
      (Array.isArray(newSectors) && newSectors.length === 0)
    ) {
      return false;
    } else {
      return newSectors.find(sector => sector.key === key);
    }
  };

  const saveScreenerPref = e => {
    if (onSaveScreener) {
      onSaveScreener(
        userIncludeCompanies,
        userExlcudeCompanies,
        userIncludeSectors,
        userExcludeSectors,
        userIncludeCountries,
        userExcludeCountries,
        excludePriorityCompanies,
        excludeHighTestingCompanies,
        numberOfCompaniesAssignedAtCheckIn,
        numberOfRetainCompaniesAllowed,
        allowRetainMoreCompanies
      );
    }
  };
  const closeScreenerPref = e => {
    console.log("close screener pref Edit Screener");
    if (onCloseScreenerPref) {
      onCloseScreenerPref("");
    }
  };
  const companyTitleGeneratorFn = c => ({
    ...c,
    title: `${c.name} (${c.tld})`,
  });
  const sectorTitleGeneratorFn = s => ({ ...s, title: s.display_name });
  const countryTitleGeneratorFn = c => ({ ...c, title: c.label });
  const companiesWithTitle = companies.map(companyTitleGeneratorFn);
  const userIncludedCompanieswithTitle = userIncludeCompanies.map(
    companyTitleGeneratorFn
  );
  const userExlcludedCompaniesWithTitle = userExlcudeCompanies.map(
    companyTitleGeneratorFn
  );
  const newSectorsWithTitle = newSectors.map(sectorTitleGeneratorFn);
  const userIncludedSectorsWithTitle = userIncludeSectors.map(
    sectorTitleGeneratorFn
  );
  const userExcludedSectorsWithTitle = userExcludeSectors.map(
    sectorTitleGeneratorFn
  );
  const countriesWithTitle = COUNTRIES.map(countryTitleGeneratorFn);
  const userIncludeCountriesWithTitle = userIncludeCountries.map(
    countryTitleGeneratorFn
  );
  const userExcludeCountriesWithTitle = userExcludeCountries.map(
    countryTitleGeneratorFn
  );
  return (
    <>
      {/* User information */}
      <div>
        <h4 className="pt3">
          {user.name}-({user.email})
        </h4>
        {user.status === "deactivated" ? (
          <>
            <div className="tr red pv2">Deactivated</div>
          </>
        ) : (
          <div className="tr red pv2"></div>
        )}
      </div>

      <MultiPreferenceComp
        title="Include Companies"
        options={companiesWithTitle}
        defaultOptions={userIncludedCompanieswithTitle}
        keyField="tld"
        idField="tld"
        callback={setUserIncludeCompanies}
      />
      <MultiPreferenceComp
        title="Exclude Companies"
        options={companiesWithTitle}
        defaultOptions={userExlcludedCompaniesWithTitle}
        keyField="tld"
        idField="tld"
        callback={setUserExcludeCompanies}
      />
      <MultiPreferenceComp
        title="Include Sectors"
        options={newSectorsWithTitle}
        defaultOptions={userIncludedSectorsWithTitle}
        keyField="key"
        idField="key"
        callback={setUserIncludeSectors}
      />
      <MultiPreferenceComp
        title="Exclude Sectors"
        options={newSectorsWithTitle}
        defaultOptions={userExcludedSectorsWithTitle}
        keyField="key"
        idField="key"
        callback={setUserExcludeSectors}
      />
      <MultiPreferenceComp
        title="Include Countries"
        options={countriesWithTitle}
        defaultOptions={userIncludeCountriesWithTitle}
        keyField="value"
        idField="value"
        callback={setUserIncludeCountries}
      />
      <MultiPreferenceComp
        title="Exclude Countries"
        options={countriesWithTitle}
        defaultOptions={userExcludeCountriesWithTitle}
        keyField="value"
        idField="value"
        callback={setUserExcludeCountries}
      />
      <div className="flex items-center pt3">
        <input
          type="checkbox"
          name="exclude_priority"
          id="exclude_priority"
          checked={excludePriorityCompanies}
          onChange={e => {
            setExcludePriorityCompanies(e.target.checked);
          }}
        ></input>
        <label for="exclude_priority" className="mb0 ph2">
          Exclude Priority companies
        </label>
        {/* <div className="f4 pr3"></div> */}
      </div>

      <div className="flex items-center pt3">
        <input
          type="checkbox"
          name="exclude_hightesting"
          id="exclude_hightesting"
          checked={excludeHighTestingCompanies}
          onChange={e => {
            setExcludeHighTestingCompanies(e.target.checked);
          }}
        ></input>
        <label for="exclude_hightesting" className="mb0 ph2">
          Exclude High Testing companies
        </label>
      </div>

      <div className="flex items-center pt3">
        <input
          type="number"
          name="numberOfCompaniesAssignedAtCheckIn"
          id="numberOfCompaniesAssignedAtCheckIn"
          value={numberOfCompaniesAssignedAtCheckIn}
          onChange={e => {
            const val = Number.parseInt(e.target.value);
            setNumberOfCompaniesAssignedAtCheckIn(val && val > 0 ? val : 0);
          }}
          className="w-5"
        ></input>
        <label for="numberOfCompaniesAssignedAtCheckIn" className="mb0 ph2">
          Number of companies assigned at check-in
        </label>
      </div>

      <div className="flex items-center pt3">
        <input
          type="number"
          name="numberOfRetainCompaniesAllowed"
          id="numberOfRetainCompaniesAllowed"
          value={numberOfRetainCompaniesAllowed}
          onChange={e => {
            const val = Number.parseInt(e.target.value);
            setNumberOfRetainCompaniesAllowed(val && val > 0 ? val : 0);
          }}
          className="w-5"
        ></input>
        <label for="numberOfRetainCompaniesAllowed" className="mb0 ph2">
          Number of Retain Companies Allowed
        </label>
      </div>

      {/* <div className="flex items-center pt3">
        <input
          type="checkbox"
          name="allow_retain_more_companies"
          checked={allowRetainMoreCompanies}
          onChange={e => {
            setAllowRetainMoreCompanies(e.target.checked);
          }}
        ></input>
        <div className="f4 pr3">Allow to retain more companies</div>
      </div> */}
      <div></div>
      {/* Save and Cancel Buttons */}
      <div className="flex justify-start">
        <button
          className="ui-button primary mr3 mv1"
          onClick={saveScreenerPref}
        >
          Save
        </button>
        <button
          className="ui-button secondary mh3 mv1"
          onClick={closeScreenerPref}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
const Screeners = props => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(false);
  const [accessError, setAccessError] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [sectors, setSectors] = useState(null);
  const [newSectors, setNewSectors] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const { user } = props;
  const getScreeners = () => {
    if (!props.user || !props.user.token) {
      window.location.replace(`/login?returnUri=${window.location}`);
      return;
    }
    request.get(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/screeners`,
      },
      (err, data) => {
        if (data.statusCode > 400 && data.statusCode < 500) {
          setAccessError(true);
          setLoading(false);
        } else if (data.statusCode >= 500) {
          setError(true);
          setLoading(false);
        }
        const json = JSON.parse(data.body);
        setUsers(json.users);
        setCompanies(json.companies);
        setSectors(json.sectors);
        setNewSectors(json.newSectors);
        setSelectedUser(null);
        setLoading(false);
      }
    );
  };
  const updateScreener = (
    includeCompanies,
    excludeCompanies,
    includeSectors,
    excludeSectors,
    includeCountries,
    excludeCountries,
    excludePriorityCompanies,
    excludeHighTestingCompanies,
    numberOfCompaniesAssignedAtCheckIn,
    numberOfRetainCompaniesAllowed,
    allowRetainMoreCompanies
  ) => {
    setLoading(true);
    if (!props.user || !props.user.token) {
      window.location.replace(`/login?returnUri=${window.location}`);
      return;
    }
    const vals = {
      includeCompanies: JSON.stringify(includeCompanies),
      excludeCompanies: JSON.stringify(excludeCompanies),
      includeSectors: JSON.stringify(includeSectors),
      excludeSectors: JSON.stringify(excludeSectors),
      includeCountries: JSON.stringify(includeCountries),
      excludeCountries: JSON.stringify(excludeCountries),
      excludePriorityCompanies,
      excludeHighTestingCompanies,
      numberOfCompaniesAssignedAtCheckIn,
      numberOfRetainCompaniesAllowed,
      allowRetainMoreCompanies,
      user_id: selectedUser.id,
    };
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/screeners`,
        form: vals,
      },
      (err, data) => {
        if (data.statusCode > 400 && data.statusCode < 500) {
          setAccessError(true);
          setLoading(false);
        } else if (data.statusCode >= 500) {
          setError(true);
          setLoading(false);
        }
        const json = JSON.parse(data.body);
        getScreeners();
      }
    );
  };
  const onScreenerSelected = userId => e => {
    const selectedUser = !users ? null : users.find(user => user.id === userId);
    if (selectedUser) {
      setSelectedUser(selectedUser);
    }
  };
  const closeScreenerPref = e => {
    //e.preventDefault();
    setSelectedUser(null);
    console.log("close screener pref");
  };
  useEffect(() => {
    getScreeners();
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (accessError) {
    return (
      <main className="pv4">
        <div className="box center br2 bg-white tl pa4 tc">
          <h1 className="mb4">You don't have access to this</h1>
          <p className="f4">
            Please ask the main point of contact at your company for access.
          </p>
          <p className="f4">
            You an also email us at{" "}
            <a href="mailto:help@dowhatworks.io">help@dowhatworks.io</a> with
            any questions.
          </p>
        </div>
      </main>
    );
  }
  return (
    <main className="pv4">
      <div
        className="box center br2 bg-white tl"
        style={{ position: "relative" }}
      >
        <div className="bb b--black-05 ph3 pt3 mb3 pb2">
          <h1>Manage Screeners</h1>
        </div>
        <div className="ph3 pb3 pt2">
          {error && (
            <p className="dark-red">
              Whoops, looks like something went wrong. Please try again.
            </p>
          )}
          <div className="flex">
            {users && users.length > 0 && !selectedUser && (
              <div className={cx("w-100")}>
                {users.map(user => {
                  return (
                    <div
                      className="mb3 pointer"
                      onClick={onScreenerSelected(user.id)}
                      key={user.id}
                    >
                      <div className="flex">
                        <div
                          className={cx("mr3", "pv2", {
                            gray: user.status === "deactivated",
                          })}
                          title={`${user.name} (${user.email})`}
                          style={{
                            flexGrow: 1,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {user.name} ({user.email})
                        </div>
                        {user.status === "deactivated" ? (
                          <>
                            <div className="tr red pv2">Deactivated</div>
                          </>
                        ) : (
                          <div className="tr red pv2"></div>
                        )}
                        {(!selectedUser || selectedUser !== user.id) && (
                          <div className="pv2 mh2">
                            <a
                              href="#"
                              onClick={onScreenerSelected(user.id)}
                              className="red"
                            >
                              <img
                                src={EditIcon}
                                style={{ height: "12px", width: "12px" }}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                      {/* {successUser === user.id && (
                        <div className="pt1 green b tr">Saved.</div>
                      )}
                      {loadingUser === user.id && (
                        <div className="pt1 gray b tr">Saving&hellip;</div>
                      )} */}
                    </div>
                  );
                })}
              </div>
            )}
            {selectedUser && (
              <div className=" w-100 mb3 flex flex-column">
                <EditScreener
                  user={selectedUser}
                  companies={companies}
                  sectors={sectors}
                  newSectors={newSectors}
                  onSaveScreener={updateScreener}
                  onCloseScreenerPref={setSelectedUser}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
export default Screeners;
