import React from "react";
import request from "request";
import Loading from "../../components/Loading";
import TagSearch from "../../components/TagSearch";
import { baseApiUrl } from "../../util";
import * as querystring from "query-string";

class Company extends React.Component {
  state = {
    name: "",
    editing: false,
  };
  input = React.createRef();
  submit = e => {
    const { name } = this.state;
    this.setState({ saving: true });
    e.preventDefault();
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/admin/sem_companies`,
        form: {
          tld: this.props.company.tld,
          name,
        },
      },
      err => {
        if (err) {
          this.setState({
            error: true,
            saving: false,
          });
          alert("Looks like something went wrong!");
          return;
        }
        if (!err) {
          this.setState(
            {
              saving: false,
              displayTerm: "",
              term: "",
              editing: false,
            },
            () => {
              this.props.callback();
            }
          );
        }
      }
    );
  };
  showAdd = e => {
    e.preventDefault();
    this.setState({ editing: true });
  };
  changeCompany = e => {
    this.setState({ name: e.currentTarget.value });
  };
  render() {
    const { company } = this.props;
    const { editing, saving } = this.state;
    return (
      <div className="mb3">
        <form onSubmit={this.submit}>
          <h3>
            <a href={`https://${company.tld}`} target="_blank">
              {company.tld}
            </a>
          </h3>
          {editing && (
            <>
              <div>
                <input
                  name="company"
                  onChange={this.changeCompany}
                  placeholder="Company Name"
                />
              </div>
              <div className="mt2">
                {!saving && (
                  <input
                    type="submit"
                    className="ui-button primary"
                    value="Add"
                  />
                )}
                {saving && <span className="b">Saving&hellip;</span>}
              </div>
            </>
          )}
          <div>
            {company.headlines} headlines &middot; {company.terms.join(", ")}
          </div>
          <div>
            {company.added ? (
              <b>Company added.</b>
            ) : (
              <a href=" #" className="b" onClick={this.showAdd}>
                + add company
              </a>
            )}
          </div>
        </form>
      </div>
    );
  }
}

class Keywords extends React.Component {
  state = {
    loading: true,
    selectedTerms: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    request(
      {
        url: `${baseApiUrl}/v1/admin/sem_companies`,
        headers: {
          Authorization: this.props.user.token,
        },
      },
      (err, res, data) => {
        if (err) {
          this.setState({
            loading: false,
          });
          return;
        }
        const json = JSON.parse(data);

        let selectedTerms = null;
        const { terms } = querystring.parse(window.location.search);

        if (terms) {
          selectedTerms = terms.split("||");
        }

        this.setState({
          loading: false,
          companies: json.companies,
          terms: json.terms,
          selectedTerms: selectedTerms ? selectedTerms : [],
        });
      }
    );
  };
  setTerms = vals => {
    if (vals.length !== 0) {
      this.setState({ selectedTerms: vals });
    } else {
      this.setState({ selectedTerms: null });
    }
  };
  setCompanies = vals => {
    if (vals.length !== 0) {
      this.setState({ selectedCompanies: vals });
    } else {
      this.setState({ selectedCompanies: null });
    }
  };
  markCompanyAsAdded = tld => {
    const { companies } = this.state;
    const company = companies.find(c => c.tld === tld);
    if (company) {
      company.added = true;
    }
    this.setState({ companies });
  };
  filter = () => {
    let ret = this.state.companies;
    if (this.state.selectedTerms && this.state.selectedTerms.length > 0) {
      ret = ret.filter(company => {
        const hasTerm = this.state.selectedTerms.find(t =>
          company.terms.includes(t)
        );
        return hasTerm;
      });
    }
    if (
      this.state.selectedCompanies &&
      this.state.selectedCompanies.length > 0
    ) {
      ret = ret.filter(company =>
        this.state.selectedCompanies.includes(company.tld)
      );
    }
    return ret
      .slice(0, 500)
      .sort((a, b) => (a.headlines >= b.headlines ? -1 : 1));
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const { terms, selectedTerms } = this.state;

    const companies = this.filter();

    return (
      <div>
        <h1>Companies from SEM not in our companies list</h1>

        <div className="flex">
          <div className="w-50 pr2">
            <TagSearch
              options={terms.map(c => ({
                label: c.displayTerm,
                value: c.term,
              }))}
              defaultTags={
                selectedTerms
                  ? selectedTerms.map(c => ({
                      label: c,
                      value: c,
                    }))
                  : null
              }
              callback={this.setTerms}
              placeholder="Search Keywords"
            />
          </div>
          <div className="pl2 w-50">
            <TagSearch
              options={this.state.companies.map(c => ({
                label: c.tld,
                value: c.tld,
              }))}
              callback={this.setCompanies}
              placeholder="Search Companies"
            />
          </div>
        </div>
        {companies.map(c => (
          <Company
            company={c}
            callback={() => {
              this.markCompanyAsAdded(c.tld);
            }}
            user={this.props.user}
          />
        ))}
      </div>
    );
  }
}

export default Keywords;
