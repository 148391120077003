import React, { Component } from "react";
import { Link } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
import * as cx from "classnames";
import { baseApiUrl, getData, loginLookup } from "../util";
import ZoomIn from "../icons/old/zoom_in.svg";
import ZoomOut from "../icons/old/zoom_out.svg";
import SideXSide from "../icons/old/side_x_side_24px_outlined.svg";
import { imageUrl } from "../util";
import ScreenshotInfo from "./ScreenshotInfo";

const zoomStylesMobile = [
  {
    maxHeight: "75vh",
    margin: "0 auto",
  },
  {
    maxWidth: "25vw",
    maxHeight: "130vh",
    margin: "0 auto",
  },
  {
    maxWidth: "25vw",
    margin: "0 auto",
  },
  {
    margin: "0 auto",
  },
];
const zoomStylesDesktop = [
  {
    maxHeight: "75vh",
    margin: "0 auto",
  },
  {
    maxHeight: "140vh",
    margin: "0 auto",
  },
  {
    margin: "0 auto",
  },
  {
    margin: "0 auto",
  },
];

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      zoom: 2,
      currentImage: props.images[0],
      zoomedOut: false,
      sidebyside: false,
      kwData: null,
    };
  }
  zoom = zoomDirection => e => {
    let newZoom = 0;
    const { zoom } = this.state;
    if (zoomDirection === "out" && zoom > 0 && zoom !== 0) {
      newZoom = zoom - 1;
    } else if (zoomDirection === "in" && zoom < 3 && zoom !== 3) {
      newZoom = zoom + 1;
    }

    this.setState({
      zoom: newZoom,
    });

    if (newZoom === 3) {
      this.setState({
        zoomedOut: true,
      });
    } else {
      this.setState({
        zoomedOut: false,
      });
    }
    e.currentTarget.blur();
  };
  next = e => {
    this.handleKeyEvent("right");
  };
  prev = e => {
    this.handleKeyEvent("left");
  };

  handleKeyEvent = (key, e) => {
    const { index } = this.state;
    const { images } = this.props;
    const newIndex = key === "left" ? index - 1 : index + 1;
    if (newIndex < 0) {
      this.setState({
        index: images.length - 1,
        currentImage: images[images.length - 1],
      });
    } else if (images.length === newIndex) {
      this.setState({
        index: 0,
        currentImage: images[0],
      });
    } else {
      this.setState({
        index: newIndex,
        currentImage: images[newIndex],
      });
    }
  };
  doSideBySide = e => {
    e.preventDefault();
    if (this.state.sidebyside) {
      this.setState({
        zoomedIn: false,
        zoomedOut: false,
        sidebyside: false,
      });
    } else {
      this.setState({
        zoomedIn: false,
        zoomedOut: true,
        sidebyside: true,
      });
    }
  };
  handlePickImage = i => e => {
    this.setState({
      index: i,
      currentImage: this.props.images[i],
    });
  };
  componentDidMount() {
    getData({
      url: `${baseApiUrl}/v1/sem/keywords?report=${
        loginLookup[this.props.company]
      }&url=${this.props.url}`,
      headers: {
        Authorization: this.props.user.token,
      },
    }).then(({ err, res, data }) => {
      this.setState({
        kwData: data,
      });
    });
  }
  render() {
    const { currentImage, index, zoom, zoomedOut, sidebyside } = this.state;
    const { images, screenshots, user } = this.props;
    const isTestCalled =
      screenshots.status === "ended" &&
      screenshots.screenshots.find(s => s.champ === true) !== undefined;
    let lStyles =
      screenshots.type === "mobile"
        ? zoomStylesMobile[zoom]
        : zoomStylesDesktop[zoom];
    return (
      <div className="flex flex-wrap">
        {!zoomedOut && (
          <div className="w-100-s w-35 pr0 pr3-ns">
            <div className="box pa3 mv3 sticky-info">
              <div className="sticky-info-scroll">
                <div className="flex mb3">
                  <h1 className="f5 b mb3 w-100">
                    <>
                      {screenshots.company_name && (
                        <>
                          <span className="cat-tag mr1">
                            {screenshots.company_name}
                          </span>{" "}
                          testing
                        </>
                      )}{" "}
                      <span className="page-tag">
                        {screenshots.headline_tag}
                      </span>
                    </>
                  </h1>
                  {user.company === "admin" && (
                    <div className="f7 ttu b w-10 tr">
                      <Link
                        to={`/admin/screenshots/edit/${screenshots.id}?back=${window.location.href}`}
                      >
                        Edit
                      </Link>
                    </div>
                  )}
                </div>
                <div className="mv3 dn db-ns">
                  {images.map((img, i) => (
                    <div className="pr2 w-30 dib">
                      <div
                        className={cx({
                          "pointer ba br3 b--light-gray overflow-hidden": true,
                          "b--blue": index === i,
                        })}
                        onClick={this.handlePickImage(i)}
                        key={`img_${i}`}
                      >
                        <div
                          className="overflow-hidden bg-light-gray ph2 pv2"
                          style={{ maxHeight: "50px" }}
                        >
                          <img
                            src={imageUrl(img.src, 150, 100)}
                            alt={`Screenshot ${i}`}
                          />
                        </div>
                        <div
                          className={cx({
                            "pa2 bt b--light-gray f7": true,
                            "b--blue": index === i,
                          })}
                        >
                          <div className="flex">
                            <div className="w-50">
                              {!isTestCalled && <b>{img.weight}</b>}
                              {isTestCalled && <b>&nbsp;</b>}
                            </div>
                            <div className="w-50 tr">
                              {img.champ && (
                                <span className="purple f7 b">Champ</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            background: `url(${imageUrl(
                              img.src,
                              1150,
                              0,
                              1
                            )}) no-repeat -9999px -9999px`,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <ScreenshotInfo
                  screenshots={screenshots}
                  company={user.company}
                  customer={this.props.company}
                  kwData={this.state.kwData}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={cx({
            "box pa3 mv3": true,
            "w-100-s w-65": !zoomedOut,
            "w-100": zoomedOut,
          })}
        >
          {images.length && (
            <div className="dn db-ns mb1 sticky-zoom">
              {!sidebyside && (
                <>
                  <button
                    onClick={this.zoom("in")}
                    className={cx({
                      "pointer pa2 dib ba br3 b--black-30": true,
                      "shadow-1": zoom !== 3,
                      "bg-light-gray": zoom === 3,
                    })}
                  >
                    <img src={ZoomIn} width="22px" alt="Zoom in" />
                  </button>
                  <button
                    onClick={this.zoom("out")}
                    className={cx({
                      "pointer pa2 dib ba br3 b--black-30 ml2": true,
                      "shadow-1": zoom !== 0,
                      "bg-light-gray": zoom === 0,
                    })}
                  >
                    <img src={ZoomOut} width="22px" alt="Zoom out" />
                  </button>
                </>
              )}
              <button
                onClick={this.doSideBySide}
                className={cx({
                  "pointer pa2 dib ba br3 b--black-30 ml2": true,
                  "shadow-1": !sidebyside,
                  "bg-light-gray": sidebyside,
                })}
                title="View side-by-side"
                style={{ cursor: "pointer" }}
              >
                <img src={SideXSide} width="22px" alt="Side by side view" />
              </button>
            </div>
          )}
          {sidebyside && (
            <div
              className="tc mr3 overflow-auto nowrap"
              style={{ height: "75vh" }}
            >
              {images.map((img, i) => (
                <div
                  className={cx({
                    ml4: i !== 0,
                    "dib tc": true,
                  })}
                  style={{
                    width: screenshots.type === "mobile" ? "25%" : "40%",
                  }}
                  key={img.src}
                >
                  {img.champ && <div className="b f7 purple mb2">Champ</div>}
                  <img
                    src={imageUrl(img.src, 1150, 0, 1)}
                    className="v-top"
                    alt={`Screenshot ${i}`}
                  />
                </div>
              ))}
            </div>
          )}
          {!sidebyside && (
            <>
              <div className="w-100 tc carousel" onClick={this.next}>
                <div className="db mv1 dn-ns center b">
                  {!isTestCalled && <span>{currentImage.weight} - </span>}
                  {this.state.index + 1}{" "}
                  {currentImage.champ && (
                    <span>
                      {" "}
                      - <span className="purple">Champ</span>
                    </span>
                  )}{" "}
                </div>
                <img
                  src={imageUrl(currentImage.src, 1150, 0, 1)}
                  className="w-100-s"
                  style={lStyles}
                  alt={`Screenshot ${this.state.index}`}
                />
              </div>
              <KeyboardEventHandler
                handleKeys={["left", "right"]}
                onKeyEvent={this.handleKeyEvent}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Carousel;
