import { useState } from "react";
import { formatReportDate } from "../util";
import EditIcon from "../icons/edit_blue.svg";
import SaveIcon from "../icons/save.svg";
import DeleteIcon from "../icons/delete_red.svg";
import UndoIcon from "../icons/undo.svg";
import CheckIcon from "../icons/check_gray.svg";
import RedXIcon from "../icons/red_x.svg";
import cx from "classnames";
import PropTypes from "prop-types";
import Linkify from "linkify-react";
const EditableNote = ({
  noteData,
  index,
  isEditable,
  onNoteSave,
  inputCols = 45,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [note, setNote] = useState(noteData.note);
  const getNoteText = noteData => {
    return `${noteData.note}${
      noteData.user ? ` - ${noteData.user.name}` || `` : ``
    } ${noteData.mtime ? formatReportDate(noteData.mtime) : ``}`;
  };
  const [noteText, setNoteText] = useState(getNoteText(noteData));
  const setEditable = isEditable => e => {
    if (isEditable) {
    }
    setIsEditing(isEditable);
  };
  const onEditNote = e => {
    if (e.target.value.trim() !== "") {
      setNote(e.target.value);
    }
  };
  const saveNote = e => {
    e.preventDefault();
    if (note.trim() === "") {
      alert("note can not be emply, please add note or revert to original");
      return;
    } else {
      setIsEditing(false);
      setNoteText(
        getNoteText({
          ...noteData,
          note,
          mtime: new Date().toISOString(),
        })
      );
      onNoteSave(
        {
          ...noteData,
          note,
        },
        index
      );
    }
  };
  const deleteConfirm = e => {
    e.preventDefault();
    onNoteSave(
      {
        ...noteData,
        deleted: true,
      },
      index
    );
    setIsDeleting(false);
  };
  const cancelDelete = e => {
    setIsDeleting(false);
    setIsEditing(false);
  };
  const revertToOriginal = e => {
    setNote(noteData.originalNote);
    onNoteSave(
      {
        ...noteData,
        note: noteData.originalNote,
      },
      index
    );
    setNoteText(getNoteText(noteData));
    setIsEditing(false);
  };
  if (noteData.deleted) {
    return <></>;
  } else {
    return (
      <div
        className={cx("flex pa1  justify-between ", {
          dim: !isEditing,
          "items-center": !isEditing,
        })}
      >
        {isDeleting ? (
          <>
            <p className="mb0 w-80">
              <textarea name="note" cols={inputCols}>
                {note}
              </textarea>
            </p>
            <div className="flex justify-between w-20">
              <img
                src={CheckIcon}
                className="pointer pa3"
                onClick={deleteConfirm}
              />
              <img
                src={UndoIcon}
                className="pointer pa3"
                onClick={cancelDelete}
              />
            </div>
          </>
        ) : isEditing ? (
          <>
            <p className="mb0 w-80">
              <textarea name="note" onChange={onEditNote} cols={inputCols}>
                {note}
              </textarea>
            </p>
            <div className="flex justify-between w-20">
              <img src={SaveIcon} className="pointer pa3" onClick={saveNote} />
              <img
                src={UndoIcon}
                className="pointer pa3"
                onClick={revertToOriginal}
              />
              <img
                src={DeleteIcon}
                className="pointer pa3"
                onClick={e => {
                  setIsDeleting(true);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <p className="mb0">
              <Linkify>{noteText}</Linkify>
            </p>
            {isEditable && (
              <div className="flex justify-between w-20">
                <img
                  src={EditIcon}
                  className="pointer"
                  onClick={setEditable(true)}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
};
EditableNote.propTypes = {
  noteData: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  index: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onNoteSave: PropTypes.func.isRequired,
  inputCols: PropTypes.number,
};
export const isNoteEditable = (note, user) => {
  if (!note.user) {
    return true;
  } else if (note.user.id === user.uid) {
    return true;
  } else {
    return false;
  }
};
export const convertNoteToObj = note => {
  if (typeof note === "string") {
    return {
      note,
      mtime: null,
      user: null,
      originalNote: note,
      deleted: false,
    };
  }
  if (typeof note === "object") {
    if (note.hasOwnProperty("originalNote")) {
      return note;
    } else {
      return {
        ...note,
        originalNote: note.note,
      };
    }
  }
};
export default EditableNote;
