import WatchOffIcon from "../icons/watch_off.svg";
import ShareIcon from "../icons/share.svg";
import BookmarkIcon from "../icons/bookmark_off_solo.svg";
import LockIcon from "../icons/lock_purple.svg";
import UnlockIcon from "../icons/lock_open_purple.svg";
import ChampBig from "../icons/ChampNoDS.svg";
import Lock from "../icons/lock_purple.svg";
import Unlock from "../icons/lock_open_purple.svg";
import UnlockWhite from "../icons/lock_open_white.svg";
import Eye from "../icons/eye_green.svg";
import Alert from "../icons/exclamation_purple.svg";
import Chart from "../icons/chart_blue.svg";
import X from "../icons/red_x.svg";
import Modal from "@material-ui/core/Modal";
import Screenshot from "../img/free_trial_screenshots.png";
import Bg from "../img/free_trial_gradient.png";
import { useEffect, useState } from "react";
import FreeTrialSalesModal from "./FreeTrialSalesModal";
import request from "request";
import { baseApiUrl } from "../util";
import * as cx from "classnames";

const FreeTrialActions = ({
  hideCollections,
  showModal = false,
  testId,
  user,
  company,
  testUnlocked = false,
  unlockedTests = 0,
  unlock_limit = 0,
  unlockedBy,
}) => {
  const [modal, setModal] = useState(false);
  const [unlock_modal, setUnlockModal] = useState(false);
  const [unlock_hover, setUnlockHover] = useState(false);
  const [apiLoading, setAPILoading] = useState(false);
  const [error, setError] = useState(null);
  const openUnlockModal = e => {
    setUnlockModal(true);
  };
  const closeUnlockModal = e => {
    setUnlockModal(false);
  };
  const openModal = e => {
    e.preventDefault();
    setModal(true);
  };
  const closeModal = e => {
    e.preventDefault();
    setModal(false);
  };
  const doUnlock = e => {
    e.preventDefault();
    if (unlockedTests >= unlock_limit && user.company !== "admin") {
      setModal(true);
    } else {
      if (!testUnlocked) {
        openUnlockModal();
      }
    }
  };
  const UnlockHover = e => {
    setUnlockHover(true);
  };
  const UnlockBlur = e => {
    setUnlockHover(false);
  };
  useEffect(() => {
    if (showModal) {
      setModal(true);
    }
  }, [showModal]);

  const unlockTest = e => {
    if (!user || !testId || !company) {
      return;
    }
    if (unlock_limit <= Number(unlockedTests) && user.company !== "admin") {
      return;
    }
    setAPILoading(true);
    request.post(
      {
        url: `${baseApiUrl}/v1/user/unlock_test`,
        headers: {
          Authorization: user ? user.token : "",
        },
        form: {
          test_id: testId,
          company,
        },
      },
      err => {
        setAPILoading(false);
        if (err) {
          setError(err);
          return;
        } else {
          window.location.reload(true);
        }
      }
    );
  };
  const height = 450;
  const width = 800;
  const unlock_width = 450;
  const unlock_height = 400;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        {/* unlock_limit > 0 && (
          <>
            {testUnlocked ? (
              <img
                title={
                  unlockedBy === "admin"
                    ? `This test was unlocked for you by the DoWhatWorks Team.`
                    : `This test is unlocked.`
                }
                src={UnlockIcon}
                color="primary"
                className="share-icon"
                height="26"
                width="26"
                style={{ marginBottom: "3px" }}
              />
            ) : (
              <img
                title={`Unlock this test`}
                src={
                  testUnlocked
                    ? UnlockIcon
                    : unlock_hover
                    ? UnlockIcon
                    : LockIcon
                }
                onMouseEnter={UnlockHover}
                onMouseLeave={UnlockBlur}
                color="primary"
                className="pointer share-icon"
                height="26"
                width="26"
                style={{ marginBottom: "3px" }}
                onClick={doUnlock}
              />
            )}
          </>
              )*/}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <img
          title={`share test`}
          aria-label="watch"
          src={ShareIcon}
          color="primary"
          className="pointer share-icon"
          height="26"
          width="26"
          style={{ marginBottom: "4px" }}
          onClick={openModal}
        />
      </div>
      {!hideCollections && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <img
            title={`share test`}
            aria-label="watch"
            src={BookmarkIcon}
            color="primary"
            style={{ marginBottom: "1px" }}
            className="pointer share-icon"
            height="26"
            width="26"
            onClick={openModal}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <img
          title={`share test`}
          aria-label="watch"
          src={WatchOffIcon}
          color="primary"
          className="pointer share-icon"
          height="26"
          width="26"
          onClick={openModal}
        />
      </div>
      {modal && (
        <FreeTrialSalesModal
          doOpenModal={modal}
          unlockedTests={unlockedTests}
          unlock_limit={unlock_limit}
          modalCallback={m => setModal(m)}
        />
      )}
      <Modal
        open={unlock_modal ? true : false}
        className="dww-modal-container free-trial-actions"
      >
        <div
          className="box"
          style={{
            width: `${unlock_width}px`,
            height: `auto`,
            overflow: "hidden",
            top: (window.innerHeight - unlock_height) / 2,
            position: "absolute",
            left: (window.innerWidth - unlock_width) / 2,
            outline: 0,
          }}
        >
          <div className="relative">
            <div style={{ top: "6px", right: "12px", position: "absolute" }}>
              <img
                src={X}
                alt="Close"
                className="pointer"
                onClick={closeUnlockModal}
              />
            </div>
            <h3 className="pv3 ph3 bb b--black-05 f2">Unlock This Test</h3>
            <div>
              <div className="pb3">
                <div className="pa3 f4">
                  Your team has unlocked{" "}
                  <b>
                    {unlockedTests}/{unlock_limit}
                  </b>{" "}
                  tests that DoWhatWorks has gifted you.
                </div>
                <div className="ph4 pv3 tc">
                  {apiLoading ? (
                    <span className="ui-button modal-action">
                      <img
                        src={UnlockWhite}
                        style={{ marginTop: "-5px", marginRight: "4px" }}
                      />
                      <span>Unlocking&hellip;</span>
                    </span>
                  ) : (
                    <a
                      className="ui-button modal-action"
                      href=" #"
                      onClick={unlockTest}
                    >
                      <img
                        src={UnlockWhite}
                        style={{ marginTop: "-5px", marginRight: "4px" }}
                      />
                      <span>Unlock</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FreeTrialActions;
