import React, { Component } from "react";
import UpdateCC from "../../components/UpdateCC";
import { Elements, StripeProvider } from "react-stripe-elements";
import { stripeApiKey, baseApiUrl } from "../../util";
import request from "request";

class UpdateCCPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    has_cc: true,
  };
  componentDidMount() {
    if (this.props.user) {
      request.get(
        {
          url: `${baseApiUrl}/v1/check_cc`,
          headers: {
            Authorization: this.props.user.token,
          },
        },
        (err, res, data) => {
          const json = JSON.parse(data);
          this.setState({
            has_cc: json.has_cc,
          });
        }
      );
    }
  }

  render() {
    if (!this.props.user) {
      this.props.history.push(`/login?returnUri=${window.location.href}`);
      return <></>;
    }
    return (
      <div className="pt4">
        <div className="tl box center mw6">
          <div className="bb b--black-05 pa3 mb3">
            <h1>Update Credit Card</h1>
          </div>
          {!this.state.has_cc && (
            <div className="pv4 ph3">
              <div className="red b">
                <p>We can't find a credit card associated with this account.</p>
                <p>
                  {" "}
                  Please have the person who originally entered the credit card
                  update it. Or,{" "}
                  <a href="mailto:help@dowhatworks.io">send us an email</a> and
                  we will help you out.
                </p>
              </div>
            </div>
          )}
          {this.state.has_cc && (
            <div className="pa3">
              <StripeProvider apiKey={stripeApiKey}>
                <Elements>
                  <UpdateCC user={this.props.user} />
                </Elements>
              </StripeProvider>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UpdateCCPage;
