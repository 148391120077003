import React, { Component } from "react";
import { baseApiUrl, encodeForm } from "../../util";
import Loading from "../../components/Loading";
import request from "request";
import * as querystring from "query-string";
import * as cx from "classnames";
import X from "../../icons/red_x.svg";
import Modal from "@material-ui/core/Modal";

const labelClass = "db fw4 lh-copy black-60 mt4";
const inputClass = "pa2 input-reset ba b--black-20 bg-white br2 mr3";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      loading: true,
      invite: {},
      successUser: false,
      deactivate: null,
      inviteDelete: null,
      loadingUser: false,
      inviteLoading: false,
      inviteSucess: false,
      addInviteError: null,
      deactivating: false,
    };
  }
  getUsers = () => {
    const { id } = querystring.parse(window.location.search);

    if (!this.props.user || !this.props.user.token) {
      window.location.replace(`/login?returnUri=${window.location}`);
      return;
    }
    request.get(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/users${id ? `?customer=${id}` : ""}`,
      },
      (err, data) => {
        if (data.statusCode > 400 && data.statusCode < 500) {
          this.setState({ access_error: true, loading: false });
        } else if (data.statusCode >= 500) {
          this.setState({ error: true, loading: false });
        }
        const json = JSON.parse(data.body);
        this.setState({
          loading: false,
          users: json.users,
          invites: json.invites,
          id,
        });
      }
    );
  };
  componentDidMount() {
    this.getUsers();
  }
  onInvite = e => {
    e.preventDefault();
    this.setState({ inviteLoading: true, addInviteError: null });
    const { email, name, permissions } = this.state.invite;
    if (!email || !name || !permissions) {
      alert("Please fill out all the info");
      return;
    }
    fetch(`${baseApiUrl}/v1/invite/ab`, {
      headers: {
        Authorization: this.props.user.token,
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      body: encodeForm({
        email,
        name,
        permissions,
        customer: this.state.id ? this.state.id : this.props.user.company,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          this.setState({ inviteLoading: false, addInviteError: data.error });
          return;
        }
        setTimeout(() => {
          this.getUsers();
        }, 750);

        this.setState(
          {
            inviteLoading: false,
            invite: { name: "", email: "" },
            inviteSuccess: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                inviteSuccess: false,
              });
            }, 2000);
          }
        );
      })
      .catch(e => {
        console.error(e);
      });
  };
  onDeactivateInvite = id => e => {
    const invite = this.state.invites.find(i => i.id === id);
    this.setState({ inviteDelete: invite });
  };
  handleChange = e => {
    const { name } = e.target;
    this.setState({
      [name]: e.target.checked,
    });
  };
  changeRoleUser = id => e => {
    if (e.currentTarget.value !== "") {
      this.setState({
        loadingUser: id,
      });
      fetch(`${baseApiUrl}/v1/user/edit/permissions`, {
        headers: {
          Authorization: this.props.user.token,
          "content-type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body: encodeForm({
          id: id,
          permissions: e.currentTarget.value,
        }),
      })
        .then(res => res.json())
        .then(data => {
          this.setState({ loadingUser: null, successUser: id }, () => {
            setTimeout(() => {
              this.setState({ successUser: null });
            }, 2000);
          });
        })
        .catch(e => {});
    }
  };
  onDeactivate = id => e => {
    const user = this.state.users.find(i => i.id === id);
    this.setState({ deactivate: user });
  };
  onCancelDeactivate = e => {
    this.setState({ deactivate: null });
  };
  onShowInvite = e => {
    e.preventDefault();
    this.setState({ showInvite: true });
  };
  onCancelInvite = e => {
    e.preventDefault();
    this.setState({ inviteDelete: null });
  };
  onChange = e => {
    const { name, value } = e.currentTarget;
    const { invite } = this.state;
    invite[name] = value;
    this.setState({
      invite,
    });
  };
  doRemoveInvite = e => {
    const { inviteDelete } = this.state;
    e.preventDefault();
    this.setState({ removingInvite: true });
    fetch(`${baseApiUrl}/v1/invite/sem`, {
      headers: {
        Authorization: this.props.user.token,
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "DELETE",
      body: encodeForm({
        id: inviteDelete.id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ removingInvite: false, inviteDelete: null }, () => {
          this.getUsers();
        });
      })
      .catch(e => {
        this.setState({ inviteError: true });
      });
  };
  doDeactivate = e => {
    const { deactivate } = this.state;
    e.preventDefault();
    this.setState({ deactivating: true });
    fetch(`${baseApiUrl}/v1/user`, {
      headers: {
        Authorization: this.props.user.token,
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "DELETE",
      body: encodeForm({
        uid: deactivate.id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ deactivating: false, deactivate: null }, () => {
          this.getUsers();
        });
      })
      .catch(e => {
        this.setState({ inviteError: true });
      });
  };
  render() {
    const {
      error,
      saving,
      saved,
      ab_email,
      sem_email,
      showInvite,
      invites,
      users,
      successUser,
      loadingUser,
      inviteLoading,
      inviteError,
      inviteSuccess,
      inviteDelete,
      removingInvite,
      userDelete,
      deactivate,
      addInviteError,
      access_error,
      invite,
      loading,
      deactivating,
      id,
    } = this.state;

    const { user } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (access_error) {
      return (
        <main className="pv4">
          <div className="box center br2 bg-white tl pa4 tc">
            <h1 className="mb4">You don't have access to this</h1>
            <p className="f4">
              Please ask the main point of contact at your company for access.
            </p>
            <p className="f4">
              You an also email us at{" "}
              <a href="mailto:help@dowhatworks.io">help@dowhatworks.io</a> with
              any questions.
            </p>
          </div>
        </main>
      );
    }

    return (
      <main className="pv4">
        <div
          className="box center br2 bg-white tl"
          style={{ position: "relative" }}
        >
          <div className="bb b--black-05 ph3 pt3 mb3 pb2">
            <h1>Manage Team</h1>
          </div>
          <div className="ph3 pb3 pt2">
            {user.permissions && user.permissions.role === "admin" && (
              <>
                {showInvite ? (
                  <form className="pv2" onSubmit={this.onInvite}>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email"
                      className={inputClass}
                      onChange={this.onChange}
                      value={invite.email}
                    />{" "}
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      className={inputClass}
                      onChange={this.onChange}
                      value={invite.name}
                    />
                    <select
                      onChange={this.onChange}
                      value={invite.permissions ? invite.permissions : null}
                      className="pa2 ba b--black-20 bg-white br2 mr3"
                      name="permissions"
                    >
                      <option value="">-- Choose a role --</option>
                      {user && user.company === "admin" && !id && (
                        <option value="screener">Screener</option>
                      )}
                      <option value="member">Team Member</option>
                      <option value="admin">Admin</option>
                    </select>
                    {inviteLoading ? (
                      <span className="grey">Inviting&hellip;</span>
                    ) : (
                      <input
                        type="submit"
                        value="Invite"
                        className="ui-button primary"
                      />
                    )}
                    {inviteSuccess && (
                      <div className="green b">Invite Sent!</div>
                    )}
                    {addInviteError && (
                      <div className="red b mt1">{addInviteError}</div>
                    )}
                  </form>
                ) : (
                  <div className="pv2">
                    <a
                      href=" #"
                      onClick={this.onShowInvite}
                      className="ui-button primary"
                    >
                      + Invite Colleauges
                    </a>
                  </div>
                )}
              </>
            )}
            {error && (
              <p className="dark-red">
                Whoops, looks like something went wrong. Please try again.
              </p>
            )}
            <div className="flex">
              {user.customer.invite && (
                <div className="pr5 mt3 w-50">
                  <h3>Invites</h3>
                  {!invites ||
                    (invites.length === 0 && (
                      <div className="mv3 gray">No active invites.</div>
                    ))}
                  {invites && invites.length > 0 && (
                    <>
                      {invites.map(user => {
                        return (
                          <div>
                            <div className="mb3 flex">
                              <div className="mr3 pa2" style={{ flexGrow: 1 }}>
                                {user.name} ({user.email})
                              </div>
                              <div className="tr">
                                <select
                                  onChange={this.onChange}
                                  value={
                                    user.permissions
                                      ? user.permissions.role
                                      : null
                                  }
                                  className="pa2 ba b--black-20 bg-white br2 mr3"
                                >
                                  <option value="screener">Screener</option>
                                  <option value="member">Team Member</option>
                                  <option value="admin">Admin</option>
                                </select>
                              </div>
                              <div className="ml2 pv2">
                                <a
                                  href=" #"
                                  onClick={this.onDeactivateInvite(user.id)}
                                  className="red"
                                >
                                  <img
                                    src={X}
                                    height={12}
                                    width={12}
                                    style={{ height: "12px", width: "12px" }}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}
              {users && users.length > 0 && (
                <div
                  className={cx({
                    "mt3 w-50": user.customer.invite,
                    "w-60": !user.customer.invite,
                  })}
                >
                  {this.props.user.customer.invite && <h3>Team</h3>}
                  {users.map(user => {
                    return (
                      <div className="mb3">
                        <div className="flex">
                          {user.status === "deactivated" ? (
                            <>
                              <div
                                className="mr3 pv2 gray"
                                title={`${user.name} (${user.email})`}
                                style={{
                                  flexGrow: 1,
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {user.name} ({user.email})
                              </div>
                              <div className="tr red pv2">Deactivated</div>
                            </>
                          ) : (
                            <>
                              <div
                                className="mr3 pv2"
                                title={`${user.name} (${user.email})`}
                                style={{
                                  flexGrow: 1,
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {user.name} ({user.email})
                              </div>
                              <div className="tr">
                                <select
                                  onChange={this.changeRoleUser(user.id)}
                                  defaultValue={
                                    user.permissions
                                      ? user.permissions.role
                                      : null
                                  }
                                  className="pa2 ba b--black-20 bg-white br2 mr3"
                                >
                                  <option value="">Select a Role</option>
                                  {user && user.company === "admin" && !id && (
                                    <option value="screener">Screener</option>
                                  )}
                                  <option value="member">Team Member</option>
                                  <option value="admin">Admin</option>
                                </select>
                              </div>
                              <div className="ml2 pv2">
                                <a
                                  href=" #"
                                  onClick={this.onDeactivate(user.id)}
                                  className="red"
                                >
                                  <img
                                    src={X}
                                    style={{ height: "12px", width: "12px" }}
                                  />
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                        {successUser === user.id && (
                          <div className="pt1 green b tr">Saved.</div>
                        )}
                        {loadingUser === user.id && (
                          <div className="pt1 gray b tr">Saving&hellip;</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {inviteDelete && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `500px`,
                    top: "10vh",
                    position: "absolute",
                    left: "40%",
                    overflow: "auto",
                  }}
                >
                  <div className="pa3 f4">
                    Are you sure you want to remove the invite for{" "}
                    {inviteDelete.name} ({inviteDelete.email})?
                  </div>
                  {inviteError && (
                    <div className="pa3 red b">
                      Hmm&hellip; Something went wrong. Please try again.
                    </div>
                  )}
                  <div className="tc pa3 mb4">
                    {removingInvite ? (
                      <>
                        <span className="gray">Removing&hellip;</span>
                      </>
                    ) : (
                      <>
                        <a
                          href=" #"
                          className="ui-button primary"
                          onClick={this.doRemoveInvite}
                        >
                          Remove
                        </a>
                        <a
                          href=" #"
                          className="ml2"
                          onClick={this.onCancelInvite}
                        >
                          Cancel
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </Modal>
            )}
            {deactivate && (
              <Modal open={true}>
                <div
                  className="box"
                  style={{
                    width: `500px`,
                    top: "10vh",
                    position: "absolute",
                    left: "50%",
                    overflow: "auto",
                  }}
                >
                  <div className="pa3 f4">
                    Are you sure you want to deactivate
                    {deactivate.name}'s ({deactivate.email}) account?
                  </div>
                  {inviteError && (
                    <div className="pa3 red b">
                      Hmm&hellip; Something went wrong. Please try again.
                    </div>
                  )}
                  <div className="tc pa3 mb4">
                    {deactivating ? (
                      <>
                        <span className="gray">Deactivating&hellip;</span>
                      </>
                    ) : (
                      <>
                        <a
                          href=" #"
                          className="ui-button primary"
                          onClick={this.doDeactivate}
                        >
                          Deactivate
                        </a>
                        <a
                          href=" #"
                          className="ml2"
                          onClick={this.onCancelDeactivate}
                        >
                          Cancel
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </main>
    );
  }
}

export default Users;
