import React from "react";
import DashboardHeader from "./DashboardHeader";
import FakeDonut from "../icons/old/FakeDonut.svg";

const FakeDashboard = props => (
  <>
    <DashboardHeader customer={props.company} />
    <div
      className={props.oldDash || props.isSEM ? "document-spacing center" : ""}
    >
      {props.oldDash && (
        <div className="mt4 flex flex-wrap">
          <div className="w-100-s w-60">
            <div
              className="tc box overflow-hidden"
              style={{ padding: "31px 0" }}
            >
              <img src={FakeDonut} width="650" alt="Fake Chart" />
            </div>
          </div>
          <div className="pl3 pl0-s pt5-s tc w-100-s w-40">
            <div
              className="box tc pv2 flex flex-column items-center justify-center"
              style={{ height: "100%" }}
            >
              <div>
                <div
                  style={{
                    width: "100px",
                    height: "50px",
                    background: "#ccc",
                  }}
                  className="br1"
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "75px",
                    height: "25px",
                    background: "#eee",
                  }}
                  className="mt2 br1"
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "145px",
                    height: "50px",
                    background: "#ccc",
                  }}
                  className="br1 mt4"
                ></div>
              </div>
              <div>
                <div
                  style={{
                    width: "175px",
                    height: "25px",
                    background: "#eee",
                  }}
                  className="mt2 br1"
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.newDash && (
        <>
          <div className="pt4 pb3 document-spacing center tc flex">
            <div className="flex" style={{ margin: "8px auto 0px" }}>
              <div className="dash-infobox" style={{ height: "100px" }}>
                <div className="ui-link db"></div>
              </div>
              <div className="dash-infobox" style={{ height: "100px" }}>
                <div className="ui-link db"></div>
              </div>
              <div className="dash-infobox" style={{ height: "100px" }}>
                <a
                  className="ui-link db"
                  style={{ cursor: "help" }}
                  href=" #"
                ></a>
              </div>
            </div>
          </div>
          <div className="mt3 mb3 fun-fact center tc">
            <span
              className="bg-near-white w-60 dib"
              style={{ height: "20px", backgroundColor: "#efefef" }}
            ></span>
          </div>
          <div className="pt4 bg-white">
            <div className="pv4 document-spacing center flex">
              <div className="w-40 pr5">
                <h3 className="pt2 pb4 mt2 mb4 newdash-head bg-near-white"></h3>
                <a className="newdash-link bg-near-white ">
                  <span className="title" style={{ height: "50px" }}></span>
                </a>
                <a className="newdash-link bg-near-white ">
                  <span className="title" style={{ height: "50px" }}></span>
                </a>
                <a className="newdash-link bg-near-white">
                  <span className="title" style={{ height: "50px" }}></span>
                </a>
              </div>
              <div className="dashbox-pad dash-modules ph3 w-60 flex flex-wrap">
                <div className="dash-mosaic-item item-1">
                  <a className="db ui-link dash-mosaic-item-img"></a>
                </div>
                <div className="dash-mosaic-item item-2">
                  <a className="db" href="/home/adobe/report?recent=views">
                    <div className="dash-mosaic-item-img"></div>
                  </a>
                </div>
                <div className="dash-mosaic-item item-3">
                  <a className="db" href="/home/adobe/report?new=added">
                    <div className="dash-mosaic-item-img"></div>
                  </a>
                </div>
                <div className="dash-mosaic-item item-4">
                  <a className="db ui-link dash-mosaic-item-img"></a>
                </div>
                <div className="dash-mosaic-item item-5">
                  <a className="db" href="/home/adobe/report?new=added">
                    <div className="dash-mosaic-item-img"></div>
                  </a>
                </div>
                <div className="dash-mosaic-item item-6">
                  <a className="db" href="/home/adobe/report?recent=views">
                    <div className="dash-mosaic-item-img"></div>
                  </a>
                </div>
                <div className="dash-mosaic-item item-7">
                  <a className="db ui-link dash-mosaic-item-img"></a>
                </div>
                <div className="dash-mosaic-item item-8">
                  <a className="db" href="/home/adobe/report?new=added">
                    <div className="dash-mosaic-item-img"></div>
                  </a>
                </div>
                <div className="dash-mosaic-item item-9">
                  <a className="db ui-link dash-mosaic-item-img"></a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(props.isSEM || props.oldDash) && (
        <div className="mt4 box pb2">
          <div className="bb b--black-05 pv2 ph3 mb2">
            <div
              style={{
                width: "175px",
                height: "20px",
                background: "#eee",
              }}
              className="mv2 br1"
            ></div>
          </div>
          <div className="mh3 overflow-hidden br2 ba b--black-05 mv4">
            <table className="w-100 f6 f8-s">
              <tbody>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="bg-near-white dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
                <tr className="dt-row nowrap">
                  <td style={{ height: "50px" }} />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  </>
);

export default FakeDashboard;
