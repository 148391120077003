import DeleteIcon from "../../../icons/delete_red.svg";
import { components } from "react-select";
const { Option } = components;

const IconOption = props => {
  return (
    <Option {...props}>
      <div className="flex items-center justify-between">
        <p className="m-0 tl">{props.data.label}</p>
        {props.data.value.toLowerCase() !== "new" && (
          <img
            src={DeleteIcon}
            className="grow pointer"
            alt={props.data.label}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              const { onDeleteAnalysis } = props.selectProps;
              onDeleteAnalysis(props.data.value, props.data.label);
            }}
          />
        )}
      </div>
    </Option>
  );
};
export default IconOption;
