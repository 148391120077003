import React from "react";
import request from "request";
import * as querystring from "query-string";
import {
  baseApiUrl,
  COOKIE_EMAIL_SHARED,
  getCookieValue,
  getData,
} from "../util";
import "./home/screenshots.css";
import Loading from "../components/Loading";
import Carousel from "../components/Carousel";
import CarouselNew from "../components/CarouselNew";
import AccessDenied from "../components/AccessDenied";
import { useParams } from "react-router";
import Modal from "@material-ui/core/Modal";
import * as cx from "classnames";
import X from "../icons/red_x.svg";
import SadComputer from "../icons/old/sadpage.svg";
import SuccessImg from "../icons/old/champion.svg";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { LinearProgress } from "@material-ui/core";

class RequestAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedSavedTests: true,
      shareId: this.props.match.params.next,
      saveEmailRequestLoading: false,
      emailShared: false,
      showLinkPopup: false,
      loading: true,
      email: "",
      name: "",
      error: false,
    };
  }
  componentDidMount() {
    request({
      url: `${baseApiUrl}/v1/t?url=${window.location.href}`,
    });
    const requestParams = {
      url: `${baseApiUrl}/v1/share/details?shareId=${this.state.shareId}`,
    };
    if (this.props.user) {
      requestParams.headers = {
        Authorization: this.props.user.token,
      };
    }
    getData(requestParams)
      .then(({ err, res, data }) => {
        if (err) {
          this.setState({
            loading: false,
          });
        }
        this.setState({
          loading: false,
          error: false,
          email: data.email,
          name: data.name,
          testId: data.testId,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }
  componentWillUnmount() {
    document.body.style = "";
  }
  closeLinkPopup = () => {
    this.setState({ showLinkPopup: false });
  };
  showLinkPopup = () => {
    this.setState({ showLinkPopup: true });
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  openGetinTouchForm = () => {
    window.location.href = window.location.origin + this.props.redirectUrl;
    // "https://dowhatworks.typeform.com/to/AdnLxKCC?utm_source=dowhatworks_share",

    // this.closeLinkPopup();
  };
  requestAccess = e => {
    e.preventDefault();
    if (this.state.saveEmailRequestLoading) return;
    if (
      this.state.email.trim() === "" ||
      !this.state.email.match(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    )
      return;
    this.setState({
      saveEmailRequestLoading: true,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        url: `${baseApiUrl}/v1/user/get_access`,
        form: {
          email: this.state.email.trim(),
          shared_link: this.state.shareId,
          name: this.state.name.trim(),
        },
      },
      (err, res, data) => {
        if (err) {
          alert("Something went wrong, please try again");
        } else {
          const json = JSON.parse(data);
          if (json.error) {
            this.setState({
              error: true,
              message: json.message,
              saveEmailRequestLoading: false,
              emailShared: false,
            });
          } else {
            this.setState({
              error: false,
              emailShared: true,
              saveEmailRequestLoading: false,
            });
          }
        }
      }
    );
  };
  reset = () => {
    this.setState({
      emailShared: false,
      email: "",
      name: "",
    });
  };
  render() {
    let width = 500;
    let height = 350;
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.state.error) {
      return (
        <>
          <Modal open={true}>
            <div
              className="box tc"
              style={{
                width: `${width}px`,
                height: `${height}px`,
                overflow: "hidden",
                top: (window.innerHeight - height) / 2,
                position: "absolute",
                left: (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div className="pa4">
                <>
                  <img
                    src={SadComputer}
                    style={{ width: "60px" }}
                    alt="error"
                  />
                  <p className="f3 tc pt4">
                    {this.state.message && this.state.message === "no-admin" ? (
                      <>
                        Uh oh, looks like something went wrong! Please contact
                        your dashboard admin.
                      </>
                    ) : (
                      <>
                        Looks like this link is expired or invalid. You can
                        reach out to the person who sent you this link to find
                        out more information.
                      </>
                    )}
                  </p>
                  <div className="flex justify-around">
                    <a
                      className="ui-button secondary w-40"
                      href="mailto:help@dowhatworks.io"
                      target="_blank"
                    >
                      Get in Touch
                    </a>
                  </div>
                </>
              </div>
            </div>
          </Modal>
        </>
      );
    }
    width = 640;
    height = 500;
    if (!this.props.user) {
      return (
        <>
          <Modal open={true}>
            <div
              className="box"
              style={{
                width: `${width}px`,
                height: `${height}px`,
                overflow: "hidden",
                top: (window.innerHeight - height) / 2,
                position: "absolute",
                left: (window.innerWidth - width) / 2,
                outline: 0,
              }}
            >
              <div className="pa4">
                <>
                  {this.state.emailShared && (
                    <div className="tc center">
                      <div className="mb3 tc mt4">
                        <img
                          src={SuccessImg}
                          style={{
                            width: "78px",
                            height: "78px",
                          }}
                          alt="success"
                          className="moviefone-success mb4"
                        />
                        <h1 className="mb2">Request Sent!</h1>
                      </div>
                      <p className="tc f4 pa3">
                        We'll send you an email with a link to create an account
                        when you've been approved.
                      </p>
                      <p className="tc f4 pa3">
                        In the meantime, you can learn a little more about
                        DoWhatWorks <a href="https://dowhatworks.io/">here</a>.
                      </p>
                    </div>
                  )}
                  {!this.state.emailShared && (
                    <>
                      <h1>Request Access</h1>
                      <p className="f4 pt3">
                        Looks like you don't have access to your team's
                        DoWhatWorks Dashboard yet. Request access and we'll send
                        an email to your Dashboard's admin so they can give you
                        access.
                      </p>
                      <p className="f4 mb4">
                        Already have an account?{" "}
                        <a
                          href={`${window.location.origin}/login?returnUri=${window.location.href}`}
                        >
                          Sign in
                        </a>
                        .
                      </p>
                      <form onSubmit={this.requestAccess}>
                        <input
                          type="text"
                          placeholder="Your Name"
                          className="bg-light-gray gray bn br3 w-100 pl2 pv2 mb2"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleKeyUp}
                          required
                        />
                        <input
                          type="email"
                          placeholder="Your Work Email"
                          className="bg-light-gray gray bn br3 w-100 pl2 pv2 mv2 "
                          name="email"
                          value={this.state.email}
                          onChange={this.handleKeyUp}
                          required
                        />
                        <div className="flex justify-around">
                          <button
                            className="primary br-pill bn ma4 w-40"
                            style={{
                              color: "#fff",
                              padding: "6px 18px",
                              backgroundColor: "rgba(246,91,86,1)",
                            }}
                            type="submit"
                            disabled={this.state.saveEmailRequestLoading}
                          >
                            {this.state.saveEmailRequestLoading ? (
                              <>Requesting&hellip;</>
                            ) : (
                              <>Request Access</>
                            )}
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </>
              </div>
            </div>
          </Modal>
        </>
      );
    }

    if (this.state.testId && this.props.user)
      return (
        <Redirect
          to={`/home/${this.props.user.company}/report/screenshots/${this.state.testId}`}
        />
      );
  }
}

export default RequestAccess;
