import React from "react";
import SuccessImg from "../../icons/old/champion.svg";

const Success = ({ user }) => {
  return (
    <div className="pt4">
      <div className="mt3 tc register mw6 center pa3 box">
        <div className="mb3 tc mt4">
          <img
            src={SuccessImg}
            style={{
              width: "48px",
              height: "48px",
            }}
            alt="success"
            className="moviefone-success mb4"
          />
          <h1 className="b">You're all set!</h1>
        </div>
        <p className="tc mt4 mb4 f3">
          We will reach out to schedule a <br /> diagnostic call.
        </p>
      </div>
    </div>
  );
};

export default Success;
