import WatchOffIcon from "../icons/watch_off.svg";
import ShareIcon from "../icons/share.svg";
import BookmarkIcon from "../icons/bookmark_off_solo.svg";
import LockIcon from "../icons/lock_purple.svg";
import UnlockIcon from "../icons/lock_open_purple.svg";
import ChampBig from "../icons/ChampNoDS.svg";
import Lock from "../icons/lock_purple.svg";
import Unlock from "../icons/lock_open_purple.svg";
import UnlockWhite from "../icons/lock_open_white.svg";
import Eye from "../icons/eye_green.svg";
import Alert from "../icons/exclamation_purple.svg";
import Chart from "../icons/chart_blue.svg";
import X from "../icons/red_x.svg";
import Modal from "@material-ui/core/Modal";
import Screenshot from "../img/free_trial_screenshots.png";
import Bg from "../img/free_trial_gradient.png";
import { useEffect, useState } from "react";
import request from "request";
import { baseApiUrl } from "../util";
import * as cx from "classnames";
import FreeTrialActions from "./FreeTrialActions";
import FreeTrialSalesModal from "./FreeTrialSalesModal";

const UnlockTest = ({
  hideCollections,
  showModal = false,
  testId,
  user,
  company,
  testUnlocked = false,
  unlockedTests = 0,
  unlock_limit = 0,
  reloadCallback,
  unlockedBy,
}) => {
  const [modal, setModal] = useState(false);
  const [unlock_modal, setUnlockModal] = useState(false);
  const [unlock_hover, setUnlockHover] = useState(false);
  const [apiLoading, setAPILoading] = useState(false);
  const [error, setError] = useState(null);
  const openUnlockModal = e => {
    setUnlockModal(true);
  };
  const closeUnlockModal = e => {
    setUnlockModal(false);
  };
  const openModal = e => {
    e.preventDefault();
    setModal(true);
  };
  const doUnlock = e => {
    if (e) {
      e.preventDefault();
    }
    if (
      true === false &&
      unlockedTests >= unlock_limit &&
      user.company !== "admin"
    ) {
      setModal(true);
    } else {
      if (!testUnlocked) {
        openUnlockModal();
      }
    }
  };
  const UnlockHover = e => {
    setUnlockHover(true);
  };
  const UnlockBlur = e => {
    setUnlockHover(false);
  };
  useEffect(() => {
    if (showModal) {
      doUnlock();
    }
  }, [showModal]);

  const unlockTest = e => {
    e.preventDefault();
    if (!user || !testId || !company) {
      return;
    }
    if (unlock_limit <= Number(unlockedTests) && user.company !== "admin") {
      return;
    }
    setAPILoading(true);
    request.post(
      {
        url: `${baseApiUrl}/v1/user/unlock_test`,
        headers: {
          Authorization: user ? user.token : "",
        },
        form: {
          test_id: testId,
          company,
        },
      },
      (opts, resp, body) => {
        setAPILoading(false);
        if (resp.statusCode >= 400) {
          const err = JSON.parse(body);
          setError(err);
          return;
        } else {
          closeUnlockModal();
          if (reloadCallback) {
            reloadCallback();
          } else {
            window.location.reload();
          }
        }
      }
    );
  };
  const height = 450;
  const width = 800;
  const unlock_width = 450;
  const unlock_height = 400;

  if (unlock_limit && unlock_limit === 0) {
    return <></>;
  }

  return (
    <>
      <div>
        {testUnlocked ? (
          <span
            className="subtle-action w-100 dib b f6"
            title={
              unlockedBy && unlockedBy.unlockedBy === "admin"
                ? `This test was unlocked for you by the DoWhatWorks Team.`
                : ``
            }
          >
            Unlocked
          </span>
        ) : (
          <span
            className="ui-button pointer w-100 dib b f6 subtle-action"
            onMouseEnter={UnlockHover}
            onMouseLeave={UnlockBlur}
            onClick={doUnlock}
          >
            {unlock_hover ? (
              <img
                src={UnlockIcon}
                height={16}
                style={{ marginTop: "-4px" }}
                className="pr1"
              />
            ) : (
              <img
                src={LockIcon}
                height={16}
                style={{ marginTop: "-4px", opacity: "0.8" }}
                className="pr1"
              />
            )}
            <span>Unlock</span>
          </span>
        )}
      </div>
      {modal && (
        <FreeTrialSalesModal
          doOpenModal={modal}
          unlockedTests={unlockedTests}
          unlock_limit={unlock_limit}
        />
      )}
      <Modal
        open={unlock_modal ? true : false}
        className="dww-modal-container free-trial-actions"
      >
        <div
          className="box"
          style={{
            width: `${unlock_width}px`,
            height: `auto`,
            overflow: "hidden",
            top: (window.innerHeight - unlock_height) / 2,
            position: "absolute",
            left: (window.innerWidth - unlock_width) / 2,
            outline: 0,
          }}
        >
          <div className="relative">
            <div style={{ top: "6px", right: "12px", position: "absolute" }}>
              <img
                src={X}
                alt="Close"
                className="pointer"
                onClick={closeUnlockModal}
              />
            </div>
            <h3 className="pv3 ph3 bb b--black-05 f2">Unlock This Test</h3>
            <div>
              <div className="pb3">
                <div className="pa3 f4">
                  Your team has unlocked{" "}
                  <b>
                    {unlockedTests}/{unlock_limit}
                  </b>{" "}
                  tests that DoWhatWorks has gifted you.
                </div>
                <div className="ph4 pv3 tc">
                  {apiLoading ? (
                    <span className="ui-button modal-action">
                      <img
                        src={UnlockWhite}
                        style={{ marginTop: "-5px", marginRight: "4px" }}
                      />
                      <span>Unlocking&hellip;</span>
                    </span>
                  ) : (
                    <a
                      className="ui-button modal-action"
                      href=" #"
                      onClick={unlockTest}
                    >
                      <img
                        src={UnlockWhite}
                        style={{ marginTop: "-5px", marginRight: "4px" }}
                      />
                      <span>Unlock</span>
                    </a>
                  )}
                  {error && (
                    <>
                      {error.error && error.error === "Unlock limit reached" ? (
                        <div className="pt3 error red b ph3">
                          Looks like you reached your test limit!
                        </div>
                      ) : (
                        <div className="pt3 error red b ph3">
                          Looks like something went wrong&hellip;
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UnlockTest;
