import React, { Component } from "react";
import { baseApiUrl, capitalize, addCommas, getCurrentTime } from "../util";
import cx from "classnames";
import request from "request";
import "./tagfilter.css";
import TagFilter from "./TagFilter";
import TagPicker from "./TagPicker";
import CompanyIcon from "../icons/companies_gray.svg";
import WhatsTestedIcon from "../icons/WhatsTested_gray.svg";
import CollectionsIcon from "../icons/Collections_gray.svg";
import PageIcon from "../icons/page.svg";
import DesktopIcon from "../icons/desktop_gray.svg";
import MobileIcon from "../icons/mobile_gray.svg";

class OmniFilter extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.state = {
      showing: false,
      selectedTags: this.props.defaultOptions || [],
      synonym_match: this.props.synonyms
        ? Object.keys(this.props.synonyms)
        : undefined,
      title_slug: `of-${getCurrentTime()}-${props.title_slug || ""}`,
      rect: { top: 0, left: 0 },
    };
  }
  sort = () => {
    if (this.props.noSort) {
      return () => {};
    } else if (this.props.addGroup) {
      return this.groupTagSort;
    } else {
      return this.tagSort;
    }
  };
  handleChange = (newValue, actionMeta) => {};
  open = e => {
    e.preventDefault();
    const elm = document.getElementById(this.state.title_slug).parentElement;
    const rect = elm.getBoundingClientRect();
    this.setState(
      {
        showing: !this.state.showing,
        rect: {
          left: elm.offsetLeft,
          top: elm.offsetTop + rect.height,
          width: rect.width,
          x: rect.x,
          y: rect.y,
        },
        search: null,
      },
      () => {
        if (this.inputRef && this.inputRef.current) {
          this.inputRef.current.focus();
        }
      }
    );
  };
  removeFromTags = (tags, tag) => {
    let newTags;
    if (tag.id) {
      newTags = tags.filter(t => t.id !== tag.id);
    } else {
      newTags = tags.filter(t => t.value !== tag.value);
    }
    newTags.sort(this.sort);
    return newTags;
  };
  update = (type, subtype) => tags => {
    const { selectedTags } = this.state;
    let typeTags;
    if (subtype) {
      typeTags = selectedTags.filter(
        t => t.type !== type && t.type !== subtype
      );
    } else {
      typeTags = selectedTags.filter(t => t.type !== type);
    }
    tags.forEach(tag => {
      if (!tag.type) {
        tag.type = type;
      }
      typeTags.push(tag);
    });
    typeTags.sort(this.sort);

    this.setState(
      {
        selectedTags: typeTags,
      },
      () => {
        this.props.callback(typeTags);
      }
    );
  };
  close = () => {
    this.setState({ showing: false, search: null });
  };
  tagSort = (a, b) => (a.value.toLowerCase() >= b.value.toLowerCase() ? 1 : -1);
  groupTagSort = (a, b) => {
    if (a.group && !b.group) {
      return -1;
    } else if (!a.group && b.group) {
      return 1;
    } else {
      return a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1;
    }
  };
  hasTag = (tag, id) => {
    if (id) {
      return this.state.selectedTags.find(t => t.id === id);
    } else {
      return this.state.selectedTags.find(t => t.value === tag);
    }
  };
  clearAll = () => {
    this.setState({
      selectedTags: [],
      showing: false,
    });
    setTimeout(() => this.props.callback([]), 0);
  };
  onChange = e => {
    if (e.currentTarget.value.length > 0) {
      this.setState({ search: e.currentTarget.value });
    } else {
      this.setState({ search: null });
    }
  };
  refine = tag => e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ search: `${tag.value}:` });
    this.inputRef.current.value = `${tag.value}:`;
  };
  clearSearch = e => {
    this.setState({ search: null });
    this.inputRef.current.value = "";
    this.inputRef.current.focus();
  };
  remove = (tag, id, type) => e => {
    e.preventDefault();
    const { selectedTags } = this.state;
    let fullTag;
    if (type === "tags") {
      if (id) {
        fullTag = this.props.tags.find(t => t.id === id);
      } else {
        fullTag = this.props.tags.find(t => t.value === tag);
      }
    }
    if (type === "collections") {
      fullTag = this.props.collections.find(t => t.value === tag);
    }
    if (type === "challenges") {
      fullTag = this.props.challenges.find(t => t.value === tag);
    }
    if (type === "page_types") {
      fullTag = this.props.page_types.find(t => t.value === tag);
    }
    if (type === "companies") {
      fullTag = this.props.companies.find(t => t.value === tag);
    }
    const newTags = this.removeFromTags(selectedTags, fullTag);

    this.setState({
      selectedTags: newTags,
    });
    setTimeout(() => this.props.callback(newTags), 0);
  };
  addGroup = e => {
    e.preventDefault();
    this.props.groupAdd();
    this.setState({ showing: false });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.defaultOptions !== this.props.defaultOptions) {
      this.setState({ selectedTags: this.props.defaultOptions });
    }
  }
  rect = null;
  shouldDisable = tag => {
    const { includeOptions } = this.props;
    if (includeOptions) {
      if (!tag.isGroup) {
        return !includeOptions.includes(tag.value);
      } else {
        return !tag.value.find(t => includeOptions.includes(t));
      }
    }
  };
  timeout = null;
  render() {
    const { selectedTags, title_slug } = this.state;
    const {
      tags,
      collections,
      companies,
      challenges,
      page_types,
      count,
      sectors,
      status,
      platform,
      isAdminPage,
    } = this.props;
    const fixAt = { width: 300, right: -40, top: 32 };

    const statuses = [
      { label: "Live / Ended", value: "all" },
      { label: "Ended", value: "ended" },
      { label: "Live", value: "live" },
    ];
    const platforms = [
      { label: "Mobile / Desktop", value: "all" },
      { label: "Mobile", value: "mobile" },
      { label: "Desktop", value: "desktop" },
    ];

    return (
      <div
        className={cx({
          "tag-filter-container omnifilter-container flex": true,
          "f6 small-tag-filter": this.props.small,
        })}
        id={title_slug}
      >
        <div className="view-count">
          {isAdminPage ? (
            <span className="pill-purple b">{addCommas(count)}</span>
          ) : (
            <>
              Viewing <span className="pill-purple b">{addCommas(count)}</span>{" "}
              tests
            </>
          )}
        </div>
        {sectors && (
          <TagFilter
            token={this.props.token}
            options={sectors}
            callback={this.update("sectors")}
            defaultOptions={selectedTags.filter(t => t.type === "sectors")}
            icon={""}
            title="Sectors"
            isSticky={true}
            onRight
            noSort
            fixAt={fixAt}
            small
            title_slug="sectors"
            tabIndex={1}
          />
        )}
        {tags && (
          <TagFilter
            token={this.props.token}
            options={tags}
            synonyms={this.props.synonyms}
            callback={this.update("tags")}
            defaultOptions={selectedTags.filter(t => t.type === "tags")}
            fullText
            icon={WhatsTestedIcon}
            title="What's tested"
            isSticky={true}
            onRight
            noSort
            fixAt={fixAt}
            small
            title_slug="whatstested"
            tabIndex={2}
          />
        )}
        {collections && (
          <TagFilter
            options={collections.concat(challenges)}
            callback={this.update("collections", "challenges")}
            defaultOptions={selectedTags.filter(
              t => t.type === "collections" || t.type === "challenges"
            )}
            icon={CollectionsIcon}
            title="Collections"
            counts={this.props.collections_counts}
            isSticky={true}
            onRight
            token={this.props.token}
            fixAt={fixAt}
            small
            title_slug="collections"
            tabIndex={3}
          />
        )}
        {companies && (
          <TagFilter
            options={companies}
            callback={this.update("companies")}
            defaultOptions={selectedTags.filter(t => t.type === "companies")}
            icon={CompanyIcon}
            counts={this.props.company_counts}
            title="Companies"
            isSticky={true}
            onRight
            token={this.props.token}
            fixAt={fixAt}
            small
            title_slug="companies"
            tabIndex={4}
          />
        )}
        {page_types && (
          <TagFilter
            options={page_types}
            callback={this.update("page_types")}
            defaultOptions={selectedTags.filter(t => t.type === "page_types")}
            counts={this.props.page_counts}
            icon={PageIcon}
            title="Pages"
            isSticky={true}
            onRight
            token={this.props.token}
            fixAt={fixAt}
            small
            title_slug="pages"
            tabIndex={5}
            andOps={this.props.isAdmin}
            // key={`tagFilter-Pages-${
            //   selectedTags.filter(t => t.type === "page_types").length
            // }`}
          />
        )}
        {statuses && (
          <TagPicker
            options={statuses}
            callback={val => {
              this.props.statusCallback(val);
            }}
            defaultOptions={
              statuses.find(a => a.value === status) || {
                label: "Live / Ended",
                value: "all",
              }
            }
            noSort
            isSticky={true}
            onRight
            token={this.props.token}
            fixAt={{ width: "150px", right: -20, top: 32 }}
            small
            title_slug="status"
            tabIndex={6}
          />
        )}
        {platforms && (
          <TagPicker
            options={platforms}
            callback={val => {
              this.props.platformCallback(val);
            }}
            defaultOptions={
              platforms.find(a => a.value === platform) || {
                label: "Mobile / Desktop",
                value: "all",
              }
            }
            noSort
            isSticky={true}
            onRight
            token={this.props.token}
            fixAt={{ width: "150px", right: -44, top: 32 }}
            usePlatformIcons
            small
            title_slug="platform"
            tabIndex={7}
          />
        )}
      </div>
    );
  }
}

export default OmniFilter;
