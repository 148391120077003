import React, { Component } from "react";
import request from "request";
import Icon from "../icons/hand3.svg";
import Success from "../icons/old/champion.svg";
import X from "../icons/red_x.svg";
import Loading from "./Loading";
import * as cx from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { baseApiUrl, isMigratedComp, track } from "../util";
class MovieFone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      loading: false,
      name: "",
      description: "",
      fade: false,
      placeholder:
        "I’m working on a hero redesign and could use inspiration on the kinds of things that work.",
      title: "Inspiration",
    };
  }
  componentDidMount() {
    this.showMF();
  }

  componentWillUnmount() {
    clearTimeout(this.mfTimeout);
  }
  showMF() {
    if (this.props.open) {
      this.setState({ showMF: true, showing: true });
      return;
    }
    if (!this.state.showing) {
      this.setState({ showing: true }, () => {
        this.mfTimeout = setTimeout(() => {
          this.setState({ showMF: true });
        }, 1500);
      });
    }
  }
  mfTimeout = null;
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleChange = e => {
    const { value } = e.target;
    let placeholder = "";

    if (value === "Inspiration") {
      placeholder =
        "I’m working on a hero redesign and could use inspiration on the kinds of things that work.";
    } else if (value === "Priotitization") {
      placeholder =
        "I have a couple of ideas of what to test, but don't know which one to test first.";
    } else if (value === "Execution") {
      placeholder =
        "I'm adjusting callouts on a pricing page and could use examples of what's worked for others.";
    } else if (value === "Messaging") {
      placeholder =
        "I’m optimizing headlines and could use example of messages that resonate.";
    } else if (value === "Competitor analysis") {
      placeholder =
        "We're preparing for a quarterly strategy review and I want to show our competitors are trying.";
    } else if (value === "Something else") {
      placeholder = "Let us know how we can help.";
    }
    this.setState({
      title: value,
      placeholder,
    });
  };
  send = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true, error: false });

    const { title, description } = this.state;

    if (description.trim() === "") {
      this.setState({ loading: false, error: "empty-desc" });
      return;
    }

    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/v1/moviefone`,
        form: {
          title,
          description,
        },
      },
      err => {
        if (err) {
          this.setState({ error: true, loading: false });
        }
        this.setState({ success: true, loading: false });

        setTimeout(() => {
          this.setState({ success: false, open: false, loading: false });
        }, 5000);
      }
    );
  };
  render() {
    if (!isMigratedComp(this.props.company)) {
      return <></>;
    }
    return (
      <div className="moviefone moviefone-static">
        <div className="moviefone-popover">
          <div className="moviefone-popover-inner">
            {this.state.success ? (
              <>
                <div className="pa3 tc">
                  <div className="pt5 pb4">
                    <img
                      src={Success}
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                      alt="success"
                      className="moviefone-success"
                    />
                  </div>
                  <p className="pt2">
                    You should expect an an email from us soon with an answer to
                    your question.
                  </p>
                  <p>
                    In the meantime, feel free to search on the all tests page
                    and see what you can find.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="dash-head pa3 bb b--black-05">
                  <h3>Need a hand?</h3>
                </div>
                <div className="pa3">
                  <label>What are you looking for?</label>
                  <div>
                    <Select
                      labelId="title"
                      id="title"
                      value={this.state.title}
                      onChange={this.handleChange}
                      className="bg-white mb3"
                      variant="outlined"
                    >
                      <MenuItem value="Inspiration">Inspiration</MenuItem>
                      <MenuItem value="Priotitization">Prioritization</MenuItem>
                      <MenuItem value="Execution">Execution</MenuItem>
                      <MenuItem value="Messaging">Messaging</MenuItem>
                      <MenuItem value="Competitor analysis">
                        Competitor analysis
                      </MenuItem>
                      <MenuItem value="Something else">Something else</MenuItem>
                    </Select>
                  </div>
                  <div className="mb3">
                    <textarea
                      placeholder={this.state.placeholder}
                      className="search-form"
                      name="description"
                      onKeyUp={this.handleKeyUp}
                      style={{ height: "150px" }}
                    ></textarea>
                    {this.state.error && this.state.error === "empty-desc" && (
                      <div className="red">Please add something here.</div>
                    )}
                  </div>
                  <button className="ui-button primary" onClick={this.send}>
                    {this.state.loading ? <Loading /> : <>Ask Us</>}
                  </button>
                  <div className="mt2 f7 i">
                    We'll reply by email with help.
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MovieFone;
