import React from "react";
import DesktopIconOn from "../icons/desktop_white.svg";
import DesktopIconOff from "../icons/desktop_purple.svg";
import MobileIconOn from "../icons/mobile_white.svg";
import MobileIconOff from "../icons/mobile_purple.svg";
import DropDown from "../icons/info_dropdown.svg";
import ReportDates from "./ReportDates";

const ScreenshotInfo = ({
  screenshots,
  kwData,
  customer,
  newView,
  longView,
}) => {
  return (
    <>
      <div
        className={`db info-box-container
         ${longView ? "info-box-container-long" : ""}`}
      >
        <div
          className={`${longView ? "info-box-container-long-inner" : ""} ${
            kwData && kwData.keywords && kwData.keywords.length > 0
              ? " kw-data"
              : ""
          }`}
        >
          <div className="link-box">
            {screenshots.related_test &&
            screenshots.related_test !== "none" &&
            screenshots.related_test !== "later" ? (
              <div className="platform-toggle-container">
                {screenshots.type === "desktop" && (
                  <>
                    <a
                      href={`/home/${customer}/report/screenshots/${screenshots.related_test}`}
                      title="See Mobile Test"
                      className="platform-toggle off mobile"
                    >
                      <img src={MobileIconOff} alt="Mobile" />
                      mobile
                    </a>
                    <span className="platform-toggle on desktop">
                      <img src={DesktopIconOn} alt="desktop" />
                      desktop
                    </span>
                  </>
                )}
                {screenshots.type === "mobile" && (
                  <>
                    <span className="platform-toggle on mobile">
                      <img src={MobileIconOn} alt="Mobile" />
                      mobile
                    </span>
                    <a
                      href={`/home/${customer}/screenshots/${screenshots.related_test}`}
                      title="See Desktop Test"
                      className="platform-toggle off desktop"
                    >
                      <img src={DesktopIconOff} alt="Desktop" />
                      desktop
                    </a>
                  </>
                )}
              </div>
            ) : (
              <div className="w-100 tc">
                {screenshots.type === "desktop" && (
                  <span className="platform-toggle solo">
                    <img src={DesktopIconOn} alt="desktop" />
                    desktop
                  </span>
                )}
                {screenshots.type === "mobile" && (
                  <span className="platform-toggle solo">
                    <img src={MobileIconOn} alt="Mobile" />
                    mobile
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="dates-box-container">
            <ReportDates
              start_date={screenshots.start_date}
              end_date={screenshots.end_date}
              longView={longView}
            />
          </div>
        </div>
      </div>
      {!newView && (
        <>
          <div className="mb2 pb3 bb b--black-10 f6">
            {screenshots.tags &&
              screenshots.tags.map(tag => (
                <span className="ttu br2 mb1 dib mr2 b tag">{tag}</span>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default ScreenshotInfo;
