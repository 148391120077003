import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { CardElement, injectStripe } from "react-stripe-elements";
import request from "request";
import { baseApiUrl } from "../util";

const labelClass = "db fw4 lh-copy f6 black-60";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      full_name: "",
      redirect: false,
      submitting: false,
      payment_error: false,
    };
  }

  async submit(ev) {
    this.setState({
      submitting: true,
    });
    const { token } = await this.props.stripe.createToken({
      name: this.state.full_name,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.user.token,
        },
        url: `${baseApiUrl}/sub`,
        form: { token: JSON.stringify(token), sku: this.props.sku },
      },
      (err, response) => {
        if (err || (response && response.statusCode > 400)) {
          this.setState({
            payment_error: true,
            submitting: false,
          });
          return;
        }
        this.setState({
          redirect: true,
        });
      }
    );
  }

  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      error: null,
    });
  };
  render() {
    const { redirect, submitting, payment_error } = this.state;
    if (redirect) {
      return <Redirect to="/success" />;
    }
    return (
      <div className="tl">
        <h3 className="f3" style={{ fontWeight: "normal" }}>
          Payment information
        </h3>
        {payment_error && (
          <p className="dark-red b">
            We had trouble processing this transaction. Please try a different
            card or contact us.
          </p>
        )}
        <div className="w-100">
          <div className="mt3 bb b--black-20">
            <label className={labelClass}>Cardholder's name</label>
            <input
              type="text"
              name="full_name"
              onChange={this.handleKeyUp}
              defaultValue={this.props.user.name}
              className="w-100 b--white pb2 pt2 input-reset f6"
              style={{ border: "none" }}
            />
          </div>
          <div className="mt3">
            <label className={labelClass}>Card information</label>
            <div className="pt2">
              <CardElement />
            </div>
          </div>
          <div className="mt4 tr mb2">
            <button
              className="ui-button primary"
              onClick={this.submit}
              disabled={submitting}
            >
              Purchase
            </button>
            {submitting && (
              <p className="f6 tr black-50">Sending your payment&hellip;</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
