import React from "react";
import * as cx from "classnames";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DeleteIcon from "../icons/delete_red.svg";
import DragIcon from "../icons/drag_indicator.svg";
function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
    isOver,
    isSorting,
  } = useSortable({
    id: props.id,
  });
  const { item, removeItem, title, id } = props;
  const onDelete = e => {
    console.log(`delete called`);
    if (id && removeItem) {
      removeItem(id)(e);
    }
  };
  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : "none",
    transition,
    backgroundColor: isDragging ? "#CCCCCC" : "white",
    //border: isDragging ? "solid 1px" : "none",
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={cx({ "bg-light-gray": isOver })}
    >
      <p>
        <span className="ph2">{title}</span>
        <img
          src={DeleteIcon}
          className=""
          style={{ height: "12px", width: "12px" }}
          onClick={onDelete}
        />
        <img
          src={DragIcon}
          className="ph2"
          ref={setActivatorNodeRef}
          {...listeners}
        />
      </p>
    </div>
  );
}
export default SortableItem;
