import { useState } from "react";
import {
  ALLOWED_CUSTOMER_FOR_SLACK_CONNECTION,
  SLACK_CLIENT_ID,
  baseApiUrl,
  encodeForm,
  fbAuth,
} from "../../util";
import * as cx from "classnames";
import * as querystring from "query-string";
import { Redirect } from "react-router-dom";
import Success from "../../icons/old/champion.svg";

const User = props => {
  const qs = querystring.parse(window.location.search);
  const { returnUri, error: errorQS, success: successQS } = qs;
  const [email, setEmail] = useState(props.user ? props.user.email : null);
  const [name, setName] = useState(props.user ? props.user.name : null);
  const [password, setPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState(errorQS ? { message: errorQS } : null);
  const [success, setSuccess] = useState(successQS ? true : null);
  const [loading, setLoading] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);
  const [type, setType] = useState(null);

  if (!props.user) {
    return <Redirect to={`/login?returnUri=${window.location.href}`} />;
  }

  const { user } = props;
  console.log({ user });
  const {
    reauthenticateWithCredential,
    EmailAuthProvider,
    updateEmail,
    updatePassword,
    getIdToken,
  } = fbAuth.currentUser;

  const updateInfo = (name, email, user) =>
    new Promise((resolve, reject) => {
      user.getIdToken().then(token => {
        fetch(`${baseApiUrl}/v1/user/edit/info`, {
          method: "POST",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: token,
          },
          body: encodeForm({ name, email }),
        })
          .then(res => res.json())
          .then(data => {
            resolve(true);
          })
          .catch(e => {
            reject(true);
          });
      });
    });
  const removeSlackRequest = () => {
    new Promise((resolve, reject) => {
      user.getIdToken().then(token => {
        fetch(`${baseApiUrl}/v1/user/removeSlack`, {
          method: "POST",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: token,
          },
        })
          .then(res => res.json())
          .then(data => {
            resolve(true);
            window.location.reload();
          })
          .catch(e => {
            reject(true);
          });
      });
    });
  };
  const onChangeType = type => e => {
    e.preventDefault();
    setType(type);
  };

  const handleNameChange = async u => {
    await updateInfo(name, user.email, u);
    return true;
  };

  const handlePasswordChange = u =>
    new Promise((resolve, reject) => {
      u.updatePassword(new_password)
        .then(async res => {
          resolve(true);
        })
        .catch(e => {
          reject(e);
        });
    });
  const handleEmailChange = (u, new_email) =>
    new Promise((resolve, reject) => {
      u.updateEmail(new_email)
        .then(async res => {
          await updateInfo(name, new_email, u);
          resolve(true);
        })
        .catch(async e => {
          reject(e);
        });
    });

  const handleSubmit = type => async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    if (!password || password === "") {
      setError({ type: "no-password", message: "Please enter your password." });
      setLoading(false);
      return;
    }
    fbAuth
      .signInWithEmailAndPassword(fbAuth.currentUser.email, password)
      .then(async authState => {
        const u = authState.user;
        try {
          if (type === "email") {
            await handleEmailChange(u, email);
          } else if (type === "password") {
            await handlePasswordChange(u);
          } else if (type === "name") {
            await handleNameChange(u);
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (e) {
          console.error(e);
          setError(e);
          setLoading(false);
        }
        setLoading(false);
        setSuccess(true);
      })
      .catch(e => {
        if (e.message && e.message.includes("wrong-password")) {
          setError({
            type: "wrong-password",
            message: "Please try a different password.",
          });
        } else {
          setError(e);
        }
        setLoading(false);
      });
  };
  const handleKeyUp = e => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      if (value !== user.email) {
        setEmailDirty(true);
      } else {
        setEmailDirty(true);
      }
    }
    if (name === "name") {
      setName(value);
    }
    if (name === "password") {
      setPassword(value);
    }
    if (name === "new_password" || name === "confirm_password") {
      if (name === "new_password") {
        setNewPassword(value);
        if (confirm_password !== "" && value !== confirm_password) {
          setUnmatchedPassword(true);
        } else {
          setUnmatchedPassword(false);
        }
        if (value !== "") {
          setPasswordDirty(true);
        } else {
          setPasswordDirty(false);
        }
      }
      if (name === "confirm_password") {
        setConfirmPassword(value);
        if (confirm_password !== "" && value !== new_password) {
          setUnmatchedPassword(true);
        } else {
          setUnmatchedPassword(false);
        }
        if (new_password !== "") {
          setPasswordDirty(true);
        } else {
          setPasswordDirty(false);
        }
      }
    }
  };
  const removeSlackConnection = e => {
    setLoading(true);
    removeSlackRequest();
  };
  const labelClass = "db lh-copy black-80 b";
  const inputClass = "pa2 input-reset w-100 ba b--black-20 bg-white br2";
  const doKeywordsCallback = () => {};
  const toggleMenu = menu => e => {};
  if (success && successQS) {
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }
  return (
    <main className="pv4 mw7 center">
      <div
        className="box center br2 bg-white tl"
        style={{ position: "relative" }}
      >
        <div className="bb b--black-05 ph3 pt3 pb2">
          <h1>Account</h1>
        </div>
        <div className="tl pa3">
          {error && (
            <p className="dark-red pt3">
              {error.message ? <>{error.message}</> : <>Something went wrong</>}
            </p>
          )}
          <div>
            <label className={labelClass}>Name</label>
            <>
              {type === "name" ? (
                <form onSubmit={handleSubmit(type)}>
                  <input
                    type="name"
                    onChange={handleKeyUp}
                    name="name"
                    defaultValue={user.name}
                    className={inputClass}
                  />
                  <div className="mt3">
                    <label className={labelClass}>Enter Your Password</label>
                    <input
                      type="password"
                      onChange={handleKeyUp}
                      name="password"
                      className={inputClass}
                    />
                  </div>
                  <div className="mt4">
                    {!loading ? (
                      <div className="flex items-center">
                        <input
                          type="submit"
                          value={`Save`}
                          className="ui-button med-button primary"
                          disabled={unmatchedPassword}
                        />
                        <a
                          href="#"
                          onClick={onChangeType(null)}
                          className="ml2"
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <button
                        className="ui-button med-button secondary"
                        disabled
                      >
                        Saving&hellip;
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <div className="mt3">
                  {user.name}{" "}
                  <a onClick={onChangeType("name")} href="#">
                    Edit
                  </a>
                </div>
              )}
            </>
          </div>
          <div className="mt4">
            <label className={labelClass}>Email</label>
            <>
              {type === "email" ? (
                <form onSubmit={handleSubmit(type)}>
                  <input
                    type="email"
                    onChange={handleKeyUp}
                    name="email"
                    defaultValue={user.email}
                    className={inputClass}
                  />
                  <div className="mt3">
                    <label className={labelClass}>Enter Your Password</label>
                    <input
                      type="password"
                      onChange={handleKeyUp}
                      name="password"
                      className={inputClass}
                    />
                  </div>
                  <div className="mt4">
                    {!loading ? (
                      <div className="flex items-center">
                        <input
                          type="submit"
                          value={`Save`}
                          className="ui-button med-button primary"
                          disabled={unmatchedPassword}
                        />
                        <a
                          href="#"
                          onClick={onChangeType(null)}
                          className="ml2"
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <button
                        className="ui-button med-button secondary"
                        disabled
                      >
                        Saving&hellip;
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <div className="mt3">
                  {user.email}{" "}
                  <a onClick={onChangeType("email")} href="#">
                    Edit
                  </a>
                </div>
              )}
            </>
          </div>
          <div className="mt4">
            <label className={labelClass}>Change Password</label>
            <>
              {type === "password" ? (
                <form onSubmit={handleSubmit(type)}>
                  <label className={labelClass}>New Password</label>
                  <input
                    type="password"
                    onChange={handleKeyUp}
                    name="new_password"
                    className={inputClass}
                  />
                  <div className="mt3">
                    <label className={labelClass}>Confirm New Password</label>
                    <input
                      type="password"
                      onChange={handleKeyUp}
                      name="confirm_password"
                      className={inputClass}
                    />
                  </div>
                  <div className="mt3">
                    <label className={labelClass}>
                      Enter Your Current Password
                    </label>
                    <input
                      type="password"
                      onChange={handleKeyUp}
                      name="password"
                      className={inputClass}
                    />
                  </div>
                  <div className="mt4">
                    {!loading ? (
                      <div className="flex items-center">
                        <input
                          type="submit"
                          value={`Save`}
                          className="ui-button med-button primary"
                          disabled={unmatchedPassword}
                        />
                        <a
                          href="#"
                          onClick={onChangeType(null)}
                          className="ml2"
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <button
                        className="ui-button med-button secondary"
                        disabled
                      >
                        Saving&hellip;
                      </button>
                    )}
                  </div>
                </form>
              ) : (
                <div className="mt3">
                  ********{" "}
                  <a onClick={onChangeType("password")} href="#">
                    Edit
                  </a>
                </div>
              )}
            </>
          </div>
          {user.customer &&
            user.permissions &&
            user.permissions.role === "admin" &&
            ALLOWED_CUSTOMER_FOR_SLACK_CONNECTION.includes(user.company) && (
              <div className="mt4">
                <label className={labelClass}>Add to Slack</label>
                <div className="flex">
                  {user.customer.slackConnected && (
                    <span className="pr2">Connected</span>
                  )}
                  {success && successQS === "slack" && (
                    <div className="flex mh2">
                      <img
                        src={Success}
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                        alt="success"
                        className="moviefone-success"
                      />
                    </div>
                  )}
                  <a
                    href={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=incoming-webhook,chat:write,channels:join&user_scope=`}
                  >
                    {user.customer.slackConnected ? (
                      <span className="pointer ph1">Reconnect</span>
                    ) : (
                      <img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                      />
                    )}
                  </a>
                  {user.customer.slackConnected && (
                    <>
                      &middot;
                      <a
                        onClick={removeSlackConnection}
                        className="pointer ph1"
                        href="#"
                      >
                        Remove
                      </a>
                    </>
                  )}
                </div>
              </div>
            )}
          <div className="error red mt1">
            {unmatchedPassword ? <>Passwords don't match!</> : <>&nbsp;</>}
          </div>
        </div>
      </div>
    </main>
  );
};

export default User;
