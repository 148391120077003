import React from "react";
import request from "request";
import * as qs from "query-string";
import { Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { stripeApiKey, formatStripePrice, baseApiUrl } from "../../util";
import Register from "../../components/Register";
import SubPicker from "../../components/SubPicker";
import CheckoutForm from "../../components/CheckoutForm";

class Subscribe extends React.Component {
  parseQS = () => {
    const QS = qs.parse(window.location.search);
    return QS || {};
  };
  state = {
    authed: false,
    loading: true,
    sub: null,
    isSSO: false,
    needsInvite: false,
    invite: this.parseQS().invite,
    redirectUrl: this.parseQS().returnUri,
  };
  componentDidMount() {
    const { user, match, loading } = this.props;

    if (user) {
      request.get(
        {
          url: `${baseApiUrl}/v1/sub?company=${match.params.company}`,
          headers: {
            Authorization: user.token,
          },
        },
        async (err, res, data) => {
          const json = JSON.parse(res.body);
          this.setState({ sub: json.sub, loading: false });
        }
      );
    } else {
      request.get(
        {
          url: `${baseApiUrl}/v1/can_register?company=${
            this.props.match.params.company
          }${this.state.invite ? `&invite=${this.state.invite}` : ""}`,
        },
        async (err, data) => {
          if (err || data.statusCode >= 400) {
            this.setState({
              error: {
                key: "cant_reg",
              },
              submitting: false,
            });
            return;
          }
          const json = JSON.parse(data.body);
          this.setState({
            loading: false,
            needsInvite: json.invite,
            isSSO: json.isSSO,
          });
        }
      );
    }
  }
  render() {
    const { sub, loading, isSSO, needsInvite, invite } = this.state;
    const { isRegister, user, match, authed } = this.props;
    if (loading) {
      return (
        <div className="loader">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }

    if (isRegister && user && authed) {
      if (this.state.redirectUrl) {
        window.location.replace(this.state.redirectUrl);
        return <></>;
      }

      return <Redirect to={`/home/${user.company}/report?from=register`} />;
    }

    if (isRegister && !user && !authed && isSSO && !needsInvite) {
      return <Redirect to={`/login/?sso=true`} />;
    }
    return (
      <main className="pt4 pb4 tl">
        {!loading && !user && match.params.company && !needsInvite && (
          <Register
            company={match.params.company}
            isRegister={this.props.isRegister}
            redirectUrl={this.state.redirectUrl}
          />
        )}
        {needsInvite && (
          <div
            className="register box pa4 center tc"
            style={{ width: "650px" }}
          >
            <h1 className="mb4">You'll need an invite to sign up</h1>{" "}
            <p className="f4">
              Please ask the main point of contact at your company.
            </p>
            <p className="f4">
              You an also email us at{" "}
              <a href="mailto:help@dowhatworks.io">help@dowhatworks.io</a> with
              any questions.
            </p>
          </div>
        )}
        {user && !loading && !sub && (
          <div className="box pa3 center" style={{ width: "650px" }}>
            <h1>We can't find your company&hellip;</h1>{" "}
            <p>
              Do What Works is currently in private beta. If you'd like to learn
              more, email us at{" "}
              <a href="mailto:help@dowhatworks.io">help@dowhatworks.io</a>.
            </p>
          </div>
        )}
        {user && !loading && sub && (
          <div className="register center box tl" style={{ width: "650px" }}>
            <div className="tl pa3">
              <h1 style={{ fontSize: "2rem", fontWeight: "normal" }}>
                {sub.name}
                <br />
                <span className="f3 sb">{sub.description}</span>
              </h1>
              <div class="flex items-middle mb2">
                <input
                  type="radio"
                  name="sku"
                  checked
                  className="mr2"
                  value={sub.price_data.id}
                />
                <div>
                  <p className="f4" style={{ marginTop: "1.25rem" }}>
                    {formatStripePrice(sub.price_data.unit_amount)}/
                    {sub.price_data.recurring.interval} ({sub.price_data.type})
                  </p>
                </div>
              </div>

              <StripeProvider apiKey={stripeApiKey}>
                <div>
                  <Elements>
                    <CheckoutForm
                      user={this.props.user}
                      sku={sub.price_data.id}
                    />
                  </Elements>
                </div>
              </StripeProvider>
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default Subscribe;
