import React, { Component } from "react";
import request from "request";
import { baseApiUrl } from "../util";
import NoteDisplay from "./NoteDisplay";
import TextareaAutosize from "react-textarea-autosize";

class EditableAutocomplete extends Component {
  constructor(props) {
    super(props);

    const tlItems = [];

    this.props.items.sort(this.doSort);
    this.props.items.forEach(item => {
      const tl = item.value.split(":")[0];

      if (!tlItems.includes(tl)) {
        tlItems.push(tl);
      }
    });

    const allItems = this.props.items.map(item => {
      const itemArr = item.value.split(":");

      if (!itemArr[1]) {
        itemArr[1] = "";
      }

      return {
        text: item.value,
        tl: itemArr[0].trim(),
        vary: itemArr[1].trim(),
        rest: itemArr.length > 2 ? itemArr.slice(2).join(": ") : null,
      };
    });

    this.state = {
      items: tlItems,
      tlItems,
      allItems,
      subtags: this.props.subtags,
      value: this.props.initialValue,
      showSelector: false,
      dirty: false,
      hasFocus: true,
      isTl: true,
    };
  }

  doSort = (a, b) => {
    return a.value.toLowerCase().replace(/[^a-z0-9]/gi, "") >=
      b.value.toLowerCase().replace(/[^a-z0-9]/gi, "")
      ? 1
      : -1;
  };

  select = item => e => {
    if (item.indexOf(":") === -1) {
      const val = `${item}:`;
      this.setState({
        value: val,
        showSelector: true,
      });
      this.input.current.focus();
      this.handleChange({ currentTarget: { value: val } });
    } else {
      this.setState(
        {
          value: item,
        },
        () => {
          this.props.callback(item);
        }
      );
    }
  };
  create = e => {
    if (this.state.value.split(":").length < 2) {
      alert(
        "All notes should be formatted in the style of: [Element changing]: [Change]: [what's changing text for display]. If you need a new top-level element, please talk to Will or Andres."
      );
      return;
    }
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/tags`,
        form: {
          headline: "note",
          tag: this.state.value,
        },
      },
      err => {
        if (err) {
          alert(err);
          return;
        }
        this.props.callback(this.state.value);
        return;
      }
    );
  };
  input = React.createRef();
  handleFocus = e => {
    if (this.props.focusCallback) {
      this.props.focusCallback();
    }
    const { value } = e.currentTarget;

    if (!value || value.trim() === "") {
      this.setState({
        items: this.state.tlItems,
        showSelector: true,
        isTl: true,
        hasFocus: true,
      });
    } else {
      this.handleChange({ currentTarget: { value } });
    }
  };
  handleChange = e => {
    const { value } = e.currentTarget;
    this.setState({ value });
    setTimeout(() => {
      const tlds = [];
      const vals = value.split(" ");
      let items =
        value.trim() !== "" && value.length > 1
          ? this.state.allItems
              .filter(item => {
                let found = true;

                vals.forEach(val => {
                  if (
                    item.text
                      .toLowerCase()
                      .indexOf(val.toLowerCase().trim()) === -1
                  ) {
                    found = false;
                  }
                });

                const tld = `${item.tl}: ${item.vary}: `;
                if (found && vals.length < 3) {
                  let tFound = true;

                  vals.forEach(val => {
                    if (
                      tld.toLowerCase().indexOf(val.toLowerCase().trim()) === -1
                    ) {
                      tFound = false;
                    }
                  });
                  if (tFound) {
                    item.tldFound = true;
                  }
                }

                return found;
              })
              .sort((a, b) => {
                if (a.tldFound && !b.tldFound) {
                  return -1;
                } else if (!a.tldFound && b.tldFound) {
                  return 1;
                } else {
                  return a.text >= b.text ? 1 : -1;
                }
              })
          : this.state.tlItems;

      if (this.state.subtags) {
        const stArr = value.split(":");
        const st = stArr.pop().trim();
        if (st.length > 1) {
          const displayValue = stArr.length > 0 ? `${stArr.join(": ")}: ` : "";
          const moreItems = this.state.subtags
            .filter(t => t.toLowerCase().indexOf(st.toLowerCase()) !== -1)
            .map(t => `${displayValue}${t}`);

          if (moreItems.length > 0) {
            items = items.concat(moreItems);
          }
        }
      }

      this.setState({
        items: items,
        showSelector: true,
        dirty: true,
      });
    }, 1);
  };
  handleKeyUp = () => {
    setTimeout(() => {
      this.setState({
        isTl: false,
      });
    }, 1);
  };
  handleBlur = e => {
    this.setState({
      hasFocus: false,
      value: e.currentTarget.value,
      showSelector: false,
    });
    setTimeout(() => {
      if (!this.state.hasFocus) {
        this.props.callback(this.state.value);
      }
    }, 500);
  };
  render() {
    const { placeholder, initialValue } = this.props;
    const { value, items, showSelector } = this.state;

    return (
      <div style={{ position: "relative" }} className="w-100">
        <TextareaAutosize
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          defaultValue={initialValue}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref={this.input}
          className={this.props.className}
          style={{ padding: "4px 2px", margin: "1px" }}
        />
        <div
          className="autocomplete tl"
          style={{
            display: showSelector ? "block" : "none",
            position: "absolute",
            left: "1px",
            marginTop: "-10px",
            minWidth: "100%",
            background: "white",
            zIndex: 2,
            borderBottom: "1px solid #000",
            borderLeft: "1px solid #000",
            borderRight: "1px solid #000",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            paddingTop: "12px",
            paddingBottom: "12px",
            maxHeight: "200px",
            overflowX: "none",
            overflowY: "scroll",
          }}
        >
          {items.length > 0 && (
            <div>
              {value && value.trim() !== "" && value.indexOf(":") !== -1 && (
                <div
                  onClick={this.create}
                  className="pointer pa2 bb b--black-10 i"
                >
                  Create {value}
                </div>
              )}
              {items.map((item, i) => (
                <div
                  onClick={this.select(item.vary ? item.text : item)}
                  key={`aItem-${i}`}
                  className="pointer pa2"
                >
                  {item.tl || item.vary ? (
                    <span>
                      <b>{item.tl}: </b> <i>{item.vary}: </i> {item.rest}
                    </span>
                  ) : (
                    <b>{item}</b>
                  )}
                </div>
              ))}
            </div>
          )}
          {items.length === 0 && (
            <div onClick={this.create} className="pointer pa2">
              Create {value}
            </div>
          )}
        </div>
        {/* 
        <div className="w-100 tl f7">
          <NoteDisplay text={value} />
        </div>
        */}
      </div>
    );
  }
}

export default EditableAutocomplete;