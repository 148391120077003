import React, { Component } from "react";
import request from "request";
import { baseApiUrl } from "../util";

class EditableAutocompleteHeadline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allItems: this.props.items,
      items: this.props.items,
      value: this.props.initialValue,
      showSelector: false,
      dirty: false,
      hasFocus: true,
      isTl: true,
    };
  }

  doSort = (a, b) => {
    return a.value.toLowerCase().replace(/[^a-z0-9]/gi, "") >=
      b.value.toLowerCase().replace(/[^a-z0-9]/gi, "")
      ? 1
      : -1;
  };

  select = item => e => {
    this.setState(
      {
        value: item.value,
        dirty: true,
      },
      () => {
        this.props.callback(item);
      }
    );
  };
  create = e => {
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: this.props.token,
        },
        url: `${baseApiUrl}/v1/tags`,
        form: {
          headline: true,
          tag: this.state.value,
        },
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          alert("Looks like the headline didn't save! Please try again");
          return;
        }
        this.props.callback(this.state.value);
        return;
      }
    );
  };
  input = React.createRef();
  handleFocus = e => {
    const { value } = e.currentTarget;

    if (!value || value.trim() === "") {
      this.setState({
        items: this.state.allItems,
        showSelector: true,
        isTl: true,
        hasFocus: true,
      });
    } else {
      this.handleChange({ currentTarget: { value } });
    }
  };
  handleChange = e => {
    const { value } = e.currentTarget;
    const items =
      value.trim() !== ""
        ? this.state.allItems.filter(item => item.value.indexOf(value) !== -1)
        : this.state.allItems;

    this.setState({
      value,
      items: items,
      showSelector: true,
      dirty: true,
    });
  };
  handleKeyUp = () => {
    this.setState({
      isTl: false,
    });
  };
  handleBlur = e => {
    this.setState({
      hasFocus: false,
      value: e.currentTarget.value,
    });
    setTimeout(() => {
      if (!this.state.hasFocus) {
        this.setState({
          showSelector: false,
        });
        this.props.callback(this.state.value);
      }
    }, 500);
  };
  render() {
    const { placeholder, initialValue } = this.props;
    const { value, items, showSelector, dirty } = this.state;

    return (
      <div style={{ position: "relative" }} className="w-100">
        <input
          type="text"
          placeholder={placeholder}
          value={dirty ? value : initialValue}
          defaultValue={initialValue}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref={this.input}
          className="w-100"
          style={{ padding: "4px 2px", margin: "1px" }}
        />
        <div
          className="autocomplete tl"
          style={{
            display: showSelector ? "block" : "none",
            position: "absolute",
            left: "1px",
            top: "35px",
            minWidth: "100%",
            background: "white",
            zIndex: 2,
            borderBottom: "1px solid #000",
            borderLeft: "1px solid #000",
            borderRight: "1px solid #000",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            paddingTop: "12px",
            paddingBottom: "12px",
            maxHeight: "200px",
            overflowX: "none",
            overflowY: "scroll",
          }}
        >
          {items.length > 0 && (
            <div>
              {value &&
                value.trim() !== "" &&
                !items.find(i => i.value === value) && (
                  <div
                    onClick={this.create}
                    className="pointer pa2 bb b--black-10 i"
                  >
                    <b>Create</b> {value}
                  </div>
                )}
              {items.map(item => (
                <div
                  onClick={this.select(item)}
                  key={item.value}
                  className="pointer pa2"
                >
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          )}
          {items.length === 0 && (
            <div onClick={this.create} className="pointer pa2">
              <b>Create</b> {value}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EditableAutocompleteHeadline;
