import React from "react";

class Apply extends React.Component {
  componentDidMount() {
    var qs,
      js,
      q,
      s,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = "typef_orm",
      b = "https://embed.typeform.com/";
    if (!gi.call(d, id)) {
      js = ce.call(d, "script");
      js.id = id;
      js.src = b + "embed.js";
      q = gt.call(d, "script")[0];
      q.parentNode.insertBefore(js, q);
    }
  }
  render() {
    return (
      <div className="center tc w-80 w-100-m br3 overflow-hidden">
        <div
          class="typeform-widget"
          data-url="https://form.typeform.com/to/MQNYoUBq"
          style={{ width: "100%", height: "500px" }}
        ></div>
      </div>
    );
  }
}

export default Apply;
