import firebase from "firebase/app";
import "firebase/auth";
import request from "request";
import desktop1 from "../img/blurred_shots/desktop_1.jpg";
import desktop2 from "../img/blurred_shots/desktop_2.jpg";
import desktop3 from "../img/blurred_shots/desktop_3.jpg";
import desktop4 from "../img/blurred_shots/desktop_4.jpg";
import mobile1 from "../img/blurred_shots/mobile_1.jpg";
import mobile2 from "../img/blurred_shots/mobile_2.jpg";
import mobile3 from "../img/blurred_shots/mobile_3.jpg";
import mobile4 from "../img/blurred_shots/mobile_4.jpg";
import full_desktop1 from "../img/blurred_shots_full/desktop_1.jpg";
import full_desktop2 from "../img/blurred_shots_full/desktop_2.jpg";
import full_desktop3 from "../img/blurred_shots_full/desktop_3.jpg";
import full_desktop4 from "../img/blurred_shots_full/desktop_4.jpg";
import full_mobile1 from "../img/blurred_shots_full/mobile_1.jpg";
import full_mobile2 from "../img/blurred_shots_full/mobile_2.jpg";
import full_mobile3 from "../img/blurred_shots_full/mobile_3.jpg";
import full_mobile4 from "../img/blurred_shots_full/mobile_4.jpg";
const firebaseConfig = {
  apiKey: "AIzaSyDrDLG3-t8dN66T6huGd2SPWrDtsso4hzQ",
  authDomain: "dww-web-52b3e.firebaseapp.com",
  databaseURL: "https://dww-web-52b3e.firebaseio.com",
  projectId: "dww-web-52b3e",
  storageBucket: "",
  messagingSenderId: "267167280403",
  appId: "1:267167280403:web:c55a33fd9161565df67ee6",
  measurementId: "G-KJ44JBS0PN",
};

const ssHashMap = {
  "765aa5d5-fc0d-4f45-a13c-525de0323c3d":
    "0cb78f71-fa83-435f-84cb-8ef59bc95faa",
  "8b253d68-19e5-4856-be6c-7bcdc13b6bc7":
    "24de3f25-2cbb-48ab-9339-893594a9deac",
  "a9c8a9fc-3545-41e2-978c-c44a0181df73":
    "76b9e270-cdd1-440a-8ff5-7a570779d65c",
  "fc7034f7-1e9e-4480-99f7-5407af02c75a":
    "0cb78f71-fa83-435f-84cb-8ef59bc95faa",
  "475646e6-a37c-4bb1-85ac-cf1f86657c51":
    "6d712cbb-9afa-4f83-9f40-16b4372fe994",
  "ad3ebd87-03cf-445e-b386-ebfe5ddd65fa":
    "2847a1a7-9b0f-4265-a3a6-dccce66b547e",
  "b47a08a9-4f01-47af-86fc-c7677c1c0fe5":
    "a0c8ada5-02a5-4fa3-8fef-a6637cc2e47d",
  "28aa097c-7ac1-4fd8-9898-7984c1462222":
    "c68e0f60-1004-4802-9945-7ffb7cb65fc1",
  "73162a7d-bbef-40b3-8771-474cd5785f87":
    "eaa69815-1860-46dd-9ed1-cca150b4af73",
  "60fca3ef-d3d7-4352-9ff0-86a27af48441":
    "74e5f92c-f008-4fb8-9c1c-7717928b6407",
};

const urlHashMap = {
  "elizabethwarren.com/": "www.elizabethwarren.com/",
  "www.google.com/aclk?sa=l&ai=DChcSEwilgvSZt_7lAhXLnrMKHZi6BJAYABAAGgJxbg&sig=AOD64_2IDuerEde37N_F-RMF-8556lqnFQ&adurl=&rct=j&q=":
    "www.kickstarter.com/",
  "get.daily-harvest.com/search-hp/": "www.daily-harvest.com/",
  "thrivemarket.com/": "www.thrivemarket.com/",
  "sunbasket.com/try/healthy-2019/?offer=SEMGBACQ35":
    "www.sunbasket.com/Eat-Clean/Healthy-2019",
  "secure.aspca.org/donate/ps-gn-p2": "secure.aspca.org/donate",
  "www.eventbrite.com/l/sell-tickets-online/":
    "www.eventbrite.com/sell-tickets",
  "www.google.com/aclk?sa=l&ai=DChcSEwiQ8oub2KTmAhUjhVoFHfxpDeAYABAAGgJ2dQ&sig=AOD64_0ys0mM3ooasyK5imycSjcMfgMAgw&adurl=&rct=j&q=":
    "www.surveymonkey.com/Create/Surveys",
  "www.google.com/aclk?sa=l&ai=DChcSEwi-6J2vjqbmAhUKbIYKHcRpDfUYABAAGgJ2dQ&sig=AOD64_2C_ADToSHnqVUOwFT-ZJE9ywHy2A&adurl=&rct=j&q=":
    "www.airbnb.com/",
  "www.google.com/aclk?sa=l&ai=DChcSEwi6hYG3sIfmAhWJlbMKHUHuAmsYABAAGgJxbg&sig=AOD64_2UZLgGJ_-5WqVOjoxSd2x6Mwlahw&adurl=&rct=j&q=":
    "www.blueapron.com/",
  "www.google.com/aclk?sa=l&ai=DChcSEwiL3LjcpbbmAhVM3IYKHZ0aB1gYABAAGgJ2dQ&sig=AOD64_034gfWL7m2TRKwWY9eOh2fhv0iWA&adurl=&rct=j&q=":
    "secure.aspca.org/donate",
  "www.google.com/aclk?sa=l&ai=DChcSEwjApdC_zLLmAhWVg1oFHcc6A00YABAFGgJ2dQ&sig=AOD64_3jwGz8fX8psB3cfeAdirG4sfIhAg&adurl=&rct=j&q=":
    "www.surveymonkey.com/",
  "get.daily-harvest.com/search-lp/": "www.daily-harvest.com/",
  "card.americanexpress.com/d/american-express/": "www.americanexpress.com/",
  "www.economist.com/subscribe":
    "subscription.economist.com/Magazine/Subscribe",
  "subs.ft.com/spa3_bc1": "www.ft.com/Subscribe/Today",
  "www.usaa.com/inet/wc/ins_bundle_landing": "www.usaa.com/Insurance/Auto",
  "costcohomefinance.com/getrates/?": "www.costcohomefinance.com/",
};

export const urlLookup = url => {
  const newUrl = urlHashMap[url];

  return newUrl ? newUrl : url;
};
export const ssLookup = id => {
  const newId = ssHashMap[id];

  return newId ? newId : id;
};

export const loginLookup = {
  changedotorg: "22484273-b6ea-4612-aa69-68126c88599e",
  hungryroot_old: "a945451a-367c-4924-9d55-6b56700ddf97",
  hungryroot: "5a003a1c-0bef-495a-b8a8-6c4d98b9c1bd",
  demo: "6a65e917-b1eb-4607-b7b6-f3fd26bf5377",
  hok: "f453c0fc-29e7-438c-b402-da50b801c67d",
  candid: "dad304f1-948c-43db-9caa-9d10413a47e0",
  teacherspayteachers: "a62bc59e-3c46-4d50-844e-b6b386733db8",
  prospect: "cf6e66f0-25c3-4453-8568-9ecd029315ce",
  disney: "33a30a88-f75a-4a65-b5c7-83ff958ff002",
  purplecarrot: "efa1acf2-1ee4-462a-8591-8973ebafa34d",
  cookunity: "ee16bd44-3a8b-4911-83b3-df398c69d432",
  test: "8b4f525e-b33f-47a9-bbbf-0ac392e36367",
  admin: "admin",
  variis: "8067a95b-7e8e-4a31-a796-ae0c2e6bce07",
  all: "a1bc8b23-3724-45a0-b387-2013475f512d",
  pillclub: "4e27dc09-ad3c-4b1b-a122-3147b2c40766",
  dailyharvest: "91e18338-ec36-487f-ade3-27f2ce497f7b",
  alexgodin: "ca5720a6-2355-4ecc-b189-9cb329fd6093",
  veestro: "39c5bd00-0ec8-4e1f-bb49-7124b36054ef",
  allplants: "e1559f52-0143-49a0-aa8b-e8a17b8d29e9",
  phin: "b49bf677-2475-4027-a11d-8d44b9f939e9",
  mirror: "f3616bbd-24dd-4d4c-adbb-18b8c259bdc6",
  homechef: "9c57b974-ee2d-44e9-b91a-c236a2b17dd3",
  cbs: "97242189-c3a8-4ce2-b2e7-1a7ede52b285",
  curology: "2850cb5a-f011-43db-9da9-ecb8635caee9",
  hellofresh: "a80162c6-2c2c-40b0-9f61-e4026bbbe929",
  duolingo: "a9cee209-2309-4349-b572-268afe7adc13",
  edlyn: "f073419f-cb11-437a-be19-5cd077d47488",
  siriusxm: "1ed0e233-d8fe-4cce-a232-6cb4be3c1285",
  spotify: "b125e14a-6c6b-458d-88e5-e10e11392655",
  disneydemo: "635ea9c9-15f6-40bf-8625-3477c268571d",
  vidsense: "ee43ef5b-dc7e-4fd0-bc3b-a5f028b0b4f3",
  steveberry: "c9d9544f-f64c-4470-b3c3-3f452761653d",
  lovevery: "c2bc26db-4b22-4e09-998f-15e1b9263bc9",
  peacock: "afb131cf-84e3-44b1-9d3c-5dead5daf867",
  marleyspoon: "599b5861-6f76-4cf8-a009-22a7dd2a811a",
  classpass: "34d74a1e-57ea-475d-a1bf-2978742c91a2",
  buildbook: "97d26982-0bd2-4ded-9b45-614b2522c0d7",
  dashboard: "d5b215ce-efed-4ea4-b7ee-aec4e680081b",
  webex: "xx",
  adobe: "xx",
  deta: "xx",
  thrivemarket: "Thrive Market",
  oura: "Oura Health",
  thryv: "Thryv",
  hotjar: "Hotjar",
  cometeer: "Cometeer",
};
export const companyNames = {
  changedotorg: "Change.org",
  hungryroot: "Hungryroot",
  demo: "Demo",
  hok: "HoK",
  candid: "Candid",
  teacherspayteachers: "Teachers Pay Teachers",
  prospect: "Prospect",
  disney: "Disney+",
  disneydemo: "Disney+",
  purplecarrot: "Purple Carrot",
  cookunity: "CookUnity",
  test: "Test",
  variis: "Variis",
  admin: "Admin",
  pillclub: "Pill Club",
  alexgodin: "Alex Godin",
  dailyharvest: "Daily Harvest",
  allplants: "allplants",
  phin: "Phin",
  veestro: "Veestro",
  mirror: "Mirror",
  homechef: "Home Chef",
  hellofresh: "HelloFresh",
  duolingo: "Duolingo",
  edlyn: "Edlyn",
  cbs: "CBS Interactive",
  curology: "Curology",
  all: "All Tests",
  siriusxm: "SiriusXM",
  spotify: "Spotify",
  vidsense: "Vidsense",
  steveberry: "Steve Berry",
  lovevery: "Lovevery",
  peacock: "Peacock",
  marleyspoon: "Marley Spoon",
  classpass: "ClassPass",
  buildbook: "BuildBook",
  charitywater: "Charity: Water",
  dashboard: "Sample",
  adobe: "Adobe",
  deta: "Deta",
  thrivemarket: "Thrive Market",
  cometeer: "Cometeer",
  oura: "Oura Health",
  thryv: "Thryv",
  hotjar: "Hotjar",
};
export const COLLECTION_TYPES = {
  collection: "collection",
  analysis: "analysis",
};
const rLookup = {};

Object.keys(loginLookup).forEach(c => {
  rLookup[loginLookup[c]] = {
    key: c,
    name: companyNames[c],
  };
});
export const reportLookup = (report, comma) => {
  if (rLookup[report]) {
    return `${comma ? ", " : ""}${rLookup[report].name}`;
  } else if (report === "8856d019-8c3e-489d-b0aa-32bd0923c6a0") {
    return `${comma ? ", All tests draft" : "All tests draft"}`;
  } else {
    return `${comma ? ", Prospect" : "Prospect"}`;
  }
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const fbAuth = firebase.auth();

export const pickRandomElm = arr => {
  const rand = Math.floor(Math.random() * arr.length);
  return arr[rand];
};

export const truncateText = (text, amount) => {
  if (text.length <= amount) {
    return text;
  }
  return `${text.substring(0, amount)}...`;
};

const padZero = num => {
  if (num >= 10) {
    return num;
  } else {
    return `0${num}`;
  }
};
export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const capitalize = str =>
  str && typeof str === "string"
    ? `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    : str;
export const addCommas = (num = 0) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const niceNumbers = (num = 0) => {
  if (num >= 1000000) {
    num = (num * 0.000001).toFixed(2);
    return `${num}M`;
  } else if (num >= 100000) {
    num = (num * 0.001).toFixed(0);
    return `${num}K`;
  } else {
    return addCommas(num);
  }
};

export const formatTime = date => {
  return `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
};

export const formatDate = (date, small) => {
  if (small) {
    const year = date.getFullYear().toString();
    return `${date.getMonth() + 1}/${date.getDate()}/${year.substr(2, 3)}`;
  }
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const formatDateFromString = date => {
  if (date && date !== "") {
    const dateArr = date.split("-");
    return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
  }
  return date;
};

export const formatDateAndTime = date => {
  return `${formatDate(date)} ${formatTime(date)}`;
};

export const formatReportDate = dateStr => {
  const date = new Date(dateStr);
  date.setUTCHours(18);
  return formatDate(date, true);
};

const cache = {};

const CACHE_TIME = 1800000;

export const saveSearch = (search, token, id, company, cb) => {
  request.post(
    {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
      url: `${baseApiUrl}/v1/saved_search`,
      form: {
        id,
        company,
        search: JSON.stringify(search),
      },
    },
    (err, res, data) => {
      if (err) {
        cb(null, err);
        return;
      }
      cb(data);
    }
  );
};

export const getFromStorage = key => {
  const data = localStorage.getItem(key);

  if (data) {
    const parsedData = JSON.parse(data);
    const now = new Date().getTime();
    if (parsedData.time && now <= parsedData.time) {
      return parsedData;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const addToStorage = (key, data) => {
  const date = new Date();
  date.setHours(date.getHours() + 1);
  data.time = date.getTime();
  localStorage.setItem(key, JSON.stringify(data));
  return true;
};

export const problems = [
  "Driving trial & adoption",
  "Optimizing plans & pricing page",
  "Testing offers & promotions",
  "Driving donations",
  "Seller / host / supplier acquisition",
];
export const moduleLookup = {
  "Driving trial & adoption": "Driving trial & adoption",
  "Optimizing plans & pricing page": "Pricing & plans",
  "Testing offers & promotions": "Offers & promotions",
  "Search messaging & landing pages": "Search messaging",
  "Seller / host / supplier acquisition": "Supplier acquisition (marketplaces)",
  "Driving donations": "Driving donations",
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthText = (start_date, end_date) => {
  if (end_date) {
    const e_date = new Date(end_date);
    const s_date = new Date(start_date);
    const end_month = months[e_date.getMonth()];
    const start_month = months[s_date.getMonth()];
    if (end_month === start_month) {
      return `Ran in ${end_month}`;
    } else {
      return `Ran ${start_month} to ${end_month}`;
    }
  } else {
    const date = new Date(start_date);
    return `Started in ${months[date.getMonth()]}`;
  }
};

export const getCompanyName = company => {
  return companyNames[company] || "Unknown";
};

export const htmlNewLines = text => {
  if (!text) {
    return "";
  }
  return text.replace(/\n/gi, "<br />");
};

export const stripeApiKey =
  process.env.NODE_ENV === "development"
    ? "pk_test_UBDfH6AiSa9tO5tmx2dRb4VK00oZiU0Cki"
    : "pk_live_5faLGpWaIuWMbZWh2vaGZk4R00QaqnGhHy";

export const stripeSkus =
  process.env.NODE_ENV === "development"
    ? {
        hungryroot: "plan_Fv3aTUL2O1jkNQ",
        changedotorg: "plan_Fv3aTUL2O1jkNQ",
        hok: "plan_G2v4kQSlK5y87Y",
        teacherspayteachers: "plan_GW3j6kUp7ah1Vk",
        950: "plan_GKcD4vrkwmZXOs",
        purplecarrot: "plan_GiSTY1wgJZOsbm",
        cookunity: "plan_GjDDRejOAMUk26",
        allplants: "price_1H0AmsFzsFEOE62DT9GIEvQ6",
        vidsense: "price_1HNeyeFzsFEOE62DSS7kftZK",
        homechef: "price_1GyTvPFzsFEOE62DH1BEcp3A",
        veestro: "plan_HImK7xqy0sMGrt",
        curology: "price_1GtI1hFzsFEOE62D7dtFBpyu",
        variis: "price_1GqVWNFzsFEOE62DDcrmfICa",
        duolingo: "price_1GsvikFzsFEOE62DI9q3KJRH",
        siriusxm: "price_1H5YThFzsFEOE62DilT7mB3D",
        spotify: "price_1H5anZFzsFEOE62DVZBS2GoG",
        cbs: "price_1H736xFzsFEOE62D6joR1onu",
        lovevery: "price_1HEiAiFzsFEOE62DA1IXvzhN",
        charitywater: "price_1Ha4XnFzsFEOE62Dktb8rPfL",
        marleyspoon: "price_1Heik7FzsFEOE62DOSmjxBxc",
        thrive: "price_1In1GOFzsFEOE62DtSJR10pS",
        oura: "price_1JQCFYFzsFEOE62DSvFCRBZM",
        hubstaff: "price_1JwDLjFzsFEOE62DmuX0lfBM",
        sem25: "price_1JwDLjFzsFEOE62DmuX0lfBM",
        sem8: "price_1KlIAcFzsFEOE62DpkVbU4iQ",
        thryv: "price_1Kv9RNFzsFEOE62DFFWyeAhL",
        hotjar: "price_1KvO2RFzsFEOE62DcR9DpIOv",
      }
    : {
        hungryroot: "plan_FuEDkAnzmuqxkf",
        changedotorg: "plan_FuEETyt6CT7HFd",
        hok: "plan_G2v4kQSlK5y87Y",
        admin: "plan_FzV3j6ImzSDzAu",
        candid: "plan_GKchUFmtTqjjsZ",
        teacherspayteachers: "plan_GW3j6kUp7ah1Vk",
        950: "plan_GKchUFmtTqjjsZ",
        purplecarrot: "plan_GiSTY1wgJZOsbm",
        cookunity: "plan_GjDDRejOAMUk26",
        veestro: "plan_HImK7xqy0sMGrt",
        allplants: "price_1Gq7jpFzsFEOE62DHU0NBWfU",
        vidsense: "price_1HLAefFzsFEOE62DIbIxagCq",
        homechef: "price_1GyTvPFzsFEOE62DH1BEcp3A",
        curology: "price_1GtI1hFzsFEOE62D7dtFBpyu",
        variis: "price_1GqVVIFzsFEOE62DoIWW6OxT",
        duolingo: "price_1GsvikFzsFEOE62DI9q3KJRH",
        siriusxm: "price_1H5YThFzsFEOE62DilT7mB3D",
        spotify: "price_1H5anZFzsFEOE62DVZBS2GoG",
        cbs: "price_1H736xFzsFEOE62D6joR1onu",
        lovevery: "price_1HEiAiFzsFEOE62DA1IXvzhN",
        charitywater: "price_1Ha4XnFzsFEOE62Dktb8rPfL",
        marleyspoon: "price_1Heik7FzsFEOE62DOSmjxBxc",
        thrive: "price_1Im3w3FzsFEOE62DPVa2PAMm",
        oura: "price_1JQCFYFzsFEOE62DSvFCRBZM",
        hubstaff: "price_1JwDLjFzsFEOE62DmuX0lfBM",
        sem25: "price_1JwDLjFzsFEOE62DmuX0lfBM",
        sem8: "price_1KlIAcFzsFEOE62DpkVbU4iQ",
        thryv: "price_1Kv9RNFzsFEOE62DFFWyeAhL",
        hotjar: "price_1KvO2RFzsFEOE62DcR9DpIOv",
      };
export const COOKIE_EMAIL_SHARED = "email_shared";
export const SHOW_ADMIN_BOX_KEY = "show_admin_box";
let apiUrl;

if (process.env.NODE_ENV === "development") {
  if (document.URL.startsWith("https")) {
    apiUrl = "https://localhost:3005/dev";
  } else {
    apiUrl = "http://localhost:3005/dev";
  }
  //apiUrl = "https://xhzhz9l540.execute-api.us-east-1.amazonaws.com/staging";
} else if (
  window &&
  (window.location.href.startsWith("https://beta.") ||
    window.location.href.startsWith("https://beta--") ||
    window.location.href.startsWith("https://deploy-preview-"))
) {
  apiUrl = "https://xhzhz9l540.execute-api.us-east-1.amazonaws.com/staging";
} else {
  apiUrl = "https://z95m2zgo46.execute-api.us-east-1.amazonaws.com/prod";
}

export const baseApiUrl = apiUrl;

export const createDisplayUrl = (url, cnt) =>
  truncate(
    url.split("/").slice(2).join("/").split(".").slice(-2).join("."),
    cnt ? cnt : 25
  );

export const handleError = (err, res) => {
  const time = localStorage.getItem("error_refresh");
  if (!time || new Date().getTime() - Number(time) > 600000) {
    window.location.reload();
    localStorage.setItem("error_refresh", new Date().getTime());
  }
};

export const setRefreshTime = () => {
  if (!localStorage) {
    return;
  }
  const date = new Date();
  date.setHours(date.getHours() + 12);
  localStorage.setItem("refresh_time", date.getTime());
};
export const getRefreshTime = () => {
  if (!localStorage) {
    return null;
  }
  return localStorage.getItem("refresh_time");
};

export const copyData = (title, data) => {
  if (!localStorage) {
    return null;
  }
  const cData = {
    title,
    data,
  };
  return localStorage.setItem("copy_data", JSON.stringify(cData));
};

export const getCopyData = title => {
  let cData = localStorage.getItem("copy_data");
  if (cData && cData.trim().startsWith("{")) {
    cData = JSON.parse(cData);
  } else {
    console.info("bad copy data");
    return undefined;
  }
  if (cData && cData.title === title) {
    return cData;
  }
  return undefined;
};

export const checkRefresh = () => {
  if (!localStorage) {
    return false;
  }
  const refreshTime = localStorage.getItem("refresh_time");
  const newTime = new Date().getTime();
  if (!refreshTime || newTime > refreshTime) {
    if (!refreshTime) {
      setRefreshTime();
    }
    return true;
  }
  return false;
};

export const getData = (url, isAdmin, resolveErrors, skipCache) =>
  new Promise((resolve, reject) => {
    const key = typeof url === "object" ? url.url : url;
    const cachedData = cache[key];
    const now = new Date().getTime();

    if (!skipCache && cachedData && cachedData.ttl - now <= CACHE_TIME) {
      resolve({ err: null, res: "cache", data: cachedData.data });
      return;
    }
    request(url, (err, res, data) => {
      if (err || res.statusCode >= 400) {
        if (!isAdmin) {
          handleError(err, res);
        }
        if (resolveErrors) {
          resolve({ err, res, data: null });
        }
        reject({ err, res, data });
        return;
      }
      const json = JSON.parse(data);
      cache[key] = {
        data: json,
        ttl: new Date().getTime(),
      };
      resolve({ err, res, data: json });
    });
  });

export const truncate = (text, size) => {
  if (text.length > size) {
    return `${text.substring(0, size)}...`;
  }
  return text;
};
export const track = (url, user) => {
  request({
    url: `${baseApiUrl}/v1/t?url=${url}`,
    headers: {
      Authorization: user.token,
    },
  });
};

export const imageUrl = (url, width, height = 0, modifier = 2) => {
  let newUrl = "";
  if (typeof url === "string") {
    newUrl = url.replace(
      "https://dwwwww.s3.amazonaws.com",
      `https://d39sdekjh6lrma.cloudfront.net/${width * modifier}x${
        height * modifier
      }`
    );
  } else if (url && url[0]) {
    newUrl = url[0].replace(
      "https://dwwwww.s3.amazonaws.com",
      `https://d39sdekjh6lrma.cloudfront.net/${width * modifier}x${
        height * modifier
      }`
    );
  }
  return newUrl;
};
export const randomBlur = amount => {
  // const amount = Math.round(Math.random() * 15);
  return `${5 + amount}px`;
  //return `0px`;
};
export const randomHue = amount => {
  // const amount = Math.round(Math.random() * 189);
  return `${amount}deg`;
};
export const getImage = (type, i, full) => {
  let mobile_imgs, desktop_imgs, img;
  if (full) {
    mobile_imgs = [full_mobile1, full_mobile2, full_mobile3, full_mobile4];
    desktop_imgs = [full_desktop1, full_desktop2, full_desktop3, full_desktop4];
  } else {
    mobile_imgs = [mobile1, mobile2, mobile3, mobile4];
    desktop_imgs = [desktop1, desktop2, desktop3, desktop4];
  }
  if (type === "desktop") {
    img = desktop_imgs[i];
  }
  if (type === "mobile") {
    img = mobile_imgs[i];
  }
  return img;
};
export const freeTrialImageUrl = (
  url,
  type,
  width,
  height = 0,
  modifier = 2
) => {
  let mobile_imgs;
  let desktop_imgs;
  const [imgIndex] = url.split(":");
  return getImage(type, imgIndex);
};
export const freeTrialImageStyles = url => {
  const [imgIndex, hue, blur] = url.split(":");
  return {
    filter: `blur(${randomBlur(Number.parseInt(blur))}) hue-rotate(${randomHue(
      Number.parseInt(hue)
    )})`,
  };
};
export const imageUrlCrop = (url, width, height, y, modifier = 2) => {
  const yStr = y.toString();
  if (!y) {
    return imageUrl(url, width, height, modifier);
  }
  let newUrl = "";
  let computedY =
    yStr.indexOf(".") !== -1 ? yStr.replace(".", "i") : `${yStr}i0`;

  let replaceUrl;
  let realUrl;

  if (typeof url === "string") {
    realUrl = url;
  } else if (url && url[0]) {
    realUrl = url[0];
  } else {
    return imageUrl(url, width, height, modifier);
  }

  if (realUrl.startsWith("https://dwwwww.s3.us-east-1.amazonaws.com")) {
    replaceUrl = "https://dwwwww.s3.us-east-1.amazonaws.com";
  } else {
    replaceUrl = "https://dwwwww.s3.amazonaws.com";
  }
  newUrl = realUrl.replace(
    replaceUrl,
    `https://d39sdekjh6lrma.cloudfront.net/${width * modifier}x${
      height * modifier
    }iixii1i8x${computedY}`
  );
  return newUrl;
};

export const isDemoComp = company =>
  company.includes("-demo") || company.includes("demo-");

export const isMigratedComp = company => {
  if (
    !["admin", "all", "homechef", "cisco", "arena"].includes(company) &&
    !isDemoComp(company)
  ) {
    return true;
  }
  return false;
};

export const hasDiscountDash = company =>
  [
    "allplants",
    "disney",
    "duolingo",
    "hungryroot",
    "homechef",
    "marleyspoon",
    "inuit",
    "lovevery",
    "oura",
    "demo-pet-food",
    "siriusxm",
    "thrivemarket",
    "variis",
  ].includes(company);

export const useHotjar = company =>
  [
    "adobe",
    "arena",
    "betterhelp",
    "cbs",
    "cisco",
    "duolingo",
    "hims",
    "hok",
    "homechef",
    "lovevery",
    "myfitnesspal",
    "siriusxm",
    "smartcommerce",
    "thryv",
    "twilio",
  ].includes(company);

export const useHotjarUrl = pn => {
  const pathname = pn.split("/");
  if (useHotjar(pathname[2])) {
    return true;
  }
  return false;
};

const feature_flags = {
  dashboard2: true,
};

export const hasFeatureFlag = (flag, company) => {
  if (
    feature_flags[flag] &&
    (feature_flags[flag] === true || feature_flags[flag].includes(company))
  ) {
    return true;
  }
  return false;
};

export const parseNoteTag = (text, isAdminPage) => {
  if (!text) {
    return null;
  }
  const textArr = text.split(":");
  if (textArr.length <= 1) {
    return null;
  } else {
    const tag = textArr[0].trim();
    let desc;

    if (isAdminPage) {
      desc = textArr.slice(1).join(":");
    } else {
      if (
        textArr[textArr.length - 1].indexOf('"') !== -1 &&
        textArr[textArr.length - 2].indexOf('"') !== -1
      ) {
        desc = `${textArr[textArr.length - 2].trim()}: ${textArr[
          textArr.length - 1
        ].trim()}`;
      } else {
        desc = textArr.pop().trim();
      }
    }
    if (desc === '"' || desc === "") {
      desc = `${textArr.pop()}`;
    }

    return { tag, desc };
  }
};

export const challengeLookup = {
  "Driving trial & adoption": "Driving trial & adoption",
  "Optimizing plans & pricing page": "Pricing & plans",
  "Testing offers & promotions": "Offers & promotions",
  "Search messaging & landing pages": "Search messaging",
  "Seller / host / supplier acquisition": "Supplier acquisition (marketplaces)",
  "Driving donations": "Driving donations",
};
export const CHALLENGES = [
  {
    value: "Driving trial & adoption",
    text: "Driving trial & adoption",
    collectionName: "Driving trial & adoption",
  },
  {
    value: "Optimizing plans & pricing page",
    text: "Optimizing plans & pricing page",
    collectionName: "Pricing & plans",
  },
  {
    value: "Testing offers & promotions",
    text: "Testing offers & promotions",
    collectionName: "Offers & promotions",
  },
  {
    value: "Driving donations",
    text: "Driving donations",
    collectionName: "Driving donations",
  },
  {
    value: "Seller / host / supplier acquisition",
    text: "Seller / host / supplier acquisition",
    collectionName: "Supplier acquisition (marketplaces)",
  },
  // {
  //   value: "Search messaging & landing pages",
  //   text: "Search messaging & landing pages",
  //   collectionName: "Search messaging",
  // },
  {
    value: "B2C",
    text: "B2C",
    collectionName: "B2C",
  },
  {
    value: "B2B",
    text: "B2B",
    collectionName: "B2B",
  },
  // {
  //   value: "B2C/B2B",
  //   text: "B2C/B2B",
  //   collectionName: "B2C/B2B",
  // },
];
export const formatDomain = url => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    let tld = url.split("/")[2].split(".").slice(-2).join(".");
    if (url.includes(".co.") || url.includes(".com.")) {
      tld = url.split("/")[2].split(".").slice(-3).join(".");
    }

    return tld;
  } else {
    let tld = url.replace(/www\./gi, "");
    return tld;
  }
  return url;
};

export const formatStripePrice = price => {
  if (!price) {
    return "";
  }
  let priceArr = price.toString().split("");
  const decimals = priceArr.slice(-2).join("");
  const dollars = priceArr.slice(0, priceArr.length - 2).join("");

  return `$${addCommas(dollars)}.${decimals}`;
};

export const tagLookup = {
  tos: "TOS",
  faq: "FAQ",
  "call-to-action (cta)": "call-to-action (CTA)",
};

export const copyTextToClipboard = async text => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const generateShareURL = (url, company) => {
  return url.replace(`/home/${company}`, `/link`);
};

export const encodeForm = details => {
  const formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
};

export const encodeAndJoin = arr =>
  arr.map(a => encodeURIComponent(a)).join("|");

export const getShareableURL = shareId =>
  `${window.location.origin}/view/${shareId}`;

export const getCustomerShareableURL = shareId =>
  `${window.location.origin}/share/${shareId}`;

export const getCookieValue = cookieName => {
  const cookie = document.cookie
    .split(";")
    .find(row => row.trim().startsWith(`${cookieName}=`));
  if (cookie) {
    return cookie.split("=")[1];
  } else {
    return undefined;
  }
};
export const getCurrentTime = () => {
  return new Date().getTime();
};

export const correctScreenshotsWeight = screenshots => {
  const eqWeight = 100 / screenshots.length;
  const parsedWeightScreenshots = screenshots.map(ss => {
    const { weight } = ss;
    let weightValue = 0;
    if (weight) {
      weightValue = Math.floor(Number.parseInt(`${weight}`));
    }
    return {
      ...ss,
      weight: weightValue,
    };
  });
  const areValuesNearEqual = allValuesNearEqual(parsedWeightScreenshots);
  const updatedScreenshots = parsedWeightScreenshots.map(screenshot => {
    const { weight } = screenshot;
    let weightValue = weight;
    let nearestMultiple;
    if (areValuesNearEqual) {
      nearestMultiple = eqWeight;
    } else {
      nearestMultiple = 5;
    }
    let newWeight;
    if (areValuesNearEqual) {
      newWeight = Math.floor(nearestMultiple);
    } else {
      newWeight = roundToNearestMultipleOf5(weight, nearestMultiple);
    }
    return {
      ...screenshot,
      weight: newWeight,
    };
  });
  const sumWeight = getWeightedSum(updatedScreenshots);
  const diff = 100 - sumWeight;
  if (diff !== 0 && !areValuesNearEqual) {
    const maxWeightScreenshotIndex =
      getMaxWeightScreenshotIndex(updatedScreenshots);

    updatedScreenshots[maxWeightScreenshotIndex].weight += diff;
  }
  console.log(
    { sumWeight: getWeightedSum(updatedScreenshots) },
    `for weights`,
    updatedScreenshots.map(ss => ss.weight)
  );
  // if (sumWeight > 100) {
  //   const diff = Math.round((sumWeight - 100) / updatedScreenshots.length);
  //   updatedScreenshots.forEach(ss => {
  //     ss.weight -= diff;
  //   });
  // }

  return updatedScreenshots.map(ss => ({ ...ss, weight: `${ss.weight}%` }));
};
function roundToNearestMultipleOf5(val, nearestMultiple = 5) {
  const remainder = val % nearestMultiple;
  if (remainder < Math.floor(nearestMultiple / 2)) {
    return val - remainder;
  } else {
    return val + (nearestMultiple - remainder);
  }
}
const getNearestMultiplier = (screenshotWeight, eqWeight) => {
  const eqMultiplier = Number.parseInt(screenshotWeight / eqWeight);

  const diff = Math.abs(screenshotWeight - eqWeight);
  const nearestMultiple =
    diff <= 4 && screenshotWeight % 5 !== 0 ? eqWeight : 5;
  return nearestMultiple;
};
const allValuesNearEqual = (screenshots, diffRange = 5) => {
  const eqWeight = 100 / screenshots.length;
  return screenshots.every(screenshot => {
    const { weight } = screenshot;
    const diff = Math.abs(weight - eqWeight);
    return diff <= diffRange;
  });
};
const getWeightedSum = screenshots => {
  return screenshots
    .map(ss => ss.weight)
    .reduce((sum, weight, index, arr) => {
      sum += weight;
      return sum;
    }, 0);
};
const getMaxWeightScreenshotIndex = screenshots => {
  const weightArr = screenshots.map(ss => ss.weight);
  const maxIndex = weightArr.indexOf(Math.max(...weightArr));
  return maxIndex;
};
export const ALLOWED_CUSTOMER_FOR_SLACK_CONNECTION = [
  "admin",
  "betterhelp",
  "cbs",
  "demo-ecom",
];

export const SLACK_CLIENT_ID =
  process.env.NODE_ENV === "development"
    ? `6313327648503.6331044993109`
    : `1505726643939.6415816449285`;

export const COUNTRIES = [
  { label: "US / United States", value: "us" },
  { label: "UK / United Kingdom", value: "uk" },
  { label: "AU / Australia", value: "au" },
  { label: "FR / France", value: "fr" },
  { label: "DE / Germany", value: "de" },
];
export function getWinnerText(
  screenshots,
  potential_winner,
  potential_winner_other
) {
  try {
    const potentialWinnerScreenshot = screenshots.find(
      s => s.id === potential_winner
    );
    let winnerText = "";
    if (potential_winner && potential_winner === "other") {
      if (potential_winner_other && potential_winner_other.trim() !== "") {
        winnerText = `Other - ${potential_winner_other}`;
      }
    } else if (potential_winner && potential_winner !== "no-winner") {
      if (potentialWinnerScreenshot) {
        winnerText = potentialWinnerScreenshot.control
          ? `Control`
          : `Variant-${potentialWinnerScreenshot.varNum}`;
      }
    }
    return winnerText;
  } catch (ex) {
    return "";
  }
}
export function getBetScoreName(betScore) {
  if (betScore === "None") {
    return "";
  } else if (betScore <= 100 && betScore >= 80) {
    return "Good Bet";
  } else if (betScore < 80 && betScore >= 60) {
    return "Leans Positive";
  } else if (betScore < 60 && betScore >= 40) {
    return "Toss-Up";
  } else if (betScore < 40 && betScore >= 20) {
    return "Leans Negative";
  } else if (betScore < 20 && betScore >= 0) {
    return "Bad Bet";
  }
}
export const cleanStr = str => {
  const type = typeof str;
  if (str && type === "string") {
    return str.replace(/[^A-Za-z0-9]/gi, "_").toLowerCase();
  } else if (type !== "string") {
    console.info("BAD STR", str);
    return str;
  }
  return "";
};
export const generateNoteText = (noteData, data) => {
  if (!noteData.data || !noteData.type) {
    return noteData.note;
  } else {
    if (noteData.type.toLowerCase() === "winner") {
      const [key, value] = Object.entries(noteData.data).flat();
      let winnerText = value ? value : "";
      if (!winnerText.startsWith("other") && !winnerText === "no-winner") {
        data.screenshots.forEach(ss => {
          if (value === ss.id) {
            let title = ss && ss.control ? `Control` : ``;
            title +=
              ss && ss.champ
                ? title.length == 0
                  ? `Champion`
                  : ` & Champion`
                : ``;
            winnerText = `${title || `Variant ${ss.varNum}`} - ${ss.id}`;
          }
        });
      }
      return noteData.note.replace(`{${key}}`, `${winnerText}`);
    }
  }
};
