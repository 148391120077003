import React from "react";
import request from "request";
import { baseApiUrl } from "../util";
import TagPicker from "./TagPicker";
import BookmarkIcon from "../icons/bookmark_off_solo.svg";
import BookmarkIconOn from "../icons/bookmark_on_solo.svg";
import AddToAnalysisIcon from "../icons/add_box_purple.svg";
import xIcon from "../icons/red_x.svg";
import { addToStorage, getFromStorage } from "../util";
import Modal from "@material-ui/core/Modal";
import InputCounter from "./InputCounter";
const CACHE_NAME = "alltests-cache";

class Collections extends React.Component {
  state = {
    show: false,
    name: undefined,
    id: null,
    deleteId: null,
    addedTests: this.props.d.savedTests,
    savedTests: this.props.savedTests,
    showDelete: false,
    onLoad: true,
    deleteName: "",
    showAnalysis: this.props.showAnalysis || false,
    showCollection: this.props.showCollection || false,
    analysis: this.props.analysis || null,
  };
  updateCache = () => {
    addToStorage(CACHE_NAME, { cache: new Date().getTime() });
  };
  showBookmark = () => {
    this.setState({ show: !this.state.show });
  };
  handleNameChange = val => {
    const id = this.state.id !== "new" ? undefined : this.state.id;
    this.setState({ name: val, id });
  };
  handleSelect = val => {
    this.setState({ id: val, name: undefined });
  };
  close = () => {
    this.setState({ show: false });
  };
  doStar = () => {
    const { token, urlCompany, d, uid } = this.props;
    const { addedTests } = this.state;
    const doDelete = addedTests && addedTests.includes(uid);
    const apiUrl = doDelete
      ? `${baseApiUrl}/v1/delete_favorite`
      : `${baseApiUrl}/v1/favorite`;
    const vals = {
      id: d.id,
      type: "abtest",
      company: urlCompany,
    };
    this.setState({
      show: false,
      name: "",
      doingFavCall: true,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: apiUrl,
        form: vals,
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          this.setState({ error: true });
          return;
        }
        this.updateCache();
        const data = JSON.parse(resp.body);
        let addedTests = data.savedTests.map(x => x.id);
        if (doDelete) {
          addedTests.filter(x => x !== uid);
        } else {
          addedTests.push(uid);
        }
        this.setState({
          savedTests: data.allTests,
          addedTests,
          doingFavCall: false,
        });
        d.savedTests = addedTests;
        if (
          this.props.getDataCallback &&
          doDelete &&
          uid === this.props.collectionId
        ) {
          this.props.getDataCallback(true);
        } else {
          this.props.savedTestsCallback(data.allTests, false, d.id, "star");
        }
      }
    );
  };
  showDelete = (id, name) => e => {
    this.setState({
      showDelete: true,
      deleteId: id,
      deleteName: name,
    });
  };
  doDelete = id => e => {
    this.updateCache();
    const { d, token, urlCompany } = this.props;
    const vals = {
      saved_id: id,
      id: d.id,
      company: urlCompany,
    };
    this.setState({
      deleteId: null,
      deleteName: null,
      showDelete: false,
    });
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: `${baseApiUrl}/v1/delete_saved_tests`,
        form: vals,
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          this.setState({ error: true });
          return;
        }
        const data = JSON.parse(resp.body);
        const addedTests = data.savedTests.map(x => x.id);
        this.setState({
          savedTests: data.allTests,
          addedTests,
          doingSaveCall: false,
        });
        d.savedTests = addedTests;
        if (this.props.getDataCallback && id === this.props.collectionId) {
          this.props.getDataCallback(true);
        } else {
          this.props.savedTestsCallback(
            data.allTests,
            false,
            d.id,
            "delete",
            id
          );
        }
      }
    );
    this.setState({ name: "", doingSaveCall: true });
  };
  closeModal = () => {
    this.setState({ showDelete: false, onLoad: true });
  };
  doSave = () => {
    const { d, token, urlCompany } = this.props;
    const { id, name } = this.state;

    let sId = id;

    if (!sId) {
      const lastItem = getFromStorage(`last-test-${urlCompany}`);
      if (lastItem && lastItem.value) {
        sId = lastItem.value;
      }
    }
    this.updateCache();

    const vals =
      sId && sId !== "new"
        ? {
            saved_id: sId,
            id: d.id,
            type: "abtest",
            company: urlCompany,
          }
        : {
            name,
            id: d.id,
            type: "abtest",
            company: urlCompany,
          };
    request.post(
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
        url: `${baseApiUrl}/v1/saved_tests`,
        form: vals,
      },
      (err, resp) => {
        if (err || resp.statusCode >= 400) {
          this.setState({ error: true });
          return;
        }
        const data = JSON.parse(resp.body);
        const addedTests = data.savedTests.map(x => x.id);
        this.setState({
          savedTests: data.allTests,
          addedTests,
        });
        d.savedTests = addedTests;
        this.props.savedTestsCallback(
          data.allTests,
          sId === "new",
          d.id,
          "save",
          data.savedTests[0].id
        );
        if (id === "new") {
          const item = data.allTests.find(t => t.name === name);
          if (item) {
            addToStorage(`last-test-${urlCompany}`, {
              value: item.id,
              label: item.name,
            });
          }
        } else {
          addToStorage(`last-test-${urlCompany}`, { value: sId, label: "" });
        }
      }
    );
    this.setState({
      show: false,
      name: "",
      id: this.state.id === "new" ? null : this.state.id,
    });
  };
  closeErrorModal = () => {
    this.setState({ error: false });
  };
  editTestName = (id, name) => {
    return new Promise((resolve, reject) => {
      const { d, token, urlCompany } = this.props;
      const vals = {
        id,
        name,
        company: urlCompany,
      };
      this.updateCache();
      request.post(
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: token,
          },
          url: `${baseApiUrl}/v1/edit_saved_test`,
          form: vals,
        },
        (err, resp) => {
          if (err || resp.statusCode >= 400) {
            this.setState({ error: true });
            reject({ error: true });
          }
          const data = JSON.parse(resp.body);
          this.setState({
            doingSaveCall: false,
          });

          this.props.getDataCallback && this.props.getDataCallback(true);

          resolve({ data });
        }
      );
      this.setState({ doingSaveCall: true });
    });
  };
  render() {
    const { urlCompany, uid } = this.props;

    const {
      addedTests,
      doingSaveCall,
      savedTests,
      analysis,
      showCollection,
      showAnalysis,
    } = this.state;

    const usedTests = savedTests
      ? savedTests.filter(t => addedTests && addedTests.includes(t.id))
      : [];
    const testsForDD =
      addedTests && savedTests
        ? savedTests.filter(t => !addedTests.includes(t.id) && !t.isFav)
        : savedTests
        ? savedTests.filter(t => !t.isFav)
        : [];

    let lastItem = getFromStorage(`last-test-${urlCompany}`);

    if (lastItem && lastItem.label === "") {
      const realItem = testsForDD.find(x => x.id === lastItem.value);

      if (realItem) {
        lastItem.label = realItem.name;
      } else {
        lastItem.id = undefined;
      }
      if (this.state.show === true) {
        if (lastItem.value !== this.state.id && this.state.onLoad) {
          this.setState({ id: lastItem.value, onLoad: false });
        } else if (this.state.onLoad) {
          this.setState({ onLoad: false });
        }
      }
    }

    const width = 600;
    const height = 200;
    return (
      <div
        className="c-box-inner collections-module pb1"
        style={{
          position: "relative",
        }}
      >
        <Modal
          open={this.state.error ? true : false}
          className="dww-modal-container"
        >
          <div
            className="box"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              overflow: "hidden",
              top: (window.innerHeight - height) / 2,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
              outline: 0,
            }}
          >
            <div>
              <h3 className="pv3 ph4 bb b--black-05">
                Looks like something went wrong&hellip;
              </h3>
              <div className="ph4 pv2">Please try again.</div>
              <div className="ph4 pv3">
                <button
                  className="ui-button primary"
                  onClick={this.closeErrorModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal open={this.state.showDelete} className="dww-modal-container">
          <div
            className="box"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              overflow: "hidden",
              top: (window.innerHeight - height) / 2,
              position: "absolute",
              left: (window.innerWidth - width) / 2,
              outline: 0,
            }}
          >
            <div>
              <h3 className="pv3 ph4 bb b--black-05">Are you sure?</h3>
              <div className="ph4 pv2">
                Are you sure you want to remove this test from "
                {this.state.deleteName}"?
              </div>
              <div className="ph4 pv3">
                <button
                  className="ui-button secondary mr3"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  className="ui-button primary"
                  onClick={this.doDelete(this.state.deleteId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {showCollection && (
          <img
            src={
              usedTests.filter(x => !x.isFav).length > 0
                ? BookmarkIconOn
                : BookmarkIcon
            }
            alt="Add to collection"
            title="Add to collection"
            className="pointer"
            height="24"
            width="24"
            onClick={this.showBookmark}
            style={{
              opacity: doingSaveCall ? 0.5 : 1.0,
            }}
          />
        )}
        {showAnalysis && (
          <img
            src={AddToAnalysisIcon}
            alt="Add to analysis"
            title="Add to analysis"
            className="pointer"
            height="24"
            width="24"
            onClick={this.showBookmark}
            style={{
              opacity: doingSaveCall ? 0.5 : 1.0,
              marginTop: "-4px",
            }}
          />
        )}
        {this.state.show && (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: "4",
                width: "100vw",
                height: "100vh",
                top: "0px",
                left: "0px",
              }}
              onClick={this.close}
            ></div>
            <div
              className="pa2 bg-white br3 box f5"
              style={{
                width: "250px",
                position: "absolute",
                right: "0px",
                top: "-6px",
                zIndex: "5",
              }}
            >
              <div className="mb2">
                {savedTests && savedTests.length > 0 ? (
                  <>
                    <div className="w-100 mb2 mt2 skinny-tag-filter">
                      <TagPicker
                        options={(showCollection
                          ? [
                              {
                                label: "Add a new collection",
                                value: "new",
                              },
                            ]
                          : []
                        ).concat(
                          testsForDD.map(t => ({
                            label: t.name,
                            value: t.id,
                          }))
                        )}
                        defaultOptions={lastItem}
                        callback={this.handleSelect}
                        fixAt={{
                          width: 300,
                          right: -40,
                          top: 72,
                        }}
                        title={
                          showCollection
                            ? "Collections"
                            : showAnalysis
                            ? "Analysis"
                            : ""
                        }
                        onRight
                        isSticky
                        noSort
                        hasSearch
                        editable={showCollection}
                        onTagSave={this.editTestName}
                      />
                    </div>
                    {this.state.id === "new" && showCollection && (
                      <>
                        <InputCounter
                          name="name"
                          className="tag-filter w-100"
                          placeholder="Name your collection"
                          callback={this.handleNameChange}
                          total={40}
                        />
                        <p className="f7 dww-gray tl">
                          <b>For example:</b> Landing page ideas, Signup page
                          ideas, Interesting CTAs for new home page.
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <InputCounter
                      name="name"
                      className="tag-filter w-100"
                      placeholder="Name your collection"
                      callback={this.handleNameChange}
                      total={40}
                    />
                    <p className="f7 dww-gray tl">
                      <b>For example:</b> Landing page ideas, Signup page ideas,
                      Interesting CTAs for new home page.
                    </p>
                  </>
                )}
              </div>
              <button onClick={this.doSave} className="ui-button primary">
                Save
              </button>
              {usedTests && usedTests.length > 0 && (
                <div className="mt3 mb2 tl f5 bt b--black-05 pt3">
                  <p className="f6 b">
                    {showCollection
                      ? "In Collections"
                      : showAnalysis
                      ? "In Analysis"
                      : ""}
                  </p>
                  {usedTests.map(t => {
                    const showX = !t.isFav || (t.isFav && t.id === uid);
                    return (
                      <span className="f6 bg-light-gray br3 pa2 mb2 dib mr2">
                        {t.name}
                        {showX && (
                          <span
                            className="ml2 red pointer"
                            onClick={this.showDelete(t.id, t.name)}
                          >
                            <img
                              src={xIcon}
                              width="8"
                              height="8"
                              alt="remove"
                              title={t.id}
                            />
                          </span>
                        )}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Collections;
