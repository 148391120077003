import React, { Component } from "react";
import request from "request";
import { fbAuth, baseApiUrl, setRefreshTime } from "../util";
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email || "",
      name: this.props.name || "",
      invite_id: this.props.invite_id,
      redirectUrl: this.props.redirectUrl,
      password: "",
      password_error: false,
      password_checked: false,
      submitting: false,
      error: null,
      newsletter: true,
      interest: {
        web: false,
        sem: false,
        intel: false,
        discounts: false,
      },
    };
  }
  onChange = e => {
    this.setState({ newsletter: !this.state.newsletter });
  };
  handleSubmit = e => {
    const {
      email,
      name,
      password,
      password_error,
      password_checked,
      newsletter,
    } = this.state;
    const { isSSO } = this.props;
    e.preventDefault();
    this.setState({
      error: null,
      submitting: true,
    });

    if (!isSSO && (password_error || !password_checked)) {
      this.setState({
        error: { message: "Please fix the errors below!" },
        password_error: true,
        submitting: false,
      });

      return;
    }

    request.get(
      { url: `${baseApiUrl}/v1/can_register?company=${this.props.company}` },
      async (err, data) => {
        if (err || data.statusCode >= 400) {
          this.setState({
            error: {
              key: "cant_reg",
            },
            submitting: false,
          });
          return;
        }
        setRefreshTime();
        if (isSSO) {
          request.post(
            {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
              url: `${baseApiUrl}/v1/user/sso`,
              form: {
                id: this.props.profile.id,
                email,
                name,
                company: this.props.company,
                newsletter,
                interests: Object.keys(this.state.interest)
                  .filter(i => this.state.interest[i])
                  .join(","),
                invite: this.props.invite ? this.props.invite.id : undefined,
              },
            },
            async (err, resp, data) => {
              if (!resp || resp.statusCode >= 400) {
                this.setState({
                  error: {
                    message: "Something went wrong! We aren't quite sure what.",
                  },
                  submitting: false,
                });
              }
              const json = JSON.parse(data);
              setRefreshTime();
              await fbAuth.signInWithCustomToken(json.token);
            }
          );
        } else {
          fbAuth
            .createUserWithEmailAndPassword(email, password)
            .catch(error => {
              this.setState({ error, submitting: false });
            })
            .then(async (data, err) => {
              if (!data) {
                return;
              }
              const { name, email } = this.state;
              const token = await fbAuth.currentUser.getIdToken(true);
              request.post(
                {
                  headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    Authorization: token,
                  },
                  url: `${baseApiUrl}/v1/user`,
                  form: {
                    invite: this.props.invite_id,
                    email,
                    name,
                    company: this.props.company,
                    newsletter,
                    interests: Object.keys(this.state.interest)
                      .filter(i => this.state.interest[i])
                      .join(","),
                  },
                },
                (err, resp) => {
                  if (resp.statusCode >= 400) {
                    this.setState({
                      error: {
                        message:
                          "Something went wrong! We aren't quite sure what.",
                      },
                      submitting: false,
                    });
                    return;
                  }
                  setRefreshTime();
                  if (!this.props.isRegister) {
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  } else {
                    if (this.props.redirectUrl) {
                      window.location = this.props.redirectUrl;
                    } else {
                      setTimeout(() => {
                        window.location.reload();
                      }, 500);
                    }
                  }

                  return;
                }
              );
            });
        }
      }
    );
  };
  handleKeyUp = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      error: null,
    });
  };
  handlePWConfirm = e => {
    const { value } = e.target;
    if (value !== this.state.password) {
      this.setState({
        password_error: true,
        password_checked: true,
      });
    } else {
      this.setState({
        password_error: false,
        password_checked: true,
      });
    }
  };
  logout = e => {
    e.preventDefault();
    fbAuth.signOut();
  };
  changeInterest = e => {
    const { name, checked } = e.currentTarget;

    const interest = this.state.interest;

    interest[name] = checked;

    this.setState({
      interest,
    });
  };
  render() {
    const labelClass = "db fw4 lh-copy black-60 ssb";
    const inputClass = "pa2 input-reset w-100 ba b--black-20 bg-white br2";

    const { error, password_error, submitting } = this.state;
    const { isRegister, isSSO, isInvite } = this.props;
    return (
      <div className="register center box flex flex-wrap tl w-75">
        <form onSubmit={this.handleSubmit} className="w-100">
          <div className="flex bb b--dww pv2 ph3">
            {isInvite && (
              <h1 className="f2 dash-head pt2">Accept your invitation</h1>
            )}
            {isRegister && !isInvite && (
              <h1 className="f2 dash-head pt2">Create an account</h1>
            )}
            {!isRegister && !isInvite && (
              <h1 className="f2 dash-head pt2">
                First, let's create an account
              </h1>
            )}
          </div>
          <div className="pa3">
            {error && (
              <p className="dark-red b">
                {error.key && error.key === "cant_reg" ? (
                  <>
                    You can't sign up for this company. Please contact{" "}
                    <a href="mailto:help@dowhatworks.io">help@dowhatworks.io</a>{" "}
                    for assistance.
                  </>
                ) : (
                  <>{error.message}</>
                )}
              </p>
            )}
            <div className="flex mt2">
              <div className="w-50 pr3">
                <label className={labelClass}>Name</label>
                <input
                  type="text"
                  onChange={this.handleKeyUp}
                  name="name"
                  className={inputClass}
                  disabled={submitting}
                  value={this.state.name}
                  required
                />
              </div>
              <div className="w-50 pl3">
                <label className={labelClass}>Email</label>
                <input
                  type="email"
                  onChange={this.handleKeyUp}
                  name="email"
                  className={inputClass}
                  disabled={submitting}
                  value={this.state.email}
                  required
                />
              </div>
            </div>
            {!isSSO && (
              <>
                <div className="flex mt4">
                  <div className="w-50 pr3">
                    <label className={labelClass}>Password</label>
                    <input
                      type="password"
                      onChange={this.handleKeyUp}
                      name="password"
                      className={inputClass}
                      disabled={submitting}
                      required
                    />
                  </div>
                  <div className="w-50 pl3">
                    <label className={labelClass}>Confirm Password</label>
                    <input
                      type="password"
                      onChange={this.handlePWConfirm}
                      name="password_confirm"
                      className={inputClass}
                      disabled={submitting}
                    />
                  </div>
                </div>
                {password_error && (
                  <p className="dark-red">Passwords don't match!</p>
                )}
              </>
            )}
            <div className="mt4 flex">
              <div className="w-50 pr3">
                <label className={labelClass}>
                  What kind of tests are you interested in?
                </label>
                <div className="f6">
                  <div className="mb1">
                    <label className="black-60">
                      <input
                        type="checkbox"
                        name="web"
                        className="mr2"
                        onClick={this.changeInterest}
                      />
                      Web optimization
                    </label>
                  </div>
                  <div className="mb1">
                    <label className="black-60">
                      <input
                        type="checkbox"
                        name="sem"
                        className="mr2"
                        onClick={this.changeInterest}
                      />
                      Search Engine Marketing optimization
                    </label>
                  </div>
                  <div className="mb1">
                    <label className="black-60">
                      <input
                        type="checkbox"
                        name="intel"
                        className="mr2"
                        onClick={this.changeInterest}
                      />
                      Competitive intel
                    </label>
                  </div>
                  <div className="mb1">
                    <label className="black-60">
                      <input
                        type="checkbox"
                        name="discounts"
                        className="mr2"
                        onClick={this.changeInterest}
                      />
                      Discounts
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-50 pl3">
                <label className={labelClass}>
                  Subscribe to our newsletter
                </label>
                <label className={`${labelClass} flex`}>
                  <input
                    type="checkbox"
                    className="mr2 dib"
                    name="newsletter"
                    style={{ height: "20px", width: "20px" }}
                    onChange={this.onChange}
                    checked={this.state.newsletter}
                  />
                  <div className="dib f6" style={{ marginTop: "-1px" }}>
                    Sign me up
                    <div className="f7">
                      You'll get updates to the DoWhatWorks blog in your inbox.
                      <br />
                      We'll share insights from growth experiments, interviews
                      with growth leaders, and more!
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="pb3 pr3 pl3 pt2 flex">
            <div>
              {submitting && (
                <input
                  type="button"
                  value="Saving..."
                  disabled
                  className="ui-button secondary"
                />
              )}
              {!submitting && (
                <input
                  type="submit"
                  value="Create"
                  disabled={submitting}
                  className="ui-button primary"
                />
              )}
            </div>
            <p className="f7 pl3" style={{ paddingTop: "10px" }}>
              By clicking "Create", you're agreeing to Do What Works'{" "}
              <a href="/terms" target="_blank">
                Terms of Service
              </a>
              .
            </p>
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
